import React, { useState } from 'react';
import {
    useParams
} from "react-router-dom";
import { Row, Col, Container, Form, Accordion, Alert, InputGroup } from "react-bootstrap";
import './ViewInscripcion.css';
//import DEFAULT_URL from '../../../api/url';
export const ViewInscripcionCliente = () => {
    const [inscripcion_Agremiado, setInscripcion_Agremiado] = useState("");
    const [inscripcion_Inversion, setInscripcion_Inversion] = useState("");
    const [evento_Nombre, setEvento_Nombre] = useState("");
    const [moneda_Nombre, setMoneda_Nombre] = useState("");
    const [detalle, setDetalle] = useState([]);
    const [asesor, setAsesor] = useState({});
    const [estado, setEstado] = useState("");
    const [empresa, setEmpresa] = useState('');
    const { id } = useParams();
    const [comprobante, setComprobante] = useState(null);
    var endPoint = process.env.REACT_APP_DEFAULT_URL + "/api/read_id/inscripcion/" + id;
    React.useEffect(() => {
        fetch(endPoint, { cache: "no-cache" })
            .then(res => {
                return res.json();
            })
            .then(data => {
                setEmpresa(data.rows[0].empresa);
                setInscripcion_Agremiado(data.rows[0].inscripcion_Agremiado);
                setInscripcion_Inversion(data.rows[0].inscripcion_Inversion);
                setEvento_Nombre(data.rows[0].evento_Nombre);
                setMoneda_Nombre(data.rows[0].moneda_Nombre);
                setDetalle(data.detalle);;
                setAsesor(data.asesor[0]);
                setEstado(data.rows[0].estado);
                setComprobante(data.rows[0].comprobante);
            });
    }, []);
    return (
        <div className="contenedor-view-inscripcion">
            <Container>
                <Row>
                    <h1 className="title-contancia">Confirmación de inscripción</h1>
                </Row>
                <Row>
                    <Col xs={12} md={6} className="columna-formulario-view">
                        <Container>
                            <Row >
                                <Col>
                                    <div style={{ padding: '2%', textAlign: 'center' }}>
                                        <Form.Group className="mb-3" controlId="Agremiado" style={{ color: 'white' }}>
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                className="font-normal"
                                                name="Agremiado"
                                                label="Agremiados"
                                                style={{ textAlign: 'left' }}
                                                disabled
                                                checked={inscripcion_Agremiado}
                                            />
                                        </Form.Group>
                                        <Accordion defaultActiveKey="0" className="bg-success" style={{ marginTop: '5%' }} alwaysOpen>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header className="font-bold">INFORMACIÓN DEL EVENTO</Accordion.Header>
                                                <Accordion.Body className="font-normal">
                                                    <Form.Group className="mb-3" controlId="Forma_Pago_UniqueID">
                                                        <Form.Label>Asesor</Form.Label>
                                                        <Form.Control type="text" value={asesor.Nombres + " " + asesor.Apellidos} disabled aria-label="Asesor" name="Asesor_UniqueID"></Form.Control>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="Inversion">
                                                        <Form.Label>Inversión</Form.Label>
                                                        <InputGroup className="mb-3">
                                                            <InputGroup.Text>{moneda_Nombre}</InputGroup.Text>
                                                            <Form.Control disabled name="Inversion" placeholder="Ingrese el monto de inversión" value={inscripcion_Inversion/detalle.length} />
                                                        </InputGroup>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="Evento_Unique_ID">
                                                        <Form.Label>Evento</Form.Label>
                                                        <Form.Control type="text" disabled aria-label="Evento" name="Evento_Unique_ID" value={evento_Nombre} ></Form.Control>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="empresa">
                                                        <Form.Label>Institución</Form.Label>
                                                        <Form.Control type="text" disabled aria-label="empresa" name="empresa" value={empresa} ></Form.Control>
                                                    </Form.Group>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '25px', marginBottom: '25px' }}>
                                <Container>
                                    <Row>
                                        <Alert show={estado == '1'} style={{ textAlign: 'left' }} variant="danger">
                                            <Alert.Heading>Inscripción pendiente de autorización</Alert.Heading>
                                            <p>
                                                Se estará contactando con usted una persona del equipo de EBG
                                                para confirmar su pago.
                                            </p>
                                            <hr />
                                            Cualquier duda o comentario, contáctenos
                                        </Alert>
                                        <Alert show={estado == '2'} style={{ textAlign: 'left' }} variant="success">
                                            <Alert.Heading>Inscripción autorizada</Alert.Heading>
                                            <p>
                                                ¡Todo listo! se ha confirmado el pago de la presente ficha de inscripción.
                                            </p>
                                            <hr />
                                            <p>
                                                Esta ficha de inscripción ha sido pagada
                                            </p>
                                        </Alert>
                                    </Row>
                                </Container>
                            </Row>
                        </Container>
                    </Col>
                    <Col xs={12} md={6}>
                        <Container>
                            <Row>
                                <Col>
                                    <div className="view-detalle" style={{ padding: '2%', textAlign: 'center' }}>
                                        <Accordion>
                                            {detalle.map(actual =>
                                                <Accordion.Item eventKey={actual.UniqueID} key={actual.UniqueID}>
                                                    <Accordion.Header>{actual.Nombres + ' ' + actual.Apellidos}</Accordion.Header>
                                                    <Accordion.Body>
                                                        <Container>
                                                            <Row>
                                                                <Col xs={12} md={12}>
                                                                    <Form.Group className="mb-3" >
                                                                        <Form.Label>País</Form.Label>
                                                                        <Form.Control disabled type="text" value={actual.pais} />
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={12} md={6}>
                                                                    <Form.Group className="mb-3" >
                                                                        <Form.Label>Nombres</Form.Label>
                                                                        <Form.Control disabled type="text" value={actual.Nombres} />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col xs={12} md={6}>
                                                                    <Form.Group className="mb-3" >
                                                                        <Form.Label>Apellidos</Form.Label>
                                                                        <Form.Control disabled type="text" value={actual.Apellidos} />
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={12} md={6}>
                                                                    <Form.Group className="mb-3" >
                                                                        <Form.Label>Puesto</Form.Label>
                                                                        <Form.Control disabled type="text" value={actual.Puesto} />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col xs={12} md={6}>
                                                                    <Form.Group className="mb-3" >
                                                                        <Form.Label>Correo Electrónico</Form.Label>
                                                                        <Form.Control disabled type="email" value={actual.Correo} />
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={12} md={6}>
                                                                    <Form.Group className="mb-3" >
                                                                        <Form.Label>Teléfono y extensión</Form.Label>
                                                                        <Form.Control disabled type="text" value={actual.Telefono} />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col xs={12} md={6}>
                                                                    <Form.Group className="mb-3" >
                                                                        <Form.Label>Celular</Form.Label>
                                                                        <Form.Control disabled type="text" value={actual.celular} />
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                        </Container>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            )}
                                        </Accordion>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
                {comprobante != null && <Row style={{ color: 'white', paddingBottom:'75px', paddingTop:'25px' }}>
                    <h1 className="title-contancia">Scan de cheque o depósito/transferencia</h1>
                    <iframe src = {comprobante} alt = 'comprobante' style = {{height:'800px'}}/>
                </Row>}
            </Container>
        </div>
    );

}