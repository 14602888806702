import React, { useState, useEffect } from 'react'
import { Row, Button, Form, Container } from "react-bootstrap";
//import DEFAULT_URL from '../../api/url';
import ViewFacilitador from './ViewFacilitador';
import FormFacilitador from './FormFacilitador';
const Facilitadores = (props) => {
  const [contador, setContador] = useState(0);
  const [facilitadores, setFacilitadores] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [criterio, setCriterio] = useState('');
  const [edit, setEdit] = useState('');

  useEffect(() => {
    async function fetchData() {
      fetch(process.env.REACT_APP_DEFAULT_URL + '/api/facilitadores/read', { cache: "no-cache" })
        .then((res) => {
          return res.json();
        })
        .then(data => {
          setCriterio('');
          setFilteredData(data.rows);
          setFacilitadores(data.rows);
        });
    }
    fetchData();
  }, [contador, props.display]);
  useEffect(() => {
  }, [edit]);
  const refresh = () => {
    setContador(contador + 1);
  }
  const onChange = (evt) => {
    setCriterio(evt.target.value);
    setEdit(edit + 1);
    let result = [];
    result = facilitadores.filter((actual) => {
      return actual.Nombres.search(evt.target.value) != -1 || actual.Apellidos.search(evt.target.value) != -1 || actual.Correo.search(evt.target.value) != -1;
    });
    setFilteredData(result);
    setEdit(edit + 1);
  }
  return (
    <Container>
      <Row>
        <FormFacilitador refresh={refresh} />
      </Row>
      <Row style={{ marginTop: '25px', marginBottom: '25px' }}>
        <Form.Group className="mb-3" controlId="institucion">
          <Form.Label>Buscar</Form.Label>
          <Form.Control required type="text" placeholder="puede buscar el o los facilitadores por nombres, apellidos o correo electrónico" name="criterio" value={criterio} onChange={onChange} />
        </Form.Group>
      </Row>
      <Row style={{ marginTop: '25px' }}>
        <Container>
          <Row>
            <Button variant='outline-dark' onClick={refresh}>Refrescar</Button>
          </Row>
          <Row style={{ marginTop: '25px' }}>
            <h1>Listado de facilitadores</h1>
          </Row>
          {filteredData.map((actual, index) => <Row key={'FACILITADOR-' + index} style={{ marginTop: '25px', marginBottom: '25px' }}>
            <ViewFacilitador facilitador={actual} refresh={refresh} />
          </Row>)}
        </Container>
      </Row>
    </Container>
  )
}

export default Facilitadores