import React from 'react'
import { Row, Col, Form, ListGroup, Badge } from "react-bootstrap";

const Solicitud = (props) => {
    return (
        <Row>
            <h1 style={{ marginBottom: '25px', marginTop: '25px', color: 'white' }}>Información de solicitud</h1>
            <ListGroup as="ol">
                <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                >
                    <div className="ms-2 me-auto">
                        <div className="fw-bold">{props.solicitud.Empresa}</div>
                        {props.solicitud.fecha}
                    </div>
                    {props.solicitud.tipo == 0 && <Badge bg="secondary" pill>
                        Baja
                    </Badge>}
                    {props.solicitud.tipo == 1 && <Badge bg="warning" pill>
                        Normal
                    </Badge>}
                    {props.solicitud.tipo == 2 && <Badge bg="danger" pill>
                        Urgente
                    </Badge>}
                    {props.solicitud.estado == 0 && <Badge bg="primary" pill>
                        En revisión
                    </Badge>}
                    {props.solicitud.estado == 1 && <Badge bg="danger" pill>
                        Rechazado
                    </Badge>}
                </ListGroup.Item>
                {props.solicitud.estado == 1 && props.solicitud.revisor != null &&
                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start"
                    >
                        <div className="ms-2 me-auto">
                            <div className="fw-bold">Revisado por</div>
                            {props.solicitud.revisor}
                        </div>
                    </ListGroup.Item>
                }

            </ListGroup>
            <Col style={{ color: 'white', marginTop: '20px' }}>
                <h1 style={{ marginBottom: '25px', marginTop: '25px', color: 'white' }}>Comentario de la solicitud</h1>
                <Form.Group className="mb-3" controlId="comentario">
                    <Form.Control as="textarea" disabled placeholder="Ingrese sus comentarios sobre la solicitud" rows={3} style={{ resize: 'none' }} name="Comentario" value={props.solicitud.comentario} />
                </Form.Group>
            </Col>
            {props.solicitud.estado == 1 && props.solicitud.retroalimentacion != null &&
                <Col style={{ color: 'white', marginTop: '20px' }}>
                    <h1 style={{ marginBottom: '25px', marginTop: '25px', color: 'white' }}>Retroalimentación de solicitud</h1>
                    <Form.Group className="mb-3" controlId="comentario">
                        <Form.Control as="textarea" disabled placeholder="Ingrese sus comentarios sobre la solicitud" rows={3} style={{ resize: 'none' }} name="Comentario" value={props.solicitud.retroalimentacion} />
                    </Form.Group>
                </Col>
            }
        </Row>
    )
}

export default Solicitud