import React, { useState, useEffect } from 'react';
import { Modal, Row, Button, Col, Form, Container } from "react-bootstrap";
import { ErrorModal } from '../../../Utils/ErrorModal/ErrorModal';
import { SuccessModal } from '../../../Utils/SuccessModal/SuccessModal';
//import DEFAULT_URL from '../../../../api/url';
import { ModalCargando } from '../../../Utils/Modal-Cargando/ModalCargando';
import './ModalMaterial.css'
const ModalNuevaCarpeta = (props) => {
    const [nombre, setNombre] = useState('');
    const [contador, setContador] = useState(0);
    const [modalSucc, setModalSucc] = useState(false);
    const [modalError, setModalError] = useState(false);
    const [modalCargando, setModalCargando] = useState(false);
    const [mensaje, setMensaje] = useState(false);
    const [consideracion, setConsideracion] = useState('');
    const [mensajeCargando, setMensajeCargando] = useState('');
    useEffect(() => {
    }, [contador])
    const ocultar = () => {
        setModalSucc(false);
        setModalError(false);
        props.refrescar();
        props.onHide();
    }
    const onChange = (evt) => {
        setNombre(evt.target.value);
        setContador(contador + 1);
    }
    const ocultarModalCargando = () => {
        setModalCargando(false);
    }
    const submitCarpeta = (evt) => {
        evt.preventDefault();
        setMensajeCargando('Se está creando la carpeta, por favor espere.');
        setModalCargando(true);
        const info = { Nombre: nombre, evento_UniqueID: props.evento_UniqueID };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/create/carpeta', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                if (data.codigo == 200) {
                    setModalCargando(false);
                    setMensaje('Carpeta almacenada exitosamente');
                    setContador(contador + 1);
                    setModalSucc(true);
                } else {
                    setMensaje(data.respuesta);
                    setModalError(true);
                }
                Array.from(document.querySelectorAll("input")).forEach(
                    input => (input.value = "")
                );
            });
        setNombre('');
    }
    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
        >
            <Modal.Header closeButton style={{ backgroundColor: "#617785", borderColor: 'transparent', color: 'black' }}>
                <Modal.Title id="contained-modal-title-vcenter" className="font-bold">
                    Nueva Carpeta
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: "#617785", borderColor: 'transparent', color: 'black' }} id="body-modal-edit-evento">
                <Form onSubmit={submitCarpeta}>
                    <Container>
                        <Row>
                            <Col sm={12} md={12} lg={12}>
                                <Form.Group className="mb-3" controlId="material">
                                    <Form.Label>Nombre de la carpeta</Form.Label>
                                    <Form.Control required type="text" name="nombre" placeholder="Ingrese el nombre de la carpeta" onChange={onChange} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button variant="light" className="font-bold" type="submit">Crear Carpeta</Button>
                            </Col>
                        </Row>
                    </Container>
                </Form>
            </Modal.Body>
            <SuccessModal
                color={"#b5bad0"}
                show={modalSucc}
                onHide={ocultar}
                mensaje={mensaje}
                consideracion={consideracion}
                enlace={''}
                llevaenlace={false}
            />
            <ErrorModal
                color={"#b5bad0"}
                show={modalError}
                onHide={ocultar}
                mensaje={mensaje}
                consideracion={consideracion}
            />
            <ModalCargando
                color={"#b5bad0"}
                show={modalCargando}
                onHide={ocultarModalCargando}
                mensaje={mensajeCargando}
            />
        </Modal>
    )
}

export default ModalNuevaCarpeta