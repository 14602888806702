import React from 'react';
import { Modal } from "react-bootstrap";
const ModalVistaPrevia = (props) => {
    return (
        <Modal
            size='fullscreen'
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
        >
            <Modal.Header closeButton style={{ backgroundColor: "#B5BAD0", borderColor: 'transparent', color: 'black' }}>
                <Modal.Title id="contained-modal-title-vcenter" className="font-bold">
                    Vista Previa
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: "#B5BAD0", borderColor: 'transparent', color: 'white' }} id="body-modal-edit-evento">
                <iframe src={props.url} style={{ width: '100%', height: '100%', objectFit: 'cover' }}>

                </iframe>
            </Modal.Body>
        </Modal>
    )
}

export default ModalVistaPrevia