import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import { Row, Button, Col, Form, Container, ProgressBar, Alert } from "react-bootstrap";
//import DEFAULT_URL from '../../../../../api/url';
//import { SELF_URL } from '../../../../../api/url';
import './common.css';
const Formulario = () => {
    const { correlativo, param } = useParams();
    const [contador, setContador] = useState(0);
    const [formulario, setFormulario] = useState({});
    const [preguntas, setPreguntas] = useState([]);
    const [mensaje, setMensaje] = useState('');
    const [checked, setChecked] = useState(false);
    const [asesores, setAsesores] = useState([]);
    const [salida, setSalida] = useState([]);
    const [paises, setPaises] = useState([]);
    const [dia, setDia] = useState('');
    const [mesAnio, setMesAnio] = useState('');
    const [promocional, setPromocional] = useState('');
    const [exito, setExito] = useState('none');
    const [error, setError] = useState('none');
    const [cargando, setCargando] = useState('none');
    const [detalle, setDetalle] = useState('block');
    const [status, setStatus] = useState(0);
    useEffect(() => {
        async function fetchData() {
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/formulario/' + correlativo, { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    if (data.preguntas.length == 0) {
                        window.location.replace('/unauthorized');
                    } else {
                        if (data.formulario.disponible == 0) {
                            window.location.replace('/unauthorized');
                        } else {
                            let form = data.formulario;
                            let pregunts = data.preguntas;
                            pregunts.map((pregunta, index) => {
                                pregunta.respuesta = '';
                            });
                            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/gafete/' + param, { cache: "no-cache" })
                                .then((res) => {
                                    return res.json();
                                })
                                .then(info => {
                                    if (info.salida[0] != null) {
                                        pregunts[0].respuesta = info.salida[0].Correo;
                                        pregunts[1].respuesta = info.salida[0].Nombres;
                                        pregunts[2].respuesta = info.salida[0].Apellidos;
                                    } else {
                                        window.location.replace('/unauthorized');
                                    }
                                });
                            setFormulario(data.formulario);
                            setPreguntas(pregunts);
                            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read_id/evento/' + data.formulario.evento_UniqueID, { cache: "no-cache" })
                                .then((res) => {
                                    return res.json();
                                })
                                .then(data => {
                                    setMesAnio(data.mes_anio);
                                    setDia(data.dia);
                                    if (form.tipo == '1' || form.tipo == '4') {
                                        setPromocional(data.flier);
                                    } else {
                                        setPromocional(form.promocional);
                                    }
                                });
                        }
                    }
                });
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/asesor', { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setAsesores(data.rows);
                });
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/paises')
                .then((res) => {
                    return res.json()
                })
                .then(data => {
                    let paises_arr = [];
                    data.paises.map((actual) => { paises_arr.push({ id: actual.code, nombre: actual.name}) });
                    paises_arr.sort(function (a, b) { return a.nombre.localeCompare(b.nombre) });
                    setPaises(paises_arr);
                });
        }
        if (contador == 0) {
            fetchData();
        }
    }, [contador]);

    const onChange = (evt) => {
        const index_finder = (pregunta) => pregunta.UniqueID == evt.target.name;
        let pregunts = preguntas;
        pregunts[pregunts.findIndex(index_finder)].respuesta = evt.target.value;
        setPreguntas(pregunts);
        setContador(contador + 1);
    }
    const procesar = () => {
        const info = { preguntas: preguntas, UniqueID: formulario.UniqueID };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        setMensaje("Sincronizando información...")
        setStatus(25);
        setContador(contador + 1);
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/submit/formulario', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                if (data.codigo == 200) {
                    setStatus(30);
                    setContador(contador + 1);
                    let llenado_id = data.llenado_id;
                    if (formulario.tipo == '1') {
                        const index_finder = (asesor) => (asesor.Nombres + " " + asesor.Apellidos) == preguntas[7].respuesta;
                        const id_asesor = asesores[asesores.findIndex(index_finder)].UniqueID;
                        const email_asesor = asesores[asesores.findIndex(index_finder)].email;
                        var today = new Date();
                        const info =
                        {
                            participantes: [
                                {
                                    No: 0,
                                    identificacion: "PENDIENTE",
                                    Nombres: preguntas[1].respuesta,
                                    Apellidos: preguntas[2].respuesta,
                                    Puesto: preguntas[4].respuesta,
                                    Correo: preguntas[0].respuesta,
                                    Tel: preguntas[5].respuesta,
                                    Celular: "PENDIENTE",
                                    Pais: preguntas[6].respuesta
                                }
                            ],
                            Agremiado: false,
                            Inversion: "0",
                            Fecha_Max_FACT: today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate(),
                            Nombre_FACT: "CF",
                            NIT: "CF",
                            Direccion: "Ciudad",
                            Correo_FACT: preguntas[0].respuesta,
                            Responsable: preguntas[1].respuesta + ' ' + preguntas[2].respuesta,
                            Correo_RESP: preguntas[0].respuesta,
                            Tel_RESP: preguntas[5].respuesta,
                            Responsable_PAGO: preguntas[1].respuesta + ' ' + preguntas[2].respuesta,
                            Correo_RP: preguntas[0].respuesta,
                            Tel_RP: preguntas[5].respuesta,
                            Comentario: "INSCRIPCIÓN DE CORTESÍA",
                            Evento_Unique_ID: formulario.evento_UniqueID,
                            Forma_Pago_UniqueID: "15",
                            Moneda_Unique_ID: 8,
                            moneda: "Q",
                            Asesor_UniqueID: id_asesor,
                            empresa: preguntas[3].respuesta
                        };
                        const requestOptions = {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(info)
                        };
                        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/inscribir', requestOptions)
                            .then((res) => {
                                return res.json();
                            })
                            .then(data => {
                                if (data.codigo == 200) {
                                    setStatus(45);
                                    setContador(contador + 1);
                                    let id_inscripcion = data.id;
                                    let detalle_inscripcion = data.inscritos[0];
                                    const info = { llenado: llenado_id, formulario: formulario.UniqueID, detalle_inscripcion: detalle_inscripcion, inscripcion: id_inscripcion };
                                    const requestOptions = {
                                        method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json'
                                        },
                                        body: JSON.stringify(info)
                                    };
                                    fetch(process.env.REACT_APP_DEFAULT_URL + '/api/enlazar/formulario', requestOptions)
                                        .then((res) => {
                                            return res.json();
                                        })
                                        .then(data => {
                                            if (data.codigo == 200) {
                                                setStatus(60);
                                                setContador(contador + 1);
                                                const info = { UniqueID: id_inscripcion };
                                                const requestOptions = {
                                                    method: 'POST',
                                                    headers: {
                                                        'Content-Type': 'application/json'
                                                    },
                                                    body: JSON.stringify(info)
                                                };
                                                fetch(process.env.REACT_APP_DEFAULT_URL + '/api/update/inscripcion/confirmar_pago', requestOptions)
                                                    .then((res) => {
                                                        return res.json();
                                                    })
                                                    .then(data => {
                                                        if (data.codigo == 200) {
                                                            setStatus(70);
                                                            setContador(contador + 1);
                                                            const info = { email: preguntas[0].respuesta, titulo: formulario.titulo, nombres: preguntas[1].respuesta, promocional: formulario.promocional, enlace: process.env.REACT_APP_SELF_URL + '/inscripcion/public/ver/' + id_inscripcion, dia: dia, mes_anio: mesAnio, email_asesor: email_asesor };
                                                            const requestOptions = {
                                                                method: 'POST',
                                                                headers: {
                                                                    'Content-Type': 'application/json'
                                                                },
                                                                body: JSON.stringify(info)
                                                            };
                                                            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/confirmar/formulario/cortesia', requestOptions)
                                                                .then((res) => {
                                                                    return res.json();
                                                                })
                                                                .then(data => {
                                                                    if (data.codigo == 200) {
                                                                        setStatus(100);
                                                                        setMensaje('Respuestas almacenadas exitosamente');
                                                                        operacionExito();
                                                                    } else {
                                                                        setMensaje('Ocurrió un error al confirmar el formulario: ' + data.respuesta);
                                                                        operacionError();
                                                                    }
                                                                });
                                                        } else {
                                                            setMensaje('Ocurrió un error al validar la inscripción');
                                                            operacionError();
                                                        }
                                                    });
                                            } else {
                                                setMensaje('Ocurrió un error al validar la inscripción');
                                                operacionError();
                                            }
                                        });
                                } else {
                                    setMensaje('Ocurrió un error al inscribir');
                                    operacionError();
                                }
                            });
                    } else if (formulario.tipo == '4') {
                        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read_id/evento/' + formulario.evento_UniqueID, { cache: "no-cache" })
                            .then((res) => {
                                return res.json();
                            })
                            .then(data => {
                                if (data.rows.length > 0) {
                                    setStatus(35);
                                    setContador(contador + 1);
                                    let precio = data.rows[0].precio_no_agremiados;
                                    const index_finder = (asesor) => (asesor.Nombres + " " + asesor.Apellidos) == preguntas[7].respuesta;
                                    const id_asesor = asesores[asesores.findIndex(index_finder)].UniqueID;
                                    const email_asesor = asesores[asesores.findIndex(index_finder)].email;
                                    var today = new Date();
                                    const info =
                                    {
                                        participantes: [
                                            {
                                                No: 0,
                                                identificacion: "PENDIENTE",
                                                Nombres: preguntas[1].respuesta,
                                                Apellidos: preguntas[2].respuesta,
                                                Puesto: preguntas[4].respuesta,
                                                Correo: preguntas[0].respuesta,
                                                Tel: preguntas[5].respuesta,
                                                Celular: "PENDIENTE",
                                                Pais: preguntas[6].respuesta
                                            }
                                        ],
                                        Agremiado: false,
                                        Inversion: precio,
                                        Fecha_Max_FACT: today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate(),
                                        Nombre_FACT: "CF",
                                        NIT: "CF",
                                        Direccion: "Ciudad",
                                        Correo_FACT: preguntas[0].respuesta,
                                        Responsable: preguntas[1].respuesta + ' ' + preguntas[2].respuesta,
                                        Correo_RESP: preguntas[0].respuesta,
                                        Tel_RESP: preguntas[5].respuesta,
                                        Responsable_PAGO: preguntas[1].respuesta + ' ' + preguntas[2].respuesta,
                                        Correo_RP: preguntas[0].respuesta,
                                        Tel_RP: preguntas[5].respuesta,
                                        Comentario: "FICHA PENDIENTE DE COBRO",
                                        Evento_Unique_ID: formulario.evento_UniqueID,
                                        Forma_Pago_UniqueID: "14",
                                        Moneda_Unique_ID: 8,
                                        moneda: "Q",
                                        Asesor_UniqueID: id_asesor,
                                        empresa: preguntas[3].respuesta
                                    };
                                    const requestOptions = {
                                        method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json'
                                        },
                                        body: JSON.stringify(info)
                                    };

                                    fetch(process.env.REACT_APP_DEFAULT_URL + '/api/inscribir', requestOptions)
                                        .then((res) => {
                                            return res.json();
                                        })
                                        .then(data => {
                                            if (data.codigo == 200) {
                                                setStatus(50);
                                                setContador(contador + 1);
                                                let id_inscripcion = data.id;
                                                let detalle_inscripcion = data.inscritos[0];
                                                const info = { llenado: llenado_id, formulario: formulario.UniqueID, detalle_inscripcion: detalle_inscripcion, inscripcion: id_inscripcion };
                                                const requestOptions = {
                                                    method: 'POST',
                                                    headers: {
                                                        'Content-Type': 'application/json'
                                                    },
                                                    body: JSON.stringify(info)
                                                };
                                                fetch(process.env.REACT_APP_DEFAULT_URL + '/api/enlazar/formulario', requestOptions)
                                                    .then((res) => {
                                                        return res.json();
                                                    })
                                                    .then(data => {
                                                        if (data.codigo == 200) {
                                                            setStatus(60);
                                                            setContador(contador + 1);
                                                            const info = { email: preguntas[0].respuesta, titulo: formulario.titulo, nombres: preguntas[1].respuesta, promocional: formulario.promocional, enlace: process.env.REACT_APP_SELF_URL + '/inscripcion/public/ver/' + id_inscripcion, enlace_seguimiento: process.env.REACT_APP_SELF_URL + '/inscripcion/seguimiento/' + id_inscripcion, dia: dia, mes_anio: mesAnio, email_asesor: email_asesor };
                                                            const requestOptions = {
                                                                method: 'POST',
                                                                headers: {
                                                                    'Content-Type': 'application/json'
                                                                },
                                                                body: JSON.stringify(info)
                                                            };
                                                            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/confirmar/formulario/pagado', requestOptions)
                                                                .then((res) => {
                                                                    return res.json();
                                                                })
                                                                .then(data => {
                                                                    if (data.codigo == 200) {
                                                                        setStatus(100);
                                                                        setMensaje('Respuestas almacenadas exitosamente');
                                                                        operacionExito();
                                                                    } else {
                                                                        setMensaje('Ocurrió un error al confirmar el formulario: ' + data.respuesta);
                                                                        operacionError();
                                                                    }
                                                                });
                                                        } else {
                                                            setMensaje('Ocurrió un error al validar la inscripción');
                                                            operacionError();
                                                        }
                                                    });
                                            } else {
                                                setMensaje('Ocurrió un error al inscribir');
                                                operacionError();
                                            }
                                        });
                                }
                            });
                    } else {
                        const info = { email: preguntas[0].respuesta, titulo: formulario.titulo, nombres: preguntas[1].respuesta, promocional: formulario.promocional };
                        const requestOptions = {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(info)
                        };
                        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/confirmar/formulario', requestOptions)
                            .then((res) => {
                                return res.json();
                            })
                            .then(data => {
                                if (data.codigo == 200) {
                                    setStatus(100);
                                    setMensaje('Respuestas almacenadas exitosamente');
                                    operacionExito();
                                } else {
                                    setMensaje('Ocurrió un error al confirmar el formulario: ' + data.respuesta);
                                    operacionError();
                                }
                            });
                    }
                } else {
                    setMensaje('Ocurrió un error al almacenar las respuestas: ' + data.respuesta);
                    operacionError();
                }
            });
    }
    const submit = (evt) => {
        evt.preventDefault();
        setMensaje('Validando información');
        cargar();
        setStatus(0);
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/formulario/report/' + formulario.UniqueID, { cache: "no-cache" })
            .then((res) => {
                return res.json();
            })
            .then(data => {
                setStatus(25);
                if (data.salida != null) {
                    if (data.salida.length == 0) {
                        procesar();
                    } else {
                        for (let i = 0; i < data.salida.length; i++) {
                            if (preguntas[0].respuesta == data.salida[i].respuestas[0].texto) {
                                setMensaje("¡Correo electrónico registrado!\n Existe un registro con el correo " + preguntas[0].respuesta);
                                operacionError();
                            } else if (i == (data.salida.length - 1)) {
                                procesar();
                            }
                        }
                    }
                } else {
                    procesar();
                }
            });
    }
    const chequear = (evt) => {
        setChecked(evt.target.checked);
    }
    const cargar = () => {
        setExito('none');
        setError('none');
        setCargando('block');
        setDetalle('none');
        setStatus(0);
        setContador(contador + 1);
    }
    const operacionExito = () => {
        setExito('block');
        setError('none');
        setCargando('none');
        setDetalle('none');
        setStatus(0);
        setContador(0);
    }
    const operacionError = () => {
        setExito('none');
        setError('block');
        setCargando('none');
        setDetalle('none');
        setStatus(0);
        setContador(contador + 1);
    }
    const goBack = () => {
        setExito('none');
        setError('none');
        setCargando('none');
        setDetalle('block');
        setStatus(0);
        setContador(contador + 1);
    }
    return (
        <Container style={{ fontFamily: 'Montserrat, sans-serif' }}>
            <Row style={{ padding: '5%' }}>
                <Col xs={12} md={12} lg={6}>
                    <Row style={{ display: 'grid', placeItems: 'center' }}>
                        <img style={{ width: '80%', objectFit: 'contain' }} src={'https://escuela-bancaria.s3.us-east-2.amazonaws.com/Logotipos+Finales+ABG+2022-01.png'} />
                    </Row>
                    <Row>
                        <h1 style={{ color: 'black', fontWeight: 'bold', textAlign: 'center' }}>{formulario.titulo}</h1>
                    </Row>
                </Col>
                <Col xs={12} md={12} lg={6}>
                    <img style={{ width: '100%', objectFit: 'contain' }} src={promocional} />
                </Col>
            </Row>
            <Row style={{ display: detalle }}>
                <Container>
                    <Row>
                        <Form onSubmit={submit}>
                            <Row style={{ marginBottom: '15px' }}>
                                {preguntas.map((pregunta, index) =>
                                    ((index == 1 || index == 2) && pregunta.tipo_pregunta_UniqueID == '1') &&
                                    <Col xs={6} md={6} lg={6} style={{ backgroundColor: 'transparent', color: 'white', borderRadius: '15px', marginBottom: '15px' }} >
                                        <Form.Group className="mb-3" controlId={pregunta.UniqueID}>
                                            <Form.Label style={{ color: '#1e3d52', fontWeight: 'bold', backgroundColor: 'transparent' }}>{pregunta.pregunta}</Form.Label>
                                            {index > 0 && <Form.Control size="lg" type="text" readOnly required placeholder="Ingrese lo solicitado " name={pregunta.UniqueID} onChange={onChange} value={pregunta.respuesta} />}
                                            {index == 0 && <Form.Control size="lg" type="email" readOnly required placeholder="Ingrese lo solicitado " name={pregunta.UniqueID} onChange={onChange} value={pregunta.respuesta} />}
                                        </Form.Group>
                                    </Col>
                                )}
                                {preguntas.map((pregunta, index) =>
                                    ((index == 0) && pregunta.tipo_pregunta_UniqueID == '1') &&
                                    <Col xs={12} md={12} lg={12}>
                                        <Form.Group className="mb-3" controlId={pregunta.UniqueID}>
                                            <Form.Label style={{ color: '#1e3d52', fontWeight: 'bold' }}>{pregunta.pregunta}</Form.Label>
                                            {index > 0 && <Form.Control size="lg" type="text" readOnly required placeholder="Ingrese lo solicitado " name={pregunta.UniqueID} onChange={onChange} value={pregunta.respuesta} />}
                                            {index == 0 && <Form.Control size="lg" type="email" readOnly required placeholder="Ingrese lo solicitado " name={pregunta.UniqueID} onChange={onChange} value={pregunta.respuesta} />}
                                        </Form.Group>
                                    </Col>
                                )}
                            </Row>
                            {preguntas.map((pregunta, index) =>
                                (index > 2) &&
                                <Row style={{ backgroundColor: 'transparent', color: 'white', borderRadius: '15px', marginBottom: '15px' }} key={pregunta.UniqueID}>
                                    {pregunta.tipo_pregunta_UniqueID == '1' &&
                                        <Form.Group className="mb-3" controlId={pregunta.UniqueID}>
                                            <Form.Label style={{ color: '#1e3d52', fontWeight: 'bold' }}>{pregunta.pregunta}</Form.Label>
                                            <Form.Control size="lg" type="text" required placeholder="Ingrese lo solicitado " name={pregunta.UniqueID} onChange={onChange} value={pregunta.respuesta} />
                                        </Form.Group>
                                    }
                                    {pregunta.tipo_pregunta_UniqueID == '2' &&
                                        <Form.Group className="mb-3" controlId={pregunta.UniqueID}>
                                            <Form.Label style={{ color: '#1e3d52', fontWeight: 'bold' }}>{pregunta.pregunta}</Form.Label>
                                            {checked == false &&
                                                <Form.Control size="lg" required as="select" aria-label="tipo" name={pregunta.UniqueID} onChange={onChange} value={pregunta.respuesta}>
                                                    <option value="" key="0">Seleccione</option>
                                                    <option value="Si">Si</option>
                                                    <option value="No">No</option>
                                                </Form.Control>
                                            }
                                            {
                                                checked == true && <Form.Control size="lg" type="text" required placeholder="Ingrese lo solicitado " name={pregunta.UniqueID} onChange={onChange} value={pregunta.respuesta} />
                                            }
                                            <Form.Check type="checkbox" label="Otro" style={{ textAlign: 'left', marginTop: '10px' }} checked={checked} onChange={chequear} />
                                        </Form.Group>
                                    }
                                    {pregunta.tipo_pregunta_UniqueID == '3' &&
                                        <Form.Group className="mb-3" controlId={pregunta.UniqueID}>
                                            <Form.Label style={{ color: '#1e3d52', fontWeight: 'bold' }}>{pregunta.pregunta}</Form.Label>
                                            <Form.Control size="lg" as="select" required aria-label="Asesor" name={pregunta.UniqueID} onChange={onChange} value={pregunta.respuesta}>
                                                <option value="" key="0">Seleccione el asesor</option>
                                                {asesores.map(asesor => <option key={asesor.UniqueID} value={asesor.Nombres + " " + asesor.Apellidos}>{asesor.Nombres + " " + asesor.Apellidos}</option>)}
                                            </Form.Control>
                                        </Form.Group>
                                    }
                                    {pregunta.tipo_pregunta_UniqueID == '4' &&
                                        <Form.Group className="mb-3" controlId={pregunta.UniqueID}>
                                            <Form.Label style={{ color: '#1e3d52', fontWeight: 'bold' }}>{pregunta.pregunta}</Form.Label>
                                            <Form.Control size="lg" as="select" required aria-label="Pais" name={pregunta.UniqueID} onChange={onChange} value={pregunta.respuesta}>
                                                <option value="" key="0">Seleccione el país</option>
                                                {paises.map(pais => <option key={pais.id} value={pais.nombre}>{pais.nombre}</option>)}
                                            </Form.Control>
                                        </Form.Group>
                                    }
                                    {pregunta.tipo_pregunta_UniqueID == '5' &&
                                        <Form.Group className="mb-3" controlId={pregunta.UniqueID}>
                                            <Form.Label style={{ color: '#1e3d52', fontWeight: 'bold' }}>{pregunta.pregunta}</Form.Label>
                                            <Form.Control size="lg" as="select" required name={pregunta.UniqueID} onChange={onChange} value={pregunta.respuesta}>
                                                <option value="" key="0">Seleccione</option>
                                                {pregunta.opciones.map((actual, indice) => <option key={indice} value={actual.opcion}>{actual.opcion}</option>)}
                                            </Form.Control>
                                        </Form.Group>
                                    }
                                </Row>
                            )}
                            <Row style={{ marginBottom: '15px' }}>
                                <Button variant="outline-dark" className="font-bold" type="submit">Enviar</Button>
                            </Row>
                        </Form>
                    </Row>
                </Container>
            </Row>
            <Row style={{ display: cargando }}>
                <Alert variant='dark'>
                    <Alert.Heading>Procesando operación</Alert.Heading>
                    <p>
                        Esto puede demorar unos minutos
                    </p>
                    <hr />
                    <p className="mb-0">
                        Por favor espere...<br /><br />
                        {mensaje}
                        <br /><br />
                        <ProgressBar animated now={status} variant='dark' />
                    </p>
                </Alert>
            </Row>
            <Row style={{ display: exito }}>
                <Alert variant='success'>
                    <Alert.Heading>Operación procesada con éxito</Alert.Heading>
                    <p>
                        {mensaje}
                    </p>
                    <Row>
                        <Col xs={8} md={10} lg={10}>
                        </Col>
                        <Col xs={4} md={2} lg={2}>
                            <Row>
                                <Button onClick={goBack} variant='outline-success' >Ok</Button>
                            </Row>
                        </Col>
                    </Row>
                </Alert>
            </Row>
            <Row style={{ display: error }}>
                <Alert variant='danger'>
                    <Alert.Heading>{mensaje}</Alert.Heading>
                    <Row>
                        <Col xs={8} md={10} lg={10}>
                        </Col>
                        <Col xs={4} md={2} lg={2}>
                            <Row>
                                <Button onClick={goBack} variant='outline-danger'>Ok</Button>
                            </Row>
                        </Col>
                    </Row>
                </Alert>
            </Row>
        </Container>
    )
}

export default Formulario