import React, { Component } from 'react';
import { Row, Button, Col, Form, Alert, Container, Accordion, ButtonGroup, DropdownButton, InputGroup } from "react-bootstrap";
import "./InscripcionCliente.css"
import { ModalParticipantes } from './Modal-Participantes/ModalParticipantes';
import { ErrorModal } from '../Utils/ErrorModal/ErrorModal';
import { SuccessModal } from '../Utils/SuccessModal/SuccessModal';
//import DEFAULT_URL from '../../api/url';
//import { SELF_URL } from '../../api/url';
export class InscripcionCliente extends Component {
    state = {
        llevaenlace: false,
        modalShow: false,
        show: false,
        modalError: false,
        modalSucc: false,
        participantes: [
            {
                No: 0,
                identificacion: '',
                Nombres: 'Nuevo Participante',
                Apellidos: '',
                Puesto: '',
                Correo: '',
                Tel: '',
                Celular: ''
            }
        ],
        Agremiado: false,
        Inversion: '',
        Fecha_Max_FACT: '',
        Nombre_FACT: '',
        NIT: '',
        Direccion: '',
        Correo_FACT: '',
        Responsable: '',
        Correo_RESP: '',
        Tel_RESP: '',
        Responsable_PAGO: '',
        Correo_RP: '',
        Tel_RP: '',
        Comentario: '',
        Evento_Unique_ID: '',
        Forma_Pago_UniqueID: '',
        Moneda_Unique_ID: 8,
        moneda: 'Q',
        eventos: [],
        formas_pago: [],
        asesores: [],
        Mensaje: '',
        Consideracion: '',
        Asesor_UniqueID: 0,
        enlace: '',
        empresa: ''
    }

    componentDidMount() {
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/evento/agenda', {cache: "no-cache"})
            .then((res) => {
                return res.json();
            })
            .then(data => {
                this.setState({ eventos: data.rows })
            });
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/forma_pago', {cache: "no-cache"})
            .then((res) => {
                return res.json();
            })
            .then(data => {
                this.setState({ formas_pago: data.rows })
            });
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/asesor', {cache: "no-cache"})
            .then((res) => {
                return res.json();
            })
            .then(data => {
                this.setState({ asesores: data.rows })
            });
    }
    ocultar = () => {
        this.setState({ modalShow: false });
        this.setState({ modalError: false });
        this.setState({ modalSucc: false });

    }
    addparticipante = () => {
        const newParticipante = { No: this.state.participantes.length, Nombres: 'Nuevo Participante', Apellidos: '', Puesto: '', Correo: '', Tel: '', Celular: '' };
        this.setState({ participantes: [...this.state.participantes, newParticipante] });
    }
    deleteparticipante = (No) => {
        var new_array = this.state.participantes.filter(participante => participante.No !== No)
        if (new_array.length > 0) {
            for (let i = 0; i < new_array.length; i++) {
                new_array[i].No = i;
            }
            this.setState({ participantes: new_array });
            return 0;
        } else {
            var nuevo_array = this.state.participantes;
            nuevo_array[0].Nombres = 'Nuevo Participante';
            nuevo_array[0].Apellidos = '';
            nuevo_array[0].Puesto = '';
            nuevo_array[0].Correo = '';
            nuevo_array[0].Tel = '';
            nuevo_array[0].Celular = '';
            this.setState({ participantes: nuevo_array });
        }
    }

    eventocambio = (evt) => {
        const arr_name = evt.target.name.split("-");
        var new_array = this.state.participantes;
        switch (arr_name[0]) {
            case "nombres":
                new_array[arr_name[1]].Nombres = evt.target.value;
                this.setState({ participantes: new_array });
                break;
            case "apellidos":
                new_array[arr_name[1]].Apellidos = evt.target.value;
                this.setState({ participantes: new_array });
                break;
            case "puesto":
                new_array[arr_name[1]].Puesto = evt.target.value;
                this.setState({ participantes: new_array });
                break;
            case "correo":
                new_array[arr_name[1]].Correo = evt.target.value;
                this.setState({ participantes: new_array });
                break;
            case "telefono":
                new_array[arr_name[1]].Tel = evt.target.value;
                this.setState({ participantes: new_array });
                break;
            case "celular":
                new_array[arr_name[1]].Celular = evt.target.value;
                this.setState({ participantes: new_array });
                break;
            case "identificacion":
                new_array[arr_name[1]].identificacion = evt.target.value;
                this.setState({ participantes: new_array });
                break;
            default:
                break;
        }
    }
    cambioencabezado = (evt) => {
        if (evt.target.name === "Agremiado") {
            this.setState({ Agremiado: evt.target.checked });

        } else {
            this.setState({ [evt.target.name]: evt.target.value });
        }
    }
    eventoenvio = (evt) => {
        evt.preventDefault();
        const validar_participantes = this.state.participantes.filter(participante => participante.Nombres === '' || participante.Nombres === 'Nuevo Participante' || participante.Apellidos === '' || participante.Celular === '' || participante.Correo === '' || participante.Puesto === '' || participante.Tel === '' || participante.identificacion === '');
        if (validar_participantes.length > 0) {
            this.setState({ Mensaje: 'Por favor, revise la información que ingresó en el apartado de participantes' });
            this.setState({ Consideracion: 'Los participantes se ingresan en Registrar/Participantes' })
            this.setState({ modalError: true });
        } else {
            const info =
            {
                participantes: this.state.participantes,
                Agremiado: this.state.Agremiado,
                Inversion: this.state.Inversion,
                Fecha_Max_FACT: this.state.Fecha_Max_FACT,
                Nombre_FACT: this.state.Nombre_FACT,
                NIT: this.state.NIT,
                Direccion: this.state.Direccion,
                Correo_FACT: this.state.Correo_FACT,
                Responsable: this.state.Responsable,
                Correo_RESP: this.state.Correo_RESP,
                Tel_RESP: this.state.Tel_RESP,
                Responsable_PAGO: this.state.Responsable_PAGO,
                Correo_RP: this.state.Correo_RP,
                Tel_RP: this.state.Tel_RP,
                Comentario: this.state.Comentario,
                Evento_Unique_ID: this.state.Evento_Unique_ID,
                Forma_Pago_UniqueID: this.state.Forma_Pago_UniqueID,
                Moneda_Unique_ID: this.state.Moneda_Unique_ID,
                moneda: this.state.moneda,
                Asesor_UniqueID: this.state.Asesor_UniqueID,
                empresa: this.state.empresa
            };
            //console.log(info);
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(info)
            };
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/inscribir', requestOptions)
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    if (data.codigo == 200) {
                        this.clean_state();
                        this.setState({ Mensaje: '¡Información almacenada exitosamente!' });
                        this.setState({ Consideracion: 'Puede verificar su inscripción ' });
                        this.setState({ enlace: process.env.REACT_APP_SELF_URL + '/inscripcion/ver/' + data.id });
                        this.setState({ llevaenlace: true });
                        this.setState({ modalSucc: true });
                    } else {
                        //console.log(data.respuesta);
                        this.setState({ Mensaje: 'Ocurrió un error al intentar almacenar la inscripción' });
                        this.setState({ Consideracion: '' });
                        this.setState({ modalError: true });
                    }
                });
        }
    }

    seleccion_moneda = (evt) => {
        if (evt.target.checked) {
            this.setState({ moneda: '$' });
            this.setState({ Moneda_Unique_ID: 7 })
        } else {
            this.setState({ moneda: 'Q' });
            this.setState({ Moneda_Unique_ID: 8 });
        }
    }

    clean_state = () => {
        this.setState({
            modalShow: false,
            show: false,
            participantes: [
                {
                    No: 0,
                    Nombres: 'Nuevo Participante',
                    Apellidos: '',
                    Puesto: '',
                    Correo: '',
                    Tel: '',
                    Celular: ''
                }
            ],
            Agremiado: false,
            Inversion: '',
            Fecha_Max_FACT: '',
            Nombre_FACT: '',
            NIT: '',
            Direccion: '',
            Correo_FACT: '',
            Responsable: '',
            Correo_RESP: '',
            Tel_RESP: '',
            Responsable_PAGO: '',
            Correo_RP: '',
            Tel_RP: '',
            Comentario: '',
            Evento_Unique_ID: '',
            Forma_Pago_UniqueID: '',
            Moneda_Unique_ID: 8,
            moneda: 'Q',
            Mensaje: '',
            Consideracion: '',
            Asesor_UniqueID: 0,
            empresa: ''
        });
    }
    render() {
        return (
            <div className="contenedor-inscripcion-cliente poster-info">
                <Form onSubmit={this.eventoenvio}>
                    <Container>
                        <Row >
                            <Col xs={12} md={6}>
                                <div className="columna-formulario " style={{ padding: '2%', textAlign: 'center' }}>
                                    <h1 className="font-bold blanco">ASOCIACIÓN BANCARIA DE GUATEMALA</h1>
                                    <h2 className="font-bold blanco" style={{ marginTop: '5%', marginBottom: '5%' }}>FORMULARIO DE INSCRIPCIÓN</h2>
                                    <Form.Group className="mb-3" controlId="Agremiado" style={{ color: 'white' }}>
                                        <Form.Check
                                            type="switch"
                                            id="custom-switch"
                                            className="font-normal"
                                            name="Agremiado"
                                            label="Agremiados"
                                            style={{ textAlign: 'left' }}
                                            onChange={this.cambioencabezado}
                                            value={this.state.Agremiado}

                                        />
                                    </Form.Group>
                                    <Accordion defaultActiveKey="0" className="bg-success" style={{ marginTop: '5%' }}>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header className="font-bold">INFORMACIÓN DEL EVENTO</Accordion.Header>
                                            <Accordion.Body className="font-normal">
                                                <Form.Group className="mb-3" controlId="Forma_Pago_UniqueID">
                                                    <Form.Label>Asesor</Form.Label>
                                                    <Form.Control as="select" required aria-label="Asesor" name="Asesor_UniqueID" onChange={this.cambioencabezado} value={this.state.Asesor_UniqueID}>
                                                        <option value="" key="0">Seleccione el asesor contactado</option>
                                                        {this.state.asesores.map(asesor => <option key={asesor.UniqueID} value={asesor.UniqueID}>{asesor.Nombres + " " + asesor.Apellidos}</option>)}
                                                    </Form.Control>
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="Inversion">
                                                    <Form.Label>Inversión</Form.Label>
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Text>{this.state.moneda}</InputGroup.Text>
                                                        <Form.Control required name="Inversion" placeholder="Ingrese el monto de inversión" onChange={this.cambioencabezado} value={this.state.Inversion} />
                                                    </InputGroup>
                                                    <Form.Check
                                                        type="switch"
                                                        id="switch-dolar"
                                                        className="font-normal"
                                                        label="$ (USD)"
                                                        onChange={this.seleccion_moneda}
                                                        style={{ textAlign: 'left' }}
                                                    />
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="Evento_Unique_ID">
                                                    <Form.Label>Evento</Form.Label>
                                                    <Form.Control as="select" required aria-label="Evento" name="Evento_Unique_ID" onChange={this.cambioencabezado} value={this.state.Evento_Unique_ID} >
                                                        <option value="" key="0">Seleccione el evento</option>
                                                        {this.state.eventos.map(evento => <option key={evento.UniqueID} value={evento.UniqueID}>{evento.Nombre + "   (" + evento.Descripcion + ")"}</option>)}
                                                    </Form.Control>
                                                </Form.Group>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header className="font-bold">DATOS PARA LA FACTURA ELECTRÓNICA</Accordion.Header>
                                            <Accordion.Body className="font-normal">
                                                <Form.Group className="mb-3" controlId="Fecha_Max_FACT">
                                                    <Form.Label>Fecha máxima que reciben facturas</Form.Label>
                                                    <Form.Control required type="date" name="Fecha_Max_FACT" placeholder="Seleccione la fecha" onChange={this.cambioencabezado} value={this.state.Fecha_Max_FACT} />
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="Nombre_FACT">
                                                    <Form.Label>Nombre para la factura</Form.Label>
                                                    <Form.Control required type="text" name="Nombre_FACT" placeholder="Consumidor Final" onChange={this.cambioencabezado} value={this.state.Nombre_FACT} />
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="NIT">
                                                    <Form.Label>NIT</Form.Label>
                                                    <Form.Control required type="text" placeholder="CF" name="NIT" onChange={this.cambioencabezado} value={this.state.NIT} />
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="Direccion">
                                                    <Form.Label>Dirección</Form.Label>
                                                    <Form.Control required type="text" placeholder="Ciudad" name="Direccion" onChange={this.cambioencabezado} value={this.state.Direccion} />
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="Correo_FACT">
                                                    <Form.Label>Correo para enviar factura electrónica</Form.Label>
                                                    <Form.Control required type="text" placeholder="Por favor ingrese el correo" name="Correo_FACT" onChange={this.cambioencabezado} value={this.state.Correo_FACT} />
                                                </Form.Group>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header className="font-bold">CONTACTO Y FORMA DE PAGO</Accordion.Header>
                                            <Accordion.Body className="font-normal">
                                                <Row>
                                                    <Col xs={12} md={12}>
                                                        <Form.Group className="mb-3" controlId="Responsable">
                                                            <Form.Label>Empresa</Form.Label>
                                                            <Form.Control required type="text" placeholder="Ingrese la empresa" name="empresa" onChange={this.cambioencabezado} value={this.state.empresa} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} md={12}>
                                                        <Form.Group className="mb-3" controlId="Responsable">
                                                            <Form.Label>Responsable de la inscripción</Form.Label>
                                                            <Form.Control required type="text" placeholder="Ingrese el responsable" name="Responsable" onChange={this.cambioencabezado} value={this.state.Responsable} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} md={8}>
                                                        <Form.Group className="mb-3" controlId="Correo_RESP">
                                                            <Form.Label>Correo</Form.Label>
                                                            <Form.Control required type="text" placeholder="Ingrese el correo" name="Correo_RESP" onChange={this.cambioencabezado} value={this.state.Correo_RESP} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} md={4}>
                                                        <Form.Group className="mb-3" controlId="Tel_RESP">
                                                            <Form.Label>Tel.</Form.Label>
                                                            <Form.Control required type="text" placeholder="Ingrese el teléfono" name="Tel_RESP" onChange={this.cambioencabezado} value={this.state.Tel_RESP} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} md={12}>
                                                        <Form.Group className="mb-3" controlId="Responsable_PAGO">
                                                            <Form.Label>Persona responsable del trámite de Pago</Form.Label>
                                                            <Form.Control required type="text" placeholder="Ingrese el responsable" name="Responsable_PAGO" onChange={this.cambioencabezado} value={this.state.Responsable_PAGO} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} md={8}>
                                                        <Form.Group className="mb-3" controlId="Correo_RP">
                                                            <Form.Label>Correo</Form.Label>
                                                            <Form.Control required type="email" placeholder="Ingrese el correo" name="Correo_RP" onChange={this.cambioencabezado} value={this.state.Correo_RP} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} md={4}>
                                                        <Form.Group className="mb-3" controlId="Tel_RP">
                                                            <Form.Label>Tel.</Form.Label>
                                                            <Form.Control required type="text" placeholder="Ingrese el teléfono" name="Tel_RP" onChange={this.cambioencabezado} value={this.state.Tel_RP} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} md={12}>
                                                        <Form.Group className="mb-3" controlId="Forma_Pago_UniqueID">
                                                            <Form.Label>Indique su forma de pago</Form.Label>
                                                            <Form.Control as="select" required aria-label="Evento" name="Forma_Pago_UniqueID" onChange={this.cambioencabezado} value={this.state.Forma_Pago_UniqueID}>
                                                                <option value="" key="0">Seleccione la forma de pago</option>
                                                                {this.state.formas_pago.map(forma_pago => <option key={forma_pago.UniqueID} value={forma_pago.UniqueID}>{forma_pago.Nombre}</option>)}
                                                            </Form.Control>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} md={12}>
                                                        <Form.Group className="mb-3" controlId="Comentario">
                                                            <Form.Label>Comentario sobre el pago</Form.Label>
                                                            <Form.Control as="textarea" rows={3} style={{ resize: 'none' }} name="Comentario" onChange={this.cambioencabezado} value={this.state.Comentario} />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                                <ButtonGroup>
                                    <DropdownButton as={ButtonGroup} title="Registrar" className="font-bold" id="bg-nested-dropdown" variant="light">
                                        <Button variant="light" className="font-bold" onClick={() => this.setState({ modalShow: true })} style={{ left: '0%' }}>Participantes</Button>
                                        <Button variant="light" className="font-bold" type="submit">Inscripción</Button>
                                    </DropdownButton>
                                </ButtonGroup>
                            </Col>
                            <Col xs={12} md={6}>
                                <div className="informacion-derecha">
                                    <>
                                        <Alert show={this.state.show} variant="danger">
                                            <Alert.Heading>Información de participantes errónea</Alert.Heading>
                                            <p>
                                                Por favor, revise la información que ingresó en el apartado de participantes
                                            </p>
                                            <hr />
                                            <p>Los participantes se ingresan en Registrar/Participantes</p>
                                            <div className="d-flex justify-content-end">
                                                <Button onClick={() => this.setState({ show: false })} variant="outline-danger">
                                                    Cerrar
                                                </Button>
                                            </div>
                                        </Alert>
                                    </>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <ModalParticipantes
                        show={this.state.modalShow}
                        onHide={this.ocultar}
                        participantes={this.state.participantes}
                        addparticipante={this.addparticipante}
                        deleteparticipante={this.deleteparticipante}
                        eventocambio={this.eventocambio}
                    />
                    <ErrorModal
                        color={"#E0E0E2"}
                        show={this.state.modalError}
                        onHide={this.ocultar}
                        mensaje={this.state.Mensaje}
                        consideracion={this.state.Consideracion}
                    />
                    <SuccessModal
                        color={"#E0E0E2"}
                        show={this.state.modalSucc}
                        onHide={this.ocultar}
                        mensaje={this.state.Mensaje}
                        consideracion={this.state.Consideracion}
                        enlace={this.state.enlace}
                        llevaenlace={this.state.llevaenlace}
                    />
                </Form>
            </div>
        );
    }
}