import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Form,
  Pagination,
  Spinner,
} from "react-bootstrap";
import ActionButton from "./Action";
const helpers = require("./helpers");
const Read = (props) => {
  const [cambios, setCambios] = useState(0);
  const [data, setData] = useState([]);
  const [configuration, setConfiguration] = useState({});
  const [filterData, setFilterData] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [pagesArr, setPagesArr] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    function getData() {
      helpers
        .getData(props.configuration.endpoint)
        .then((data) => {
          if (
            data.data.rows[1] != null &&
            data.data.rows[1].serverStatus != null
          ) {
            setData(data.data.response.rows[0]);
            setFilteredData(data.data.response.rows[0]);
            setPagesArr(helpers.chunkArray(data.data.response.rows[0], 10));
            setConfiguration(props.configuration);
            setCurrentPage(0);
            setLoaded(true);
          } else {
            setData(data.data.rows);
            setFilteredData(data.data.rows);
            setPagesArr(helpers.chunkArray(data.data.rows, 10));
            setConfiguration(props.configuration);
            setCurrentPage(0);
            setLoaded(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    getData();
  }, [props.configuration]);
  useEffect(() => {
    setPagesArr(helpers.chunkArray(filteredData, 10));
  }, [cambios]);
  const onChange = (evt) => {
    setCurrentPage(0);
    let result = [];
    result = data.filter((actual) => props.filterEvt(actual, evt.target.value));
    setFilteredData(result);
    setFilterData(evt.target.value);
    setCambios(cambios + 1);
  };
  return !loaded ? (
    <Container
      style={{ width: "100vw", height: "100vh" }}
      className="d-flex justify-content-center align-items-center"
    >
      <Spinner animation="border" role="status" />
    </Container>
  ) : (
    <Container fluid>
      <Row className="mt-5">
        <Form.Control
          type="text"
          placeholder={configuration.placeHolder}
          value={filterData}
          onChange={onChange}
        />
      </Row>
      <Row className="mt-5">
        <Table
          striped
          bordered
          hover
          variant="light"
          style={{ textAlign: "center" }}
        >
          <thead>
            <tr>
              <th>#</th>
              {configuration.properties &&
                configuration.properties.map((actual, index) => (
                  <th key={"header-" + index}>{actual.label}</th>
                ))}
              {configuration.withActions && <th>Acciones</th>}
            </tr>
          </thead>
          <tbody>
            {pagesArr[currentPage] &&
              pagesArr[currentPage].map((actual, index) => (
                <tr key={"iteration-" + index}>
                  <td>{index + 1}</td>
                  {configuration.properties &&
                    configuration.properties.map((current, indice) => (
                      <td key={"field-" + indice + "-" + index}>
                        {actual[current.field]}
                      </td>
                    ))}
                  {configuration.withActions && (
                    <td>
                      <Container fluid>
                        <Row>
                          {props.configuration.onRead && (
                            <Col xs={4} md={4} lg={4}>
                              <ActionButton
                                configuration={{
                                  type: 1,
                                  url:
                                    props.configuration.onRead +
                                    actual[props.configuration.key],
                                  icon: "eye",
                                  variant: "outline-primary",
                                }}
                              />
                            </Col>
                          )}
                          {props.configuration.onUpdate && (
                            <Col xs={4} md={4} lg={4}>
                              <ActionButton
                                configuration={{
                                  type: 1,
                                  url:
                                    props.configuration.onUpdate +
                                    actual[props.configuration.key],
                                  icon: "pencil",
                                  variant: "outline-warning",
                                }}
                              />
                            </Col>
                          )}
                          {props.configuration.onDelete && (
                            <Col xs={4} md={4} lg={4}>
                              <ActionButton
                                configuration={{
                                  type: 2,
                                  url:
                                    props.configuration.onDelete +
                                    actual[props.configuration.key],
                                  icon: "trash",
                                  variant: "outline-danger",
                                  mensaje:
                                    props.configuration.confirmationMensage,
                                }}
                                action={props.actionAfterDelete}
                              />
                            </Col>
                          )}
                        </Row>
                      </Container>
                    </td>
                  )}
                </tr>
              ))}
          </tbody>
        </Table>
      </Row>
      <Row className="mt-5" style={{ width: "75vw", overflowX: "scroll" }}>
        <Pagination size="md">
          {pagesArr.map((actual, index) => (
            <Pagination.Item
              key={index}
              active={index == currentPage}
              onClick={() => {
                setCurrentPage(index);
                setCambios(cambios + 1);
              }}
            >
              {index + 1}
            </Pagination.Item>
          ))}
        </Pagination>
      </Row>
    </Container>
  );
};

export default Read;
