import React, { useState, useEffect } from 'react'
import { Alert, Spinner, Row, Button, Col, Container, Form } from "react-bootstrap";
import "./style.css"
import axios from 'axios';
//import DEFAULT_URL from '../../api/url';
const FormTema = (props) => {
    const [contador, setContador] = useState(0);
    const [nombre, setNombre] = useState('');
    const [mensaje, setMensaje] = useState('');
    const [exito, setExito] = useState('none');
    const [formulario, setFormulario] = useState('block');
    const [error, setError] = useState('none');
    const [cargando, setCargando] = useState('none');
    const [tiposInfo, setTiposInfo] = useState([]);
    const [informacion, setInformacion] = useState([]);
    const [descripcion, setDescripcion] = useState('');
    const [img, setImg] = useState(null);
    useEffect(() => {
        async function fetchData() {
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/informacion-catalogo/tipos/read')
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setTiposInfo(data.salida);
                });
        }
        fetchData();
    }, [contador]);
    const onChange = (evt) => {
        if (evt.target.name == "nombre") {
            setNombre(evt.target.value);
        } else if (evt.target.name == 'descripcion') {
            setDescripcion(evt.target.value);
        } else {
            setImg(evt.target.files[0]);
        }
        setContador(contador + 1);
    }
    const cargar = (mensaje) => {
        setExito('none');
        setFormulario('none');
        setError('none');
        setCargando('block');
        setMensaje(mensaje);
        setContador(contador + 1);
    }
    const operacionExito = (mensaje) => {
        setExito('block');
        setFormulario('none');
        setError('none');
        setCargando('none');
        setMensaje(mensaje);
        props.refresh();
        setContador(contador + 1);
    }
    const operacionError = (mensaje) => {
        setExito('none');
        setFormulario('none');
        setError('block');
        setCargando('none');
        setMensaje(mensaje);
        setContador(contador + 1);
    }
    const onSubmit = (evt) => {
        evt.preventDefault();
        if (informacion.length == 0) {
            operacionError('Debes adjuntar información del tema');
        } else {
            if (img != null) {
                const data = new FormData();
                data.append('file', img);
                cargar('Subiendo imagen');
                axios.post(process.env.REACT_APP_DEFAULT_URL+ "/api/files/upload/s3", data, {}).then(res => {
                    if (res.data.codigo == 200) {
                        cargar('Sincronizando base de datos');
                        const info = {
                            nombre: nombre,
                            catalogo: props.Catalogo,
                            foto: res.data.resultado.Location,
                            descripcion: descripcion,
                            informacion: informacion,
                        };
                        const requestOptions = {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(info)
                        };
                        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/temas/create', requestOptions)
                            .then((res) => {
                                return res.json();
                            })
                            .then(data => {
                                if (data.codigo == 200) {
                                    operacionExito('Tema registrado exitosamente');
                                } else {
                                    operacionError('Error al grabar registro en db');
                                }
                            });
                    } else {
                        operacionError('Ocurrió un error al subir la imagen a AWS');
                    }
                });
            } else {
                operacionError('Debes adjuntar una imagen del tema');
            }
        }
    }
    const goBack = () => {
        setExito('none');
        setFormulario('block');
        setError('none');
        setCargando('none');
        setNombre('');
        setInformacion([]);
        setDescripcion('');
        Array.from(document.querySelectorAll("input")).forEach(
            input => (input.value = "")
        );
        setContador(contador + 1);
    }
    const onChangeInfo = (evt) => {
        const arr = evt.target.name.split('-');
        let temp = informacion;
        let indice = parseInt(arr[0]);
        if (arr[1] == "contenido") {
            temp[indice].contenido = evt.target.value;
        } else if (arr[1] == "tipo") {
            temp[indice].tipo = evt.target.value;
        }
        setInformacion(temp);
        setContador(contador + 1);
    }
    return (
        <Container style={{ padding: '5%', border: '0.5px dotted black', borderRadius: '1%' }}>
            <Row style={{ display: formulario }}>
                <Container>
                    <Form onSubmit={onSubmit}>
                        <Row>
                            <h1>Ingresar Tema</h1>
                        </Row>
                        <Row>
                            <Form.Group className="mb-3" controlId="nombre">
                                <Form.Label>Nombre del tema</Form.Label>
                                <Form.Control required type="text" placeholder="Ingrese el nombre del tema" name="nombre" value={nombre} onChange={onChange} />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group className="mb-3" controlId="direccion">
                                <Form.Label>Descripción de la biblioteca</Form.Label>
                                <Form.Control as="textarea" rows={3} style={{ resize: 'none' }} placeholder="Ingrese la descripción de la biblioteca" name="descripcion" value={descripcion} onChange={onChange} />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group style={{ color: 'black' }}>
                                <Form.Label>Foto de biblioteca</Form.Label>
                                <Form.Control type="file" onChange={onChange} name="img" accept="image/*" />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Container fluid>
                                <Row style={{ marginTop: '25px', marginBottom: '25px' }}>
                                    <h2>Información del tema</h2>
                                </Row>
                                {informacion.map((actual, index) => <Row style={{ marginTop: '25px', marginBottom: '25px' }} key={index}>
                                    <Col xs={12} md={12} lg={4} style={{ marginTop: '15px', marginBottom: '15px' }}>
                                        <h5>Acciones</h5>
                                        <Row>
                                            <Button variant='outline-danger' onClick={() => { informacion.splice(index, 1); setContador(contador + 1); }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3-fill" viewBox="0 0 16 16">
                                                <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                                            </svg>&nbsp;&nbsp;&nbsp;&nbsp;Eliminar información</Button>
                                        </Row>
                                    </Col>
                                    <Col xs={12} md={12} lg={4} style={{ marginTop: '15px', marginBottom: '15px' }}>
                                        <Form.Group>
                                            <Form.Label>Contenido</Form.Label>
                                            <Form.Control as="textarea" rows={3} style={{ resize: 'none' }} placeholder="Ingrese el contenido de la información" name={index + '-contenido'} value={actual.contenido} onChange={onChangeInfo} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={12} lg={4} style={{ marginTop: '15px', marginBottom: '15px' }}>
                                        <Form.Group>
                                            <Form.Label>Seleccione el tipo de información</Form.Label>
                                            <Form.Control as="select" required aria-label="tipo" name={index + '-tipo'} onChange={onChangeInfo} value={actual.tipo}>
                                                <option value="" key="0">Seleccione el tipo de información</option>
                                                {tiposInfo.map(curr => <option key={curr.UniqueID} value={curr.UniqueID}>{curr.Nombre}</option>)}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>)}
                                <Row style={{ marginTop: '25px', marginBottom: '25px' }}>
                                    <Button variant='outline-success' onClick={() => { informacion.push({ contenido: '', tipo: '' }); setContador(contador + 1); }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-square-fill" viewBox="0 0 16 16">
                                        <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                                    </svg>&nbsp;&nbsp;&nbsp;&nbsp;Agregar información</Button>
                                </Row>
                            </Container>
                        </Row>
                        <Row>
                            <Button variant="outline-dark" className="font-bold" type="submit">Guardar</Button>
                        </Row>
                    </Form>
                </Container>
            </Row>
            <Row style={{ display: exito }}>
                <Alert variant='success'>
                    <Alert.Heading>
                        Operación realizada con éxito
                    </Alert.Heading>
                    <p>
                        {mensaje}
                    </p>
                    <hr />
                    <div className="d-flex justify-content-end">
                        <Button variant='outline-success' onClick={goBack}>
                            Ok
                        </Button>
                    </div>
                </Alert>
            </Row>
            <Row style={{ display: error }}>
                <Alert variant='danger'>
                    <Alert.Heading>
                        Ocurrió un error al ingresar el tema
                    </Alert.Heading>
                    <p>
                        {mensaje}
                    </p>
                    <hr />
                    <div className="d-flex justify-content-end">
                        <Button variant='outline-danger' onClick={goBack}>
                            Ok
                        </Button>
                    </div>
                </Alert>
            </Row>
            <Row style={{ display: cargando }}>
                <Alert variant='dark'>
                    <Alert.Heading>
                        <Spinner animation="border" role="status">
                        </Spinner>&nbsp;&nbsp;&nbsp;
                        Ingresando información
                    </Alert.Heading>
                    <p>
                        {mensaje}
                    </p>
                    <hr />
                    <p>
                        Por favor espere...
                    </p>
                </Alert>
            </Row>
        </Container>)
}

export default FormTema