import React, { useState, useEffect } from "react";
import {
  Modal,
  Row,
  Popover,
  OverlayTrigger,
  Form,
  Col,
  Alert,
  Container,
  Tabs,
  Tab,
  Button,
  ProgressBar,
} from "react-bootstrap";
//import DEFAULT_URL from '../../../../../api/url';
import axios from "axios";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
const popover = (
  <Popover id="popover-basic">
    <Popover.Header as="h3">¡Listo!</Popover.Header>
    <Popover.Body>Enlace copiado exitosamente.</Popover.Body>
  </Popover>
);
const ModalPatrocinadores = (props) => {
  const [patrocinadores, setPatrocinadores] = useState([]);
  const [contador, setContador] = useState(0);
  const [mensaje, setMensaje] = useState("");
  const [nombre, setNombre] = useState([]);
  const [url, setUrl] = useState("");
  const [inversion, setInversion] = useState(0);
  const [asesores, setAsesores] = useState([]);
  const [asesor, setAsesor] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileApp, setSelectedFileApp] = useState(null);
  const [cargando, setCargando] = useState("none");
  const [detalle, setDetalle] = useState("block");
  const [error, setError] = useState("none");
  const [exito, setExito] = useState("none");
  const [confirmacion, setConfirmacion] = useState("none");
  const [status, setStatus] = useState(0);
  const [edit, setEdit] = useState("none");
  const [selectedId, setSelectedId] = useState("");
  const [promocional, setPromocional] = useState("");
  const [promocionalApp, setPromocionalApp] = useState("");
  const [tipos] = useState([
    "DIAMANTE",
    "ORO",
    "PLATA",
    "MEDIA PARTNER",
    "APOYO INSTITUCIONAL",
  ]);
  const [tipo, setTipo] = useState("");
  const [cambios, setCambios] = useState(0);
  useEffect(() => {
    async function fetchData() {
      fetch(
        process.env.REACT_APP_DEFAULT_URL +
          "/api/read/parocinador/" +
          props.evento_uniqueid,
        { cache: "no-cache" }
      )
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setPatrocinadores(data.rows);
        });
      fetch(process.env.REACT_APP_DEFAULT_URL + "/api/read/asesor", {
        cache: "no-cache",
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setAsesores(data.rows);
        });
    }
    fetchData();
  }, [contador]);
  useEffect(() => {}, [cambios]);
  const entering = () => {
    setNombre("");
    setInversion(0);
    setUrl("");
    setAsesor("");
    setSelectedFile(null);
    setSelectedFileApp(null);
    setPromocional("");
    setPromocionalApp("");
    setTipo("");
    setCambios(cambios + 1);
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );
  };
  const onChange = (evt) => {
    if (evt.target.name == "nombre") {
      setNombre(evt.target.value);
    } else if (evt.target.name == "inversion") {
      setInversion(evt.target.value);
    } else if (evt.target.name == "asesor") {
      setAsesor(evt.target.value);
    } else if (evt.target.name == "tipo") {
      if (
        evt.target.value == "MEDIA PARTNER" ||
        evt.target.value == "APOYO INSTITUCIONAL"
      ) {
        setInversion(0);
      }
      setTipo(evt.target.value);
    } else {
      setUrl(evt.target.value);
    }
    setCambios(cambios + 1);
  };
  const goBack = () => {
    setCargando("none");
    setDetalle("block");
    setError("none");
    setExito("none");
    setConfirmacion("none");
    setEdit("none");
    setStatus(0);
    setNombre("");
    setInversion(0);
    setUrl("");
    setAsesor("");
    setPromocional("");
    setPromocionalApp("");
    setTipo("");
    setSelectedFile(null);
    setSelectedFileApp(null);
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );
    setCambios(cambios + 1);
  };
  const submit = (evt) => {
    evt.preventDefault();
    const data = new FormData();
    data.append("file", selectedFile);
    setMensaje(
      "Se está subiendo el archivo al repositorio de imágenes, por favor espere."
    );
    setStatus(0);
    setCambios(cambios + 1);
    cargar();
    axios
      .post(
        process.env.REACT_APP_DEFAULT_URL + "/api/files/upload/s3",
        data,
        {}
      )
      .then((res) => {
        if (res.data.codigo == 200) {
          setStatus(50);
          setCambios(cambios + 1);
          const info = {
            promocional: res.data.resultado.Location,
            nombre: nombre,
            evento: props.evento_uniqueid,
            url: url,
            inversion: inversion,
            asesor: asesor,
            tipo: tipo,
          };
          const requestOptions = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(info),
          };
          fetch(
            process.env.REACT_APP_DEFAULT_URL + "/api/create/parocinador",
            requestOptions
          )
            .then((res) => {
              return res.json();
            })
            .then((data) => {
              if (data.codigo == 200) {
                setStatus(100);
                setMensaje("Patrocinador registrado exitosamente");
                setCambios(cambios + 1);
                operacionExitosa();
              } else {
                console.log(data);
                setMensaje("Ocurrió un error al registrar al patrocinador");
                setCambios(cambios + 1);
                operacionError();
              }
            });
        } else {
          console.log(res);
          setMensaje(
            "Ocurrió un error al subir el archivo al repositorio de imágenes"
          );
          setCambios(cambios + 1);
          operacionError();
        }
      });
  };
  const onChangeHandler = (event) => {
    if (event.target.name == "app") {
      setSelectedFileApp(event.target.files[0]);
    } else {
      setSelectedFile(event.target.files[0]);
    }
    setCambios(cambios + 1);
  };
  const deletePatrocinador = () => {
    setMensaje("Eliminando patrocinador, por favor espere");
    setStatus(0);
    setCambios(cambios + 1);
    const info = { UniqueID: selectedId };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(info),
    };
    fetch(
      process.env.REACT_APP_DEFAULT_URL + "/api/parocinador/delete",
      requestOptions
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.codigo == 200) {
          setMensaje("Patrocinador eliminado exitosamente");
          setStatus(100);
          setCambios(cambios + 1);
          operacionExitosa();
        } else {
          console.log(data);
          setMensaje("Ocurrió un error al eliminar al patrocinador");
          setCambios(cambios + 1);
          operacionError();
        }
      });
  };
  const confirmar = () => {
    setCargando("none");
    setDetalle("none");
    setError("none");
    setExito("none");
    setConfirmacion("block");
    setEdit("none");
    setCambios(cambios + 1);
  };
  const cargar = () => {
    setCargando("block");
    setDetalle("none");
    setError("none");
    setExito("none");
    setConfirmacion("none");
    setEdit("none");
    setCambios(cambios + 1);
  };
  const operacionExitosa = () => {
    setCargando("none");
    setDetalle("none");
    setError("none");
    setExito("block");
    setEdit("none");
    setConfirmacion("none");
    setCambios(cambios + 1);
  };
  const operacionError = () => {
    setCargando("none");
    setDetalle("none");
    setError("block");
    setExito("none");
    setEdit("none");
    setConfirmacion("none");
    setCambios(cambios + 1);
  };
  const editar = () => {
    setCargando("none");
    setDetalle("none");
    setError("none");
    setExito("none");
    setEdit("block");
    setConfirmacion("none");
    setCambios(cambios + 1);
  };
  const submitEdit = (evt) => {
    evt.preventDefault();
    if (selectedFile != null) {
      const data = new FormData();
      data.append("file", selectedFile);
      setMensaje("Subiendo imagen promocional, por favor espere.");
      setStatus(0);
      setCambios(cambios + 1);
      cargar();
      uploadFile(data).then((urlImg) => {
        if (selectedFileApp != null) {
          const dataApp = new FormData();
          dataApp.append("file", selectedFileApp);
          setMensaje("Subiendo imagen promocional de APP, por favor espere.");
          setStatus(25);
          setCambios(cambios + 1);
          cargar();
          uploadFile(dataApp).then((urlImgApp) => {
            const info = {
              UniqueID: selectedId,
              promocional: urlImg,
              promocional_app: urlImgApp,
              nombre: nombre,
              evento: props.evento_uniqueid,
              url: url,
              inversion: inversion,
              asesor: asesor,
              tipo: tipo,
            };
            sendEdit(info);
          });
        } else {
          const info = {
            UniqueID: selectedId,
            promocional: urlImg,
            promocional_app: promocionalApp,
            nombre: nombre,
            evento: props.evento_uniqueid,
            url: url,
            inversion: inversion,
            asesor: asesor,
            tipo: tipo,
          };
          sendEdit(info);
        }
      });
    } else {
      if (selectedFileApp != null) {
        const dataApp = new FormData();
        dataApp.append("file", selectedFileApp);
        setMensaje("Subiendo imagen promocional de APP, por favor espere.");
        setStatus(25);
        setCambios(cambios + 1);
        cargar();
        uploadFile(dataApp).then((urlImgApp) => {
          const info = {
            UniqueID: selectedId,
            promocional: promocional,
            promocional_app: urlImgApp,
            nombre: nombre,
            evento: props.evento_uniqueid,
            url: url,
            inversion: inversion,
            asesor: asesor,
            tipo: tipo,
          };
          sendEdit(info);
        });
      } else {
        const info = {
          UniqueID: selectedId,
          promocional: promocional,
          promocional_app: promocionalApp,
          nombre: nombre,
          evento: props.evento_uniqueid,
          url: url,
          inversion: inversion,
          asesor: asesor,
          tipo: tipo,
        };
        sendEdit(info);
      }
    }
  };
  const uploadFile = (formData) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          process.env.REACT_APP_DEFAULT_URL + "/api/files/upload/s3",
          formData,
          {}
        )
        .then((res) => {
          if (res.data.codigo == 200) {
            resolve(res.data.resultado.Location);
          } else {
            console.log(res.data);
            setMensaje("Ocurrió un error al actualizar al patrocinador");
            setCambios(cambios + 1);
            operacionError();
            reject();
          }
        });
    });
  };
  const sendEdit = (info) => {
    setStatus(50);
    setCambios(cambios + 1);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(info),
    };
    fetch(
      process.env.REACT_APP_DEFAULT_URL + "/api/update/parocinador",
      requestOptions
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.codigo == 200) {
          setStatus(100);
          setMensaje("Patrocinador actualizado exitosamente");
          setCambios(cambios + 1);
          operacionExitosa();
        } else {
          console.log(data);
          setMensaje("Ocurrió un error al actualizar al patrocinador");
          setCambios(cambios + 1);
          operacionError();
        }
      });
  };
  const generateReport = (id, name) => {
    fetch(
      process.env.REACT_APP_DEFAULT_URL +
        "/api/report/patrocinador/stand/" +
        id,
      { cache: "no-cache" }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        const fileType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const ws = XLSX.utils.json_to_sheet(data.rows);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const info = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(info, Date.now() + "-" + name + fileExtension);
      });
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
      onEnter={entering}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="font-bold">
          Patrocinadores Del Evento
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row style={{ display: detalle }}>
            <Tabs defaultActiveKey="formulario">
              <Tab
                eventKey="formulario"
                title="Nuevo Patrocinador"
                style={{ padding: "2%" }}
              >
                <Form onSubmit={submit}>
                  <Container>
                    <Row>
                      <Form.Group className="mb-3" controlId="nombre">
                        <Form.Label>Nombre del patrocinador</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          name="nombre"
                          placeholder="Ingrese el nombre del patrocinador"
                          onChange={onChange}
                          value={nombre}
                        />
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group className="mb-3" controlId="url">
                        <Form.Label>Inversión del patrocinador</Form.Label>
                        <Form.Control
                          required
                          disabled={
                            tipo == "MEDIA PARTNER" ||
                            tipo == "APOYO INSTITUCIONAL"
                          }
                          type="text"
                          name="inversion"
                          placeholder="Ingrese el monto de inversión del patrocinador"
                          onChange={onChange}
                          value={inversion}
                        />
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group controlId="formFileMultiple" className="mb-3">
                        <Form.Label>
                          Seleccione la imagen promocional del patrocinador
                        </Form.Label>
                        <Form.Control
                          required
                          type="file"
                          onChange={onChangeHandler}
                        />
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group className="mb-3" controlId="url">
                        <Form.Label>Sitio web del patrocinador</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          name="url"
                          placeholder="Ingrese el url del sitio web del patrocinador"
                          onChange={onChange}
                          value={url}
                        />
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group
                        className="mb-3"
                        controlId="Forma_Pago_UniqueID"
                      >
                        <Form.Label>Asesor</Form.Label>
                        <Form.Control
                          as="select"
                          required
                          aria-label="Asesor"
                          name="asesor"
                          onChange={onChange}
                          value={asesor}
                        >
                          <option value="" key="0">
                            Seleccione el asesor
                          </option>
                          {asesores.map((asesor) => (
                            <option
                              key={asesor.UniqueID}
                              value={asesor.UniqueID}
                            >
                              {asesor.Nombres + " " + asesor.Apellidos}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group className="mb-3" controlId="tipo">
                        <Form.Label>Tipo de patrocinio</Form.Label>
                        <Form.Control
                          as="select"
                          required
                          aria-label="tipo"
                          name="tipo"
                          onChange={onChange}
                          value={tipo}
                        >
                          <option value="" key="0">
                            Seleccione el tipo de patrocinio
                          </option>
                          {tipos.map((curr, index) => (
                            <option key={index} value={curr}>
                              {curr}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Row>
                    <Row>
                      <Button type="submit" variant="outline-dark">
                        Guardar
                      </Button>
                    </Row>
                  </Container>
                </Form>
              </Tab>
              <Tab eventKey="patrocinadores" title="Ver Patrocinadores">
                <Container style={{ padding: "2%" }}>
                  <Row>
                    <Button
                      variant="outline-dark"
                      onClick={() => {
                        setContador(contador + 1);
                      }}
                    >
                      Refrescar
                    </Button>
                  </Row>
                  {patrocinadores.map((patrocinador) => (
                    <Row
                      style={{
                        backgroundColor: "white",
                        padding: "25px",
                        marginTop: "30px",
                        border: "2px dashed black",
                        borderRadius: "10px",
                      }}
                    >
                      <Container>
                        <Row>
                          <h3 style={{ color: "black", textAlign: "center" }}>
                            {patrocinador.nombre}
                          </h3>
                        </Row>
                        <Row style={{ marginTop: "25px" }}>
                          <Col
                            style={{ padding: "15px", color: "black" }}
                            xs={12}
                            md={12}
                            lg={6}
                          >
                            <h4 style={{ textAlign: "center" }}>
                              Vista previa de imagen promocional
                            </h4>
                            <img
                              src={patrocinador.promocional_landing}
                              style={{ width: "100%", objectFit: "contain" }}
                            />
                          </Col>
                          {patrocinador.promocional_app != null && (
                            <Col
                              style={{ padding: "15px", color: "black" }}
                              xs={12}
                              md={12}
                              lg={6}
                            >
                              <h4 style={{ textAlign: "center" }}>
                                Vista previa de imagen promocional en APP ABG
                              </h4>
                              <img
                                src={patrocinador.promocional_app}
                                style={{ width: "100%", objectFit: "contain" }}
                              />
                            </Col>
                          )}
                        </Row>
                        <Row style={{ marginTop: "25px" }}>
                          <Col
                            style={{ padding: "15px", color: "black" }}
                            xs={12}
                            md={12}
                            lg={6}
                          >
                            <Row>
                              <Col xs={1} md={1} lg={1}>
                                <OverlayTrigger
                                  trigger="click"
                                  placement="left"
                                  overlay={popover}
                                  rootClose
                                >
                                  <div
                                    style={{
                                      cursor: "pointer",
                                      width: "20%",
                                      color: "black",
                                    }}
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Copiar enlace al portapapeles"
                                    onClick={() => {
                                      navigator.clipboard.writeText(
                                        patrocinador.url
                                      );
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="25"
                                      height="25"
                                      fill="currentColor"
                                      className="bi bi-clipboard-check"
                                      viewBox="0 0 16 16"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"
                                      />
                                      <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                                      <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                                    </svg>
                                  </div>
                                </OverlayTrigger>
                              </Col>
                              <Col xs={11} md={11} lg={11}>
                                <p
                                  style={{ width: "100%", textAlign: "center" }}
                                >
                                  Copiar enlace del patrocinador al portapapeles
                                </p>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={1} md={1} lg={1}>
                                <OverlayTrigger
                                  trigger="click"
                                  placement="left"
                                  overlay={popover}
                                  rootClose
                                >
                                  <div
                                    style={{
                                      cursor: "pointer",
                                      width: "20%",
                                      color: "black",
                                    }}
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Copiar enlace al portapapeles"
                                    onClick={() => {
                                      navigator.clipboard.writeText(
                                        patrocinador.promocional_landing
                                      );
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="25"
                                      height="25"
                                      fill="currentColor"
                                      className="bi bi-clipboard-check"
                                      viewBox="0 0 16 16"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"
                                      />
                                      <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                                      <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                                    </svg>
                                  </div>
                                </OverlayTrigger>
                              </Col>
                              <Col xs={11} md={11} lg={11}>
                                <p
                                  style={{ width: "100%", textAlign: "center" }}
                                >
                                  Copiar enlace de imagen promocional al
                                  portapapeles
                                </p>
                              </Col>
                            </Row>
                          </Col>
                          <Col
                            style={{ padding: "15px", color: "black" }}
                            xs={12}
                            md={12}
                            lg={6}
                          >
                            <Row>
                              <Col xs={1} md={1} lg={1}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="25"
                                  height="25"
                                  fill="currentColor"
                                  className="bi bi-currency-dollar"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z" />
                                </svg>
                              </Col>
                              <Col xs={11} md={11} lg={11}>
                                <p
                                  style={{ width: "100%", textAlign: "center" }}
                                >
                                  <strong>
                                    Inversión del patrocinador
                                    <br />
                                  </strong>
                                  {patrocinador.inversion}
                                </p>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={1} md={1} lg={1}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="25"
                                  height="25"
                                  fill="currentColor"
                                  className="bi bi-person-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                </svg>
                              </Col>
                              <Col xs={11} md={11} lg={11}>
                                <p
                                  style={{ width: "100%", textAlign: "center" }}
                                >
                                  <strong>
                                    Asesor
                                    <br />
                                  </strong>
                                  {patrocinador.asesor}
                                </p>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row style={{ marginTop: "35px" }}>
                          <Alert variant="secondary" style={{ padding: "5%" }}>
                            <Alert.Heading>Url de patrocinador</Alert.Heading>
                            <Alert.Link href={patrocinador.url} target="_blank">
                              {patrocinador.url}
                            </Alert.Link>
                          </Alert>
                        </Row>
                        <Row style={{ marginBottom: "25px" }}>
                          <Button
                            onClick={() => {
                              generateReport(
                                patrocinador.UniqueID,
                                patrocinador.nombre
                              );
                            }}
                            variant="outline-success"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-file-earmark-spreadsheet"
                              viewBox="0 0 16 16"
                            >
                              <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V9H3V2a1 1 0 0 1 1-1h5.5v2zM3 12v-2h2v2H3zm0 1h2v2H4a1 1 0 0 1-1-1v-1zm3 2v-2h3v2H6zm4 0v-2h3v1a1 1 0 0 1-1 1h-2zm3-3h-3v-2h3v2zm-7 0v-2h3v2H6z" />
                            </svg>
                            &nbsp;&nbsp;&nbsp;Generar reporte de stands
                          </Button>
                        </Row>
                        <Row style={{ marginBottom: "25px" }}>
                          <Button
                            onClick={() => {
                              setSelectedId(patrocinador.UniqueID);
                              setNombre(patrocinador.nombre);
                              setInversion(patrocinador.inv);
                              setUrl(patrocinador.url);
                              setAsesor(patrocinador.asesor_id);
                              setSelectedFile(null);
                              setPromocional(patrocinador.promocional_landing);
                              setPromocionalApp(patrocinador.promocional_app);
                              setTipo(patrocinador.tipo);
                              editar();
                              setCambios(cambios + 1);
                            }}
                            variant="outline-secondary"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-pen-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001z" />
                            </svg>
                            &nbsp;&nbsp;&nbsp;Editar información del
                            Patrocinador
                          </Button>
                        </Row>
                        <Row>
                          <Button
                            onClick={() => {
                              setSelectedId(patrocinador.UniqueID);
                              setMensaje(
                                "¡Está segur@ de eliminar al patrocinador " +
                                  patrocinador.nombre +
                                  "?"
                              );
                              setCambios(cambios + 1);
                              confirmar();
                            }}
                            variant="outline-danger"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="25"
                              fill="currentColor"
                              class="bi bi-trash3-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                            </svg>
                            &nbsp;&nbsp;&nbsp;Eliminar Patrocinador
                          </Button>
                        </Row>
                      </Container>
                    </Row>
                  ))}
                </Container>
              </Tab>
            </Tabs>
          </Row>
          <Row style={{ display: edit }}>
            <Form onSubmit={submitEdit}>
              <Container>
                <Row>
                  <Form.Group className="mb-3" controlId="nombre">
                    <Form.Label>Nombre del patrocinador</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="nombre"
                      placeholder="Ingrese el nombre del patrocinador"
                      onChange={onChange}
                      value={nombre}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className="mb-3" controlId="url">
                    <Form.Label>Inversión del patrocinador</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="inversion"
                      placeholder="Ingrese el monto de inversión del patrocinador"
                      onChange={onChange}
                      value={inversion}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group controlId="formFileMultiple" className="mb-3">
                    <Form.Label>
                      Seleccione la imagen promocional del patrocinador
                      (opcional)
                    </Form.Label>
                    <Form.Control
                      type="file"
                      name="img"
                      onChange={onChangeHandler}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group controlId="formFileMultiple" className="mb-3">
                    <Form.Label>
                      Seleccione la imagen promocional del patrocinador en APP
                      ABG (opcional)
                    </Form.Label>
                    <Form.Control
                      type="file"
                      name="app"
                      onChange={onChangeHandler}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className="mb-3" controlId="url">
                    <Form.Label>Sitio web del patrocinador</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="url"
                      placeholder="Ingrese el url del sitio web del patrocinador"
                      onChange={onChange}
                      value={url}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className="mb-3" controlId="Forma_Pago_UniqueID">
                    <Form.Label>Asesor</Form.Label>
                    <Form.Control
                      as="select"
                      required
                      aria-label="Asesor"
                      name="asesor"
                      onChange={onChange}
                      value={asesor}
                    >
                      <option value="" key="0">
                        Seleccione el asesor
                      </option>
                      {asesores.map((asesor) => (
                        <option key={asesor.UniqueID} value={asesor.UniqueID}>
                          {asesor.Nombres + " " + asesor.Apellidos}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className="mb-3" controlId="tipo">
                    <Form.Label>Tipo de patrocinio</Form.Label>
                    <Form.Control
                      as="select"
                      required
                      aria-label="tipo"
                      name="tipo"
                      onChange={onChange}
                      value={tipo}
                    >
                      <option value="" key="0">
                        Seleccione el tipo de patrocinio
                      </option>
                      {tipos.map((curr, index) => (
                        <option key={index} value={curr}>
                          {curr}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Row>
                <Row>
                  <Button type="submit" variant="outline-success">
                    Guardar
                  </Button>
                </Row>
                <Row style={{ marginTop: "25px" }}>
                  <Button variant="outline-dark" onClick={goBack}>
                    Cancelar
                  </Button>
                </Row>
              </Container>
            </Form>
          </Row>
          <Row style={{ display: cargando }}>
            <Alert variant="dark">
              <Alert.Heading>Cargando...</Alert.Heading>
              <p>{mensaje}</p>
              <hr />
              <ProgressBar variant="dark" now={status} label={`${status}%`} />
            </Alert>
          </Row>
          <Row style={{ display: exito }}>
            <Alert variant="success">
              <Alert.Heading>Operación realizada con éxito</Alert.Heading>
              <p>{mensaje}</p>
              <hr />
              <Button variant="outline-success" onClick={goBack}>
                Ok
              </Button>
            </Alert>
          </Row>
          <Row style={{ display: error }}>
            <Alert variant="danger">
              <Alert.Heading>Ocurrió un error</Alert.Heading>
              <p>{mensaje}</p>
              <hr />
              <Button variant="outline-danger" onClick={goBack}>
                Ok
              </Button>
            </Alert>
          </Row>
          <Row style={{ display: confirmacion }}>
            <Alert variant="warning">
              <Alert.Heading>Se necesita confirmación</Alert.Heading>
              <p>{mensaje}</p>
              <hr />
              <Button variant="outline-danger" onClick={deletePatrocinador}>
                Sí
              </Button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Button
                variant="outline-warning"
                onClick={() => {
                  setSelectedId("");
                  goBack();
                }}
              >
                No
              </Button>
            </Alert>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default ModalPatrocinadores;
