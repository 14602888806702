import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
//import DEFAULT_URL from '../../api/url';
const ViewInHouse = () => {
  const [contador, setContador] = useState(0);
  const { hash } = useParams();
  useEffect(() => {
    fetch(
      process.env.REACT_APP_DEFAULT_URL +
        "/api/propuestas/report/HASH-PDF/" +
        hash
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        console.log(data);
        window.location.replace(data.enlace_S3);
      });
  }, [contador]);

  return (
    <div
      style={{
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "cover",
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        backgroundImage:
          "url(https://ik.imagekit.io/p5ethkcnzwh/PORTADA-CARGANDO_zrnGXPrab.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1651185621397)",
      }}
    ></div>
  );
};

export default ViewInHouse;
