import React, { useState, useEffect } from 'react'
import { Row, Button, Form, Container, Tab, ListGroup, Card, Breadcrumb, Accordion, Col, Tabs } from "react-bootstrap";
import "./style.css"
//import DEFAULT_URL from '../../api/url';
import FormCatalogo from './FormCatalogo';
import Temas from './Temas';
import Docentes from './Docentes';
const Biblioteca = (props) => {
    const [contador, setContador] = useState(0);
    const [edit, setEdit] = useState('');
    const [biblioteca, setBiblioteca] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [criterio, setCriterio] = useState('');
    const [todasLasBibliotecas, setTodasLasBibliotecas] = useState('block');
    const [detalleBiblioteca, setDetalleBiblioteca] = useState('none');
    useEffect(() => {
        async function fetchData() {
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/catalogo-tema/read', { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setCriterio('');
                    setBiblioteca(data.salida);
                    setFilteredData(data.salida);
                });
        }
        fetchData();
    }, [contador, props.display]);
    useEffect(() => {
    }, [edit]);

    const refresh = () => {
        setContador(contador + 1);
    }
    const onChange = (evt) => {
        setCriterio(evt.target.value);
        setEdit(edit + 1);
        let result = [];
        result = biblioteca.filter((actual) => {
            return actual.Nombre.toLowerCase().search(evt.target.value.toLowerCase()) != -1;
        });
        setFilteredData(result);
        setEdit(edit + 1);
    }
    const showDetailBiblioteca = () => {
        setTodasLasBibliotecas('none');
        setDetalleBiblioteca('block');
        setEdit(edit + 1);
    }
    const backToAllBibliotecas = () => {
        setTodasLasBibliotecas('block');
        setDetalleBiblioteca('none');
        setEdit(edit + 1);
    }
    return (
        <Container>
            <Row style={{ marginBottom: '25px' }}>
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Registro de bibliotecas a oferta formativa</Accordion.Header>
                        <Accordion.Body>
                            <FormCatalogo refresh={refresh} />
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Row>
            <Tabs
                defaultActiveKey="oferta-formativa"
                className="mb-3"
            >
                <Tab eventKey="docentes" title="Docentes">
                    <Docentes />
                </Tab>
                <Tab eventKey="oferta-formativa" title="Oferta Formativa">
                    <Container fluid>
                        <Row style={{ marginTop: '25px', marginBottom: '25px', display: todasLasBibliotecas }}>
                            <Form.Group className="mb-3" controlId="institucion">
                                <Form.Label>Buscar</Form.Label>
                                <Form.Control required type="text" placeholder="puede buscar la o las bibliotecas por nombre" name="criterio" value={criterio} onChange={onChange} />
                            </Form.Group>
                        </Row>
                        <Row style={{ marginTop: '25px' }}>
                            <Container fluid>
                                <Tab.Container variant='dark'>
                                    <Row style={{ marginBottom: '25px', display: todasLasBibliotecas }}>
                                        <Button variant='outline-dark' onClick={refresh}>Refrescar</Button>
                                    </Row>
                                    <Row style={{ marginBottom: '45px', marginTop: '45px', display: todasLasBibliotecas }}>
                                        <ListGroup style={{ textAlign: 'left' }}>
                                            <Col xs={12} md={12} lg={12} >
                                                <Row style={{ marginBottom: '45px' }}>
                                                    <Breadcrumb variant="dark">
                                                        <Breadcrumb.Item active>Oferta formativa</Breadcrumb.Item>
                                                    </Breadcrumb>
                                                </Row>
                                                <Row>
                                                    {filteredData.map(biblioteca =>
                                                        <Col xs={12} md={6} lg={6} style={{ marginBottom: '25px' }} key={biblioteca.UniqueID}>
                                                            <Card style={{ height: '100%' }}>
                                                                <Card.Img loading="lazy" variant="top" alt='img-catalogo' src={biblioteca.foto} />
                                                                <Card.Body style={{ display: 'grid', placeItems: 'center' }}>
                                                                    <Card.Title style={{ textAlign: 'center' }}>{biblioteca.Nombre}</Card.Title>
                                                                    <ListGroup.Item variant='light' key={biblioteca.UniqueID} action href={"#" + biblioteca.UniqueID}>
                                                                        <h4 style={{ textAlign: 'center', width: '100%', height: '100%' }} onClick={showDetailBiblioteca}>SELECCIONAR</h4>
                                                                    </ListGroup.Item>
                                                                </Card.Body>
                                                            </Card>
                                                        </Col>
                                                    )}
                                                </Row>
                                            </Col>
                                        </ListGroup>
                                    </Row>
                                    <Row style={{ display: detalleBiblioteca }}>
                                        <Tab.Content>
                                            {filteredData.map((actual, index) => <Tab.Pane key={actual.UniqueID} eventKey={"#" + actual.UniqueID}>
                                                <Container fluid>
                                                    <Row style={{ marginTop: '25px' }}>
                                                        <Col xs={2} md={2} lg={2}>
                                                            <svg onClick={backToAllBibliotecas} style={{ cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-backspace-fill" viewBox="0 0 16 16">
                                                                <path d="M15.683 3a2 2 0 0 0-2-2h-7.08a2 2 0 0 0-1.519.698L.241 7.35a1 1 0 0 0 0 1.302l4.843 5.65A2 2 0 0 0 6.603 15h7.08a2 2 0 0 0 2-2V3zM5.829 5.854a.5.5 0 1 1 .707-.708l2.147 2.147 2.146-2.147a.5.5 0 1 1 .707.708L9.39 8l2.146 2.146a.5.5 0 0 1-.707.708L8.683 8.707l-2.147 2.147a.5.5 0 0 1-.707-.708L7.976 8 5.829 5.854z" />
                                                            </svg>
                                                        </Col>
                                                        <Col xs={10} md={10} lg={10}>
                                                            <Breadcrumb variant="dark">
                                                                <Breadcrumb.Item active>Oferta formativa</Breadcrumb.Item>
                                                                <Breadcrumb.Item active>{actual.Nombre}</Breadcrumb.Item>
                                                            </Breadcrumb>
                                                        </Col>
                                                    </Row>
                                                    <Row style={{ marginTop: '25px' }}>
                                                        <Temas catalogo={actual} refresh={() => { setContador(contador + 1); }} back={backToAllBibliotecas} />
                                                    </Row>
                                                </Container>
                                            </Tab.Pane>)}
                                        </Tab.Content>
                                    </Row>
                                </Tab.Container>
                            </Container>
                        </Row >
                    </Container>
                </Tab>
            </Tabs>
        </Container >
    )
}

export default Biblioteca