import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Form, Button } from "react-bootstrap";
const CrearTarifa = () => {
  const { hash } = useParams();
  const [contador] = useState(0);
  const [evento, setEvento] = useState({});
  const [descripcion, setDescripcion] = useState("");
  const [cambios, setCambios] = useState("");
  const [validated, setValidated] = useState(false);
  const [cargando, setCargando] = useState(false);
  const [error, setError] = useState(false);
  useEffect(() => {
    fetch(
      process.env.REACT_APP_DEFAULT_URL + "/api/read/evento/landing/" + hash,
      { cache: "no-cache" }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setEvento(data.rows[0]);
        /*
        fetch(
          process.env.REACT_APP_DEFAULT_URL +
            "/api/read/evento/tarifas/" +
            data.rows[0].UniqueID,
          { cache: "no-cache" }
        )
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            //console.log(data);
            setTarifario(data.salida);
          });
          */
      });
  }, [contador]);
  useEffect(() => {}, [cambios]);
  const onSubmit = (evt) => {
    const form = evt.currentTarget;
    if (form.checkValidity() === false) {
      evt.preventDefault();
      evt.stopPropagation();
    } else {
      evt.preventDefault();
      setCargando(true);
      setCambios(cambios + 1);
      const info = { descripcion: descripcion, evento: evento.UniqueID };
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(info),
      };
      fetch(
        process.env.REACT_APP_DEFAULT_URL + "/api/tarifas/create",
        requestOptions,
        { cache: "no-cache" }
      )
        .then((res) => {
          return res.json();
        })
        .then((datos) => {
          if (datos.codigo == 200) {
            window.location.replace(
              process.env.REACT_APP_SELF_URL +
                "/tarifas/" +
                hash +
                "/" +
                datos.id
            );
          } else {
            window.location.reload();
          }
        });
    }
    setValidated(true);
    setCambios(cambios + 1);
  };
  const onChange = (evt) => {
    setDescripcion(evt.target.value);
    setCambios(cambios + 1);
  };
  return (
    <Container style={{ padding: "3%" }}>
      <Row style={{ padding: "1%" }}>
        <h1>Registrar tarifa</h1>
      </Row>
      <Form onSubmit={onSubmit} noValidate validated={validated}>
        <Row>
          <Form.Group className="mb-3" controlId="nombres">
            <Form.Label>Descripción de la tarifa</Form.Label>
            <Form.Control
              value={descripcion}
              disabled={cargando || error}
              required
              as="textarea"
              rows={5}
              style={{ resize: "none" }}
              placeholder="Ingrese una breve descripción de la tarifa"
              name="descripcion"
              onChange={onChange}
            />
            <Form.Control.Feedback>Ok</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Por favor, completa este campo
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row>
          <Button
            variant="outline-primary"
            type="submit"
            disabled={cargando || error}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-tags"
              viewBox="0 0 16 16"
            >
              <path d="M3 2v4.586l7 7L14.586 9l-7-7H3zM2 2a1 1 0 0 1 1-1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 2 6.586V2z" />
              <path d="M5.5 5a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm0 1a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zM1 7.086a1 1 0 0 0 .293.707L8.75 15.25l-.043.043a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 0 7.586V3a1 1 0 0 1 1-1v5.086z" />
            </svg>
            &nbsp;&nbsp;&nbsp;&nbsp;Registrar tarifa
          </Button>
        </Row>
      </Form>
    </Container>
  );
};

export default CrearTarifa;
