import React, { Component, useEffect, useState } from 'react';
import { Row, Button, Col, Form, Alert, Container, Accordion, ButtonGroup, DropdownButton, InputGroup, Popover, Card, OverlayTrigger } from "react-bootstrap";
import "../../../Inscripcion-Cliente/InscripcionCliente.css"
import { ModalParticipantes } from '../../../Inscripcion-Cliente/Modal-Participantes/ModalParticipantes';
import { ErrorModal } from '../../../Utils/ErrorModal/ErrorModal';
import { SuccessModal } from '../../../Utils/SuccessModal/SuccessModal';
//import DEFAULT_URL from '../../../../api/url';
//import { SELF_URL } from '../../../../api/url';
import axios from '../../../../api/axios';
import useLogout from '../../../../hooks/useLogout';
import ModalFinal from '../../../Inscripcion-Cliente/InscripcionAsesor/ModalFinal';
import { ModalCargando } from '../../../Utils/Modal-Cargando/ModalCargando';
const InscripcionManual = () => {
    const [uniqueID, setUniqueID] = useState('');
    const [nombres, setNombres] = useState('');
    const [apellidos, setApellidos] = useState('');
    const [img, setImg] = useState('');
    const [email, setEmail] = useState('');
    const logout = useLogout();
    const [llevaenlace, setLlevaenlace] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [show, setShow] = useState(false);
    const [modalError, setModalError] = useState(false);
    const [modalSucc, setModalSucc] = useState(false);
    const [cartera, setCartera] = useState([]);
    const [hash, setHash] = useState('');
    const [precio, setPrecio] = useState(0);
    const [promocional, setPromocional] = useState('');
    const [titulo, setTitulo] = useState('');
    const [dia, setDia] = useState('');
    const [mes_anio, setMesAnio] = useState('');
    const [modalFinal, setModalFinal] = useState(false);
    const [modalCargando, setModalCargando] = useState(false);
    const [participantes, setParticipantes] = useState([

        {
            No: 0,
            identificacion: '',
            Nombres: 'Nuevo Participante',
            Apellidos: '',
            Puesto: '',
            Correo: '',
            Tel: '',
            Celular: ''
        }
    ]);
    const [agremiado, setAgremiado] = useState(false);
    const [inversion, setInversion] = useState('');
    const [fecha_Max_FACT, setFecha_Max_FACT] = useState('');
    const [nombre_FACT, setNombre_FACT] = useState('');
    const [nIT, setNIT] = useState('');
    const [direccion, setDireccion] = useState('');
    const [correo_FACT, setCorreo_FACT] = useState('');
    const [responsable, setResponsable] = useState('');
    const [correo_RESP, setCorreo_RESP] = useState('');
    const [tel_RESP, setTel_RESP] = useState('');
    const [responsable_PAGO, setResponsable_PAGO] = useState('');
    const [correo_RP, setCorreo_RP] = useState('');
    const [tel_RP, setTel_RP] = useState('');
    const [comentario, setComentario] = useState('');
    const [evento_Unique_ID, setEvento_Unique_ID] = useState('');
    const [forma_Pago_UniqueID, setForma_Pago_UniqueID] = useState('');
    const [moneda_Unique_ID, setMoneda_Unique_ID] = useState(8);
    const [moneda, setMoneda] = useState('Q');
    const [eventos, setEventos] = useState([]);
    const [formas_pago, setFormas_pago] = useState([]);
    const [asesores, setAsesores] = useState([]);
    const [mensaje, setMensaje] = useState('');
    const [consideracion, setConsideracion] = useState('');
    const [asesor_UniqueID, setAsesor_UniqueID] = useState(0);
    const [enlace, setEnlace] = useState('');
    const [empresa, setEmpresa] = useState('');
    const [contador, setContador] = useState(0);
    const [paises, setPaises] = useState([]);
    const [enlaceActual, setEnlaceActual] = useState('https://www.ebg.edu.gt/');
    const singOut = async () => {
        await logout();
        window.location.replace('/')
    }
    const popover = (
        <Popover id="popover-basic">
            <Card className="text-center">
                <Card.Img variant="top" src={img} style={{ height: '300px' }} />
                <Card.Body>
                    <Card.Title>{nombres + ' ' + apellidos}</Card.Title>
                    <Card.Text>
                        {email}
                    </Card.Text>
                    <Card.Text style={{ cursor: 'pointer' }}>
                        <a target="_blank" href={process.env.REACT_APP_SELF_URL + '/asesor/page/' + hash}>Ver mi perfil público</a>
                    </Card.Text>
                </Card.Body>
                <Card.Footer>
                    <ButtonGroup className="me-2" aria-label="First group">
                        <Button style={{ backgroundColor: '#343434 ', borderColor: 'transparent' }} onClick={() => window.location.replace('/usuarios/actualizar')}>Editar Perfil</Button>
                        <Button style={{ backgroundColor: '#1e3d52', borderColor: 'transparent' }} onClick={singOut}>Cerrar Sesión</Button>
                    </ButtonGroup>
                </Card.Footer>
            </Card>
        </Popover>
    );
    useEffect(async () => {
        async function fetchData() {
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/auth/userinfo' , { cache: "no-cache", credentials: 'include' })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/cartera/' + data.UniqueID, { cache: "no-cache" })
                        .then((res) => {
                            return res.json();
                        })
                        .then(data => {
                            setCartera(data.rows);
                        });
                    setNombres(data.nombres);
                    setApellidos(data.apellidos);
                    setImg(data.foto);
                    setAsesor_UniqueID(data.UniqueID);
                    setEmail(data.email);
                    setHash(data.hash);
                });
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/evento/agenda', { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setEventos(data.rows);
                });
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/forma_pago', { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setFormas_pago(data.rows);
                });
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/asesor', { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setAsesores(data.rows);
                });
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/paises')
                .then((res) => {
                    return res.json()
                })
                .then(data => {
                    let paises_arr = [];
                    data.paises.map((actual) => { paises_arr.push({ id: actual.code, nombre: actual.name}) });
                    paises_arr.sort(function (a, b) { return a.nombre.localeCompare(b.nombre) });
                    setPaises(paises_arr);
                });
        }
        fetchData();
    }, [contador]);

    const ocultar = () => {
        setModalShow(false);
        setModalError(false);
        setModalSucc(false);
        setContador(contador + 1);

    }
    const addparticipante = () => {
        const newParticipante = { No: participantes.length, Nombres: 'Nuevo Participante', Apellidos: '', Puesto: '', Correo: '', Tel: '', Celular: '' };
        setParticipantes([...participantes, newParticipante]);
        setContador(contador + 1);
    }
    const deleteparticipante = (No) => {
        var new_array = participantes.filter(participante => participante.No !== No)
        if (new_array.length > 0) {
            for (let i = 0; i < new_array.length; i++) {
                new_array[i].No = i;
            }
            setParticipantes(new_array);
            setContador(contador + 1);
            return 0;
        } else {
            var nuevo_array = participantes;
            nuevo_array[0].Nombres = 'Nuevo Participante';
            nuevo_array[0].Apellidos = '';
            nuevo_array[0].Puesto = '';
            nuevo_array[0].Correo = '';
            nuevo_array[0].Tel = '';
            nuevo_array[0].Celular = '';
            nuevo_array[0].Pais = '';
            setParticipantes(nuevo_array);
            setContador(contador + 1);
        }
    }

    const eventocambio = (evt) => {
        const arr_name = evt.target.name.split("-");
        var new_array = participantes;
        switch (arr_name[0]) {
            case "nombres":
                new_array[arr_name[1]].Nombres = evt.target.value;
                setParticipantes(new_array);
                break;
            case "apellidos":
                new_array[arr_name[1]].Apellidos = evt.target.value;
                setParticipantes(new_array);
                break;
            case "puesto":
                new_array[arr_name[1]].Puesto = evt.target.value;
                setParticipantes(new_array);
                break;
            case "correo":
                new_array[arr_name[1]].Correo = evt.target.value;
                setParticipantes(new_array);
                break;
            case "telefono":
                new_array[arr_name[1]].Tel = evt.target.value;
                setParticipantes(new_array);
                break;
            case "celular":
                new_array[arr_name[1]].Celular = evt.target.value;
                setParticipantes(new_array);
                break;
            case "identificacion":
                new_array[arr_name[1]].identificacion = evt.target.value;
                setParticipantes(new_array);
                break;
            case "pais":
                new_array[arr_name[1]].Pais = evt.target.value;
                setParticipantes(new_array);
                break;
            default:
                break;
        }
        setContador(contador + 1);
    }
    const cambioencabezado = (evt) => {
        if (evt.target.name === "agremiado") {
            setAgremiado(evt.target.checked);

        } else {

            switch (evt.target.name) {
                case "asesor_UniqueID":
                    setAsesor_UniqueID(evt.target.value)
                    break;
                case "inversion":
                    setInversion(evt.target.value);
                    break;
                case "evento_Unique_ID":
                    setEvento_Unique_ID(evt.target.value);
                    break;
                case "fecha_Max_FACT":
                    setFecha_Max_FACT(evt.target.value);
                    break;
                case "nombre_FACT":
                    setNombre_FACT(evt.target.value);
                    break;
                case "nIT":
                    setNIT(evt.target.value);
                    break;
                case "direccion":
                    setDireccion(evt.target.value);
                    break;
                case "correo_FACT":
                    setCorreo_FACT(evt.target.value);
                    break;
                case "empresa":
                    changeEmpresaInfo(evt.target.value);
                    break;
                case "responsable":
                    setResponsable(evt.target.value);
                    break;
                case "correo_RESP":
                    setCorreo_RESP(evt.target.value);
                    break;
                case "tel_RESP":
                    setTel_RESP(evt.target.value);
                    break;
                case "responsable_PAGO":
                    setResponsable_PAGO(evt.target.value);
                    break;
                case "correo_RP":
                    setCorreo_RP(evt.target.value);
                    break;
                case "tel_RP":
                    setTel_RP(evt.target.value);
                    break;
                case "forma_Pago_UniqueID":
                    setForma_Pago_UniqueID(evt.target.value);
                    break;
                case "comentario":
                    setComentario(evt.target.value);
                    break;
            }
        }
        setContador(contador + 1);
    }
    const changeEmpresaInfo = (id) => {
        let seleccionado = cartera.find(cliente => cliente.Empresa_UniqueID == id);
        setEmpresa(seleccionado.Empresa);
        setUniqueID(id);
        setResponsable(seleccionado.ResponsableInscripcion);
        setCorreo_RESP(seleccionado.CorreoResponsable);
        setTel_RESP(seleccionado.TelefonoResponsable);
        setResponsable_PAGO(seleccionado.ResponsablePago);
        setCorreo_RP(seleccionado.CorreoPago);
        setTel_RP(seleccionado.TelefonoPago);
        setForma_Pago_UniqueID(seleccionado.FormaPago);
        setNombre_FACT(seleccionado.NombreFactura);
        setNIT(seleccionado.NIT);
        setDireccion(seleccionado.Direccion);
        setCorreo_FACT(seleccionado.CorreoFacturacion);
    }

    const eventoenvio = (evt) => {
        evt.preventDefault();
        const validar_participantes = participantes.filter(participante => participante.Nombres === '' || participante.Nombres === 'Nuevo Participante' || participante.Apellidos === '' || participante.Celular === '' || participante.Correo === '' || participante.Puesto === '' || participante.Tel === '' || participante.Pais === '');
        if (validar_participantes.length > 0) {
            setMensaje('Por favor, revise la información que ingresó en el apartado de participantes');
            setConsideracion('Los participantes se ingresan en Registrar/Participantes');
            setModalError(true);
            setContador(contador + 1);
        } else {
            setMensaje('Procesando información, por favor espere...');
            setModalCargando(true);
            const info =
            {
                participantes: participantes,
                Agremiado: agremiado,
                Inversion: inversion,
                Fecha_Max_FACT: fecha_Max_FACT,
                Nombre_FACT: nombre_FACT,
                NIT: nIT,
                Direccion: direccion,
                Correo_FACT: correo_FACT,
                Responsable: responsable,
                Correo_RESP: correo_RESP,
                Tel_RESP: tel_RESP,
                Responsable_PAGO: responsable_PAGO,
                Correo_RP: correo_RP,
                Tel_RP: tel_RP,
                Comentario: comentario,
                Evento_Unique_ID: evento_Unique_ID,
                Forma_Pago_UniqueID: forma_Pago_UniqueID,
                Moneda_Unique_ID: moneda_Unique_ID,
                moneda: moneda,
                Asesor_UniqueID: asesor_UniqueID,
                empresa: empresa
            };
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(info)
            };
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/inscribir', requestOptions)
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    if (data.codigo == 200) {
                        setPrecio(inversion);
                        setEnlaceActual(process.env.REACT_APP_SELF_URL + '/inscripcion/public/ver/' + data.id);
                        const index_finder = (asesor) => asesor.UniqueID == asesor_UniqueID;
                        let email_asesor = asesores[asesores.findIndex(index_finder)].email;
                        const index_finder_2 = (evento) => evento.UniqueID == evento_Unique_ID;
                        let evento_seleccionado = eventos[eventos.findIndex(index_finder_2)];
                        setTitulo(evento_seleccionado.Nombre);
                        setPromocional(evento_seleccionado.flier);
                        setDia(evento_seleccionado.dia);
                        setMesAnio(evento_seleccionado.mes_anio);
                        setContador(contador + 1);
                        if (inversion > 0) {
                            const info = { participantes: participantes, titulo: titulo, promocional: promocional, enlace: process.env.REACT_APP_SELF_URL + '/inscripcion/public/ver/' + data.id, enlace_seguimiento: process.env.REACT_APP_SELF_URL + '/inscripcion/seguimiento/' + data.id, dia: dia, mes_anio: mes_anio, email_asesor: email_asesor };
                            const requestOptions = {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify(info)
                            };
                            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/email/informar/inscripcion/pagada', requestOptions)
                                .then((res) => {
                                    return res.json();
                                })
                                .then(data => {
                                    clean_state();
                                    setModalFinal(true);
                                    setModalCargando(false);
                                    setContador(contador + 1);
                                });
                        } else {
                            const info = { participantes: participantes, titulo: titulo, promocional: promocional, enlace: process.env.REACT_APP_SELF_URL + '/inscripcion/public/ver/' + data.id, dia: dia, mes_anio: mes_anio, email_asesor: email_asesor };
                            const requestOptions = {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify(info)
                            };
                            const info_2 = { UniqueID: data.id };
                            const requestOptions_2 = {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify(info_2)
                            };
                            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/update/inscripcion/confirmar_pago', requestOptions_2)
                                .then((res) => {
                                    return res.json();
                                })
                                .then(data => {
                                    if (data.codigo == 200) {
                                        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/email/informar/inscripcion/cortesia', requestOptions)
                                            .then((res) => {
                                                return res.json();
                                            })
                                            .then(data => {
                                                clean_state();
                                                setModalFinal(true);
                                                setModalCargando(false);
                                                setContador(contador + 1);
                                            });
                                    } else {
                                        console.log(data);
                                        setMensaje('Ocurrió un error al intentar almacenar la inscripción');
                                        setConsideracion('');
                                        setModalError(true);
                                        setContador(contador + 1);
                                    }
                                });
                        }
                    } else {
                        setMensaje('Ocurrió un error al intentar almacenar la inscripción');
                        setConsideracion('');
                        setModalError(true);
                        setContador(contador + 1);
                    }
                });
        }
    }

    const seleccion_moneda = (evt) => {
        if (evt.target.checked) {
            setMoneda('$');
            setMoneda_Unique_ID(7)
        } else {
            setMoneda('Q');
            setMoneda_Unique_ID(8);
        }
        setContador(contador + 1);
    }

    const clean_state = () => {
        setModalShow(false);
        setShow(false);
        setParticipantes([

            {
                No: 0,
                identificacion: '',
                Nombres: 'Nuevo Participante',
                Apellidos: '',
                Puesto: '',
                Correo: '',
                Tel: '',
                Celular: ''
            }
        ]);
        setAgremiado(false);
        setInversion('');
        setFecha_Max_FACT('');
        setNombre_FACT('');
        setNIT('');
        setDireccion('');
        setCorreo_FACT('');
        setResponsable('');
        setCorreo_RESP('');
        setTel_RESP('');
        setResponsable_PAGO('');
        setCorreo_RP('');
        setTel_RP('');
        setComentario('');
        setEvento_Unique_ID('');
        setForma_Pago_UniqueID('');
        setMoneda_Unique_ID(8);
        setMoneda('Q');
        setMensaje('');
        setConsideracion('');
        setAsesor_UniqueID(0);
        setEmpresa('');
        setUniqueID('');
        setContador(contador + 1);
    }
    return (
        <div className="contenedor-inscripcion-cliente poster-info" style={{ overflow: 'scroll' }}>
            <Container>
                <Row style={{ marginTop: '20px' }}>
                    <Col xs={8} md={8} lg={8}>
                        <div style={{ right: '45%', position: 'relative', cursor: 'pointer', color: 'white' }} data-toggle="tooltip" data-placement="top" title="Atrás" onClick={() => window.location.replace('/asesor')}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="currentColor" class="bi bi-reply-fill" viewBox="0 0 16 16">
                                <path d="M5.921 11.9 1.353 8.62a.719.719 0 0 1 0-1.238L5.921 4.1A.716.716 0 0 1 7 4.719V6c1.5 0 6 0 7 8-2.5-4.5-7-4-7-4v1.281c0 .56-.606.898-1.079.62z" />
                            </svg>
                        </div>
                    </Col>
                    <Col xs={4} md={4} lg={4} style={{ left: '0%', position: 'relative' }}>
                        <OverlayTrigger trigger="click" placement="bottom" overlay={popover} rootClose>
                            <Button variant='light'>{nombres}</Button>
                        </OverlayTrigger>
                    </Col>
                </Row>
                <Row>
                    <Form onSubmit={eventoenvio}>
                        <Container>
                            <Row >
                                <Col xs={12} md={6}>
                                    <div className="columna-formulario " style={{ padding: '2%', textAlign: 'center' }}>
                                        <h1 className="font-bold blanco">ASOCIACIÓN BANCARIA DE GUATEMALA</h1>
                                        <h2 className="font-bold blanco" style={{ marginTop: '5%', marginBottom: '5%' }}>FORMULARIO DE INSCRIPCIÓN</h2>
                                        <Form.Group className="mb-3" controlId="Agremiado" style={{ color: 'white' }}>
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                className="font-normal"
                                                name="agremiado"
                                                label="Agremiados"
                                                style={{ textAlign: 'left' }}
                                                onChange={cambioencabezado}
                                                value={agremiado}

                                            />
                                        </Form.Group>
                                        <Accordion defaultActiveKey="0" className="bg-success" style={{ marginTop: '5%' }}>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header className="font-bold">INFORMACIÓN DEL EVENTO</Accordion.Header>
                                                <Accordion.Body className="font-normal">
                                                    <Form.Group className="mb-3" controlId="Forma_Pago_UniqueID">
                                                        <Form.Label>Asesor</Form.Label>
                                                        <Form.Control disabled as="select" required aria-label="Asesor" name="asesor_UniqueID" onChange={cambioencabezado} value={asesor_UniqueID}>
                                                            <option value="" key="0">Seleccione el asesor contactado</option>
                                                            {asesores.map(asesor => <option key={asesor.UniqueID} value={asesor.UniqueID}>{asesor.Nombres + " " + asesor.Apellidos}</option>)}
                                                        </Form.Control>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="Inversion">
                                                        <Form.Label>Inversión</Form.Label>
                                                        <InputGroup className="mb-3">
                                                            <InputGroup.Text>{moneda}</InputGroup.Text>
                                                            <Form.Control required name="inversion" placeholder="Ingrese el monto de inversión" onChange={cambioencabezado} value={inversion} />
                                                        </InputGroup>
                                                        <Form.Check
                                                            type="switch"
                                                            id="switch-dolar"
                                                            className="font-normal"
                                                            label="$ (USD)"
                                                            onChange={seleccion_moneda}
                                                            style={{ textAlign: 'left' }}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="Evento_Unique_ID">
                                                        <Form.Label>Evento</Form.Label>
                                                        <Form.Control as="select" required aria-label="Evento" name="evento_Unique_ID" onChange={cambioencabezado} value={evento_Unique_ID} >
                                                            <option value="" key="0">Seleccione el evento</option>
                                                            {eventos.map(evento => <option key={evento.UniqueID} value={evento.UniqueID}>{evento.Nombre}</option>)}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header className="font-bold">CONTACTO Y FORMA DE PAGO</Accordion.Header>
                                                <Accordion.Body className="font-normal">
                                                    <Row>
                                                        <Col xs={12} md={12}>
                                                            <Form.Group className="mb-3" controlId="empresa">
                                                                <Form.Label>Empresa</Form.Label>
                                                                <Form.Control as="select" required aria-label="Empresa" name="empresa" onChange={cambioencabezado} value={uniqueID} >
                                                                    <option value="" key="0">Seleccione la empresa</option>
                                                                    {cartera.map(cliente => <option key={cliente.Empresa_UniqueID} value={cliente.Empresa_UniqueID}>{cliente.Empresa}</option>)}
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs={12} md={12}>
                                                            <Form.Group className="mb-3" controlId="Responsable">
                                                                <Form.Label>Responsable de la inscripción</Form.Label>
                                                                <Form.Control required disabled type="text" placeholder="Ingrese el responsable" name="responsable" onChange={cambioencabezado} value={responsable} />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs={12} md={8}>
                                                            <Form.Group className="mb-3" controlId="Correo_RESP">
                                                                <Form.Label>Correo</Form.Label>
                                                                <Form.Control required disabled type="email" placeholder="Ingrese el correo" name="correo_RESP" onChange={cambioencabezado} value={correo_RESP} />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs={12} md={4}>
                                                            <Form.Group className="mb-3" controlId="Tel_RESP">
                                                                <Form.Label>Tel.</Form.Label>
                                                                <Form.Control required disabled type="text" placeholder="Ingrese el teléfono" name="tel_RESP" onChange={cambioencabezado} value={tel_RESP} />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs={12} md={12}>
                                                            <Form.Group className="mb-3" controlId="Responsable_PAGO">
                                                                <Form.Label>Persona responsable del trámite de Pago</Form.Label>
                                                                <Form.Control required disabled type="text" placeholder="Ingrese el responsable" name="responsable_PAGO" onChange={cambioencabezado} value={responsable_PAGO} />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs={12} md={8}>
                                                            <Form.Group className="mb-3" controlId="Correo_RP">
                                                                <Form.Label>Correo</Form.Label>
                                                                <Form.Control required disabled type="email" placeholder="Ingrese el correo" name="correo_RP" onChange={cambioencabezado} value={correo_RP} />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs={12} md={4}>
                                                            <Form.Group className="mb-3" controlId="Tel_RP">
                                                                <Form.Label>Tel.</Form.Label>
                                                                <Form.Control required disabled type="text" placeholder="Ingrese el teléfono" name="tel_RP" onChange={cambioencabezado} value={tel_RP} />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs={12} md={12}>
                                                            <Form.Group className="mb-3" controlId="Forma_Pago_UniqueID">
                                                                <Form.Label>Indique su forma de pago</Form.Label>
                                                                <Form.Control as="select" required aria-label="Evento" name="forma_Pago_UniqueID" onChange={cambioencabezado} value={forma_Pago_UniqueID}>
                                                                    <option value="" key="0">Seleccione la forma de pago</option>
                                                                    {formas_pago.map(forma_pago => <option key={forma_pago.UniqueID} value={forma_pago.UniqueID}>{forma_pago.Nombre}</option>)}
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs={12} md={12}>
                                                            <Form.Group className="mb-3" controlId="Comentario">
                                                                <Form.Label>Comentario sobre el pago</Form.Label>
                                                                <Form.Control as="textarea" rows={3} style={{ resize: 'none' }} name="comentario" onChange={cambioencabezado} value={comentario} />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header className="font-bold">DATOS PARA LA FACTURA ELECTRÓNICA</Accordion.Header>
                                                <Accordion.Body className="font-normal">
                                                    <Form.Group className="mb-3" controlId="Fecha_Max_FACT">
                                                        <Form.Label>Fecha máxima que reciben facturas</Form.Label>
                                                        <Form.Control required type="date" name="fecha_Max_FACT" placeholder="Seleccione la fecha" onChange={cambioencabezado} value={fecha_Max_FACT} />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="Nombre_FACT">
                                                        <Form.Label>Nombre para la factura</Form.Label>
                                                        <Form.Control required disabled type="text" name="nombre_FACT" placeholder="Consumidor Final" onChange={cambioencabezado} value={nombre_FACT} />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="NIT">
                                                        <Form.Label>NIT</Form.Label>
                                                        <Form.Control required disabled type="text" placeholder="CF" name="nIT" onChange={cambioencabezado} value={nIT} />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="Direccion">
                                                        <Form.Label>Dirección</Form.Label>
                                                        <Form.Control required disabled type="text" placeholder="Ciudad" name="direccion" onChange={cambioencabezado} value={direccion} />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="Correo_FACT">
                                                        <Form.Label>Correo para enviar factura electrónica</Form.Label>
                                                        <Form.Control required disabled type="email" placeholder="Por favor ingrese el correo" name="correo_FACT" onChange={cambioencabezado} value={correo_FACT} />
                                                    </Form.Group>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                    <ButtonGroup>
                                        <DropdownButton as={ButtonGroup} title="Registrar" className="font-bold" id="bg-nested-dropdown" variant="light">
                                            <Button variant="light" className="font-bold" onClick={() => setModalShow(true)} style={{ left: '0%' }}>Participantes</Button>
                                            <Button variant="light" className="font-bold" type="submit">Inscripción</Button>
                                        </DropdownButton>
                                    </ButtonGroup>
                                </Col>
                                <Col xs={12} md={6}>
                                    <div className="informacion-derecha">
                                        <>
                                            <Alert show={show} variant="danger">
                                                <Alert.Heading>Información de participantes errónea</Alert.Heading>
                                                <p>
                                                    Por favor, revise la información que ingresó en el apartado de participantes
                                                </p>
                                                <hr />
                                                <p>Los participantes se ingresan en Registrar/Participantes</p>
                                                <div className="d-flex justify-content-end">
                                                    <Button onClick={() => setShow(false)} variant="outline-danger">
                                                        Cerrar
                                                    </Button>
                                                </div>
                                            </Alert>
                                        </>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                        <ModalParticipantes
                            show={modalShow}
                            onHide={ocultar}
                            participantes={participantes}
                            addparticipante={addparticipante}
                            deleteparticipante={deleteparticipante}
                            eventocambio={eventocambio}
                            paises={paises}
                            inversion = {inversion}
                        />
                        <ErrorModal
                            color={"#E0E0E2"}
                            show={modalError}
                            onHide={ocultar}
                            mensaje={mensaje}
                            consideracion={consideracion}
                        />
                        <SuccessModal
                            color={"#E0E0E2"}
                            show={modalSucc}
                            onHide={ocultar}
                            mensaje={mensaje}
                            consideracion={consideracion}
                            enlace={enlace}
                            llevaenlace={llevaenlace}
                        />
                    </Form>
                    <ModalCargando show={modalCargando} onHide={() => setModalCargando(false)} mensaje={mensaje} />
                    <ModalFinal enlace={enlaceActual} show={modalFinal} onHide={() => { setModalFinal(false); window.location.reload(); }} promocional={promocional} inversion={precio} />
                </Row>
            </Container>

        </div>
    );
}
export default InscripcionManual;