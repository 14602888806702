import React, { useState } from 'react';
import {
    useParams

} from "react-router-dom";
import { Row, Col, Container, Form, Accordion, InputGroup } from "react-bootstrap";
import './ViewInscripcion.css';
//import DEFAULT_URL from '../../../api/url';
export const ViewInscripcion = () => {
    const [inscripcion_Agremiado, setInscripcion_Agremiado] = useState("");
    const [inscripcion_Inversion, setInscripcion_Inversion] = useState("");
    const [inscripcion_Fecha_Max_FACT, setInscripcion_Fecha_Max_FACT] = useState("");
    const [inscripcion_Nombre_FACT, setInscripcion_Nombre_FACT] = useState("");
    const [inscripcion_NIT, setInscripcion_NIT] = useState("");
    const [inscripcion_Direccion, setInscripcion_Direccion] = useState("");
    const [inscripcion_Correo_FACT, setInscripcion_Correo_FACT] = useState("");
    const [inscripcion_Responsable, setInscripcion_Responsable] = useState("");
    const [inscripcion_Correo_RESP, setInscripcion_Correo_RESP] = useState("");
    const [inscripcion_Tel_RESP, setInscripcion_Tel_RESP] = useState("");
    const [inscripcion_Responsable_PAGO, setInscripcion_Responsable_PAGO] = useState("");
    const [inscripcion_Correo_RP, setInscripcion_Correo_RP] = useState("");
    const [inscripcion_Tel_RP, setInscripcion_Tel_RP] = useState("");
    const [inscripcion_Comentario, setInscripcion_Comentario] = useState("");
    const [evento_Nombre, setEvento_Nombre] = useState("");
    const [forma_pago_Nombre, setForma_pago_Nombre] = useState("");
    const [moneda_Nombre, setMoneda_Nombre] = useState("");
    const [detalle, setDetalle] = useState([]);
    const [asesor, setAsesor] = useState({});
    const [empresa, setEmpresa] = useState({});
    const { id } = useParams();
    const [comprobante, setComprobante] = useState(null);
    var endPoint = process.env.REACT_APP_DEFAULT_URL + "/api/read_id/inscripcion/" + id;
    React.useEffect(() => {
        fetch(endPoint, { cache: "no-cache" })
            .then(res => {
                return res.json();
            })
            .then(data => {
                console.log(data);
                setInscripcion_Agremiado(data.rows[0].inscripcion_Agremiado);
                setInscripcion_Inversion(data.rows[0].inscripcion_Inversion);
                setInscripcion_Fecha_Max_FACT(data.rows[0].inscripcion_Fecha_Max_FACT);
                setInscripcion_Nombre_FACT(data.rows[0].inscripcion_Nombre_FACT);
                setInscripcion_NIT(data.rows[0].inscripcion_NIT);
                setInscripcion_Direccion(data.rows[0].inscripcion_Direccion);
                setInscripcion_Correo_FACT(data.rows[0].inscripcion_Correo_FACT);
                setInscripcion_Responsable(data.rows[0].inscripcion_Responsable);
                setInscripcion_Correo_RESP(data.rows[0].inscripcion_Correo_RESP);
                setInscripcion_Responsable_PAGO(data.rows[0].inscripcion_Responsable_PAGO);
                setInscripcion_Correo_RP(data.rows[0].inscripcion_Correo_RP);
                setInscripcion_Tel_RP(data.rows[0].inscripcion_Tel_RP);
                setInscripcion_Comentario(data.rows[0].inscripcion_Comentario);
                setEvento_Nombre(data.rows[0].evento_Nombre);
                setForma_pago_Nombre(data.rows[0].forma_pago_Nombre);
                setMoneda_Nombre(data.rows[0].moneda_Nombre);
                setInscripcion_Tel_RESP(data.rows[0].inscripcion_Tel_RESP);
                setDetalle(data.detalle);
                setAsesor(data.asesor[0]);
                setEmpresa(data.rows[0].empresa);
                setComprobante(data.rows[0].comprobante);
            });
    }, []);
    return (
        <div className="contenedor-view-inscripcion">
            <Container>
                <Row>
                    <h1 className="title-contancia">Confirmación de inscripción</h1>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <Container>
                            <Row >
                                <Col>
                                    <div className="columna-formulario-view" style={{ padding: '2%', textAlign: 'center' }}>
                                        <Form.Group className="mb-3" controlId="Agremiado" style={{ color: 'white' }}>
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                className="font-normal"
                                                name="Agremiado"
                                                label="Agremiados"
                                                style={{ textAlign: 'left' }}
                                                disabled
                                                checked={inscripcion_Agremiado}
                                            />
                                        </Form.Group>
                                        <Accordion defaultActiveKey="0" className="bg-success" style={{ marginTop: '5%' }}>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header className="font-bold">INFORMACIÓN DEL EVENTO</Accordion.Header>
                                                <Accordion.Body className="font-normal">
                                                    <Form.Group className="mb-3" controlId="Forma_Pago_UniqueID">
                                                        <Form.Label>Asesor</Form.Label>
                                                        <Form.Control type="text" value={asesor.Nombres + " " + asesor.Apellidos} disabled aria-label="Asesor" name="Asesor_UniqueID"></Form.Control>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="Inversion">
                                                        <Form.Label>Inversión</Form.Label>
                                                        <InputGroup className="mb-3">
                                                            <InputGroup.Text>{moneda_Nombre}</InputGroup.Text>
                                                            <Form.Control disabled name="Inversion" placeholder="Ingrese el monto de inversión" value={inscripcion_Inversion/detalle.length} />
                                                        </InputGroup>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="Evento_Unique_ID">
                                                        <Form.Label>Evento</Form.Label>
                                                        <Form.Control type="text" disabled aria-label="Evento" name="Evento_Unique_ID" value={evento_Nombre} ></Form.Control>
                                                    </Form.Group>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header className="font-bold">DATOS PARA LA FACTURA ELECTRÓNICA</Accordion.Header>
                                                <Accordion.Body className="font-normal">
                                                    <Form.Group className="mb-3" controlId="Fecha_Max_FACT">
                                                        <Form.Label>Fecha máxima que reciben facturas</Form.Label>
                                                        <Form.Control disabled type="date" name="Fecha_Max_FACT" placeholder="Seleccione la fecha" value={inscripcion_Fecha_Max_FACT} />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="Nombre_FACT">
                                                        <Form.Label>Nombre para la factura</Form.Label>
                                                        <Form.Control disabled type="text" name="Nombre_FACT" placeholder="Consumidor Final" value={inscripcion_Nombre_FACT} />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="NIT">
                                                        <Form.Label>NIT</Form.Label>
                                                        <Form.Control disabled type="text" placeholder="CF" name="NIT" value={inscripcion_NIT} />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="Direccion">
                                                        <Form.Label>Dirección</Form.Label>
                                                        <Form.Control disabled type="text" placeholder="Ciudad" name="Direccion" value={inscripcion_Direccion} />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="Correo_FACT">
                                                        <Form.Label>Correo para enviar factura electrónica</Form.Label>
                                                        <Form.Control disabled type="text" placeholder="Por favor ingrese el correo" name="Correo_FACT" value={inscripcion_Correo_FACT} />
                                                    </Form.Group>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header className="font-bold">CONTACTO Y FORMA DE PAGO</Accordion.Header>
                                                <Accordion.Body className="font-normal">
                                                    <Row>
                                                        <Col xs={12} md={12}>
                                                            <Form.Group className="mb-3" controlId="Responsable">
                                                                <Form.Label>Empresa</Form.Label>
                                                                <Form.Control disabled type="text" placeholder="Ingrese la empresa" name="empresa" value={empresa} />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs={12} md={12}>
                                                            <Form.Group className="mb-3" controlId="Responsable">
                                                                <Form.Label>Responsable de la inscripción</Form.Label>
                                                                <Form.Control disabled type="text" placeholder="Ingrese el responsable" name="Responsable" value={inscripcion_Responsable} />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs={12} md={8}>
                                                            <Form.Group className="mb-3" controlId="Correo_RESP">
                                                                <Form.Label>Correo</Form.Label>
                                                                <Form.Control disabled type="text" placeholder="Ingrese el correo" name="Correo_RESP" value={inscripcion_Correo_RESP} />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs={12} md={4}>
                                                            <Form.Group className="mb-3" controlId="Tel_RESP">
                                                                <Form.Label>Tel.</Form.Label>
                                                                <Form.Control disabled type="text" placeholder="Ingrese el teléfono" name="Tel_RESP" value={inscripcion_Tel_RESP} />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs={12} md={12}>
                                                            <Form.Group className="mb-3" controlId="Responsable_PAGO">
                                                                <Form.Label>Persona responsable del trámite de Pago</Form.Label>
                                                                <Form.Control disabled type="text" placeholder="Ingrese el responsable" name="Responsable_PAGO" value={inscripcion_Responsable_PAGO} />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs={12} md={8}>
                                                            <Form.Group className="mb-3" controlId="Correo_RP">
                                                                <Form.Label>Correo</Form.Label>
                                                                <Form.Control disabled type="email" placeholder="Ingrese el correo" name="Correo_RP" value={inscripcion_Correo_RP} />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs={12} md={4}>
                                                            <Form.Group className="mb-3" controlId="Tel_RP">
                                                                <Form.Label>Tel.</Form.Label>
                                                                <Form.Control disabled type="text" placeholder="Ingrese el teléfono" name="Tel_RP" value={inscripcion_Tel_RP} />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs={12} md={12}>
                                                            <Form.Group className="mb-3" controlId="Forma_Pago_UniqueID">
                                                                <Form.Label>Indique su forma de pago</Form.Label>
                                                                <Form.Control type="text" disabled aria-label="Evento" name="Forma_Pago_UniqueID" value={forma_pago_Nombre}></Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs={12} md={12}>
                                                            <Form.Group className="mb-3" controlId="Comentario">
                                                                <Form.Label>Comentario sobre el pago</Form.Label>
                                                                <Form.Control disabled as="textarea" rows={3} style={{ resize: 'none' }} name="Comentario" value={inscripcion_Comentario} />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                    <Col xs={12} md={6}>
                        <Container>
                            <Row>
                                <Col>
                                    <div className="view-detalle" style={{ padding: '2%', textAlign: 'center' }}>
                                        <Accordion>
                                            {detalle.map(actual =>
                                                <Accordion.Item eventKey={actual.UniqueID} key={actual.UniqueID}>
                                                    <Accordion.Header>{actual.Nombres + ' ' + actual.Apellidos}</Accordion.Header>
                                                    <Accordion.Body>
                                                        <Container>
                                                            <Row>
                                                                <Form.Group className="mb-3" >
                                                                    <Form.Label>Documento de identificacion</Form.Label>
                                                                    <Form.Control disabled type="text" value={actual.identificacion} />
                                                                </Form.Group>
                                                            </Row>
                                                            <Row>
                                                                <Form.Group className="mb-3" >
                                                                    <Form.Label>Nombres</Form.Label>
                                                                    <Form.Control disabled type="text" value={actual.Nombres} />
                                                                </Form.Group>
                                                            </Row>
                                                            <Row>
                                                                <Form.Group className="mb-3" >
                                                                    <Form.Label>Apellidos</Form.Label>
                                                                    <Form.Control disabled type="text" value={actual.Apellidos} />
                                                                </Form.Group>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={12} md={6}>
                                                                    <Form.Group className="mb-3" >
                                                                        <Form.Label>Puesto</Form.Label>
                                                                        <Form.Control disabled type="text" value={actual.Puesto} />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col xs={12} md={6}>
                                                                    <Form.Group className="mb-3" >
                                                                        <Form.Label>Correo Electrónico</Form.Label>
                                                                        <Form.Control disabled type="email" value={actual.Correo} />
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={12} md={6}>
                                                                    <Form.Group className="mb-3" >
                                                                        <Form.Label>Teléfono y extensión</Form.Label>
                                                                        <Form.Control disabled type="text" value={actual.Telefono} />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col xs={12} md={6}>
                                                                    <Form.Group className="mb-3" >
                                                                        <Form.Label>Celular</Form.Label>
                                                                        <Form.Control disabled type="text" value={actual.celular} />
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                        </Container>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            )}
                                        </Accordion>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
                {comprobante != null && <Row style={{ color: 'white', paddingBottom:'75px', paddingTop:'25px' }}>
                    <h1 className="title-contancia">Scan de cheque o depósito/transferencia</h1>
                    <iframe src = {comprobante} alt = 'comprobante' style = {{height:'800px'}}/>
                </Row>}
            </Container>
        </div>
    );

}