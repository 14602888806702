import React, { useEffect, useState } from 'react';
import { Col, Card } from 'react-bootstrap';
const DateTimeDisplay = ({ value, type, isDanger }) => {

    return (
        <Col xs={12} md={12} lg={6} >
            <Card style={{ backgroundColor: 'transparent', padding: '2%' }}>
                <Card.Title style = {{fontSize:'25em'}}>{value}</Card.Title>
                <Card.Body>
                    <Card.Text style = {{fontSize:'5em'}}>{type}</Card.Text>
                </Card.Body>
            </Card>
        </Col>
    );
};

export default DateTimeDisplay;
