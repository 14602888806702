import React, { useEffect, useState } from 'react';
import { Row, Spinner, Container, Form, Alert, Button } from "react-bootstrap";
import axios from 'axios';
//import DEFAULT_URL from '../../../../../api/url';
const EnvioEmails = (props) => {
    const [subject, setSubject] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [contador, setContador] = useState(0);
    const [formulario, setFormulario] = useState('block');
    const [exito, setExito] = useState('none');
    const [error, setError] = useState('none');
    const [cargando, setCargando] = useState('none');
    const [log, setLog] = useState('');
    useEffect(() => {

    }, [contador]);

    const sendMails = (evt) => {
        evt.preventDefault();
        setCargando('block');
        setFormulario('none');
        setContador(contador + 1);
        const data = new FormData();
        data.append('file', selectedFile);
        axios.post(process.env.REACT_APP_DEFAULT_URL+ "/api/files/upload", data, {
        }).then(res => {
            if (res.status == 200) {
                const info = { path: res.data.res, eventoUniqueID: props.evento.UniqueID, subject: subject };
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(info)
                };
                fetch(process.env.REACT_APP_DEFAULT_URL + '/api/email/send/email/html/eventList', requestOptions)
                    .then((res) => {
                        return res.json();
                    })
                    .then(data => {
                        if (data.codigo == 200) {
                            setExito('block');
                            setLog(data.log);
                            setCargando('none');
                        } else {
                            setError('block');
                            setCargando('none');
                        }
                    });
            } else {
                setError('block');
                setCargando('none');
            }
            Array.from(document.querySelectorAll("input")).forEach(
                input => (input.value = "")
            );
            setSelectedFile(null);
            setSubject('');
            setContador(contador + 1);
        });

    }
    const onChangeEmails = (evt) => {
        switch (evt.target.name) {
            case "subject":
                setSubject(evt.target.value);
                break;
            case "body-email":
                setSelectedFile(evt.target.files[0]);
                break;
        }
        setContador(contador + 1);
    }
    return (
        <Container style={{ border: '1px dashed black', padding: '50px', borderRadius: '50px' }}>
            <Row>
                <h1>Envío de correos masivos</h1>
            </Row>
            <Row style={{ marginTop: '20px', display: formulario }}>
                <Form onSubmit={sendMails}>
                    <Form.Group className="mb-3" controlId="sujeto">
                        <Form.Label>Asunto de correo electrónico</Form.Label>
                        <Form.Control type="text" required placeholder="Ingrese el asunto del correo" name="subject" onChange={onChangeEmails} value={subject} />
                    </Form.Group>
                    <Form.Group controlId="formFileMultiple" className="mb-3">
                        <Form.Label>Archivo con HTML de correo electrónico</Form.Label>
                        <Form.Control required type="file" onChange={onChangeEmails} name="body-email" />
                    </Form.Group>
                    <Button type="submit" variant="dark">Enviar correo masivo</Button>
                </Form>
            </Row>
            <Row style={{ marginTop: '20px', display: cargando }}>
                <Alert variant="dark">
                    <Alert.Heading>
                        <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                        &nbsp;&nbsp;&nbsp;Enviando correo masivo
                    </Alert.Heading>
                    <p>
                        Esto puede tardar unos minutos
                    </p>
                    <hr />
                    <p className="mb-0">
                        Por favor espere...
                    </p>
                </Alert>
            </Row>
            <Row style={{ marginTop: '20px', display: error }}>
                <Alert variant="warning">
                    <Alert.Heading>Hubo un problema con el envío del correo masivo</Alert.Heading>
                    <p>
                        Por favor recargue la página e inténtelo de nuevo
                    </p>
                    <hr />
                    <div className="d-flex justify-content-end">
                        <Button onClick={() => { setFormulario('block'); setError('none'); setContador(contador + 1); }} variant="outline-warning">
                            Ok
                        </Button>
                    </div>
                </Alert>
            </Row>
            <Row style={{ marginTop: '20px', display: exito }}>
                <Alert variant="success">
                    <Alert.Heading>{log}</Alert.Heading>
                    <p>
                        Correo masivo enviado exitosamente
                    </p>
                    <hr />
                    <div className="d-flex justify-content-end">
                        <Button onClick={() => { setFormulario('block'); setExito('none'); setContador(contador + 1); }} variant="outline-success">
                            Ok
                        </Button>
                    </div>
                </Alert>
            </Row>
        </Container>
    )
}

export default EnvioEmails