import React, { useState, useEffect } from "react";
import { Row, Container, Col } from "react-bootstrap";
import "./style.css";
import Read from "../../helpers/read";
import ActionButton from "../../helpers/Action";
const helpers = require("../../helpers/helpers");
const InHouse = (props) => {
  const [contador] = useState(0);
  const [config, setConfig] = useState(null);
  useEffect(() => {
    helpers
      .getData("/api/auth/userinfo")
      .then((data_usr) => {
        setConfig({
          endpoint:
            data_usr.data.tipo != 1
              ? "/api/read/admin/propuesta"
              : "/api/read/propuesta/" + data_usr.data.UniqueID,
          onDelete: data_usr.data.tipo != 1 ? "/api/delete/propuesta" : null,
          onUpdate: null,
          onRead: "/in-house/seguimiento/",
          key: "UniqueID",
          properties: [
            { label: "Empresa solicitante", field: "institucion" },
            { label: "Fecha registro", field: "fecha" },
            { label: "Fecha propuesta", field: "fechaPropuesta" },
          ],
          withActions: true,
          confirmationMensage:
            "¿Está segur@ de eliminar la propuesta seleccionada?",
          placeHolder: "puedes buscar por nombre de institucion",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [contador]);
  const filterEvt = (actual, valor) => {
    return actual.institucion.search(valor) != -1;
  };

  return (
    <Container fluid style={{ padding: "3%" }}>
      <Row>
        <h1>Propuestas IN-HOUSE Registradas</h1>
      </Row>
      <Row className="mt-5">
        <Col xs={12} md={6} lg={4}>
          <ActionButton
            configuration={{
              type: 1,
              url: "/in-house/registrar",
              icon: "pencil",
              variant: "outline-primary",
              title: "Registrar propuesta",
            }}
          />
        </Col>
      </Row>
      <Row>
        {config != null && (
          <Read
            configuration={config}
            filterEvt={filterEvt}
            actionAfterDelete={() => {
              window.location.reload();
            }}
          />
        )}
      </Row>
    </Container>
  );
};

export default InHouse;
