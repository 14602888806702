import React, { useState, useEffect } from 'react'
import { Row, Button, Form, Container, Alert, Spinner, Col } from "react-bootstrap";
//import DEFAULT_URL from '../../api/url';
import axios from 'axios';
const FormFacilitador = (props) => {
  const [contador, setContador] = useState(0);
  const [nombres, setNombres] = useState('');
  const [apellidos, setApellidos] = useState('');
  const [telefono, setTelefono] = useState('');
  const [correo, setCorreo] = useState('');
  const [tarifa, setTarifa] = useState(0);
  const [foto, setFoto] = useState(null);
  const [experiencia, setExperiencia] = useState([]);
  const [tipos, setTipos] = useState([]);
  const [temas, setTemas] = useState([]);
  const [biblioteca, setBiblioteca] = useState([]);
  const [mensaje, setMensaje] = useState('');
  const [exito, setExito] = useState('none');
  const [formulario, setFormulario] = useState('block');
  const [error, setError] = useState('none');
  const [cargando, setCargando] = useState('none');
  useEffect(() => {
    async function fetchData() {
      fetch(process.env.REACT_APP_DEFAULT_URL + '/api/facilitadores/read/tipo-experiencia', { cache: "no-cache" })
        .then((res) => {
          return res.json();
        })
        .then(data => {
          setTipos(data.rows);
        });
      fetch(process.env.REACT_APP_DEFAULT_URL + '/api/temas/read', { cache: "no-cache" })
        .then((res) => {
          return res.json();
        })
        .then(data => {
          setBiblioteca(data.salida);
        });
    }
    fetchData();
  }, [contador]);
  const onChange = (evt) => {
    if (evt.target.name == "nombres") {
      setNombres(evt.target.value);
    } else if (evt.target.name == "apellidos") {
      setApellidos(evt.target.value);
    } else if (evt.target.name == "correo") {
      setCorreo(evt.target.value);
    } else if (evt.target.name == "tarifa") {
      setTarifa(evt.target.value);
    } else if (evt.target.name == "foto") {
      setFoto(evt.target.files[0]);
    } else if (evt.target.name == "telefono") {
      setTelefono(evt.target.value);
    }
    setContador(contador + 1);
  }
  const cargar = (mensaje) => {
    setExito('none');
    setFormulario('none');
    setError('none');
    setCargando('block');
    setMensaje(mensaje);
    setContador(contador + 1);
  }
  const operacionExito = (mensaje) => {
    setExito('block');
    setFormulario('none');
    setError('none');
    setCargando('none');
    setMensaje(mensaje);
    props.refresh();
    setContador(contador + 1);
  }
  const operacionError = (mensaje) => {
    setExito('none');
    setFormulario('none');
    setError('block');
    setCargando('none');
    setMensaje(mensaje);
    setContador(contador + 1);
  }
  const onSubmit = (evt) => {
    evt.preventDefault();
    if (experiencia.length == 0) {
      operacionError('Se debe ingresar la experiencia del facilitador');
    } else if (temas.length == 0) {
      operacionError('Se debe ingresar la biblioteca del facilitador');
    } else if (experiencia.length != 0 && temas.length != 0) {
      const data = new FormData();
      data.append('file', foto);
      cargar('Subiendo archivo');
      axios.post(process.env.REACT_APP_DEFAULT_URL+ "/api/files/upload/s3", data, {}).then(res => {
        cargar('Sincronizando db');
        const info = {
          Nombres: nombres,
          Apellidos: apellidos,
          Telefono: telefono,
          Correo: correo,
          Tarifa: tarifa,
          Foto: res.data.resultado.Location,
          keyFoto: res.data.resultado.Key,
          experiencia: experiencia,
          biblioteca: temas
        };
        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/facilitadores/create', requestOptions)
          .then((res) => {
            return res.json();
          })
          .then(data => {
            if (data.codigo == 200) {
              operacionExito('Facilitador registrado exitosamente');
            } else {
              operacionError('Error al grabar registro en db');
            }
          });
      });
    }
  }
  const goBack = () => {
    setExito('none');
    setFormulario('block');
    setError('none');
    setCargando('none');
    setNombres('');
    setApellidos('');
    setCorreo('');
    setTarifa('');
    setFoto(null);
    setTelefono('');
    setExperiencia([]);
    setTemas([]);
    Array.from(document.querySelectorAll("input")).forEach(
      input => (input.value = "")
    );
    setContador(contador + 1);
  }
  const onChangeExperiencia = (evt) => {
    const arr = evt.target.name.split('-');
    let temp = experiencia;
    let indice = parseInt(arr[0]);
    if (arr[1] == "descripcion") {
      temp[indice].descripcion = evt.target.value;
    } else if (arr[1] == "tipo") {
      temp[indice].tipo = evt.target.value;
    }
    setExperiencia(temp);
    setContador(contador + 1);
  }
  const onChangeTema = (evt) => {
    const arr = evt.target.name.split('-');
    let temp = temas;
    let indice = parseInt(arr[0]);
    if (arr[1] == "tema") {
      temp[indice].UniqueID = evt.target.value;
    }
    setTemas(temp);
    setContador(contador + 1);
  }
  return (
    <Container style={{ padding: '5%', border: '0.5px dotted black', borderRadius: '1%' }}>
      <Row style={{ display: formulario }}>
        <Container>
          <Form onSubmit={onSubmit}>
            <Row>
              <h1>Ingresar Facilitador</h1>
            </Row>
            <Row>
              <Form.Group className="mb-3" controlId="nombres">
                <Form.Label>Nombres del facilitador</Form.Label>
                <Form.Control required type="text" placeholder="Ingrese los nombres de la persona" name="nombres" value={nombres} onChange={onChange} />
              </Form.Group>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={6}>
                <Form.Group className="mb-3" controlId="apellidos">
                  <Form.Label>Apellidos</Form.Label>
                  <Form.Control required type="text" placeholder="Ingrese los apellidos de la persona" name="apellidos" value={apellidos} onChange={onChange} />
                </Form.Group>
              </Col>
              <Col xs={12} md={12} lg={6}>
                <Form.Group className="mb-3" controlId="telefono">
                  <Form.Label>Teléfono</Form.Label>
                  <Form.Control required type="text" placeholder="Ingrese el teléfono de la persona" name="telefono" value={telefono} onChange={onChange} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={6}>
                <Form.Group className="mb-3" controlId="correo">
                  <Form.Label>Correo electrónico</Form.Label>
                  <Form.Control required type="text" placeholder="Ingrese el correo electrónico de la persona" name="correo" value={correo} onChange={onChange} />
                </Form.Group>
              </Col>
              <Col xs={12} md={12} lg={6}>
                <Form.Group className="mb-3" controlId="tarifa">
                  <Form.Label>Tarifa</Form.Label>
                  <Form.Control required type="text" placeholder="Ingrese la tarifa" name="tarifa" value={tarifa} onChange={onChange} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={12}>
                <Form.Group className="mb-3" controlId="archivo">
                  <Form.Label>Seleccione el archivo con la foto del facilitador</Form.Label>
                  <Form.Control required type="file" name='foto' onChange={onChange} accept="image/x-png,image/gif,image/jpeg" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <h3>Experiencia del facilitador</h3>
              <Container>
                <Row style={{ padding: '2%' }}>
                  <Button variant='outline-success' onClick={() => { experiencia.push({ descripcion: '', tipo: 1 }); setContador(contador + 1); }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                    </svg>&nbsp;&nbsp;&nbsp;Añadir experiencia
                  </Button>
                </Row>
                {experiencia.map((actual, index) => <Row style={{ display: 'flex', placeItems: 'center' }}>
                  <Col xs={12} md={12} lg={4}>
                    <Form.Group className="mb-3" controlId={index + "-descripcion"}>
                      <Form.Label>Descripción</Form.Label>
                      <Form.Control required as="textarea" rows={3} style={{ resize: 'none' }} placeholder="ingrese una breve descripción de la experiencia del docente" name={index + '-descripcion'} value={actual.descripcion} onChange={onChangeExperiencia} />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={12} lg={4}>
                    <Form.Group className="mb-3" controlId={index + "-tipo"}>
                      <Form.Label>Indique el tipo de experiencia</Form.Label>
                      <Form.Control as="select" required aria-label="Tipo" name={index + '-tipo'} onChange={onChangeExperiencia} value={actual.tipo}>
                        <option value="" key="0">Seleccione el tipo de experiencia</option>
                        {tipos.map(tipo => <option key={tipo.UniqueID} value={tipo.UniqueID}>{tipo.Nombre}</option>)}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={12} lg={4}>
                    <Row>
                      <Button onClick={() => { experiencia.splice(index, 1); setContador(contador + 1); }} variant='outline-danger'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3-fill" viewBox="0 0 16 16">
                        <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                      </svg>&nbsp;&nbsp;&nbsp;Eliminar experiencia</Button>
                    </Row>
                  </Col>
                </Row>)}
              </Container>
            </Row>
            <Row>
              <h3>Manejo de biblioteca</h3>
              <Container>
                <Row style={{ padding: '2%' }}>
                  <Button variant='outline-success' onClick={() => { temas.push({ UniqueID: '' }); setContador(contador + 1); }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                    </svg>&nbsp;&nbsp;&nbsp;Añadir tema de biblioteca
                  </Button>
                </Row>
                {temas.map((actual, index) => <Row style={{ display: 'flex', placeItems: 'center' }}>
                  <Col xs={12} md={12} lg={6}>
                    <Form.Group className="mb-3" controlId={index + "-tema"}>
                      <Form.Label>Seleccione un tema de la biblioteca de contenidos</Form.Label>
                      <Form.Control as="select" required aria-label="Tipo" name={index + '-tema'} onChange={onChangeTema} value={actual.UniqueID}>
                        <option value="" key="0">Seleccione el tema</option>
                        {biblioteca.map(tema => <option key={tema.UniqueID} value={tema.UniqueID}>{tema.nombre}</option>)}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={12} lg={6}>
                    <Row>
                      <Button onClick={() => { temas.splice(index, 1); setContador(contador + 1); }} variant='outline-danger'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3-fill" viewBox="0 0 16 16">
                        <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                      </svg>&nbsp;&nbsp;&nbsp;Eliminar tema</Button>
                    </Row>
                  </Col>
                </Row>)}
              </Container>
            </Row>
            <Row>
              <Button variant="outline-dark" className="font-bold" type="submit">Guardar</Button>
            </Row>
          </Form>
        </Container>
      </Row>
      <Row style={{ display: exito }}>
        <Alert variant='success'>
          <Alert.Heading>
            Operación realizada con éxito
          </Alert.Heading>
          <p>
            {mensaje}
          </p>
          <hr />
          <div className="d-flex justify-content-end">
            <Button variant='outline-success' onClick={goBack}>
              Ok
            </Button>
          </div>
        </Alert>
      </Row>
      <Row style={{ display: error }}>
        <Alert variant='danger'>
          <Alert.Heading>
            Ocurrió un error al ingresar al facilitador
          </Alert.Heading>
          <p>
            {mensaje}
          </p>
          <hr />
          <div className="d-flex justify-content-end">
            <Button variant='outline-danger' onClick={goBack}>
              Ok
            </Button>
          </div>
        </Alert>
      </Row>
      <Row style={{ display: cargando }}>
        <Alert variant='dark'>
          <Alert.Heading>
            <Spinner animation="border" role="status">
            </Spinner>&nbsp;&nbsp;&nbsp;
            Ingresando información
          </Alert.Heading>
          <p>
            {mensaje}
          </p>
          <hr />
          <p>
            Por favor espere...
          </p>
        </Alert>
      </Row>
    </Container>)
}

export default FormFacilitador