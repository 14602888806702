import React, { useState, useEffect } from 'react'
//import DEFAULT_URL from '../../../../../api/url';
import { Row, Alert, Button, Spinner, Popover, Col, OverlayTrigger, Form, Container, Accordion, ButtonToolbar, ButtonGroup, DropdownButton, InputGroup } from "react-bootstrap";
const popover = (
    <Popover id="popover-basic">
        <Popover.Header as="h3">¡Listo!</Popover.Header>
        <Popover.Body>
            Enlace copiado exitosamente.
        </Popover.Body>
    </Popover>
);
const DetalleEnlace = (props) => {
    const [contador, setContador] = useState(0);
    const [detalle, setDetalle] = useState('none');
    const [portada, setPortada] = useState('block');
    const [exito, setExito] = useState('none');
    const [error, setError] = useState('none');
    const [cargando, setCargando] = useState('none');
    const [confirmacion, setConfirmacion] = useState('none');
    const [mensaje, setMensaje] = useState('');
    useEffect(() => {

    }, [contador]);

    const deleteSelf = () => {
        cargar('Validando información');
        if (props.enlaces == 1) {
            operacionError('La sesión debe tener por lo menos 1 enlace');
        } else {
            cargar('Sincronizando base de datos');
            const info = { UniqueID: props.enlace.UniqueID };
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(info)
            };
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/delete/enlace/' + props.enlace.UniqueID, requestOptions)
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    if (data.codigo === 200) {
                        operacionExito('Enlace eliminado exitosamente');
                    } else {
                        operacionError('Error al eliminar registro en db');
                    }
                });
        }
    }
    const cargar = (mensaje) => {
        setExito('none');
        setDetalle('none');
        setPortada('none');
        setError('none');
        setCargando('block');
        setConfirmacion('none');
        setMensaje(mensaje);
        setContador(contador + 1);
    }
    const confirmar = () => {
        setExito('none');
        setDetalle('none');
        setPortada('none');
        setError('none');
        setCargando('none');
        setConfirmacion('block');
        setContador(contador + 1);
    }
    const operacionExito = (mensaje) => {
        setExito('block');
        setDetalle('none');
        setPortada('none');
        setError('none');
        setCargando('none');
        setMensaje(mensaje);
        setContador(contador + 1);
    }
    const operacionError = (mensaje) => {
        setDetalle('none');
        setPortada('none');
        setExito('none');
        setError('block');
        setCargando('none');
        setMensaje(mensaje);
        setContador(contador + 1);
    }

    const goBack = () => {
        setDetalle('none');
        setPortada('block');
        setExito('none');
        setError('none');
        setCargando('none');
        setConfirmacion('none');
        setMensaje('');
        setContador(contador + 1);
    }
    const goToDetail = () => {
        setDetalle('block');
        setPortada('none');
        setExito('none');
        setError('none');
        setCargando('none');
        setConfirmacion('none');
        setMensaje('');
        setContador(contador + 1);
    }
    return (
        <Row style={{ marginTop: '15px', border: '0.5px dashed black', borderRadius: '15px', padding: '25px' }}>
            <Container fluid>
                <Row style={{ display: portada }}>
                    <Container fluid>
                        <Row style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                            <Col xs={6} md={6} lg={6}>
                                {props.enlace.TipoEnlace_UniqueID == 1 && <img src='https://escuela-bancaria.s3.us-east-2.amazonaws.com/zoom+(1).png' style={{ width: '50%' }} alt='logo' />}
                                {props.enlace.TipoEnlace_UniqueID == 2 && <img src='https://escuela-bancaria.s3.us-east-2.amazonaws.com/facebook.png' style={{ width: '50%' }} alt='logo' />}
                                {props.enlace.TipoEnlace_UniqueID == 3 && <img src='https://escuela-bancaria.s3.us-east-2.amazonaws.com/youtube.png' style={{ width: '50%' }} alt='logo' />}
                                {props.enlace.TipoEnlace_UniqueID == 4 && <img src='https://escuela-bancaria.s3.us-east-2.amazonaws.com/Logo+virtual+ABG+2022-01.png' style={{ width: '50%' }} alt='logo' />}

                            </Col>
                            <Col xs={6} md={6} lg={6}>
                                <h4>{props.enlace.Nombre}</h4>
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: '15px' }}>
                            <OverlayTrigger trigger="click" placement="left" overlay={popover} rootClose>
                                <Button variant='outline-dark' onClick={() => { navigator.clipboard.writeText(props.enlace.url); }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-clipboard-check" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                                        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                                        <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                                    </svg>&nbsp;&nbsp;&nbsp;&nbsp;Copiar enlace al portapapeles
                                </Button>
                            </OverlayTrigger>
                        </Row>
                        <Row>
                            <Button variant='outline-info' onClick={goToDetail}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-square-fill" viewBox="0 0 16 16">
                                    <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                </svg>&nbsp;&nbsp;&nbsp;&nbsp;Más información
                            </Button>
                        </Row>
                    </Container>
                </Row>
                <Row style={{ display: detalle }}>
                    <Container fluid>
                        <Row>
                            <h5>{props.enlace.Nombre}</h5>
                            <Form.Control as="textarea" disabled placeholder='url' rows={3} style={{ resize: 'none' }} value={props.enlace.url} />
                        </Row>
                        <Row>
                            <h5>Comentario o instrucciones</h5>
                            <Form.Control as="textarea" disabled placeholder='url' rows={3} style={{ resize: 'none' }} value={props.enlace.comentario} />
                        </Row>
                        <Row style={{ marginTop: '15px' }}>
                            <Button variant='outline-danger' onClick={confirmar}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3" viewBox="0 0 16 16">
                                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                </svg>&nbsp;&nbsp;&nbsp;&nbsp;Eliminar enlace
                            </Button>
                        </Row>
                        <Row style={{ marginTop: '15px' }}>
                            <Button variant='outline-dark' onClick={goBack}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-90deg-left" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M1.146 4.854a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H12.5A2.5 2.5 0 0 1 15 6.5v8a.5.5 0 0 1-1 0v-8A1.5 1.5 0 0 0 12.5 5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4z" />
                                </svg>&nbsp;&nbsp;&nbsp;&nbsp;Atrás
                            </Button>
                        </Row>
                    </Container>
                </Row>
                <Row style={{ display: exito }}>
                    <Alert variant='success'>
                        <Alert.Heading>
                            Operación realizada con éxito
                        </Alert.Heading>
                        <p>
                            {mensaje}
                        </p>
                        <hr />
                        <div className="d-flex justify-content-end">
                            <Button variant='outline-success' onClick={() => { goBack(); props.refresh(); }}>
                                Ok
                            </Button>
                        </div>
                    </Alert>
                </Row>
                <Row style={{ display: error }}>
                    <Alert variant='danger'>
                        <Alert.Heading>
                            Ocurrió un error al ingresar la propuesta
                        </Alert.Heading>
                        <p>
                            {mensaje}
                        </p>
                        <hr />
                        <div className="d-flex justify-content-end">
                            <Button variant='outline-danger' onClick={goBack}>
                                Ok
                            </Button>
                        </div>
                    </Alert>
                </Row>
                <Row style={{ display: cargando }}>
                    <Alert variant='dark'>
                        <Alert.Heading>
                            <Spinner animation="border" role="status">
                            </Spinner>&nbsp;&nbsp;&nbsp;
                            Ingresando información
                        </Alert.Heading>
                        <p>
                            {mensaje}
                        </p>
                        <hr />
                        <p>
                            Por favor espere...
                        </p>
                    </Alert>
                </Row>
                <Row style={{ display: confirmacion }}>
                    <Alert variant='warning'>
                        <Alert.Heading>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
                                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                            </svg>&nbsp;&nbsp;&nbsp;Se necesita confirmación
                        </Alert.Heading>
                        <p>
                            ¿Está segur@ de eliminar el enlace seleccionado?
                            <br />
                            <strong>Tipo de enlace: </strong> {props.enlace.Nombre}
                            <br />
                            <strong>Enlace: </strong> {props.enlace.url}
                        </p>
                        <hr />
                        <div className="d-flex justify-content-end">
                            <Button variant='outline-danger' onClick={deleteSelf}>
                                si
                            </Button>&nbsp;&nbsp;&nbsp;
                            <Button variant='outline-dark' onClick={goBack}>
                                no
                            </Button>
                        </div>
                    </Alert>
                </Row>
            </Container>
        </Row>
    )
}

export default DetalleEnlace