import React, { useState, useEffect } from 'react';
import { Modal, Row, Tab, Col, ListGroup } from "react-bootstrap";
//import DEFAULT_URL from '../../../../api/url';
import DetalleCarpeta from './DetalleCarpeta';
import ModalNuevaCarpeta from './ModalNuevaCarpeta';
import './ModalMaterial.css'
const ModalMaterial = (props) => {
    const [contador, setContador] = useState(0);
    const [carpetas, setCarpetas] = useState([]);
    const [modalNuevaCarpeta, setModalNuevaCarpeta] = useState(false);
    useEffect(() => {
        async function fetchData() {
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/material/carpetas/iniciales/' + props.evento_uniqueid, { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setCarpetas(data.rows);
                });
        }
        fetchData();
    }, [contador]);
    const hideModalNuevaCarpeta = () => {
        setModalNuevaCarpeta(false);
    }
    const entering = () => {
        setContador(contador + 1);
    }
    return (
        <Modal
            {...props}
            size="fullscreen"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
            onEnter={entering}
        >
            <Modal.Header closeButton style={{ backgroundColor: "#1e3d52", borderColor: 'transparent', color: 'white' }}>
                <Modal.Title id="contained-modal-title-vcenter" className="font-bold">
                    Material Del Evento
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: "#1e3d52", borderColor: 'transparent', color: 'white' }} id="body-modal-edit-evento">
                <Tab.Container id="list-group-tabs-example">
                    <Row style={{ marginBottom: '25px' }}>
                        {props.rol != 1 && <Col xs={1} md={1} lg={1} >
                            <div style={{ color: 'white', cursor: 'pointer' }} data-toggle="tooltip" data-placement="top" title="Nueva Carpeta" onClick={() => setModalNuevaCarpeta(true)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                                </svg>
                            </div>
                        </Col>}
                        <Col xs={1} md={1} lg={1} >
                            <div style={{ color: 'white', cursor: 'pointer' }} data-toggle="tooltip" data-placement="top" title="Refrescar" onClick={() => setContador(contador + 1)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                                    <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                                </svg>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ height: '85%' }}>
                        <Col sm={4} className="columna-carpeta">
                            <ListGroup style={{ textAlign: 'left' }}>
                                {carpetas.map(carpeta => <ListGroup.Item className="tab-home cliente" key={carpeta.UniqueID} action href={"#" + carpeta.UniqueID}>{carpeta.Nombre}</ListGroup.Item>)}
                            </ListGroup>
                        </Col>
                        <Col sm={8} className="columna-carpeta">
                            <Tab.Content style={{ height: '100%' }}>
                                {carpetas.map(carpeta => <Tab.Pane key={carpeta.UniqueID} eventKey={"#" + carpeta.UniqueID} style={{ height: '100%' }}><DetalleCarpeta carpeta={carpeta.UniqueID} permiso={carpeta.permiso} nombre_carpeta={carpeta.Nombre} correlativo={carpeta.Correlativo} refrescar={() => setContador(contador + 1)} rol={props.rol} /></Tab.Pane>)}
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Modal.Body>
            <ModalNuevaCarpeta show={modalNuevaCarpeta} onHide={hideModalNuevaCarpeta} refrescar={() => setContador(contador + 1)} evento_UniqueID={props.evento_uniqueid} />
        </Modal>
    );
}

export default ModalMaterial