import React, { useEffect, useState } from "react";
import {
  Row,
  DropdownButton,
  ButtonGroup,
  Dropdown,
  Accordion,
  Table,
  Col,
  Container,
  ListGroup,
  Form,
  Alert,
  Popover,
  Button,
  OverlayTrigger,
  Breadcrumb,
} from "react-bootstrap";
import "./EventoDashBoard.css";
import { ModalProyecciones } from "./Modal-Proyecciones/ModalProyecciones";
import ModalEditarEvento from "./Dashboard/Modal-Editar-Evento/ModalEditarEvento";
import ModalRendimiento from "./Dashboard/Modal-Rendimiento/ModalRendimiento";
import { ModalEditarParticipantes } from "./Dashboard/Modal-Editar-Participantes/ModalEditarParticipantes";
import { ErrorModal } from "../../Utils/ErrorModal/ErrorModal";
import { SuccessModal } from "../../Utils/SuccessModal/SuccessModal";
import { Confirmacion } from "../../Utils/ConfirmationModal/Confirmacion";
import ModalMaterial from "./Dashboard/Modal-Material/ModalMaterial";
import ModalActividades from "./Dashboard/Modal-Actividades/ModalActividades";
import ModalIngresos from "./Dashboard/Modal-Ingresos/ModalIngresos";
import { ModalCargando } from "../../Utils/Modal-Cargando/ModalCargando";
import Formularios from "./Dashboard/Formularios/Formularios";
//import DEFAULT_URL from '../../../api/url';
//import { SELF_URL, EBG_URL } from '../../../api/url';
import ModalPatrocinadores from "./Dashboard/Modal-Patrocinadores/ModalPatrocinadores";
import axios from "axios";
import DetalleInscripcion from "../../Contabilidad/Inscripciones/DetalleInscripcion";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Notificaciones from "./Notificaciones/Notificaciones";
import EnvioEmails from "./Dashboard/Envio-Emails/envioEmails";
import Inscripciones from "../../Asesor/Cartera/Inscripciones/Inscripciones";
import CargaMasiva from "./Dashboard/Carga-Masiva/CargaMasiva";
import Descuento from "./Dashboard/Descuento/Descuento";
import ViewCupones from "./Dashboard/Descuento/ViewCupones";
import Diplomas from "./Dashboard/Diplomas/Diplomas";
import Sesion from "./Dashboard/Sesion/Sesion";
import DescuentoAdmin from "./Dashboard/Descuento/DescuentoAdmin";
import ViewCuponesAdmin from "./Dashboard/Descuento/ViewCuponesAdmin";
import Cortesias from "./Dashboard/Cortesias/Cortesias";
import InscripcionesExtraemporaneas from "./Dashboard/Inscripciones-extraemporaneas/InscripcionesExtraemporaneas";
import Jornada from "./Dashboard/Jornada/Jornada";
import Video from "./Dashboard/video/Video";
import Planos from "./Dashboard/Planos/Planos";
import Temario from "./Dashboard/Temario/Temario";
import EnlaceExterno from "./Dashboard/enlace-externo/EnlaceExterno";
import BatchUpload from "./Dashboard/BatchUpload/BatchUpload";
const popover = (
  <Popover id="popover-basic">
    <Popover.Header as="h3">¡Listo!</Popover.Header>
    <Popover.Body>Enlace copiado exitosamente.</Popover.Body>
  </Popover>
);
const EventoDashboard = (props) => {
  const [modalMaterial, setModalMaterial] = useState(false);
  const [modalIngresos, setModalIngresos] = useState(false);
  const [modalShowProyecciones, setModalShowProyecciones] = useState(false);
  const [modalEditarEvento, setModalEditarEvento] = useState(false);
  const [modalActividades, setModalActividades] = useState(false);
  const [ventas, setVentas] = useState([]);
  const [total, setTotal] = useState(0);
  const [modalRendimiento, setModalRendimiento] = useState(false);
  const [modalSucc, setModalSucc] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [consideracion, setConsideracion] = useState("");
  const [modalError, setModalError] = useState(false);
  const [modalEditarParticipantes, setModalEditarParticipantes] =
    useState(false);
  const [mensajeConfirmacion, setMensajeConfirmacion] = useState(false);
  const [id_actual, setId_actual] = useState("");
  const [mensajeCargando, setMensajeCargando] = useState("");
  const [modalCargando, setModalCargando] = useState(false);
  const [confirmacionEliminar, setConfirmacionEliminar] = useState(false);
  const [modalFormularios, setModalFormularios] = useState(false);
  const [contador, setContador] = useState(0);
  const [confirmacionOcultarLanding, setConfirmacionOcultarLanding] =
    useState(false);
  const [confirmacionPublicarLanding, setConfirmacionPublicarLanding] =
    useState(false);
  const [confirmacionOcultarPromocion, setConfirmacionOcultarPromocion] =
    useState(false);
  const [confirmacionPublicarPromocion, setConfirmacionPublicarPromocion] =
    useState(false);
  const [modalPatrocinadores, setModalPatrocinadores] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedImg, setSelectedImg] = useState(null);
  const [asesores, setAsesores] = useState([]);
  const [ingresos, setIngresos] = useState([]);
  useEffect(() => {
    async function fetchData() {
      setAsesores([]);
      setVentas([]);
      setTotal(0);
      setIngresos([]);
      fetch(process.env.REACT_APP_DEFAULT_URL + "/api/read/asesor", {
        cache: "no-cache",
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setAsesores(data.rows);
        });
      fetch(
        process.env.REACT_APP_DEFAULT_URL +
          "/api/read/evento/ventas/" +
          props.evento.UniqueID,
        { cache: "no-cache" }
      )
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setVentas(data.rows);
          if (data.total[0] == null) {
            setTotal(0);
          } else {
            setTotal(data.total[0].total);
          }
        });

      fetch(
        process.env.REACT_APP_DEFAULT_URL +
          "/api/read/evento/ingresos/" +
          props.evento.UniqueID,
        { cache: "no-cache" }
      )
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setIngresos(data.rows);
        });
    }
    fetchData();
  }, [contador]);

  const ocultarProyecciones = () => {
    setModalShowProyecciones(false);
    setContador(contador + 1);
  };
  const ocultarEditarEvento = () => {
    setModalEditarEvento(false);
    setContador(contador + 1);
  };
  const ocultarModalIngresos = () => {
    setModalIngresos(false);
    setContador(contador + 1);
  };
  const ocultarRendimiento = () => {
    setModalRendimiento(false);
    setContador(contador + 1);
  };
  const ocultarEditParticipantes = () => {
    setModalEditarParticipantes(false);
    setContador(contador + 1);
  };
  const ocultarModalMaterial = () => {
    setModalMaterial(false);
    setContador(contador + 1);
  };
  const ocultarModalActividades = () => {
    setModalActividades(false);
    setContador(contador + 1);
  };
  const ocultarModalCargando = () => {
    setModalCargando(false);
    setContador(contador + 1);
  };
  const deleteparticipante = (UniqueID, Nombres) => {
    var confirmatinomessage =
      "¿Está segur@ que desea elimnar al participante " + Nombres + " ?";
    setConsideracion("Esta acción no se puede revertir");
    setMensaje(confirmatinomessage);
    setId_actual(UniqueID);
    setMensajeConfirmacion(true);
    setContador(contador + 1);
  };
  const ocultarModalFormularios = () => {
    setModalFormularios(false);
  };
  const accioneliminarparticipante = (UniqueID, param) => {
    const info = { UniqueID: UniqueID };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(info),
    };
    fetch(
      process.env.REACT_APP_DEFAULT_URL + "/api/delete/detalle_inscripcion",
      requestOptions
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.codigo == 200) {
          setMensaje("Participante eliminado exitosamente");
          setModalSucc(true);
          ocultarEditConfirmacion();
        } else {
          ocultarEditConfirmacion();
          setMensaje(data.respuesta);
          setModalError(true);
        }
      });
  };
  const ocultar = () => {
    setModalSucc(false);
    setModalError(false);
    props.refrescar();
  };

  const ocultarEditConfirmacion = () => {
    setMensajeConfirmacion(false);
    setConfirmacionEliminar(false);
  };
  const publicarLanging = (id, param) => {
    setConfirmacionPublicarLanding(false);
    setModalCargando(true);
    setMensajeCargando("Procesando petición...");
    const info = { UniqueID: id };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(info),
    };
    fetch(
      process.env.REACT_APP_DEFAULT_URL + "/api/update/evento/publicarLanding",
      requestOptions
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setModalCargando(false);
        if (data.codigo == 200) {
          setMensaje("Landing page publicada exitosamente");
          setModalSucc(true);
        } else {
          setMensaje(data.respuesta);
          setModalError(true);
        }
      });
  };
  const ocultarLanging = (id, param) => {
    setConfirmacionOcultarLanding(false);
    setModalCargando(true);
    setMensajeCargando("Procesando petición...");
    const info = { UniqueID: id };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(info),
    };
    fetch(
      process.env.REACT_APP_DEFAULT_URL + "/api/update/evento/ocultarLanding",
      requestOptions
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setModalCargando(false);
        if (data.codigo == 200) {
          setMensaje("Landing page oculta exitosamente");
          setModalSucc(true);
        } else {
          setMensaje(data.respuesta);
          setModalError(true);
        }
      });
  };
  const publicarPromocion = (id, param) => {
    setConfirmacionPublicarPromocion(false);
    setModalCargando(true);
    setMensajeCargando("Procesando petición...");
    const info = { UniqueID: id };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(info),
    };
    fetch(
      process.env.REACT_APP_DEFAULT_URL +
        "/api/update/evento/publicarPromocion",
      requestOptions
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setModalCargando(false);
        if (data.codigo == 200) {
          setMensaje("Página promocion publicada exitosamente");
          setModalSucc(true);
        } else {
          setMensaje(data.respuesta);
          setModalError(true);
        }
      });
  };
  const ocultarPromocion = (id, param) => {
    setConfirmacionOcultarPromocion(false);
    setModalCargando(true);
    setMensajeCargando("Procesando petición...");
    const info = { UniqueID: id };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(info),
    };
    fetch(
      process.env.REACT_APP_DEFAULT_URL + "/api/update/evento/ocultarPromocion",
      requestOptions
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setModalCargando(false);
        if (data.codigo == 200) {
          setMensaje("Página promocion oculta exitosamente");
          setModalSucc(true);
        } else {
          setMensaje(data.respuesta);
          setModalError(true);
        }
      });
  };
  const eliminar = (id, param) => {
    ocultarEditConfirmacion();
    const info = { UniqueID: id };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(info),
    };
    fetch(
      process.env.REACT_APP_DEFAULT_URL + "/api/delete/evento",
      requestOptions
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.codigo == 200) {
          setMensaje("Evento Eliminado Exitosamente");
          setModalSucc(true);
          props.back();
        } else {
          setMensaje(data.respuesta);
          setModalError(true);
        }
      });
  };
  const onChangeFile = (evt) => {
    if (evt.target.name == "img") {
      setSelectedImg(evt.target.files[0]);
      setContador(contador + 1);
    } else {
      setSelectedFile(evt.target.files[0]);
      setContador(contador + 1);
    }
  };
  const submitImageHandler = async (endpoint, fileSelected) => {
    if (fileSelected != null) {
      setModalCargando(true);
      setMensajeCargando("Subiendo imagenes a repositorio de medios");
      setConsideracion("Por favor espere...");
      const data = new FormData();
      data.append("file", fileSelected);
      axios
        .post(
          process.env.REACT_APP_DEFAULT_URL + "/api/files/upload/s3",
          data,
          {}
        )
        .then((res) => {
          if (res.data.codigo == 200) {
            setMensajeCargando("Sincronizando base de datos...");
            const info = {
              enlace: res.data.resultado.Location,
              evento_UniqueID: props.evento.UniqueID,
            };
            const requestOptions = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(info),
            };
            fetch(process.env.REACT_APP_DEFAULT_URL + endpoint, requestOptions)
              .then((res) => {
                return res.json();
              })
              .then((data) => {
                setModalCargando(false);
                if (data.codigo == 200) {
                  setMensaje("Evento Modificado Exitosamente");
                  setConsideracion(
                    "Por favor, notifique al personal de sus cambios"
                  );
                  setModalSucc(true);
                } else {
                  setMensaje(data.respuesta);
                  setModalError(true);
                }
              });
          }
        });
    } else {
      setMensaje("Seleccione un archivo");
      setConsideracion("Inténtelo de nuevo");
      setModalError(true);
    }
  };
  const reporte_admin = () => {
    fetch(
      process.env.REACT_APP_DEFAULT_URL +
        "/api/read/detalle_inscripcion/admin/report/" +
        props.evento.UniqueID,
      { cache: "no-cache" }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        var excel_contenido = data.rows;
        var asesores = [];
        var Sheets = [];
        var SheetNames = [];
        var asesores_tabla = [];
        const sw = XLSX.utils.json_to_sheet(excel_contenido);
        Sheets["Datos"] = sw;
        SheetNames.push("Datos");
        for (let i = 0; i < data.rows.length; i++) {
          if (asesores.length == 0 || !asesores.includes(data.rows[i].asesor)) {
            asesores.push(data.rows[i].asesor);
          }
        }
        for (let i = 0; i < asesores.length; i++) {
          let arr_respuestas = data.rows.filter(
            (actual) => actual.asesor == asesores[i]
          );
          arr_respuestas.sort(function (a, b) {
            return a["Apellidos"].localeCompare(b["Apellidos"]);
          });
          const ws = XLSX.utils.json_to_sheet(arr_respuestas);
          let seetname =
            asesores[i].split(" ")[0] +
            " " +
            asesores[i].split(" ")[1][0] +
            ". (" +
            arr_respuestas.length +
            ")";
          Sheets[seetname] = ws;
          SheetNames.push(seetname);
          asesores_tabla.push({
            Asesor: asesores[i],
            Respuestas: arr_respuestas.length,
          });
        }
        asesores_tabla.sort(function (a, b) {
          return b.Respuestas - a.Respuestas;
        });
        asesores_tabla.push({ Asesor: "TOTAL", Respuestas: data.rows.length });
        const ws = XLSX.utils.json_to_sheet(asesores_tabla);
        Sheets["RESUMEN"] = ws;
        SheetNames.push("RESUMEN");
        const fileType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const wb = { Sheets, SheetNames };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const datos = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(datos, Date.now() + fileExtension);
      });
  };
  return (
    <Container>
      <Row style={{ marginTop: "45px", marginBottom: "45px" }}>
        <Breadcrumb variant="dark">
          <Breadcrumb.Item active>Eventos</Breadcrumb.Item>
          <Breadcrumb.Item active>{props.tipoEventos}</Breadcrumb.Item>
          <Breadcrumb.Item active>
            {props.tipoEventos + " " + props.mes + " " + props.year}
          </Breadcrumb.Item>
          <Breadcrumb.Item active>{props.evento.Nombre}</Breadcrumb.Item>
        </Breadcrumb>
      </Row>
      <Row style={{ display: "none" }}>
        <Col xs={1} md={1}>
          <div
            style={{ cursor: "pointer", width: "20%", color: "black" }}
            data-toggle="tooltip"
            data-placement="top"
            title="Atrás"
            onClick={() => {
              props.back();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              className="bi bi-backspace-fill"
              viewBox="0 0 16 16"
            >
              <path d="M15.683 3a2 2 0 0 0-2-2h-7.08a2 2 0 0 0-1.519.698L.241 7.35a1 1 0 0 0 0 1.302l4.843 5.65A2 2 0 0 0 6.603 15h7.08a2 2 0 0 0 2-2V3zM5.829 5.854a.5.5 0 1 1 .707-.708l2.147 2.147 2.146-2.147a.5.5 0 1 1 .707.708L9.39 8l2.146 2.146a.5.5 0 0 1-.707.708L8.683 8.707l-2.147 2.147a.5.5 0 0 1-.707-.708L7.976 8 5.829 5.854z" />
            </svg>
          </div>
        </Col>
        {(props.rol == 2 || props.rol == 3 || props.rol == 4) && (
          <Col xs={1} md={1}>
            <div
              style={{ cursor: "pointer", width: "20%", color: "black" }}
              data-toggle="tooltip"
              data-placement="top"
              title="Editar Información"
              onClick={() => setModalEditarEvento(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                className="bi bi-pencil-fill"
                viewBox="0 0 16 16"
              >
                <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
              </svg>
            </div>
          </Col>
        )}
        {(props.rol == 2 || props.rol == 4) && (
          <Col xs={1} md={1}>
            <div
              style={{ cursor: "pointer", width: "20%", color: "black" }}
              data-toggle="tooltip"
              data-placement="top"
              title="Participantes"
              onClick={() => setModalEditarParticipantes(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                className="bi bi-person-check-fill"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M15.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z"
                />
                <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
              </svg>
            </div>
          </Col>
        )}
        {(props.rol == 2 || props.rol == 4 || props.rol == 7) && (
          <Col xs={1} md={1}>
            <div
              style={{ cursor: "pointer", width: "20%", color: "black" }}
              data-toggle="tooltip"
              data-placement="top"
              title="Material"
              onClick={() => setModalMaterial(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                className="bi bi-inboxes-fill"
                viewBox="0 0 16 16"
              >
                <path d="M4.98 1a.5.5 0 0 0-.39.188L1.54 5H6a.5.5 0 0 1 .5.5 1.5 1.5 0 0 0 3 0A.5.5 0 0 1 10 5h4.46l-3.05-3.812A.5.5 0 0 0 11.02 1H4.98zM3.81.563A1.5 1.5 0 0 1 4.98 0h6.04a1.5 1.5 0 0 1 1.17.563l3.7 4.625a.5.5 0 0 1 .106.374l-.39 3.124A1.5 1.5 0 0 1 14.117 10H1.883A1.5 1.5 0 0 1 .394 8.686l-.39-3.124a.5.5 0 0 1 .106-.374L3.81.563zM.125 11.17A.5.5 0 0 1 .5 11H6a.5.5 0 0 1 .5.5 1.5 1.5 0 0 0 3 0 .5.5 0 0 1 .5-.5h5.5a.5.5 0 0 1 .496.562l-.39 3.124A1.5 1.5 0 0 1 14.117 16H1.883a1.5 1.5 0 0 1-1.489-1.314l-.39-3.124a.5.5 0 0 1 .121-.393z" />
              </svg>
            </div>
          </Col>
        )}
        {(props.rol == 2 || props.rol == 4) && (
          <Col xs={1} md={1}>
            <div
              style={{ cursor: "pointer", width: "20%", color: "black" }}
              data-toggle="tooltip"
              data-placement="top"
              title="Actividades"
              onClick={() => setModalActividades(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                className="bi bi-journal-bookmark-fill"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M6 1h6v7a.5.5 0 0 1-.757.429L9 7.083 6.757 8.43A.5.5 0 0 1 6 8V1z"
                />
                <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z" />
                <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z" />
              </svg>
            </div>
          </Col>
        )}
        {(props.rol == 2 || props.rol == 4) && (
          <Col xs={1} md={1}>
            <div
              style={{ cursor: "pointer", width: "20%", color: "black" }}
              data-toggle="tooltip"
              data-placement="top"
              title="Formularios"
              onClick={() => setModalFormularios(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                className="bi bi-ui-radios"
                viewBox="0 0 16 16"
              >
                <path d="M7 2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1zM0 12a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm7-1.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1zm0-5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 8a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zM3 1a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm0 4.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
              </svg>
            </div>
          </Col>
        )}
        {(props.rol == 3 || props.rol == 4) && (
          <Col xs={1} md={1}>
            <div
              style={{ cursor: "pointer", width: "20%", color: "black" }}
              data-toggle="tooltip"
              data-placement="top"
              title="Proyección"
              onClick={() => setModalShowProyecciones(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                className="bi bi-file-earmark-bar-graph-fill"
                viewBox="0 0 16 16"
              >
                <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zm.5 10v-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-2.5.5a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1zm-3 0a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-1z" />
              </svg>
            </div>
          </Col>
        )}
        {(props.rol == 3 || props.rol == 4) && (
          <Col xs={1} md={1}>
            <div
              style={{ cursor: "pointer", width: "20%", color: "black" }}
              data-toggle="tooltip"
              data-placement="top"
              title="Rendimiento"
              onClick={() => setModalRendimiento(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                className="bi bi-graph-up-arrow"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M0 0h1v15h15v1H0V0Zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5Z"
                />
              </svg>
            </div>
          </Col>
        )}
        {(props.rol == 3 || props.rol == 4) && (
          <Col xs={1} md={1}>
            <div
              style={{ cursor: "pointer", width: "20%", color: "black" }}
              data-toggle="tooltip"
              data-placement="top"
              title="Ingresos"
              onClick={() => setModalIngresos(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                className="bi bi-currency-dollar"
                viewBox="0 0 16 16"
              >
                <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z" />
              </svg>
            </div>
          </Col>
        )}
        {props.rol == 4 && (
          <Col xs={1} md={1}>
            <div
              style={{ cursor: "pointer", width: "20%", color: "black" }}
              data-toggle="tooltip"
              data-placement="top"
              title="Eliminar"
              onClick={() => {
                setConfirmacionEliminar(true);
                setMensaje(
                  "¿Está segur@ de eliminar el evento " +
                    props.evento.Nombre +
                    "?"
                );
                setConsideracion("Esta acción no se puede revertir");
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                className="bi bi-trash-fill"
                viewBox="0 0 16 16"
              >
                <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
              </svg>
            </div>
          </Col>
        )}
        {(props.rol == 2 || props.rol == 4) && (
          <Col xs={1} md={1}>
            <div
              style={{ cursor: "pointer", width: "20%", color: "black" }}
              data-toggle="tooltip"
              data-placement="top"
              title="Patrocinadores"
              onClick={() => setModalPatrocinadores(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                class="bi bi-file-person-fill"
                viewBox="0 0 16 16"
              >
                <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm-1 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0zm-3 4c2.623 0 4.146.826 5 1.755V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-1.245C3.854 11.825 5.377 11 8 11z" />
              </svg>
            </div>
          </Col>
        )}
        <Col xs={1} md={1}>
          <div
            style={{ cursor: "pointer", width: "20%", color: "black" }}
            data-toggle="tooltip"
            data-placement="top"
            title="Refrescar"
            onClick={props.refrescar}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              className="bi bi-arrow-clockwise"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
              />
              <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
            </svg>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={1} md={1} lg={1}>
          <div
            style={{ cursor: "pointer", width: "20%", color: "black" }}
            data-toggle="tooltip"
            data-placement="top"
            title="Atrás"
            onClick={() => {
              props.back();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              className="bi bi-backspace-fill"
              viewBox="0 0 16 16"
            >
              <path d="M15.683 3a2 2 0 0 0-2-2h-7.08a2 2 0 0 0-1.519.698L.241 7.35a1 1 0 0 0 0 1.302l4.843 5.65A2 2 0 0 0 6.603 15h7.08a2 2 0 0 0 2-2V3zM5.829 5.854a.5.5 0 1 1 .707-.708l2.147 2.147 2.146-2.147a.5.5 0 1 1 .707.708L9.39 8l2.146 2.146a.5.5 0 0 1-.707.708L8.683 8.707l-2.147 2.147a.5.5 0 0 1-.707-.708L7.976 8 5.829 5.854z" />
            </svg>
          </div>
        </Col>
        <Col xs={10} md={10} lg={10}>
          <Row>
            <DropdownButton as={ButtonGroup} title="Acciones" variant="dark">
              {(props.rol == 2 || props.rol == 3 || props.rol == 4) && (
                <Dropdown.Item onClick={() => setModalEditarEvento(true)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    className="bi bi-pencil-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                  </svg>
                  &nbsp;&nbsp;&nbsp;Editar
                </Dropdown.Item>
              )}
              {(props.rol == 1 ||
                props.rol == 2 ||
                props.rol == 3 ||
                props.rol == 4 ||
                props.rol == 6 ||
                props.rol == 7 ||
                props.rol == 8) && (
                <Dropdown.Item
                  href={"/participantes/" + props.evento.hash}
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    className="bi bi-person-check-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M15.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z"
                    />
                    <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                  </svg>
                  &nbsp;&nbsp;&nbsp;Participantes
                </Dropdown.Item>
              )}
              {(props.rol == 1 ||
                props.rol == 2 ||
                props.rol == 4 ||
                props.rol == 7 ||
                props.rol == 6) && (
                <Dropdown.Item onClick={() => setModalMaterial(true)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    className="bi bi-inboxes-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4.98 1a.5.5 0 0 0-.39.188L1.54 5H6a.5.5 0 0 1 .5.5 1.5 1.5 0 0 0 3 0A.5.5 0 0 1 10 5h4.46l-3.05-3.812A.5.5 0 0 0 11.02 1H4.98zM3.81.563A1.5 1.5 0 0 1 4.98 0h6.04a1.5 1.5 0 0 1 1.17.563l3.7 4.625a.5.5 0 0 1 .106.374l-.39 3.124A1.5 1.5 0 0 1 14.117 10H1.883A1.5 1.5 0 0 1 .394 8.686l-.39-3.124a.5.5 0 0 1 .106-.374L3.81.563zM.125 11.17A.5.5 0 0 1 .5 11H6a.5.5 0 0 1 .5.5 1.5 1.5 0 0 0 3 0 .5.5 0 0 1 .5-.5h5.5a.5.5 0 0 1 .496.562l-.39 3.124A1.5 1.5 0 0 1 14.117 16H1.883a1.5 1.5 0 0 1-1.489-1.314l-.39-3.124a.5.5 0 0 1 .121-.393z" />
                  </svg>
                  &nbsp;&nbsp;&nbsp;Material
                </Dropdown.Item>
              )}
              {(props.rol == 2 || props.rol == 4) && (
                <Dropdown.Item onClick={() => setModalActividades(true)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    className="bi bi-journal-bookmark-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M6 1h6v7a.5.5 0 0 1-.757.429L9 7.083 6.757 8.43A.5.5 0 0 1 6 8V1z"
                    />
                    <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z" />
                    <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z" />
                  </svg>
                  &nbsp;&nbsp;&nbsp;Actividades
                </Dropdown.Item>
              )}
              {(props.rol == 2 || props.rol == 4) && (
                <Dropdown.Item onClick={() => setModalFormularios(true)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    className="bi bi-ui-radios"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7 2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1zM0 12a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm7-1.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1zm0-5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 8a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zM3 1a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm0 4.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                  </svg>
                  &nbsp;&nbsp;&nbsp;Formularios
                </Dropdown.Item>
              )}
              {(props.rol == 2 || props.rol == 4) && (
                <Dropdown.Item
                  href={
                    process.env.REACT_APP_SELF_URL +
                    "/tarifas/" +
                    props.evento.hash +
                    "/all"
                  }
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-tags"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3 2v4.586l7 7L14.586 9l-7-7H3zM2 2a1 1 0 0 1 1-1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 2 6.586V2z" />
                    <path d="M5.5 5a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm0 1a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zM1 7.086a1 1 0 0 0 .293.707L8.75 15.25l-.043.043a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 0 7.586V3a1 1 0 0 1 1-1v5.086z" />
                  </svg>
                  &nbsp;&nbsp;&nbsp;Tarifas
                </Dropdown.Item>
              )}
              {(props.rol == 3 ||
                props.rol == 4 ||
                props.rol == 6 ||
                props.rol == 2) && (
                <Dropdown.Item onClick={() => setModalShowProyecciones(true)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    className="bi bi-file-earmark-bar-graph-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zm.5 10v-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-2.5.5a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1zm-3 0a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-1z" />
                  </svg>
                  &nbsp;&nbsp;&nbsp;Proyección
                </Dropdown.Item>
              )}
              {(props.rol == 3 ||
                props.rol == 4 ||
                props.rol == 6 ||
                props.rol == 2) && (
                <Dropdown.Item onClick={() => setModalRendimiento(true)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    className="bi bi-graph-up-arrow"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M0 0h1v15h15v1H0V0Zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5Z"
                    />
                  </svg>
                  &nbsp;&nbsp;&nbsp;Rendimiento
                </Dropdown.Item>
              )}
              {(props.rol == 3 ||
                props.rol == 4 ||
                props.rol == 6 ||
                props.rol == 2) && (
                <Dropdown.Item onClick={() => setModalIngresos(true)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    className="bi bi-currency-dollar"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z" />
                  </svg>
                  &nbsp;&nbsp;&nbsp;Ingresos
                </Dropdown.Item>
              )}
              {(props.rol == 2 || props.rol == 4) && (
                <Dropdown.Item onClick={() => setModalPatrocinadores(true)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    class="bi bi-file-person-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm-1 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0zm-3 4c2.623 0 4.146.826 5 1.755V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-1.245C3.854 11.825 5.377 11 8 11z" />
                  </svg>
                  &nbsp;&nbsp;&nbsp;Patrocinadores
                </Dropdown.Item>
              )}
              <Dropdown.Item
                href={
                  process.env.REACT_APP_SELF_URL +
                  "/pagina-recepcion/" +
                  props.evento.hash
                }
                target="_blank"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-door-open-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M1.5 15a.5.5 0 0 0 0 1h13a.5.5 0 0 0 0-1H13V2.5A1.5 1.5 0 0 0 11.5 1H11V.5a.5.5 0 0 0-.57-.495l-7 1A.5.5 0 0 0 3 1.5V15H1.5zM11 2h.5a.5.5 0 0 1 .5.5V15h-1V2zm-2.5 8c-.276 0-.5-.448-.5-1s.224-1 .5-1 .5.448.5 1-.224 1-.5 1z" />
                </svg>
                &nbsp;&nbsp;&nbsp;Página de recepción
              </Dropdown.Item>
              {(props.rol == 1 ||
                props.rol == 2 ||
                props.rol == 3 ||
                props.rol == 4 ||
                props.rol == 6 ||
                props.rol == 7 ||
                props.rol == 8) && (
                <Dropdown.Item
                  href={"/gafetes-fisicos/" + props.evento.hash + "/all"}
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-person-badge"
                    viewBox="0 0 16 16"
                  >
                    <path d="M6.5 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zM11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                    <path d="M4.5 0A2.5 2.5 0 0 0 2 2.5V14a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2.5A2.5 2.5 0 0 0 11.5 0h-7zM3 2.5A1.5 1.5 0 0 1 4.5 1h7A1.5 1.5 0 0 1 13 2.5v10.795a4.2 4.2 0 0 0-.776-.492C11.392 12.387 10.063 12 8 12s-3.392.387-4.224.803a4.2 4.2 0 0 0-.776.492V2.5z" />
                  </svg>
                  &nbsp;&nbsp;&nbsp;Gafetes físicos
                </Dropdown.Item>
              )}
              {props.rol == 4 && (
                <Dropdown.Item
                  style={{ color: "red" }}
                  onClick={() => {
                    setConfirmacionEliminar(true);
                    setMensaje(
                      "¿Está segur@ de eliminar el evento " +
                        props.evento.Nombre +
                        "?"
                    );
                    setConsideracion("Esta acción no se puede revertir");
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    className="bi bi-trash-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                  </svg>
                  &nbsp;&nbsp;&nbsp;Eliminar Evento
                </Dropdown.Item>
              )}
            </DropdownButton>
          </Row>
        </Col>
        <Col xs={1} md={1}>
          <div
            style={{ cursor: "pointer", width: "20%", color: "black" }}
            data-toggle="tooltip"
            data-placement="top"
            title="Refrescar"
            onClick={() => {
              setContador(contador + 1);
              props.refrescar();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              className="bi bi-arrow-clockwise"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
              />
              <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
            </svg>
          </div>
        </Col>
      </Row>
      <Row>
        <h1 style={{ marginBottom: "25px", marginTop: "25px" }}>
          Datos del evento
        </h1>
        <ListGroup as="ol">
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between align-items-start"
          >
            <div className="ms-2 me-auto">
              <div className="fw-bold">Evento</div>
            </div>
            {props.evento.Nombre}
          </ListGroup.Item>
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between align-items-start"
          >
            <div className="ms-2 me-auto">
              <div className="fw-bold">Fecha de inicio del evento</div>
            </div>
            {props.evento.Fecha_Inicio}
          </ListGroup.Item>
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between align-items-start"
          >
            <div className="ms-2 me-auto">
              <div className="fw-bold">Fecha de finalización del evento</div>
            </div>
            {props.evento.Fecha_Fin}
          </ListGroup.Item>
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between align-items-start"
          >
            <div className="ms-2 me-auto">
              <div className="fw-bold">Hora del evento</div>
            </div>
            {props.evento.Hora}
          </ListGroup.Item>
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between align-items-start"
          >
            <div className="ms-2 me-auto">
              <div className="fw-bold">Mínimo de participantes</div>
            </div>
            {props.evento.Minimo}
          </ListGroup.Item>
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between align-items-start"
          >
            <div className="ms-2 me-auto">
              <div className="fw-bold">Tipo de evento</div>
            </div>
            {props.evento.tipo}
          </ListGroup.Item>
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between align-items-start"
          >
            <div className="ms-2 me-auto">
              <div className="fw-bold">Estado del evento</div>
            </div>
            {props.evento.estado}
          </ListGroup.Item>
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between align-items-start"
          >
            <div className="ms-2 me-auto">
              <div className="fw-bold">Modalidad del evento</div>
            </div>
            {props.evento.modalidad}
          </ListGroup.Item>
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between align-items-start"
          >
            <div className="ms-2 me-auto">
              <div className="fw-bold">Días a impartir</div>
            </div>
            {props.evento.dias}
          </ListGroup.Item>
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between align-items-start"
          >
            <div className="ms-2 me-auto">
              <div className="fw-bold">Duración del evento</div>
            </div>
            {props.evento.duracion}
          </ListGroup.Item>
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between align-items-start"
          >
            <div className="ms-2 me-auto">
              <div className="fw-bold">Número de sesiones del evento</div>
            </div>
            {props.evento.sesiones}
          </ListGroup.Item>
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between align-items-start"
          >
            <div className="ms-2 me-auto">
              <div className="fw-bold">Precio para agremiados</div>
            </div>
            {props.evento.precio_agremiados}
          </ListGroup.Item>
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between align-items-start"
          >
            <div className="ms-2 me-auto">
              <div className="fw-bold">Precio para no agremiados</div>
            </div>
            {props.evento.precio_no_agremiados}
          </ListGroup.Item>
          {props.rol == 4 && (
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start"
            >
              <div className="ms-2 me-auto">
                <div className="fw-bold">Página registro e ingreso</div>
              </div>
              {props.evento.disponible == 0 && (
                <Alert
                  variant="danger"
                  style={{
                    height: "10px",
                    display: "flex",
                    placeItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setConfirmacionPublicarLanding(true);
                    setMensaje("¿Está segur@ de publicar esta landing page?");
                    setConsideracion("Esta acción se puede revertir");
                    setContador(contador + 1);
                  }}
                >
                  <div
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Click para publicar landing page"
                  >
                    No Disponible
                  </div>
                </Alert>
              )}
              {props.evento.disponible == 1 && (
                <Alert
                  variant="success"
                  style={{
                    height: "10px",
                    display: "flex",
                    placeItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setConfirmacionOcultarLanding(true);
                    setMensaje("¿Está segur@ de ocultar esta landing page?");
                    setConsideracion("Esta acción se puede revertir");
                    setContador(contador + 1);
                  }}
                >
                  <div
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Click para ocultar landing page"
                  >
                    Disponible
                  </div>
                </Alert>
              )}
              {props.evento.disponible == 1 && (
                <OverlayTrigger
                  trigger="click"
                  placement="left"
                  overlay={popover}
                  rootClose
                >
                  <div
                    style={{ cursor: "pointer", width: "20%", color: "black" }}
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Copiar enlace al portapapeles"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        process.env.REACT_APP_EBG_URL +
                          "/pre-inscripcion/" +
                          props.evento.hash
                      );
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="currentColor"
                      className="bi bi-clipboard-check"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"
                      />
                      <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                      <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                    </svg>
                  </div>
                </OverlayTrigger>
              )}
              {props.evento.disponible == 1 && (
                <div
                  style={{ cursor: "pointer", width: "20%", color: "black" }}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Ver landing page"
                >
                  <a
                    href={
                      process.env.REACT_APP_EBG_URL +
                      "/pre-inscripcion/" +
                      props.evento.hash
                    }
                    target="_blank"
                    style={{ color: "black" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="currentColor"
                      className="bi bi-eye-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                      <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                    </svg>
                  </a>
                </div>
              )}
            </ListGroup.Item>
          )}
          {props.rol != 4 && (
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start"
            >
              <div className="ms-2 me-auto">
                <div className="fw-bold">Página registro e ingreso</div>
              </div>
              {props.evento.disponible == 0 && (
                <Alert
                  variant="danger"
                  style={{
                    height: "10px",
                    display: "flex",
                    placeItems: "center",
                  }}
                >
                  No Disponible
                </Alert>
              )}
              {props.evento.disponible == 1 && (
                <Alert
                  variant="success"
                  style={{
                    height: "10px",
                    display: "flex",
                    placeItems: "center",
                  }}
                >
                  Disponible
                </Alert>
              )}
              {props.evento.disponible == 1 && (
                <OverlayTrigger
                  trigger="click"
                  placement="left"
                  overlay={popover}
                  rootClose
                >
                  <div
                    style={{ cursor: "pointer", width: "20%", color: "black" }}
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Copiar enlace al portapapeles"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        process.env.REACT_APP_EBG_URL +
                          "/pre-inscripcion/" +
                          props.evento.hash
                      );
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="currentColor"
                      className="bi bi-clipboard-check"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"
                      />
                      <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                      <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                    </svg>
                  </div>
                </OverlayTrigger>
              )}
              {props.evento.disponible == 1 && (
                <div
                  style={{ cursor: "pointer", width: "20%", color: "black" }}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Ver landing page"
                >
                  <a
                    href={
                      process.env.REACT_APP_EBG_URL +
                      "/pre-inscripcion/" +
                      props.evento.hash
                    }
                    target="_blank"
                    style={{ color: "black" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="currentColor"
                      className="bi bi-eye-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                      <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                    </svg>
                  </a>
                </div>
              )}
            </ListGroup.Item>
          )}
          {props.rol == 4 && (
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start"
            >
              <div className="ms-2 me-auto">
                <div className="fw-bold">Página promoción</div>
              </div>
              {props.evento.promocion == 0 && (
                <Alert
                  variant="danger"
                  style={{
                    height: "10px",
                    display: "flex",
                    placeItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setConfirmacionPublicarPromocion(true);
                    setMensaje("¿Está segur@ de publicar página de promoción?");
                    setConsideracion("Esta acción se puede revertir");
                    setContador(contador + 1);
                  }}
                >
                  <div
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Click para publicar landing page"
                  >
                    No Disponible
                  </div>
                </Alert>
              )}
              {props.evento.promocion == 1 && (
                <Alert
                  variant="success"
                  style={{
                    height: "10px",
                    display: "flex",
                    placeItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setConfirmacionOcultarPromocion(true);
                    setMensaje("¿Está segur@ de ocultar página de promoción?");
                    setConsideracion("Esta acción se puede revertir");
                    setContador(contador + 1);
                  }}
                >
                  <div
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Click para ocultar landing page"
                  >
                    Disponible
                  </div>
                </Alert>
              )}
              {props.evento.promocion == 1 && (
                <OverlayTrigger
                  trigger="click"
                  placement="left"
                  overlay={popover}
                  rootClose
                >
                  <div
                    style={{ cursor: "pointer", width: "20%", color: "black" }}
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Copiar enlace al portapapeles"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        process.env.REACT_APP_SELF_URL +
                          "/evento/promocion/" +
                          props.evento.hash
                      );
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="currentColor"
                      className="bi bi-clipboard-check"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"
                      />
                      <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                      <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                    </svg>
                  </div>
                </OverlayTrigger>
              )}
              {props.evento.promocion == 1 && (
                <div
                  style={{ cursor: "pointer", width: "20%", color: "black" }}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Ver landing page"
                >
                  <a
                    href={
                      process.env.REACT_APP_SELF_URL +
                      "/evento/promocion/" +
                      props.evento.hash
                    }
                    target="_blank"
                    style={{ color: "black" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="currentColor"
                      className="bi bi-eye-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                      <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                    </svg>
                  </a>
                </div>
              )}
            </ListGroup.Item>
          )}
          {props.rol != 4 && (
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start"
            >
              <div className="ms-2 me-auto">
                <div className="fw-bold">Página promoción</div>
              </div>
              {props.evento.promocion == 0 && (
                <Alert
                  variant="danger"
                  style={{
                    height: "10px",
                    display: "flex",
                    placeItems: "center",
                  }}
                >
                  No Disponible
                </Alert>
              )}
              {props.evento.promocion == 1 && (
                <Alert
                  variant="success"
                  style={{
                    height: "10px",
                    display: "flex",
                    placeItems: "center",
                  }}
                >
                  Disponible
                </Alert>
              )}
              {props.evento.promocion == 1 && (
                <OverlayTrigger
                  trigger="click"
                  placement="left"
                  overlay={popover}
                  rootClose
                >
                  <div
                    style={{ cursor: "pointer", width: "20%", color: "black" }}
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Copiar enlace al portapapeles"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        process.env.REACT_APP_SELF_URL +
                          "/evento/promocion/" +
                          props.evento.hash
                      );
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="currentColor"
                      className="bi bi-clipboard-check"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"
                      />
                      <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                      <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                    </svg>
                  </div>
                </OverlayTrigger>
              )}
              {props.evento.promocion == 1 && (
                <div
                  style={{ cursor: "pointer", width: "20%", color: "black" }}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Ver landing page"
                >
                  <a
                    href={
                      process.env.REACT_APP_SELF_URL +
                      "/evento/promocion/" +
                      props.evento.hash
                    }
                    target="_blank"
                    style={{ color: "black" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="currentColor"
                      className="bi bi-eye-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                      <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                    </svg>
                  </a>
                </div>
              )}
            </ListGroup.Item>
          )}
        </ListGroup>
      </Row>
      <Row>
        <h1 style={{ marginBottom: "25px", marginTop: "25px", color: "black" }}>
          Descripción del evento
        </h1>
        <Form.Group className="mb-3" controlId="Comentario">
          <Form.Control
            as="textarea"
            disabled
            rows={3}
            style={{ resize: "none", width: "100%" }}
            name="Comentario"
            value={props.evento.Descripcion}
          />
        </Form.Group>
      </Row>
      <Row style={{ marginBottom: "25px" }}>
        <Col xs={12} md={12} lg={12}>
          <Form.Group
            className="mb-3"
            controlId={"imagen-" + props.evento.UniqueID}
          >
            <Form.Label>Enlace de imagen 1</Form.Label>
            <Form.Control
              readOnly
              onChange={props.eventochange}
              type="text"
              placeholder="Ingrese el enlace de la imagen"
              name={"imagen-" + props.evento.UniqueID}
              value={props.evento.imagen}
            />
          </Form.Group>
        </Col>
        {(props.rol == 2 || props.rol == 4 || props.rol == 7) && (
          <Col xs={12} md={12} lg={8} style={{ marginBottom: "15px" }}>
            <Form.Group controlId="formFileMultiple" className="mb-3">
              <Form.Control
                type="file"
                onChange={onChangeFile}
                accept=".jpeg, .png, .JPG, .JPEG, .PNG"
                name="img"
              />
            </Form.Group>
          </Col>
        )}
        {(props.rol == 2 || props.rol == 4 || props.rol == 7) && (
          <Col xs={12} md={12} lg={4} style={{ marginBottom: "15px" }}>
            <Button
              variant="dark"
              style={{ width: "100%" }}
              onClick={() =>
                submitImageHandler("/api/update/evento/img", selectedImg)
              }
            >
              Cambiar imagen
            </Button>
          </Col>
        )}
        <Col xs={12} md={12} lg={6}>
          <Row style={{ marginBottom: "15px" }}>
            <h1 style={{ textAlign: "center" }}>Vista previa</h1>
          </Row>
          <Row style={{ padding: "15%", border: "5px dotted black" }}>
            <img
              src={props.evento.imagen}
              loading="lazy"
              width="100%"
              height="100%"
            />
          </Row>
        </Col>
        <Col
          xs={12}
          md={12}
          lg={6}
          style={{ display: "flex", placeItems: "center" }}
        >
          <Alert variant="primary">
            <Alert.Heading>Aclaración</Alert.Heading>
            <p>
              Esta imagen se visualizará en la ABG APP, si es un congreso, esta
              imagen aparecerá en el listado de congresos.
            </p>
            <hr />
            <p className="mb-0">
              De lo contrario aparecerá como imagen de la portada de cada evento
              de la agenda en la app.
            </p>
          </Alert>
        </Col>
      </Row>
      <Row style={{ marginBottom: "25px" }}>
        <Col xs={12} md={12} lg={12}>
          <Form.Group
            className="mb-3"
            controlId={"imagen-" + props.evento.UniqueID}
          >
            <Form.Label>Enlace de imagen 2</Form.Label>
            <Form.Control
              readOnly
              onChange={props.eventochange}
              type="text"
              placeholder="Ingrese el enlace de la imagen"
              name={"flier-" + props.evento.UniqueID}
              value={props.evento.flier}
            />
          </Form.Group>
        </Col>
        {(props.rol == 2 || props.rol == 4 || props.rol == 7) && (
          <Col xs={12} md={12} lg={8} style={{ marginBottom: "15px" }}>
            <Form.Group controlId="formFileMultiple" className="mb-3">
              <Form.Control
                type="file"
                onChange={onChangeFile}
                accept=".jpeg, .png, .JPG, .JPEG, .PNG"
                name="flier"
              />
            </Form.Group>
          </Col>
        )}
        {(props.rol == 2 || props.rol == 4 || props.rol == 7) && (
          <Col xs={12} md={12} lg={4} style={{ marginBottom: "15px" }}>
            <Button
              variant="dark"
              style={{ width: "100%" }}
              onClick={() =>
                submitImageHandler("/api/update/evento/flier", selectedFile)
              }
            >
              Cambiar imagen
            </Button>
          </Col>
        )}
        <Col xs={12} md={12} lg={6}>
          <Row style={{ marginBottom: "15px" }}>
            <h1 style={{ textAlign: "center" }}>Vista previa</h1>
          </Row>
          <Row style={{ padding: "15%", border: "5px dotted black" }}>
            <img
              loading="lazy"
              src={props.evento.flier}
              width="100%"
              height="100%"
            />
          </Row>
        </Col>
        <Col
          xs={12}
          md={12}
          lg={6}
          style={{ display: "flex", placeItems: "center" }}
        >
          <Alert variant="primary">
            <Alert.Heading>Aclaración</Alert.Heading>
            <p>
              Esta imagen aparecerá en la app exclusivamente para congresos,
              aparecerá como foto de la portada del evento.
            </p>
            <hr />
            <p className="mb-0">
              Para todos los tipos de eventos, aparecerá esta imagen en su
              respectiva landing page.
            </p>
          </Alert>
        </Col>
      </Row>
      <Row>
        <Video
          evento={props.evento}
          permiso={props.rol}
          refrescar={props.refrescar}
        />
      </Row>
      <Row>
        <EnlaceExterno
          evento={props.evento}
          permiso={props.rol}
          refrescar={props.refrescar}
        />
      </Row>
      <Row>
        <Planos
          evento={props.evento}
          permiso={props.rol}
          refrescar={props.refrescar}
        />
      </Row>

      {(props.rol == 2 || props.rol == 4 || props.rol == 7) && (
        <Row style={{ marginTop: "25px" }}>
          <Notificaciones evento={props.evento} />
        </Row>
      )}
      {(props.rol == 2 || props.rol == 4 || props.rol == 7) && (
        <Row style={{ marginTop: "25px" }}>
          <EnvioEmails evento={props.evento} />
        </Row>
      )}
      {(props.rol == 2 || props.rol == 4) && (
        <Row style={{ color: "black" }}>
          <Diplomas evento={props.evento} />
        </Row>
      )}
      {(props.rol == 2 || props.rol == 4) && (
        <Row style={{ color: "black" }}>
          <Sesion evento={props.evento} />
        </Row>
      )}
      {(props.rol == 2 || props.rol == 4) && (
        <Row
          style={{ color: "black", paddingBottom: "75px", paddingTop: "75px" }}
        >
          <h1>Aplicar descuento</h1>
          <DescuentoAdmin
            UniqueID={props.evento.UniqueID}
            hash_evt={props.evento.hash}
          />
        </Row>
      )}
      {(props.rol == 2 || props.rol == 4) && (
        <Row style={{ color: "black", paddingBottom: "75px" }}>
          <h1>Descuentos autorizados</h1>
          <ViewCuponesAdmin evento={props.evento.hash} />
        </Row>
      )}
      {(props.rol == 2 || props.rol == 4) && (
        <Row style={{ color: "black", paddingBottom: "75px" }}>
          <h1>Cortesías</h1>
          <Cortesias
            evento={props.evento.UniqueID}
            hash_evt={props.evento.hash}
          />
        </Row>
      )}
      {(props.rol == 2 || props.rol == 4) && (
        <Row style={{ color: "black", paddingBottom: "75px" }}>
          <h1>Inscripciones Extemporáneas</h1>
          <InscripcionesExtraemporaneas
            evento={props.evento.UniqueID}
            hash_evt={props.evento.hash}
          />
        </Row>
      )}
      {props.rol == 4 && (
        <Row style={{ color: "black", paddingBottom: "75px" }}>
          <Jornada evento={props.evento} />
        </Row>
      )}
      {props.rol == 4 && (
        <Row style={{ color: "black", paddingBottom: "75px" }}>
          <Temario evento={props.evento} />
        </Row>
      )}
      {props.rol == 4 && (
        <Row className="mt-5">
          <BatchUpload evento={props.evento.UniqueID} />
        </Row>
      )}
      {(props.rol == 2 ||
        props.rol == 3 ||
        props.rol == 4 ||
        props.rol == 6) && (
        <Row style={{ color: "black" }}>
          <h1 style={{ marginBottom: "25px", marginTop: "20px" }}>
            Ventas por asesor
          </h1>
          <Col
            xs={12}
            md={12}
            lg={12}
            style={{ marginTop: "25px", marginBottom: "25px" }}
          >
            <Row>
              <Button variant="dark" onClick={reporte_admin}>
                Descargar reporte consolidado de inscritos
              </Button>
            </Row>
          </Col>
          <Col style={{ marginBottom: "25px" }} xs={12} md={12} lg={12}>
            <Accordion>
              {asesores.map((asesor) => (
                <Accordion.Item eventKey={asesor.UniqueID}>
                  <Accordion.Header>
                    {asesor.Nombres + " " + asesor.Apellidos}
                  </Accordion.Header>
                  <Accordion.Body className="acordeon">
                    <DetalleInscripcion
                      evento={props.evento}
                      asesor={asesor}
                      refrescar={props.refrescar}
                      permiso={props.rol}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </Col>
          <Col xs={12} md={12} lg={12}>
            <Table striped bordered hover responsive variant="dark">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Asesor</th>
                  <th>Ventas</th>
                </tr>
              </thead>
              <tbody>
                {ventas.map((venta, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{venta.asesor}</td>
                    <td>{venta.inscritos}</td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={2} style={{ fontWeight: "bolder" }}>
                    TOTAL VENTAS
                  </td>
                  <td style={{ fontWeight: "bolder" }}>{total}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      )}
      {props.rol == 1 && (
        <Row style={{ color: "black", padding: "2%" }}>
          <Container fluid>
            <Row style={{ padding: "3%" }}>
              <h1>Enlaces directos a inscripción</h1>
              <Col xs={12} md={12} lg={12} style={{ padding: "5%" }}>
                <Row>
                  <Button
                    variant="outline-danger"
                    style={{ borderRadius: "25px", height: "100px" }}
                    onClick={() => {
                      window
                        .open(
                          process.env.REACT_APP_EBG_URL +
                            "/inscripcion/" +
                            props.evento.hash +
                            "/" +
                            props.hash,
                          "_blank"
                        )
                        .focus();
                    }}
                  >
                    Portal ebg.edu.gt
                  </Button>
                </Row>
                <Row className="mt-5">
                  <Button
                    variant="outline-danger"
                    style={{ borderRadius: "25px", height: "100px" }}
                    onClick={() => {
                      window
                        .open(
                          process.env.REACT_APP_EBG_URL +
                            "/cortesia-inscripcion/" +
                            props.evento.hash +
                            "/" +
                            props.hash,
                          "_blank"
                        )
                        .focus();
                    }}
                  >
                    CORTESÍA ebg.edu.gt
                  </Button>
                </Row>
              </Col>
            </Row>
            {/*    <Row style={{ padding: "3%" }}>
              <CargaMasiva
                evento={props.evento.UniqueID}
                asesor={props.UniqueID}
              />
            </Row>*/}
          </Container>
        </Row>
      )}
      {props.rol == 1 && (
        <Row style={{ color: "black", paddingBottom: "75px" }}>
          <h1>Aplicar descuento</h1>
          <Descuento
            UniqueID={props.evento.UniqueID}
            asesor={props.UniqueID}
            hash_evt={props.evento.hash}
            hash_asesor={props.hash}
          />
        </Row>
      )}
      {props.rol == 1 && (
        <Row style={{ color: "black", paddingBottom: "75px" }}>
          <h1>Descuentos autorizados</h1>
          <ViewCupones evento={props.evento.hash} asesor={props.hash} />
        </Row>
      )}
      {props.rol == 1 && (
        <Row style={{ color: "black", paddingBottom: "75px" }}>
          <h1>Inscripciones procesadas</h1>
          <Inscripciones
            UniqueID={props.evento.UniqueID}
            asesor={props.UniqueID}
          />
        </Row>
      )}
      <ModalProyecciones
        show={modalShowProyecciones}
        onHide={ocultarProyecciones}
        data={[
          {
            name: "Ingresos Proyectados",
            monto: props.evento.ingresos,
            "Mínimo Participantes": props.evento.Minimo,
          },
          {
            name: "Egresos Proyectados",
            monto: props.evento.egresos,
            "Mínimo Participantes": props.evento.Minimo,
          },
          {
            name: "Resultados Proyectados",
            monto: props.evento.resultado,
            "Mínimo Participantes": props.evento.Minimo,
          },
        ]}
      />
      <ModalRendimiento
        show={modalRendimiento}
        onHide={ocultarRendimiento}
        data={ventas}
        evt={props.evento.Nombre}
      />
      <ModalIngresos
        show={modalIngresos}
        onHide={ocultarModalIngresos}
        data={ingresos}
        ventas={ventas}
      />
      <ModalEditarEvento
        show={modalEditarEvento}
        onHide={ocultarEditarEvento}
        evento={props.evento}
        refrescar={props.refrescar}
      />
      <SuccessModal
        color={"#b5bad0"}
        show={modalSucc}
        onHide={ocultar}
        mensaje={mensaje}
        consideracion={consideracion}
        enlace={""}
        llevaenlace={false}
      />
      <ModalCargando
        color={"#b5bad0"}
        show={modalCargando}
        onHide={ocultarModalCargando}
        mensaje={mensajeCargando}
      />
      <ErrorModal
        color={"#b5bad0"}
        show={modalError}
        onHide={ocultar}
        mensaje={mensaje}
        consideracion={consideracion}
      />
      <ModalEditarParticipantes
        show={modalEditarParticipantes}
        onHide={ocultarEditParticipantes}
        deleteparticipante={deleteparticipante}
        refrescar={props.refrescar}
        eventoUniqueID={props.evento.UniqueID}
      />
      <Confirmacion
        show={mensajeConfirmacion}
        onHide={ocultarEditConfirmacion}
        accion={accioneliminarparticipante}
        mensaje={mensaje}
        uniqueid={id_actual}
        consideracion={consideracion}
        param=""
      />

      <Confirmacion
        show={confirmacionEliminar}
        onHide={ocultarEditConfirmacion}
        accion={eliminar}
        mensaje={mensaje}
        uniqueid={props.evento.UniqueID}
        consideracion={consideracion}
        param=""
      />
      <Confirmacion
        show={confirmacionPublicarLanding}
        onHide={() => setConfirmacionPublicarLanding(false)}
        accion={publicarLanging}
        mensaje={mensaje}
        uniqueid={props.evento.UniqueID}
        consideracion={consideracion}
        param=""
      />
      <Confirmacion
        show={confirmacionOcultarLanding}
        onHide={() => setConfirmacionOcultarLanding(false)}
        accion={ocultarLanging}
        mensaje={mensaje}
        uniqueid={props.evento.UniqueID}
        consideracion={consideracion}
        param=""
      />
      <Confirmacion
        show={confirmacionPublicarPromocion}
        onHide={() => setConfirmacionPublicarPromocion(false)}
        accion={publicarPromocion}
        mensaje={mensaje}
        uniqueid={props.evento.UniqueID}
        consideracion={consideracion}
        param=""
      />
      <Confirmacion
        show={confirmacionOcultarPromocion}
        onHide={() => setConfirmacionOcultarPromocion(false)}
        accion={ocultarPromocion}
        mensaje={mensaje}
        uniqueid={props.evento.UniqueID}
        consideracion={consideracion}
        param=""
      />
      {props.rol == 4 && (
        <ModalMaterial
          show={modalMaterial}
          onHide={ocultarModalMaterial}
          evento_uniqueid={props.evento.UniqueID}
          admin={true}
          rol={props.rol}
          nombre_evento={props.evento.Nombre}
        />
      )}
      {props.rol != 4 && (
        <ModalMaterial
          show={modalMaterial}
          onHide={ocultarModalMaterial}
          evento_uniqueid={props.evento.UniqueID}
          admin={false}
          rol={props.rol}
          nombre_evento={props.evento.Nombre}
        />
      )}
      <ModalPatrocinadores
        show={modalPatrocinadores}
        onHide={() => setModalPatrocinadores(false)}
        evento_uniqueid={props.evento.UniqueID}
      />
      {(props.rol == 4 || props.rol == 2) && (
        <Formularios
          evento={props.evento}
          show={modalFormularios}
          onHide={ocultarModalFormularios}
        />
      )}
      <ModalActividades
        show={modalActividades}
        onHide={ocultarModalActividades}
        evento_uniqueid={props.evento.UniqueID}
        evento_name={props.evento.Nombre}
      />
    </Container>
  );
};

export default EventoDashboard;
