import React, { useState, useEffect } from 'react'
//import DEFAULT_URL from '../../../../../api/url';
import { Row, Alert, Button, Spinner, Popover, Col, OverlayTrigger, Form, Container, Accordion, ButtonToolbar, ButtonGroup, DropdownButton, InputGroup } from "react-bootstrap";
const popover = (
    <Popover id="popover-basic">
        <Popover.Header as="h3">¡Listo!</Popover.Header>
        <Popover.Body>
            Enlace copiado exitosamente.
        </Popover.Body>
    </Popover>
);
const DetalleTema = (props) => {
    const [contador, setContador] = useState(0);
    const [detalle, setDetalle] = useState('none');
    const [portada, setPortada] = useState('block');
    const [exito, setExito] = useState('none');
    const [error, setError] = useState('none');
    const [cargando, setCargando] = useState('none');
    const [confirmacion, setConfirmacion] = useState('none');
    const [mensaje, setMensaje] = useState('');
    const [cambios, setCambios] = useState(0);
    const [tema, setTema] = useState('');
    useEffect(() => {
        setTema(props.tema.descripcion);
    }, [contador, props.contador]);
    useEffect(() => {

    }, [cambios]);

    const deleteSelf = () => {
        cargar('Sincronizando base de datos');
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/tema-temario/delete/' + props.tema.UniqueID, requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                if (data.codigo === 200) {
                    operacionExito('Tema eliminado exitosamente');
                } else {
                    operacionError('Error al eliminar registro en db');
                }
            });
    }
    const onChange = (evt) => {
        if (evt.target.name == 'tema') {
            setTema(evt.target.value);
        }
        setCambios(cambios + 1);
    }
    const onSubmit = (evt) => {
        evt.preventDefault();
        cargar('Sincronizando base de datos');
        let info = {descripcion:tema, UniqueID: props.tema.UniqueID};
        const requestOptions = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/tema-temario/update', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                if (data.codigo === 200) {
                    operacionExito('Información actualizada exitosamente');
                } else {
                    operacionError('Error al editar registro en db');
                }
            });
    }
    const cargar = (mensaje) => {
        setExito('none');
        setDetalle('none');
        setPortada('none');
        setError('none');
        setCargando('block');
        setConfirmacion('none');
        setMensaje(mensaje);
        setCambios(cambios + 1);
    }
    const confirmar = () => {
        setExito('none');
        setDetalle('none');
        setPortada('none');
        setError('none');
        setCargando('none');
        setConfirmacion('block');
        setCambios(cambios + 1);
    }
    const operacionExito = (mensaje) => {
        setExito('block');
        setDetalle('none');
        setPortada('none');
        setError('none');
        setCargando('none');
        setMensaje(mensaje);
        props.refresh();
        setContador(contador + 1);
    }
    const operacionError = (mensaje) => {
        setDetalle('none');
        setPortada('none');
        setExito('none');
        setError('block');
        setCargando('none');
        setMensaje(mensaje);
        setContador(contador + 1);
    }

    const goBack = () => {
        setDetalle('none');
        setPortada('block');
        setExito('none');
        setError('none');
        setCargando('none');
        setConfirmacion('none');
        setMensaje('');
        setContador(contador + 1);
    }
    return (
        <Row style={{ padding: '2%' }}>
            <Container fluid>
                <Row style={{ display: portada }}>
                    <Container fluid>
                        <Row>
                            <Container fluid>
                                <Form onSubmit={onSubmit}>
                                    <Row style={{ padding: '2%' }}>
                                        <h3>Editar tema</h3>
                                    </Row>
                                    <Row style={{ marginBottom: '25px' }}>
                                        <Form.Group controlId="tema">
                                            <Form.Label>Tema</Form.Label>
                                            <Form.Control type='text' name="tema" onChange={onChange} value={tema} />
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <Button type="submit" variant='outline-success'>
                                            Guardar cambios
                                        </Button>
                                    </Row>
                                </Form>
                            </Container>
                        </Row>
                        <Row style={{ marginTop: '15px' }}>
                            <Button variant='outline-danger' onClick={confirmar}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3" viewBox="0 0 16 16">
                                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                </svg>&nbsp;&nbsp;&nbsp;&nbsp;Eliminar tema
                            </Button>
                        </Row>
                    </Container>
                </Row>
                <Row style={{ display: exito }}>
                    <Alert variant='success'>
                        <Alert.Heading>
                            Operación realizada con éxito
                        </Alert.Heading>
                        <p>
                            {mensaje}
                        </p>
                        <hr />
                        <div className="d-flex justify-content-end">
                            <Button variant='outline-success' onClick={() => { goBack(); props.refresh(); }}>
                                Ok
                            </Button>
                        </div>
                    </Alert>
                </Row>
                <Row style={{ display: error }}>
                    <Alert variant='danger'>
                        <Alert.Heading>
                            Ocurrió un error al eliminar el tema
                        </Alert.Heading>
                        <p>
                            {mensaje}
                        </p>
                        <hr />
                        <div className="d-flex justify-content-end">
                            <Button variant='outline-danger' onClick={goBack}>
                                Ok
                            </Button>
                        </div>
                    </Alert>
                </Row>
                <Row style={{ display: cargando }}>
                    <Alert variant='dark'>
                        <Alert.Heading>
                            <Spinner animation="border" role="status">
                            </Spinner>&nbsp;&nbsp;&nbsp;
                            Ingresando información
                        </Alert.Heading>
                        <p>
                            {mensaje}
                        </p>
                        <hr />
                        <p>
                            Por favor espere...
                        </p>
                    </Alert>
                </Row>
                <Row style={{ display: confirmacion }}>
                    <Alert variant='warning'>
                        <Alert.Heading>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
                                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                            </svg>&nbsp;&nbsp;&nbsp;Se necesita confirmación
                        </Alert.Heading>
                        <p>
                            ¿Está segur@ de eliminar el tema seleccionado?
                            <br />

                        </p>
                        <hr />
                        <div className="d-flex justify-content-end">
                            <Button variant='outline-danger' onClick={deleteSelf}>
                                si
                            </Button>&nbsp;&nbsp;&nbsp;
                            <Button variant='outline-dark' onClick={goBack}>
                                no
                            </Button>
                        </div>
                    </Alert>
                </Row>
            </Container>
        </Row>
    )
}

export default DetalleTema