import React, { useState, useEffect } from 'react'
import { Row, ListGroup, Container } from "react-bootstrap";
import ModalSeguimiento from './ModalSeguimiento';
import { Confirmacion } from '../../../Utils/ConfirmationModal/Confirmacion';
import { SuccessModal } from '../../../Utils/SuccessModal/SuccessModal';
import { ErrorModal } from '../../../Utils/ErrorModal/ErrorModal';
//import { DEFAULT_URL } from '../../../../api/url';
const Seguimiento = (props) => {
    const [modalSeguimiento, setModalSeguimiento] = useState(false);
    const [seguimientoActual, setSeguimientoActual] = useState({});
    const [confirmacionEliminar, setConfirmacionEliminar] = useState(false);
    const [contador, setContador] = useState(0);
    const [mensaje, setMensaje] = useState('');
    const [idActual, setIdActual] = useState('');
    const [consideracion, setConsideracion] = useState('');
    const [modalError, setModalError] = useState(false);
    const [modalSucc, setModalSucc] = useState(false);
    useEffect(() => {

    }, [contador]);
    const ocultarEliminarConfirmacion = () => {
        setConfirmacionEliminar(false);
    }
    const ocultarSeguimiento = () => {
        setModalSeguimiento(false);
    }
    const ocultar = () => {
        setModalSucc(false);
        setModalError(false);
        props.actualizar();
        setContador(contador + 1);
    }
    const cancelarLead = (id, param) => {
        ocultarEliminarConfirmacion();
        const info = { UniqueID: id };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/update/leads/cancelar', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                if (data.codigo == 200) {
                    setModalSucc(true);
                    setMensaje('Lead cancelado exitosamente');
                } else {
                    setModalError(true);
                    setMensaje('ERROR');
                }
            });
        ocultar();
    }
    return (
        <Container>
            {props.leads.map((lead) =>
                <Row style={{ padding: '10px' }}>
                    <ListGroup as="ol">
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start"
                        >
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">{lead.nombre}</div>
                                {lead.fecha}
                            </div>
                            <div data-toggle="tooltip" data-placement="top" title="Ver seguimiento" style={{ cursor: 'pointer', color: 'black' }} onClick={() => { setSeguimientoActual(lead); setModalSeguimiento(true); }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16">
                                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                    <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                </svg>
                            </div>
                            <div data-toggle="tooltip" data-placement="top" title="Cancelar lead" style={{ cursor: 'pointer', color: 'black' }} onClick={() => { setMensaje('¿Está segur@ de cancelar este lead?'); setConsideracion('Esta acción es reversible'); setIdActual(lead.UniqueID); setConfirmacionEliminar(true); }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                                </svg>
                            </div>
                        </ListGroup.Item>
                    </ListGroup>
                </Row>
            )}
            <ModalSeguimiento show={modalSeguimiento} onHide={ocultarSeguimiento} lead={seguimientoActual} fullscreen={true} seguimiento={props.seguimiento} refrescar = {props.actualizar} />
            <Confirmacion
                show={confirmacionEliminar}
                onHide={ocultarEliminarConfirmacion}
                accion={cancelarLead}
                mensaje={mensaje}
                uniqueid={idActual}
                consideracion={consideracion}
                param=''
            />
            <ErrorModal
                color={"#b5bad0"}
                show={modalError}
                onHide={ocultar}
                mensaje={mensaje}
                consideracion={consideracion}
            />
            <SuccessModal
                color={"#b5bad0"}
                show={modalSucc}
                onHide={ocultar}
                mensaje={mensaje}
                consideracion={consideracion}
                enlace={''}
                llevaenlace={false}
            />
        </Container>
    )
}

export default Seguimiento