import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
//import DEFAULT_URL from '../../api/url';
//import { SELF_URL } from '../../api/url';
import { Container, Row, Col, Button } from 'react-bootstrap';
const Interesados = () => {
    ///api/read/leads/en-seguimiento/encabezado/:evento/:UniqueID
    const { evento } = useParams();
    const [contador] = useState(0);
    const [encabezado, setEncabezado] = useState({});
    const [usuario, setUsuario] = useState({});
    const [detalle, setDetalle] = useState([]);
    useEffect(() => {
        async function fetchData() {
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/auth/userinfo', { cache: "no-cache", credentials: 'include' })
                .then((res) => {
                    if (res.status == 401) {
                        window.location.reload();
                    } else {
                        return res.json();
                    }
                })
                .then(data => {
                    setUsuario(data);
                    fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/leads/en-seguimiento/encabezado/' + evento + '/' + data.UniqueID, { cache: "no-cache", credentials: 'include' })
                        .then((res) => {
                            return res.json();
                        })
                        .then(data => {
                            setEncabezado(data.rows[0]);
                        });
                    fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/leads/en-seguimiento/detalle/' + evento + '/' + data.UniqueID, { cache: "no-cache", credentials: 'include' })
                        .then((res) => {
                            return res.json();
                        })
                        .then(data => {
                            setDetalle(data.rows);
                        });
                });
        }
        fetchData();
    }, [contador])

    return (
        <Container fluid>
            <Row style={{ padding: '5%' }}>
                <Container>
                    <Row style={{ padding: '5%', borderBottom: '5px solid #1e3d52', borderLeft: '5px solid #1e3d52', backgroundColor: 'rgb(230,230,230)' }}>
                        <Col xs={12} md={6} lg={4}>
                            <img src={encabezado.flier} style={{ width: '100%' }} />
                        </Col>
                        <Col xs={12} md={6} lg={8} style={{ textAlign: 'left' }}>
                            <h1>PERSONAS INTERESADAS</h1>
                            {encabezado != null && <p>
                                INFORMACIÓN
                                <br />
                                <strong>EVENTO: </strong>{encabezado.evento_name}
                                <br />
                                <strong>INTERESADOS: </strong>{encabezado.conteo}
                            </p>}
                        </Col>
                    </Row>
                </Container>
            </Row>
            <Row style={{ padding: '5%' }}>
                {detalle.map((actual, index) => <Col key={index} xs={12} md={6} lg={4}>
                    <Container fluid style={{ padding: '1%', border: '5px solid #1e3d52', backgroundColor: 'rgb(230,230,230)' }}>
                        <Row>
                            <Col xs={4} md={4} lg={4}>
                                <img src='https://escuela-bancaria.s3.us-east-2.amazonaws.com/user.png' style={{ width: '100%', objectFit: 'cover' }} />
                            </Col>
                            <Col xs={8} md={8} lg={8} style={{ textAlign: 'left' }}>
                                <p>
                                    <strong>FECHA INGRESO: </strong>{actual.fecha}<br />
                                    <strong>NOMBRE: </strong>{actual.nombre}<br />
                                    <strong>INSTITUCIÓN: </strong>{actual.institucion}<br />
                                    <strong>PUESTO: </strong>{actual.puesto}<br />
                                </p>
                                <Button variant='outline-success' href={process.env.REACT_APP_SELF_URL + '/crm/seguimiento/view/' + actual.UniqueID}>Ver seguimiento</Button>
                            </Col>
                        </Row>
                    </Container>
                </Col>)}
            </Row>
        </Container>
    )
}

export default Interesados