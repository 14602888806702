import React, { useState, useEffect } from 'react'
import { Row, Button, Form, Container, Col, Alert, Spinner, Accordion, Card } from "react-bootstrap";
import "./style.css"
//import DEFAULT_URL from '../../api/url';
import axios from 'axios';
const ViewTema = (props) => {
    const [contador, setContador] = useState(0);
    const [nombre, setNombre] = useState('');
    const [exito, setExito] = useState('none');
    const [formulario, setFormulario] = useState('none');
    const [error, setError] = useState('none');
    const [cargando, setCargando] = useState('none');
    const [view, setView] = useState('block');
    const [confirmacion, setConfirmacion] = useState('none');
    const [mensaje, setMensaje] = useState('');
    const [docentes, setDocentes] = useState([]);
    const [propuestas, setPropuestas] = useState([]);
    const [tiposInfo, setTiposInfo] = useState([]);
    const [informacion, setInformacion] = useState([]);
    const [edit, setEdit] = useState(0);
    const [info, setInfo] = useState([]);
    const [descripcion, setDescripcion] = useState('');
    const [img, setImg] = useState(null);

    useEffect(() => {
        async function fetchData() {
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/facilitadores/read/by-tema/' + props.tema.UniqueID, { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setDocentes(data.salida);
                });
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/propuestas/read/by-temas/' + props.tema.UniqueID, { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setPropuestas(data.rows);
                });
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/informacion-catalogo/tipos/read')
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setTiposInfo(data.salida);
                });

            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/informacion-catalogo/read/' + props.tema.UniqueID)
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setInformacion(data.salida);
                    let tmp = [];
                    data.salida.map((actual, index) => {
                        const index_finder = (info) => info.tipo == actual.tipo;
                        if (tmp.findIndex(index_finder) >= 0) {
                            let item = tmp[tmp.findIndex(index_finder)];
                            item.listado.push(actual);
                            tmp[tmp.findIndex(index_finder)] = item;
                        } else {
                            tmp.push({ tipo: actual.tipo, nombre: actual.Nombre, listado: [actual] });
                        }
                        if (index == (data.salida.length - 1)) {
                            setInfo(tmp);
                        }
                    });
                });

        }
        fetchData();
    }, [contador]);
    useEffect(() => {

    }, [edit]);

    const goBack = () => {
        setExito('none');
        setFormulario('none');
        setError('none');
        setCargando('none');
        setView('block');
        setConfirmacion('none');
        setNombre('');
        setInformacion([]);
        setDescripcion('');
        Array.from(document.querySelectorAll("input")).forEach(
            input => (input.value = "")
        );
        setContador(contador + 1);
    }
    const deleteSelf = () => {
        cargar('Sincronizando base de datos');
        const info = {
            UniqueID: props.tema.UniqueID
        }
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/tema/delete', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                if (data.codigo == 200) {
                    operacionExito('Tema eliminado exitosamente');
                    props.refresh();
                    props.onHide();
                } else {
                    operacionError('Error al eliminar registro en db');
                }
            });
    }
    const cargar = (mensaje) => {
        setExito('none');
        setFormulario('none');
        setError('none');
        setCargando('block');
        setView('none');
        setConfirmacion('none');
        setMensaje(mensaje);
        setEdit(edit + 1);
    }
    const confirmar = () => {
        setExito('none');
        setFormulario('none');
        setError('none');
        setCargando('none');
        setConfirmacion('block');
        setView('none');
        setEdit(edit + 1);
    }
    const operacionExito = (mensaje) => {
        setExito('block');
        setFormulario('none');
        setError('none');
        setCargando('none');
        setView('none');
        setMensaje(mensaje);
        setContador(contador + 1);
    }
    const operacionError = (mensaje) => {
        setExito('none');
        setFormulario('none');
        setError('block');
        setCargando('none');
        setView('none');
        setMensaje(mensaje);
        setEdit(edit + 1);
    }
    const onChange = (evt) => {
        if (evt.target.name == "nombre") {
            setNombre(evt.target.value);
        } else if (evt.target.name == 'descripcion') {
            setDescripcion(evt.target.value);
        } else {
            setImg(evt.target.files[0]);
        }
        setEdit(edit + 1);
    }
    const goToEdit = () => {
        setExito('none');
        setFormulario('block');
        setError('none');
        setCargando('none');
        setView('none');
        setConfirmacion('none');
        setNombre(props.tema.nombre);
        setDescripcion(props.tema.descripcion);
        setEdit(edit + 1);
    }
    const onSubmit = (evt) => {
        evt.preventDefault();
        if (informacion.length == 0) {
            operacionError('Debes adjuntar información del tema');
        } else {
            if (img != null) {
                const data = new FormData();
                data.append('file', img);
                cargar('Subiendo imagen');
                axios.post(process.env.REACT_APP_DEFAULT_URL+ "/api/files/upload/s3", data, {}).then(res => {
                    if (res.data.codigo == 200) {
                        cargar('Sincronizando base de datos');
                        const info = {
                            nombre: nombre,
                            foto: res.data.resultado.Location,
                            descripcion: descripcion,
                            informacion: informacion,
                            UniqueID: props.tema.UniqueID
                        };
                        const requestOptions = {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(info)
                        };
                        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/tema/update', requestOptions)
                            .then((res) => {
                                return res.json();
                            })
                            .then(data => {
                                if (data.codigo == 200) {
                                    operacionExito('Tema editado exitosamente');
                                } else {
                                    operacionError('Error al editar registro en db');
                                }
                            });
                    } else {
                        operacionError('Error al subir imagen a AWS');
                    }
                });
            } else {
                cargar('Sincronizando base de datos');
                const info = {
                    nombre: nombre,
                    foto: props.tema.foto,
                    descripcion: descripcion,
                    informacion: informacion,
                    UniqueID: props.tema.UniqueID
                };
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(info)
                };
                fetch(process.env.REACT_APP_DEFAULT_URL + '/api/tema/update', requestOptions)
                    .then((res) => {
                        return res.json();
                    })
                    .then(data => {
                        if (data.codigo == 200) {
                            operacionExito('Tema editado exitosamente');
                        } else {
                            operacionError('Error al editar registro en db');
                        }
                    });
            }
        }
    }
    const onChangeInfo = (evt) => {
        const arr = evt.target.name.split('-');
        let temp = informacion;
        let indice = parseInt(arr[0]);
        if (arr[1] == "contenido") {
            temp[indice].contenido = evt.target.value;
        } else if (arr[1] == "tipo") {
            temp[indice].tipo = evt.target.value;
        }
        setInformacion(temp);
        setEdit(edit + 1);
    }
    return (
        <Container style={{ padding: '2%' }}>
            <Row style={{ display: view }}>
                <Container>
                    <Row>
                        <Container fluid>
                            <Row>
                                {props.permiso == 1 && <Col xs={12} md={12} lg={4}>
                                    <Container fluid>
                                        <Row style={{ marginTop: '25px' }}>
                                            <Button variant='outline-success' onClick={goToEdit}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-fill" viewBox="0 0 16 16">
                                                <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                            </svg>&nbsp;&nbsp;&nbsp;Editar Tema</Button>
                                        </Row>
                                    </Container>
                                </Col>
                                }
                                {props.permiso == 1 &&
                                    <Col xs={12} md={12} lg={4}>
                                        <Container fluid>
                                            <Row style={{ marginTop: '25px' }}>
                                                <Button variant='outline-danger' onClick={() => { confirmar(); }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3-fill" viewBox="0 0 16 16">
                                                    <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                                                </svg>&nbsp;&nbsp;&nbsp;Eliminar Tema</Button>
                                            </Row>
                                        </Container>
                                    </Col>}
                                <Col xs={12} md={12} lg={4}>
                                    <Container fluid>
                                        <Row style={{ marginTop: '25px' }}>
                                            <Button variant='outline-dark' onClick={() => { setContador(contador + 1) }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                                                <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                                            </svg>&nbsp;&nbsp;&nbsp;Refrescar</Button>
                                        </Row>
                                    </Container>
                                </Col>
                            </Row>
                        </Container>
                    </Row>
                    <Row style={{ padding: '5%', marginTop: '25px', display: 'flex', placeItems: 'center' }}>
                        <Col xs={12} md={12} lg={6}>
                            <img alt='imagen-tema' src={props.tema.foto} style={{ width: '65%', objectFit: 'cover' }} />
                        </Col>
                        <Col xs={12} md={12} lg={6}>
                            <h2>TEMA: <strong>{props.tema.nombre}</strong></h2>
                        </Col>
                    </Row>
                    <Row style={{ padding: '5%', color: 'black' }}>
                        <p style={{ textAlign: 'justify', width: '100%', fontStyle: 'oblique', fontSize: '1.7em' }}>{props.tema.descripcion}</p>
                    </Row>
                    <Row>
                        <h1>Información del tema</h1>
                    </Row>
                    <Row style={{ padding: '5%', color: 'black' }}>
                        {info.map((actual, index) => <Col xs={12} md={12} lg={4} style={{ marginTop: '15px', marginBottom: '15px' }} key={index}>
                            <Accordion>
                                <Accordion.Item eventKey={index}>
                                    <Accordion.Header>{actual.nombre}</Accordion.Header>
                                    <Accordion.Body>
                                        <Container fluid>
                                            {actual.listado.map((curr, indice) => <Row style={{ padding: '1%' }} key={indice}><p style={{ textAlign: 'justify' }}>{curr.contenido}</p></Row>)}
                                        </Container>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>)
                        }
                    </Row>
                    <Row>
                        <h1>Docentes que imparten el tema</h1>
                    </Row>
                    <Row style={{ marginTop: '25px', marginBottom: '55px' }}>
                        {docentes.map((actual, index) => <Col xs={12} md={12} lg={6} style={{ marginTop: '15px', marginBottom: '15px', padding: '1%' }} key={index}>
                            <Card style={{ height: '100%', border: '1px solid transparent' }}>
                                <Card.Body style={{ display: 'grid', placeItems: 'center' }}>
                                    <Card.Img src={actual.foto} style={{ width: '50%' }} />
                                    <Card.Title style={{ textAlign: 'center', marginTop: '25px', color: 'white', backgroundColor: '#1e3d52', padding: '7%', borderRadius: '25px' }}>{actual.Nombres + ' ' + actual.Apellidos}<br />{actual.tarifa}</Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>)}
                    </Row>
                    {propuestas.length > 0 &&
                        <Row style={{ padding: '1%' }}>
                            <h3>Propuestas activas relacionadas a este tema: {propuestas.length}</h3>
                            <ul style={{ textAlign: 'left' }}>
                                {propuestas.map((actual, indice) =>
                                    <li key={actual.UniqueID + '-' + indice}>
                                        {actual.institucion}
                                    </li>
                                )}
                            </ul>
                        </Row>
                    }
                </Container>
            </Row>
            <Row style={{ display: exito }}>
                <Alert variant='success'>
                    <Alert.Heading>
                        Operación realizada con éxito
                    </Alert.Heading>
                    <p>
                        {mensaje}
                    </p>
                    <hr />
                    <div className="d-flex justify-content-end">
                        <Button variant='outline-success' onClick={() => { goBack(); props.refresh(); }}>
                            Ok
                        </Button>
                    </div>
                </Alert>
            </Row>
            <Row style={{ display: error }}>
                <Alert variant='danger'>
                    <Alert.Heading>
                        Ocurrió un error al actualizar el tema
                    </Alert.Heading>
                    <p>
                        {mensaje}
                    </p>
                    <hr />
                    <div className="d-flex justify-content-end">
                        <Button variant='outline-danger' onClick={goBack}>
                            Ok
                        </Button>
                    </div>
                </Alert>
            </Row>
            <Row style={{ display: cargando }}>
                <Alert variant='dark'>
                    <Alert.Heading>
                        <Spinner animation="border" role="status">
                        </Spinner>&nbsp;&nbsp;&nbsp;
                        Ingresando información
                    </Alert.Heading>
                    <p>
                        {mensaje}
                    </p>
                    <hr />
                    <p>
                        Por favor espere...
                    </p>
                </Alert>
            </Row>
            <Row style={{ display: confirmacion }}>
                <Alert variant='warning'>
                    <Alert.Heading>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
                            <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                        </svg>&nbsp;&nbsp;&nbsp;Se necesita confirmación
                    </Alert.Heading>
                    <p>
                        ¿Está segur@ de eliminar el tema seleccionado?
                        <br />
                        <strong>Nombre del tema: </strong> {props.tema.nombre}
                    </p>
                    <hr />
                    <div className="d-flex justify-content-end">
                        <Button variant='outline-danger' onClick={deleteSelf}>
                            si
                        </Button>&nbsp;&nbsp;&nbsp;
                        <Button variant='outline-dark' onClick={goBack}>
                            no
                        </Button>
                    </div>
                </Alert>
            </Row>
            <Row style={{ display: formulario }}>
                <Container>
                    <Form onSubmit={onSubmit}>
                        <Row>
                            <h1>Editar tema</h1>
                        </Row>
                        <Row>
                            <Col xs={12} md={12} lg={12}>
                                <Form.Group className="mb-3" controlId="nombre">
                                    <Form.Label>Nombre del tema</Form.Label>
                                    <Form.Control required type="text" placeholder="Ingrese el nombre del tema" name="nombre" value={nombre} onChange={onChange} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Form.Group className="mb-3" controlId="direccion">
                                <Form.Label>Descripción de la biblioteca</Form.Label>
                                <Form.Control as="textarea" rows={3} style={{ resize: 'none' }} placeholder="Ingrese la descripción de la biblioteca" name="descripcion" value={descripcion} onChange={onChange} />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group style={{ color: 'black' }}>
                                <Form.Label>Foto de biblioteca</Form.Label>
                                <Form.Control type="file" onChange={onChange} name="img" accept="image/*" />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Container fluid>
                                <Row style={{ marginTop: '25px', marginBottom: '25px' }}>
                                    <h2>Información del tema</h2>
                                </Row>
                                {informacion.map((actual, index) => <Row style={{ marginTop: '25px', marginBottom: '25px' }} key={index}>
                                    <Col xs={12} md={12} lg={4} style={{ marginTop: '15px', marginBottom: '15px' }}>
                                        <h5>Acciones</h5>
                                        <Row>
                                            <Button variant='outline-danger' onClick={() => { informacion.splice(index, 1); setEdit(edit + 1); }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3-fill" viewBox="0 0 16 16">
                                                <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                                            </svg>&nbsp;&nbsp;&nbsp;&nbsp;Eliminar información</Button>
                                        </Row>
                                    </Col>
                                    <Col xs={12} md={12} lg={4} style={{ marginTop: '15px', marginBottom: '15px' }}>
                                        <Form.Group>
                                            <Form.Label>Contenido</Form.Label>
                                            <Form.Control as="textarea" rows={3} style={{ resize: 'none' }} placeholder="Ingrese el contenido de la información" name={index + '-contenido'} value={actual.contenido} onChange={onChangeInfo} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={12} lg={4} style={{ marginTop: '15px', marginBottom: '15px' }}>
                                        <Form.Group>
                                            <Form.Label>Seleccione el tipo de información</Form.Label>
                                            <Form.Control as="select" required aria-label="tipo" name={index + '-tipo'} onChange={onChangeInfo} value={actual.tipo}>
                                                <option value="" key="0">Seleccione el tipo de información</option>
                                                {tiposInfo.map(curr => <option key={curr.UniqueID} value={curr.UniqueID}>{curr.Nombre}</option>)}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>)}
                                <Row style={{ marginTop: '25px', marginBottom: '25px' }}>
                                    <Button variant='outline-success' onClick={() => { informacion.push({ contenido: '', tipo: '' }); setEdit(edit + 1); }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-square-fill" viewBox="0 0 16 16">
                                        <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                                    </svg>&nbsp;&nbsp;&nbsp;&nbsp;Agregar información</Button>
                                </Row>
                            </Container>
                        </Row>
                        <Row>
                            <Button variant="outline-dark" className="font-bold" type="submit">Guardar</Button>
                        </Row>
                        <Row style={{ marginTop: '25px' }}>
                            <Button variant="outline-secondary" className="font-bold" onClick={goBack}>Cancelar</Button>
                        </Row>
                    </Form>
                </Container>
            </Row>
        </Container>
    )
}

export default ViewTema