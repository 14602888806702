import React, { useState } from 'react';
import { Row, Button, Col, Form, Container } from "react-bootstrap";
import "./Login.css"
import { useNavigate, useLocation } from 'react-router-dom'
import useAuth from '../../useAuth';
//import DEFAULT_URL from '../../api/url';
import ModalRecovery from './ModalRecovery';
export const Login = () => {
    const { setAuth } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";
    const [usr, setUsr] = useState('');
    const [pswrd, setPswrd] = useState('');
    const [showModalRecovery, setShowModalRecovery] = useState(false);
    const closeModalRecovery = () => {
        setShowModalRecovery(false);
    }
    const login = async (evt) => {
        evt.preventDefault();
        try {
            const info = { usr, pswrd };
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                cache: "no-cache",
                credentials: 'include',
                body: JSON.stringify(info)
            };
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/auth', requestOptions)
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    const accessToken = data?.accessToken;
                    //localStorage.setItem("CEA", data?.refreshToken);
                    const rol = data?.rol;
                    const roles = [rol];
                    setAuth({ usr, pswrd, roles, accessToken });
                    setUsr('');
                    setPswrd('');
                    navigate(from, { replace: true });
                });
        } catch (err) {
            console.log(err);
        }
    }
    const onChange = (evt) => {
        if (evt.target.name == 'usuario') {
            setUsr(evt.target.value);
        } else {
            setPswrd(evt.target.value);
        }
    }
    return (
        <div className="contenedor-login">
            <Container>
                <Row style={{ height: '100vh' }}>
                    <Col xs={12} md={12} lg={6}></Col>
                    <Col xs={12} md={12} lg={6} className="col-formulario">
                        <div className="container-formulario">
                            <div style={{ right: '55%', position: 'relative', cursor: 'pointer' }} data-toggle="tooltip" data-placement="top" title="Ir a inicio" onClick={() => window.location.replace('/')}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-house-fill" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6zm5-.793V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z" />
                                    <path fillRule="evenodd" d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z" />
                                </svg>
                            </div>
                            <h1 style={{ fontFamily: 'Montserrat, sans-serif' }}>Inicio Sesión</h1>
                            <div style={{ marginTop: '10%' }}>
                                <Form onSubmit={(evt) => { login(evt) }}>
                                    <Container>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="usuario">
                                                    <Form.Label>Nombre de usuario</Form.Label>
                                                    <Form.Control required type="text" name="usuario" placeholder="Usuario" value={usr} onChange={onChange} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="pass">
                                                    <Form.Label>Contraseña</Form.Label>
                                                    <Form.Control required type="password" name="pass" placeholder="Contraseña" value={pswrd} onChange={onChange} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={6} md={6} ls={6}>
                                                <Button variant="outline-secondary" onClick={() => setShowModalRecovery(true)}>Olvidé mi contraseña</Button>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: '10%' }}>
                                            <Button variant="secondary" className="font-bold" type="submit" >Iniciar Sesión</Button>
                                        </Row>
                                    </Container>
                                </Form>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <ModalRecovery show={showModalRecovery} onHide={closeModalRecovery} />
        </div>
    );

}