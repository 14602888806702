import React, { useState, useEffect } from 'react';
import { Modal, Row, Tabs, Tab, Button, Col, Form, Container, InputGroup } from "react-bootstrap";
import './ModalEditarEvento.css';
//import DEFAULT_URL from '../../../../api/url';

const ModalEditarEvento = (props) => {
    const [modalidades, setModalidades] = useState([]);
    const [tipos, setTipos] = useState([]);
    const [estados, setEstados] = useState([]);
    const [contador, setContador] = useState(0);
    useEffect(() => {
        async function fetchData() {
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/evento/modalidades', { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setModalidades(data.rows);
                });
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/evento/tipos', { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setTipos(data.rows);
                });
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/evento/estados', { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setEstados(data.rows);
                });
        }
        fetchData();
    }, [contador]);
    const guardarCambios = (evt) => {
        evt.preventDefault();
        props.submitchanges();
        setContador(contador + 1);
        props.onHide();
    }
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
            style={{ color: 'white' }}
        >
            <Form onSubmit={guardarCambios}>
                <Modal.Header closeButton style={{ backgroundColor: "#1e3d52", borderColor: 'transparent' }}>
                    <Modal.Title id="contained-modal-title-vcenter" className="font-bold">
                        Editar Información De Evento
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: "#1e3d52", height: '60vh', borderColor: 'transparent' }} id="body-modal-edit-evento">
                    <Tabs defaultActiveKey="info" id="uncontrolled-tab-example" className="mb-3" variant='pills'>
                        <Tab eventKey="info" title="Información General Del Evento" tabClassName='tab-edit'>
                            <Container>
                                <Row>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="mb-3" controlId={"Nombre-" + props.evento.UniqueID} >
                                            <Form.Label>Nombre De Evento</Form.Label>
                                            <Form.Control required onChange={props.eventochange} type="text" placeholder="Ingrese el nombre del Evento" name={"Nombre-" + props.evento.UniqueID} value={props.evento.Nombre} />
                                            <Form.Control.Feedback type="invalid">
                                                Por favor, ingrese el nombre dle evento.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="mb-3" controlId={"Minimo-" + props.evento.UniqueID}>
                                            <Form.Label>Mínimo De Participantes</Form.Label>
                                            <Form.Control required onChange={props.eventochange} type="text" placeholder="Ingrese el mínimo de participantes" name={"Minimo-" + props.evento.UniqueID} value={props.evento.Minimo} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Form.Group className="mb-3" controlId={"Descripcion-" + props.evento.UniqueID}>
                                        <Form.Label>Descripción De Evento</Form.Label>
                                        <Form.Control required onChange={props.eventochange} as="textarea" rows={3} style={{ resize: 'none' }} name={"Descripcion-" + props.evento.UniqueID} placeholder="Ingrese la descripción del evento" value={props.evento.Descripcion} />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Col xs={12} md={12} lg={4}>
                                        <Form.Group className="mb-3" controlId={"Fecha_Inicio-" + props.evento.UniqueID}>
                                            <Form.Label>Fecha De Inicio</Form.Label>
                                            <Form.Control required onChange={props.eventochange} type="date" name={"Fecha_Inicio-" + props.evento.UniqueID} value={props.evento.Fecha_Inicia} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={12} lg={4}>
                                        <Form.Group className="mb-3" controlId={"Fecha_Fin-" + props.evento.UniqueID}>
                                            <Form.Label>Fecha De Finalización</Form.Label>
                                            <Form.Control required onChange={props.eventochange} type="date" name={"Fecha_Fin-" + props.evento.UniqueID} value={props.evento.Fecha_Finaliza} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={12} lg={4}>
                                        <Form.Group className="mb-3" controlId={"Hora-" + props.evento.UniqueID}>
                                            <Form.Label>Hora Del Evento</Form.Label>
                                            <Form.Control required onChange={props.eventochange} type="text" placeholder="Ingrese la hora del evento" name={"Hora-" + props.evento.UniqueID} value={props.evento.Hora} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={12} lg={4}>
                                        <Form.Group className="mb-3" controlId={"modalidad-" + props.evento.UniqueID}>
                                            <Form.Label>Modalidad de evento</Form.Label>
                                            <Form.Control as="select" required aria-label="modalidad" name={"modalidad-" + props.evento.UniqueID} onChange={props.eventochange} value={props.evento.modalidad_id}>
                                                <option key="0">Seleccione la modalidad del evento</option>
                                                {modalidades.map(modalidad => <option key={modalidad.UniqueID} value={modalidad.UniqueID}>{modalidad.Nombre}</option>)}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={12} lg={4}>
                                        <Form.Group className="mb-3" controlId={"tipo-" + props.evento.UniqueID}>
                                            <Form.Label>Tipo de evento</Form.Label>
                                            <Form.Control as="select" required aria-label="tipo" name={"tipo-" + props.evento.UniqueID} onChange={props.eventochange} value={props.evento.tipo_id}>
                                                <option key="0">Seleccione el tipo del evento</option>
                                                {tipos.map(tipo => <option key={tipo.UniqueID} value={tipo.UniqueID}>{tipo.Nombre}</option>)}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={12} lg={4}>
                                        <Form.Group className="mb-3" controlId={"estado-" + props.evento.UniqueID}>
                                            <Form.Label>Estado de evento</Form.Label>
                                            <Form.Control as="select" required aria-label="estado" name={"estado-" + props.evento.UniqueID} onChange={props.eventochange} value={props.evento.estado_id}>
                                                <option key="0">Seleccione el estado del evento</option>
                                                {estados.map(estado => <option key={estado.UniqueID} value={estado.UniqueID}>{estado.Nombre}</option>)}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={12} lg={4}>
                                        <Form.Group className="mb-3" controlId={"dias-" + props.evento.UniqueID}>
                                            <Form.Label>Días a impartir</Form.Label>
                                            <Form.Control required onChange={props.eventochange} type="text" placeholder="Ingrese los días a impartir" name={"dias-" + props.evento.UniqueID} value={props.evento.dias} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={12} lg={4}>
                                        <Form.Group className="mb-3" controlId={"duracion-" + props.evento.UniqueID}>
                                            <Form.Label>Duración del evento</Form.Label>
                                            <Form.Control required onChange={props.eventochange} type="text" placeholder="Ingrese la duración" name={"duracion-" + props.evento.UniqueID} value={props.evento.duracion} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={12} lg={4}>
                                        <Form.Group className="mb-3" controlId={"sesiones-" + props.evento.UniqueID}>
                                            <Form.Label>Número de sesiones</Form.Label>
                                            <Form.Control required onChange={props.eventochange} type="text" placeholder="Ingrese el número de sesiones" name={"sesiones-" + props.evento.UniqueID} value={props.evento.sesiones} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={12} lg={6}>
                                        <Form.Group className="mb-3" controlId={"precio_agremiados-" + props.evento.UniqueID}>
                                            <Form.Label>Precio Agremiados</Form.Label>
                                            <Form.Control required onChange={props.eventochange} type="text" placeholder="Ingrese el precio para agremiados" name={"precio_agremiados-" + props.evento.UniqueID} value={props.evento.costo_agremiados} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={12} lg={6}>
                                        <Form.Group className="mb-3" controlId={"precio_no_agremiados-" + props.evento.UniqueID}>
                                            <Form.Label>Precio No Agremiados</Form.Label>
                                            <Form.Control required onChange={props.eventochange} type="text" placeholder="Ingrese el precio para no agremiados" name={"precio_no_agremiados-" + props.evento.UniqueID} value={props.evento.costo_no_agremiados} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Container>
                        </Tab>
                        <Tab eventKey="proy" title="Proyecciones" tabClassName='tab-edit'>
                            <Container>
                                <Row>
                                    <Form.Group className="mb-3" controlId={"Ingresos-" + props.evento.UniqueID}>
                                        <Form.Label>Ingresos Proyectados</Form.Label>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text>QTZ</InputGroup.Text>
                                            <Form.Control required onChange={props.eventochange} type="text" placeholder="Ingrese Los Ingresos Proyectados" name={"Ingresos-" + props.evento.UniqueID} value={props.evento.ingresos} />
                                        </InputGroup>
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group className="mb-3" controlId={"Egresos-" + props.evento.UniqueID}>
                                        <Form.Label>Egresos Proyectados</Form.Label>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text>QTZ</InputGroup.Text>
                                            <Form.Control required onChange={props.eventochange} type="text" placeholder="Ingrese Los Egresos Proyectados" name={"Egresos-" + props.evento.UniqueID} value={props.evento.egresos} />
                                        </InputGroup>
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group className="mb-3" controlId={"Resultado-" + props.evento.UniqueID}>
                                        <Form.Label>Resultado Proyectado</Form.Label>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text>QTZ</InputGroup.Text>
                                            <Form.Control required onChange={props.eventochange} type="text" placeholder="Ingrese El Resultado Proyectado" name={"Resultado-" + props.evento.UniqueID} value={props.evento.resultado} />
                                        </InputGroup>
                                    </Form.Group>
                                </Row>
                            </Container>
                        </Tab>
                    </Tabs>
                </Modal.Body>
                <Modal.Footer style={{ backgroundColor: "#1e3d52", borderColor: 'transparent' }}>
                    <Button variant="light" className="font-bold" type="submit" >Guardar</Button>
                    <Button variant="secondary" className="font-bold" onClick={props.onHide}>Cerrar</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );

}

export default ModalEditarEvento;