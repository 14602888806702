import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { Container, Row, Nav, Navbar } from 'react-bootstrap'
//import DEFAULT_URL from '../../api/url'
const Pagos = () => {
    const [contador] = useState(0);
    const [redireccion, setRedireccion] = useState('/');
    const [permiso, setPermiso] = useState(0);
    useEffect(() => {
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/auth/userinfo', { cache: "no-cache", credentials: 'include' })
            .then((res) => {
                return res.json();
            })
            .then(data => {
                if (data.tipo == 2) {
                    setRedireccion('/control-academico');
                } else if (data.tipo == 1) {
                    setRedireccion('/asesor');
                } else if (data.tipo == 4) {
                    setRedireccion('/home');
                }
                setPermiso(data.tipo);
            });
    }, [contador]);

    return (
        <Container fluid>
            <Row>
                <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
                    <Container>
                        <Navbar.Brand href='/pagos-electronicos/all'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-wallet2" viewBox="0 0 16 16">
                                <path d="M12.136.326A1.5 1.5 0 0 1 14 1.78V3h.5A1.5 1.5 0 0 1 16 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-9a1.5 1.5 0 0 1 1.432-1.499L12.136.326zM5.562 3H13V1.78a.5.5 0 0 0-.621-.484L5.562 3zM1.5 4a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-13z" />
                            </svg>&nbsp;&nbsp;&nbsp;&nbsp;Pagos electrónicos
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="me-auto">
                                {permiso != 1 && <Nav.Link href="/pagos-electronicos/create">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                                    </svg>&nbsp;&nbsp;&nbsp;&nbsp;Habilitar cobro electrónico
                                </Nav.Link>}
                                {permiso != 1 && <Nav.Link href='/pagos-electronicos/no-vinculado'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-tags-fill" viewBox="0 0 16 16">
                                        <path d="M2 2a1 1 0 0 1 1-1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 2 6.586V2zm3.5 4a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                                        <path d="M1.293 7.793A1 1 0 0 1 1 7.086V2a1 1 0 0 0-1 1v4.586a1 1 0 0 0 .293.707l7 7a1 1 0 0 0 1.414 0l.043-.043-7.457-7.457z" />
                                    </svg>&nbsp;&nbsp;&nbsp;&nbsp;Pagos no vinculados
                                </Nav.Link>}
                                <Nav.Link href={redireccion}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-90deg-left" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M1.146 4.854a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H12.5A2.5 2.5 0 0 1 15 6.5v8a.5.5 0 0 1-1 0v-8A1.5 1.5 0 0 0 12.5 5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4z" />
                                    </svg>&nbsp;&nbsp;&nbsp;&nbsp;Regresar a sistema
                                </Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </Row>
            <Row>
                <Outlet />
            </Row>
        </Container>
    )
}

export default Pagos