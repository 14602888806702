import React, { useState, useEffect } from 'react'
//import DEFAULT_URL from '../../../../../api/url';
import { Row, Alert, Button, Spinner, Col, Form, Container } from "react-bootstrap";
import DetalleSesion from './DetalleSesion';
const Sesion = (props) => {
    const [contador, setContador] = useState(0);
    const [detalle, setDetalle] = useState('block');
    const [formulario, setFormulario] = useState('none');
    const [tipos_enlace, setTiposEnlace] = useState([]);
    const [enlaces, setEnlaces] = useState([]);
    const [fechaInicio, setFechaInicio] = useState('');
    const [fechaFin, setFechaFin] = useState('');
    const [exito, setExito] = useState('none');
    const [error, setError] = useState('none');
    const [cargando, setCargando] = useState('none');
    const [mensaje, setMensaje] = useState('');
    const [sesiones, setSesiones] = useState([]);
    useEffect(() => {
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/tipos/enlace', { cache: "no-cache" })
            .then((res) => {
                return res.json();
            })
            .then(data => {
                setTiposEnlace(data.rows);
            });
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/sesion/read/' + props.evento.UniqueID, { cache: "no-cache" })
            .then((res) => {
                return res.json();
            })
            .then(data => {
                setSesiones(data.rows);
            });
    }, [contador]);

    const submit = (evt) => {
        evt.preventDefault();
        cargar('Validando información');
        if (enlaces.length == 0) {
            operacionError('Debes añadir 1 o más enlaces');
        } else {
            cargar('Sincronizando base de datos');
            const info = {
                fecha_inicio: fechaInicio,
                fecha_fin: fechaFin,
                evento_UniqueID: props.evento.UniqueID,
                enlaces: enlaces
            };
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(info)
            };
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/sesion/create', requestOptions)
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    if (data.codigo == 200) {
                        operacionExito('Sesión ingresada exitosamente');
                    } else {
                        operacionError('Error al grabar registro en db');
                    }
                });
        }
    }
    const onChange = (evt) => {
        if (evt.target.name == 'fecha_inicio') {
            setFechaInicio(evt.target.value);
        } else {
            setFechaFin(evt.target.value);
        }
        setContador(contador + 1);
    }
    const goToForm = () => {
        setDetalle('none');
        setFormulario('block');
        setContador(contador + 1);
    }
    const cargar = (mensaje) => {
        setExito('none');
        setFormulario('none');
        setError('none');
        setCargando('block');
        setMensaje(mensaje);
        setContador(contador + 1);
    }
    const operacionExito = (mensaje) => {
        setExito('block');
        setFormulario('none');
        setError('none');
        setCargando('none');
        setMensaje(mensaje);
        setContador(contador + 1);
    }
    const operacionError = (mensaje) => {
        setExito('none');
        setFormulario('none');
        setError('block');
        setCargando('none');
        setMensaje(mensaje);
        setContador(contador + 1);
    }
    const onChangeEnlaces = (evt) => {
        let name = evt.target.name.split('-');
        let tmp = enlaces;
        if (name[1] == 'url') {
            tmp[name[0]].url = evt.target.value;
        } else if (name[1] == 'comentario') {
            tmp[name[0]].comentario = evt.target.value;
        } else {
            tmp[name[0]].tipo = evt.target.value;
        }
        setEnlaces(tmp);
        setContador(contador + 1);
    }
    const goBack = () => {
        setFechaInicio('');
        setFechaFin('');
        setEnlaces([]);
        setDetalle('block');
        setFormulario('none');
        setError('none');
        setCargando('none');
        setExito('none');
        setContador(contador + 1);
    }
    return (
        <Row style={{ marginTop: '45px', border: '0.5px dashed black', borderRadius: '15px', padding: '25px' }}>
            <Container fluid>
                <Row>
                    <h1>Sesiones</h1>
                </Row>
                <Row style={{ display: detalle, marginTop: '45px' }}>
                    <Button variant="outline-success" onClick={goToForm}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-patch-check-fill" viewBox="0 0 16 16">
                            <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z" />
                        </svg>&nbsp;&nbsp;&nbsp;&nbsp;Registrar Sesión
                    </Button>
                </Row>
                <Row style={{ display: detalle, marginTop: '45px' }}>
                    <Button variant="outline-success" onClick={() => { setContador(contador + 1); }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                            <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                        </svg>&nbsp;&nbsp;&nbsp;&nbsp;Refrescar Info
                    </Button>
                </Row>
                <Row style={{ display: detalle, marginTop: '45px' }}>
                    <Container fluid>
                        {sesiones.map((actual, index) => <DetalleSesion sesion={actual} indice={index} refresh={() => { setContador(contador + 1); }} />)}
                    </Container>
                </Row>
                <Row style={{ display: formulario, marginTop: '25px' }}>
                    <Form onSubmit={submit}>
                        <Container>
                            <Row style={{ padding: '15px' }}>
                                <h1>Registrar sesión</h1>
                            </Row>
                            <Row style={{ padding: '15px' }}>
                                <Col xs={12} md={12} lg={6}>
                                    <Form.Group className="mb-3" controlId="url">
                                        <Form.Label>Fecha y hora de inicio</Form.Label>
                                        <Form.Control required type="datetime-local" name="fecha_inicio" value={fechaInicio} placeholder="Fecha y hora de inicio" onChange={onChange} />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={12} lg={6}>
                                    <Form.Group className="mb-3" controlId="url">
                                        <Form.Label>Fecha y hora de finalización</Form.Label>
                                        <Form.Control required type="datetime-local" name="fecha_fin" value={fechaFin} placeholder="Fecha y hora de finalización" onChange={onChange} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row style={{ padding: '15px' }}>
                                <h3>Enlaces para sesión</h3>
                            </Row>
                            <Row style={{ padding: '15px' }}>
                                <Button variant="outline-success" onClick={() => { enlaces.push({ url: '', tipo: '', comentario: '' }); setContador(contador + 1); }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                                    </svg>&nbsp;&nbsp;&nbsp;&nbsp;Añadir enlace
                                </Button>
                            </Row>
                            {enlaces.map((actual, index) => <Row style={{ padding: '15px' }}>
                                <Container fluid>
                                    <Row style={{ marginBottom: '15px' }}>
                                        <Col xs={12} md={12} lg={8}>
                                        </Col>
                                        <Col xs={12} md={12} lg={4}>
                                            <Button variant="outline-danger" onClick={() => { enlaces.splice(index, 1); setContador(contador + 1); }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
                                                    <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                                                </svg>&nbsp;&nbsp;&nbsp;&nbsp;Eliminar enlace
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={12} lg={6}>
                                            <Form.Group className="mb-3" controlId="url">
                                                <Form.Label>Url</Form.Label>
                                                <Form.Control required type="text" name={index + "-url"} value={actual.url} placeholder="Url de enlace" onChange={onChangeEnlaces} />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={12} lg={6}>
                                            <Form.Group className="mb-3" controlId="tipo_enlace">
                                                <Form.Label>Tipo de enlace</Form.Label>
                                                <Form.Control as="select" required aria-label="Tipo Enlace" value={actual.tipo} name={index + "-tipo_enlace"} onChange={onChangeEnlaces}>
                                                    <option value="" key="0">Seleccione el tipo de enlace</option>
                                                    {tipos_enlace.map(tipo => <option key={tipo.UniqueID} value={tipo.UniqueID}>{tipo.Nombre}</option>)}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Form.Group className="mb-3" controlId="comentario">
                                            <Form.Label>Comentario o instrucciones de enlace</Form.Label>
                                            <Form.Control as="textarea" rows={3} style={{ resize: 'none' }} name={index + "-comentario"} onChange={onChangeEnlaces} value={actual.comentario} />
                                        </Form.Group>
                                    </Row>
                                </Container>
                            </Row>)}
                            <Row>
                                <Button type="submit" variant="outline-success"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-save2-fill" viewBox="0 0 16 16">
                                    <path d="M8.5 1.5A1.5 1.5 0 0 1 10 0h4a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h6c-.314.418-.5.937-.5 1.5v6h-2a.5.5 0 0 0-.354.854l2.5 2.5a.5.5 0 0 0 .708 0l2.5-2.5A.5.5 0 0 0 10.5 7.5h-2v-6z" />
                                </svg>&nbsp;&nbsp;&nbsp;&nbsp;Guardar</Button>
                            </Row>
                            <Row style={{ marginTop: '15px' }}>
                                <Button onClick={goBack} variant="outline-dark"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-octagon-fill" viewBox="0 0 16 16">
                                    <path d="M11.46.146A.5.5 0 0 0 11.107 0H4.893a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353L11.46.146zm-6.106 4.5L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708z" />
                                </svg>&nbsp;&nbsp;&nbsp;&nbsp;Cancelar</Button>
                            </Row>
                        </Container>
                    </Form>
                </Row>
                <Row style={{ display: exito }}>
                    <Alert variant='success'>
                        <Alert.Heading>
                            Operación realizada con éxito
                        </Alert.Heading>
                        <p>
                            {mensaje}
                        </p>
                        <hr />
                        <div className="d-flex justify-content-end">
                            <Button variant='outline-success' onClick={goBack}>
                                Ok
                            </Button>
                        </div>
                    </Alert>
                </Row>
                <Row style={{ display: error }}>
                    <Alert variant='danger'>
                        <Alert.Heading>
                            Ocurrió un error al ingresar la propuesta
                        </Alert.Heading>
                        <p>
                            {mensaje}
                        </p>
                        <hr />
                        <div className="d-flex justify-content-end">
                            <Button variant='outline-danger' onClick={goBack}>
                                Ok
                            </Button>
                        </div>
                    </Alert>
                </Row>
                <Row style={{ display: cargando }}>
                    <Alert variant='dark'>
                        <Alert.Heading>
                            <Spinner animation="border" role="status">
                            </Spinner>&nbsp;&nbsp;&nbsp;
                            Ingresando información
                        </Alert.Heading>
                        <p>
                            {mensaje}
                        </p>
                        <hr />
                        <p>
                            Por favor espere...
                        </p>
                    </Alert>
                </Row>
            </Container>
        </Row>
    )
}

export default Sesion