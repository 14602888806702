import React, { useState, useEffect } from 'react';
import { Button } from "react-bootstrap";
//import DEFAULT_URL from '../../../../../api/url';
import './Gerencia.css'
import ModalIngresos from '../Modal-Ingresos/ModalIngresos';
import ModalRendimiento from '../Modal-Rendimiento/ModalRendimiento';
import ModalPatrocinios from '../Modal-Patrocinios/ModalPatrocinios';
const Evento = (props) => {
    const [contador, setContador] = useState(0);
    const [ventas, setVentas] = useState([]);
    const [ingresos, setIngresos] = useState([]);
    const [modalIngresos, setModalIngresos] = useState(false);
    const [modalRendimiento, setModalRendimiento] = useState(0);
    const [modalPatrocinios, setModalPatrocinios] = useState(false);
    useEffect(() => {
        async function fetchData() {
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/evento/ventas/' + props.evento.UniqueID, { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setVentas(data.rows);
                });
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/evento/ingresos/' + props.evento.UniqueID, { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setIngresos(data.rows);
                });
        }
        fetchData();
    }, [contador, props.evento.UniqueID]);
    return (
        <tr key={props.evento.UniqueID}>
            <td>{props.evento['Modalidad de evento']}</td>
            <td>{props.evento['Evento']}</td>
            <td>{props.evento['Fecha de inicio del evento']}</td>
            <td>{props.evento['Ingresos Confirmados']}</td>
            <td>{props.evento['Ingresos No Confirmados']}</td>
            <td>{props.evento['Ingresos Totales']}</td>
            <td>{props.evento['Ingresos (patrocinios)']}</td>
            <td>{props.evento['Egresos']}</td>
            <td>{props.evento['Resultado']}</td>        
            <td>{props.evento['Resultado no confirmado']}</td>
            <td>{props.evento['Cortesías']}</td>
            <td>{props.evento['Pagados']}</td>
            <td>{props.evento['Sin validación de contabilidad']}</td>
            <td>{props.evento['Total Inscritos']}</td>
            <td>{props.evento['Ejecutado EBC (Inscritos)']}</td>
            <td>{props.evento['Ejecutado EBG (Inscritos)']}</td>
            <td>{props.evento['Ejecutado EBC (Ingresos)']}</td>
            <td>{props.evento['Ejecutado EBG (Ingresos)']}</td>
            <td>{props.evento['Ingresos proyectados']}</td>
            <td>{props.evento['Egresos proyectados']}</td>
            <td>{props.evento['Resultado proyectado']}</td>
            <td>{props.evento['Mínimo de participantes']}</td>
            <td><Button variant="outline-dark" onClick={() => { setModalIngresos(true); setContador(contador + 1); }}>Ver detalle</Button></td>
            <td><Button variant="outline-dark" onClick={() => { setModalRendimiento(true); setContador(contador + 1); }}>Ver detalle</Button></td>
            <td><Button variant="outline-dark" onClick={() => { setModalPatrocinios(true); setContador(contador + 1); }}>Ver detalle</Button></td>
            <ModalIngresos
                show={modalIngresos}
                onHide={() => { setModalIngresos(false); setContador(contador + 1); }}
                data={ingresos}
                ventas={ventas}
            />
            <ModalRendimiento
                show={modalRendimiento}
                onHide={() => { setModalRendimiento(false); setContador(contador + 1); }}
                data={ventas}
                evt={props.evento.Nombre}
            />
            <ModalPatrocinios
                show={modalPatrocinios}
                onHide={() => { setModalPatrocinios(false); setContador(contador + 1); }}
                data={ingresos}
            />
        </tr >
    )
}

export default Evento