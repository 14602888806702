import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import Formulario from "../../../../../helpers/form";
const helpers = require("../../../../../helpers/helpers");
const BatchUpload = (props) => {
  const [contador] = useState(0);
  const [formConfig, setFormConfig] = useState(null);
  const [asesores, setAsesores] = useState([]);
  useEffect(() => {
    helpers
      .getData("/api/read/asesor")
      .then((response_asesores) => {
        setAsesores(response_asesores.data.rows);
        setFormConfig({
          title: "Ingreso de participantes a través de excel consolidado",
          submitTitle: "Registrar participantes",
          customSubmit: true,
          formConfig: [
            {
              name: "archivo",
              type: 6,
              required: true,
              title: "Excel a subir",
              placeholder: "Ingrese lo solicitado",
              value: "",
            },
          ],
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [contador]);
  const afterSubmit = (formConfig) => {
    return new Promise((resolve, reject) => {
      let body = {};
      formConfig.formConfig.map((actual, indice) => {
        body[actual.name] = actual.value;
        if (indice == formConfig.formConfig.length - 1) {
          const base64Code = body.archivo.split(";");
          let base64FileCode = base64Code[1].split(",")[1];
          let info = helpers.parseExcelBase64(base64FileCode);
          let check_array = info.map((participante) => {
            return { Correo: participante.email };
          });
          let body_check = { participantes: check_array };
          helpers
            .postData(
              "/api/read/detalle_inscripcion/search/email/" + props.evento,
              body_check
            )
            .then((response) => {
              let final_array = info.filter(
                (current) =>
                  response.data.salida.find(
                    (repetido) => repetido.Correo == current.email
                  ) == null
              );
              let por_asesores = helpers.classifyByField(final_array, "asesor");
              por_asesores.map((current, index) => {
                let por_empresa = helpers.classifyByField(current, "empresa");
                por_empresa.map((ficha, indicador) => {
                  inscribir(ficha[0].empresa, ficha[0].asesor, ficha)
                    .then((res) => {
                      if (indicador == por_empresa.length - 1) {
                        if (index == por_asesores.length - 1) {
                          resolve();
                        }
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                      reject("ERROR");
                    });
                });
              });
            })
            .catch((error) => {
              console.log(error);
              reject("ERROR");
            });
        }
      });
    });
  };

  const inscribir = async (empresa, asesor, participantes) => {
    return new Promise((resolve, reject) => {
      let vendor = asesores.find(
        (vendedor) => vendedor.Nombres + " " + vendedor.Apellidos == asesor
      );
      let inversion = participantes.reduce((accumulator, currentValue) => {
        return accumulator + parseFloat(currentValue.costo);
      }, 0);
      let people = participantes.map((participante) => {
        return {
          Nombres: participante.nombres,
          Apellidos: participante.apellidos,
          Puesto: participante.puesto,
          Correo: participante.email,
          Tel: participante.telefono,
          Celular: participante.telefono,
          identificacion: "PENDIENTE",
          Pais:
            participante.pais == null
              ? "PENDIENTE"
              : participante == ""
              ? "PENDIENTE"
              : participante.pais,
        };
      });
      if (vendor != null) {
        let body = {
          participantes: people,
          Agremiado: false,
          Inversion: inversion,
          Fecha_Max_FACT:
            new Date(Date.now()).getFullYear() +
            "-" +
            (new Date(Date.now()).getMonth() + 1) +
            "-" +
            new Date(Date.now()).getDate(),
          Nombre_FACT: "CF",
          NIT: "CF",
          Direccion: "Ciudad",
          Correo_FACT: "PENDIENTE",
          Responsable: "PENDIENTE",
          Correo_RESP: "PENDIENTE",
          Tel_RESP: "PENDIENTE",
          Responsable_PAGO: "PENDIENTE",
          Correo_RP: "PENDIENTE",
          Tel_RP: "PENDIENTE",
          Comentario:
            "INSCRIPCIÓN EXTEMPORÁNEA AUTORIZADA POR CONTROL ACADÉMICO AL DÍA " +
            new Date(Date.now()).getFullYear() +
            "-" +
            (new Date(Date.now()).getMonth() + 1) +
            "-" +
            new Date(Date.now()).getDate(),
          Evento_Unique_ID: props.evento,
          Forma_Pago_UniqueID: 15,
          Moneda_Unique_ID: 8,
          moneda: "Q",
          Asesor_UniqueID: vendor.UniqueID,
          empresa: empresa,
        };
        helpers
          .postData("/api/inscribir", body)
          .then((response_inscripcion) => {
            console.log(response_inscripcion);
            if (inversion == 0) {
              let body_confirmar = { UniqueID: response_inscripcion.data.id };
              helpers
                .postData(
                  "/api/update/inscripcion/confirmar_pago",
                  body_confirmar
                )
                .then((response_confirmar) => {
                  resolve();
                })
                .catch((error) => {
                  console.log(error);
                  reject(error);
                });
            } else {
              resolve();
            }
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      } else {
        resolve();
      }
    });
  };

  return (
    <Container>
      <Row className="mt-5" style={{ padding: "1%" }}>
        {formConfig != null && (
          <Formulario formConfig={formConfig} afterSubmit={afterSubmit} />
        )}
      </Row>
    </Container>
  );
};

export default BatchUpload;
