import React, { useState } from 'react';
import { Row, Col, Container, Accordion } from "react-bootstrap";
import Entregable from './Entregable';
import ModalSubirNotas from './ModalSubirNotas';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
//import DEFAULT_URL from '../../../../api/url';

const PonderarParticipantes = (props) => {
    const [modalMaterial, setModalMaterial] = useState(false);
    const ocultarModalMaterial = () => {
        setModalMaterial(false);
    }
    const downloadReporte = async () => {
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/actividad/grades/' + props.evento + '/' + props.actividad, { cache: "no-cache" })
            .then((res) => {
                return res.json();
            })
            .then(data_ => {
                const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                const fileExtension = '.xlsx';
                const ws = XLSX.utils.json_to_sheet(data_.respuesta);
                const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const data = new Blob([excelBuffer], { type: fileType });
                FileSaver.saveAs(data, Date.now() + fileExtension);
            });
    }
    return (
        <Container>
            <Row style={{ marginBottom: '20px' }}>
                <Col xs={1} md={1}>
                    <div style={{ cursor: 'pointer', color: 'white' }} data-toggle="tooltip" data-placement="top" title="Subir Notas" onClick={() => setModalMaterial(true)} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-cloud-arrow-up-fill" viewBox="0 0 16 16">
                            <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 5.146a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2z" />
                        </svg>
                    </div>
                </Col>
                <Col xs={1} md={1}>
                    <div style={{ cursor: 'pointer', color: 'white' }} data-toggle="tooltip" data-placement="top" title="Descargar Reporte De Actividad" onClick={() => downloadReporte()} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-file-earmark-spreadsheet-fill" viewBox="0 0 16 16">
                            <path d="M6 12v-2h3v2H6z" />
                            <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM3 9h10v1h-3v2h3v1h-3v2H9v-2H6v2H5v-2H3v-1h2v-2H3V9z" />
                        </svg>
                    </div>
                </Col>
            </Row>
            <Row className="ponderar-acordion">
                <Accordion flush>
                    {props.participantes.map((participante) =>
                        <Accordion.Item eventKey={participante.UniqueID} key={participante.UniqueID}>
                            <Accordion.Header>{participante.Nombres + ' ' + participante.Apellidos + "( " + participante.Identificacion + " )"}</Accordion.Header>
                            <Accordion.Body>
                                <Entregable key={participante.registro_academico} actividad={props.actividad} registro_academico={participante.registro_academico} nombres={participante.Nombres + ' ' + participante.Apellidos} ponderacion={props.ponderacion} />
                            </Accordion.Body>
                        </Accordion.Item>
                    )}
                </Accordion>
            </Row>
            <ModalSubirNotas
                show={modalMaterial}
                onHide={ocultarModalMaterial}
                actividad={props.actividad}
                refrescar={props.refrescar}
            />
        </Container>
    );
}

export default PonderarParticipantes
/*
export class PonderarParticipantes extends Component {

    state = { modalMaterial: false }

    ocultarModalMaterial = () => {
        this.setState({ modalMaterial: false });
    }

    downloadReporte = () => {
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/actividad/grades/' + this.props.evento + '/' + this.props.actividad, { cache: "no-cache" })
            .then((res) => {
                return res.json();
            })
            .then(data_ => {
                //console.log(data_);
                const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                const fileExtension = '.xlsx';
                const ws = XLSX.utils.json_to_sheet(data_.respuesta);
                const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const data = new Blob([excelBuffer], { type: fileType });
                FileSaver.saveAs(data, Date.now() + fileExtension);
            });
    }
    render() {
        return (
            <Container>
                <Row style={{ marginBottom: '20px' }}>
                    <Col xs={1} md={1}>
                        <div style={{ cursor: 'pointer', color: 'white' }} data-toggle="tooltip" data-placement="top" title="Subir Notas" onClick={() => this.setState({ modalMaterial: true })} >
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-cloud-arrow-up-fill" viewBox="0 0 16 16">
                                <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 5.146a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2z" />
                            </svg>
                        </div>
                    </Col>
                    <Col xs={1} md={1}>
                        <div style={{ cursor: 'pointer', color: 'white' }} data-toggle="tooltip" data-placement="top" title="Descargar Reporte De Actividad" onClick={() => this.downloadReporte()} >
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-file-earmark-spreadsheet-fill" viewBox="0 0 16 16">
                                <path d="M6 12v-2h3v2H6z" />
                                <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM3 9h10v1h-3v2h3v1h-3v2H9v-2H6v2H5v-2H3v-1h2v-2H3V9z" />
                            </svg>
                        </div>
                    </Col>
                </Row>
                <Row className="ponderar-acordion">
                    <Accordion flush>
                        {this.props.participantes.map((participante) =>
                            <Accordion.Item eventKey={participante.UniqueID} key={participante.UniqueID}>
                                <Accordion.Header>{participante.Nombres + ' ' + participante.Apellidos + "( " + participante.Identificacion + " )"}</Accordion.Header>
                                <Accordion.Body>
                                    <Entregable key={participante.registro_academico} actividad={this.props.actividad} registro_academico={participante.registro_academico} nombres={participante.Nombres + ' ' + participante.Apellidos} ponderacion={this.props.ponderacion} />
                                </Accordion.Body>
                            </Accordion.Item>
                        )}
                    </Accordion>
                </Row>
                <ModalSubirNotas
                    show={this.state.modalMaterial}
                    onHide={this.ocultarModalMaterial}
                    actividad={this.props.actividad}
                />
            </Container>
        );
    }
}
*/