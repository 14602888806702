import React, { useState, useEffect } from 'react';
import { Row, Button, Col, Form, Container, Table } from "react-bootstrap";
//import DEFAULT_URL from '../../api/url';
import TipoEvento from './TipoEvento';
import './Gerencia.css'
import {
    ComposedChart,
    Line,
    Area,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';
const Subgerencia = () => {
    const [contador, setContador] = useState(0);
    const [fechaInicio, setFechaInicio] = useState('');
    const [fechaFin, setFechaFin] = useState('');
    const [reporte, setReporte] = useState([]);
    const [buscar, setBuscar] = useState(0);
    const [ventasTotales, setVentasTotales] = useState([]);
    const [ingresosTotales, setIngresosTotales] = useState([]);
    const [info, setInfo] = useState([]);
    const [totalesEjecucion, setTotalesEjecucion] = useState([]);
    useEffect(() => {
        async function fetchData() {
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/gerencia/reporte/' + fechaInicio + '/' + fechaFin)
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setReporte(data.salida);
                });
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/evento/ventas/' + fechaInicio + '/' + fechaFin)
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setVentasTotales(data.rows);
                    fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/evento/ingresos/reporte-intervalo/' + fechaInicio + '/' + fechaFin)
                        .then((res) => {
                            return res.json();
                        })
                        .then(datos => {
                            setIngresosTotales(datos.rows);
                            let temp = [];
                            data.rows.map((actual) => {
                                const index_finder = (asesor) => asesor.asesor === actual.asesor;
                                if (datos.rows.findIndex(index_finder) >= 0) {
                                    let ingresos = datos.rows[datos.rows.findIndex(index_finder)].ingresos;
                                    let fichas_confirmadas = datos.rows[datos.rows.findIndex(index_finder)].inscritos;
                                    temp.push({ ingresos: ingresos, fichas_confirmadas: fichas_confirmadas, asesor: actual.asesor, ventas: actual.inscritos });
                                }
                                return true;
                            });
                            setInfo(temp);
                        });
                });
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/evento/ingresos/reporte-intervalo-total/' + fechaInicio + '/' + fechaFin)
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setTotalesEjecucion(data);
                });
        }
        if (fechaInicio !== '' && fechaFin !== '') {
            fetchData();
        }
    }, [buscar, fechaFin, fechaInicio]);

    const onChange = (evt) => {
        if (evt.target.name == "fechaInicio") {
            setFechaInicio(evt.target.value);
        } else {
            setFechaFin(evt.target.value);
        }
        setContador(contador + 1);
    }
    const onSubmit = (evt) => {
        evt.preventDefault();
        setBuscar(buscar + 1);
    }
    const genPDF = () => {
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/gerencia/reporte/PDF/' + fechaInicio + '/' + fechaFin)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                if (data.codigo == 200) {
                    window.open(data.enlace, '_blank').focus();
                } else {
                    alert('error');
                    console.log(data);
                }
            });
    }
    return (
        <div style={{ width: '100vw', height: '100vh', backgroundColor: 'black', overflowY: 'scroll' }}>
            <Container fluid style={{ paddingLeft: '50px', paddingRight: '50px' }}>
                <Row style={{ marginTop: '75px' }}>
                    <Col xs={12} md={6} lg={6} style={{ padding: '8%', borderRadius: '70px' }}>
                        <img src='https://ik.imagekit.io/p5ethkcnzwh/Blanco_Logo_final_2022_ABG_6V4gmyglP.png?ik-sdk-version=javascript-1.4.3&updatedAt=1657298859263' style={{ width: '100%' }} />
                    </Col>
                    <Col xs={12} md={6} lg={6} style={{ display: 'grid', placeItems: 'center', color: 'white' }}>
                        <h1>ESCUELA BANCARIA DE GUATEMALA</h1>
                        <h1>Informes Subgerencia</h1>
                    </Col>
                </Row>
                <Row style={{ marginTop: '75px' }}>
                    <Form onSubmit={onSubmit}>
                        <Container style={{ color: 'white' }}>
                            <Row>
                                <h1>Por favor seleccione un intervalo de tiempo</h1>
                            </Row>
                            <Row style={{ marginTop: '25px' }}>
                                <Col xs={12} md={6} lg={3}>
                                    <Form.Group className="mb-3" controlId="fechaInicio">
                                        <Form.Label>Fecha Inicio</Form.Label>
                                        <Form.Control type="date" required placeholder="Ingrese la fecha inicio" name="fechaInicio" onChange={onChange} value={fechaInicio} />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6} lg={3}>
                                    <Form.Group className="mb-3" controlId="fechaFin">
                                        <Form.Label>Fecha Fin</Form.Label>
                                        <Form.Control type="date" required placeholder="Ingrese la fecha fin" name="fechaFin" onChange={onChange} value={fechaFin} />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={12} lg={3} style={{ display: 'grid', placeItems: 'center' }}>
                                    <Button variant="light" type="submit">Procesar</Button>
                                </Col>
                                <Col xs={12} md={12} lg={3} style={{ display: 'grid', placeItems: 'center' }}>
                                    <Button onClick={genPDF} variant="outline-light"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-pdf-fill" viewBox="0 0 16 16">
                                        <path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z" />
                                        <path fillRule="evenodd" d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z" />
                                    </svg>&nbsp;&nbsp;&nbsp;&nbsp;Generar reporte en PDF</Button>
                                </Col>
                            </Row>
                        </Container>
                    </Form>
                </Row>
                {reporte.map((actual) => <TipoEvento eventos={actual} />)}
                {(ventasTotales.length > 0 && ingresosTotales.length > 0) &&
                    <Row>
                        <Container fluid>
                            <Row style={{ color: 'white', marginTop: '45px' }}>
                                <h1>Ventas totales en periodo seleccionado</h1>
                            </Row>
                            <Row style={{ backgroundColor: 'white', marginTop: '45px', height: '800px' }}>
                                <Container>
                                    <ResponsiveContainer width="100%" height="100%">
                                        <ComposedChart
                                            width={500}
                                            height={400}
                                            data={info}
                                            margin={{
                                                top: 20,
                                                right: 20,
                                                bottom: 20,
                                                left: 20,
                                            }}
                                        >
                                            <CartesianGrid stroke="#f5f5f5" />
                                            <XAxis dataKey="asesor" scale="band" />
                                            <YAxis />
                                            <Tooltip />
                                            <Legend />
                                            <Area type="monotone" dataKey="ingresos" fill="#8884d8" stroke="#8884d8" />
                                            <Bar dataKey="ventas" barSize={20} fill="#413ea0" />
                                            <Line dataKey="fichas_confirmadas" fill="red" />
                                        </ComposedChart>
                                    </ResponsiveContainer>
                                </Container>
                            </Row>
                            <Row style={{ color: 'white', marginTop: '45px' }}>
                                <h1>Inscripciones totales en periodo seleccionado</h1>
                            </Row>
                            <Row style={{ backgroundColor: 'white', marginTop: '45px', height: '800px' }}>
                                <Container>
                                    <ResponsiveContainer width="100%" height="100%" id='chart'>
                                        <ComposedChart
                                            layout="vertical"
                                            width={500}
                                            height={400}
                                            data={ventasTotales}
                                            margin={{
                                                top: 20,
                                                right: 20,
                                                bottom: 20,
                                                left: 20,
                                            }}
                                        >
                                            <CartesianGrid stroke="#f5f5f5" />
                                            <XAxis type="number" />
                                            <YAxis dataKey="asesor" type="category" scale="band" />
                                            <Tooltip />
                                            <Legend />
                                            <Bar dataKey="inscritos" barSize={20} fill="#416788" />
                                        </ComposedChart>
                                    </ResponsiveContainer>
                                </Container>
                            </Row>
                        </Container>
                    </Row>
                }
                {
                    (totalesEjecucion.ingresos != null && totalesEjecucion.cortesias != null && totalesEjecucion.pagados != null && totalesEjecucion.sin_confirmar != null && totalesEjecucion.resultado_total_inscritos_ebc != null && totalesEjecucion.total_inscritos_ebg != null) &&
                    <Row className='fila-tabla-reporte' style={{ marginTop: '45px', }}>
                        <Container>
                            <Row>
                                <div >
                                    <h1 style={{ color: 'white', marginBottom: '45px' }}>Totales ejecución</h1>
                                    <Table striped bordered hover variant="light" style={{ borderCollapse: 'collapse', backgroundColor: 'white' }}>
                                        <thead>
                                            <th style={{ position: 'sticky', top: '0' }}>Ingresos</th>
                                            <th style={{ position: 'sticky', top: '0' }}>Cortesías</th>
                                            <th style={{ position: 'sticky', top: '0' }}>Pagados</th>
                                            <th style={{ position: 'sticky', top: '0' }}>Sin validación de contabilidad</th>
                                            <th style={{ position: 'sticky', top: '0' }}>Ejecutado EBC</th>
                                            <th style={{ position: 'sticky', top: '0' }}>Ejecutado EBG</th>
                                        </thead>
                                        <tbody>
                                            {(totalesEjecucion.ingresos != null && totalesEjecucion.ingresos[0].ingresos != null) && <td>{totalesEjecucion.ingresos[0].ingresos}</td>}
                                            {(totalesEjecucion.ingresos != null && totalesEjecucion.ingresos[0].ingresos == null) && <td>Q 0.00</td>}
                                            {(totalesEjecucion.cortesias != null && totalesEjecucion.cortesias[0].inscritos != null) && <td>{totalesEjecucion.cortesias[0].inscritos}</td>}
                                            {(totalesEjecucion.cortesias != null && totalesEjecucion.cortesias[0].inscritos == null) && <td>0</td>}
                                            {(totalesEjecucion.pagados != null && totalesEjecucion.pagados[0].inscritos != null) && <td>{totalesEjecucion.pagados[0].inscritos}</td>}
                                            {(totalesEjecucion.pagados != null && totalesEjecucion.pagados[0].inscritos == null) && <td>0</td>}
                                            {(totalesEjecucion.sin_confirmar != null && totalesEjecucion.sin_confirmar[0].inscritos != null) && <td>{totalesEjecucion.sin_confirmar[0].inscritos}</td>}
                                            {(totalesEjecucion.sin_confirmar != null && totalesEjecucion.sin_confirmar[0].inscritos == null) && <td>0</td>}
                                            {(totalesEjecucion.resultado_total_inscritos_ebc != null && totalesEjecucion.resultado_total_inscritos_ebc[0].inscritos != null) && <td>{totalesEjecucion.resultado_total_inscritos_ebc[0].inscritos}</td>}
                                            {(totalesEjecucion.resultado_total_inscritos_ebc != null && totalesEjecucion.resultado_total_inscritos_ebc[0].inscritos == null) && <td>0</td>}
                                            {(totalesEjecucion.total_inscritos_ebg != null && totalesEjecucion.total_inscritos_ebg[0].inscritos != null) && <td>{totalesEjecucion.total_inscritos_ebg[0].inscritos}</td>}
                                            {(totalesEjecucion.total_inscritos_ebg != null && totalesEjecucion.total_inscritos_ebg[0].inscritos == null) && <td>0</td>}
                                        </tbody>
                                    </Table>
                                </div>
                            </Row>
                        </Container>
                    </Row>
                }
            </Container>
        </div>
    )
}

export default Subgerencia