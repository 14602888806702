import React, { useState, useEffect } from 'react';
import { Modal, Row, Popover, OverlayTrigger, Form, Col, Alert, Container, Badge, Tabs, Tab, Button } from "react-bootstrap";
//import DEFAULT_URL from '../../../../api/url';
import { Confirmacion } from '../../../Utils/ConfirmationModal/Confirmacion';
import { SuccessModal } from '../../../Utils/SuccessModal/SuccessModal';
import { ErrorModal } from '../../../Utils/ErrorModal/ErrorModal';
import { ModalCargando } from '../../../Utils/Modal-Cargando/ModalCargando';
const popover = (
    <Popover id="popover-basic">
        <Popover.Header as="h3">¡Listo!</Popover.Header>
        <Popover.Body>
            Enlace copiado exitosamente.
        </Popover.Body>
    </Popover>
);
const ModalEnlaces = (props) => {
    const [enlaces, setEnlaces] = useState([]);
    const [mensajeConfirmacionPublicar, setMensajeConfirmacionPublicar] = useState(false);
    const [mensajeConfirmacionOcultar, setMensajeConfirmacionOcultar] = useState(false);
    const [mensajeConfirmacionCerrar, setMensajeConfirmacionCerrar] = useState(false);
    const [mensajeConfirmacionAbrir, setMensajeConfirmacionAbrir] = useState(false);
    const [mensajeConfirmacionEliminar, setMensajeConfirmacionEliminar] = useState(false);
    const [contador, setContador] = useState(0);
    const [mensaje, setMensaje] = useState('');
    const [consideracion, setConsideracion] = useState('');
    const [id_actual, setId_Actual] = useState('');
    const [modalSucc, setModalSucc] = useState(false);
    const [modalError, setModalError] = useState(false);
    const [modalCargando, setModalCargando] = useState(false);
    const [mensajeCargando, setMensajeCargando] = useState('');
    const [tipos_enlace, setTiposEnlace] = useState([]);
    const [url, setUrl] = useState('');
    const [tipo, setTipo] = useState('');
    useEffect(() => {
        async function fetchData() {
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/enlace/' + props.evento_uniqueid, { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setEnlaces(data.rows);
                });
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/tipos/enlace', { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setTiposEnlace(data.rows);
                });
        }
        fetchData();
    }, [contador]);
    const entering = () => {
        setContador(contador + 1);
    }
    const cerrarEnlace = (id, param) => {
        setMensajeConfirmacionCerrar(false);
        setMensajeCargando('Se está cerrando el enlace, por favor espere.');
        setModalCargando(true);
        const info = { UniqueID: id };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/update/enlace/cerrar/' + id, requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                setModalCargando(false);
                setContador(contador + 1);
                if (data.codigo == 200) {
                    setMensaje('Enlace cerrado exitosamente');
                    setConsideracion('');
                    setModalSucc(true);
                } else {
                    setConsideracion('');
                    setMensaje(data.respuesta);
                    setModalError(true);
                }
            });
    }
    const abrirEnlace = (id, param) => {
        setMensajeConfirmacionAbrir(false);
        setMensajeCargando('Se está abriendo el enlace, por favor espere.');
        setModalCargando(true);
        const info = { UniqueID: id };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/update/enlace/abrir/' + id, requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                setModalCargando(false);
                setContador(contador + 1);
                if (data.codigo == 200) {
                    setMensaje('Enlace abierto exitosamente');
                    setConsideracion('');
                    setModalSucc(true);
                } else {
                    setConsideracion('');
                    setMensaje(data.respuesta);
                    setModalError(true);
                }
            });
    }

    const publicarEnlace = (id, param) => {
        setMensajeConfirmacionPublicar(false);
        setMensajeCargando('Se está publicando el enlace, por favor espere.');
        setModalCargando(true);
        const info = { UniqueID: id };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/update/enlace/publicar/' + id, requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                setModalCargando(false);
                setContador(contador + 1);
                if (data.codigo == 200) {
                    setMensaje('Enlace publicado exitosamente');
                    setConsideracion('');
                    setModalSucc(true);
                } else {
                    setConsideracion('');
                    setMensaje(data.respuesta);
                    setModalError(true);
                }
            });
    }
    const ocultarEnlace = (id, param) => {
        setMensajeConfirmacionOcultar(false);
        setMensajeCargando('Se está ocultando el enlace, por favor espere.');
        setModalCargando(true);
        const info = { UniqueID: id };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/update/enlace/ocultar/' + id, requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                setModalCargando(false);
                setContador(contador + 1);
                if (data.codigo == 200) {
                    setMensaje('Se ha ocultado el enlace exitosamente');
                    setConsideracion('');
                    setModalSucc(true);
                } else {
                    setConsideracion('');
                    setMensaje(data.respuesta);
                    setModalError(true);
                }
            });
    }
    const eliminarEnlace = (id, param) => {
        setMensajeConfirmacionEliminar(false);
        setMensajeCargando('Se está eliminando el enlace, por favor espere.');
        setModalCargando(true);
        const info = { UniqueID: id };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/delete/enlace/' + id, requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                setModalCargando(false);
                setContador(contador + 1);
                if (data.codigo == 200) {
                    setMensaje('Enlace eliminado exitosamente');
                    setConsideracion('');
                    setModalSucc(true);
                } else {
                    setConsideracion('');
                    setMensaje(data.respuesta);
                    setModalError(true);
                }
            });
    }
    const onChange = (evt) => {
        if (evt.target.name == 'tipo_enlace') {
            setTipo(evt.target.value);
        } else {
            setUrl(evt.target.value);
        }
        setContador(contador + 1);
    }
    const submit = (evt) => {
        evt.preventDefault();
        //                var inserts = [req.body.url, req.body.evento_UniqueID, req.body.TipoEnlace_UniqueID];
        setMensajeCargando('Se está generando el enlace, por favor espere.');
        setModalCargando(true);
        const info = { url: url, evento_UniqueID: props.evento_uniqueid, TipoEnlace_UniqueID: tipo };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/create/enlace/', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                setModalCargando(false);
                setContador(contador + 1);
                if (data.codigo == 200) {
                    setMensaje('Enlace creado exitosamente');
                    setConsideracion('');
                    setModalSucc(true);
                } else {
                    setConsideracion('');
                    setMensaje(data.respuesta);
                    setModalError(true);
                }
            });
    }
    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
            onEnter={entering}
        >
            <Modal.Header closeButton style={{ backgroundColor: "#1e3d52", borderColor: 'transparent', color: 'white' }}>
                <Modal.Title id="contained-modal-title-vcenter" className="font-bold">
                    Enlaces Del Evento
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: "#1e3d52", borderColor: 'transparent', color: 'white' }} id="body-modal-edit-evento">
                <Tabs defaultActiveKey="formulario" id="uncontrolled-tab-example" className="mb-3" variant='pills'>
                    <Tab tabClassName='tab-material' eventKey="formulario" title="Nuevo Enlace">
                        <Form onSubmit={submit}>
                            <Container>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="url">
                                            <Form.Label>Url</Form.Label>
                                            <Form.Control required type="text" name="url" placeholder="Url de enlace" onChange={onChange} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="tipo_enlace">
                                            <Form.Label>Tipo de enlace</Form.Label>
                                            <Form.Control as="select" required aria-label="Tipo Enlace" name="tipo_enlace" onChange={onChange}>
                                                <option value="" key="0">Seleccione el tipo de enlace</option>
                                                {tipos_enlace.map(tipo => <option key={tipo.UniqueID} value={tipo.UniqueID}>{tipo.Nombre}</option>)}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Button type="submit" variant="light">Guardar</Button>
                                </Row>
                            </Container>
                        </Form>
                    </Tab>
                    <Tab tabClassName='tab-material' eventKey="enlaces" title="Ver Enlaces">
                        <Container>
                            {enlaces.map((enlace) =>
                                <Row style={{ backgroundColor: 'white', padding: '25px', marginTop: '30px', border: '2px dashed black', borderRadius: '10px' }}>
                                    <Container>
                                        <Row>
                                            <Col xs={10} md={10}>
                                            </Col>
                                            <Col xs={1} md={1} onClick={() => { setMensaje('¿Está segur@ de eliminar el enlace seleccionado?'); setConsideracion('Esta acción no se puede revertir'); setMensajeConfirmacionEliminar(true); setId_Actual(enlace.UniqueID); setContador(contador + 1); }}>
                                                <div style={{ cursor: 'pointer', width: '20%', color: 'black' }} data-toggle="tooltip" data-placement="top" title="Eliminar enlace">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-trash3-fill" viewBox="0 0 16 16">
                                                        <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                                                    </svg>
                                                </div>
                                            </Col>
                                            <Col xs={1} md={1}>
                                                <OverlayTrigger trigger="click" placement="left" overlay={popover} rootClose>
                                                    <div style={{ cursor: 'pointer', width: '20%', color: 'black' }} data-toggle="tooltip" data-placement="top" title="Copiar enlace al portapapeles" onClick = {()=>{navigator.clipboard.writeText(enlace.url);}}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-clipboard-check" viewBox="0 0 16 16">
                                                            <path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                                                            <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                                                            <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                                                        </svg>
                                                    </div>
                                                </OverlayTrigger>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={6} md={6} lg={6}>
                                                <h3 style={{ color: 'black' }}>{enlace.Nombre}</h3>
                                            </Col>
                                            <Col xs={6} md={6} lg={6}>
                                                <Container style={{ display: 'grid', placeItems: 'center', cursor: 'pointer' }}>
                                                    <Row>
                                                        <Col xs={12} md={12} lg={12}>
                                                            {enlace.disponible == 1 &&
                                                                <div data-toggle="tooltip" data-placement="top" title="Click para ocultar enlace" onClick={() => { setMensaje('¿Está segur@ de ocultar el enlace seleccionado?'); setConsideracion('Esta acción se puede revertir'); setMensajeConfirmacionOcultar(true); setId_Actual(enlace.UniqueID); setContador(contador + 1); }}>
                                                                    <Badge bg="success">Disponible</Badge>
                                                                </div>
                                                            }
                                                            {enlace.disponible == 0 &&
                                                                <div data-toggle="tooltip" data-placement="top" title="Click para publicar enlace" onClick={() => { setMensaje('¿Está segur@ de publicar el enlace seleccionado?'); setConsideracion('Esta acción se puede revertir'); setMensajeConfirmacionPublicar(true); setId_Actual(enlace.UniqueID); setContador(contador + 1); }}>
                                                                    <Badge bg="danger">No Disponible</Badge>
                                                                </div>
                                                            }
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={12} md={12} lg={12} >
                                                            {enlace.lleno == 0 &&
                                                                <div data-toggle="tooltip" data-placement="top" title="Click para cerrar enlace" onClick={() => { setMensaje('¿Está segur@ de cerrar el enlace seleccionado?'); setConsideracion('Esta acción se puede revertir'); setMensajeConfirmacionCerrar(true); setId_Actual(enlace.UniqueID); setContador(contador + 1); }} >
                                                                    <Badge bg="success">Abierto</Badge>
                                                                </div>
                                                            }
                                                            {enlace.lleno == 1 &&
                                                                <div data-toggle="tooltip" data-placement="top" title="Click para abrir enlace" onClick={() => { setMensaje('¿Está segur@ de abrir el enlace seleccionado?'); setConsideracion('Esta acción se puede revertir'); setMensajeConfirmacionAbrir(true); setId_Actual(enlace.UniqueID); setContador(contador + 1); }}>
                                                                    <Badge bg="danger">Lleno</Badge>
                                                                </div>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </Col>
                                        </Row>
                                        <Row style = {{marginTop:'35px'}}>
                                            <Alert variant="secondary">
                                                {enlace.url}
                                            </Alert>
                                        </Row>
                                    </Container>
                                </Row>
                            )}
                        </Container>
                    </Tab>
                </Tabs>
            </Modal.Body >
            <Confirmacion
                show={mensajeConfirmacionAbrir}
                onHide={() => setMensajeConfirmacionAbrir(false)}
                accion={abrirEnlace}
                mensaje={mensaje}
                uniqueid={id_actual}
                consideracion={consideracion}
                param={''}
            />
            <Confirmacion
                show={mensajeConfirmacionCerrar}
                onHide={() => setMensajeConfirmacionCerrar(false)}
                accion={cerrarEnlace}
                mensaje={mensaje}
                uniqueid={id_actual}
                consideracion={consideracion}
                param={''}
            />
            <Confirmacion
                show={mensajeConfirmacionPublicar}
                onHide={() => setMensajeConfirmacionPublicar(false)}
                accion={publicarEnlace}
                mensaje={mensaje}
                uniqueid={id_actual}
                consideracion={consideracion}
                param={''}
            />
            <Confirmacion
                show={mensajeConfirmacionOcultar}
                onHide={() => setMensajeConfirmacionOcultar(false)}
                accion={ocultarEnlace}
                mensaje={mensaje}
                uniqueid={id_actual}
                consideracion={consideracion}
                param={''}
            />
            <Confirmacion
                show={mensajeConfirmacionEliminar}
                onHide={() => setMensajeConfirmacionEliminar(false)}
                accion={eliminarEnlace}
                mensaje={mensaje}
                uniqueid={id_actual}
                consideracion={consideracion}
                param={''}
            />
            <SuccessModal
                color={"#b5bad0"}
                show={modalSucc}
                onHide={() => setModalSucc(false)}
                mensaje={mensaje}
                consideracion={consideracion}
                enlace={''}
                llevaenlace={false}
            />
            <ErrorModal
                color={"#b5bad0"}
                show={modalError}
                onHide={() => setModalError(false)}
                mensaje={mensaje}
                consideracion={consideracion}
            />
            <ModalCargando
                color={"#b5bad0"}
                show={modalCargando}
                onHide={() => setModalCargando(false)}
                mensaje={mensajeCargando}
            />
        </Modal >
    );
}

export default ModalEnlaces