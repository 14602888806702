import React, { useState, useEffect } from 'react'
import { Row, Button, Form, Container, Accordion, Alert, Spinner, Col, Card, Carousel } from "react-bootstrap";
import "./style.css"
//import DEFAULT_URL from '../../api/url';
import axios from 'axios';
import FormTema from './FormTema';
import DetalleTema from './DetalleTema';
const Temas = (props) => {
    const [contador, setContador] = useState(0);
    const [edit, setEdit] = useState('');
    const [temas, setTemas] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [criterio, setCriterio] = useState('');
    const [nombre, setNombre] = useState('');
    const [img, setImg] = useState(null);
    const [mensaje, setMensaje] = useState('');
    const [exito, setExito] = useState('none');
    const [formulario, setFormulario] = useState('block');
    const [error, setError] = useState('none');
    const [cargando, setCargando] = useState('none');
    const [descripcion, setDescripcion] = useState('');
    const [docentes, setDocentes] = useState([]);
    const [confirmacion, setConfirmacion] = useState('none');
    useEffect(() => {
        async function fetchData() {
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/temas/read/' + props.catalogo.UniqueID, { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setCriterio('');
                    setTemas(data.salida);
                    setFilteredData(data.salida);
                });
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/catalogo-tema/read/docentes/' + props.catalogo.UniqueID)
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setDocentes(data.salida);
                });
            setNombre(props.catalogo.Nombre);
            setDescripcion(props.catalogo.descripcion);
        }
        fetchData();
    }, [contador]);
    useEffect(() => {
    }, [edit]);

    const refresh = () => {
        setContador(contador + 1);
    }
    const onChange = (evt) => {
        if (evt.target.name == 'criterio') {
            setCriterio(evt.target.value);
            setEdit(edit + 1);
            let result = [];
            result = temas.filter((actual) => {
                return actual.nombre.toLowerCase().search(evt.target.value.toLowerCase()) != -1;
            });
            setFilteredData(result);
            setEdit(edit + 1);
        } else if (evt.target.name == 'nombre') {
            setNombre(evt.target.value);
            setEdit(edit + 1);
        } else if (evt.target.name == 'descripcion') {
            setDescripcion(evt.target.value);
            setEdit(edit + 1);
        } else {
            setImg(evt.target.files[0]);
            setEdit(edit + 1);
        }
    }
    const deleteSelf = () => {
        cargar('Sincronizando base de datos');
        const info = {
            UniqueID: props.catalogo.UniqueID
        }
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/catalogo-tema/delete', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                if (data.codigo == 200) {
                    operacionExito('Catálogo eliminado exitosamente');
                    props.refresh();
                    props.back();
                } else {
                    operacionError('Error al eliminar registro en db');
                }
            });
    }
    const onSubmit = (evt) => {
        evt.preventDefault();
        if (img != null) {
            const data = new FormData();
            data.append('file', img);
            cargar('Subiendo imagen');
            axios.post(process.env.REACT_APP_DEFAULT_URL+ "/api/files/upload/s3", data, {}).then(res => {
                if (res.data.codigo == 200) {
                    cargar('Sincronizando base de datos');
                    const info = {
                        nombre: nombre,
                        foto: res.data.resultado.Location,
                        descripcion: descripcion,
                        UniqueID: props.catalogo.UniqueID
                    };
                    const requestOptions = {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(info)
                    };
                    fetch(process.env.REACT_APP_DEFAULT_URL + '/api/catalogo-tema/update', requestOptions)
                        .then((res) => {
                            return res.json();
                        })
                        .then(data => {
                            if (data.codigo == 200) {
                                operacionExito('Catálogo actualizado exitosamente');
                            } else {
                                operacionError('Error al grabar registro en db');
                            }
                        });
                }
            });
        } else {
            cargar('Sincronizando base de datos');
            const info = {
                nombre: nombre,
                foto: props.catalogo.foto,
                descripcion: descripcion,
                UniqueID: props.catalogo.UniqueID
            };
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(info)
            };
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/catalogo-tema/update', requestOptions)
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    if (data.codigo == 200) {
                        operacionExito('Catálogo actualizado exitosamente');
                    } else {
                        operacionError('Error al grabar registro en db');
                    }
                });
        }

    }
    const cargar = (mensaje) => {
        setExito('none');
        setFormulario('none');
        setError('none');
        setCargando('block');
        setConfirmacion('none');
        setMensaje(mensaje);
        setContador(contador + 1);
    }
    const operacionExito = (mensaje) => {
        setExito('block');
        setFormulario('none');
        setError('none');
        setConfirmacion('none');
        setCargando('none');
        setMensaje(mensaje);
        props.refresh();
        setContador(contador + 1);
    }
    const operacionError = (mensaje) => {
        setExito('none');
        setFormulario('none');
        setError('block');
        setCargando('none');
        setConfirmacion('none');
        setMensaje(mensaje);
        setContador(contador + 1);
    }
    const goBack = () => {
        setExito('none');
        setFormulario('block');
        setError('none');
        setCargando('none');
        setNombre('');
        setDescripcion('');
        setConfirmacion('none');
        setImg(null);
        Array.from(document.querySelectorAll("input")).forEach(
            input => (input.value = "")
        );
        setContador(contador + 1);
    }
    const confirmar = () => {
        setExito('none');
        setFormulario('none');
        setError('none');
        setCargando('none');
        setConfirmacion('block');
        setContador(contador + 1);
    }
    return (
        <Container fluid>
            <Row>
                <Button variant='outline-dark' onClick={refresh}>Refrescar</Button>
            </Row>
            <Row style={{ marginTop: '25px' }}>
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header onClick={() => { setContador(contador + 1); }}>Operaciones sobre biblioteca</Accordion.Header>
                        <Accordion.Body>
                            <Container fluid>
                                <Row>
                                    <FormTema refresh={refresh} Catalogo={props.catalogo.UniqueID} />
                                </Row>
                                <Row style={{ marginTop: '25px' }}>
                                    <h1>Editar información de biblioteca</h1>
                                </Row>
                                <Row style={{ marginTop: '25px', display: formulario }}>
                                    <Container fluid >
                                        <Form onSubmit={onSubmit}>
                                            <Row>
                                                <Form.Group className="mb-3" controlId="institucion">
                                                    <Form.Label>Nombre de la biblioteca</Form.Label>
                                                    <Form.Control required type="text" placeholder="ingrese el nombre de la biblioteca" name="nombre" value={nombre} onChange={onChange} />
                                                </Form.Group>
                                            </Row>
                                            <Row>
                                                <Form.Group className="mb-3" controlId="direccion">
                                                    <Form.Label>Descripción de la biblioteca</Form.Label>
                                                    <Form.Control as="textarea" rows={3} style={{ resize: 'none' }} placeholder="Ingrese la descripción de la biblioteca" name="descripcion" value={descripcion} onChange={onChange} />
                                                </Form.Group>
                                            </Row>
                                            <Row>
                                                <Form.Group style={{ color: 'black' }}>
                                                    <Form.Label>Foto de biblioteca</Form.Label>
                                                    <Form.Control type="file" onChange={onChange} name="img" accept="image/*" />
                                                </Form.Group>
                                            </Row>
                                            <Row>
                                                <Button type='submit' variant='outline-dark' style={{ marginTop: '25px' }}>Guardar información</Button>
                                            </Row>
                                        </Form>
                                    </Container>
                                </Row>
                                <Row style={{ marginTop: '5px', display: formulario }}>
                                    <Button onClick={confirmar} variant='outline-danger' style={{ marginTop: '25px' }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3-fill" viewBox="0 0 16 16">
                                        <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                                    </svg>&nbsp;&nbsp;&nbsp;&nbsp;Eliminar biblioteca</Button>
                                </Row>
                                <Row style={{ display: exito }}>
                                    <Alert variant='success'>
                                        <Alert.Heading>
                                            Operación realizada con éxito
                                        </Alert.Heading>
                                        <p>
                                            {mensaje}
                                        </p>
                                        <hr />
                                        <div className="d-flex justify-content-end">
                                            <Button variant='outline-success' onClick={goBack}>
                                                Ok
                                            </Button>
                                        </div>
                                    </Alert>
                                </Row>
                                <Row style={{ display: error }}>
                                    <Alert variant='danger'>
                                        <Alert.Heading>
                                            Ocurrió un error al ingresar el tema
                                        </Alert.Heading>
                                        <p>
                                            {mensaje}
                                        </p>
                                        <hr />
                                        <div className="d-flex justify-content-end">
                                            <Button variant='outline-danger' onClick={goBack}>
                                                Ok
                                            </Button>
                                        </div>
                                    </Alert>
                                </Row>
                                <Row style={{ display: cargando }}>
                                    <Alert variant='dark'>
                                        <Alert.Heading>
                                            <Spinner animation="border" role="status">
                                            </Spinner>&nbsp;&nbsp;&nbsp;
                                            Ingresando información
                                        </Alert.Heading>
                                        <p>
                                            {mensaje}
                                        </p>
                                        <hr />
                                        <p>
                                            Por favor espere...
                                        </p>
                                    </Alert>
                                </Row>
                                <Row style={{ display: confirmacion }}>
                                    <Alert variant='warning'>
                                        <Alert.Heading>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
                                                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                            </svg>&nbsp;&nbsp;&nbsp;Se necesita confirmación
                                        </Alert.Heading>
                                        <p>
                                            ¿Está segur@ de eliminar la biblioteca seleccionada?
                                            <br />
                                            <strong>Nombre del tema: </strong> {props.catalogo.Nombre}
                                        </p>
                                        <hr />
                                        <div className="d-flex justify-content-end">
                                            <Button variant='outline-danger' onClick={deleteSelf}>
                                                si
                                            </Button>&nbsp;&nbsp;&nbsp;
                                            <Button variant='outline-dark' onClick={goBack}>
                                                no
                                            </Button>
                                        </div>
                                    </Alert>
                                </Row>
                            </Container>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Row>
            <Row style={{ marginTop: '25px' }}>
                <Container>
                    <Row style={{ padding: '5%', display: 'flex', placeItems: 'center' }}>
                        <Col xs={12} md={12} lg={6}>
                            <img src={props.catalogo.foto} alt='img-catalogo' style={{ width: '100%' }} />
                        </Col>
                        <Col xs={12} md={12} lg={6}>
                            <h3>{props.catalogo.Nombre}</h3>
                        </Col>
                    </Row>
                    <Row style={{ padding: '5%', color: 'black' }}>
                        <p style={{ textAlign: 'justify', width: '100%', fontStyle: 'oblique', fontSize: '1.7em' }}>{props.catalogo.descripcion}</p>
                    </Row>
                    <Row style={{ padding: '2%', display: 'flex', placeItems: 'center', backgroundColor: '#1e3d52', color: 'white' }}>
                        <Col xs={12} md={12} lg={6}>
                            <h2><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-book-half" viewBox="0 0 16 16">
                                <path d="M8.5 2.687c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z" />
                            </svg>&nbsp;&nbsp;&nbsp;&nbsp;{temas.length} Temas</h2>
                        </Col>
                        <Col xs={12} md={12} lg={6}>
                            <h2><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-person-check-fill" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M15.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                                <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                            </svg>&nbsp;&nbsp;&nbsp;&nbsp;{docentes.length} Docentes</h2>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '25px', marginBottom: '25px' }}>
                        <Form.Group className="mb-3" controlId="institucion">
                            <Form.Label>Buscar</Form.Label>
                            <Form.Control required type="text" placeholder="puede buscar el o los temas por nombre" name="criterio" value={criterio} onChange={onChange} />
                        </Form.Group>
                    </Row>
                    <Row style={{ marginTop: '25px' }}>
                        <h1>Contenidos de biblioteca</h1>
                    </Row>
                    <Row style={{ marginTop: '25px', marginBottom: '55px' }}>
                        {filteredData.map((actual, index) =>
                            <Col xs={12} md={12} lg={6} style={{ marginTop: '15px', marginBottom: '15px', padding: '1%' }} key={index}>
                                <DetalleTema biblioteca={props.catalogo.Nombre} tema={actual} refresh={refresh} permiso = {1} />
                            </Col>
                        )}
                    </Row>
                    <Row style={{ marginTop: '25px' }}>
                        <h1>Facilitadores</h1>
                    </Row>
                    <Row style={{ marginTop: '25px', marginBottom: '55px' }}>
                        {docentes.map((actual, index) => <Col xs={12} md={12} lg={6} style={{ marginTop: '15px', marginBottom: '15px', padding: '1%' }} key={index}>
                            <Card style={{ height: '100%', border: '1px solid transparent' }}>
                                <Card.Body style={{ display: 'grid', placeItems: 'center' }}>
                                    <Card.Img src={actual.foto} style={{ width: '50%', marginBottom:'15px', borderRadius:'100%', objectFit:'contain' }} />
                                    <Card.Title style={{ marginTop: '25px', color: 'white', backgroundColor: '#1e3d52', padding: '7%', borderRadius: '25px' }}>{actual.Nombres + ' ' + actual.Apellidos}<br/>{actual.tarifa}</Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>)}
                    </Row>
                </Container>
            </Row>
        </Container>
    )
}

export default Temas