import React, { useState, useEffect } from 'react';
import { Row, Button, Col, Form, Container } from "react-bootstrap";
//import DEFAULT_URL from '../../../../../api/url';
import './ModalMaterial.css'
const FormEditCarpeta = (props) => {
    const [nombre, setNombre] = useState('');
    const [contador, setContador] = useState(0);
    const [edit, setEdit] = useState(0);
    useEffect(() => {
        setNombre(props.nombre);
    }, [contador]);
    useEffect(() => {

    }, [edit]);
    const onChange = (evt) => {
        setNombre(evt.target.value);
        setEdit(edit + 1);
    }
    const back = () => {
        setContador(contador + 1);
        props.back();
    }
    const submitCarpeta = (evt) => {
        evt.preventDefault();
        props.cargar('Sincronizando base de datos', 'Por favor espere...')
        const info = { nombre: nombre, UniqueID: props.UniqueId };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/update/carpeta', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                props.back();
                props.refrescar();
                setContador(0);
                if (data.codigo == 200) {
                    props.ok('Información almacenada exitosamente', 'Por favor valide e informe a todos.');
                } else {
                    props.err('Ocurrió un error al guardar la información', 'Por recarge la página e intentelo de nuevo.');
                }
                Array.from(document.querySelectorAll("input")).forEach(
                    input => (input.value = "")
                );
            });
    }
    return (
        <Form onSubmit={submitCarpeta}>
            <Container>
                <Row style={{ marginBottom: '45px' }}>
                    <Col xs={12} md={12} lg={12}>
                        <Button variant="dark" onClick={back}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-backspace-fill" viewBox="0 0 16 16">
                                <path d="M15.683 3a2 2 0 0 0-2-2h-7.08a2 2 0 0 0-1.519.698L.241 7.35a1 1 0 0 0 0 1.302l4.843 5.65A2 2 0 0 0 6.603 15h7.08a2 2 0 0 0 2-2V3zM5.829 5.854a.5.5 0 1 1 .707-.708l2.147 2.147 2.146-2.147a.5.5 0 1 1 .707.708L9.39 8l2.146 2.146a.5.5 0 0 1-.707.708L8.683 8.707l-2.147 2.147a.5.5 0 0 1-.707-.708L7.976 8 5.829 5.854z" />
                            </svg>&nbsp;&nbsp;&nbsp;Atrás
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <h1 className='font-bold'>Editar Carpeta</h1>
                </Row>
                <Row>
                    <Col sm={12} md={12} lg={12}>
                        <Form.Group className="mb-3" controlId="material">
                            <Form.Label>Nombre de la carpeta</Form.Label>
                            <Form.Control required type="text" name="nombre" placeholder="Ingrese el nombre de la carpeta" onChange={onChange} value={nombre} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button variant="dark" className="font-bold" type="submit">Guardar</Button>
                    </Col>
                </Row>
            </Container>
        </Form>)
}

export default FormEditCarpeta