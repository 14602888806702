import React, { useEffect, useState } from 'react';
import { Modal, Row, Button, Container, Form, Alert, Spinner } from "react-bootstrap";
import { Participantes } from './Participantes/Participantes';
import './ModalEditarParticipantes.css'
//import DEFAULT_URL from '../../../../../api/url';
export const ModalEditarParticipantes = (props) => {
    const [participantes, setParticipantes] = useState([]);
    const [contador, setContador] = useState(0);
    const [formulario, setFormulario] = useState('block');
    const [cargando, setCargando] = useState('none');
    const [mensaje, setMensaje] = useState('');
    const [consideracion, setConsideracion] = useState('');
    const [exito, setExito] = useState('none');
    const [error, setError] = useState('none');
    const [confirmacion, setConfirmacion] = useState('none');
    const [participanteSeleccionado, setParticipanteSeleccionado] = useState('');
    const [edit, setEdit] = useState(0);
    useEffect(() => {
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/detalle_inscripcion/evento/' + props.eventoUniqueID, { cache: "no-cache" })
            .then((res) => {
                return res.json();
            })
            .then(data => {
                setParticipantes(data.rows);
            });
    }, [contador]);
    useEffect(() => {
    }, [edit])

    const submitChanges = (evt) => {
        evt.preventDefault();
        setFormulario('none');
        setCargando('block');
        setMensaje('Sincronizando base de datos');
        setConsideracion('Por favor Espere...');
        const info = participantes;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/update/detalle_inscripcion/batch', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                if (data.codigo == 200) {
                    setMensaje('Información de participantes modificada exitosamente.');
                    setConsideracion('Por favor, valide');
                    setCargando('none');
                    setExito('block');
                } else {
                    setMensaje(data.respuesta);
                    setCargando('none');
                    setError('block');
                    setConsideracion('Ocurrió un error al guardar los cambios del evento');
                }
                setContador(contador + 1);
            });
    }
    const onEnter = () => {
        setContador(contador + 1);
    }
    const onChange = (evt) => {
        const arr_name = evt.target.name.split("-");
        var new_array = participantes;
        const index_finder = (participante) => participante.UniqueID == arr_name[1];
        switch (arr_name[0]) {
            case "nombres":
                new_array[new_array.findIndex(index_finder)].Nombres = evt.target.value;
                setParticipantes(new_array);
                break;
            case "apellidos":
                new_array[new_array.findIndex(index_finder)].Apellidos = evt.target.value;
                setParticipantes(new_array);
                break;
            case "puesto":
                new_array[new_array.findIndex(index_finder)].Puesto = evt.target.value;
                setParticipantes(new_array);
                break;
            case "correo":
                new_array[new_array.findIndex(index_finder)].Correo = evt.target.value;
                setParticipantes(new_array);
                break;
            case "telefono":
                new_array[new_array.findIndex(index_finder)].Telefono = evt.target.value;
                setParticipantes(new_array);
                break;
            case "celular":
                new_array[new_array.findIndex(index_finder)].Celular = evt.target.value;
                setParticipantes(new_array);
                break;
            case "identificacion":
                new_array[new_array.findIndex(index_finder)].Identificacion = evt.target.value;
                setParticipantes(new_array);
                break;
            default:
                break;
        }
        setParticipantes(new_array);
        setEdit(edit + 1);
    }
    const eliminarParticipante = (UniqueID) => {
        setConfirmacion('none');
        setFormulario('none');
        setCargando('block');
        setMensaje('Sincronizando base de datos');
        setConsideracion('Por favor Espere...');
        const info = { "UniqueID": UniqueID };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/delete/detalle_inscripcion', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                if (data.codigo == 200) {
                    setMensaje('Participante eliminado del sistema exitosamente.');
                    setConsideracion('Por favor, valide');
                    setCargando('none');
                    setExito('block');
                } else {
                    setMensaje(data.respuesta);
                    setCargando('none');
                    setError('block');
                    setConsideracion('Ocurrió un error al guardar los cambios del evento');
                }
                setContador(contador + 1);
            });
    }
    const confirmarEliminar = (UniqueID) => {
        setMensaje('¿Está segur@ de eliminar al participante seleccionado?');
        setConsideracion('Esta acción no se puede revertir');
        setFormulario('none');
        setConfirmacion('block');
        setParticipanteSeleccionado(UniqueID);
        setContador(contador + 1);
    }
    const sendGafete = (id) => {
        setConfirmacion('none');
        setFormulario('none');
        setCargando('block');
        setMensaje('Sincronizando base de datos');
        setConsideracion('Por favor Espere...');
        const info = { "UniqueID": id };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/send/detalle_inscripcion/gafete', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                if (data.codigo == 200) {
                    setMensaje('Gafete enviado exitosamente');
                    setConsideracion('Por favor, valide');
                    setCargando('none');
                    setExito('block');
                } else {
                    setMensaje(data.respuesta);
                    setCargando('none');
                    setError('block');
                    setConsideracion('Ocurrió un error al el gafete al participante seleccionado');
                }
                setContador(contador + 1);
            });
    }
    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
            variant="light"
            onEnter={onEnter}
        >
            <Form onSubmit={submitChanges} >
                <Modal.Header>
                    <Modal.Title className="font-bold">
                        Información de participantes
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ height: '60vh', overflowX: 'hidden' }} >
                    <Container>
                        {/* Stack the columns on mobile by making one full-width and the other half-width */}
                        <Row style={{ display: formulario }}>
                            <Participantes sendGafete={sendGafete} participantes={participantes} deleteparticipante={confirmarEliminar} eventocambio={onChange} />
                        </Row>
                        <Row style={{ display: cargando }}>
                            <Button variant="dark" disabled>
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                &nbsp;&nbsp;&nbsp;{mensaje}
                                <p>{consideracion}</p>
                            </Button>
                        </Row>
                        <Row style={{ display: exito }}>
                            <Alert variant="success">
                                <Alert.Heading>Operación realizada con éxito</Alert.Heading>
                                <p>
                                    {mensaje}
                                </p>
                                <hr />
                                <p className="mb-0">
                                    {consideracion}
                                </p>
                            </Alert>
                        </Row>
                        <Row style={{ display: error }}>
                            <Alert variant="danger">
                                <Alert.Heading>Ocurrió un error</Alert.Heading>
                                <p>
                                    {mensaje}
                                </p>
                                <hr />
                                <p className="mb-0">
                                    {consideracion}
                                </p>
                            </Alert>
                        </Row>
                        <Row style={{ display: confirmacion }}>
                            <Alert variant="warning">
                                <Alert.Heading>Se necesita confirmación</Alert.Heading>
                                <p>
                                    {mensaje}
                                </p>
                                <hr />
                                <p className="mb-0">
                                    {consideracion}
                                </p>
                            </Alert>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer style={{ display: formulario }}>
                    <Button variant="secondary" className="font-bold" type="submit">Guardar Cambios</Button>
                    <Button variant="light" className="font-bold" onClick={props.onHide}>Cerrar</Button>
                </Modal.Footer>
                <Modal.Footer style={{ display: error }}>
                    <Button variant="secondary" className='font-bold' onClick={props.onHide} >Cerrar</Button>
                </Modal.Footer>
                <Modal.Footer style={{ display: exito }}>
                    <Button variant="secondary" className='font-bold' onClick={() => { setFormulario('block'); setExito('none'); setContador(contador + 1); }} >Ok</Button>
                </Modal.Footer>
                <Modal.Footer style={{ display: confirmacion }}>
                    <Button variant="light" className='font-bold' onClick={() => { eliminarParticipante(participanteSeleccionado); }} >Sí</Button>
                    <Button variant="secondary" className='font-bold' onClick={() => {
                        setFormulario('block'); setConfirmacion('none'); setParticipanteSeleccionado('');
                        setContador(contador + 1);
                    }} >Cancelar</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}

export default ModalEditarParticipantes;