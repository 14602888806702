import React, { useEffect, useState } from 'react'
import { Button, Modal, Container } from "react-bootstrap";
import DetalleFormularios from './DetalleFormularios';
const Formularios = (props) => {
    const [contador, setContador] = useState(0);
    const [lock, setLock] = useState('block');
    useEffect(() => {

    }, [contador]);
    const bloquear = () => {
        setLock('none');
        setContador(contador + 1);
    }
    const desbloquear = () => {
        setLock('block');
        setContador(contador + 1);
    }
    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
            variant="light"
            backdrop='static'

        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter" className="font-bold">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-ui-checks" viewBox="0 0 16 16">
                        <path d="M7 2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1zM2 1a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H2zm0 8a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2v-2a2 2 0 0 0-2-2H2zm.854-3.646a.5.5 0 0 1-.708 0l-1-1a.5.5 0 1 1 .708-.708l.646.647 1.646-1.647a.5.5 0 1 1 .708.708l-2 2zm0 8a.5.5 0 0 1-.708 0l-1-1a.5.5 0 0 1 .708-.708l.646.647 1.646-1.647a.5.5 0 0 1 .708.708l-2 2zM7 10.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1zm0-5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 8a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                    </svg>
                    &nbsp;&nbsp;&nbsp;Formularios
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ minHeight: '60vh' }}>
                <Container>
                    <DetalleFormularios evento={props.evento} lock={bloquear} unlock={desbloquear} />
                </Container>
            </Modal.Body >
            <Modal.Footer style={{ display: lock }}>
                <Button variant="dark" onClick={props.onHide}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal >

    )
}

export default Formularios