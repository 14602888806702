import React, { useEffect, useState } from 'react'
//import DEFAULT_URL from '../../../api/url';
import { Container, Row, Form, Button, Col } from 'react-bootstrap';
const EditIntervencion = (props) => {
    const [contador] = useState(0);
    const [cambios, setCambios] = useState(0);
    const [intervenciones, setIntervencinoes] = useState([]);
    const [descripcion, setDescripcion] = useState('');
    const [tipo, setTipo] = useState('');
    const [validated, setValidated] = useState(false);
    const [cargando, setCargando] = useState(false);
    const [exito, setExito] = useState(false);
    const [error, setError] = useState(false);
    const [editar, setEditar] = useState(false);
    useEffect(() => {
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/leads/intervenciones', { cache: "no-cache", credentials: 'include' })
            .then((res) => {
                return res.json();
            })
            .then(data => {
                setIntervencinoes(data.rows);
            });
        setDescripcion(props.intervencion.descripcion);
        setTipo(props.intervencion.tipo_intervencion_UniqueID);
    }, [contador]);
    useEffect(() => {

    }, [cambios]);
    
    const onChange = (evt) => {
        switch (evt.target.name) {
            case "Descripcion":
                setDescripcion(evt.target.value);
                break;
            case "Tipo":
                setTipo(evt.target.value);
                break;
        }
    }
    const submit = (evt) => {
        const form = evt.currentTarget;
        if (form.checkValidity() === false) {
            evt.preventDefault();
            evt.stopPropagation();
        } else {
            evt.preventDefault();
           
        }
        setValidated(true);
        setCambios(cambios + 1);
    }
    return (
        <Container fluid>
            <Row style={{ padding: '5%' }}>
                <h2>{props.intervencion.fecha}</h2>
            </Row>
            <Row style={{ paddingTop: '2%', paddingBottom: '2%', paddingLeft: '5%', paddingRight: '5%', textAlign: 'left' }}>
                <Form onSubmit={submit} noValidate validated={validated}>
                    <Row>
                        <Form.Group className="mb-4" controlId="Descripcion">
                            <Form.Label>Descripción de intervención</Form.Label>
                            <Form.Control disabled={cargando || error || !editar} required as="textarea" placeholder="Ingrese la descripción de la intervención" rows={3} style={{ resize: 'none' }} name="Descripcion" onChange={onChange} value={descripcion} />
                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                            <Form.Control.Feedback type='invalid'>Por favor, completa este campo</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group className="mb-4" controlId="Tipo">
                            <Form.Label>Tipo de intervención</Form.Label>
                            <Form.Control value={tipo} as="select" disabled={cargando || error || !editar} required aria-label="Tipo" name="Tipo" onChange={onChange}>
                                <option value="" key="-1">Seleccione el tipo de intervención</option>
                                {intervenciones.map((intervencion) =>
                                    <option value={intervencion.UniqueID} key={intervencion.UniqueID}>{intervencion.descripcion}</option>
                                )}
                            </Form.Control>
                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                            <Form.Control.Feedback type='invalid'>Por favor, completa este campo</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                </Form>
            </Row>
        </Container>

    )
}

export default EditIntervencion