import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
const AskModal = (props) => {
  return (
    <Modal show={props.show} centered backdrop='static'>
      <Modal.Header>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body><p>{props.text}</p></Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={props.action}>
          Sí
        </Button>
        <Button variant="outline-secondary" onClick={props.handleClose}>
          No
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AskModal;
