import React, { useEffect, useState } from 'react'
//import DEFAULT_URL from '../../../api/url'
import { Container, Row, Col, Button } from 'react-bootstrap'
import EditIntervencion from './EditIntervencion'
const Intervenciones = (props) => {
    const [contador, setContador] = useState(0);
    const [intervenciones, setIntervenciones] = useState([]);
    useEffect(() => {
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/leads/intervenciones/' + props.lead, { cache: "no-cache", credentials: 'include' })
            .then((res) => {
                return res.json();
            })
            .then(data => {
                setIntervenciones([]);
                setIntervenciones(data.rows);
            });
    }, [contador]);

    return (
        <Container fluid>
            <Row style={{ padding: '5%' }}>
                <Button variant='outline-dark' onClick={() => { setContador(contador + 1); }}>Refrescar información</Button>
            </Row>
            <Row>
                {intervenciones.map((actual, index) => <Col key={index} xs={12} md={6} lg={4} style={{ padding: '2%' }}>
                    <EditIntervencion intervencion={actual} />
                </Col>)}
            </Row>
        </Container>
    )
}

export default Intervenciones