import React, { useState, useEffect } from 'react';
import { Modal, Row, Tabs, Tab, Button, Col, Form, Container } from "react-bootstrap";
//import DEFAULT_URL from '../../api/url';
import { ErrorModal } from '../Utils/ErrorModal/ErrorModal';
import { SuccessModal } from '../Utils/SuccessModal/SuccessModal';
import { ModalCargando } from '../Utils/Modal-Cargando/ModalCargando';
const ModalRecovery = (props) => {
    const [contador, setContador] = useState(0);
    const [usr, setUsr] = useState('');
    const [modalCargando, setModalCargando] = useState(false);
    const [mensaje, setMensaje] = useState('');
    const [modalSucc, setModalSucc] = useState(false);
    const [modalError, setModalError] = useState(false);
    const [consideracion, setConsideracion] = useState('');
    const [mensajeCargando, setMensajeCargando] = useState('');

    useEffect(() => {

    }, [contador]);
    const onChange = (evt) => {
        if (evt.target.name == "usuario") {
            setUsr(evt.target.value);
        }
        setContador(contador + 1);
    }
    const ocultar = () => {
        setModalError(false);
        setModalSucc(false);
        props.onHide();
    }
    const ocultarModalCargando = () => {
        setModalCargando(false);
    }
    const submit = (evt) => {
        const info = { usr: usr };
        evt.preventDefault();
        setMensajeCargando('Generando token de recuperación, por favor espere...');
        setModalCargando(true);
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/auth/recovery', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                setModalCargando(false);
                if (data.codigo == 200) {
                    if (data.respuesta == 0) {
                        setMensaje('No existe un usuario registrado como \'' + usr + '\'');
                        setConsideracion('Por favor, intenta de nuevo');
                        setModalError(true);
                    } else {
                        setMensaje('Se te ha enviado un correo con instrucciones para recuperar tu contraseña');
                        setConsideracion('Por favor, revisa tu correo');
                        setModalSucc(true);
                    }
                } else {
                    setMensaje(data.respuesta);
                    setModalError(true);
                }
            });
    }
    const entering = () => {
        setUsr('');
        setContador(0);
    }
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
            style={{ color: 'black' }}
            onEnter={entering}
        >
            <Modal.Header closeButton style={{ backgroundColor: "#E0E0E2", borderColor: 'transparent' }}>
                <Modal.Title id="contained-modal-title-vcenter" className="font-bold">
                    Editar Información De Evento
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: "#E0E0E2", height: '60vh', borderColor: 'transparent' }} id="body-modal-edit-evento">
                <Tabs defaultActiveKey="generacion" id="uncontrolled-tab-example" className="mb-3" variant='pills'>
                    <Tab eventKey="generacion" title="Recuperar Contraseña" tabClassName='tab-recovery'>
                        <Form onSubmit={submit}>
                            <Container>
                                <Row>
                                    <h5 style={{ textAlign: 'center' }}>Por favor ingrese su nombre de usuario</h5>
                                </Row>
                                <Row>
                                    <Form.Group className="mb-3" controlId="usuario">
                                        <Form.Label>Nombre de usuario</Form.Label>
                                        <Form.Control required type="text" name="usuario" placeholder="Usuario" value={usr} onChange={onChange} />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Col sm={4} md={4} lg={4}><Button variant="secondary" className="font-bold" type="submit" >Enviar</Button></Col>
                                </Row>
                            </Container>
                        </Form>
                    </Tab>
                </Tabs>
            </Modal.Body>
            <Modal.Footer style={{ backgroundColor: "#E0E0E2", borderColor: 'transparent' }}>
                <Button variant="secondary" className="font-bold" onClick={props.onHide}>Cerrar</Button>
            </Modal.Footer>
            <SuccessModal
                color={"#b5bad0"}
                show={modalSucc}
                onHide={ocultar}
                mensaje={mensaje}
                consideracion={consideracion}
                enlace={''}
                llevaenlace={false}
            />
            <ModalCargando
                color={"#b5bad0"}
                show={modalCargando}
                onHide={ocultarModalCargando}
                mensaje={mensajeCargando}
            />
            <ErrorModal
                color={"#b5bad0"}
                show={modalError}
                onHide={ocultar}
                mensaje={mensaje}
                consideracion={consideracion}
            />
        </Modal>
    )
}

export default ModalRecovery