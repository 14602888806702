import React, { useState, useEffect } from 'react'
import { Row, ListGroup, Container } from "react-bootstrap";
import ModalSeguimiento from './ModalSeguimiento';
import { Confirmacion } from '../../../Utils/ConfirmationModal/Confirmacion';
import { SuccessModal } from '../../../Utils/SuccessModal/SuccessModal';
import { ErrorModal } from '../../../Utils/ErrorModal/ErrorModal';
//import { DEFAULT_URL } from '../../../../api/url';
const Prospectos = (props) => {
    const [modalSeguimiento, setModalSeguimiento] = useState(false);
    const [seguimientoActual, setSeguimientoActual] = useState({});
    const [confirmacionEliminar, setConfirmacionEliminar] = useState(false);
    const [confirmacionRestaurar, setConfirmacionRestaurar] = useState(false);
    const [contador, setContador] = useState(0);
    const [mensaje, setMensaje] = useState('');
    const [modalError, setModalError] = useState(false);
    const [modalSucc, setModalSucc] = useState(false);
    useEffect(() => {

    }, [contador]);
    const ocultarRestaurarConfirmacion = () => {
        setConfirmacionRestaurar(false);
    }
    const ocultarEliminarConfirmacion = () => {
        setConfirmacionEliminar(false);
    }
    const ocultarSeguimiento = () => {
        setModalSeguimiento(false);
    }
    const ocultar = () => {
        setModalSucc(false);
        setModalError(false);
        props.actualizar();
        setContador(contador + 1);
    }
    const restaurarLead = (id, param) => {
        ocultarRestaurarConfirmacion();
        const info = { UniqueID: id };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/update/leads/restaurar', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                if (data.codigo == 200) {
                    setModalSucc(true);
                    setMensaje('Lead restaurado exitosamente');
                } else {
                    setModalError(true);
                    setMensaje('ERROR');
                }
            });
        ocultar();
    }
    const eliminar = (id, param) => {
        ocultarEliminarConfirmacion();
        const info = { UniqueID: id };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/delete/leads', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                if (data.codigo == 200) {
                    setModalSucc(true);
                    setMensaje('Lead eliminado exitosamente');
                } else {
                    setModalError(true);
                    setMensaje('ERROR');
                }
            });
        ocultar();
    }
    return (
        <Container>
            {props.leads.map((lead) =>
                <Row style={{ padding: '10px' }}>
                    <ListGroup as="ol">
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start"
                        >
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">{lead.nombre}</div>
                                {lead.fecha}
                            </div>
                            <div data-toggle="tooltip" data-placement="top" title="Ver seguimiento" style={{ cursor: 'pointer', color: 'black' }} onClick={() => { setSeguimientoActual(lead); setModalSeguimiento(true); }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16">
                                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                    <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                </svg>
                            </div>
                        </ListGroup.Item>
                    </ListGroup>
                </Row>
            )}
            <ModalSeguimiento show={modalSeguimiento} onHide={ocultarSeguimiento} lead={seguimientoActual} fullscreen={true} seguimiento={props.seguimiento} />
            <Confirmacion
                show={confirmacionRestaurar}
                onHide={ocultarRestaurarConfirmacion}
                accion={restaurarLead}
                mensaje={mensaje}
                uniqueid={''}
                consideracion={''}
                param=''
            />
            <Confirmacion
                show={confirmacionEliminar}
                onHide={ocultarEliminarConfirmacion}
                accion={eliminar}
                mensaje={mensaje}
                uniqueid={''}
                consideracion={''}
                param=''
            />
            <ErrorModal
                color={"#b5bad0"}
                show={modalError}
                onHide={ocultar}
                mensaje={mensaje}
                consideracion={''}
            />
            <SuccessModal
                color={"#b5bad0"}
                show={modalSucc}
                onHide={ocultar}
                mensaje={mensaje}
                consideracion={''}
                enlace={''}
                llevaenlace={false}
            />
        </Container>
    )
}

export default Prospectos