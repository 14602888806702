import React, { useState, useEffect } from "react";
import { Container, Row, Card, Button, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
const AllTarifas = () => {
  const { hash } = useParams();
  const [contador] = useState(0);
  const [tarifas, setTarifas] = useState([]);
  useEffect(() => {
    fetch(
      process.env.REACT_APP_DEFAULT_URL + "/api/read/evento/landing/" + hash,
      { cache: "no-cache" }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        fetch(
          process.env.REACT_APP_DEFAULT_URL +
            "/api/tarifas/read/" +
            data.rows[0].UniqueID,
          {
            cache: "no-cache",
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            console.log(data);
            setTarifas(data.rows);
          });
      });
  }, [contador]);
  return (
    <Container style={{ padding: "3%" }}>
      <Row style={{ padding: "2%" }}>
        <h1>Tarifas registradas</h1>
      </Row>
      <Row style={{ padding: "2%" }}>
        {tarifas.map((actual, index) => (
          <Col key={index} xs={12} md={6} lg={4} style={{ padding: "1%" }}>
            <Card>
              <Card.Header>
                <Card.Text>{actual.descripcion}</Card.Text>
              </Card.Header>
              <Card.Body>
                <Button
                  variant="outline-success"
                  href={
                    process.env.REACT_APP_SELF_URL +
                    "/tarifas/" +
                    hash +
                    "/" +
                    actual.UniqueID
                  }
                >
                  Ir a detalle
                </Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default AllTarifas;
