import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Container, Table, Dropdown } from "react-bootstrap";
//import DEFAULT_URL from '../../../../../api/url';
import TipoEvento from './TipoEvento';
import './Gerencia.css';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import {
    ComposedChart,
    Line,
    Area,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';
const Reporterias = () => {
    const [fechaInicio, setFechaInicio] = useState('');
    const [fechaFin, setFechaFin] = useState('');
    const [buscar, setBuscar] = useState(0);
    const [ventasTotales, setVentasTotales] = useState([]);
    const [ingresosTotales, setIngresosTotales] = useState([]);
    const [info, setInfo] = useState([]);
    const [totalesAsesorByTipo, setTotalesAsesorByTipo] = useState([]);
    const [report, setReport] = useState([]);
    const [totales, setTotales] = useState([]);
    const [asesorSales, setAsesorSales] = useState([]);
    useEffect(() => {
        async function fetchData() {
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/gerencia/one/reporte/no-exportable/' + fechaInicio + '/' + fechaFin)
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    let tmp = [];
                    data.reporte.map((actual, index) => {
                        if (tmp.length == 0) {
                            tmp.push({ tipo: actual['Tipo de evento'], eventos: [actual] });
                        } else {
                            const index_finder = (segmento) => segmento.tipo == actual['Tipo de evento'];
                            if (tmp.findIndex(index_finder) >= 0) {
                                let item = tmp[tmp.findIndex(index_finder)];
                                item.eventos.push(actual);
                                tmp[tmp.findIndex(index_finder)] = item;
                            } else {
                                tmp.push({ tipo: actual['Tipo de evento'], eventos: [actual] });
                            }
                        }
                        if (index === (data.reporte.length - 1)) {
                            return true;
                        }
                    });
                    setReport(tmp);
                    setTotales(data.totales);
                });
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/evento/ventas/' + fechaInicio + '/' + fechaFin)
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setVentasTotales(data.rows);
                    fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/evento/ingresos/reporte-intervalo/' + fechaInicio + '/' + fechaFin)
                        .then((res) => {
                            return res.json();
                        })
                        .then(datos => {
                            setIngresosTotales(datos.rows);
                            let temp = [];
                            data.rows.map((actual) => {
                                const index_finder = (asesor) => asesor.asesor === actual.asesor;
                                if (datos.rows.findIndex(index_finder) >= 0) {
                                    let ingresos = datos.rows[datos.rows.findIndex(index_finder)].ingresos;
                                    let fichas_confirmadas = datos.rows[datos.rows.findIndex(index_finder)].inscritos;
                                    temp.push({ ingresos: ingresos, fichas_confirmadas: fichas_confirmadas, asesor: actual.asesor, ventas: actual.inscritos });
                                }
                                return true;
                            });
                            setInfo(temp);
                        });
                });
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/gerencia/asesor/reporte/' + fechaInicio + '/' + fechaFin)
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    let tmp = [];
                    data.reporte.map((actual, index) => {
                        if (tmp.length == 0) {
                            tmp.push({ key: actual.UniqueID, tipo: actual.Nombre, asesores: [{ UniqueID: actual.id, asesor: actual.Asesor, total: actual.Total, inscripciones: actual.inscripciones, inversion_total: actual.inversion_total, participantes: actual.participantes, patrocinios_number: actual.patrocinios_number, patrocinios: actual.patrocinios, total_total: actual.total_total, total_dec: actual.total_dec }] });
                        } else {
                            const index_finder = (segmento) => segmento.tipo == actual.Nombre;
                            if (tmp.findIndex(index_finder) >= 0) {
                                let item = tmp[tmp.findIndex(index_finder)];
                                item.asesores.push({ UniqueID: actual.id, asesor: actual.Asesor, total: actual.Total, inscripciones: actual.inscripciones, inversion_total: actual.inversion_total, participantes: actual.participantes, patrocinios_number: actual.patrocinios_number, patrocinios: actual.patrocinios, total_total: actual.total_total, total_dec: actual.total_dec });
                                tmp[tmp.findIndex(index_finder)] = item;
                            } else {
                                tmp.push({ key: actual.UniqueID, tipo: actual.Nombre, asesores: [{ UniqueID: actual.id, asesor: actual.Asesor, total: actual.Total, inscripciones: actual.inscripciones, inversion_total: actual.inversion_total, participantes: actual.participantes, patrocinios_number: actual.patrocinios_number, patrocinios: actual.patrocinios, total_total: actual.total_total, total_dec: actual.total_dec }] });
                            }
                        }
                        if (index === (data.reporte.length - 1)) {
                            tmp.map((seg, j) => {
                                seg.asesores.sort((a, b) => {
                                    if ((a.inversion_total + a.patrocinios_number) > (b.inversion_total + b.patrocinios_number)) {
                                        return -1;
                                    }
                                    if ((a.inversion_total + a.patrocinios_number) < (b.inversion_total + b.patrocinios_number)) {
                                        return 1;
                                    }
                                    return 0;
                                });
                                if (j === (tmp.length - 1)) {
                                    setTotalesAsesorByTipo(tmp);
                                    return true;
                                }
                            })
                        }
                    });
                });
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/gerencia/asesor/ventas/reporte/' + fechaInicio + '/' + fechaFin)
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    //console.log(data);
                    setAsesorSales(data.reporte);
                });
        }
        if (fechaInicio !== '' && fechaFin !== '') {
            fetchData();
        }
    }, [buscar, fechaFin, fechaInicio]);

    const onChange = (evt) => {
        if (evt.target.name == "fechaInicio") {
            setFechaInicio(evt.target.value);
        } else {
            setFechaFin(evt.target.value);
        }
        setBuscar(buscar + 1);
    }
    const onSubmit = (evt) => {
        evt.preventDefault();
        setBuscar(buscar + 1);
    }
    const genPDF = () => {
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/gerencia/reporte/PDF/' + fechaInicio + '/' + fechaFin)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                if (data.codigo == 200) {
                    window.open(data.enlace, '_blank').focus();
                } else {
                    alert('error');
                }
            });
    }
    const exportExcel = () => {
        if (fechaInicio != '' && fechaFin != '') {
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/gerencia/one/reporte/' + fechaInicio + '/' + fechaFin)
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    if (data.codigo == 200) {
                        var excel_contenido = data.reporte;
                        var totals = {
                            "Tipo de evento": "TOTAL",
                            "Modalidad de evento": "",
                            "Evento": "",
                            "Fecha de inicio del evento": "",
                            "Ingresos": data.totales[0].totalIngresos,
                            "Egresos": data.totales[0].totalEgresos,
                            "Resultado": data.totales[0].totalResultado,
                            "Cortesías": data.totales[0].totalCortesias,
                            "Pagados": data.totales[0].totalPagados,
                            "Sin validación de contabilidad": data.totales[0].totalSinValidacion,
                            "Total Inscritos": data.totales[0].totalInscritos,
                            "Ejecutado EBG (Inscritos)": data.totales[0].totalInscritosEBG,
                            "Ejecutado EBC (Inscritos)": data.totales[0].totalInscritosEBC,
                            "Ejecutado EBG (Ingresos)": data.totales[0].totalIngresosEBG,
                            "Ejecutado EBC (Ingresos)": data.totales[0].totalIngresosEBC,
                            "Ingresos proyectados": data.totales[0].totalIngresosProyectados,
                            "Egresos proyectados": data.totales[0].totalEgresosProyectados,
                            "Resultado proyectado": data.totales[0].totalResultadoProyectado,
                            "Mínimo de participantes": data.totales[0].totalMinimoParticipantes
                        };
                        excel_contenido.push(totals);
                        var Sheets = [];
                        var SheetNames = [];
                        const sw = XLSX.utils.json_to_sheet(excel_contenido);
                        Sheets['Datos'] = sw;
                        SheetNames.push('Datos');
                        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                        const fileExtension = '.xlsx';
                        const wb = { Sheets, SheetNames };
                        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                        const datos = new Blob([excelBuffer], { type: fileType });
                        FileSaver.saveAs(datos, Date.now() + fileExtension);
                    } else {
                        console.log(data);
                    }
                });
        }
    }

    const exportExcelParticipantes = () => {
        if (fechaInicio != '' && fechaFin != '') {
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/gerencia/reporte/excel/' + fechaInicio + '/' + fechaFin)
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    if (data.codigo == 200) {
                        var excel_contenido = data.reporte;
                        var inscritos = data.inscritos;
                        var resumen_asesores = data.resumen_asesores;
                        var resumen_asesores_sin_confirmaciones = data.resumen_asesores_sin_confirmaciones;
                        var Sheets = [];
                        var SheetNames = [];
                        var evts = [];
                        inscritos.map((actual, index) => {
                            //        const index_finder = (evento) => evento.UniqueID == id;
                            //                new_arr[new_arr.findIndex(index_finder)].Nombre = evt.target.value;
                            const index_finder = (evento) => evento.idEvento == actual.idEvento;
                            if (evts.findIndex(index_finder) == -1) {
                                evts.push({ idEvento: actual.idEvento, Nombre: actual.EVENTO, Fecha: actual['FECHA DEL EVENTO'], Participantes: [actual] });
                            } else {
                                evts[evts.findIndex(index_finder)].Participantes.push(actual);
                            }
                            if (index == (inscritos.length - 1)) {
                                evts.map((curr, indice) => {
                                    const curr_sheet = XLSX.utils.json_to_sheet(curr.Participantes);
                                    Sheets[curr.idEvento + ' ' + curr.Fecha] = curr_sheet;
                                    SheetNames.push(curr.idEvento + ' ' + curr.Fecha);
                                    if (indice == (evts.length - 1)) {
                                        const sw = XLSX.utils.json_to_sheet(inscritos);
                                        const sw1 = XLSX.utils.json_to_sheet(resumen_asesores);
                                        const sw2 = XLSX.utils.json_to_sheet(resumen_asesores_sin_confirmaciones);
                                        Sheets['Inscritos'] = sw;
                                        Sheets['Resumen Asesores'] = sw1;
                                        Sheets['Ingresos no confirmados'] = sw2;
                                        SheetNames.push('Inscritos');
                                        SheetNames.push('Resumen Asesores');
                                        SheetNames.push('Ingresos no confirmados');
                                        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                                        const fileExtension = '.xlsx';
                                        const wb = { Sheets, SheetNames };
                                        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                                        const datos = new Blob([excelBuffer], { type: fileType });
                                        FileSaver.saveAs(datos, Date.now() + fileExtension);
                                    }
                                })
                            }
                        });
                    } else {
                        console.log(data);
                    }
                });
        }
    }
    return (
        <Container fluid style={{}}>
            <Row style={{ marginTop: '75px' }}>
                <Col xs={12} md={6} lg={6} style={{ padding: '8%', borderRadius: '70px', backgroundColor: '#1e3d52' }}>
                    <img src='https://ik.imagekit.io/p5ethkcnzwh/Blanco_Logo_final_2022_ABG_6V4gmyglP.png?ik-sdk-version=javascript-1.4.3&updatedAt=1657298859263' style={{ width: '100%' }} />
                </Col>
                <Col xs={12} md={6} lg={6} style={{ display: 'grid', placeItems: 'center', color: 'black' }}>
                    <h1>ESCUELA BANCARIA DE GUATEMALA</h1>
                    <h1>Informes Subgerencia</h1>
                </Col>
            </Row>
            <Row style={{ marginTop: '75px' }}>
                <Form onSubmit={onSubmit}>
                    <Container style={{ color: 'black' }}>
                        <Row>
                            <h1>Por favor seleccione un intervalo de tiempo</h1>
                        </Row>
                        <Row style={{ marginTop: '25px' }}>
                            <Col xs={12} md={6} lg={3}>
                                <Form.Group className="mb-3" controlId="fechaInicio">
                                    <Form.Label>Fecha Inicio</Form.Label>
                                    <Form.Control type="date" required placeholder="Ingrese la fecha inicio" name="fechaInicio" onChange={onChange} value={fechaInicio} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6} lg={3}>
                                <Form.Group className="mb-3" controlId="fechaFin">
                                    <Form.Label>Fecha Fin</Form.Label>
                                    <Form.Control type="date" required placeholder="Ingrese la fecha fin" name="fechaFin" onChange={onChange} value={fechaFin} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={12} lg={6} style={{ display: 'grid', placeItems: 'center' }}>
                                <Row>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="outline-dark">
                                            Exportar
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={genPDF}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-pdf-fill" viewBox="0 0 16 16">
                                                <path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z" />
                                                <path fillRule="evenodd" d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z" />
                                            </svg>&nbsp;&nbsp;&nbsp;&nbsp;Generar reporte en PDF</Dropdown.Item>
                                            <Dropdown.Item onClick={exportExcel}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-spreadsheet-fill" viewBox="0 0 16 16">
                                                <path d="M12 0H4a2 2 0 0 0-2 2v4h12V2a2 2 0 0 0-2-2zm2 7h-4v2h4V7zm0 3h-4v2h4v-2zm0 3h-4v3h2a2 2 0 0 0 2-2v-1zm-5 3v-3H6v3h3zm-4 0v-3H2v1a2 2 0 0 0 2 2h1zm-3-4h3v-2H2v2zm0-3h3V7H2v2zm4 0V7h3v2H6zm0 1h3v2H6v-2z" />
                                            </svg>&nbsp;&nbsp;&nbsp;&nbsp;Generar reporte en Excel</Dropdown.Item>
                                            <Dropdown.Item onClick={exportExcelParticipantes}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-spreadsheet" viewBox="0 0 16 16">
                                                <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V9H3V2a1 1 0 0 1 1-1h5.5v2zM3 12v-2h2v2H3zm0 1h2v2H4a1 1 0 0 1-1-1v-1zm3 2v-2h3v2H6zm4 0v-2h3v1a1 1 0 0 1-1 1h-2zm3-3h-3v-2h3v2zm-7 0v-2h3v2H6z" />
                                            </svg>&nbsp;&nbsp;&nbsp;&nbsp;Generar reporte en Excel (Inscritos)</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </Form>
            </Row>
            {report.map((actual) => <TipoEvento eventos={actual} />)}
            {(ventasTotales.length > 0 && ingresosTotales.length > 0) &&
                <Row>
                    <Container fluid>
                        <Row style={{ color: 'black', marginTop: '45px' }}>
                            <h1>Ventas totales en periodo seleccionado</h1>
                        </Row>
                        <Row style={{ backgroundColor: 'white', marginTop: '45px', height: '800px' }}>
                            <Container>
                                <ResponsiveContainer width="100%" height="100%">
                                    <ComposedChart
                                        width={500}
                                        height={400}
                                        data={info}
                                        margin={{
                                            top: 20,
                                            right: 20,
                                            bottom: 20,
                                            left: 20,
                                        }}
                                    >
                                        <CartesianGrid stroke="#f5f5f5" />
                                        <XAxis dataKey="asesor" scale="band" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Area type="monotone" dataKey="ingresos" fill="#8884d8" stroke="#8884d8" />
                                        <Bar dataKey="ventas" barSize={20} fill="#413ea0" />
                                        <Line dataKey="fichas_confirmadas" fill="red" />
                                    </ComposedChart>
                                </ResponsiveContainer>
                            </Container>
                        </Row>
                        <Row style={{ color: 'black', marginTop: '45px' }}>
                            <h1>Inscripciones totales en periodo seleccionado</h1>
                        </Row>
                        <Row style={{ backgroundColor: 'white', marginTop: '45px', height: '800px' }}>
                            <Container>
                                <ResponsiveContainer width="100%" height="100%" id='chart'>
                                    <ComposedChart
                                        layout="vertical"
                                        width={500}
                                        height={400}
                                        data={ventasTotales}
                                        margin={{
                                            top: 20,
                                            right: 20,
                                            bottom: 20,
                                            left: 20,
                                        }}
                                    >
                                        <CartesianGrid stroke="#f5f5f5" />
                                        <XAxis type="number" />
                                        <YAxis dataKey="asesor" type="category" scale="band" />
                                        <Tooltip />
                                        <Legend />
                                        <Bar dataKey="inscritos" barSize={20} fill="#416788" />
                                    </ComposedChart>
                                </ResponsiveContainer>
                            </Container>
                        </Row>
                        <Row style={{ color: 'black', marginTop: '45px' }}>
                            <h1>Patrocinios en periodo seleccionado</h1>
                        </Row>
                        <Row style={{ backgroundColor: 'white', marginTop: '45px', height: '800px' }}>
                            <Container>
                                <ResponsiveContainer width="100%" height="100%" id='chart'>
                                    <ComposedChart
                                        layout="vertical"
                                        width={500}
                                        height={400}
                                        data={ingresosTotales}
                                        margin={{
                                            top: 20,
                                            right: 20,
                                            bottom: 20,
                                            left: 20,
                                        }}
                                    >
                                        <CartesianGrid stroke="#f5f5f5" />
                                        <XAxis type="number" />
                                        <YAxis dataKey="asesor" type="category" scale="band" />
                                        <Tooltip />
                                        <Legend />
                                        <Line type="monotone" dataKey="Ingresos por patrocinio" stroke="#ff7300" />
                                    </ComposedChart>
                                </ResponsiveContainer>
                            </Container>
                        </Row>
                    </Container>
                </Row>
            }
            {
                totalesAsesorByTipo.length > 0 && <Row style={{ marginTop: '45px', }}>
                    <Container>
                        {totalesAsesorByTipo.map((actual) => <Row key={actual.key} className='fila-tabla-reporte' style={{ marginTop: '50px' }}>
                            <h1 style={{ marginBottom: '20px' }}>Resumen consolidado por asesor para {actual.tipo}</h1>
                            <Table striped bordered hover variant="light" style={{ borderCollapse: 'collapse', backgroundColor: 'white' }}>
                                <thead>
                                    <th style={{ position: 'sticky', top: '0' }}>Asesor</th>
                                    <th style={{ position: 'sticky', top: '0' }}>Fichas de inscripción</th>
                                    <th style={{ position: 'sticky', top: '0' }}>Personas inscritas</th>
                                    <th style={{ position: 'sticky', top: '0' }}>Ingresos (inscripciones)</th>
                                    <th style={{ position: 'sticky', top: '0' }}>Ingresos (patrocinios)</th>
                                    <th style={{ position: 'sticky', top: '0' }}>Ingresos totales</th>
                                    <th style={{ position: 'sticky', top: '0' }}>Aporte</th>
                                </thead>
                                <tbody>
                                    {/*patrocinios_number */}
                                    {actual.asesores.map((asesor, indice) =>
                                        <tr key={asesor.UniqueID}>
                                            <td>{asesor.asesor}</td>
                                            <td>{asesor.inscripciones}</td>
                                            <td>{asesor.participantes}</td>
                                            <td>{asesor.total}</td>
                                            <td>{asesor.patrocinios}</td>
                                            <td>{asesor.total_dec}</td>
                                            {(actual.asesores.reduce((accumulator, object) => {
                                                return accumulator + object.total_total;
                                            }, 0) + actual.asesores.reduce((accumulator, object) => {
                                                return accumulator + object.total_total;
                                            }, 0)) > 0 && <td>{((asesor.total_total / (actual.asesores.reduce((accumulator, object) => {
                                                return accumulator + object.total_total;
                                            }, 0).toFixed(2))) * 100).toFixed(4)} %</td>}
                                            {actual.asesores.reduce((accumulator, object) => {
                                                return accumulator + object.total_total;
                                            }, 0) === 0 && <td>0%</td>}
                                        </tr>
                                    )}
                                    <tr>
                                        <td><strong>TOTALES</strong></td>
                                        <td><strong>{(actual.asesores.reduce((accumulator, object) => {
                                            return accumulator + object.inscripciones;
                                        }, 0)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</strong></td>
                                        <td><strong>{(actual.asesores.reduce((accumulator, object) => {
                                            return accumulator + object.participantes;
                                        }, 0)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</strong></td>
                                        <td><strong>Q {(actual.asesores.reduce((accumulator, object) => {
                                            return accumulator + object.inversion_total;
                                        }, 0).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</strong></td>
                                        <td><strong>Q {(actual.asesores.reduce((accumulator, object) => {
                                            return accumulator + object.patrocinios_number;
                                        }, 0).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</strong></td>
                                        <td><strong>Q {(actual.asesores.reduce((accumulator, object) => {
                                            return accumulator + object.total_total;
                                        }, 0).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</strong></td>
                                        <td><strong></strong></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Row>)}
                    </Container>
                </Row>
            }
            {
                totales.length > 0 &&
                <Row className='fila-tabla-reporte' style={{ marginTop: '45px', }}>
                    <Container>
                        <Row>
                            <div >
                                <h1 style={{ color: 'black', marginBottom: '45px' }}>Totales ejecución</h1>
                                <Table striped bordered hover variant="light" style={{ borderCollapse: 'collapse', backgroundColor: 'white', minWidth: '4000px' }}>
                                    <thead>
                                        <th style={{ position: 'sticky', top: '0' }}>Ingresos</th>
                                        <th style={{ position: 'sticky', top: '0' }}>Egresos</th>
                                        <th style={{ position: 'sticky', top: '0' }}>Resultado</th>
                                        <th style={{ position: 'sticky', top: '0' }}>Cortesías</th>
                                        <th style={{ position: 'sticky', top: '0' }}>Pagados</th>
                                        <th style={{ position: 'sticky', top: '0' }}>Sin validación de contabilidad</th>
                                        <th style={{ position: 'sticky', top: '0' }}>Ejecutado EBG (Inscritos)</th>
                                        <th style={{ position: 'sticky', top: '0' }}>Ejecutado EBC (Inscritos)</th>
                                        <th style={{ position: 'sticky', top: '0' }}>Ejecutado EBG (Ingresos)</th>
                                        <th style={{ position: 'sticky', top: '0' }}>Ejecutado EBC (Ingresos)</th>
                                        <th style={{ position: 'sticky', top: '0' }}>Total Inscritos</th>
                                        <th style={{ position: 'sticky', top: '0' }}>Ingresos proyectados</th>
                                        <th style={{ position: 'sticky', top: '0' }}>Egresos proyectados</th>
                                        <th style={{ position: 'sticky', top: '0' }}>Resultado proyectado</th>
                                        <th style={{ position: 'sticky', top: '0' }}>Mínimo de participantes</th>
                                    </thead>
                                    <tbody>
                                        <td>{totales[0].totalIngresos}</td>
                                        <td>{totales[0].totalEgresos}</td>
                                        <td>{totales[0].totalResultado}</td>
                                        <td>{totales[0].totalCortesias}</td>
                                        <td>{totales[0].totalPagados}</td>
                                        <td>{totales[0].totalSinValidacion}</td>
                                        <td>{totales[0].totalInscritosEBG}</td>
                                        <td>{totales[0].totalInscritosEBC}</td>
                                        <td>{totales[0].totalIngresosEBG}</td>
                                        <td>{totales[0].totalIngresosEBC}</td>
                                        <td>{totales[0].totalInscritos}</td>
                                        <td>{totales[0].totalIngresosProyectados}</td>
                                        <td>{totales[0].totalEgresosProyectados}</td>
                                        <td>{totales[0].totalResultadoProyectado}</td>
                                        <td>{totales[0].totalMinimoParticipantes}</td>
                                    </tbody>
                                </Table>
                            </div>
                        </Row>
                    </Container>
                </Row>
            }
            {
                asesorSales.length > 0 &&
                <Row className='fila-tabla-reporte' style={{ marginTop: '45px', }}>
                    <Container>
                        <Row>
                            <div >
                                <h1 style={{ color: 'black', marginBottom: '45px' }}>Totales ejecución por Asesor</h1>
                                <Table striped bordered hover variant="light" style={{ borderCollapse: 'collapse', backgroundColor: 'white' }}>
                                    <thead>
                                        <th style={{ position: 'sticky', top: '0' }}>Ranking</th>
                                        <th style={{ position: 'sticky', top: '0' }}>Asesor</th>
                                        <th style={{ position: 'sticky', top: '0' }}>Fichas de inscripción</th>
                                        <th style={{ position: 'sticky', top: '0' }}>Personas inscritas</th>
                                        <th style={{ position: 'sticky', top: '0' }}>Ingresos (inscripciones)</th>
                                        <th style={{ position: 'sticky', top: '0' }}>Ingresos (patrocinios)</th>
                                        <th style={{ position: 'sticky', top: '0' }}>Ingresos totales</th>
                                        <th style={{ position: 'sticky', top: '0' }}>Aporte</th>
                                    </thead>
                                    <tbody>
                                        {
                                            asesorSales.map((asesor, index) =>
                                                <tr key={asesor.id}>
                                                    <td>{index + 1}</td>
                                                    <td>{asesor.asesor}</td>
                                                    <td>{asesor.inscripciones}</td>
                                                    <td>{asesor.participantes}</td>
                                                    <td>{asesor.total}</td>
                                                    <td>{asesor.patrocinios}</td>
                                                    <td>{asesor.total_dec}</td>
                                                    {asesorSales.reduce((accumulator, object) => {
                                                        return accumulator + object.total_total;
                                                    }, 0) > 0 && <td>{((asesor.total_total / (asesorSales.reduce((accumulator, object) => {
                                                        return accumulator + object.total_total;
                                                    }, 0).toFixed(2))) * 100).toFixed(4)} %</td>}
                                                    {asesorSales.reduce((accumulator, object) => {
                                                        return accumulator + object.total_total;
                                                    }, 0) === 0 && <td>0%</td>}
                                                </tr>)
                                        }
                                        <tr>
                                            <td></td>
                                            <td><strong>TOTAL</strong></td>
                                            <td><strong>{(asesorSales.reduce((accumulator, object) => {
                                                return accumulator + object.inscripciones;
                                            }, 0))}</strong></td>
                                            <td><strong>{(asesorSales.reduce((accumulator, object) => {
                                                return accumulator + object.participantes;
                                            }, 0))}</strong></td>
                                            <td><strong>Q {(asesorSales.reduce((accumulator, object) => {
                                                return accumulator + object.inversion_total;
                                            }, 0).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</strong></td>
                                            <td><strong>Q {(asesorSales.reduce((accumulator, object) => {
                                                return accumulator + object.patrocinios_number;
                                            }, 0).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</strong></td>
                                            <td><strong>Q {(asesorSales.reduce((accumulator, object) => {
                                                return accumulator + object.total_total;
                                            }, 0).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</strong></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </Row>
                    </Container>
                </Row>
            }
        </Container>
    )
}

export default Reporterias