import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Form, Button, Col } from "react-bootstrap";
import axios from "axios";
const CreateGafeteFisico = () => {
  const { hash } = useParams();
  const [contador, setContador] = useState(0);
  const [cambios, setCambios] = useState(0);
  const [evento, setEvento] = useState({});
  const [frente, setFrente] = useState(null);
  const [reverso, setReverso] = useState(null);
  const [descripcion, setDescripcion] = useState("");
  const [cargando, setCargando] = useState(false);
  const [exito, setExito] = useState(false);
  const [error, setError] = useState(false);
  const [validated, setValidated] = useState(false);
  useEffect(() => {
    async function fetchData() {
      fetch(
        process.env.REACT_APP_DEFAULT_URL + "/api/read/evento/landing/" + hash,
        {
          cache: "no-cache",
          credentials: "include",
        }
      )
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setEvento(data.rows[0]);
        });
    }
    fetchData();
  }, [contador]);
  useEffect(() => {}, [cambios]);
  const onChange = (evt) => {
    if (evt.target.name == "frente") {
      setFrente(evt.target.files[0]);
    } else if (evt.target.name == "reverso") {
      setReverso(evt.target.files[0]);
    } else if (evt.target.name == "descripcion") {
      setDescripcion(evt.target.value);
    }
    setCambios(cambios + 1);
  };
  const onSubmit = (evt) => {
    const form = evt.currentTarget;
    if (form.checkValidity() === false) {
      evt.preventDefault();
      evt.stopPropagation();
    } else {
      evt.preventDefault();
      setCargando(true);
      setCambios(cambios + 1);
      const dataFrente = new FormData();
      dataFrente.append("file", frente);
      const dataReverso = new FormData();
      dataReverso.append("file", reverso);
      axios
        .post(
          process.env.REACT_APP_DEFAULT_URL + "/api/files/upload/s3/diplomas",
          dataFrente,
          {}
        )
        .then((resFrente) => {
          axios
            .post(
              process.env.REACT_APP_DEFAULT_URL +
                "/api/files/upload/s3/diplomas",
              dataReverso,
              {}
            )
            .then((resReverso) => {
              let frenteKey = resFrente.data.resultado.Key;
              let reversoKey = resReverso.data.resultado.Key;
              const info = {
                frente: frenteKey,
                reverso: reversoKey,
                descripcion: descripcion,
                evento_UniqueID: evento.UniqueID,
              };
              const requestOptions = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(info),
              };
              fetch(
                process.env.REACT_APP_DEFAULT_URL +
                  "/api/gafetes-fisicos/create",
                requestOptions
              )
                .then((res) => {
                  return res.json();
                })
                .then((data) => {
                  setCargando(false);
                  setCambios(cambios + 1);
                  if (data.codigo == 200) {
                    //crm/seguimiento/view/:id
                    window.location.replace(
                      process.env.REACT_APP_SELF_URL +
                        "/gafetes-fisicos/" +
                        hash +
                        "/view/" +
                        data.UniqueID
                    );
                    setExito(true);
                    setCambios(cambios + 1);
                  } else {
                    setError(true);
                    setCambios(cambios + 1);
                  }
                });
            });
        });
    }
    setValidated(true);
    setCambios(cambios + 1);
  };
  return (
    <Container fluid>
      <Row style={{ padding: "5%" }}>
        <Container>
          <Row
            style={{
              padding: "5%",
              borderBottom: "5px solid #1e3d52",
              borderLeft: "5px solid #1e3d52",
              backgroundColor: "rgb(230,230,230)",
            }}
          >
            <Col xs={12} md={6} lg={4}>
              <img
                loading="lazy"
                src={evento.flier}
                style={{ width: "100%" }}
              />
            </Col>
            <Col xs={12} md={6} lg={8} style={{ textAlign: "left" }}>
              <h1>REGISTRO GAFETE FÍSICO</h1>
              {evento != null && (
                <p>
                  <br />
                  <strong>EVENTO: </strong>
                  {evento.Nombre}
                  <br />
                  <strong>FECHA: </strong>
                  {evento.Fecha_Inicio}
                </p>
              )}
            </Col>
          </Row>
        </Container>
      </Row>
      <Row
        style={{
          paddingTop: "2%",
          paddingBottom: "2%",
          paddingLeft: "15%",
          paddingRight: "15%",
          textAlign: "left",
        }}
      >
        <Form onSubmit={onSubmit} noValidate validated={validated}>
          <Row>
            <Form.Group className="mb-3" controlId="descripcion">
              <Form.Label>Descripción breve del gafete</Form.Label>
              <Form.Control
                value={descripcion}
                disabled={cargando || error}
                required
                type="text"
                placeholder="Ingrese una breve descripción"
                name="descripcion"
                onChange={onChange}
              />
              <Form.Control.Feedback>Ok</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Por favor, completa este campo
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="mb-3" controlId="frente">
              <Form.Label>Archivo con imagen para frente de gafete</Form.Label>
              <Form.Control
                disabled={cargando || error}
                type="file"
                accept="image/png, image/jpeg"
                name="frente"
                required
                onChange={onChange}
              />
              <Form.Control.Feedback>Ok</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Por favor, completa este campo
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="mb-3" controlId="reverso">
              <Form.Label>Archivo con imagen para reverso de gafete</Form.Label>
              <Form.Control
                disabled={cargando || error}
                type="file"
                accept="image/png, image/jpeg"
                name="reverso"
                required
                onChange={onChange}
              />
              <Form.Control.Feedback>Ok</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Por favor, completa este campo
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            {!error && (
              <Button
                variant="outline-secondary"
                className="font-bold"
                type="submit"
                disabled={cargando || error}
              >
                Guardar
              </Button>
            )}
            {error && (
              <Button
                variant="outline-secondary"
                className="font-bold"
                onClick={() => {
                  setError(false);
                  setCambios(cambios + 1);
                }}
                disabled={cargando || !error}
              >
                Intentar de nuevo
              </Button>
            )}
          </Row>
        </Form>
      </Row>{" "}
    </Container>
  );
};

export default CreateGafeteFisico;
