import React, { useState, useEffect } from 'react'
import { Row, Button, Form, Container, Col, Alert, Spinner } from "react-bootstrap";
//import DEFAULT_URL from '../../api/url';
import axios from 'axios';
import "./style.css"
const ViewFacilitador = (props) => {
  const [contador, setContador] = useState(0);
  const [edit, setEdit] = useState(0);
  const [exito, setExito] = useState('none');
  const [formulario, setFormulario] = useState('none');
  const [error, setError] = useState('none');
  const [cargando, setCargando] = useState('none');
  const [view, setView] = useState('block');
  const [confirmacion, setConfirmacion] = useState('none');
  const [mensaje, setMensaje] = useState('');
  const [nombres, setNombres] = useState('');
  const [apellidos, setApellidos] = useState('');
  const [telefono, setTelefono] = useState('');
  const [correo, setCorreo] = useState('');
  const [tarifa, setTarifa] = useState(0);
  const [foto, setFoto] = useState(null);
  const [experiencia, setExperiencia] = useState([]);
  const [tipos, setTipos] = useState([]);
  const [temas, setTemas] = useState([]);
  const [biblioteca, setBiblioteca] = useState([]);
  useEffect(() => {
    async function fetchData() {
      fetch(process.env.REACT_APP_DEFAULT_URL + '/api/facilitadores/read/tipo-experiencia', { cache: "no-cache" })
        .then((res) => {
          return res.json();
        })
        .then(data => {
          setTipos(data.rows);
        });
      fetch(process.env.REACT_APP_DEFAULT_URL + '/api/temas/read', { cache: "no-cache" })
        .then((res) => {
          return res.json();
        })
        .then(data => {
          setBiblioteca(data.salida);
        });
      fetch(process.env.REACT_APP_DEFAULT_URL + '/api/temas/read/by-docente/' + props.facilitador.UniqueID, { cache: "no-cache" })
        .then((res) => {
          return res.json();
        })
        .then(data => {
          setTemas(data.salida);
        });
      ///api/facilitadores/read/experiencia/
      fetch(process.env.REACT_APP_DEFAULT_URL + '/api/facilitadores/read/experiencia/' + props.facilitador.UniqueID, { cache: "no-cache" })
        .then((res) => {
          return res.json();
        })
        .then(data => {
          setExperiencia(data.rows);
        });
    }
    fetchData();
  }, [contador]);
  useEffect(() => {

  }, [edit]);

  const onChange = (evt) => {
    if (evt.target.name == "nombres") {
      setNombres(evt.target.value);
    } else if (evt.target.name == "apellidos") {
      setApellidos(evt.target.value);
    } else if (evt.target.name == "correo") {
      setCorreo(evt.target.value);
    } else if (evt.target.name == "tarifa") {
      setTarifa(evt.target.value);
    } else if (evt.target.name == "foto") {
      setFoto(evt.target.files[0]);
    } else if (evt.target.name == "telefono") {
      setTelefono(evt.target.value);
    }
    setEdit(edit + 1);
  }
  const cargar = (mensaje) => {
    setExito('none');
    setFormulario('none');
    setError('none');
    setCargando('block');
    setMensaje(mensaje);
    setConfirmacion('none');
    setContador(contador + 1);
  }
  const operacionExito = (mensaje) => {
    setExito('block');
    setFormulario('none');
    setError('none');
    setCargando('none');
    setMensaje(mensaje);
    props.refresh();
    setContador(contador + 1);
  }
  const operacionError = (mensaje) => {
    setExito('none');
    setFormulario('none');
    setError('block');
    setCargando('none');
    setMensaje(mensaje);
    setContador(contador + 1);
  }
  const onSubmit = (evt) => {
    evt.preventDefault();
    if (experiencia.length == 0) {
      operacionError('Se debe ingresar la experiencia del facilitador');
    } else if (temas.length == 0) {
      operacionError('Se debe ingresar la biblioteca del facilitador');
    } else if (experiencia.length != 0 && temas.length != 0) {
      const data = new FormData();
      data.append('file', foto);
      cargar('Subiendo archivo');
      if (foto == null) {
        cargar('Sincronizando db');
        const info = {
          Nombres: nombres,
          Apellidos: apellidos,
          Telefono: telefono,
          Correo: correo,
          Tarifa: tarifa,
          Foto: props.facilitador.Foto,
          keyFoto: props.facilitador.keyFoto,
          experiencia: experiencia,
          biblioteca: temas,
          UniqueID: props.facilitador.UniqueID
        };
        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/facilitadores/update', requestOptions)
          .then((res) => {
            return res.json();
          })
          .then(data => {
            if (data.codigo == 200) {
              operacionExito('Facilitador actualizado exitosamente');
            } else {
              operacionError('Error al actuailzar registro en db');
            }
          });
      } else {
        axios.post(process.env.REACT_APP_DEFAULT_URL + "/api/files/upload/s3", data, {}).then(res => {
          cargar('Sincronizando db');
          const info = {
            Nombres: nombres,
            Apellidos: apellidos,
            Telefono: telefono,
            Correo: correo,
            Tarifa: tarifa,
            Foto: res.data.resultado.Location,
            keyFoto: res.data.resultado.Key,
            experiencia: experiencia,
            biblioteca: temas,
            UniqueID: props.facilitador.UniqueID
          };
          const requestOptions = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
          };
          fetch(process.env.REACT_APP_DEFAULT_URL + '/api/facilitadores/update', requestOptions)
            .then((res) => {
              return res.json();
            })
            .then(data => {
              if (data.codigo == 200) {
                operacionExito('Facilitador actualizado exitosamente');
              } else {
                operacionError('Error al actualizar registro en db');
              }
            });
        });
      }
    }
  }
  const goBack = () => {
    setExito('none');
    setFormulario('none');
    setError('none');
    setCargando('none');
    setView('block');
    setConfirmacion('none');
    setNombres('');
    Array.from(document.querySelectorAll("input")).forEach(
      input => (input.value = "")
    );
    setContador(contador + 1);
  }
  const onChangeExperiencia = (evt) => {
    const arr = evt.target.name.split('-');
    let temp = experiencia;
    let indice = parseInt(arr[0]);
    if (arr[1] == "descripcion") {
      temp[indice].descripcion = evt.target.value;
    } else if (arr[1] == "tipo") {
      temp[indice].tipo = evt.target.value;
    }
    setExperiencia(temp);
    setEdit(edit + 1);
  }
  const onChangeTema = (evt) => {
    const arr = evt.target.name.split('-');
    let temp = temas;
    let indice = parseInt(arr[0]);
    if (arr[1] == "tema") {
      temp[indice].UniqueID = evt.target.value;
    }
    setTemas(temp);
    setEdit(edit + 1);
  }
  const goToEdit = () => {
    setExito('none');
    setFormulario('block');
    setError('none');
    setCargando('none');
    setView('none');
    setConfirmacion('none');
    setNombres(props.facilitador.Nombres);
    setApellidos(props.facilitador.Apellidos);
    setTelefono(props.facilitador.Telefono);
    setCorreo(props.facilitador.Correo);
    setTarifa(props.facilitador.costo);
    setContador(contador + 1);
  }
  const confirmar = () => {
    setExito('none');
    setFormulario('none');
    setError('none');
    setCargando('none');
    setConfirmacion('block');
    setView('none');
    setContador(contador + 1);
  }
  const deleteSelf = () => {
    cargar('Sincronizando base de datos');
    const info = {
      UniqueID: props.facilitador.UniqueID
    }
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(info)
    };
    fetch(process.env.REACT_APP_DEFAULT_URL + '/api/facilitador/delete', requestOptions)
      .then((res) => {
        return res.json();
      })
      .then(data => {
        if (data.codigo == 200) {
          operacionExito('Facilitador eliminado exitosamente');
        } else {
          operacionError('Error al eliminar registro en db');
        }
      });
  }
  return (
    <Container style={{ border: '1px solid black', padding: '2%' }}>
      <Row style={{ display: view }}>
        <Container>
          <Row>
            <Button variant='outline-success' onClick={goToEdit}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-fill" viewBox="0 0 16 16">
              <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
            </svg>&nbsp;&nbsp;&nbsp;Editar Facilitador</Button>
          </Row>
          <Row style={{ marginTop: '25px' }}>
            <Button variant='outline-danger' onClick={() => { confirmar(); }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3-fill" viewBox="0 0 16 16">
              <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
            </svg>&nbsp;&nbsp;&nbsp;Eliminar Facilitador</Button>
          </Row>
          <Row style={{ padding: '5%', marginTop: '25px' }}>
            <Col xs={12} md={12} lg={6}>
              <img src={props.facilitador.Foto} style={{ width: '75%' }} alt='foto-docente' />
            </Col>
            <Col xs={12} md={12} lg={6} style={{ marginTop: '25px' }}>
              <h2>Información personal del facilitador</h2>
              <ul style={{ listStyle: 'square', textAlign: 'left', fontSize: '18px' }}>
                <li><strong>Nombre completo: </strong>{props.facilitador.Nombres + ' ' + props.facilitador.Apellidos}</li>
                <li><strong>Teléfono: </strong>{props.facilitador.Telefono}</li>
                <li><strong>Correo electrónico: </strong>{props.facilitador.Correo}</li>
                <li><strong>Tarifa: </strong>{props.facilitador.Tarifa}</li>
              </ul>
            </Col>
          </Row>
          <Row style={{ padding: '5%', marginTop: '25px' }}>
            <Col xs={12} md={12} lg={6}>
              <h2>Experiencia</h2>
              <ul style={{ textAlign: 'left' }}>
                {experiencia.map((actual, index) => <li key={actual.UniqueID}>
                  {actual.descripcion}
                  <ul className='lista'>
                    <li className='item'><strong>{actual.Nombre}</strong></li>
                  </ul>
                </li>)}
              </ul>
            </Col>
            <Col xs={12} md={12} lg={6}>
              <h2>Biblioteca</h2>
              <ul className='lista'>
                {temas.map((actual, index) => <li className='item' key={actual.UniqueID}>{actual.nombre}</li>)}
              </ul>

            </Col>
          </Row>
        </Container>
      </Row >
      <Row style={{ display: exito }}>
        <Alert variant='success'>
          <Alert.Heading>
            Operación realizada con éxito
          </Alert.Heading>
          <p>
            {mensaje}
          </p>
          <hr />
          <div className="d-flex justify-content-end">
            <Button variant='outline-success' onClick={() => { goBack(); props.refresh(); }}>
              Ok
            </Button>
          </div>
        </Alert>
      </Row>
      <Row style={{ display: error }}>
        <Alert variant='danger'>
          <Alert.Heading>
            Ocurrió un error al ingresar la propuesta
          </Alert.Heading>
          <p>
            {mensaje}
          </p>
          <hr />
          <div className="d-flex justify-content-end">
            <Button variant='outline-danger' onClick={goBack}>
              Ok
            </Button>
          </div>
        </Alert>
      </Row>
      <Row style={{ display: cargando }}>
        <Alert variant='dark'>
          <Alert.Heading>
            <Spinner animation="border" role="status">
            </Spinner>&nbsp;&nbsp;&nbsp;
            Ingresando información
          </Alert.Heading>
          <p>
            {mensaje}
          </p>
          <hr />
          <p>
            Por favor espere...
          </p>
        </Alert>
      </Row>
      <Row style={{ display: confirmacion }}>
        <Alert variant='warning'>
          <Alert.Heading>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
              <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
            </svg>&nbsp;&nbsp;&nbsp;Se necesita confirmación
          </Alert.Heading>
          <p>
            ¿Está segur@ de eliminar el facilitador seleccionado?
            <br />
            <strong>Nombre del facilitador: </strong> {props.facilitador.Nombres}
          </p>
          <hr />
          <div className="d-flex justify-content-end">
            <Button variant='outline-danger' onClick={deleteSelf}>
              si
            </Button>&nbsp;&nbsp;&nbsp;
            <Button variant='outline-dark' onClick={goBack}>
              no
            </Button>
          </div>
        </Alert>
      </Row>
      <Row style={{ display: formulario }}>
        <Container>
          <Form onSubmit={onSubmit}>
            <Row>
              <h1>Editar Facilitador</h1>
            </Row>
            <Row>
              <Form.Group className="mb-3" controlId="nombres">
                <Form.Label>Nombres del facilitador</Form.Label>
                <Form.Control required type="text" placeholder="Ingrese los nombres de la persona" name="nombres" value={nombres} onChange={onChange} />
              </Form.Group>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={6}>
                <Form.Group className="mb-3" controlId="apellidos">
                  <Form.Label>Apellidos</Form.Label>
                  <Form.Control required type="text" placeholder="Ingrese los apellidos de la persona" name="apellidos" value={apellidos} onChange={onChange} />
                </Form.Group>
              </Col>
              <Col xs={12} md={12} lg={6}>
                <Form.Group className="mb-3" controlId="telefono">
                  <Form.Label>Teléfono</Form.Label>
                  <Form.Control required type="text" placeholder="Ingrese el teléfono de la persona" name="telefono" value={telefono} onChange={onChange} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={6}>
                <Form.Group className="mb-3" controlId="correo">
                  <Form.Label>Correo electrónico</Form.Label>
                  <Form.Control required type="text" placeholder="Ingrese el correo electrónico de la persona" name="correo" value={correo} onChange={onChange} />
                </Form.Group>
              </Col>
              <Col xs={12} md={12} lg={6}>
                <Form.Group className="mb-3" controlId="tarifa">
                  <Form.Label>Tarifa</Form.Label>
                  <Form.Control required type="text" placeholder="Ingrese la tarifa" name="tarifa" value={tarifa} onChange={onChange} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={12}>
                <Form.Group className="mb-3" controlId="archivo">
                  <Form.Label>Seleccione el archivo con la foto del facilitador</Form.Label>
                  <Form.Control type="file" name='foto' onChange={onChange} accept="image/x-png,image/gif,image/jpeg" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <h3>Experiencia del facilitador</h3>
              <Container>
                <Row style={{ padding: '2%' }}>
                  <Button variant='outline-success' onClick={() => { experiencia.push({ descripcion: '', tipo: 1 }); setEdit(edit + 1); }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                    </svg>&nbsp;&nbsp;&nbsp;Añadir experiencia
                  </Button>
                </Row>
                {experiencia.map((actual, index) => <Row key={'experiencia-docente-' + index+'-'+props.facilitador.UniqueID} style={{ display: 'flex', placeItems: 'center' }}>
                  <Col xs={12} md={12} lg={4}>
                    <Form.Group className="mb-3" controlId={index + "-descripcion"}>
                      <Form.Label>Descripción</Form.Label>
                      <Form.Control required as="textarea" rows={3} style={{ resize: 'none' }} placeholder="ingrese una breve descripción de la experiencia del docente" name={index + '-descripcion'} value={actual.descripcion} onChange={onChangeExperiencia} />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={12} lg={4}>
                    <Form.Group className="mb-3" controlId={index + "-tipo"}>
                      <Form.Label>Indique el tipo de experiencia</Form.Label>
                      <Form.Control as="select" required aria-label="Tipo" name={index + '-tipo'} onChange={onChangeExperiencia} value={actual.tipo}>
                        <option value="" key="0">Seleccione el tipo de experiencia</option>
                        {tipos.map(tipo => <option key={tipo.UniqueID} value={tipo.UniqueID}>{tipo.Nombre}</option>)}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={12} lg={4}>
                    <Row>
                      <Button onClick={() => { experiencia.splice(index, 1); setEdit(edit + 1); }} variant='outline-danger'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3-fill" viewBox="0 0 16 16">
                        <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                      </svg>&nbsp;&nbsp;&nbsp;Eliminar experiencia</Button>
                    </Row>
                  </Col>
                </Row>)}
              </Container>
            </Row>
            <Row>
              <h3>Manejo de biblioteca</h3>
              <Container>
                <Row style={{ padding: '2%' }}>
                  <Button variant='outline-success' onClick={() => { temas.push({ UniqueID: '' }); setEdit(edit + 1); }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                    </svg>&nbsp;&nbsp;&nbsp;Añadir tema de biblioteca
                  </Button>
                </Row>
                {temas.map((actual, index) => <Row key={'tema-docente-' + index+'-'+props.facilitador.UniqueID} style={{ display: 'flex', placeItems: 'center' }}>
                  <Col xs={12} md={12} lg={6}>
                    <Form.Group className="mb-3" controlId={index + "-tema"}>
                      <Form.Label>Seleccione un tema de la biblioteca de contenidos</Form.Label>
                      <Form.Control as="select" required aria-label="Tipo" name={index + '-tema'} onChange={onChangeTema} value={actual.UniqueID}>
                        <option value="" key="0">Seleccione el tema</option>
                        {biblioteca.map(tema => <option key={tema.UniqueID} value={tema.UniqueID}>{tema.nombre}</option>)}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={12} lg={6}>
                    <Row>
                      <Button onClick={() => { temas.splice(index, 1); setEdit(edit + 1); }} variant='outline-danger'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3-fill" viewBox="0 0 16 16">
                        <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                      </svg>&nbsp;&nbsp;&nbsp;Eliminar tema</Button>
                    </Row>
                  </Col>
                </Row>)}
              </Container>
            </Row>
            <Row>
              <Button variant="outline-dark" className="font-bold" type="submit">Guardar</Button>
            </Row>
            <Row style={{ marginTop: '25px' }}>
              <Button variant="outline-secondary" className="font-bold" onClick={goBack}>Cancelar</Button>
            </Row>
          </Form>
        </Container>
      </Row>
    </Container >)
}

export default ViewFacilitador