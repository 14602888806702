import React, { useState, useEffect } from 'react';
import { Row, Button, Col, Form, Container } from "react-bootstrap";
//import DEFAULT_URL from '../../../../../api/url';
const FormCarpeta = (props) => {
    const [contador, setContador] = useState(0);
    const [nombre, setNombre] = useState('');

    useEffect(() => {

    }, [contador]);
    const onChange = (evt) => {
        setNombre(evt.target.value);
        setContador(contador + 1);
    }
    const submitCarpeta = (evt) => {
        evt.preventDefault();
        props.cargar('Sincronizando base de datos', 'Por favor espere');
        const info = { Nombre: nombre, evento_UniqueID: props.evento_UniqueID };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/create/carpeta', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                if (data.codigo == 200) {
                    props.ok('Se ha almacenado la carpeta exitosamente', 'Por favor valide la información e informe a todos');
                } else {
                    props.err('Hubo un error al almacenar la carpeta', 'Por favor recargue la página y vuelva a intentarlo');
                }
                Array.from(document.querySelectorAll("input")).forEach(
                    input => (input.value = "")
                );
            });
        setNombre('');
    }
    return (
        <Form onSubmit={submitCarpeta}>
            <Container>
                <Row style={{ marginBottom: '45px' }}>
                    <Col xs={12} md={12} lg={12}>
                        <Button variant="dark" onClick={props.back}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-backspace-fill" viewBox="0 0 16 16">
                                <path d="M15.683 3a2 2 0 0 0-2-2h-7.08a2 2 0 0 0-1.519.698L.241 7.35a1 1 0 0 0 0 1.302l4.843 5.65A2 2 0 0 0 6.603 15h7.08a2 2 0 0 0 2-2V3zM5.829 5.854a.5.5 0 1 1 .707-.708l2.147 2.147 2.146-2.147a.5.5 0 1 1 .707.708L9.39 8l2.146 2.146a.5.5 0 0 1-.707.708L8.683 8.707l-2.147 2.147a.5.5 0 0 1-.707-.708L7.976 8 5.829 5.854z" />
                            </svg>&nbsp;&nbsp;&nbsp;Atrás
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12} lg={12}>
                        <Form.Group className="mb-3" controlId="material">
                            <Form.Label>Nombre de la carpeta</Form.Label>
                            <Form.Control required type="text" name="nombre" placeholder="Ingrese el nombre de la carpeta" onChange={onChange} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Button variant="dark" className="font-bold" type="submit">Crear Carpeta</Button>
                </Row>
            </Container>
        </Form>
    );
}

export default FormCarpeta