import React, { useEffect, useState } from 'react'
import { Breadcrumb, Row, Alert, Spinner, Button, Col, Dropdown, Form, Container, ButtonGroup, DropdownButton, Popover } from "react-bootstrap";
//import { SELF_URL } from '../../../../../api/url';
//import DEFAULT_URL from '../../../../../api/url';
import ModalEditarFormulario from './ModalEditarFormulario';
import { Confirmacion } from '../../../../Utils/ConfirmationModal/Confirmacion';
import { ErrorModal } from '../../../../Utils/ErrorModal/ErrorModal';
import { SuccessModal } from '../../../../Utils/SuccessModal/SuccessModal';
import './common.css';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import axios from 'axios';

const DetalleFormulario = (props) => {
    const [contador, setContador] = useState(0);
    const [respuestas, setRespuestas] = useState(0);
    const [preguntas, setPreguntas] = useState([]);
    const [editarFormulario, setEditarFormulario] = useState(false);
    const [confirmacionEliminar, setConfirmacionEliminar] = useState(false);
    const [confirmacionOcultar, setConfirmacionOcultar] = useState(false);
    const [confirmacionPublicar, setConfirmacionPublicar] = useState(false);
    const [mensaje, setMensaje] = useState('');
    const [consideracion, seConsideracion] = useState('');
    const [modalSucc, setModalSucc] = useState(false);
    const [modalError, setModalError] = useState(false);
    const [subject, setSubject] = useState('');
    const [cargando, setCargando] = useState('none');
    const [exito, setExito] = useState('none');
    const [error, setError] = useState('none');
    const [selectedFile, setSelectedFile] = useState(null);
    const [detalle, setDetalle] = useState('block');
    const [form, setForm] = useState('none');
    const [titulo, setTitulo] = useState('');
    const [descripcion, setDescripcion] = useState('');
    useEffect(() => {
        async function fetchData() {
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/formulario/respuestas/' + props.formulario.UniqueID, { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    if (data.respuestas == null) {
                        setRespuestas(0);
                    } else {
                        setRespuestas(data.respuestas.respuestas);
                    }
                });
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/formulario/preguntas/' + props.formulario.UniqueID, { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    let pregunts = [];
                    if (data.preguntas != null) {
                        data.preguntas.map((pregunta) => {
                            pregunts.push({ pregunta: pregunta.pregunta, tipo: pregunta.tipo_pregunta_UniqueID });
                        });
                    }
                    setPreguntas(pregunts);
                });

        }
        fetchData();
    }, [contador]);
    const ocultarEliminarConfirmacion = () => {
        setConfirmacionEliminar(false);
    }
    const ocultarOcultarConfirmacion = () => {
        setConfirmacionOcultar(false);
    }
    const ocultarPublicarConfirmacion = () => {
        setConfirmacionPublicar(false);
    }
    const cerrar = () => {
        setModalError(false);
        setModalSucc(false);
        props.refresh();
    }
    const eliminar = (id, param) => {
        const info = {
            UniqueID: id
        }
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/delete/formulario', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                if (data.codigo == 200) {
                    setModalSucc(true);
                    setMensaje('Formulario eliminado del sistema exitosamente');
                    ocultarEliminarConfirmacion();
                    setContador(contador + 1);
                    props.refrescarInfo();
                } else {
                    setModalError(true);
                    ocultarEliminarConfirmacion();
                    setMensaje('ERROR');
                }
            });
    }
    const hideEditar = () => {
        setEditarFormulario(false);
    }
    const popover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">¡Listo!</Popover.Header>
            <Popover.Body>
                Enlace copiado exitosamente.
            </Popover.Body>
        </Popover>
    );
    const ocultarFormulario = (id, param) => {
        const info = {
            UniqueID: id
        }
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/ocultar/formulario', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                if (data.codigo == 200) {
                    setModalSucc(true);
                    setMensaje('Formulario oculto exitosamente');
                    ocultarOcultarConfirmacion();
                    setContador(contador + 1);
                    props.refrescarInfo();
                } else {
                    setModalError(true);
                    ocultarOcultarConfirmacion();
                    setMensaje('ERROR');
                }
            });
    }
    const publicarFormulario = (id, param) => {
        const info = {
            UniqueID: id
        }
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/publicar/formulario', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                if (data.codigo == 200) {
                    setModalSucc(true);
                    setMensaje('Formulario publicado exitosamente');
                    ocultarPublicarConfirmacion();
                    setContador(contador + 1);
                    props.refrescarInfo();
                } else {
                    setModalError(true);
                    ocultarPublicarConfirmacion();
                    setMensaje('ERROR');
                }
            });
    }
    const exportExcel = () => {
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/formulario/report/' + props.formulario.UniqueID, { cache: "no-cache" })
            .then((res) => {
                return res.json();
            })
            .then(data => {
                var excel_contenido = [];
                for (let i = 0; i < data.salida.length; i++) {
                    let actual = {};
                    actual['FECHA'] = data.salida[i].fecha;
                    actual['CORREO'] = data.salida[i].respuestas[0].texto;
                    actual['NOMBRES'] = data.salida[i].respuestas[1].texto;
                    actual['APELLIDOS'] = data.salida[i].respuestas[2].texto;
                    for (let j = 0; j < preguntas.length; j++) {
                        actual[preguntas[j].pregunta] = data.salida[i].respuestas[j + 3].texto;
                    }
                    excel_contenido.push(actual);
                }
                const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                const fileExtension = '.xlsx';
                const ws = XLSX.utils.json_to_sheet(excel_contenido);
                const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const info = new Blob([excelBuffer], { type: fileType });
                FileSaver.saveAs(info, Date.now() + fileExtension);
            });
    }

    const exportExcelCustomGroup = (custom) => {
        const index_finder = (pregunta) => pregunta.pregunta == custom;
        let indice_pregunta = preguntas.findIndex(index_finder) + 3;
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/formulario/report/' + props.formulario.UniqueID, { cache: "no-cache" })
            .then((res) => {
                return res.json();
            })
            .then(data => {
                var excel_contenido = [];
                for (let i = 0; i < data.salida.length; i++) {
                    let actual = {};
                    actual['FECHA'] = data.salida[i].fecha;
                    actual['CORREO'] = data.salida[i].respuestas[0].texto;
                    actual['NOMBRES'] = data.salida[i].respuestas[1].texto;
                    actual['APELLIDOS'] = data.salida[i].respuestas[2].texto;
                    for (let j = 0; j < preguntas.length; j++) {
                        actual[preguntas[j].pregunta] = data.salida[i].respuestas[j + 3].texto;
                    }
                    excel_contenido.push(actual);
                }
                var custom_group = [];
                var Sheets = [];
                var SheetNames = [];
                var custom_group_tabla = [];
                const sw = XLSX.utils.json_to_sheet(excel_contenido);
                Sheets['Datos'] = sw;
                SheetNames.push('Datos');
                for (let i = 0; i < data.salida.length; i++) {
                    if (custom_group.length == 0 || !custom_group.includes(data.salida[i].respuestas[indice_pregunta].texto.toUpperCase().trim().replace(/[^a-zA-Z0-9 ]/g, ''))) {
                        custom_group.push(data.salida[i].respuestas[indice_pregunta].texto.toUpperCase().trim().replace(/[^a-zA-Z0-9 ]/g, ''));
                    }
                }
                for (let i = 0; i < custom_group.length; i++) {
                    let arr_respuestas = data.salida.filter((actual) => actual.respuestas[indice_pregunta].texto.toUpperCase().trim().replace(/[^a-zA-Z0-9 ]/g, '') == custom_group[i]);
                    let responses = [];
                    for (let j = 0; j < arr_respuestas.length; j++) {
                        let actual = {};
                        actual['FECHA'] = arr_respuestas[j].fecha;
                        actual['CORREO'] = arr_respuestas[j].respuestas[0].texto;
                        actual['NOMBRES'] = arr_respuestas[j].respuestas[1].texto;
                        actual['APELLIDOS'] = arr_respuestas[j].respuestas[2].texto;
                        for (let k = 0; k < preguntas.length; k++) {
                            actual[preguntas[k].pregunta] = arr_respuestas[j].respuestas[k + 3].texto;
                        }
                        responses.push(actual);
                    }
                    const ws = XLSX.utils.json_to_sheet(responses);
                    Sheets[i.toString() + '. ' + custom_group[i].slice(0, 20).replace(/[^a-zA-Z0-9 ]/g, '') + '(' + responses.length + ')'] = ws;
                    SheetNames.push(i.toString() + '. ' + custom_group[i].slice(0, 20).replace(/[^a-zA-Z0-9 ]/g, '') + '(' + responses.length + ')');
                    let actual_tabla = {};
                    actual_tabla[custom] = custom_group[i];
                    actual_tabla['Respuestas'] = responses.length;
                    custom_group_tabla.push(actual_tabla)
                }
                custom_group_tabla.sort(function (a, b) { return b.Respuestas - a.Respuestas });
                let resumen = {};
                resumen[custom] = 'TOTAL';
                resumen['Respuestas'] = data.salida.length;
                custom_group_tabla.push(resumen);
                const ws = XLSX.utils.json_to_sheet(custom_group_tabla);
                Sheets['RESUMEN'] = ws;
                SheetNames.unshift('RESUMEN');
                const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                const fileExtension = '.xlsx';
                const wb = { Sheets, SheetNames };
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const info = new Blob([excelBuffer], { type: fileType });
                FileSaver.saveAs(info, Date.now() + fileExtension);
            });

    }
    const sendMails = (evt) => {
        props.lock();
        setCargando('block');
        setDetalle('none');
        setForm('none');
        setMensaje('Enviando correos');
        seConsideracion('Por favor espere...');
        setContador(contador + 1);
        evt.preventDefault();
        const data = new FormData();
        data.append('file', selectedFile);
        axios.post(process.env.REACT_APP_DEFAULT_URL+ "/api/files/upload", data, {
        }).then(res => {
            if (res.status == 200) {
                const info = { path: res.data.res, formularioUniqueID: props.formulario.UniqueID, subject: subject };
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(info)
                };
                fetch(process.env.REACT_APP_DEFAULT_URL + '/api/email/send/email/html/formList', requestOptions)
                    .then((res) => {
                        return res.json();
                    })
                    .then(data => {
                        if (data.codigo == 200) {
                            setMensaje('Correos enviados exitosamente');
                            seConsideracion(data.log);
                            setCargando('none');
                            setExito('block');
                            setError('none');
                            setForm('none');
                            setContador(contador + 1);
                            props.unlock();
                        } else {
                            setMensaje('Ocurrió un error al enviar los correos');
                            seConsideracion('Por favor recargue la página e intente nuevamente');
                            setCargando('none');
                            setError('block');
                            setExito('none');
                            setForm('none');
                            setContador(contador + 1);
                            props.unlock();
                        }
                    });
            } else {
                setMensaje('Ocurrió un error al enviar los correos');
                seConsideracion('Por favor recargue la página e intente nuevamente');
                setCargando('none');
                setError('block');
                setExito('none');
                setForm('none');
                setContador(contador + 1);
                props.unlock();
            }
            Array.from(document.querySelectorAll("input")).forEach(
                input => (input.value = "")
            );
            setSelectedFile(null);
            setSubject('');
            setContador(contador + 1);
        });
    }
    const onChange = (evt) => {
        switch (evt.target.name) {
            case "subject":
                setSubject(evt.target.value);
                break;
            case "body-email":
                setSelectedFile(evt.target.files[0]);
                break;
            case "titulo":
                setTitulo(evt.target.value);
                break;
            case "descripcion":
                setDescripcion(evt.target.value);
                break;
        }
        setContador(contador + 1);
    }
    const regresar = () => {
        setDetalle('block');
        setError('none');
        setExito('none');
        setCargando('none');
        setForm('none');
        setTitulo('');
        setContador(contador + 1);
    }
    const submit = (evt) => {
        evt.preventDefault();
        props.lock();
        setCargando('block');
        setDetalle('none');
        setForm('none');
        setMensaje('Sincronizando base de datos');
        seConsideracion('Por favor espere...');
        setContador(contador + 1);
        const info = { titulo: titulo, descripcion: descripcion, UniqueID: props.formulario.UniqueID };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/update/formulario', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                if (data.codigo == 200) {
                    setMensaje('Formulario editado exitosamente');
                    seConsideracion('');
                    setCargando('none');
                    setExito('block');
                    setError('none');
                    setForm('none');
                    setContador(contador + 1);
                    props.unlock();
                } else {
                    setMensaje('Ocurrió un error al sincronizar la db');
                    seConsideracion('Por favor recargue la página e intente nuevamente');
                    setCargando('none');
                    setError('block');
                    setExito('none');
                    setForm('none');
                    setContador(contador + 1);
                    props.unlock();
                }
            });
        props.refrescarInfo();
        setTitulo('');
        setContador(contador + 1);

    }
    return (
        <Container>
            <Row style={{ display: form }}>
                <Container>
                    <Row style={{ padding: '1%' }}>
                        <Alert variant="danger" o>
                            <Alert.Heading>Aclaración</Alert.Heading>
                            <p>
                                Podrá únicamente editar el título del formulario
                            </p>
                            <hr />
                            <p className="mb-0">
                                Una vez registrado el formulario, solo se podrá editar el título
                            </p>
                        </Alert>
                    </Row>

                    <Row>
                        <h1 style={{ textAlign: 'center' }}>Encabezado de formulario</h1>
                    </Row>
                    <Row>

                        <Form onSubmit={submit}>
                            <Container>
                                <Row>
                                    <Form.Group className="mb-3" controlId="titulo">
                                        <Form.Label>Título</Form.Label>
                                        <Form.Control type="text" required placeholder="Ingrese el título" name="titulo" value={titulo} onChange={onChange} />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group className="mb-3" controlId="titulo">
                                        <Form.Label>Descripción del formulario</Form.Label>
                                        <Form.Control as="textarea" rows={3} style={{ resize: 'none' }} required placeholder="Ingrese la descripción" name="descripcion" value={descripcion} onChange={onChange} />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Button variant="secondary" className="font-bold" type="submit">Guardar</Button>
                                </Row>
                            </Container>
                        </Form>
                    </Row>
                    <Row style={{ marginTop: '15px' }}>
                        <Button variant="outline-dark" className="font-bold" onClick={regresar}>Cancelar</Button>
                    </Row>
                </Container>
            </Row>
            <Row style={{ display: detalle }}>
                <Breadcrumb>
                    <Breadcrumb.Item active>{props.evento}</Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        Formularios
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        {props.formulario.titulo}
                    </Breadcrumb.Item>
                </Breadcrumb>
            </Row>
            <Row style={{ marginBottom: '75px', display: detalle }}>
                <DropdownButton
                    as={ButtonGroup}
                    title="Acciones"
                    id="bg-vertical-dropdown-2"
                    variant="dark"
                >
                    <Dropdown.Item onClick={props.back}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-backspace-fill" viewBox="0 0 16 16">
                            <path d="M15.683 3a2 2 0 0 0-2-2h-7.08a2 2 0 0 0-1.519.698L.241 7.35a1 1 0 0 0 0 1.302l4.843 5.65A2 2 0 0 0 6.603 15h7.08a2 2 0 0 0 2-2V3zM5.829 5.854a.5.5 0 1 1 .707-.708l2.147 2.147 2.146-2.147a.5.5 0 1 1 .707.708L9.39 8l2.146 2.146a.5.5 0 0 1-.707.708L8.683 8.707l-2.147 2.147a.5.5 0 0 1-.707-.708L7.976 8 5.829 5.854z" />
                        </svg>&nbsp;&nbsp;&nbsp;Atrás
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => {
                        setDetalle('none');
                        setError('none');
                        setExito('none');
                        setCargando('none');
                        setForm('block');
                        setTitulo(props.formulario.titulo);
                        setDescripcion(props.formulario.descripcion);
                        setContador(contador + 1);
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-fill" viewBox="0 0 16 16">
                            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                        </svg>&nbsp;&nbsp;&nbsp;Editar Formulario
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setContador(contador + 1)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                            <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                        </svg>&nbsp;&nbsp;&nbsp;Refrescar
                    </Dropdown.Item>
                    <Dropdown.Divider></Dropdown.Divider>
                    <Dropdown.Item onClick={() => { setMensaje('¿Está seguro@ de eliminar el formulario seleccionado?'); seConsideracion('Esta acción no se puede revertir'); setConfirmacionEliminar(true); }} style={{ color: 'red' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash-fill" viewBox="0 0 16 16">
                            <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                        </svg>&nbsp;&nbsp;&nbsp;Eliminar Formulario
                    </Dropdown.Item>
                </DropdownButton>
            </Row>
            <Row className='row-columnas font-bold'>
                <Col xs={12} md={12} lg={6} className='columna-info-form titulo' style={{ display: detalle }}>
                    <Row>
                        <h1>Fecha de creación</h1>
                    </Row>
                </Col>
                <Col xs={12} md={12} lg={6} className='columna-info-form' style={{ display: detalle }}>
                    <Row>
                        <Form.Group className="mb-3" controlId="Comentario">
                            <Form.Control as="textarea" disabled rows={3} style={{ resize: 'none', width: '100%' }} name="Comentario" value={props.formulario.fecha} />
                        </Form.Group>
                    </Row>
                </Col>
                <Col xs={12} md={12} lg={6} className='columna-info-form titulo' style={{ display: detalle }}>
                    <Row>
                        <h1>Título del formulario</h1>
                    </Row>
                </Col>
                <Col xs={12} md={12} lg={6} className='columna-info-form' style={{ display: detalle }} >
                    <Row>
                        <Form.Group className="mb-3" controlId="Comentario">
                            <Form.Control as="textarea" disabled rows={3} style={{ resize: 'none', width: '100%' }} name="Comentario" value={props.formulario.titulo} />
                        </Form.Group>
                    </Row>
                </Col>
                <Col xs={12} md={12} lg={6} className='columna-info-form titulo' style={{ display: detalle }}>
                    <Row>
                        <h1>Correlativo de formulario</h1>
                    </Row>
                </Col>
                <Col xs={12} md={12} lg={6} className='columna-info-form' style={{ display: detalle }}>
                    <Row>
                        <Form.Group className="mb-3" controlId="Comentario">
                            <Form.Control as="textarea" disabled rows={3} style={{ resize: 'none', width: '100%' }} name="Comentario" value={props.formulario.correlativo} />
                        </Form.Group>
                    </Row>
                </Col>
                <Col xs={12} md={12} lg={6} className='columna-info-form titulo' style={{ display: detalle }}>
                    <Row>
                        <h1>Respuestas de formulario</h1>
                    </Row>
                </Col>
                <Col xs={12} md={12} lg={6} className='columna-info-form' style={{ display: detalle }}>
                    <Row>
                        <Form.Group className="mb-3" controlId="Comentario">
                            <Form.Control as="textarea" disabled rows={3} style={{ resize: 'none', width: '100%' }} name="Comentario" value={respuestas + ' respuestas'} />
                        </Form.Group>
                    </Row>
                </Col>
                <Col xs={12} md={12} lg={6} className='columna-info-form titulo' style={{ display: detalle }}>
                    <Row>
                        <h1>Imagen promocional</h1>
                    </Row>
                </Col>
                <Col xs={12} md={12} lg={6} className='columna-info-form' style={{ display: detalle }}>
                    <Row>
                        <DropdownButton
                            as={ButtonGroup}
                            title="Opciones"
                            id="bg-vertical-dropdown-2"
                            variant="dark"
                        >
                            <Dropdown.Item href={props.formulario.promocional} target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16">
                                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                    <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                </svg>&nbsp;&nbsp;&nbsp;Ver imagen promocional
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => navigator.clipboard.writeText(props.formulario.promocional)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-clipboard-check" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                                    <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                                    <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                                </svg>&nbsp;&nbsp;&nbsp;Copiar enlace de imagen al portapapeles
                            </Dropdown.Item>
                        </DropdownButton>
                    </Row>
                </Col>
                <Col xs={12} md={12} lg={6} className='columna-info-form titulo' style={{ display: detalle }}>
                    <Row>
                        <h1>Estado del formulario</h1>
                    </Row>
                </Col>
                <Col xs={12} md={12} lg={6} className='columna-info-form' style={{ display: detalle }}>
                    <Row>
                        {props.formulario.disponible == 0 && <Button variant="danger" onClick={() => { setConfirmacionPublicar(true); setMensaje('¿Está segur@ de publicar este formulario?'); seConsideracion('Esta acción se puede revertir'); setContador(contador + 1); }}>No disponible</Button>}
                        {props.formulario.disponible == 1 && <Button variant="success" onClick={() => { setConfirmacionOcultar(true); setMensaje('¿Está segur@ de ocultar este formulario?'); seConsideracion('Esta acción se puede revertir'); setContador(contador + 1); }}>Disponible</Button>}
                    </Row>
                </Col>
                <Col xs={12} md={12} lg={6} className='columna-info-form titulo' style={{ display: detalle }}>
                    <Row>
                        <h1>Formulario</h1>
                    </Row>
                </Col>
                <Col xs={12} md={12} lg={6} className='columna-info-form' style={{ display: detalle }}>
                    <Row>
                        <DropdownButton
                            as={ButtonGroup}
                            title="Opciones"
                            id="bg-vertical-dropdown-2"
                            variant="dark"
                        >
                            <Dropdown.Item href={process.env.REACT_APP_SELF_URL + '/formularios/' + props.formulario.correlativo} target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-ui-checks" viewBox="0 0 16 16">
                                    <path d="M7 2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1zM2 1a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H2zm0 8a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2v-2a2 2 0 0 0-2-2H2zm.854-3.646a.5.5 0 0 1-.708 0l-1-1a.5.5 0 1 1 .708-.708l.646.647 1.646-1.647a.5.5 0 1 1 .708.708l-2 2zm0 8a.5.5 0 0 1-.708 0l-1-1a.5.5 0 0 1 .708-.708l.646.647 1.646-1.647a.5.5 0 0 1 .708.708l-2 2zM7 10.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1zm0-5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 8a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                                </svg>&nbsp;&nbsp;&nbsp;Ver formulario
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => navigator.clipboard.writeText(process.env.REACT_APP_SELF_URL + '/formularios/' + props.formulario.correlativo)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-clipboard-check" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                                    <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                                    <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                                </svg>&nbsp;&nbsp;&nbsp;Copiar enlace de formulario al portapapeles
                            </Dropdown.Item>
                            <Dropdown.Divider></Dropdown.Divider>
                            <Dropdown.Item href={process.env.REACT_APP_SELF_URL + '/formularios/respuestas/' + props.formulario.correlativo} target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-input-cursor-text" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M5 2a.5.5 0 0 1 .5-.5c.862 0 1.573.287 2.06.566.174.099.321.198.44.286.119-.088.266-.187.44-.286A4.165 4.165 0 0 1 10.5 1.5a.5.5 0 0 1 0 1c-.638 0-1.177.213-1.564.434a3.49 3.49 0 0 0-.436.294V7.5H9a.5.5 0 0 1 0 1h-.5v4.272c.1.08.248.187.436.294.387.221.926.434 1.564.434a.5.5 0 0 1 0 1 4.165 4.165 0 0 1-2.06-.566A4.561 4.561 0 0 1 8 13.65a4.561 4.561 0 0 1-.44.285 4.165 4.165 0 0 1-2.06.566.5.5 0 0 1 0-1c.638 0 1.177-.213 1.564-.434.188-.107.335-.214.436-.294V8.5H7a.5.5 0 0 1 0-1h.5V3.228a3.49 3.49 0 0 0-.436-.294A3.166 3.166 0 0 0 5.5 2.5.5.5 0 0 1 5 2z" />
                                    <path d="M10 5h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-4v1h4a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-4v1zM6 5V4H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v-1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h4z" />
                                </svg>&nbsp;&nbsp;&nbsp;Ver respuestas
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => navigator.clipboard.writeText(process.env.REACT_APP_SELF_URL + '/formularios/respuestas/' + props.formulario.correlativo)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-clipboard-check" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                                    <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                                    <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                                </svg>&nbsp;&nbsp;&nbsp;Copiar enlace de respuestas al portapapeles
                            </Dropdown.Item>
                        </DropdownButton>
                    </Row>
                </Col>
                <Col xs={12} md={12} lg={6} className='columna-info-form titulo' style={{ display: detalle }}>
                    <Row>
                        <h1>Reportería</h1>
                    </Row>
                </Col>
                <Col xs={12} md={12} lg={6} className='columna-info-form' style={{ display: detalle }}>
                    <Row>
                        <DropdownButton
                            as={ButtonGroup}
                            title="Reportes"
                            id="bg-vertical-dropdown-2"
                            variant="dark"
                        >
                            <Dropdown.Item onClick={exportExcel}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-file-earmark-spreadsheet-fill" viewBox="0 0 16 16">
                                    <path d="M6 12v-2h3v2H6z" />
                                    <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM3 9h10v1h-3v2h3v1h-3v2H9v-2H6v2H5v-2H3v-1h2v-2H3V9z" />
                                </svg>&nbsp;&nbsp;&nbsp;Reporte general
                            </Dropdown.Item>
                            <Dropdown.Divider></Dropdown.Divider>
                            {
                                preguntas.map((pregunta) =>
                                    <Dropdown.Item onClick={() => { exportExcelCustomGroup(pregunta.pregunta) }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-file-earmark-excel-fill" viewBox="0 0 16 16">
                                            <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM5.884 6.68 8 9.219l2.116-2.54a.5.5 0 1 1 .768.641L8.651 10l2.233 2.68a.5.5 0 0 1-.768.64L8 10.781l-2.116 2.54a.5.5 0 0 1-.768-.641L7.349 10 5.116 7.32a.5.5 0 1 1 .768-.64z" />
                                        </svg>&nbsp;&nbsp;&nbsp;Reporte agrupado por respuestas a "{pregunta.pregunta}"
                                    </Dropdown.Item>
                                )
                            }
                        </DropdownButton>
                    </Row>
                </Col>
                <Col xs={12} md={12} lg={6} className='columna-info-form titulo' style={{ display: detalle }}>
                    <Row>
                        <h1>Envío de correos masivos</h1>
                    </Row>
                </Col>
                <Col xs={12} md={12} lg={6} className='columna-info-form' style={{ display: detalle }}>
                    <Row>
                        <Form onSubmit={sendMails}>
                            <Form.Group className="mb-3" controlId="sujeto">
                                <Form.Label>Asunto de email</Form.Label>
                                <Form.Control type="text" required placeholder="Ingrese el asunto del email" name="subject" onChange={onChange} value={subject} />
                            </Form.Group>
                            <Form.Group controlId="formFileMultiple" className="mb-3">
                                <Form.Label>Archivo con HTML de correo electrónico</Form.Label>
                                <Form.Control required type="file" onChange={onChange} name="body-email" />
                            </Form.Group>
                            <Button type="submit" variant="dark">Enviar correos</Button>
                        </Form>
                    </Row>
                </Col>
            </Row >
            <Row style={{ display: cargando }}>
                <Button variant="dark" disabled>
                    <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                    &nbsp;&nbsp;&nbsp;{mensaje}
                    <p>{consideracion}</p>
                </Button>
            </Row>
            <Row style={{ display: exito }}>
                <Alert variant="success">
                    <Alert.Heading>Operación realizada con éxito</Alert.Heading>
                    <p>
                        {mensaje}
                    </p>
                    <p className="mb-0">
                        {consideracion}
                    </p>
                    <hr />
                    <Button variant="success" onClick={regresar}>Ok</Button>
                </Alert>
            </Row>
            <Row style={{ display: error }}>
                <Alert variant="danger">
                    <Alert.Heading>Ocurrió un error</Alert.Heading>
                    <p>
                        {mensaje}
                    </p>
                    <p className="mb-0">
                        {consideracion}
                    </p>
                    <hr />
                    <Button variant="danger" onClick={regresar}>Ok</Button>
                </Alert>
            </Row>
            <ModalEditarFormulario formulario={props.formulario} preguntas={preguntas} show={editarFormulario} onHide={hideEditar} refresh={() => { setContador(contador + 1); props.refrescarInfo(); }} />
            <Confirmacion
                show={confirmacionEliminar}
                onHide={ocultarEliminarConfirmacion}
                accion={eliminar}
                mensaje={mensaje}
                uniqueid={props.formulario.UniqueID}
                consideracion={consideracion}
                param=''
            />
            <Confirmacion
                show={confirmacionOcultar}
                onHide={ocultarOcultarConfirmacion}
                accion={ocultarFormulario}
                mensaje={mensaje}
                uniqueid={props.formulario.UniqueID}
                consideracion={consideracion}
                param=''
            />
            <Confirmacion
                show={confirmacionPublicar}
                onHide={ocultarPublicarConfirmacion}
                accion={publicarFormulario}
                mensaje={mensaje}
                uniqueid={props.formulario.UniqueID}
                consideracion={consideracion}
                param=''
            />
            <ErrorModal
                color={"#E0E0E2"}
                show={modalError}
                onHide={cerrar}
                mensaje={mensaje}
                consideracion={''}
            />
            <SuccessModal
                color={"#E0E0E2"}
                show={modalSucc}
                onHide={cerrar}
                mensaje={mensaje}
                consideracion={''}
                enlace={''}
                llevaenlace={false}
            />

        </Container >
    )
}

export default DetalleFormulario