import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Form, Alert, Button, Spinner } from "react-bootstrap";
//import DEFAULT_URL from '../../../../../api/url';
//import { SELF_URL } from '../../../../../api/url';
const Descuento = (props) => {
    const [contador, setContador] = useState(0);
    const [formulario, setFormulario] = useState('block');
    const [cargando, setCargando] = useState('none');
    const [exito, setExito] = useState('none');
    const [error, setError] = useState('none');
    const [mensaje, setMensaje] = useState('');
    const [descuento, setDescuento] = useState(0);
    const [comentario, setComentario] = useState('');
    const [hash, setHash] = useState('');
    useEffect(() => {

    }, [contador]);
    const atras = () => {
        setFormulario('block');
        setCargando('none');
        setExito('none');
        setError('none');
        setContador(contador + 1);
    }
    const onChange = (evt) => {
        switch (evt.target.name) {
            case "descuento":
                setDescuento(evt.target.value);
                break;
            case "comentario":
                setComentario(evt.target.value);
                break;
        }
        setContador(contador + 1);
    }
    const cargar = () => {
        setFormulario('none');
        setCargando('block');
        setExito('none');
        setError('none');
        setMensaje('Procesando información...')
        setContador(contador + 1);
    }
    const operacionExito = (log, hash) => {
        setFormulario('none');
        setCargando('none');
        setExito('block');
        setError('none');
        setMensaje(log);
        setHash(hash);
        setContador(contador + 1);
    }
    const operacionError = (log) => {
        setFormulario('none');
        setCargando('none');
        setExito('none');
        setError('block');
        setMensaje(log)
        setContador(contador + 1);
    }
    const submit = (evt) => {
        evt.preventDefault();
        cargar();
        const info = { evento: props.UniqueID, asesor: props.asesor, descuento: descuento, comentario: comentario };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/descuentos/create', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                if (data.codigo == 200) {
                    operacionExito(data.ret, data.hash);
                } else {
                    operacionError(data.ret);
                }
                Array.from(document.querySelectorAll("input")).forEach(
                    input => (input.value = "")
                );
                setDescuento(0);
                setComentario('');
                setContador(contador + 1);
            });
    }
    return (
        <Container>
            <Row style={{ display: formulario }}>
                <h4>Generar cupón de descuento</h4>
                <Col xs={12} md={12} lg={12}>
                    <Form onSubmit={submit}>
                        <Form.Group className="mb-3" controlId="descuento">
                            <Form.Label>Monto del descuento</Form.Label>
                            <Form.Control type="text" required placeholder="Ingrese la cantidad a descontar del precio de inscripción" name="descuento" onChange={onChange} value={descuento} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="comentario">
                            <Form.Label>Comentario (opcional)</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="Adjunte un breve comentario para el cliente sobre su descuento" style={{ resize: 'none', width: '100%' }} onChange={onChange} name="comentario" value={comentario} />
                        </Form.Group>
                        <Button type="submit" variant="dark">Generar cupón de descuento</Button>
                    </Form>
                </Col>
            </Row>
            <Row style={{ display: cargando }}>
                <Alert variant="secondary">
                    <Alert.Heading><Spinner animation="grow" />&nbsp;&nbsp;&nbsp;Procesando solicitud</Alert.Heading>
                    <p>
                        {mensaje}
                    </p>
                    <hr />
                    <p className="mb-0">
                        Por favor espere...
                    </p>
                </Alert>
            </Row>
            <Row style={{ display: exito }}>
                <Alert variant="success" onClose={() => { setHash(''); setContador(contador + 1); atras(); }} dismissible>
                    <Alert.Heading>Operación realizada con éxito</Alert.Heading>
                    <p>
                        {mensaje}
                    </p>
                    {
                        /*
                    <hr />
                    <div className="d-flex justify-content-end">
                        <Button variant="outline-success" style={{ marginRight: '25px' }} onClick={() => { navigator.clipboard.writeText(process.env.REACT_APP_SELF_URL + "/inscripciones/" + props.hash_evt + '/' + props.hash_asesor + '/' + hash); }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard-check-fill" viewBox="0 0 16 16">
                                <path d="M6.5 0A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3Zm3 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3Z" />
                                <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1A2.5 2.5 0 0 1 9.5 5h-3A2.5 2.5 0 0 1 4 2.5v-1Zm6.854 7.354-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708.708Z" />
                            </svg>&nbsp;&nbsp;&nbsp;Copiar link en portapapeles
                        </Button>
                        <Button variant="outline-success" href={process.env.REACT_APP_SELF_URL + "/inscripciones/" + props.hash_evt + '/' + props.hash_asesor + '/' + hash} target="_blank" style={{ color: 'black' }}>
                            Ir a formulario con cupón
                        </Button>
                    </div>
                    */
                    }
                </Alert>
            </Row>
            <Row style={{ display: error }}>
                <Alert variant="danger" onClose={() => { setHash(''); setContador(contador + 1); atras(); }} dismissible>
                    <Alert.Heading>Error</Alert.Heading>
                    <p>
                        {mensaje}
                    </p>
                </Alert>
            </Row>
        </Container>
    )
}

export default Descuento