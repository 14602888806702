import React, { useEffect, useState } from "react";
//import { DEFAULT_URL, EBG_URL, SELF_URL} from '../../../api/url';
import {
  Row,
  Col,
  ListGroup,
  Badge,
  Container,
  Popover,
  OverlayTrigger,
} from "react-bootstrap";
import { SuccessModal } from "../../Utils/SuccessModal/SuccessModal";
import { ErrorModal } from "../../Utils/ErrorModal/ErrorModal";
import { ModalCargando } from "../../Utils/Modal-Cargando/ModalCargando";
import { Confirmacion } from "../../Utils/ConfirmationModal/Confirmacion";
function DetalleInscripcion(props) {
  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">¡Listo!</Popover.Header>
      <Popover.Body>Enlace copiado exitosamente.</Popover.Body>
    </Popover>
  );
  const [inscripciones, setInscripciones] = useState([]);
  const [contador, setContador] = useState(0);
  const [mensajeCargando, setMensajeCargando] = useState("");
  const [modalCargando, setModalCargando] = useState(false);
  const [modalSucc, setModalSucc] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [consideracion, setConsideracion] = useState("");
  const [modalError, setModalError] = useState(false);
  const [confirmacionEliminar, setConfirmacionEliminar] = useState(false);
  const [id_actual, setId_actual] = useState("");
  const [edit, setEdit] = useState(0);
  useEffect(() => {}, [edit]);

  useEffect(async () => {
    async function fetchData() {
      fetch(
        process.env.REACT_APP_DEFAULT_URL +
          "/api/read/asesor/inscripcion/procesado/" +
          props.asesor.UniqueID +
          "/" +
          props.evento.UniqueID,
        { cache: "no-cache" }
      )
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          let tmp = data.rows;
          tmp.map((actual) => {
            actual.enlacePDF = "";
          });
          setInscripciones(tmp);
        });
    }
    fetchData();
  }, [contador]);
  const eliminarInscripcion = (id) => {
    setConfirmacionEliminar(false);
    setMensajeCargando("Eliminado inscripción");
    setConsideracion("Por favor espere...");
    setModalCargando(true);
    const info = { UniqueID: id };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(info),
    };
    fetch(
      process.env.REACT_APP_DEFAULT_URL + "/api/delete/inscripcion",
      requestOptions
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setModalCargando(false);
        setConsideracion("");
        if (data.codigo == 200) {
          setMensaje("Inscripción eliminada exitosamente");
          setConsideracion("Esta acción no se puede revertir");
          setModalSucc(true);
        } else {
          setMensaje(data.respuesta);
          setModalError(true);
        }
      });
  };
  const goToPrint = (id, indice) => {
    setMensajeCargando("Generando archivo PDF");
    setConsideracion("Por favor espere...");
    setModalCargando(true);
    fetch(process.env.REACT_APP_DEFAULT_URL + "/api/print/sheet/" + id, {
      cache: "no-cache",
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        let tmp = inscripciones;
        tmp[indice].enlacePDF = data.enlace_S3;
        setInscripciones(tmp);
        setModalCargando(false);
        setEdit(edit + 1);
      });
  };
  return (
    <Container>
      <Row style={{ marginBottom: "25px" }}>
        <Col xs={1} md={1} lg={1}>
          <div
            style={{ color: "black", cursor: "pointer" }}
            data-toggle="tooltip"
            data-placement="top"
            title="Refrescar"
            onClick={() => setContador(contador + 1)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              className="bi bi-arrow-clockwise"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
              />
              <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
            </svg>
          </div>
        </Col>
        <Col xs={1} md={1} lg={1}>
          <OverlayTrigger
            trigger="click"
            placement="left"
            overlay={popover}
            rootClose
          >
            <div
              data-toggle="tooltip"
              data-placement="top"
              title="Copiar Link de formulario"
              style={{ cursor: "pointer" }}
              onClick={() =>
                navigator.clipboard.writeText(
                  process.env.REACT_APP_EBG_URL +
                    "/inscripcion/" +
                    props.evento.hash +
                    "/" +
                    props.asesor.hash
                )
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                className="bi bi-clipboard-check"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"
                />
                <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
              </svg>
            </div>
          </OverlayTrigger>
        </Col>
        <Col xs={1} md={1} lg={1}>
          <a
            href={
              process.env.REACT_APP_EBG_URL +
              "/inscripcion/" +
              props.evento.hash +
              "/" +
              props.asesor.hash
            }
            target="_blank"
            data-toggle="tooltip"
            data-placement="top"
            title="Ir a formulario de inscripción"
            style={{ cursor: "pointer", color: "black" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              className="bi bi-eye-fill"
              viewBox="0 0 16 16"
            >
              <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
              <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
            </svg>
            <i className="bi bi-cloud-arrow-down-fill"></i>
          </a>
        </Col>
        <Col xs={1} md={1} lg={1}>
          <a
            href={
              process.env.REACT_APP_EBG_URL +
              "/cortesia-inscripcion/" +
              props.evento.hash +
              "/" +
              props.asesor.hash
            }
            target="_blank"
            data-toggle="tooltip"
            data-placement="top"
            title="Ir a formulario de inscripción por cortesía"
            style={{ cursor: "pointer", color: "black" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              className="bi bi-gift"
              viewBox="0 0 16 16"
            >
              <path d="M3 2.5a2.5 2.5 0 0 1 5 0 2.5 2.5 0 0 1 5 0v.006c0 .07 0 .27-.038.494H15a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1v7.5a1.5 1.5 0 0 1-1.5 1.5h-11A1.5 1.5 0 0 1 1 14.5V7a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h2.038A2.968 2.968 0 0 1 3 2.506V2.5zm1.068.5H7v-.5a1.5 1.5 0 1 0-3 0c0 .085.002.274.045.43a.522.522 0 0 0 .023.07zM9 3h2.932a.56.56 0 0 0 .023-.07c.043-.156.045-.345.045-.43a1.5 1.5 0 0 0-3 0V3zM1 4v2h6V4H1zm8 0v2h6V4H9zm5 3H9v8h4.5a.5.5 0 0 0 .5-.5V7zm-7 8V7H2v7.5a.5.5 0 0 0 .5.5H7z" />
            </svg>
          </a>
        </Col>
      </Row>
      <Row>
        <ListGroup as="ol" numbered>
          {inscripciones.map((inscripcion, index) => (
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start"
            >
              <div className="ms-2 me-auto">
                <div className="fw-bold">{inscripcion.empresa}</div>
                {inscripcion.fecha}
              </div>
              {inscripcion.inscritos === 1 && (
                <Badge bg="success" style={{ marginRight: "15px" }}>
                  {inscripcion.inscritos} inscrito
                </Badge>
              )}
              {inscripcion.inscritos > 1 && (
                <Badge bg="success" style={{ marginRight: "15px" }}>
                  {inscripcion.inscritos} inscritos
                </Badge>
              )}
              {inscripcion.enlacePDF != "" && (
                <Badge
                  bg="dark"
                  style={{ marginRight: "15px", cursor: "pointer" }}
                  onClick={() => {
                    window.open(inscripcion.enlacePDF, "_blank");
                  }}
                >
                  Click para ver PDF
                </Badge>
              )}
              {inscripcion.estado == "1" && (
                <Badge bg="danger" style={{ marginRight: "15px" }}>
                  Pendiente de autorización
                </Badge>
              )}
              {inscripcion.estado == "2" && (
                <Badge bg="primary" style={{ marginRight: "15px" }}>
                  Autorizada
                </Badge>
              )}
              {(props.permiso == "4" || props.permiso == "6") && (
                <a
                  href={
                    process.env.REACT_APP_SELF_URL +
                    "/inscripcion/seguimiento/" +
                    inscripcion.Id
                  }
                  target="_blank"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Dar Seguimiento"
                  style={{
                    cursor: "pointer",
                    color: "black",
                    marginRight: "15px",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-cash-coin"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0z"
                    />
                    <path d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1h-.003zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195l.054.012z" />
                    <path d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083c.058-.344.145-.678.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1H1z" />
                    <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 5.982 5.982 0 0 1 3.13-1.567z" />
                  </svg>
                </a>
              )}
              <div
                data-toggle="tooltip"
                data-placement="top"
                title="Generar PDF"
                style={{ cursor: "pointer", color: "black" }}
                onClick={() => {
                  goToPrint(inscripcion.Id, index);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-printer-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" />
                  <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                </svg>
              </div>
              <a
                href={
                  process.env.REACT_APP_SELF_URL +
                  "/inscripcion/public/ver/" +
                  inscripcion.Id
                }
                target="_blank"
                data-toggle="tooltip"
                data-placement="top"
                title="Ver Inscripción"
                style={{
                  cursor: "pointer",
                  color: "black",
                  paddingLeft: "10px",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-eye-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                  <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                </svg>
                <i className="bi bi-cloud-arrow-down-fill"></i>
              </a>
              <OverlayTrigger
                trigger="click"
                placement="left"
                overlay={popover}
                rootClose
              >
                <div
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Copiar Enlace De Inscripción"
                  style={{ cursor: "pointer", paddingLeft: "10px" }}
                  onClick={() =>
                    navigator.clipboard.writeText(
                      process.env.REACT_APP_SELF_URL +
                        "/inscripcion/public/ver/" +
                        inscripcion.Id
                    )
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-clipboard-check"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"
                    />
                    <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                    <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                  </svg>
                </div>
              </OverlayTrigger>
              {props.permiso == "4" && (
                <div
                  data-toggle="tooltip"
                  data-placement="top"
                  title={"Eliminar Inscripción (" + inscripcion.Id + ")"}
                  onClick={() => {
                    setId_actual(inscripcion.Id);
                    setMensaje(
                      "¿Está segur@ de eliminar la inscripción seleccionada?"
                    );
                    setConsideracion("Esta acción no se puede revertir");
                    setConfirmacionEliminar(true);
                    setContador(contador + 1);
                  }}
                  style={{ cursor: "pointer", paddingLeft: "10px" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="bi bi-trash-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                  </svg>
                </div>
              )}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Row>
      <SuccessModal
        color={"#b5bad0"}
        show={modalSucc}
        onHide={() => {
          setModalSucc(false);
          setContador(contador + 1);
          props.refrescar();
        }}
        mensaje={mensaje}
        consideracion={consideracion}
        enlace={""}
        llevaenlace={false}
      />
      <ModalCargando
        color={"#b5bad0"}
        show={modalCargando}
        onHide={() => {
          setModalCargando(false);
          setContador(contador + 1);
        }}
        mensaje={mensajeCargando}
      />
      <ErrorModal
        color={"#b5bad0"}
        show={modalError}
        onHide={() => {
          setModalError(false);
          setContador(contador + 1);
          props.refrescar();
        }}
        mensaje={mensaje}
        consideracion={consideracion}
      />
      <Confirmacion
        show={confirmacionEliminar}
        onHide={() => {
          setConfirmacionEliminar(false);
        }}
        accion={eliminarInscripcion}
        mensaje={mensaje}
        uniqueid={id_actual}
        consideracion={consideracion}
        param=""
      />
    </Container>
  );
}

export default DetalleInscripcion;
