import React, { useState, useEffect } from "react";
import {
  Alert,
  Breadcrumb,
  ListGroup,
  Offcanvas,
  Spinner,
  Nav,
  Navbar,
  NavDropdown,
  Row,
  Button,
  Col,
  Tab,
  Container,
  Card,
  Accordion,
  Dropdown,
} from "react-bootstrap";
import "./Admin.css";
import { CrudEvento } from "../Crud-Evento/CrudEvento";
//import DEFAULT_URL from '../../api/url';
import useLogout from "../../hooks/useLogout";
import EventoDashboard from "../Eventos/Evento-Detalle/EventosDashBoard";
import InHouse from "../in-house/InHouse";
import Reporterias from "../Eventos/Evento-Detalle/Dashboard/Reporteria/Reporterias";
import Facilitadores from "../Facilitador/Facilitadores";
import Biblioteca from "../in-house/Biblioteca";
const Admin = () => {
  const [nombres, setNombres] = useState("");
  const [apellidos, setApellidos] = useState("");
  const [email, setEmail] = useState("");
  const [contador, setContador] = useState(0);
  const logout = useLogout();
  const [agenda, setAgenda] = useState([]);
  const [cargando, setCargando] = useState("none");
  const [detalleEventos, setDetalleEventos] = useState("none");
  const [mes, setMes] = useState("");
  const [year, setYear] = useState("");
  const [eventos, setEventos] = useState([]);
  const [displayMensajeBienvenida, setDisplayMensajeBienvenida] =
    useState("none");
  const [tipo, setTipo] = useState(0);
  const [editing, setEditing] = useState(0);
  const [eventoDetalle, setEventoDetalle] = useState("none");
  const [todosEventos, setTodosEventos] = useState("block");
  const [formulario, setFormulario] = useState("none");
  const [seguimientoInHouse, setSeguimientoInHouse] = useState("none");
  const [congresos, setCongresos] = useState([]);
  const [tipoEventos, setTipoEventos] = useState("");
  const [inHouse, setInhouse] = useState([]);
  const [patrocinados, setPatrocinados] = useState([]);
  const [foros, setForos] = useState([]);
  const [eLearning, setElearning] = useState([]);
  const [uniqueID, setUniqueID] = useState(0);
  const [hash, setHash] = useState("");
  const [reportes, setReportes] = useState("none");
  const [biblioteca, setBiblioteca] = useState("none");
  const [facilitadores, setFacilitadores] = useState("none");
  const [sesiones, setSesiones] = useState([]);
  useEffect(async () => {
    async function fetchData() {
      setCargando("block");
      setDetalleEventos("none");
      setDisplayMensajeBienvenida("none");
      fetch(process.env.REACT_APP_DEFAULT_URL + "/api/auth/userinfo", {
        cache: "no-cache",
        credentials: "include",
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setNombres(data.nombres);
          setApellidos(data.apellidos);
          setEmail(data.email);
          setTipo(data.tipo);
          setUniqueID(data.UniqueID);
          setHash(data.hash);
          fetch(
            process.env.REACT_APP_DEFAULT_URL +
              "/api/read/evento/by-year/agenda",
            { cache: "no-cache" }
          )
            .then((res) => {
              return res.json();
            })
            .then((data) => {
              setAgenda(data.eventos);
              setCargando("none");
            });
          fetch(
            process.env.REACT_APP_DEFAULT_URL +
              "/api/read/evento/by-year/congresos",
            { cache: "no-cache" }
          )
            .then((res) => {
              return res.json();
            })
            .then((data) => {
              setCongresos(data.eventos);
              setCargando("none");
            });
          fetch(
            process.env.REACT_APP_DEFAULT_URL +
              "/api/read/evento/by-year/in-house",
            { cache: "no-cache" }
          )
            .then((res) => {
              return res.json();
            })
            .then((data) => {
              setInhouse(data.eventos);
              setCargando("none");
            });
          fetch(
            process.env.REACT_APP_DEFAULT_URL +
              "/api/read/evento/by-year/patrocinados",
            { cache: "no-cache" }
          )
            .then((res) => {
              return res.json();
            })
            .then((data) => {
              setPatrocinados(data.eventos);
              setCargando("none");
            });
          fetch(
            process.env.REACT_APP_DEFAULT_URL +
              "/api/read/evento/by-year/foros-centroamericanos",
            { cache: "no-cache" }
          )
            .then((res) => {
              return res.json();
            })
            .then((data) => {
              setForos(data.eventos);
              setCargando("none");
            });
          fetch(
            process.env.REACT_APP_DEFAULT_URL +
              "/api/read/evento/by-year/e-learning",
            { cache: "no-cache" }
          )
            .then((res) => {
              return res.json();
            })
            .then((data) => {
              setElearning(data.eventos);
              setCargando("none");
            });
          fetch(
            process.env.REACT_APP_DEFAULT_URL + "/api/sesion/sesiones-de-hoy",
            { cache: "no-cache" }
          )
            .then((res) => {
              return res.json();
            })
            .then((data) => {
              setSesiones(data.sesiones);
              setCargando("none");
            });
        });
      if (eventos.length == 0) {
        setDetalleEventos("none");
        setDisplayMensajeBienvenida("block");
      } else {
        setDisplayMensajeBienvenida("none");
        setDetalleEventos("block");
      }
    }
    fetchData();
  }, [contador]);
  useEffect(() => {}, [editing]);

  const singOut = async () => {
    await logout();
    window.location.replace("/");
  };
  const loadEvents = (month, anio, tipo) => {
    setCargando("block");
    setDetalleEventos("none");
    setDisplayMensajeBienvenida("none");
    setFormulario("none");
    setReportes("none");
    setSeguimientoInHouse("none");
    setBiblioteca("none");
    setFacilitadores("none");
    fetch(
      process.env.REACT_APP_DEFAULT_URL +
        "/api/read/evento/by-year/by-month/" +
        anio +
        "/" +
        month +
        "/" +
        tipo,
      { cache: "no-cache" }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setEventos(data.rows);
        setCargando("none");
        setDetalleEventos("block");
        setDisplayMensajeBienvenida("none");
        setTodosEventos("block");
        setEventoDetalle("none");
        setEditing(editing + 1);
      });
  };

  const refreshEvents = () => {
    if (mes != "" && year != "" && tipoEventos != "") {
      setCargando("block");
      setDetalleEventos("none");
      setDisplayMensajeBienvenida("none");
      setFormulario("none");
      setReportes("none");
      setSeguimientoInHouse("none");
      setBiblioteca("none");
      setFacilitadores("none");
      fetch(
        process.env.REACT_APP_DEFAULT_URL +
          "/api/read/evento/by-year/by-month/" +
          year +
          "/" +
          mes +
          "/" +
          tipoEventos,
        { cache: "no-cache" }
      )
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setEventos(data.rows);
          setCargando("none");
          setDetalleEventos("block");
          setDisplayMensajeBienvenida("none");
          setEditing(editing + 1);
        });
    }
  };
  const showDetailEvent = () => {
    setTodosEventos("none");
    setEventoDetalle("block");
    setFormulario("none");
    setReportes("none");
    setSeguimientoInHouse("none");
    setBiblioteca("none");
    setFacilitadores("none");
    setDisplayMensajeBienvenida("none");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setEditing(editing + 1);
  };
  const showAllEvents = () => {
    setTodosEventos("block");
    setEventoDetalle("none");
    setFormulario("none");
    setSeguimientoInHouse("none");
    setBiblioteca("none");
    setFacilitadores("none");
    setDisplayMensajeBienvenida("none");
    setReportes("none");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setEditing(editing + 1);
  };
  const showForm = () => {
    setTodosEventos("none");
    setEventoDetalle("none");
    setFormulario("block");
    setSeguimientoInHouse("none");
    setBiblioteca("none");
    setFacilitadores("none");
    setDisplayMensajeBienvenida("none");
    setReportes("none");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setEditing(editing + 1);
  };
  const showReportes = () => {
    setTodosEventos("none");
    setEventoDetalle("none");
    setFormulario("none");
    setSeguimientoInHouse("none");
    setBiblioteca("none");
    setFacilitadores("none");
    setDisplayMensajeBienvenida("none");
    setReportes("block");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setEditing(editing + 1);
  };
  const eventochange = (evt) => {
    setEditing(editing + 1);
    var id = evt.target.name.split("-")[1];
    var name = evt.target.name.split("-")[0];
    var new_arr = eventos;
    const index_finder = (evento) => evento.UniqueID == id;
    switch (name) {
      case "Nombre":
        new_arr[new_arr.findIndex(index_finder)].Nombre = evt.target.value;
        break;
      case "Fecha_Inicio":
        new_arr[new_arr.findIndex(index_finder)].Fecha_Inicia =
          evt.target.value;
        break;
      case "Hora":
        new_arr[new_arr.findIndex(index_finder)].Hora = evt.target.value;
        break;
      case "Minimo":
        new_arr[new_arr.findIndex(index_finder)].Minimo = evt.target.value;
        break;
      case "Descripcion":
        new_arr[new_arr.findIndex(index_finder)].Descripcion = evt.target.value;
        break;
      case "Ingresos":
        new_arr[new_arr.findIndex(index_finder)].ingresos = evt.target.value;
        break;
      case "Egresos":
        new_arr[new_arr.findIndex(index_finder)].egresos = evt.target.value;
        break;
      case "Resultado":
        new_arr[new_arr.findIndex(index_finder)].resultado = evt.target.value;
        break;
      case "tipo":
        new_arr[new_arr.findIndex(index_finder)].tipo_id = evt.target.value;
        break;
      case "estado":
        new_arr[new_arr.findIndex(index_finder)].estado_id = evt.target.value;
        break;
      case "modalidad":
        new_arr[new_arr.findIndex(index_finder)].modalidad_id =
          evt.target.value;
        break;
      case "modalidad":
        new_arr[new_arr.findIndex(index_finder)].modalidad_id =
          evt.target.value;
        break;
      case "dias":
        new_arr[new_arr.findIndex(index_finder)].dias = evt.target.value;
        break;
      case "duracion":
        new_arr[new_arr.findIndex(index_finder)].duracion = evt.target.value;
        break;
      case "sesiones":
        new_arr[new_arr.findIndex(index_finder)].sesiones = evt.target.value;
        break;
      case "precio_agremiados":
        new_arr[new_arr.findIndex(index_finder)].costo_agremiados =
          evt.target.value;
        break;
      case "precio_no_agremiados":
        new_arr[new_arr.findIndex(index_finder)].costo_no_agremiados =
          evt.target.value;
        break;
      case "imagen":
        new_arr[new_arr.findIndex(index_finder)].imagen = evt.target.value;
        break;
      case "flier":
        new_arr[new_arr.findIndex(index_finder)].flier = evt.target.value;
        break;
      case "Fecha_Fin":
        new_arr[new_arr.findIndex(index_finder)].Fecha_Finaliza =
          evt.target.value;
        break;
      default:
        break;
    }
    setEventos(new_arr);
    setEditing(editing + 1);
  };
  const edit = (field_name, value) => {
    var id = field_name.split("-")[1];
    var name = field_name.split("-")[0];
    var new_arr = eventos;
    const index_finder = (evento) => evento.UniqueID == id;
    switch (name) {
      case "imagen":
        new_arr[new_arr.findIndex(index_finder)].imagen = value;
        break;
      case "flier":
        new_arr[new_arr.findIndex(index_finder)].flier = value;
        break;
    }
    setEventos(new_arr);
    setEditing(editing + 1);
  };
  const goToAgendaActual = () => {
    agenda.map((actual) => {
      actual.meses.map((curr) => {
        if (curr.actual === 1) {
          setMes(curr.mes);
          setYear(curr.year);
          setTipoEventos("Agenda");
          setEditing(editing + 1);
          loadEvents(curr.mes, curr.year, "Agenda");
        }
      });
    });
    setEditing(editing + 1);
  };
  const goToInHouse = () => {
    setTodosEventos("none");
    setEventoDetalle("none");
    setFormulario("none");
    setSeguimientoInHouse("block");
    setDisplayMensajeBienvenida("none");
    setReportes("none");
    setBiblioteca("none");
    setFacilitadores("none");
  };
  const goToBiblioteca = () => {
    setTodosEventos("none");
    setEventoDetalle("none");
    setFormulario("none");
    setSeguimientoInHouse("none");
    setDisplayMensajeBienvenida("none");
    setReportes("none");
    setBiblioteca("block");
    setFacilitadores("none");
  };
  const goToFacilitadores = () => {
    setFacilitadores("block");
    setTodosEventos("none");
    setEventoDetalle("none");
    setFormulario("none");
    setSeguimientoInHouse("none");
    setDisplayMensajeBienvenida("none");
    setReportes("none");
    setBiblioteca("none");
  };
  return (
    <div className="contenedor-admin">
      <Container fluid>
        <Row>
          <Navbar collapseOnSelect expand={false} variant="light">
            <Container fluid>
              {nombres != null &&
                apellidos != null &&
                tipo != null &&
                uniqueID != null && (
                  <Navbar.Brand href="#home">
                    {nombres + " " + apellidos}
                  </Navbar.Brand>
                )}
              {(nombres == null ||
                apellidos == null ||
                tipo == null ||
                uniqueID == null) && (
                <Navbar.Brand style={{ color: "red" }}>
                  <Spinner animation="grow" />
                  &nbsp;&nbsp;&nbsp;Tu sesión ha expirado, por favor recarga la
                  página
                </Navbar.Brand>
              )}
              <Navbar.Toggle aria-controls="menu-off-canvas" />
              <Navbar.Offcanvas id="menu-off-canvas" placement="end">
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`responsive-navbar-nav`}>
                    Menú
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="justify-content-end flex-grow-1 pe-3">
                    <Nav.Link
                      onClick={() => {
                        window.location.reload();
                      }}
                    >
                      Inicio
                    </Nav.Link>
                    <NavDropdown
                      title="Agenda"
                      id="dropdown-agenda"
                      align={{ lg: "end" }}
                      className="dropdown-eventos"
                    >
                      {agenda.map((year) => (
                        <NavDropdown
                          key={"agenda-" + year.year}
                          title={"Agenda " + year.year}
                          id={"dropdown-agenda-" + year.year}
                        >
                          {year.meses.map(
                            (mes) =>
                              mes.eventos > 0 && (
                                <NavDropdown.Item
                                  key={mes.mes}
                                  id={
                                    "dropdown-agenda-" +
                                    year.year +
                                    "-" +
                                    mes.mes
                                  }
                                  onClick={() => {
                                    setMes(mes.mes);
                                    setYear(year.year);
                                    setTipoEventos("Agenda");
                                    setEditing(editing + 1);
                                    loadEvents(mes.mes, year.year, "Agenda");
                                  }}
                                >
                                  {"Agenda " +
                                    mes.mes +
                                    " " +
                                    year.year +
                                    " (" +
                                    mes.eventos +
                                    " eventos)"}
                                </NavDropdown.Item>
                              )
                          )}
                        </NavDropdown>
                      ))}
                      <NavDropdown.Divider />
                      <NavDropdown.Item onClick={goToAgendaActual}>
                        Agenda Actual
                      </NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown
                      title="Congresos"
                      id="dropdown-congresos"
                      align={{ lg: "end" }}
                      className="dropdown-eventos"
                    >
                      {congresos.map((year) => (
                        <NavDropdown
                          key={"congresos-" + year.year}
                          title={"Congresos " + year.year}
                          id={"dropdown-congresos-" + year.year}
                        >
                          {year.meses.map(
                            (mes) =>
                              mes.eventos > 0 && (
                                <NavDropdown.Item
                                  key={mes.mes}
                                  id={
                                    "dropdown-congresos-" +
                                    year.year +
                                    "-" +
                                    mes.mes
                                  }
                                  onClick={() => {
                                    setMes(mes.mes);
                                    setYear(year.year);
                                    setTipoEventos("Congresos");
                                    setEditing(editing + 1);
                                    loadEvents(mes.mes, year.year, "Congresos");
                                  }}
                                >
                                  {"Congresos " +
                                    mes.mes +
                                    " " +
                                    year.year +
                                    " (" +
                                    mes.eventos +
                                    " eventos)"}
                                </NavDropdown.Item>
                              )
                          )}
                        </NavDropdown>
                      ))}
                    </NavDropdown>
                    <NavDropdown
                      title="InHouse"
                      id="dropdown-in-house"
                      align={{ lg: "end" }}
                      className="dropdown-eventos"
                    >
                      {inHouse.map((year) => (
                        <NavDropdown
                          key={"in-house-" + year.year}
                          title={"InHouse " + year.year}
                          id={"dropdown-in-house-" + year.year}
                        >
                          {year.meses.map(
                            (mes) =>
                              mes.eventos > 0 && (
                                <NavDropdown.Item
                                  key={mes.mes}
                                  id={
                                    "dropdown-in-house-" +
                                    year.year +
                                    "-" +
                                    mes.mes
                                  }
                                  onClick={() => {
                                    setMes(mes.mes);
                                    setYear(year.year);
                                    setTipoEventos("InHouse");
                                    setEditing(editing + 1);
                                    loadEvents(mes.mes, year.year, "InHouse");
                                  }}
                                >
                                  {"InHouse " +
                                    mes.mes +
                                    " " +
                                    year.year +
                                    " (" +
                                    mes.eventos +
                                    " eventos)"}
                                </NavDropdown.Item>
                              )
                          )}
                        </NavDropdown>
                      ))}
                    </NavDropdown>
                    <NavDropdown
                      title="Patrocinados"
                      id="dropdown-patrocinados"
                      align={{ lg: "end" }}
                      className="dropdown-eventos"
                    >
                      {patrocinados.map((year) => (
                        <NavDropdown
                          key={"patrocinados-" + year.year}
                          title={"Patrocinados " + year.year}
                          id={"dropdown-patrocinados-" + year.year}
                        >
                          {year.meses.map(
                            (mes) =>
                              mes.eventos > 0 && (
                                <NavDropdown.Item
                                  key={mes.mes}
                                  id={
                                    "dropdown-patrocinados-" +
                                    year.year +
                                    "-" +
                                    mes.mes
                                  }
                                  onClick={() => {
                                    setMes(mes.mes);
                                    setYear(year.year);
                                    setTipoEventos("Patrocinados");
                                    setEditing(editing + 1);
                                    loadEvents(
                                      mes.mes,
                                      year.year,
                                      "Patrocinados"
                                    );
                                  }}
                                >
                                  {"Patrocinados " +
                                    mes.mes +
                                    " " +
                                    year.year +
                                    " (" +
                                    mes.eventos +
                                    " eventos)"}
                                </NavDropdown.Item>
                              )
                          )}
                        </NavDropdown>
                      ))}
                    </NavDropdown>
                    <NavDropdown
                      title="Foros Centroamericanos"
                      id="dropdown-foros-centroamericanos"
                      align={{ lg: "end" }}
                      className="dropdown-eventos"
                    >
                      {foros.map((year) => (
                        <NavDropdown
                          key={"foros-centroamericanos-" + year.year}
                          title={"Foros Centroamericanos " + year.year}
                          id={"dropdown-foros-centroamericanos-" + year.year}
                        >
                          {year.meses.map(
                            (mes) =>
                              mes.eventos > 0 && (
                                <NavDropdown.Item
                                  key={mes.mes}
                                  id={
                                    "dropdown-foros-centroamericanos-" +
                                    year.year +
                                    "-" +
                                    mes.mes
                                  }
                                  onClick={() => {
                                    setMes(mes.mes);
                                    setYear(year.year);
                                    setTipoEventos("Foros-Centroamericanos");
                                    setEditing(editing + 1);
                                    loadEvents(
                                      mes.mes,
                                      year.year,
                                      "Foros-Centroamericanos"
                                    );
                                  }}
                                >
                                  {"Foros Centroamericanos " +
                                    mes.mes +
                                    " " +
                                    year.year +
                                    " (" +
                                    mes.eventos +
                                    " eventos)"}
                                </NavDropdown.Item>
                              )
                          )}
                        </NavDropdown>
                      ))}
                    </NavDropdown>
                    <NavDropdown
                      title="e-learning"
                      id="dropdown-e-learning"
                      align={{ lg: "end" }}
                      className="dropdown-eventos"
                    >
                      {eLearning.map((year) => (
                        <NavDropdown
                          key={"e-learning-" + year.year}
                          title={"e-learning " + year.year}
                          id={"dropdown-e-learning-" + year.year}
                        >
                          {year.meses.map(
                            (mes) =>
                              mes.eventos > 0 && (
                                <NavDropdown.Item
                                  key={mes.mes}
                                  id={
                                    "dropdown-e-learning-" +
                                    year.year +
                                    "-" +
                                    mes.mes
                                  }
                                  onClick={() => {
                                    setMes(mes.mes);
                                    setYear(year.year);
                                    setTipoEventos("e-learning");
                                    setEditing(editing + 1);
                                    loadEvents(
                                      mes.mes,
                                      year.year,
                                      "e-learning"
                                    );
                                  }}
                                >
                                  {"e-learning " +
                                    mes.mes +
                                    " " +
                                    year.year +
                                    " (" +
                                    mes.eventos +
                                    " eventos)"}
                                </NavDropdown.Item>
                              )
                          )}
                        </NavDropdown>
                      ))}
                    </NavDropdown>
                    {(tipo == 4 || tipo == 2) && (
                      <Nav.Link onClick={showForm}>Diseño de eventos</Nav.Link>
                    )}
                    {(tipo == 2 || tipo == 3 || tipo == 4 || tipo == 6) && (
                      <Nav.Link onClick={showReportes}>
                        Reportería general
                      </Nav.Link>
                    )}
                    {(tipo == 4 || tipo == 2 || tipo == 3 || tipo == 1) && (
                      <Nav.Link href="/in-house/seguimiento">
                        Seguimiento INHOUSE
                      </Nav.Link>
                    )}
                    {(tipo == 4 || tipo == 3 || tipo == 2) && (
                      <Nav.Link onClick={goToBiblioteca}>
                        Biblioteca de contenidos
                      </Nav.Link>
                    )}
                    {(tipo == 4 || tipo == 3 || tipo == 2) && (
                      <Nav.Link onClick={goToFacilitadores}>
                        Facilitadores
                      </Nav.Link>
                    )}
                    {(tipo == 4 || tipo == 1 || tipo == 2) && (
                      <Nav.Link href="mails-masivos/programar-envio">
                        Mails masivos
                      </Nav.Link>
                    )}
                    {(tipo == 4 || tipo == 6 || tipo == 1) && (
                      <Nav.Link href="pagos-electronicos/all">
                        Pagos electrónicos
                      </Nav.Link>
                    )}
                    {(tipo == 4 || tipo == 6 || tipo == 1) && (
                      <Nav.Link href="crm/dashboard">CRM</Nav.Link>
                    )}
                    {tipo == 4 && (
                      <Nav.Link href="variables">Variables web</Nav.Link>
                    )}
                    {(tipo == 4 || tipo == 2) && (
                      <Nav.Link href="/formularios/dashboard">
                        Formularios
                      </Nav.Link>
                    )}
                  </Nav>
                  <Nav>
                    <NavDropdown title={nombres} id="collasible-nav-dropdown">
                      <Card style={{ width: "100%", height: "100%" }}>
                        <Card.Body>
                          <Card.Title>{nombres + " " + apellidos}</Card.Title>
                          <Card.Text>{email}</Card.Text>
                          <Button
                            variant="light"
                            onClick={() =>
                              window.location.replace("/usuarios/actualizar")
                            }
                          >
                            Editar mi información
                          </Button>
                        </Card.Body>
                      </Card>
                    </NavDropdown>
                    <Nav.Link onClick={singOut}>Cerrar Sesión</Nav.Link>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        </Row>
        <Row style={{ display: cargando, padding: "2%" }}>
          <Button variant="dark" disabled>
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            Cargando...
          </Button>
        </Row>
        <Row style={{ display: displayMensajeBienvenida, padding: "2%" }}>
          <Alert variant="primary">
            <Alert.Heading>Bienvenid@</Alert.Heading>
          </Alert>
        </Row>
        <Row style={{ padding: "2%" }}>
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Sesiones del día de hoy</Accordion.Header>
              <Accordion.Body>
                <Container fluid>
                  {sesiones.length == 0 && (
                    <Alert variant="success">
                      ¡No hay sesiones el día de hoy!
                    </Alert>
                  )}
                  {sesiones.map((actual, index) => (
                    <Row key={index} style={{ padding: "2%" }}>
                      <Container fluid>
                        <Row>
                          <Alert variant="success">
                            <Alert.Heading>
                              {actual.sesion.evento}
                            </Alert.Heading>
                            <p>
                              <strong>Inicio: </strong>
                              {actual.sesion.fechaInicio}
                              <br />
                              <strong>Fin: </strong>
                              {actual.sesion.fechaFin}
                            </p>
                            <hr />
                            <div className="d-flex justify-content-end">
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="outline-success"
                                  id="dropdown-basic"
                                >
                                  Enlaces
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  {actual.enlaces.map((curr, indice) => (
                                    <Dropdown.Item
                                      href={curr.url}
                                      target="_blamk"
                                      key={indice}
                                    >
                                      {curr.Nombre}
                                    </Dropdown.Item>
                                  ))}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </Alert>
                        </Row>
                      </Container>
                    </Row>
                  ))}
                </Container>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Row>
        <Row
          style={{
            display: detalleEventos,
            paddingLeft: "5%",
            paddingRight: "5%",
          }}
        >
          <Container style={{ width: "100%" }}>
            <Tab.Container variant="light">
              <Row
                style={{
                  marginBottom: "45px",
                  marginTop: "45px",
                  display: todosEventos,
                }}
              >
                <ListGroup style={{ textAlign: "left" }}>
                  <Col xs={12} md={12} lg={12}>
                    <Row style={{ marginBottom: "45px" }}>
                      <Breadcrumb variant="dark">
                        <Breadcrumb.Item active>Eventos</Breadcrumb.Item>
                        <Breadcrumb.Item active>{tipoEventos}</Breadcrumb.Item>
                        <Breadcrumb.Item active>
                          {tipoEventos + " " + mes + " " + year}
                        </Breadcrumb.Item>
                      </Breadcrumb>
                    </Row>
                    <Row>
                      {eventos.map((evento) => (
                        <Col
                          xs={12}
                          md={4}
                          lg={4}
                          style={{ marginBottom: "25px" }}
                          key={evento.UniqueID}
                        >
                          <Card style={{ height: "100%" }}>
                            <Card.Img
                              loading="lazy"
                              variant="top"
                              src={evento.flier}
                            />
                            <Card.Body
                              style={{ display: "grid", placeItems: "end" }}
                            >
                              <Card.Title>{evento.Nombre}</Card.Title>
                              <ListGroup.Item
                                variant="light"
                                key={evento.UniqueID}
                                action
                                href={"#" + evento.UniqueID}
                              >
                                <h4
                                  style={{
                                    textAlign: "center",
                                    width: "100%",
                                    height: "100%",
                                  }}
                                  onClick={showDetailEvent}
                                >
                                  SELECCIONAR
                                </h4>
                              </ListGroup.Item>
                            </Card.Body>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </ListGroup>
              </Row>
              <Row style={{ display: eventoDetalle }}>
                <Tab.Content>
                  {eventos.map((evento) => (
                    <Tab.Pane
                      key={evento.UniqueID}
                      eventKey={"#" + evento.UniqueID}
                    >
                      <EventoDashboard
                        edit={edit}
                        evento={evento}
                        eventochange={eventochange}
                        rol={tipo}
                        refrescar={refreshEvents}
                        back={showAllEvents}
                        mes={mes}
                        year={year}
                        tipoEventos={tipoEventos}
                        UniqueID={uniqueID}
                        hash={hash}
                      />
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Row>
            </Tab.Container>
          </Container>
        </Row>
        <Row style={{ display: formulario }}>
          <Alert variant="primary">
            <Alert.Heading>Diseño de eventos</Alert.Heading>
          </Alert>
          <CrudEvento
            refresh={() => {
              setEditing(editing + 1);
            }}
          />
        </Row>
        <Row
          style={{ display: reportes, paddingLeft: "5%", paddingRight: "5%" }}
        >
          <Alert variant="primary">
            <Alert.Heading>Reportería general</Alert.Heading>
          </Alert>
          <Reporterias />
        </Row>
        <Row
          style={{
            display: seguimientoInHouse,
            paddingLeft: "5%",
            paddingRight: "5%",
          }}
        >
          <Alert variant="primary">
            <Alert.Heading>Seguimiento InHouse</Alert.Heading>
          </Alert>
          <InHouse display={seguimientoInHouse} permiso={tipo} />
        </Row>
        <Row
          style={{ display: biblioteca, paddingLeft: "5%", paddingRight: "5%" }}
        >
          <Alert variant="primary">
            <Alert.Heading>Biblioteca de contenidos</Alert.Heading>
          </Alert>
          <Biblioteca display={biblioteca} permiso={tipo} />
        </Row>
        <Row style={{ display: facilitadores }}>
          <Alert variant="primary">
            <Alert.Heading>Facilitadores</Alert.Heading>
          </Alert>
          <Facilitadores display={facilitadores} permiso={tipo} />
        </Row>
      </Container>
    </div>
  );
};

export default Admin;
