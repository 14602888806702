import React, { useEffect, useState } from 'react';
import { Modal, Button, Container } from "react-bootstrap";
import {
    ComposedChart,
    Area,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Scatter,
    Line
} from 'recharts';


const ModalIngresos = (props) => {
    const [contador, setContador] = useState(0);
    const [info, setInfo] = useState([]);
    useEffect(() => {

    }, [contador, props.data, props.ventas]);

    const enter = () => {
        if (props.data.length > 0 && props.ventas.length > 0) {
            let temp = [];
            props.ventas.map((actual) => {
                const index_finder = (asesor) => asesor.asesor == actual.asesor;
                if (props.data.findIndex(index_finder) >= 0) {
                    let ingresos = props.data[props.data.findIndex(index_finder)].ingresos;
                    let fichas_confirmadas = props.data[props.data.findIndex(index_finder)].inscritos;
                    let patrocinios = props.data[props.data.findIndex(index_finder)].patrocinios;
                    temp.push({ ingresos: ingresos, fichas_confirmadas: fichas_confirmadas, asesor: actual.asesor, ventas: actual.inscritos, patrocinios: patrocinios });
                }
            });
            setInfo(temp);
            setContador(contador + 1);
        }
    }

    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
            onEnter={enter}
        >
            <Modal.Header closeButton style={{ backgroundColor: "#E0E0E2" }}>
                <Modal.Title id="contained-modal-title-vcenter" className="font-bold">
                    Ingresos
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: "#E0E0E2", height: '60vh' }} id="body-modal">
                <Container>
                    <ResponsiveContainer width="100%" height="100%">
                        <ComposedChart
                            width={500}
                            height={400}
                            data={info}
                            margin={{
                                top: 20,
                                right: 20,
                                bottom: 20,
                                left: 20,
                            }}
                        >
                            <CartesianGrid stroke="#f5f5f5" />
                            <XAxis dataKey="asesor" scale="band" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Area type="monotone" dataKey="ingresos" fill="#8884d8" stroke="#8884d8" />
                            <Bar dataKey="ventas" barSize={20} fill="#413ea0" />
                            {/*<Line type="monotone" dataKey="patrocinios" stroke="#ff7300" />*/}
                            <Scatter dataKey="fichas_confirmadas" fill="red" />
                        </ComposedChart>
                    </ResponsiveContainer>
                </Container>
            </Modal.Body>
            <Modal.Footer style={{ backgroundColor: "#E0E0E2" }}>
                <Button variant="secondary" className="font-bold" onClick={props.onHide}>Cerrar</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalIngresos;
