import React, { useEffect, useState } from "react";
import { Container, Row, Button, Form, Col, Spinner } from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";
import ActionButton from "./Action";
import MessageModal from "./MessageModal";
import DOMPurify from "dompurify";
import "./style.css";
const helpers = require("./helpers");
const Formulario = (props) => {
  const [cambios, setCambios] = useState([]);
  const [formConfig, setFormConfig] = useState({});
  const [validated, setValidated] = useState(false);
  const [cargando, setCargando] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [mensaje, setMensaje] = useState("");
  useEffect(() => {
    setFormConfig(props.formConfig);
    if (props.formConfig != null) {
      setLoaded(true);
    }
  }, [props.formConfig]);
  useEffect(() => {}, [cambios]);
  useEffect(() => {
    if (props.formConfig.infoFlag) {
      const intervalId = setInterval(() => {
        props
          .getInfo(formConfig)
          .then((res) => {})
          .catch((error) => {
            console.log(error);
          });
      }, props.formConfig.infoInterval);
      return () => clearInterval(intervalId);
    }
  }, [formConfig, props.getInfo]);
  const onChange = async (evt) => {
    let indice = evt.target.name.split("-")[0];
    let newFormConfig = formConfig;
    if (evt.target.files) {
      newFormConfig.formConfig[indice].value = await helpers
        .getBase64(evt.target.files[0])
        .catch((err) => {
          console.log(err);
        });
    } else {
      newFormConfig.formConfig[indice].value = evt.target.value;
    }
    setFormConfig(newFormConfig);
    setCambios(cambios + 1);
  };
  const onSubmit = (evt) => {
    const form = evt.currentTarget;
    if (form.checkValidity() === false) {
      evt.preventDefault();
      evt.stopPropagation();
    } else {
      evt.preventDefault();
      setCargando(true);
      props
        .afterSubmit(formConfig)
        .then((res) => {
          setCargando(false);
        })
        .catch((err) => {
          //window.location.reload();
          setCargando(false);
          setShowModal(true);
          setMensaje(err);
          console.log(err);
        });
    }
    setValidated(true);
    setCambios(cambios + 1);
  };
  const addToList = (index) => {
    let newFormConfig = formConfig;
    newFormConfig.formConfig[index].value.push({
      value: "",
    });
    setFormConfig(newFormConfig);
    setCambios(cambios + 1);
  };
  const addToListList = (index) => {
    let newFormConfig = formConfig;
    newFormConfig.formConfig[index].value.push(
      formConfig.formConfig[index].fields.map((actual) => {
        if (actual.type != 6) {
          return { value: "" };
        } else {
          return { value: null };
        }
      })
    );
    setFormConfig(newFormConfig);
    setCambios(cambios + 1);
  };
  const delFromList = (index, list_index) => {
    let newFormConfig = formConfig;
    newFormConfig.formConfig[index].value.splice(list_index, 1);
    setFormConfig(newFormConfig);
    setCambios(cambios + 1);
  };
  const onChangeList = async (evt) => {
    let index = evt.target.name.split("-")[0];
    let list_index = evt.target.name.split("-")[1];
    let newFormConfig = formConfig;
    if (evt.target.files) {
      newFormConfig.formConfig[index].value[list_index].value = await helpers
        .getBase64(evt.target.files[0])
        .catch((err) => {
          console.log(err);
        });
    } else {
      newFormConfig.formConfig[index].value[list_index].value =
        evt.target.value;
    }
    setFormConfig(newFormConfig);
    setCambios(cambios + 1);
  };
  const onChangeListList = async (evt) => {
    let index = evt.target.name.split("-")[0];
    let list_index = evt.target.name.split("-")[1];
    let field_index = evt.target.name.split("-")[2];
    let newFormConfig = formConfig;
    if (evt.target.files) {
      newFormConfig.formConfig[index].value[list_index][field_index].value =
        await helpers.getBase64(evt.target.files[0]).catch((err) => {
          console.log(err);
        });
    } else {
      newFormConfig.formConfig[index].value[list_index][field_index].value =
        evt.target.value;
    }
    setFormConfig(newFormConfig);
    setCambios(cambios + 1);
  };
  const addToMasterRowList = (index) => {
    let newFormConfig = formConfig;
    newFormConfig.formConfig[index].value.push({
      head: newFormConfig.formConfig[index].head.map(() => {
        return { value: "" };
      }),
      rows: [],
    });
    setFormConfig(newFormConfig);
    setCambios(cambios + 1);
  };
  const onChangeMaster = async (evt) => {
    let index = evt.target.name.split("-")[0];
    let list_index = evt.target.name.split("-")[1];
    let head_index = evt.target.name.split("-")[2];
    let newFormConfig = formConfig;
    if (evt.target.files) {
      newFormConfig.formConfig[index].value[list_index].head[head_index].value =
        await helpers.getBase64(evt.target.files[0]).catch((err) => {
          console.log(err);
        });
    } else {
      newFormConfig.formConfig[index].value[list_index].head[head_index].value =
        evt.target.value;
    }
    setFormConfig(newFormConfig);
    setCambios(cambios + 1);
  };

  const addRow = (index, list_index) => {
    let newFormConfig = formConfig;
    newFormConfig.formConfig[index].value[list_index].rows.push(
      formConfig.formConfig[index].fields.map((actual) => {
        if (actual.type != 6) {
          return { value: "" };
        } else {
          return { value: null };
        }
      })
    );
    setFormConfig(newFormConfig);
    setCambios(cambios + 1);
  };
  const delRow = (index, list_index, row_index) => {
    let newFormConfig = formConfig;
    newFormConfig.formConfig[index].value[list_index].rows.splice(row_index, 1);
    setFormConfig(newFormConfig);
    setCambios(cambios + 1);
  };
  const onChangeRow = async (evt) => {
    let index = evt.target.name.split("-")[0];
    let list_index = evt.target.name.split("-")[1];
    let row_index = evt.target.name.split("-")[2];
    let field_index = evt.target.name.split("-")[3];
    let newFormConfig = formConfig;
    if (evt.target.files) {
      newFormConfig.formConfig[index].value[list_index].rows[row_index][
        field_index
      ].value = await helpers.getBase64(evt.target.files[0]).catch((err) => {
        console.log(err);
      });
    } else {
      newFormConfig.formConfig[index].value[list_index].rows[row_index][
        field_index
      ].value = evt.target.value;
    }
    setFormConfig(newFormConfig);
    setCambios(cambios + 1);
  };
  const onEditorChange = async (value, editor) => {
    let indice = editor.iframeElement.id.split("-")[0];
    let newFormConfig = formConfig;
    newFormConfig.formConfig[indice].value = value;
    setFormConfig(newFormConfig);
    setCambios(cambios + 1);
  };
  const onEditorChangeListList = async (value, editor) => {
    let index = editor.iframeElement.id.split("-")[0];
    let list_index = editor.iframeElement.id.split("-")[1];
    let field_index = editor.iframeElement.id.split("-")[2].split("_")[0];
    let newFormConfig = formConfig;
    newFormConfig.formConfig[index].value[list_index][field_index].value =
      value;
    setFormConfig(newFormConfig);
    setCambios(cambios + 1);
  };
  const onChangeEditorRow = async (value, editor) => {
    console.log(editor.iframeElement.id);
    let index = editor.iframeElement.id.split("-")[0];
    let list_index = editor.iframeElement.id.split("-")[1];
    let row_index = editor.iframeElement.id.split("-")[2];
    let field_index = editor.iframeElement.id.split("-")[3].split("_")[0];
    let newFormConfig = formConfig;
    newFormConfig.formConfig[index].value[list_index].rows[row_index][
      field_index
    ].value = value;
    setFormConfig(newFormConfig);
    setCambios(cambios + 1);
  };
  const onChangeEditorMaster = async (value, editor) => {
    let index = editor.iframeElement.id.split("-")[0];
    let list_index = editor.iframeElement.id.split("-")[1];
    let head_index = editor.iframeElement.id.split("-")[2].split("_")[0];
    let newFormConfig = formConfig;
    newFormConfig.formConfig[index].value[list_index].head[head_index].value =
      value;
    setFormConfig(newFormConfig);
    setCambios(cambios + 1);
  };
  const onChangeEditorList = async (value, editor) => {
    let index = editor.iframeElement.id.split("-")[0];
    let list_index = editor.iframeElement.id.split("-")[1].split("_")[0];
    let newFormConfig = formConfig;
    newFormConfig.formConfig[index].value[list_index].value = value;
    setFormConfig(newFormConfig);
    setCambios(cambios + 1);
  };
  return !loaded ? (
    <Container
      style={{ width: "100vw", height: "100vh" }}
      className="d-flex justify-content-center align-items-center"
    >
      <Spinner animation="border" role="status" />
    </Container>
  ) : (
    <Container fluid>
      <Row className="mt-3">
        <h1>{props.formConfig.title}</h1>
      </Row>
      <Row>
        <Form onSubmit={onSubmit} noValidate validated={validated}>
          <Container>
            {props.formConfig.formConfig &&
              props.formConfig.formConfig.map((actual, index) => (
                <Row
                  key={"form-" + index}
                  className="mt-5"
                  style={{ borderLeft: "3px solid #1e3d52" }}
                >
                  <Container fluid>
                    {actual.html_text && (
                      <Row>
                        <div
                          className="mb-5"
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(actual.title),
                          }}
                        ></div>
                      </Row>
                    )}
                    {!actual.html_text && (
                      <Row>
                        <h5>{actual.title}</h5>
                      </Row>
                    )}
                    <Row></Row>
                    <Row>
                      {actual.type == 1 && (
                        <Form.Group controlId={actual.name}>
                          <Form.Control
                            required={actual.required}
                            type="text"
                            placeholder={actual.placeholder}
                            value={actual.value}
                            onChange={onChange}
                            name={index + "-" + actual.name}
                            disabled={
                              cargando ||
                              actual.lock ||
                              props.formConfig.locked ||
                              props.lockForm
                            }
                          />
                          <Form.Control.Feedback>Ok!</Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid">
                            Este campo presenta error
                          </Form.Control.Feedback>
                        </Form.Group>
                      )}
                      {actual.type == 2 && (
                        <Form.Group controlId={actual.name}>
                          <Form.Control
                            required={actual.required}
                            type="password"
                            placeholder={actual.placeholder}
                            value={actual.value}
                            onChange={onChange}
                            name={index + "-" + actual.name}
                            pattern={actual.pattern}
                            maxLength={actual.maxLength}
                            minLength={actual.minLength}
                            disabled={
                              cargando ||
                              actual.lock ||
                              props.formConfig.locked ||
                              props.lockForm
                            }
                          />
                          <Form.Control.Feedback>Ok!</Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid">
                            Este campo presenta error
                          </Form.Control.Feedback>
                        </Form.Group>
                      )}
                      {actual.type == 3 && (
                        <Form.Group controlId={actual.name}>
                          <Form.Select
                            required={actual.required}
                            placeholder={actual.placeholder}
                            value={actual.value}
                            onChange={onChange}
                            name={index + "-" + actual.name}
                            disabled={
                              cargando ||
                              actual.lock ||
                              props.formConfig.locked ||
                              props.lockForm
                            }
                          >
                            <option></option>
                            {actual.options &&
                              actual.options.map((current, indice) => (
                                <option
                                  key={"opcion-" + indice + "-" + index}
                                  value={current.value}
                                  style={{
                                    width: "100%",
                                    boxSizing: "border-box",
                                  }}
                                >
                                  {current.label}
                                </option>
                              ))}
                          </Form.Select>
                          <Form.Control.Feedback>Ok!</Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid">
                            Este campo presenta error
                          </Form.Control.Feedback>
                        </Form.Group>
                      )}
                      {actual.type == 4 && (
                        <Form.Group controlId={actual.name}>
                          <Form.Control
                            required={actual.required}
                            type="email"
                            placeholder={actual.placeholder}
                            value={actual.value}
                            onChange={onChange}
                            name={index + "-" + actual.name}
                            disabled={
                              cargando ||
                              actual.lock ||
                              props.formConfig.locked ||
                              props.lockForm
                            }
                          />
                          <Form.Control.Feedback>Ok!</Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid">
                            Este campo presenta error
                          </Form.Control.Feedback>
                        </Form.Group>
                      )}
                      {actual.type == 5 && (
                        <Form.Group controlId={actual.name}>
                          <Form.Control
                            required={actual.required}
                            type="date"
                            placeholder={actual.placeholder}
                            value={actual.value}
                            onChange={onChange}
                            name={index + "-" + actual.name}
                            disabled={
                              cargando ||
                              actual.lock ||
                              props.formConfig.locked ||
                              props.lockForm
                            }
                          />
                          <Form.Control.Feedback>Ok!</Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid">
                            Este campo presenta error
                          </Form.Control.Feedback>
                        </Form.Group>
                      )}
                      {actual.type == 6 && (
                        <Form.Group controlId={actual.name}>
                          <Form.Control
                            required={actual.required}
                            type="file"
                            placeholder={actual.placeholder}
                            onChange={onChange}
                            name={index + "-" + actual.name}
                            disabled={
                              cargando ||
                              actual.lock ||
                              props.formConfig.locked ||
                              props.lockForm
                            }
                          />
                          <Form.Control.Feedback>Ok!</Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid">
                            Este campo presenta error
                          </Form.Control.Feedback>
                        </Form.Group>
                      )}
                      {actual.type == 7 && (
                        <Form.Group controlId={actual.name}>
                          <Form.Control
                            required={actual.required}
                            type="number"
                            placeholder={actual.placeholder}
                            value={actual.value}
                            step={actual.step}
                            onChange={onChange}
                            name={index + "-" + actual.name}
                            disabled={
                              cargando ||
                              actual.lock ||
                              props.formConfig.locked ||
                              props.lockForm
                            }
                          />
                          <Form.Control.Feedback>Ok!</Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid">
                            Este campo presenta error
                          </Form.Control.Feedback>
                        </Form.Group>
                      )}
                      {actual.type == 8 && (
                        <Form.Group controlId={actual.name}>
                          <Form.Control
                            required={actual.required}
                            as="textarea"
                            style={{ resize: "none" }}
                            rows={5}
                            placeholder={actual.placeholder}
                            value={actual.value}
                            onChange={onChange}
                            name={index + "-" + actual.name}
                            disabled={
                              cargando ||
                              actual.lock ||
                              props.formConfig.locked ||
                              props.lockForm
                            }
                          />
                          <Form.Control.Feedback>Ok!</Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid">
                            Este campo presenta error
                          </Form.Control.Feedback>
                        </Form.Group>
                      )}
                      {actual.type == 10 && (
                        <Container fluid>
                          <Row>
                            <p>{actual.subtitle}</p>
                          </Row>
                          <Row>
                            <Col xs={12} md={6} lg={4}>
                              <ActionButton
                                configuration={{
                                  type: 4,
                                  icon: "plus",
                                  variant: "outline-success",
                                  title: "Agregar " + actual.name,
                                  lock:
                                    props.formConfig.locked || props.lockForm,
                                }}
                                action={() => {
                                  addToList(index);
                                }}
                              />
                            </Col>
                          </Row>
                          {actual.value.map((actual_lista, index_lista) => (
                            <Row
                              key={"actual-lista-" + index_lista}
                              className="mt-5"
                            >
                              <Container
                                fluid
                                style={{ borderRight: "3px solid #1e3d52" }}
                              >
                                <Row className="me-auto mt-5">
                                  <p style={{ textAlign: "center" }}>
                                    --#{index_lista + 1}--
                                  </p>
                                </Row>
                                <Row>
                                  <Col xs={12} md={12} lg={6}>
                                    <ActionButton
                                      configuration={{
                                        type: 4,
                                        icon: "trash",
                                        variant: "outline-danger",
                                        title: "Quitar de la lista",
                                        lock:
                                          props.formConfig.locked ||
                                          props.lockForm,
                                      }}
                                      action={() => {
                                        delFromList(index, index_lista);
                                      }}
                                    />
                                  </Col>
                                </Row>
                                {actual.field.html_text && (
                                  <Row className="mt-5">
                                    <div
                                      className="mb-5"
                                      dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(
                                          actual.field.title
                                        ),
                                      }}
                                    ></div>
                                  </Row>
                                )}
                                {!actual.field.html_text && (
                                  <Row className="mt-5">
                                    <h5>{actual.field.title}</h5>
                                  </Row>
                                )}
                                <Row>
                                  {actual.field.type == 1 && (
                                    <Form.Group controlId={actual.field.name}>
                                      <Form.Control
                                        required
                                        type="text"
                                        placeholder={actual.field.placeholder}
                                        value={actual_lista.value}
                                        onChange={onChangeList}
                                        name={
                                          index +
                                          "-" +
                                          index_lista +
                                          "-" +
                                          actual.field.name
                                        }
                                        disabled={
                                          cargando ||
                                          props.formConfig.locked ||
                                          props.lockForm
                                        }
                                      />
                                      <Form.Control.Feedback>
                                        Ok!
                                      </Form.Control.Feedback>
                                      <Form.Control.Feedback type="invalid">
                                        Este campo presenta error
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  )}
                                  {actual.field.type == 2 && (
                                    <Form.Group controlId={actual.field.name}>
                                      <Form.Control
                                        required
                                        type="password"
                                        placeholder={actual.field.placeholder}
                                        value={actual_lista.value}
                                        onChange={onChangeList}
                                        name={
                                          index +
                                          "-" +
                                          index_lista +
                                          "-" +
                                          actual.field.name
                                        }
                                        disabled={
                                          cargando ||
                                          props.formConfig.locked ||
                                          props.lockForm
                                        }
                                      />
                                      <Form.Control.Feedback>
                                        Ok!
                                      </Form.Control.Feedback>
                                      <Form.Control.Feedback type="invalid">
                                        Este campo presenta error
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  )}
                                  {actual.field.type == 3 && (
                                    <Form.Group controlId={actual.field.name}>
                                      <Form.Select
                                        required
                                        placeholder={actual.field.placeholder}
                                        value={actual_lista.value}
                                        onChange={onChangeList}
                                        name={
                                          index +
                                          "-" +
                                          index_lista +
                                          "-" +
                                          actual.field.name
                                        }
                                        disabled={
                                          cargando ||
                                          props.formConfig.locked ||
                                          props.lockForm
                                        }
                                      >
                                        <option></option>
                                        {actual.field.options &&
                                          actual.field.options.map(
                                            (current, indice) => (
                                              <option
                                                style={{
                                                  width: "100%",
                                                  boxSizing: "border-box",
                                                }}
                                                key={
                                                  "opcion-" +
                                                  indice +
                                                  "-" +
                                                  index
                                                }
                                                value={current.value}
                                              >
                                                {current.label}
                                              </option>
                                            )
                                          )}
                                      </Form.Select>
                                      <Form.Control.Feedback>
                                        Ok!
                                      </Form.Control.Feedback>
                                      <Form.Control.Feedback type="invalid">
                                        Este campo presenta error
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  )}
                                  {actual.field.type == 4 && (
                                    <Form.Group controlId={actual.field.name}>
                                      <Form.Control
                                        required
                                        type="email"
                                        placeholder={actual.field.placeholder}
                                        value={actual_lista.value}
                                        onChange={onChangeList}
                                        name={
                                          index +
                                          "-" +
                                          index_lista +
                                          "-" +
                                          actual.field.name
                                        }
                                        disabled={
                                          cargando ||
                                          props.formConfig.locked ||
                                          props.lockForm
                                        }
                                      />
                                      <Form.Control.Feedback>
                                        Ok!
                                      </Form.Control.Feedback>
                                      <Form.Control.Feedback type="invalid">
                                        Este campo presenta error
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  )}
                                  {actual.field.type == 5 && (
                                    <Form.Group controlId={actual.field.name}>
                                      <Form.Control
                                        required
                                        type="date"
                                        placeholder={actual.field.placeholder}
                                        value={actual_lista.value}
                                        onChange={onChangeList}
                                        name={
                                          index +
                                          "-" +
                                          index_lista +
                                          "-" +
                                          actual.field.name
                                        }
                                        disabled={
                                          cargando ||
                                          props.formConfig.locked ||
                                          props.lockForm
                                        }
                                      />
                                      <Form.Control.Feedback>
                                        Ok!
                                      </Form.Control.Feedback>
                                      <Form.Control.Feedback type="invalid">
                                        Este campo presenta error
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  )}
                                  {actual.field.type == 6 && (
                                    <Form.Group controlId={actual.field.name}>
                                      <Form.Control
                                        required
                                        type="file"
                                        placeholder={actual.placeholder}
                                        onChange={onChangeList}
                                        name={
                                          index +
                                          "-" +
                                          index_lista +
                                          "-" +
                                          actual.field.name
                                        }
                                        disabled={
                                          cargando ||
                                          props.formConfig.locked ||
                                          props.lockForm
                                        }
                                      />
                                      <Form.Control.Feedback>
                                        Ok!
                                      </Form.Control.Feedback>
                                      <Form.Control.Feedback type="invalid">
                                        Este campo presenta error
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  )}
                                  {actual.field.type == 7 && (
                                    <Form.Group controlId={actual.field.name}>
                                      <Form.Control
                                        required
                                        type="number"
                                        placeholder={actual.field.placeholder}
                                        value={actual_lista.value}
                                        step={actual.field.step}
                                        onChange={onChangeList}
                                        name={
                                          index +
                                          "-" +
                                          index_lista +
                                          "-" +
                                          actual.field.name
                                        }
                                        disabled={
                                          cargando ||
                                          props.formConfig.locked ||
                                          props.lockForm
                                        }
                                      />
                                      <Form.Control.Feedback>
                                        Ok!
                                      </Form.Control.Feedback>
                                      <Form.Control.Feedback type="invalid">
                                        Este campo presenta error
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  )}
                                  {actual.field.type == 8 && (
                                    <Form.Group controlId={actual.field.name}>
                                      <Form.Control
                                        required
                                        as="textarea"
                                        style={{ resize: "none" }}
                                        rows={5}
                                        placeholder={actual.field.placeholder}
                                        value={actual_lista.value}
                                        onChange={onChangeList}
                                        name={
                                          index +
                                          "-" +
                                          index_lista +
                                          "-" +
                                          actual.field.name
                                        }
                                        disabled={
                                          cargando ||
                                          props.formConfig.locked ||
                                          props.lockForm
                                        }
                                      />
                                      <Form.Control.Feedback>
                                        Ok!
                                      </Form.Control.Feedback>
                                      <Form.Control.Feedback type="invalid">
                                        Este campo presenta error
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  )}
                                  {actual.field.type == 13 && (
                                    <Form.Group controlId={actual.field.name}>
                                      <Form.Control
                                        required
                                        type="datetime-local"
                                        placeholder={actual.field.placeholder}
                                        value={actual_lista.value}
                                        onChange={onChangeList}
                                        name={
                                          index +
                                          "-" +
                                          index_lista +
                                          "-" +
                                          actual.field.name
                                        }
                                        disabled={
                                          cargando ||
                                          props.formConfig.locked ||
                                          props.lockForm
                                        }
                                      />
                                      <Form.Control.Feedback>
                                        Ok!
                                      </Form.Control.Feedback>
                                      <Form.Control.Feedback type="invalid">
                                        Este campo presenta error
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  )}
                                  {actual.field.type == 14 && (
                                    <Editor
                                      apiKey={
                                        process.env.REACT_APP_API_KEY_EDITOR
                                      }
                                      init={{
                                        plugins:
                                          "lists link image paste wordcount",
                                        toolbar:
                                          "undo redo | blocks | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent",
                                      }}
                                      value={actual_lista.value}
                                      id={
                                        index +
                                        "-" +
                                        index_lista +
                                        "-" +
                                        actual.field.name
                                      }
                                      onEditorChange={onChangeEditorList}
                                    />
                                  )}
                                </Row>
                              </Container>
                            </Row>
                          ))}
                        </Container>
                      )}
                      {actual.type == 11 && (
                        <Container fluid>
                          <Row>
                            <p>{actual.subtitle}</p>
                          </Row>
                          <Row>
                            <Col xs={12} md={12} lg={4}>
                              <ActionButton
                                configuration={{
                                  type: 4,
                                  icon: "plus",
                                  variant: "outline-success",
                                  title: "Agregar",
                                  lock:
                                    props.formConfig.locked || props.lockForm,
                                }}
                                action={() => {
                                  addToMasterRowList(index);
                                }}
                              />
                            </Col>
                          </Row>
                          <Row>
                            {actual.value.map((item_actual, index_item) => (
                              <Container
                                key={"value-item-" + index_item}
                                style={{ borderRight: "3px solid #1e3d52" }}
                                className="mt-5"
                              >
                                <Row className="mt-5">
                                  <p style={{ textAlign: "center" }}>
                                    --#{index_item + 1}--
                                  </p>
                                </Row>
                                <Row className="mt-5">
                                  <Col xs={12} md={12} lg={6}>
                                    <ActionButton
                                      configuration={{
                                        type: 4,
                                        icon: "trash",
                                        variant: "outline-danger",
                                        title: "Quitar de la lista",
                                        lock:
                                          props.formConfig.locked ||
                                          props.lockForm,
                                      }}
                                      action={() => {
                                        delFromList(index, index_item);
                                      }}
                                    />
                                  </Col>
                                  <Col xs={12} md={12} lg={6}>
                                    <ActionButton
                                      configuration={{
                                        type: 4,
                                        icon: "plus",
                                        variant: "outline-success",
                                        title: "Agregar " + actual.item_name,
                                        lock:
                                          props.formConfig.locked ||
                                          props.lockForm,
                                      }}
                                      action={() => {
                                        addRow(index, index_item);
                                      }}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  {actual.head.map(
                                    (actual_head, index_head) => (
                                      <Container
                                        fluid
                                        key={"head-" + index_head}
                                      >
                                        {actual_head.html_text && (
                                          <Row className="mt-5">
                                            <div
                                              className="mb-5"
                                              dangerouslySetInnerHTML={{
                                                __html: DOMPurify.sanitize(
                                                  actual_head.title
                                                ),
                                              }}
                                            ></div>
                                          </Row>
                                        )}
                                        {!actual_head.html_text && (
                                          <Row className="mt-5">
                                            <h5>{actual_head.title}</h5>
                                          </Row>
                                        )}
                                        <Row key={"head-" + index_head}>
                                          {actual_head.type == 1 && (
                                            <Form.Group
                                              controlId={actual_head.name}
                                            >
                                              <Form.Control
                                                required
                                                type="text"
                                                placeholder={
                                                  actual_head.placeholder
                                                }
                                                value={
                                                  item_actual.head[index_head]
                                                    .value
                                                }
                                                onChange={onChangeMaster}
                                                name={
                                                  index +
                                                  "-" +
                                                  index_item +
                                                  "-" +
                                                  index_head
                                                }
                                                disabled={
                                                  cargando ||
                                                  props.formConfig.locked ||
                                                  props.lockForm
                                                }
                                              />
                                              <Form.Control.Feedback>
                                                Ok!
                                              </Form.Control.Feedback>
                                              <Form.Control.Feedback type="invalid">
                                                Este campo presenta error
                                              </Form.Control.Feedback>
                                            </Form.Group>
                                          )}
                                          {actual_head.type == 2 && (
                                            <Form.Group
                                              controlId={actual_head.name}
                                            >
                                              <Form.Control
                                                required
                                                type="password"
                                                placeholder={
                                                  actual_head.placeholder
                                                }
                                                value={
                                                  item_actual.head[index_head]
                                                    .value
                                                }
                                                onChange={onChangeMaster}
                                                name={
                                                  index +
                                                  "-" +
                                                  index_item +
                                                  "-" +
                                                  index_head
                                                }
                                                disabled={
                                                  cargando ||
                                                  props.formConfig.locked ||
                                                  props.lockForm
                                                }
                                              />
                                              <Form.Control.Feedback>
                                                Ok!
                                              </Form.Control.Feedback>
                                              <Form.Control.Feedback type="invalid">
                                                Este campo presenta error
                                              </Form.Control.Feedback>
                                            </Form.Group>
                                          )}
                                          {actual_head.type == 3 && (
                                            <Form.Group
                                              controlId={actual_head.name}
                                            >
                                              <Form.Select
                                                required
                                                placeholder={
                                                  actual_head.placeholder
                                                }
                                                value={
                                                  item_actual.head[index_head]
                                                    .value
                                                }
                                                onChange={onChangeMaster}
                                                name={
                                                  index +
                                                  "-" +
                                                  index_item +
                                                  "-" +
                                                  index_head
                                                }
                                                disabled={
                                                  cargando ||
                                                  props.formConfig.locked ||
                                                  props.lockForm
                                                }
                                              >
                                                <option></option>
                                                {actual_head.options &&
                                                  actual_head.options.map(
                                                    (current, indice) => (
                                                      <option
                                                        style={{
                                                          width: "100%",
                                                          boxSizing:
                                                            "border-box",
                                                        }}
                                                        key={
                                                          "opcion-" +
                                                          indice +
                                                          "-" +
                                                          index
                                                        }
                                                        value={current.value}
                                                      >
                                                        {current.label}
                                                      </option>
                                                    )
                                                  )}
                                              </Form.Select>
                                              <Form.Control.Feedback>
                                                Ok!
                                              </Form.Control.Feedback>
                                              <Form.Control.Feedback type="invalid">
                                                Este campo presenta error
                                              </Form.Control.Feedback>
                                            </Form.Group>
                                          )}
                                          {actual_head.type == 4 && (
                                            <Form.Group
                                              controlId={actual_head.name}
                                            >
                                              <Form.Control
                                                required
                                                type="email"
                                                placeholder={
                                                  actual_head.placeholder
                                                }
                                                value={
                                                  item_actual.head[index_head]
                                                    .value
                                                }
                                                onChange={onChangeMaster}
                                                name={
                                                  index +
                                                  "-" +
                                                  index_item +
                                                  "-" +
                                                  index_head
                                                }
                                                disabled={
                                                  cargando ||
                                                  props.formConfig.locked ||
                                                  props.lockForm
                                                }
                                              />
                                              <Form.Control.Feedback>
                                                Ok!
                                              </Form.Control.Feedback>
                                              <Form.Control.Feedback type="invalid">
                                                Este campo presenta error
                                              </Form.Control.Feedback>
                                            </Form.Group>
                                          )}
                                          {actual_head.type == 5 && (
                                            <Form.Group
                                              controlId={actual_head.name}
                                            >
                                              <Form.Control
                                                required
                                                type="date"
                                                placeholder={
                                                  actual_head.placeholder
                                                }
                                                value={
                                                  item_actual.head[index_head]
                                                    .value
                                                }
                                                onChange={onChangeMaster}
                                                name={
                                                  index +
                                                  "-" +
                                                  index_item +
                                                  "-" +
                                                  index_head
                                                }
                                                disabled={
                                                  cargando ||
                                                  props.formConfig.locked ||
                                                  props.lockForm
                                                }
                                              />
                                              <Form.Control.Feedback>
                                                Ok!
                                              </Form.Control.Feedback>
                                              <Form.Control.Feedback type="invalid">
                                                Este campo presenta error
                                              </Form.Control.Feedback>
                                            </Form.Group>
                                          )}
                                          {actual_head.type == 6 && (
                                            <Form.Group
                                              controlId={actual_head.name}
                                            >
                                              <Form.Control
                                                required
                                                type="file"
                                                placeholder={actual.placeholder}
                                                onChange={onChangeMaster}
                                                name={
                                                  index +
                                                  "-" +
                                                  index_item +
                                                  "-" +
                                                  index_head
                                                }
                                                disabled={
                                                  cargando ||
                                                  props.formConfig.locked ||
                                                  props.lockForm
                                                }
                                              />
                                              <Form.Control.Feedback>
                                                Ok!
                                              </Form.Control.Feedback>
                                              <Form.Control.Feedback type="invalid">
                                                Este campo presenta error
                                              </Form.Control.Feedback>
                                            </Form.Group>
                                          )}
                                          {actual_head.type == 7 && (
                                            <Form.Group
                                              controlId={actual_head.name}
                                            >
                                              <Form.Control
                                                required
                                                type="number"
                                                placeholder={
                                                  actual_head.placeholder
                                                }
                                                value={
                                                  item_actual.head[index_head]
                                                    .value
                                                }
                                                step={actual_head.step}
                                                onChange={onChangeMaster}
                                                name={
                                                  index +
                                                  "-" +
                                                  index_item +
                                                  "-" +
                                                  index_head
                                                }
                                                disabled={
                                                  cargando ||
                                                  props.formConfig.locked ||
                                                  props.lockForm
                                                }
                                              />
                                              <Form.Control.Feedback>
                                                Ok!
                                              </Form.Control.Feedback>
                                              <Form.Control.Feedback type="invalid">
                                                Este campo presenta error
                                              </Form.Control.Feedback>
                                            </Form.Group>
                                          )}
                                          {actual_head.type == 8 && (
                                            <Form.Group
                                              controlId={actual_head.name}
                                            >
                                              <Form.Control
                                                required
                                                as="textarea"
                                                style={{ resize: "none" }}
                                                rows={5}
                                                placeholder={
                                                  actual_head.placeholder
                                                }
                                                value={
                                                  item_actual.head[index_head]
                                                    .value
                                                }
                                                onChange={onChangeMaster}
                                                name={
                                                  index +
                                                  "-" +
                                                  index_item +
                                                  "-" +
                                                  index_head
                                                }
                                                disabled={
                                                  cargando ||
                                                  props.formConfig.locked ||
                                                  props.lockForm
                                                }
                                              />
                                              <Form.Control.Feedback>
                                                Ok!
                                              </Form.Control.Feedback>
                                              <Form.Control.Feedback type="invalid">
                                                Este campo presenta error
                                              </Form.Control.Feedback>
                                            </Form.Group>
                                          )}
                                          {actual_head.type == 13 && (
                                            <Form.Group
                                              controlId={actual_head.name}
                                            >
                                              <Form.Control
                                                required
                                                type="datetime-local"
                                                placeholder={
                                                  actual_head.placeholder
                                                }
                                                value={
                                                  item_actual.head[index_head]
                                                    .value
                                                }
                                                onChange={onChangeMaster}
                                                name={
                                                  index +
                                                  "-" +
                                                  index_item +
                                                  "-" +
                                                  index_head
                                                }
                                                disabled={
                                                  cargando ||
                                                  props.formConfig.locked ||
                                                  props.lockForm
                                                }
                                              />
                                              <Form.Control.Feedback>
                                                Ok!
                                              </Form.Control.Feedback>
                                              <Form.Control.Feedback type="invalid">
                                                Este campo presenta error
                                              </Form.Control.Feedback>
                                            </Form.Group>
                                          )}
                                          {actual_head.type == 14 && (
                                            <Editor
                                              apiKey={
                                                process.env
                                                  .REACT_APP_API_KEY_EDITOR
                                              }
                                              init={{
                                                plugins:
                                                  "lists link image paste wordcount",
                                                toolbar:
                                                  "undo redo | blocks | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent",
                                              }}
                                              value={
                                                item_actual.head[index_head]
                                                  .value
                                              }
                                              id={
                                                index +
                                                "-" +
                                                index_item +
                                                "-" +
                                                index_head
                                              }
                                              disabled={
                                                cargando ||
                                                props.formConfig.locked ||
                                                props.lockForm
                                              }
                                              onEditorChange={
                                                onChangeEditorMaster
                                              }
                                            />
                                          )}
                                        </Row>
                                      </Container>
                                    )
                                  )}
                                  {item_actual.rows.map(
                                    (actual_rows, index_rows) => (
                                      <Container
                                        fluid
                                        key={"row-" + index_rows}
                                      >
                                        <Row className="mt-5">
                                          <Col xs={12} md={6} lg={4}>
                                            <ActionButton
                                              configuration={{
                                                type: 4,
                                                icon: "trash",
                                                variant: "outline-danger",
                                                title: "Quitar de la lista",
                                                lock:
                                                  props.formConfig.locked ||
                                                  props.lockForm,
                                              }}
                                              action={() => {
                                                delRow(
                                                  index,
                                                  index_item,
                                                  index_rows
                                                );
                                              }}
                                            />
                                          </Col>
                                        </Row>
                                        <Row key={"row-" + index_rows}>
                                          {actual.fields.map(
                                            (current, indicador) => (
                                              <Container
                                                fluid
                                                key={
                                                  "item-form-master-list-" +
                                                  indicador
                                                }
                                              >
                                                {current.html_text && (
                                                  <Row className="mt-5">
                                                    <div
                                                      className="mb-5"
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          DOMPurify.sanitize(
                                                            current.title
                                                          ),
                                                      }}
                                                    ></div>
                                                  </Row>
                                                )}
                                                {!current.html_text && (
                                                  <Row className="mt-5">
                                                    <h5>{current.title}</h5>
                                                  </Row>
                                                )}
                                                <Row>
                                                  {current.type == 1 && (
                                                    <Form.Group
                                                      controlId={current.name}
                                                    >
                                                      <Form.Control
                                                        required
                                                        type="text"
                                                        placeholder={
                                                          current.placeholder
                                                        }
                                                        value={
                                                          actual_rows[indicador]
                                                            .value
                                                        }
                                                        onChange={onChangeRow}
                                                        name={
                                                          index +
                                                          "-" +
                                                          index_item +
                                                          "-" +
                                                          index_rows +
                                                          "-" +
                                                          indicador
                                                        }
                                                        disabled={
                                                          current.lock ||
                                                          cargando ||
                                                          props.formConfig
                                                            .locked ||
                                                          props.lockForm
                                                        }
                                                      />
                                                      <Form.Control.Feedback>
                                                        Ok!
                                                      </Form.Control.Feedback>
                                                      <Form.Control.Feedback type="invalid">
                                                        Este campo presenta
                                                        error
                                                      </Form.Control.Feedback>
                                                    </Form.Group>
                                                  )}
                                                  {current.type == 2 && (
                                                    <Form.Group
                                                      controlId={current.name}
                                                    >
                                                      <Form.Control
                                                        required
                                                        type="password"
                                                        placeholder={
                                                          current.placeholder
                                                        }
                                                        value={
                                                          actual_rows[indicador]
                                                            .value
                                                        }
                                                        onChange={onChangeRow}
                                                        name={
                                                          index +
                                                          "-" +
                                                          index_item +
                                                          "-" +
                                                          index_rows +
                                                          "-" +
                                                          indicador
                                                        }
                                                        disabled={
                                                          current.lock ||
                                                          cargando ||
                                                          props.formConfig
                                                            .locked ||
                                                          props.lockForm
                                                        }
                                                      />
                                                      <Form.Control.Feedback>
                                                        Ok!
                                                      </Form.Control.Feedback>
                                                      <Form.Control.Feedback type="invalid">
                                                        Este campo presenta
                                                        error
                                                      </Form.Control.Feedback>
                                                    </Form.Group>
                                                  )}
                                                  {current.type == 3 && (
                                                    <Form.Group
                                                      controlId={current.name}
                                                    >
                                                      <Form.Select
                                                        required
                                                        placeholder={
                                                          current.placeholder
                                                        }
                                                        value={
                                                          actual_rows[indicador]
                                                            .value
                                                        }
                                                        onChange={onChangeRow}
                                                        name={
                                                          index +
                                                          "-" +
                                                          index_item +
                                                          "-" +
                                                          index_rows +
                                                          "-" +
                                                          indicador
                                                        }
                                                        disabled={
                                                          current.lock ||
                                                          cargando ||
                                                          props.formConfig
                                                            .locked ||
                                                          props.lockForm
                                                        }
                                                      >
                                                        <option></option>
                                                        {current.options &&
                                                          current.options.map(
                                                            (
                                                              current,
                                                              indice
                                                            ) => (
                                                              <option
                                                                key={
                                                                  "opcion-" +
                                                                  indice +
                                                                  "-" +
                                                                  index
                                                                }
                                                                value={
                                                                  current.value
                                                                }
                                                                style={{
                                                                  width: "100%",
                                                                  boxSizing:
                                                                    "border-box",
                                                                }}
                                                              >
                                                                {current.label}
                                                              </option>
                                                            )
                                                          )}
                                                      </Form.Select>
                                                      <Form.Control.Feedback>
                                                        Ok!
                                                      </Form.Control.Feedback>
                                                      <Form.Control.Feedback type="invalid">
                                                        Este campo presenta
                                                        error
                                                      </Form.Control.Feedback>
                                                    </Form.Group>
                                                  )}
                                                  {current.type == 4 && (
                                                    <Form.Group
                                                      controlId={current.name}
                                                    >
                                                      <Form.Control
                                                        required
                                                        type="email"
                                                        placeholder={
                                                          current.placeholder
                                                        }
                                                        value={
                                                          actual_rows[indicador]
                                                            .value
                                                        }
                                                        onChange={onChangeRow}
                                                        name={
                                                          index +
                                                          "-" +
                                                          index_item +
                                                          "-" +
                                                          index_rows +
                                                          "-" +
                                                          indicador
                                                        }
                                                        disabled={
                                                          current.lock ||
                                                          cargando ||
                                                          props.formConfig
                                                            .locked ||
                                                          props.lockForm
                                                        }
                                                      />
                                                      <Form.Control.Feedback>
                                                        Ok!
                                                      </Form.Control.Feedback>
                                                      <Form.Control.Feedback type="invalid">
                                                        Este campo presenta
                                                        error
                                                      </Form.Control.Feedback>
                                                    </Form.Group>
                                                  )}
                                                  {current.type == 5 && (
                                                    <Form.Group
                                                      controlId={current.name}
                                                    >
                                                      <Form.Control
                                                        required
                                                        type="date"
                                                        placeholder={
                                                          current.placeholder
                                                        }
                                                        value={
                                                          actual_rows[indicador]
                                                            .value
                                                        }
                                                        onChange={onChangeRow}
                                                        name={
                                                          index +
                                                          "-" +
                                                          index_item +
                                                          "-" +
                                                          index_rows +
                                                          "-" +
                                                          indicador
                                                        }
                                                        disabled={
                                                          current.lock ||
                                                          cargando ||
                                                          props.formConfig
                                                            .locked ||
                                                          props.lockForm
                                                        }
                                                      />
                                                      <Form.Control.Feedback>
                                                        Ok!
                                                      </Form.Control.Feedback>
                                                      <Form.Control.Feedback type="invalid">
                                                        Este campo presenta
                                                        error
                                                      </Form.Control.Feedback>
                                                    </Form.Group>
                                                  )}
                                                  {current.type == 6 && (
                                                    <Form.Group
                                                      controlId={current.name}
                                                    >
                                                      <Form.Control
                                                        required
                                                        type="file"
                                                        placeholder={
                                                          actual.placeholder
                                                        }
                                                        onChange={onChangeRow}
                                                        name={
                                                          index +
                                                          "-" +
                                                          index_item +
                                                          "-" +
                                                          index_rows +
                                                          "-" +
                                                          indicador
                                                        }
                                                        disabled={
                                                          current.lock ||
                                                          cargando ||
                                                          props.formConfig
                                                            .locked ||
                                                          props.lockForm
                                                        }
                                                      />
                                                      <Form.Control.Feedback>
                                                        Ok!
                                                      </Form.Control.Feedback>
                                                      <Form.Control.Feedback type="invalid">
                                                        Este campo presenta
                                                        error
                                                      </Form.Control.Feedback>
                                                    </Form.Group>
                                                  )}
                                                  {current.type == 7 && (
                                                    <Form.Group
                                                      controlId={current.name}
                                                    >
                                                      <Form.Control
                                                        required
                                                        type="number"
                                                        placeholder={
                                                          current.placeholder
                                                        }
                                                        value={
                                                          actual_rows[indicador]
                                                            .value
                                                        }
                                                        step={actual_rows.step}
                                                        onChange={onChangeRow}
                                                        name={
                                                          index +
                                                          "-" +
                                                          index_item +
                                                          "-" +
                                                          index_rows +
                                                          "-" +
                                                          indicador
                                                        }
                                                        disabled={
                                                          current.lock ||
                                                          cargando ||
                                                          props.formConfig
                                                            .locked ||
                                                          props.lockForm
                                                        }
                                                      />
                                                      <Form.Control.Feedback>
                                                        Ok!
                                                      </Form.Control.Feedback>
                                                      <Form.Control.Feedback type="invalid">
                                                        Este campo presenta
                                                        error
                                                      </Form.Control.Feedback>
                                                    </Form.Group>
                                                  )}
                                                  {current.type == 8 && (
                                                    <Form.Group
                                                      controlId={current.name}
                                                    >
                                                      <Form.Control
                                                        required
                                                        as="textarea"
                                                        style={{
                                                          resize: "none",
                                                        }}
                                                        rows={5}
                                                        placeholder={
                                                          current.placeholder
                                                        }
                                                        value={
                                                          actual_rows[indicador]
                                                            .value
                                                        }
                                                        onChange={onChangeRow}
                                                        name={
                                                          index +
                                                          "-" +
                                                          index_item +
                                                          "-" +
                                                          index_rows +
                                                          "-" +
                                                          indicador
                                                        }
                                                        disabled={
                                                          current.lock ||
                                                          cargando ||
                                                          props.formConfig
                                                            .locked ||
                                                          props.lockForm
                                                        }
                                                      />
                                                      <Form.Control.Feedback>
                                                        Ok!
                                                      </Form.Control.Feedback>
                                                      <Form.Control.Feedback type="invalid">
                                                        Este campo presenta
                                                        error
                                                      </Form.Control.Feedback>
                                                    </Form.Group>
                                                  )}
                                                  {current.type == 13 && (
                                                    <Form.Group
                                                      controlId={current.name}
                                                    >
                                                      <Form.Control
                                                        required
                                                        type="datetime-local"
                                                        placeholder={
                                                          current.placeholder
                                                        }
                                                        value={
                                                          actual_rows[indicador]
                                                            .value
                                                        }
                                                        onChange={onChangeRow}
                                                        name={
                                                          index +
                                                          "-" +
                                                          index_item +
                                                          "-" +
                                                          index_rows +
                                                          "-" +
                                                          indicador
                                                        }
                                                        disabled={
                                                          current.lock ||
                                                          cargando ||
                                                          props.formConfig
                                                            .locked ||
                                                          props.lockForm
                                                        }
                                                      />
                                                      <Form.Control.Feedback>
                                                        Ok!
                                                      </Form.Control.Feedback>
                                                      <Form.Control.Feedback type="invalid">
                                                        Este campo presenta
                                                        error
                                                      </Form.Control.Feedback>
                                                    </Form.Group>
                                                  )}

                                                  {current.type == 14 && (
                                                    <Editor
                                                      apiKey={
                                                        process.env
                                                          .REACT_APP_API_KEY_EDITOR
                                                      }
                                                      init={{
                                                        plugins:
                                                          "lists link image paste wordcount",
                                                        toolbar:
                                                          "undo redo | blocks | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent",
                                                      }}
                                                      value={
                                                        actual_rows[indicador]
                                                          .value
                                                      }
                                                      id={
                                                        index +
                                                        "-" +
                                                        index_item +
                                                        "-" +
                                                        index_rows +
                                                        "-" +
                                                        indicador
                                                      }
                                                      disabled={
                                                        current.lock ||
                                                        cargando ||
                                                        props.formConfig
                                                          .locked ||
                                                        props.lockForm
                                                      }
                                                      onEditorChange={
                                                        onChangeEditorRow
                                                      }
                                                    />
                                                  )}
                                                </Row>
                                              </Container>
                                            )
                                          )}
                                        </Row>
                                      </Container>
                                    )
                                  )}
                                </Row>
                              </Container>
                            ))}
                          </Row>
                        </Container>
                      )}
                      {actual.type == 12 && (
                        <Container fluid>
                          <Row>
                            <p>{actual.subtitle}</p>
                          </Row>
                          {!actual.view && (
                            <Row>
                              <Col xs={12} md={12} lg={4}>
                                <ActionButton
                                  configuration={{
                                    type: 4,
                                    icon: "plus",
                                    variant: "outline-success",
                                    title: "Agregar",
                                    lock:
                                      props.formConfig.locked || props.lockForm,
                                  }}
                                  action={() => {
                                    addToListList(index);
                                  }}
                                />
                              </Col>
                            </Row>
                          )}
                          {actual.value.map((actual_lista, index_lista) => (
                            <Row
                              key={"actual-lista-" + index_lista}
                              className="mt-5"
                              style={{ borderRight: "3px solid #1e3d52" }}
                            >
                              <Container fluid>
                                <Row className="me-auto">
                                  <p style={{ textAlign: "center" }}>
                                    --#{index_lista + 1}--
                                  </p>
                                </Row>
                                {!actual.view && (
                                  <Row>
                                    <Col xs={12} md={6} lg={4}>
                                      <ActionButton
                                        configuration={{
                                          type: 4,
                                          icon: "trash",
                                          variant: "outline-danger",
                                          title: "Quitar de la lista",
                                          lock:
                                            props.formConfig.locked ||
                                            props.lockForm,
                                        }}
                                        action={() => {
                                          delFromList(index, index_lista);
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                )}
                                {actual.fields.map((current, indicador) => (
                                  <Row
                                    key={"item-form-master-list-" + indicador}
                                    className="mt-5"
                                  >
                                    {current.html_text && (
                                      <div
                                        className="mb-5"
                                        dangerouslySetInnerHTML={{
                                          __html: DOMPurify.sanitize(
                                            current.title
                                          ),
                                        }}
                                      ></div>
                                    )}
                                    {!current.html_text && (
                                      <h5>{current.title}</h5>
                                    )}

                                    {current.type == 1 && (
                                      <Form.Group controlId={current.name}>
                                        <Form.Control
                                          required
                                          type="text"
                                          placeholder={current.placeholder}
                                          value={actual_lista[indicador].value}
                                          onChange={onChangeListList}
                                          name={
                                            index +
                                            "-" +
                                            index_lista +
                                            "-" +
                                            indicador
                                          }
                                          disabled={
                                            current.lock ||
                                            cargando ||
                                            props.formConfig.locked ||
                                            props.lockForm
                                          }
                                        />
                                        <Form.Control.Feedback>
                                          Ok!
                                        </Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                          Este campo presenta error
                                        </Form.Control.Feedback>
                                      </Form.Group>
                                    )}
                                    {current.type == 2 && (
                                      <Form.Group controlId={current.name}>
                                        <Form.Control
                                          required
                                          type="password"
                                          placeholder={current.placeholder}
                                          value={actual_lista[indicador].value}
                                          onChange={onChangeListList}
                                          name={
                                            index +
                                            "-" +
                                            index_lista +
                                            "-" +
                                            indicador
                                          }
                                          disabled={
                                            current.lock ||
                                            cargando ||
                                            props.formConfig.locked ||
                                            props.lockForm
                                          }
                                        />
                                        <Form.Control.Feedback>
                                          Ok!
                                        </Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                          Este campo presenta error
                                        </Form.Control.Feedback>
                                      </Form.Group>
                                    )}
                                    {current.type == 3 && (
                                      <Form.Group controlId={current.name}>
                                        <Form.Select
                                          required
                                          placeholder={current.placeholder}
                                          value={actual_lista[indicador].value}
                                          onChange={onChangeListList}
                                          name={
                                            index +
                                            "-" +
                                            index_lista +
                                            "-" +
                                            indicador
                                          }
                                          disabled={
                                            current.lock ||
                                            cargando ||
                                            props.formConfig.locked ||
                                            props.lockForm
                                          }
                                        >
                                          <option></option>
                                          {current.options &&
                                            current.options.map(
                                              (current, indice) => (
                                                <option
                                                  key={
                                                    "opcion-" +
                                                    indice +
                                                    "-" +
                                                    index
                                                  }
                                                  value={current.value}
                                                  style={{
                                                    width: "100%",
                                                    boxSizing: "border-box",
                                                  }}
                                                >
                                                  {current.label}
                                                </option>
                                              )
                                            )}
                                        </Form.Select>
                                        <Form.Control.Feedback>
                                          Ok!
                                        </Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                          Este campo presenta error
                                        </Form.Control.Feedback>
                                      </Form.Group>
                                    )}
                                    {current.type == 4 && (
                                      <Form.Group controlId={current.name}>
                                        <Form.Control
                                          required
                                          type="email"
                                          placeholder={current.placeholder}
                                          value={actual_lista[indicador].value}
                                          onChange={onChangeListList}
                                          name={
                                            index +
                                            "-" +
                                            index_lista +
                                            "-" +
                                            indicador
                                          }
                                          disabled={
                                            current.lock ||
                                            cargando ||
                                            props.formConfig.locked ||
                                            props.lockForm
                                          }
                                        />
                                        <Form.Control.Feedback>
                                          Ok!
                                        </Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                          Este campo presenta error
                                        </Form.Control.Feedback>
                                      </Form.Group>
                                    )}
                                    {current.type == 5 && (
                                      <Form.Group controlId={current.name}>
                                        <Form.Control
                                          required
                                          type="date"
                                          placeholder={current.placeholder}
                                          value={actual_lista[indicador].value}
                                          onChange={onChangeListList}
                                          name={
                                            index +
                                            "-" +
                                            index_lista +
                                            "-" +
                                            indicador
                                          }
                                          disabled={
                                            current.lock ||
                                            cargando ||
                                            props.formConfig.locked ||
                                            props.lockForm
                                          }
                                        />
                                        <Form.Control.Feedback>
                                          Ok!
                                        </Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                          Este campo presenta error
                                        </Form.Control.Feedback>
                                      </Form.Group>
                                    )}
                                    {current.type == 6 && (
                                      <Form.Group controlId={current.name}>
                                        <Form.Control
                                          required
                                          type="file"
                                          placeholder={actual.placeholder}
                                          onChange={onChangeListList}
                                          name={
                                            index +
                                            "-" +
                                            index_lista +
                                            "-" +
                                            indicador
                                          }
                                          disabled={
                                            current.lock ||
                                            cargando ||
                                            props.formConfig.locked ||
                                            props.lockForm
                                          }
                                        />
                                        <Form.Control.Feedback>
                                          Ok!
                                        </Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                          Este campo presenta error
                                        </Form.Control.Feedback>
                                      </Form.Group>
                                    )}
                                    {current.type == 7 && (
                                      <Form.Group controlId={current.name}>
                                        <Form.Control
                                          required
                                          type="number"
                                          placeholder={current.placeholder}
                                          value={actual_lista[indicador].value}
                                          step={current.step}
                                          onChange={onChangeListList}
                                          name={
                                            index +
                                            "-" +
                                            index_lista +
                                            "-" +
                                            indicador
                                          }
                                          disabled={
                                            current.lock ||
                                            cargando ||
                                            props.formConfig.locked ||
                                            props.lockForm
                                          }
                                        />
                                        <Form.Control.Feedback>
                                          Ok!
                                        </Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                          Este campo presenta error
                                        </Form.Control.Feedback>
                                      </Form.Group>
                                    )}
                                    {current.type == 8 && (
                                      <Form.Group controlId={current.name}>
                                        <Form.Control
                                          required
                                          as="textarea"
                                          style={{ resize: "none" }}
                                          rows={5}
                                          placeholder={current.placeholder}
                                          value={actual_lista[indicador].value}
                                          onChange={onChangeListList}
                                          name={
                                            index +
                                            "-" +
                                            index_lista +
                                            "-" +
                                            indicador
                                          }
                                          disabled={
                                            current.lock ||
                                            cargando ||
                                            props.formConfig.locked ||
                                            props.lockForm
                                          }
                                        />
                                        <Form.Control.Feedback>
                                          Ok!
                                        </Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                          Este campo presenta error
                                        </Form.Control.Feedback>
                                      </Form.Group>
                                    )}
                                    {current.type == 13 && (
                                      <Form.Group controlId={current.name}>
                                        <Form.Control
                                          required
                                          type="datetime-local"
                                          placeholder={current.placeholder}
                                          value={actual_lista[indicador].value}
                                          onChange={onChangeListList}
                                          name={
                                            index +
                                            "-" +
                                            index_lista +
                                            "-" +
                                            indicador
                                          }
                                          disabled={
                                            current.lock ||
                                            cargando ||
                                            props.formConfig.locked ||
                                            props.lockForm
                                          }
                                        />
                                        <Form.Control.Feedback>
                                          Ok!
                                        </Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                          Este campo presenta error
                                        </Form.Control.Feedback>
                                      </Form.Group>
                                    )}
                                    {current.type == 14 && (
                                      <Editor
                                        apiKey={
                                          process.env.REACT_APP_API_KEY_EDITOR
                                        }
                                        init={{
                                          plugins:
                                            "lists link image paste wordcount",
                                          toolbar:
                                            "undo redo | blocks | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent",
                                        }}
                                        value={actual_lista[indicador].value}
                                        id={
                                          index +
                                          "-" +
                                          index_lista +
                                          "-" +
                                          indicador
                                        }
                                        disabled={
                                          current.lock ||
                                          cargando ||
                                          props.formConfig.locked ||
                                          props.lockForm
                                        }
                                        onEditorChange={onEditorChangeListList}
                                      />
                                    )}
                                  </Row>
                                ))}
                              </Container>
                            </Row>
                          ))}
                        </Container>
                      )}
                      {actual.type == 13 && (
                        <Form.Group controlId={actual.name}>
                          <Form.Control
                            required={actual.required}
                            type="datetime-local"
                            placeholder={actual.placeholder}
                            value={actual.value}
                            onChange={onChange}
                            name={index + "-" + actual.name}
                            disabled={
                              cargando ||
                              actual.lock ||
                              props.formConfig.locked ||
                              props.lockForm
                            }
                          />
                          <Form.Control.Feedback>Ok!</Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid">
                            Este campo presenta error
                          </Form.Control.Feedback>
                        </Form.Group>
                      )}
                      {actual.type == 14 && (
                        <Editor
                          apiKey={process.env.REACT_APP_API_KEY_EDITOR}
                          init={{
                            plugins: "lists link image paste wordcount",
                            toolbar:
                              "undo redo | blocks | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent",
                          }}
                          value={actual.value}
                          id={index + "-" + actual.name}
                          onEditorChange={onEditorChange}
                          disabled={
                            cargando ||
                            actual.lock ||
                            props.formConfig.locked ||
                            props.lockForm
                          }
                        />
                      )}
                      {actual.type == 15 && (
                        <Container fluid>
                          {actual.options &&
                            actual.options.map((current, indice) => (
                              <Row
                                style={{ borderRight: "3px solid #1e3d52" }}
                                className="mt-5"
                              >
                                <p style={{ textAlign: "center" }}>
                                  Opción #{indice + 1}
                                </p>
                                <div
                                  className="mb-5"
                                  dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(current.value),
                                  }}
                                ></div>
                                <Form.Group controlId={actual.name}>
                                  <Form.Check
                                    key={index + "-opcion-" + indice}
                                    name={index + "-opcion-" + indice}
                                    id={index + "-opcion-" + indice}
                                    required={
                                      actual.required &&
                                      (actual.value == "" ||
                                        actual.value == null)
                                    }
                                    label="Seleccionar esta opción"
                                    type="radio"
                                    checked={current.value == actual.value}
                                    value={current.value}
                                    onChange={onChange}
                                    disabled={
                                      cargando ||
                                      actual.lock ||
                                      props.formConfig.locked ||
                                      props.lockForm
                                    }
                                  />
                                  <Form.Control.Feedback>
                                    Ok!
                                  </Form.Control.Feedback>
                                  <Form.Control.Feedback type="invalid">
                                    Este campo presenta error
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Row>
                            ))}
                        </Container>
                      )}
                    </Row>
                  </Container>
                </Row>
              ))}
            {props.formConfig.submitTitle && (
              <Row className="mt-4">
                <Button
                  id="boton-submit-form"
                  type="submit"
                  disabled={
                    cargando || props.formConfig.locked || props.lockForm
                  }
                >
                  {props.formConfig.submitTitle}
                </Button>
              </Row>
            )}
          </Container>
        </Form>
      </Row>
      <Row>
        <MessageModal
          show={showModal}
          title="INFORMACIÓN"
          text={mensaje}
          onClose={() => {
            setShowModal(false);
            setCambios(cambios + 1);
          }}
        />
      </Row>
    </Container>
  );
};

export default Formulario;
