import React, { useState, useEffect } from 'react'
import { Row, ListGroup, Container } from "react-bootstrap";
import ModalSeguimiento from './ModalSeguimiento';
import { Confirmacion } from '../../../Utils/ConfirmationModal/Confirmacion';
import { SuccessModal } from '../../../Utils/SuccessModal/SuccessModal';
import { ErrorModal } from '../../../Utils/ErrorModal/ErrorModal';
//import { DEFAULT_URL } from '../../../../api/url';
const Cancelados = (props) => {
    const [modalSeguimiento, setModalSeguimiento] = useState(false);
    const [seguimientoActual, setSeguimientoActual] = useState({});
    const [confirmacionEliminar, setConfirmacionEliminar] = useState(false);
    const [confirmacionRestaurar, setConfirmacionRestaurar] = useState(false);
    const [contador, setContador] = useState(0);
    const [mensaje, setMensaje] = useState('');
    const [idActual, setIdActual] = useState('');
    const [consideracion, setConsideracion] = useState('');
    const [modalError, setModalError] = useState(false);
    const [modalSucc, setModalSucc] = useState(false);
    useEffect(() => {

    }, [contador]);
    const ocultarRestaurarConfirmacion = () => {
        setConfirmacionRestaurar(false);
    }
    const ocultarEliminarConfirmacion = () => {
        setConfirmacionEliminar(false);
    }
    const ocultarSeguimiento = () => {
        setModalSeguimiento(false);
    }
    const ocultar = () => {
        setModalSucc(false);
        setModalError(false);
        props.actualizar();
        setContador(contador + 1);
    }
    const restaurarLead = (id, param) => {
        ocultarRestaurarConfirmacion();
        const info = { UniqueID: id };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/update/leads/restaurar', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                if (data.codigo == 200) {
                    setModalSucc(true);
                    setMensaje('Lead restaurado exitosamente');
                } else {
                    setModalError(true);
                    setMensaje('ERROR');
                }
            });
        ocultar();
    }
    const eliminar = (id, param) => {
        ocultarEliminarConfirmacion();
        const info = { UniqueID: id };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/delete/leads', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                if (data.codigo == 200) {
                    setModalSucc(true);
                    setMensaje('Lead eliminado exitosamente');
                } else {
                    setModalError(true);
                    setMensaje('ERROR');
                }
            });
        ocultar();
    }
    return (
        <Container>
            {props.leads.map((lead) =>
                <Row style={{ padding: '10px' }}>
                    <ListGroup as="ol">
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start"
                        >
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">{lead.nombre}</div>
                                {lead.fecha}
                            </div>
                            <div data-toggle="tooltip" data-placement="top" title="Ver seguimiento" style={{ cursor: 'pointer', color: 'black' }} onClick={() => { setSeguimientoActual(lead); setModalSeguimiento(true); }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16">
                                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                    <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                </svg>
                            </div>
                            <div data-toggle="tooltip" data-placement="top" title="Restaurar lead" style={{ cursor: 'pointer', color: 'black' }} onClick={() => { setMensaje('¿Está segur@ de restaurar este lead?'); setConsideracion('Esta acción es reversible'); setIdActual(lead.UniqueID); setConfirmacionRestaurar(true); }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-lightbulb" viewBox="0 0 16 16">
                                    <path d="M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13a.5.5 0 0 1 0 1 .5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1 0-1 .5.5 0 0 1 0-1 .5.5 0 0 1-.46-.302l-.761-1.77a1.964 1.964 0 0 0-.453-.618A5.984 5.984 0 0 1 2 6zm6-5a5 5 0 0 0-3.479 8.592c.263.254.514.564.676.941L5.83 12h4.342l.632-1.467c.162-.377.413-.687.676-.941A5 5 0 0 0 8 1z" />
                                </svg>
                            </div>
                            <div data-toggle="tooltip" data-placement="top" title="Eliminar lead" style={{ cursor: 'pointer', color: 'black' }} onClick={() => { setMensaje('¿Está segur@ de eliminar este lead?'); setConsideracion('Esta acción es ireversible'); setIdActual(lead.UniqueID); setConfirmacionEliminar(true); }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-trash3" viewBox="0 0 16 16">
                                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                </svg>
                            </div>
                        </ListGroup.Item>
                    </ListGroup>
                </Row>
            )}
            <ModalSeguimiento show={modalSeguimiento} onHide={ocultarSeguimiento} lead={seguimientoActual} fullscreen={true} seguimiento={props.seguimiento} />
            <Confirmacion
                show={confirmacionRestaurar}
                onHide={ocultarRestaurarConfirmacion}
                accion={restaurarLead}
                mensaje={mensaje}
                uniqueid={idActual}
                consideracion={consideracion}
                param=''
            />
            <Confirmacion
                show={confirmacionEliminar}
                onHide={ocultarEliminarConfirmacion}
                accion={eliminar}
                mensaje={mensaje}
                uniqueid={idActual}
                consideracion={consideracion}
                param=''
            />
            <ErrorModal
                color={"#b5bad0"}
                show={modalError}
                onHide={ocultar}
                mensaje={mensaje}
                consideracion={consideracion}
            />
            <SuccessModal
                color={"#b5bad0"}
                show={modalSucc}
                onHide={ocultar}
                mensaje={mensaje}
                consideracion={consideracion}
                enlace={''}
                llevaenlace={false}
            />
        </Container>
    )
}

export default Cancelados