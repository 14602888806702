import React, { useState, useEffect } from 'react'
//import DEFAULT_URL from '../../../../../api/url';
import { ListGroup, Row, Breadcrumb, Col, Tab, Dropdown, Container, ButtonGroup, DropdownButton, Button } from "react-bootstrap";
import NuevoFormulario from './NuevoFormulario';
import DetalleFormulario from './DetalleFormulario';
const DetalleFormularios = (props) => {
    const [contador, setContador] = useState(0);
    const [nuevoFormulario, setNuevoFormulario] = useState('none');
    const [formularios, setFormularios] = useState([]);
    const [allFormularios, setAllFormularios] = useState('block');
    const [formularioDet, setFormularioDet] = useState('none');
    useEffect(() => {
        async function fetchData() {
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/formulario/evento/' + props.evento.UniqueID, { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setFormularios(data.formularios);
                });
        }
        fetchData();
    }, [contador])
    const refrescarInfo = () => {
        setContador(contador + 1);
    }
    const back = () => {
        setFormularioDet('none');
        setAllFormularios('block');
        setContador(contador + 1);
    }
    const goDetalle = () => {
        setFormularioDet('block');
        setAllFormularios('none');
        setContador(contador + 1);
    }
    return (
        <Container>
            <Row style={{ display: allFormularios }}>
                <Breadcrumb>
                    <Breadcrumb.Item active>{props.evento.Nombre}</Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        Formularios
                    </Breadcrumb.Item>
                </Breadcrumb>
            </Row>
            <Row style={{ display: allFormularios }}>
                <DropdownButton
                    as={ButtonGroup}
                    title="Acciones"
                    id="bg-vertical-dropdown-2"
                    variant="dark"
                >
                    <Dropdown.Item onClick={() => { setNuevoFormulario('block'); setAllFormularios('none'); setContador(contador + 1); }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                        </svg>&nbsp;&nbsp;&nbsp;Nuevo Formulario
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setContador(contador + 1)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                            <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                        </svg>&nbsp;&nbsp;&nbsp;Refrescar
                    </Dropdown.Item>
                </DropdownButton>
            </Row>
            <Row style={{ padding: '20px' }}>
                <Tab.Container>
                    <ListGroup style={{ display: allFormularios }}>
                        <Col xs={12} md={12} lg={12}>
                            <Row>
                                {formularios.map(formulario =>
                                    <Col xs={12} md={12} lg={4} onClick={goDetalle}>
                                        <ListGroup.Item key={formulario.UniqueID} variant="light" action href={"#form-" + formulario.UniqueID}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-ui-radios-grid" viewBox="0 0 16 16">
                                                <path d="M3.5 15a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5zm9-9a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5zm0 9a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zM16 3.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0zm-9 9a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0zm5.5 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zm-9-11a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 2a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                            </svg>&nbsp;&nbsp;&nbsp;{formulario.titulo}
                                        </ListGroup.Item>
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    </ListGroup>
                    <Col xs={12} md={12} lg={12} style={{ display: formularioDet }}>
                        <Row>
                            <Tab.Content>
                                {formularios.map(formulario =>
                                    <Tab.Pane key={formulario.UniqueID} eventKey={"#form-" + formulario.UniqueID} href={"#form-" + formulario.UniqueID}>
                                        <Container>
                                            <DetalleFormulario formulario={formulario} refrescarInfo={refrescarInfo} back={back} evento={props.evento.Nombre} lock={props.lock} unlock={props.unlock} />
                                        </Container>
                                    </Tab.Pane>
                                )}
                            </Tab.Content>
                        </Row>
                    </Col>
                </Tab.Container>
            </Row>
            <Row style={{ display: nuevoFormulario }}>
                <NuevoFormulario evento={props.evento} />
                <Button variant='outline-dark' style={{ marginTop: '15px' }} onClick={() => { setAllFormularios('block'); setNuevoFormulario('none'); setContador(contador + 1); }}>Atrás</Button>
            </Row>
        </Container>
    )
}

export default DetalleFormularios