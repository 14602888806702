import React, { useState, useEffect } from 'react'
import { Modal, Row, Button, Col, Form, Container } from "react-bootstrap";
//import DEFAULT_URL from '../../../api/url';
//import { DOWNLOAD_URL }} from '../../../api/url';
import axios from 'axios';
import { SuccessModal } from '../../Utils/SuccessModal/SuccessModal';
import { ErrorModal } from '../../Utils/ErrorModal/ErrorModal';
import { ModalCargando } from '../../Utils/Modal-Cargando/ModalCargando';
const ModalFoto = (props) => {
    const [modalSucc, setModalSucc] = useState(false);
    const [mensaje, setMensaje] = useState('');
    const [modalError, setModalError] = useState(false);
    const [modalCargando, setModalCargando] = useState(false);
    const [mensajeCargando, setMensajeCargando] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [contador, setContador] = useState(0);
    useEffect(() => {

    }, [contador])
    const ocultar = () => {
        setModalSucc(false);
        setModalError(false);
    }
    const ocultarModalCargando = () => {
        setModalCargando(false);
    }
    const onChangeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setContador(contador + 1);
    };
    const submitForm = (evt) => {
        evt.preventDefault();
        const data = new FormData();
        data.append('file', selectedFile);
        setModalCargando(true);
        setMensajeCargando('Se está subiendo el archivo al servidor, por favor espere.');
        axios.post(process.env.REACT_APP_DEFAULT_URL+ "/api/files/upload", data, {
        }).then(res => {
            if (res.status == 200) {
                props.asignar(process.env.REACT_APP_DOWNLOAD_URL + res.data.res);
                props.onHide();
            } else {
                setMensaje(res.data);
                setModalError(true);
            }
        });
    }
    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
            backdrop='static'
        >
            <Modal.Header style={{ backgroundColor: "#E0E0E2" }}>
                <Modal.Title id="contained-modal-title-vcenter" className="font-bold">
                    Subir foto de perfil
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: "#E0E0E2", height: '60vh' }} id="body-modal-edit-evento">
                <Container>
                    <Form onSubmit={submitForm}>
                        <Row>
                            <Form.Group controlId="formFileMultiple" className="mb-3">
                                <Form.Label>Seleccione el archivo</Form.Label>
                                <Form.Control required type="file" onChange={onChangeHandler} />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Col>
                                <Button variant="secondary" className="font-bold" type="submit">Guardar</Button>
                            </Col>
                        </Row>
                    </Form>
                </Container>
                <SuccessModal
                    color={"#b5bad0"}
                    show={modalSucc}
                    onHide={ocultar}
                    mensaje={mensaje}
                    consideracion={''}
                    enlace={''}
                    llevaenlace={false}
                />
                <ErrorModal
                    color={"#b5bad0"}
                    show={modalError}
                    onHide={ocultar}
                    mensaje={mensaje}
                    consideracion={''}
                />
                <ModalCargando
                    color={"#b5bad0"}
                    show={modalCargando}
                    onHide={ocultarModalCargando}
                    mensaje={mensajeCargando}
                />
            </Modal.Body>
        </Modal>
    )
}

export default ModalFoto