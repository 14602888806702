import React, { useState, useEffect } from 'react'
//import DEFAULT_URL from '../../../../../api/url';
import { Row, Alert, Button, Spinner, Container, Form } from "react-bootstrap";
import axios from 'axios';
const Planos = (props) => {
    const [contador, setContador] = useState(0);
    const [formulario, setFormulario] = useState('block');
    const [exito, setExito] = useState('none');
    const [error, setError] = useState('none');
    const [cargando, setCargando] = useState('none');
    const [mensaje, setMensaje] = useState('');
    const [cambios, setCambios] = useState(0);
    const [file, setFile] = useState(null);
    useEffect(() => {
        /*
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/tipos/enlace', { cache: "no-cache" })
            .then((res) => {
                return res.json();
            })
            .then(data => {
                setTiposEnlace(data.rows);
            });
            */
    }, [contador]);
    useEffect(() => {

    }, [cambios]);

    const submit = (evt) => {
        evt.preventDefault();
        cargar('Subiendo video...');
        if (file != null) {
            const data = new FormData();
            data.append('file', file);
            axios.post(process.env.REACT_APP_DEFAULT_URL+ "/api/files/upload/s3", data, {}).then(res => {
                if (res.data.resultado.Location != null) {
                    cargar('Sincronizando base de datos...');
                    const info = {
                        planos: res.data.resultado.Location,
                        UniqueID: props.evento.UniqueID
                    };
                    const requestOptions = {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(info)
                    };
                    fetch(process.env.REACT_APP_DEFAULT_URL + '/api/evento/set-planos', requestOptions)
                        .then((res) => {
                            return res.json();
                        })
                        .then(data => {
                            if (data.codigo == 200) {
                                operacionExito('Planos subidos exitosamente');
                            } else {
                                operacionError('Error al grabar registro en db');
                            }
                        });

                } else {
                    operacionError('Ocurrió un error al subir los planos');
                }
            });
        } else {
            operacionError('Debes adjuntar una imagen.');

        }
    }
    const onChange = (evt) => {
        setFile(evt.target.files[0]);
        setCambios(cambios + 1);
    }

    const cargar = (mensaje) => {
        setExito('none');
        setFormulario('none');
        setError('none');
        setCargando('block');
        setMensaje(mensaje);
        setCambios(cambios + 1);
    }
    const operacionExito = (mensaje) => {
        setExito('block');
        setFormulario('none');
        setError('none');
        setCargando('none');
        setMensaje(mensaje);
        setContador(contador + 1);
    }
    const operacionError = (mensaje) => {
        setExito('none');
        setFormulario('none');
        setError('block');
        setCargando('none');
        setMensaje(mensaje);
        setCambios(cambios + 1);
    }
    const goBack = () => {
        setFile(null);
        setFormulario('block');
        setError('none');
        setCargando('none');
        setExito('none');
        props.refrescar();
        setCambios(cambios + 1);
    }
    return (
        <Row style={{ marginTop: '45px', border: '0.5px dashed black', borderRadius: '15px', padding: '25px' }}>
            <Container fluid>
                <Row style={{ padding: '2%' }}><h1>Planos de evento</h1></Row>
                {props.permiso == 4 && <Row style={{ display: formulario }}>
                    <Form onSubmit={submit}>
                        <Container fluid>
                            <Row>
                                <h3>Cambiar o subir planos</h3>
                            </Row>
                            <Row>
                                <Form.Group className="mb-3" controlId='file'>
                                    <Form.Label>Archivo planos</Form.Label>
                                    <Form.Control required type="file" accept="image/*" name='file' placeholder="Archivo planos" onChange={onChange} />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Button variant='outline-secondary' type='submit'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-upload" viewBox="0 0 16 16">
                                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                    <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
                                </svg>&nbsp;&nbsp;&nbsp;&nbsp;Subir planos</Button>
                            </Row>
                        </Container>
                    </Form>
                </Row>}
                {props.evento.planos != null && <Row style={{ padding: '2%' }}>
                    <Container fluid>
                        <Row>
                            <h4>Vista Previa Planos</h4>
                        </Row>
                        <Row>
                            <img src={props.evento.planos} />
                        </Row>
                    </Container>
                </Row>}
                <Row style={{ display: exito }}>
                    <Alert variant='success'>
                        <Alert.Heading>
                            Operación realizada con éxito
                        </Alert.Heading>
                        <p>
                            {mensaje}
                        </p>
                        <hr />
                        <div className="d-flex justify-content-end">
                            <Button variant='outline-success' onClick={goBack}>
                                Ok
                            </Button>
                        </div>
                    </Alert>
                </Row>
                <Row style={{ display: error }}>
                    <Alert variant='danger'>
                        <Alert.Heading>
                            Ocurrió un error al ingresar los planos
                        </Alert.Heading>
                        <p>
                            {mensaje}
                        </p>
                        <hr />
                        <div className="d-flex justify-content-end">
                            <Button variant='outline-danger' onClick={goBack}>
                                Ok
                            </Button>
                        </div>
                    </Alert>
                </Row>
                <Row style={{ display: cargando }}>
                    <Alert variant='dark'>
                        <Alert.Heading>
                            <Spinner animation="border" role="status">
                            </Spinner>&nbsp;&nbsp;&nbsp;
                            Ingresando información
                        </Alert.Heading>
                        <p>
                            {mensaje}
                        </p>
                        <hr />
                        <p>
                            Por favor espere...
                        </p>
                    </Alert>
                </Row>
            </Container>
        </Row>
    )
}

export default Planos