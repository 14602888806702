import React, { Component } from 'react';
import { Row, Button, Modal, Col, Form, Container } from "react-bootstrap";
//import DEFAULT_URL from '../../../../api/url';
export class ModalEditarCliente extends Component {
    state = {
        empresa: '',
        direccion: '',
        telefono: '',
        NombreFactura: '',
        NIT: '',
        CorreoFacturacion: '',
        ResponsableInscripcion: '',
        TelefonoResponsable: '',
        CorreoResponsable: '',
        ResponsablePago: '',
        CorreoPago: '',
        TelefonoPago: '',
        FormaPago: '',
        Empresa_UniqueID: '',
        formas_pago: [],
        Comentario: '',
        Prioridad: 0
    };
    componentDidMount = () => {
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/forma_pago', { cache: "no-cache" })
            .then((res) => {
                return res.json();
            })
            .then(data => {
                this.setState({ formas_pago: data.rows })
            });
    }
    onChange = (evt) => {
        this.setState({ [evt.target.name]: evt.target.value });
    }
    submit = (evt) => {
        evt.preventDefault();
        this.props.submitEdit(this.state);
    }
    onEnter = () => {
        if (this.props.tipo == '0') {
            this.setState(
                {
                    empresa: this.props.cliente.Empresa,
                    direccion: this.props.cliente.Direccion,
                    telefono: this.props.cliente.Telefono,
                    NombreFactura: this.props.cliente.NombreFactura,
                    NIT: this.props.cliente.NIT,
                    CorreoFacturacion: this.props.cliente.CorreoFacturacion,
                    ResponsableInscripcion: this.props.cliente.ResponsableInscripcion,
                    TelefonoResponsable: this.props.cliente.TelefonoResponsable,
                    CorreoResponsable: this.props.cliente.CorreoResponsable,
                    ResponsablePago: this.props.cliente.ResponsablePago,
                    CorreoPago: this.props.cliente.CorreoPago,
                    TelefonoPago: this.props.cliente.TelefonoPago,
                    FormaPago: this.props.cliente.FormaPago,
                    Empresa_UniqueID: this.props.cliente.Empresa_UniqueID,
                    Comentario: this.props.cliente.comentario,
                    Prioridad: this.props.cliente.tipo
                }
            );
        } else {
            this.setState(
                {
                    empresa: this.props.cliente.Empresa,
                    direccion: this.props.cliente.Direccion,
                    telefono: this.props.cliente.Telefono,
                    NombreFactura: this.props.cliente.NombreFactura,
                    NIT: this.props.cliente.NIT,
                    CorreoFacturacion: this.props.cliente.CorreoFacturacion,
                    ResponsableInscripcion: this.props.cliente.ResponsableInscripcion,
                    TelefonoResponsable: this.props.cliente.TelefonoResponsable,
                    CorreoResponsable: this.props.cliente.CorreoResponsable,
                    ResponsablePago: this.props.cliente.ResponsablePago,
                    CorreoPago: this.props.cliente.CorreoPago,
                    TelefonoPago: this.props.cliente.TelefonoPago,
                    FormaPago: this.props.cliente.FormaPago,
                    Empresa_UniqueID: this.props.cliente.UniqueID,
                    Comentario: this.props.cliente.comentario,
                    Prioridad: this.props.cliente.tipo
                }
            );
        }
    }
    render() {
        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
                onEnter={this.onEnter}
            >
                <Modal.Header closeButton style={{ backgroundColor: "#b5bad0" }}>
                    {this.props.tipo == '0' && <Modal.Title id="contained-modal-title-vcenter" className="font-bold">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-pencil-fill" viewBox="0 0 16 16">
                            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                        </svg>
                        &nbsp;&nbsp;&nbsp;Editar cliente
                    </Modal.Title>}
                    {this.props.tipo == '1' && <Modal.Title id="contained-modal-title-vcenter" className="font-bold">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-pencil-fill" viewBox="0 0 16 16">
                            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                        </svg>
                        &nbsp;&nbsp;&nbsp;Editar prospecto
                    </Modal.Title>}
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: "#b5bad0" }} className="cuerpo-modal-nuevo-cliente">
                    <Container>
                        <Form onSubmit={this.submit}>
                            <Row>
                                <Col xs={12} md={12}>
                                    <Form.Group className="mb-3" controlId="empresa">
                                        <Form.Label>Nombre De La Empresa o Cliente</Form.Label>
                                        <Form.Control required type="text" placeholder="Ingrese el nombre de la empresa" name="empresa" value={this.state.empresa} onChange={this.onChange} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Form.Group className="mb-3" controlId="direccion">
                                    <Form.Label>Direccion De La Empresa o Cliente</Form.Label>
                                    <Form.Control as="textarea" rows={3} style={{ resize: 'none' }} placeholder="Ingrese la dirección de la empresa" name="direccion" value={this.state.direccion} onChange={this.onChange} />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Col xs={12} md={12}>
                                    <Form.Group className="mb-3" controlId="telefono">
                                        <Form.Label>Teléfono De La Empresa</Form.Label>
                                        <Form.Control required type="text" placeholder="Ingrese el teléfono de la empresa" name="telefono" value={this.state.telefono} onChange={this.onChange} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12}>
                                    <Form.Group className="mb-3" controlId="NombreFactura">
                                        <Form.Label>Nombre Fiscal (para facturación)</Form.Label>
                                        <Form.Control required type="text" placeholder="Ingrese el nombre fiscal de la empresa" name="NombreFactura" value={this.state.NombreFactura} onChange={this.onChange} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12} lg={6}>
                                    <Form.Group className="mb-3" controlId="NIT">
                                        <Form.Label>NIT</Form.Label>
                                        <Form.Control required type="text" placeholder="Ingrese el NIT de la empresa" name="NIT" value={this.state.NIT} onChange={this.onChange} />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={12} lg={6}>
                                    <Form.Group className="mb-3" controlId="CorreoFacturacion">
                                        <Form.Label>Correo Para Recibir La Factura</Form.Label>
                                        <Form.Control required type="email" placeholder="Ingrese el correo para recibir la factura" name="CorreoFacturacion" value={this.state.CorreoFacturacion} onChange={this.onChange} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12}>
                                    <Form.Group className="mb-3" controlId="ResponsableInscripcion">
                                        <Form.Label>Responsable De Inscripcion</Form.Label>
                                        <Form.Control required type="text" placeholder="Ingrese el responsable de inscripcion de la empresa" name="ResponsableInscripcion" value={this.state.ResponsableInscripcion} onChange={this.onChange} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12} lg={6}>
                                    <Form.Group className="mb-3" controlId="CorreoResponsable">
                                        <Form.Label>Correo Electrónico Responsable Inscripción</Form.Label>
                                        <Form.Control required type="email" placeholder="Ingrese el Correo Electrónico Del Responsable De Inscripción" name="CorreoResponsable" value={this.state.CorreoResponsable} onChange={this.onChange} />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={12} lg={6}>
                                    <Form.Group className="mb-3" controlId="TelefonoResponsable">
                                        <Form.Label>Teléfono Responsable Inscripción</Form.Label>
                                        <Form.Control required type="text" placeholder="Ingrese el Teléfono Del Responsable De Inscripción" name="TelefonoResponsable" value={this.state.TelefonoResponsable} onChange={this.onChange} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12}>
                                    <Form.Group className="mb-3" controlId="ResponsablePago">
                                        <Form.Label>Responsable De Pago</Form.Label>
                                        <Form.Control required type="text" placeholder="Ingrese el responsable de pago" name="ResponsablePago" value={this.state.ResponsablePago} onChange={this.onChange} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12} lg={6}>
                                    <Form.Group className="mb-3" controlId="CorreoPago">
                                        <Form.Label>Correo Electrónico Responsable Pago</Form.Label>
                                        <Form.Control required type="email" placeholder="Ingrese el Correo Electrónico Del Responsable De Pago" name="CorreoPago" value={this.state.CorreoPago} onChange={this.onChange} />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={12} lg={6}>
                                    <Form.Group className="mb-3" controlId="TelefonoPago">
                                        <Form.Label>Teléfono Responsable Pago</Form.Label>
                                        <Form.Control required type="text" placeholder="Ingrese el Teléfono Del Responsable  De Pago" name="TelefonoPago" value={this.state.TelefonoPago} onChange={this.onChange} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12}>
                                    <Form.Group className="mb-3" controlId="FormaPago">
                                        <Form.Label>Indique su forma de pago</Form.Label>
                                        <Form.Control as="select" required aria-label="Evento" name="FormaPago" onChange={this.onChange} value={this.state.FormaPago}>
                                            <option value="" key="0">Seleccione la forma de pago</option>
                                            {this.state.formas_pago.map(forma_pago => <option key={forma_pago.UniqueID} value={forma_pago.UniqueID}>{forma_pago.Nombre}</option>)}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            {this.props.tipo == '1' &&
                                <Row>
                                    <Col xs={12} md={12}>
                                        <Form.Group className="mb-3" controlId="comentario">
                                            <Form.Label>Comentario de envío de solicitud</Form.Label>
                                            <Form.Control as="textarea" placeholder="Ingrese sus comentarios sobre la solicitud" rows={3} style={{ resize: 'none' }} name="Comentario" onChange={this.onChange} value={this.state.Comentario} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            }
                            {this.props.tipo == '1' &&
                                <Row>
                                    <Col xs={12} md={12}>
                                        <Form.Group className="mb-3" controlId="prioridad">
                                            <Form.Label>Prioridad</Form.Label>
                                            <Form.Control as="select" required aria-label="prioridad" name="Prioridad" onChange={this.onChange} value={this.state.Prioridad}>
                                                <option value="" key="0">Seleccione la prioridad de su solicitud</option>
                                                <option value="0">Baja</option>
                                                <option value="1">Normal</option>
                                                <option value="2">Urgente</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            }
                            <Row>
                                <Button variant="secondary" className="font-bold" type="submit">Guardar</Button>
                            </Row>
                        </Form>
                    </Container>
                </Modal.Body>
                <Modal.Footer style={{ backgroundColor: "#b5bad0" }}>
                    <Button variant="secondary" className="font-bold" onClick={this.props.onHide}>Cancelar</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}