import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Container, Row, Navbar } from "react-bootstrap";
import "./style.css";
const helpers = require("../../helpers/helpers");
const MenuLayout = () => {
  const [contador] = useState("");
  const [usuario, setUsuario] = useState({});
  useEffect(() => {
    helpers
      .getData("/api/auth/userinfo")
      .then((response) => {
        setUsuario(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [contador]);
  return (
    <Container fluid style={{ height: "100vh", overflow: "hidden" }}>
      <Row style={{ height: "5%" }}>
        <Navbar bg="light" data-bs-theme="dark">
          <Container fluid>
            <Navbar.Brand
              href={
                usuario.tipo == null
                  ? "/"
                  : usuario.tipo == 2
                  ? "/control-academico"
                  : usuario.tipo == 1
                  ? "/asesor"
                  : usuario.tipo == 4
                  ? "/home"
                  : "/"
              }
            >
              PORTAL ESCUELA BANCARIA DE GUATEMALA
            </Navbar.Brand>
          </Container>
        </Navbar>
      </Row>
      <Row
        style={{ height: "90%", overflowY: "scroll" }}
        className="layout-custom"
      >
        <Outlet />
      </Row>
      <Row style={{ height: "5%" }}></Row>
    </Container>
  );
};

export default MenuLayout;
