import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Form, Button, Col, Card } from "react-bootstrap";
const EditParticipante = () => {
  const { hash } = useParams();
  const [cambios, setCambios] = useState(0);
  const [contador] = useState(0);
  const [evento, setEvento] = useState({});
  const [cargando, setCargando] = useState(false);
  const [nombres, setNombres] = useState("");
  const [apellidos, setApellidos] = useState("");
  const [email, setEmail] = useState("");
  const [telefono, setTelefono] = useState("");
  const [celular, setCelular] = useState("");
  const [pais, setPais] = useState("");
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState(false);
  const [paises, setPaises] = useState([]);
  const [puesto, setPuesto] = useState("");
  const [id, setID] = useState("");
  useEffect(() => {
    fetch(process.env.REACT_APP_DEFAULT_URL + "/api/read/gafete/" + hash, {
      cache: "no-cache",
      credentials: "include",
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        let tmp = data.salida[0];
        setNombres(tmp.Nombres);
        setApellidos(tmp.Apellidos);
        setEmail(tmp.Correo);
        setTelefono(tmp.Telefono);
        setCelular(tmp.celular);
        setPais(tmp.pais);
        setPuesto(tmp.Puesto);
        setID(tmp.UniqueID);
        fetch(
          process.env.REACT_APP_DEFAULT_URL +
            "/api/read/evento/landing/" +
            data.salida[0].hash,
          {
            cache: "no-cache",
            credentials: "include",
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            setEvento(data.rows[0]);
          });
      });
    fetch(process.env.REACT_APP_DEFAULT_URL + "/api/read/paises", {
      cache: "no-cache",
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        let paises_arr = [];
        data.paises.map((actual) => {
          paises_arr.push({ id: actual.code, nombre: actual.name });
        });
        paises_arr.sort(function (a, b) {
          return a.nombre.localeCompare(b.nombre);
        });
        setPaises(paises_arr);
      });
  }, [contador]);
  useEffect(() => {}, [cambios]);
  const onSubmit = (evt) => {
    const form = evt.currentTarget;
    if (form.checkValidity() === false) {
      evt.preventDefault();
      evt.stopPropagation();
      /*
      /api/update/detalle_inscripcion
      req.body.Nombres,
      req.body.Apellidos,
      req.body.Puesto,
      req.body.Correo,
      req.body.Tel,
      req.body.Celular,
      req.body.pais,
      req.body.UniqueID
      */
    } else {
      evt.preventDefault();
      const info = {
        Nombres: nombres,
        Apellidos: apellidos,
        Puesto: puesto,
        Correo: email,
        Tel: telefono,
        Celular: celular,
        pais: pais,
        UniqueID: id,
      };
      const requestOptions = {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(info),
      };
      fetch(
        process.env.REACT_APP_DEFAULT_URL + "/api/update/detalle_inscripcion",
        requestOptions
      )
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setCargando(false);
          setCambios(cambios + 1);
          if (data.codigo == 200) {
            window.location.reload();
          } else {
            console.log(data);
          }
        });
    }
    setValidated(true);
    setCambios(cambios + 1);
  };
  const onChange = (evt) => {
    if (evt.target.name == "nombres") {
      setNombres(evt.target.value);
    } else if (evt.target.name == "apellidos") {
      setApellidos(evt.target.value);
    } else if (evt.target.name == "email") {
      setEmail(evt.target.value);
    } else if (evt.target.name == "telefono") {
      setTelefono(evt.target.value);
    } else if (evt.target.name == "celular") {
      setCelular(evt.target.value);
    } else if (evt.target.name == "pais") {
      setPais(evt.target.value);
    } else if (evt.target.name == "puesto") {
      setPuesto(evt.target.value);
    }
    setCambios(cambios + 1);
  };
  return (
    <Container fluid>
      <Row style={{ padding: "5%" }}>
        <Container>
          <Row
            style={{
              padding: "5%",
              borderBottom: "5px solid #1e3d52",
              borderLeft: "5px solid #1e3d52",
              backgroundColor: "rgb(230,230,230)",
            }}
          >
            <Col xs={12} md={6} lg={4}>
              <img
                loading="lazy"
                src={evento.flier}
                style={{ width: "100%" }}
                alt="arte"
              />
            </Col>
            <Col xs={12} md={6} lg={8} style={{ textAlign: "left" }}>
              <h1>EDICIÓN PARTICIPANTE</h1>
              {evento != null && (
                <p>
                  <br />
                  <strong>EVENTO: </strong>
                  {evento.Nombre}
                  <br />
                  <strong>FECHA: </strong>
                  {evento.Fecha_Inicio}
                  <br />
                </p>
              )}
            </Col>
          </Row>
        </Container>
      </Row>
      <Row style={{ padding: "1%" }}>
        <h1>Editar información de participante</h1>
      </Row>
      <Row style={{ padding: "5%" }}>
        <Form onSubmit={onSubmit} noValidate validated={validated}>
          <Row>
            <Form.Group className="mb-3" controlId="nombres">
              <Form.Label>Nombres del participante</Form.Label>
              <Form.Control
                value={nombres}
                disabled={cargando || error}
                required
                type="text"
                placeholder="Ingrese los nombres del participante"
                name="nombres"
                onChange={onChange}
              />
              <Form.Control.Feedback>Ok</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Por favor, completa este campo
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="mb-3" controlId="apellidos">
              <Form.Label>Apellidos del participante</Form.Label>
              <Form.Control
                value={apellidos}
                disabled={cargando || error}
                required
                type="text"
                placeholder="Ingrese los apellidos del participante"
                name="apellidos"
                onChange={onChange}
              />
              <Form.Control.Feedback>Ok</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Por favor, completa este campo
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="mb-3" controlId="email">
              <Form.Label>Correo electrónico del participante</Form.Label>
              <Form.Control
                value={email}
                disabled={cargando || error}
                required
                type="text"
                placeholder="Ingrese el correo electrónico participante"
                name="email"
                onChange={onChange}
              />
              <Form.Control.Feedback>Ok</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Por favor, completa este campo
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="mb-3" controlId="nombres">
              <Form.Label>Puesto del participante</Form.Label>
              <Form.Control
                value={puesto}
                disabled={cargando || error}
                required
                type="text"
                placeholder="Ingrese el puesto del participante"
                name="puesto"
                onChange={onChange}
              />
              <Form.Control.Feedback>Ok</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Por favor, completa este campo
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="mb-3" controlId="telefono">
              <Form.Label>Teléfono del participante</Form.Label>
              <Form.Control
                value={telefono}
                disabled={cargando || error}
                required
                type="text"
                placeholder="Ingrese el telefono del participante"
                name="telefono"
                onChange={onChange}
              />
              <Form.Control.Feedback>Ok</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Por favor, completa este campo
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="mb-3" controlId="celular">
              <Form.Label>Celular del participante</Form.Label>
              <Form.Control
                value={celular}
                disabled={cargando || error}
                required
                type="text"
                placeholder="Ingrese el celular del participante"
                name="celular"
                onChange={onChange}
              />
              <Form.Control.Feedback>Ok</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Por favor, completa este campo
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="mb-3" controlId="pais">
              <Form.Label>Pais del participante</Form.Label>
              <Form.Control
                as="select"
                required
                aria-label="pais"
                name="pais"
                value={pais}
                onChange={onChange}
                disabled={cargando || error}
              >
                <option value="" key="0">
                  Seleccione el país del participante
                </option>
                {paises.map((pais) => (
                  <option key={pais.id} value={pais.nombre}>
                    {pais.nombre}
                  </option>
                ))}
              </Form.Control>

              <Form.Control.Feedback>Ok</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Por favor, completa este campo
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            {!error && (
              <Button
                variant="outline-secondary"
                className="font-bold"
                type="submit"
                disabled={cargando || error}
              >
                Guardar
              </Button>
            )}
            {error && (
              <Button
                variant="outline-secondary"
                className="font-bold"
                onClick={() => {
                  setError(false);
                  setCambios(cambios + 1);
                }}
                disabled={cargando || !error}
              >
                Intentar de nuevo
              </Button>
            )}
          </Row>
        </Form>
      </Row>
    </Container>
  );
};

export default EditParticipante;
