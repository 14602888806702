import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
const helpers = require("../../../../../helpers/helpers");
const CheckAttendance = () => {
  const { hash } = useParams();
  const [contador] = useState(0);
  useEffect(() => {
    helpers
      .getData("/api/read/gafete/" + hash)
      .then((response) => {
        console.log(response);
        let persona = response.data.salida[0];
        const body = {
          hash: hash,
          evento_UniqueID: persona.evento,
          persona: persona.Nombres + " " + persona.Apellidos,
          institucion: persona.empresa,
          foto: persona.foto,
        };
        helpers
          .postData("/api/create/evento/marcar/asistencia/gafete", body)
          .then((response) => {
            window.location.href =
              process.env.REACT_APP_EBG_URL + "/mi-gafete-electronico/" + hash;
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [contador]);
  return (
    <div
      style={{
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "cover",
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        backgroundImage:
          "url(https://ik.imagekit.io/p5ethkcnzwh/PORTADA-CARGANDO_zrnGXPrab.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1651185621397)",
      }}
    ></div>
  );
};

export default CheckAttendance;
