import React, { useState, useEffect } from 'react'
//import DEFAULT_URL from '../../../../api/url'
import { Row, Col, ListGroup, Tab, Container } from "react-bootstrap";
import './Common.css'
import InscripcionesPendientes from './InscripcionesPendientes';
const NoProcesadas = (props) => {
    const [eventos, setEventos] = useState([])
    const [contador, setContador] = useState(0)
    useEffect(async () => {
        setEventos([]);
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/evento/asesor', { cache: "no-cache" })
            .then((res) => {
                return res.json();
            })
            .then(data => {
                setEventos(data.rows);
            });
    }, [contador]);
    return (
        <Container style={{ color: 'white' }}>
            <Row>
                <Col xs={1} md={1} lg={1}>
                    <div style={{ color: 'white', cursor: 'pointer' }} data-toggle="tooltip" data-placement="top" title="Refrescar" onClick={() => setContador(contador + 1)} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                            <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                        </svg>
                    </div>
                </Col>
            </Row>
            <Row style={{ marginTop: '35px' }}>
                <Tab.Container id="list-group-tabs-example">
                    <Row>
                        <Col sm={4} className="columna-eventos-procesadas">
                            <ListGroup>
                                {eventos.map((evento) =>
                                    <ListGroup.Item action key={evento.UniqueID} href={"#" + evento.UniqueID} className="tab-cartera cliente" style={{ textAlign: 'left' }}>
                                        {evento.Nombre}
                                    </ListGroup.Item>

                                )}
                            </ListGroup>
                        </Col>
                        <Col sm={8} className="columna-eventos-procesadas">
                            <Tab.Content>
                                {eventos.map((evento) =>
                                    <Tab.Pane key={evento.UniqueID} eventKey={"#" + evento.UniqueID}>
                                        <Container>
                                            <Row>
                                                <h1 style={{ marginBottom: '25px', marginTop: '25px', color: 'white' }}>Datos del evento</h1>
                                                <ListGroup as="ol">
                                                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                                        <div className="ms-2 me-auto">
                                                            <div className="fw-bold">
                                                                Evento
                                                            </div>
                                                        </div>
                                                        {evento.Nombre}
                                                    </ListGroup.Item>
                                                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                                        <div className="ms-2 me-auto">
                                                            <div className="fw-bold">
                                                                Fecha de inicio del evento
                                                            </div>
                                                        </div>
                                                        {evento.Fecha_Inicio}
                                                    </ListGroup.Item>
                                                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                                        <div className="ms-2 me-auto">
                                                            <div className="fw-bold">
                                                                Fecha de finalización del evento
                                                            </div>
                                                        </div>
                                                        {evento.Fecha_Fin}
                                                    </ListGroup.Item>
                                                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                                        <div className="ms-2 me-auto">
                                                            <div className="fw-bold">
                                                                Hora del evento
                                                            </div>
                                                        </div>
                                                        {evento.Hora}
                                                    </ListGroup.Item>
                                                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                                        <div className="ms-2 me-auto">
                                                            <div className="fw-bold">
                                                                Mínimo de participantes
                                                            </div>
                                                        </div>
                                                        {evento.Minimo}
                                                    </ListGroup.Item>
                                                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                                        <div className="ms-2 me-auto">
                                                            <div className="fw-bold">
                                                                Tipo de evento
                                                            </div>
                                                        </div>
                                                        {evento.tipo}
                                                    </ListGroup.Item>
                                                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                                        <div className="ms-2 me-auto">
                                                            <div className="fw-bold">
                                                                Estado del evento
                                                            </div>
                                                        </div>
                                                        {evento.estado}
                                                    </ListGroup.Item>
                                                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                                        <div className="ms-2 me-auto">
                                                            <div className="fw-bold">
                                                                Modalidad del evento
                                                            </div>
                                                        </div>
                                                        {evento.modalidad}
                                                    </ListGroup.Item>
                                                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                                        <div className="ms-2 me-auto">
                                                            <div className="fw-bold">
                                                                Días a impartir
                                                            </div>
                                                        </div>
                                                        {evento.dias}
                                                    </ListGroup.Item>
                                                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                                        <div className="ms-2 me-auto">
                                                            <div className="fw-bold">
                                                                Duración del evento
                                                            </div>
                                                        </div>
                                                        {evento.duracion}
                                                    </ListGroup.Item>
                                                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                                        <div className="ms-2 me-auto">
                                                            <div className="fw-bold">
                                                                Número de sesiones del evento
                                                            </div>
                                                        </div>
                                                        {evento.sesiones}
                                                    </ListGroup.Item>
                                                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                                        <div className="ms-2 me-auto">
                                                            <div className="fw-bold">
                                                                Precio para agremiados
                                                            </div>
                                                        </div>
                                                        {evento.precio_agremiados}
                                                    </ListGroup.Item>
                                                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                                        <div className="ms-2 me-auto">
                                                            <div className="fw-bold">
                                                                Precio para no agremiados
                                                            </div>
                                                        </div>
                                                        {evento.precio_no_agremiados}
                                                    </ListGroup.Item>
                                                </ListGroup>
                                            </Row>
                                            <Row>
                                                <h1 style={{ marginBottom: '25px', marginTop: '25px', color: 'white' }}>Listado inscripciones</h1>
                                                <InscripcionesPendientes UniqueID={evento.UniqueID} asesor={props.uniqueID} />
                                            </Row>
                                        </Container>
                                    </Tab.Pane>
                                )}
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Row>
        </Container>
    )
}

export default NoProcesadas