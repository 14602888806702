import React, { useState, useEffect } from 'react'
import { Row, Col, ListGroup, Tab, Container } from "react-bootstrap";
import ModalNuevoLead from './ModalNuevoLead';
//import DEFAULT_URL from '../../../../api/url';
import Seguimiento from './Seguimiento';
import Cancelados from './Cancelados';
import Prospectos from './Prospectos';
const Leads = () => {
  const [nuevoLead, setNuevoLead] = useState(false);
  const [contador, setContador] = useState(0);
  const [enSeguimiento, setEnSeguimiento] = useState([]);
  const [cancelados, setCancelados] = useState([]);
  const [prospectos, setProspectos] = useState([]);
  useEffect(() => {
    async function fetchData() {
      fetch(process.env.REACT_APP_DEFAULT_URL + '/api/auth/userinfo', {cache: "no-cache",  credentials: 'include' })
        .then((res) => {
          return res.json();
        })
        .then(data => {
          fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/leads/seguimiento/' + data.UniqueID, { cache: "no-cache", credentials: 'include' })
            .then((res) => {
              return res.json();
            })
            .then(data => {
              setEnSeguimiento(data.rows);
            });
          fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/leads/cancelados/' + data.UniqueID, { cache: "no-cache", credentials: 'include' })
            .then((res) => {
              return res.json();
            })
            .then(data => {
              setCancelados(data.rows);
            });
          fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/leads/prospectos/' + data.UniqueID, { cache: "no-cache", credentials: 'include' })
            .then((res) => {
              return res.json();
            })
            .then(data => {
              setProspectos(data.rows);
            });
        });
    }
    fetchData();
  }, [contador]);
  const ocultarNuevoLead = () => {
    setNuevoLead(false);
    setContador(contador + 1);
  }
  const refrescar = () => {
    setContador(contador + 1);
  }
  return (
    <Container>
      <Row>
        <Col xs={1} md={1} lg={1}>
          <div style={{ color: 'white', cursor: 'pointer' }} data-toggle="tooltip" data-placement="top" title="Refrescar" onClick={() => setContador(contador + 1)} >
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
              <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
            </svg>
          </div>
        </Col>
        <Col xs={1} md={1} lg={1}>
          <div style={{ color: 'white', cursor: 'pointer' }} data-toggle="tooltip" data-placement="top" title="Nuevo lead" onClick={() => setNuevoLead(true)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
            </svg>
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop: '30px' }}>
        <Tab.Container id="list-group-tabs-example">
          <Row>
            <Col sm={4} className="columna-contenido">
              <ListGroup>
                <ListGroup.Item action href="#seguimiento" className="tab-cartera cliente">
                  En seguimiento
                </ListGroup.Item>
                <ListGroup.Item action href="#no-confirmados" className="tab-cartera cliente">
                  No Confirmados
                </ListGroup.Item>
                <ListGroup.Item action href="#prospectos" className="tab-cartera cliente">
                  Prospectos
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col sm={8} className="columna-contenido">
              <Tab.Content>
                <Tab.Pane eventKey="#seguimiento" style={{ padding: '10px' }}>
                  <Container>
                    <Row>
                      <Seguimiento leads={enSeguimiento} actualizar={() => setContador(contador + 1)} seguimiento='block' />
                    </Row>
                  </Container>
                </Tab.Pane>
                <Tab.Pane eventKey="#no-confirmados" style={{ padding: '10px' }}>
                  <Container>
                    <Row>
                      <Cancelados leads={cancelados} actualizar={() => setContador(contador + 1)} seguimiento='none' />
                    </Row>
                  </Container>
                </Tab.Pane>
                <Tab.Pane eventKey="#prospectos" style={{ padding: '10px' }}>
                  <Container>
                    <Row>
                      <Prospectos leads={prospectos} actualizar={() => setContador(contador + 1)} seguimiento='none' />
                    </Row>
                  </Container>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Row>
      <ModalNuevoLead show={nuevoLead} onHide={ocultarNuevoLead} refrescar={refrescar} />
    </Container>
  )
}

export default Leads