import React from 'react'
import { ListGroup } from "react-bootstrap";

const ClienteInfo = (props) => {
    return (
        <ListGroup as="ol" >
            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                <div className="ms-2 me-auto">
                    <div className="fw-bold">Empresa</div>
                </div>
                {props.empresa.Empresa}
            </ListGroup.Item>
            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                <div className="ms-2 me-auto">
                    <div className="fw-bold" >Dirección</div>
                </div>
                {props.empresa.Direccion}
            </ListGroup.Item>
            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                <div className="ms-2 me-auto">
                    <div className="fw-bold">Teléfono</div>
                </div>
                {props.empresa.Telefono}
            </ListGroup.Item>
            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                <div className="ms-2 me-auto">
                    <div className="fw-bold">Razón social</div>
                </div>
                {props.empresa.NombreFactura}
            </ListGroup.Item>
            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                <div className="ms-2 me-auto">
                    <div className="fw-bold">NIT</div>
                </div>
                {props.empresa.NIT}
            </ListGroup.Item>
            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                <div className="ms-2 me-auto">
                    <div className="fw-bold">Correo para recibir facturas</div>
                </div>
                {props.empresa.CorreoFacturacion}
            </ListGroup.Item>
            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                <div className="ms-2 me-auto">
                    <div className="fw-bold">Responsable de inscripción</div>
                </div>
                {props.empresa.ResponsableInscripcion}
            </ListGroup.Item>
            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                <div className="ms-2 me-auto">
                    <div className="fw-bold">Teléfono responsable de inscripción</div>
                </div>
                {props.empresa.TelefonoResponsable}
            </ListGroup.Item>

            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                <div className="ms-2 me-auto">
                    <div className="fw-bold">Correo responsable inscripción</div>
                </div>
                {props.empresa.CorreoResponsable}
            </ListGroup.Item>

            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                <div className="ms-2 me-auto">
                    <div className="fw-bold">Responsable de pago</div>
                </div>
                {props.empresa.ResponsablePago}
            </ListGroup.Item>
            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                <div className="ms-2 me-auto">
                    <div className="fw-bold">Teléfono responsable de pago</div>
                </div>
                {props.empresa.TelefonoPago}
            </ListGroup.Item>
            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                <div className="ms-2 me-auto">
                    <div className="fw-bold">Correo responsable de pago</div>
                </div>
                {props.empresa.CorreoPago}
            </ListGroup.Item>
            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start" ><div className="ms-2 me-auto">
                <div className="fw-bold">Forma de pago</div>
            </div>
                {props.empresa.Nombre}
            </ListGroup.Item>
        </ListGroup>
    )
}

export default ClienteInfo