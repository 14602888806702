import React, { useState, useEffect } from 'react'
//import DEFAULT_URL from '../../../../api/url';
import { ListGroup, Row, Col, Tab, Container } from "react-bootstrap";
import ModalNuevoFormulario from './ModalNuevoFormulario';
import DetalleFormulario from './DetalleFormulario';
const DetalleFormularios = (props) => {
    const [contador, setContador] = useState(0);
    const [nuevoFormulario, setNuevoFormulario] = useState(false);
    const [formularios, setFormularios] = useState([]);
    useEffect(() => {
        async function fetchData() {
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/formulario/evento/' + props.evento.UniqueID, { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setFormularios(data.formularios);
                });
        }
        fetchData();
    }, [contador])
    const hideModal = () => {
        setNuevoFormulario(false);
        setContador(contador + 1);
    }
    const refrescarInfo = () => {
        setContador(contador + 1);
    }
    return (
        <Container>
            <Row>
                <Col xs={1} md={1} lg={1}>
                    <div style={{ color: 'white', cursor: 'pointer' }} data-toggle="tooltip" data-placement="top" title="Nuevo Formulario" onClick={() => { setNuevoFormulario(true); setContador(contador + 1); }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                        </svg>
                    </div>
                </Col>
                <Col xs={1} md={1} lg={1}>
                    <div style={{ color: 'white', cursor: 'pointer' }} data-toggle="tooltip" data-placement="top" title="Refrescar" onClick={() => setContador(contador + 1)} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                            <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                        </svg>
                    </div>
                </Col>
            </Row>
            <Row style={{ padding: '20px' }}>
                <Tab.Container id="list-group-tabs-example" >
                    <Row>
                        <Col sm={4} className="columna-contenido">
                            <ListGroup>
                                {formularios.map(formulario =>
                                    <ListGroup.Item key={formulario.UniqueID} className="tab-cartera cliente" style={{ backgroundColor: '#ffffff', textAlign: 'left' }} action href={"#form-" + formulario.UniqueID}>
                                        {formulario.correlativo}
                                    </ListGroup.Item>
                                )}
                            </ListGroup>
                        </Col>
                        <Col sm={8} className="columna-contenido">
                            <Tab.Content>
                                {formularios.map(formulario =>
                                    <Tab.Pane key={formulario.UniqueID} eventKey={"#form-" + formulario.UniqueID} href={"#form-" + formulario.UniqueID}>
                                        <Container>
                                            <DetalleFormulario formulario={formulario} refrescarInfo={refrescarInfo} />
                                        </Container>
                                    </Tab.Pane>
                                )}
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Row>
            <ModalNuevoFormulario evento={props.evento} show={nuevoFormulario} onHide={hideModal} />
        </Container>
    )
}

export default DetalleFormularios