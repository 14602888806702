import React, { useState, useEffect } from 'react';
import { Modal, Row, Popover, OverlayTrigger, Form, Col, Alert, Container, Tabs, Tab, Button } from "react-bootstrap";
//import DEFAULT_URL from '../../../../api/url';
import { SuccessModal } from '../../../Utils/SuccessModal/SuccessModal';
import { ErrorModal } from '../../../Utils/ErrorModal/ErrorModal';
import { ModalCargando } from '../../../Utils/Modal-Cargando/ModalCargando';
import axios from 'axios';
const popover = (
    <Popover id="popover-basic">
        <Popover.Header as="h3">¡Listo!</Popover.Header>
        <Popover.Body>
            Enlace copiado exitosamente.
        </Popover.Body>
    </Popover>
);
const ModalPatrocinadores = (props) => {
    const [patrocinadores, setPatrocinadores] = useState([]);
    const [mensajeConfirmacionPublicar, setMensajeConfirmacionPublicar] = useState(false);
    const [mensajeConfirmacionOcultar, setMensajeConfirmacionOcultar] = useState(false);
    const [mensajeConfirmacionCerrar, setMensajeConfirmacionCerrar] = useState(false);
    const [mensajeConfirmacionAbrir, setMensajeConfirmacionAbrir] = useState(false);
    const [mensajeConfirmacionEliminar, setMensajeConfirmacionEliminar] = useState(false);
    const [contador, setContador] = useState(0);
    const [mensaje, setMensaje] = useState('');
    const [consideracion, setConsideracion] = useState('');
    const [id_actual, setId_Actual] = useState('');
    const [modalSucc, setModalSucc] = useState(false);
    const [modalError, setModalError] = useState(false);
    const [modalCargando, setModalCargando] = useState(false);
    const [mensajeCargando, setMensajeCargando] = useState('');
    const [nombre, setNombre] = useState([]);
    const [url, setUrl] = useState('');
    const [tipo, setTipo] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    useEffect(() => {
        async function fetchData() {
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/parocinador/' + props.evento_uniqueid, { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setPatrocinadores(data.rows);
                });
        }
        fetchData();
    }, [contador]);
    const entering = () => {
        setContador(contador + 1);
    }
    const onChange = (evt) => {
        if (evt.target.name == 'nombre') {
            setNombre(evt.target.value);
        } else {
            setUrl(evt.target.value);
        }
        setContador(contador + 1);
    }
    const submit = (evt) => {
        evt.preventDefault();
        const data = new FormData();
        data.append('file', selectedFile);
        setMensajeCargando('Se está subiendo el archivo al repositorio de imágenes, por favor espere.');
        setModalCargando(true);
        axios.post(process.env.REACT_APP_DEFAULT_URL+ "/api/files/upload/imagekit", data, {
        }).then(res => {
            if (res.data.codigo == 200) {
                //                var inserts = [req.body.nombre, req.body.promocional, req.body.evento, req.body.url];

                const info = { promocional: res.data.resultado.url, nombre: nombre, evento: props.evento_uniqueid, url: url };
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(info)
                };
                fetch(process.env.REACT_APP_DEFAULT_URL + '/api/create/parocinador', requestOptions)
                    .then((res) => {
                        return res.json();
                    })
                    .then(data => {
                        if (data.codigo == 200) {
                            setModalCargando(false);
                            setMensaje('Patrocinador registrado exitosamente');
                            setContador(contador + 1);
                            setModalSucc(true);
                        } else {
                            console.log(data);
                            setMensaje('Ocurrió un error al registrar al patrocinador');
                            setModalError(true);
                        }
                        Array.from(document.querySelectorAll("input")).forEach(
                            input => (input.value = "")
                        );
                    });
            } else {
                console.log(res);
                setMensaje('Ocurrió un error al subir el archivo al repositorio de imágenes');
                setModalError(true);
            }
        });
    }
    const onChangeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
    };
    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
            onEnter={entering}
            size='lg'
        >
            <Modal.Header closeButton style={{ backgroundColor: "#1e3d52", borderColor: 'transparent', color: 'white' }}>
                <Modal.Title id="contained-modal-title-vcenter" className="font-bold">
                    Patrocinadores Del Evento
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: "#1e3d52", borderColor: 'transparent', color: 'white' }} id="body-modal-edit-evento">
                <Tabs defaultActiveKey="formulario" id="uncontrolled-tab-example" className="mb-3" variant='pills'>
                    <Tab tabClassName='tab-material' eventKey="formulario" title="Nuevo Patrocinador">
                        <Form onSubmit={submit}>
                            <Container>
                                <Row>
                                    <Form.Group className="mb-3" controlId="nombre">
                                        <Form.Label>Nombre del patrocinador</Form.Label>
                                        <Form.Control required type="text" name="nombre" placeholder="Ingrese el nombre del patrocinador" onChange={onChange} />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group controlId="formFileMultiple" className="mb-3">
                                        <Form.Label>Seleccione la imagen promocional del patrocinador</Form.Label>
                                        <Form.Control required type="file" onChange={onChangeHandler} />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group className="mb-3" controlId="url">
                                        <Form.Label>Sitio web del patrocinador</Form.Label>
                                        <Form.Control required type="text" name="url" placeholder="Ingrese el url del sitio web del patrocinador" onChange={onChange} />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Button type="submit" variant="light">Guardar</Button>
                                </Row>
                            </Container>
                        </Form>
                    </Tab>
                    <Tab tabClassName='tab-material' eventKey="patrocinadores" title="Ver Patrocinadores">
                        <Container>
                            {patrocinadores.map((patrocinador) =>
                                <Row style={{ backgroundColor: 'white', padding: '25px', marginTop: '30px', border: '2px dashed black', borderRadius: '10px' }}>
                                    <Container>
                                        <Row>
                                            <Col xs={6} md={6} lg={6}>
                                                <h3 style={{ color: 'black', textAlign: 'center' }}>{patrocinador.nombre}</h3>
                                            </Col>
                                        </Row>
                                        <Row style={{ color: 'black', display: 'flex', placeItems: 'center', marginTop: '25px' }}>
                                            <Col sm={5} md={5} lg={5}>
                                                <p style={{ textAlign: 'center', width: '100%' }}>Vista previa historia App</p>
                                            </Col>
                                            <Col sm={2} md={2} lg={2}></Col>
                                            <Col sm={5} md={5} lg={5}>
                                                <p style={{ textAlign: 'center', width: '100%' }}>Vista previa publicidad landing page</p>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginBottom: '25px' }}>
                                            <Col sm={3} md={3} lg={3}></Col>
                                            <Col sm={1} md={1} lg={1}>
                                                <div style={{ cursor: 'pointer', width: '20%', color: 'black' }} data-toggle="tooltip" data-placement="top" title="Ver imagen">
                                                    <a href={patrocinador.promocional_app} target="_blank" style={{ color: 'black' }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16">
                                                            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                                            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                                        </svg>
                                                    </a>
                                                </div>
                                            </Col>
                                            <Col sm={1} md={1} lg={1}>
                                                <OverlayTrigger trigger="click" placement="left" overlay={popover} rootClose>
                                                    <div style={{ cursor: 'pointer', width: '20%', color: 'black' }} data-toggle="tooltip" data-placement="top" title="Copiar enlace al portapapeles" onClick={() => { navigator.clipboard.writeText(patrocinador.promocional_app); }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-clipboard-check" viewBox="0 0 16 16">
                                                            <path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                                                            <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                                                            <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                                                        </svg>
                                                    </div>
                                                </OverlayTrigger>
                                            </Col>
                                            <Col sm={1} md={1} lg={1}></Col>
                                            <Col sm={1} md={1} lg={1}></Col>
                                            <Col sm={3} md={3} lg={3}></Col>
                                            <Col sm={1} md={1} lg={1}>
                                                <div style={{ cursor: 'pointer', width: '20%', color: 'black' }} data-toggle="tooltip" data-placement="top" title="Ver imagen">
                                                    <a href={patrocinador.promocional_landing} target="_blank" style={{ color: 'black' }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16">
                                                            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                                            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                                        </svg>
                                                    </a>
                                                </div>
                                            </Col>
                                            <Col sm={1} md={1} lg={1}>
                                                <OverlayTrigger trigger="click" placement="left" overlay={popover} rootClose>
                                                    <div style={{ cursor: 'pointer', width: '20%', color: 'black' }} data-toggle="tooltip" data-placement="top" title="Copiar enlace al portapapeles" onClick={() => { navigator.clipboard.writeText(patrocinador.promocional_landing); }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-clipboard-check" viewBox="0 0 16 16">
                                                            <path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                                                            <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                                                            <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                                                        </svg>
                                                    </div>
                                                </OverlayTrigger>
                                            </Col>
                                        </Row>
                                        <Row style={{ height: '200px' }}>
                                            <Col style={{ height: '100%', padding: '15px', border: '2px dashed black' }} sm={5} md={5} lg={5}>
                                                <img src={patrocinador.promocional_app} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                            </Col>
                                            <Col sm={2} md={2} lg={2}></Col>
                                            <Col style={{ height: '100%', padding: '15px', border: '2px dashed black' }} sm={5} md={5} lg={5}>
                                                <img src={patrocinador.promocional_landing} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: '35px' }}>
                                            <Alert variant="secondary">
                                                {patrocinador.url}
                                            </Alert>
                                        </Row>
                                    </Container>
                                </Row>
                            )}
                        </Container>
                    </Tab>
                </Tabs>
            </Modal.Body >
            {/*
            <Confirmacion
                show={mensajeConfirmacionEliminar}
                onHide={() => setMensajeConfirmacionEliminar(false)}
                accion={eliminarEnlace}
                mensaje={mensaje}
                uniqueid={id_actual}
                consideracion={consideracion}
                param={''}
            />

*/}
            <SuccessModal
                color={"#b5bad0"}
                show={modalSucc}
                onHide={() => setModalSucc(false)}
                mensaje={mensaje}
                consideracion={consideracion}
                enlace={''}
                llevaenlace={false}
            />
            <ErrorModal
                color={"#b5bad0"}
                show={modalError}
                onHide={() => setModalError(false)}
                mensaje={mensaje}
                consideracion={consideracion}
            />
            <ModalCargando
                color={"#b5bad0"}
                show={modalCargando}
                onHide={() => setModalCargando(false)}
                mensaje={mensajeCargando}
            />
        </Modal >
    );
}

export default ModalPatrocinadores