import React, { useState, useEffect } from 'react';
import { Modal, Spinner, Row, Tabs, Tab, Button, Col, Form, Container, InputGroup, Alert } from "react-bootstrap";
import './ModalEditarEvento.css';
//import DEFAULT_URL from '../../../../../api/url';
const ModalEditarEvento = (props) => {
    const [modalidades, setModalidades] = useState([]);
    const [tipos, setTipos] = useState([]);
    const [estados, setEstados] = useState([]);
    const [contador, setContador] = useState(0);
    const [evento, setEvento] = useState({});
    const [formulario, setFormulario] = useState('block');
    const [cargando, setCargando] = useState('none');
    const [mensaje, setMensaje] = useState('');
    const [consideracion, setConsideracion] = useState('');
    const [exito, setExito] = useState('none');
    const [error, setError] = useState('none');
    useEffect(() => {
        async function fetchData() {
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/evento/modalidades', { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setModalidades(data.rows);
                });
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/evento/tipos', { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setTipos(data.rows);
                });
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/evento/estados', { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setEstados(data.rows);
                });
        }
        fetchData();
    }, [contador]);
    const guardarCambios = (evt) => {
        evt.preventDefault();
        props.submitchanges();
        props.onHide();
    }
    const onEnter = () => {
        setEvento(props.evento);
        setError('none');
        setExito('none');
        setCargando('none');
        setFormulario('block');
        setContador(contador + 1);
    }
    const eventochange = (evt) => {
        let evt_last = evento;
        switch (evt.target.name) {
            case "Nombre":
                evt_last.Nombre = evt.target.value;
                break;
            case "Fecha_Inicio":
                evt_last.Fecha_Inicia = evt.target.value;
                break;
            case "Hora":
                evt_last.Hora = evt.target.value;
                break;
            case "Minimo":
                evt_last.Minimo = evt.target.value;
                break;
            case "Descripcion":
                evt_last.Descripcion = evt.target.value;
                break;
            case "Ingresos":
                evt_last.ingresos = evt.target.value;
                break;
            case "Egresos":
                evt_last.egresos = evt.target.value;
                break;
            case "Resultado":
                evt_last.resultado = evt.target.value;
                break;
            case "tipo":
                evt_last.tipo_id = evt.target.value;
                break;
            case "estado":
                evt_last.estado_id = evt.target.value;
                break;
            case "modalidad":
                evt_last.modalidad_id = evt.target.value;
                break;
            case "modalidad":
                evt_last.modalidad_id = evt.target.value;
                break;
            case "dias":
                evt_last.dias = evt.target.value;
                break;
            case "duracion":
                evt_last.duracion = evt.target.value;
                break;
            case "sesiones":
                evt_last.sesiones = evt.target.value;
                break;
            case "precio_agremiados":
                evt_last.costo_agremiados = evt.target.value;
                break;
            case "precio_no_agremiados":
                evt_last.costo_no_agremiados = evt.target.value;
                break;
            case "imagen":
                evt_last.imagen = evt.target.value;
                break;
            case "flier":
                evt_last.flier = evt.target.value;
                break;
            case "Fecha_Fin":
                evt_last.Fecha_Finaliza = evt.target.value;
                break;
            default:
                break;
        }
        setEvento(evt_last);
        setContador(contador + 1);
    };
    const onSubmit = (evt) => {
        evt.preventDefault();
        setFormulario('none');
        setCargando('block');
        setMensaje('Sincronizando base de datos');
        setConsideracion('Por favor Espere...');
        const info = evento;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/update/evento', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                if (data.codigo == 200) {
                    setMensaje('Evento Modificado Exitosamente');
                    setConsideracion('Por favor, notifique al personal de sus cambios');
                    setCargando('none');
                    setExito('block');
                } else {
                    setMensaje(data.respuesta);
                    setCargando('none');
                    setError('block');
                    setConsideracion('Ocurrió un error al guardar los cambios del evento');
                }
                setContador(contador + 1);
            });
    }
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
            variant="light"
            onEnter={onEnter}
            backdrop='static'
        >
            <Form onSubmit={onSubmit}>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter" className="font-bold">
                        Editar Información De Evento
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ height: '60vh' }}>
                    <Container>
                        <Row style={{ display: formulario }}>
                            <Tabs defaultActiveKey="info" className="mb-3" variant='pills' bg="dark">
                                <Tab eventKey="info" title="Información General Del Evento">
                                    <Container>
                                        <Row>
                                            <Col xs={12} md={6}>
                                                <Form.Group className="mb-3" controlId={"Nombre"} >
                                                    <Form.Label>Nombre De Evento</Form.Label>
                                                    <Form.Control required onChange={eventochange} type="text" placeholder="Ingrese el nombre del Evento" name={"Nombre"} value={evento.Nombre} />
                                                    <Form.Control.Feedback type="invalid">
                                                        Por favor, ingrese el nombre dle evento.
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <Form.Group className="mb-3" controlId={"Minimo"}>
                                                    <Form.Label>Mínimo De Participantes</Form.Label>
                                                    <Form.Control required onChange={eventochange} type="text" placeholder="Ingrese el mínimo de participantes" name={"Minimo"} value={evento.Minimo} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Form.Group className="mb-3" controlId={"Descripcion"}>
                                                <Form.Label>Descripción De Evento</Form.Label>
                                                <Form.Control required onChange={eventochange} as="textarea" rows={3} style={{ resize: 'none' }} name={"Descripcion"} placeholder="Ingrese la descripción del evento" value={evento.Descripcion} />
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={12} lg={4}>
                                                <Form.Group className="mb-3" controlId={"Fecha_Inicio"}>
                                                    <Form.Label>Fecha De Inicio</Form.Label>
                                                    <Form.Control required onChange={eventochange} type="date" name={"Fecha_Inicio"} value={evento.Fecha_Inicia} />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={12} lg={4}>
                                                <Form.Group className="mb-3" controlId={"Fecha_Fin"}>
                                                    <Form.Label>Fecha De Finalización</Form.Label>
                                                    <Form.Control required onChange={eventochange} type="date" name={"Fecha_Fin"} value={evento.Fecha_Finaliza} />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={12} lg={4}>
                                                <Form.Group className="mb-3" controlId={"Hora"}>
                                                    <Form.Label>Hora Del Evento</Form.Label>
                                                    <Form.Control required onChange={eventochange} type="text" placeholder="Ingrese la hora del evento" name={"Hora"} value={evento.Hora} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={12} lg={4}>
                                                <Form.Group className="mb-3" controlId={"modalidad"}>
                                                    <Form.Label>Modalidad de evento</Form.Label>
                                                    <Form.Control as="select" required aria-label="modalidad" name={"modalidad"} onChange={eventochange} value={evento.modalidad_id}>
                                                        <option key="0">Seleccione la modalidad del evento</option>
                                                        {modalidades.map(modalidad => <option key={modalidad.UniqueID} value={modalidad.UniqueID}>{modalidad.Nombre}</option>)}
                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={12} lg={4}>
                                                <Form.Group className="mb-3" controlId={"tipo"}>
                                                    <Form.Label>Tipo de evento</Form.Label>
                                                    <Form.Control as="select" required aria-label="tipo" name={"tipo"} onChange={eventochange} value={evento.tipo_id}>
                                                        <option key="0">Seleccione el tipo del evento</option>
                                                        {tipos.map(tipo => <option key={tipo.UniqueID} value={tipo.UniqueID}>{tipo.Nombre}</option>)}
                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={12} lg={4}>
                                                <Form.Group className="mb-3" controlId={"estado"}>
                                                    <Form.Label>Estado de evento</Form.Label>
                                                    <Form.Control as="select" required aria-label="estado" name={"estado"} onChange={eventochange} value={evento.estado_id}>
                                                        <option key="0">Seleccione el estado del evento</option>
                                                        {estados.map(estado => <option key={estado.UniqueID} value={estado.UniqueID}>{estado.Nombre}</option>)}
                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={12} lg={4}>
                                                <Form.Group className="mb-3" controlId={"dias"}>
                                                    <Form.Label>Días a impartir</Form.Label>
                                                    <Form.Control required onChange={eventochange} type="text" placeholder="Ingrese los días a impartir" name={"dias"} value={evento.dias} />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={12} lg={4}>
                                                <Form.Group className="mb-3" controlId={"duracion"}>
                                                    <Form.Label>Duración del evento</Form.Label>
                                                    <Form.Control required onChange={eventochange} type="text" placeholder="Ingrese la duración" name={"duracion"} value={evento.duracion} />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={12} lg={4}>
                                                <Form.Group className="mb-3" controlId={"sesiones"}>
                                                    <Form.Label>Número de sesiones</Form.Label>
                                                    <Form.Control required onChange={eventochange} type="text" placeholder="Ingrese el número de sesiones" name={"sesiones"} value={evento.sesiones} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={12} lg={6}>
                                                <Form.Group className="mb-3" controlId={"precio_agremiados"}>
                                                    <Form.Label>Precio Agremiados</Form.Label>
                                                    <Form.Control required onChange={eventochange} type="text" placeholder="Ingrese el precio para agremiados" name={"precio_agremiados"} value={evento.costo_agremiados} />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={12} lg={6}>
                                                <Form.Group className="mb-3" controlId={"precio_no_agremiados"}>
                                                    <Form.Label>Precio No Agremiados</Form.Label>
                                                    <Form.Control required onChange={eventochange} type="text" placeholder="Ingrese el precio para no agremiados" name={"precio_no_agremiados"} value={evento.costo_no_agremiados} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Tab>
                                <Tab eventKey="proy" title="Proyecciones">
                                    <Container>
                                        <Row>
                                            <Form.Group className="mb-3" controlId={"Ingresos"}>
                                                <Form.Label>Ingresos Proyectados</Form.Label>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text>QTZ</InputGroup.Text>
                                                    <Form.Control required onChange={eventochange} type="text" placeholder="Ingrese Los Ingresos Proyectados" name={"Ingresos"} value={evento.ingresos} />
                                                </InputGroup>
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Form.Group className="mb-3" controlId={"Egresos"}>
                                                <Form.Label>Egresos Proyectados</Form.Label>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text>QTZ</InputGroup.Text>
                                                    <Form.Control required onChange={eventochange} type="text" placeholder="Ingrese Los Egresos Proyectados" name={"Egresos"} value={evento.egresos} />
                                                </InputGroup>
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Form.Group className="mb-3" controlId={"Resultado"}>
                                                <Form.Label>Resultado Proyectado</Form.Label>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text>QTZ</InputGroup.Text>
                                                    <Form.Control required onChange={eventochange} type="text" placeholder="Ingrese El Resultado Proyectado" name={"Resultado"} value={evento.resultado} />
                                                </InputGroup>
                                            </Form.Group>
                                        </Row>
                                    </Container>
                                </Tab>
                            </Tabs>
                        </Row>
                        <Row style={{ display: cargando }}>
                            <Button variant="dark" disabled>
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                &nbsp;&nbsp;&nbsp;{mensaje}
                                <p>{consideracion}</p>
                            </Button>
                        </Row>
                        <Row style={{ display: exito }}>
                            <Alert variant="success">
                                <Alert.Heading>Operación realizada con éxito</Alert.Heading>
                                <p>
                                    {mensaje}
                                </p>
                                <hr />
                                <p className="mb-0">
                                    {consideracion}
                                </p>
                            </Alert>
                        </Row>
                        <Row style={{ display: error }}>
                            <Alert variant="danger">
                                <Alert.Heading>Ocurrió un error</Alert.Heading>
                                <p>
                                    {mensaje}
                                </p>
                                <hr />
                                <p className="mb-0">
                                    {consideracion}
                                </p>
                            </Alert>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer style={{ display: formulario }}>
                    <Button variant="secondary" className="font-bold" type="submit" >Guardar</Button>
                    <Button variant="light" className="font-bold" onClick={props.onHide}>Cerrar</Button>
                </Modal.Footer>
                <Modal.Footer style={{ display: error }}>
                    <Button variant="secondary" className='font-bold' onClick={props.onHide} >Cerrar</Button>
                </Modal.Footer>
                <Modal.Footer style={{ display: exito }}>
                    <Button variant="secondary" className='font-bold' onClick={() => { props.refrescar(); props.onHide(); }} >Cerrar</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );

}

export default ModalEditarEvento;