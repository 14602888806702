import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
//import DEFAULT_URL from '../../api/url';
const Seguimiento = () => {
  const [contador] = useState(0);
  const [usuario, setUsuario] = useState({});
  const [eventos, setEventos] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filterData, setFilterData] = useState('');
  useEffect(() => {
    async function fetchData() {
      fetch(process.env.REACT_APP_DEFAULT_URL + '/api/auth/userinfo', { cache: "no-cache", credentials: 'include' })
        .then((res) => {
          if (res.status == 401) {
            window.location.reload();
          } else {
            return res.json();
          }
        })
        .then(data => {
          setUsuario(data);
          fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/leads/seguimiento/' + data.UniqueID, { cache: "no-cache", credentials: 'include' })
            .then((res) => {
              return res.json();
            })
            .then(data => {
              setEventos(data.rows);
            });
        });
    }
    fetchData();
  }, [contador]);

  return (
    <Container fluid>
      <Row style={{ padding: '5%', border: '5px solid #1e3d52', backgroundColor: 'rgb(230,230,230)', textAlign: 'left' }}>
        <h1>Leads en seguimiento</h1>
      </Row>
      <Row style={{ padding: '5%' }}>
        {eventos.map((actual, index) => <Col key={index} xs={12} md={6} lg={4}>
          <Container fluid style={{ padding: '1%', border: '5px solid #1e3d52', backgroundColor: 'rgb(230,230,230)' }}>
            <Row>
              <Col xs={4} md={4} lg={4}>
                <img src={actual.flier} style={{ width: '100%', objectFit: 'cover' }} />
              </Col>
              <Col xs={8} md={8} lg={8} style={{ textAlign: 'left' }}>
                <p>
                  <strong>EVENTO: </strong>{actual.evento_name}<br />
                  <strong>INTERESADOS: </strong>{actual.conteo}
                </p>
                <Button variant = 'outline-success' href = {process.env.REACT_APP_SELF_URL+'/crm/seguimiento/'+actual.evento_id}>Ver interesados</Button>
              </Col>
            </Row>
          </Container>
        </Col>)}
      </Row>
    </Container>
  )
}

export default Seguimiento