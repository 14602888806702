import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Alert, Button } from "react-bootstrap";
//import DEFAULT_URL from '../../../../../api/url';
//import { SELF_URL, EBG_URL } from '../../../../../api/url';
const ViewCupones = (props) => {
    const [contador, setContador] = useState(0);
    const [descuentos, setDescuentos] = useState([]);
    useEffect(() => {
        setDescuentos([]);
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/descuentos/read/' + props.evento + '/' + props.asesor, { cache: "no-cache" })
            .then((res) => {
                return res.json();
            })
            .then(data => {
                setDescuentos(data.rows);
            });
    }, [contador]);
    const deleteCupon = (id) => {
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/descuentos/delete/' + id, { cache: "no-cache" })
            .then((res) => {
                return res.json();
            })
            .then(data => {
                setContador(contador + 1);
            });
    }
    return (
        <Container>
            <Row style={{ marginBottom: '25px' }}>
                <Button variant='outline-dark' onClick={() => { setContador(contador + 1); }}>Refrescar</Button>
            </Row>
            <Row style={{ height: '600px', overflowY: 'scroll' }}>
                <Container>
                    {descuentos.map((descuento, index) => <Row style={{ marginBottom: '15px' }}>
                        <Col xs={12} md={12} lg={12}>
                            <Alert variant="dark">
                                <Alert.Heading>Descuento #{index + 1}</Alert.Heading>
                                <p>
                                    Descuento por {descuento.letrero_descuento}<br />
                                    Llave: {descuento.hash}<br />
                                    Fecha de registro: {descuento.fecha_registro}<br />
                                    Fecha de vencimiento: {descuento.fecha_vencimiento}<br />
                                    {descuento.dias > 1 && <strong>Vence en {descuento.dias} días <br /></strong>}
                                    {descuento.dias == 1 && <strong>Vence en mañana <br /></strong>}
                                    {descuento.dias == 0 && <strong>Vence hoy <br /></strong>}
                                </p>
                                <hr />
                                <div className="d-flex justify-content-end">
                                    <Button variant="outline-danger" style={{ marginRight: '25px' }} onClick={() => { deleteCupon(descuento.UniqueID); }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3-fill" viewBox="0 0 16 16">
                                            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                                        </svg>&nbsp;&nbsp;&nbsp;
                                        Eliminar cupón
                                    </Button>
                                    <Button variant="outline-dark" style={{ marginRight: '25px' }} onClick={() => { navigator.clipboard.writeText(process.env.REACT_APP_EBG_URL + "/inscripcion/" + props.evento + '/' + props.asesor + '/' + descuento.hash); }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard-check-fill" viewBox="0 0 16 16">
                                            <path d="M6.5 0A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3Zm3 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3Z" />
                                            <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1A2.5 2.5 0 0 1 9.5 5h-3A2.5 2.5 0 0 1 4 2.5v-1Zm6.854 7.354-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708.708Z" />
                                        </svg>&nbsp;&nbsp;&nbsp;Copiar link en portapapeles
                                    </Button>
                                    <Button variant="outline-dark" href={process.env.REACT_APP_EBG_URL + "/inscripcion/" + props.evento + '/' + props.asesor + '/' + descuento.hash} target="_blank">
                                        Ir a formulario con cupón
                                    </Button>
                                </div>
                            </Alert>
                        </Col>
                    </Row>)}
                </Container>
            </Row>
        </Container>
    )
}

export default ViewCupones