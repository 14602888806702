import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Navbar,
  Nav,
  Col,
  Button,
  Form,
} from "react-bootstrap";
import axios from "axios";
const Variables = () => {
  const [contador] = useState(0);
  const [agendaInfo, setAgendaInfo] = useState({});
  const [congresosInfo, setCongresosInfo] = useState({});
  const [ebcInfo, setEbcInfo] = useState({});
  const [tituloAgenda, setTituloAgenda] = useState("");
  const [tituloCongresos, setTituloCongresos] = useState("");
  const [tituloEbc, setTituloEbc] = useState("");
  const [imagenAgenda, setImagenAgenda] = useState(null);
  const [imagenCongresos, setImagenCongresos] = useState(null);
  const [imagenEbc, setImagenEbc] = useState(null);
  const [docAgenda, setDocAgenda] = useState(null);
  const [lockAgenda, setLockAgenda] = useState(true);
  const [lockCongresos, setLockCongresos] = useState(true);
  const [lockEbc, setLockEbc] = useState(true);
  const [cambios, setCambios] = useState(0);
  const [validatedAgenda, setValidatedAgenda] = useState(false);
  const [validatedCongresos, setValidatedCongresos] = useState(false);
  const [validatedEbc, setValidatedEbc] = useState(false);
  const [cargando, setCargando] = useState(false);
  const [error, setError] = useState(false);
  useEffect(() => {
    fetch(process.env.REACT_APP_DEFAULT_URL + "/api/agenda/info", {
      cache: "no-cache",
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setTituloAgenda(data.titulo);
        setAgendaInfo(data);
      });
    fetch(process.env.REACT_APP_DEFAULT_URL + "/api/ebc/info", {
      cache: "no-cache",
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setTituloEbc(data.titulo);
        setEbcInfo(data);
      });
    fetch(process.env.REACT_APP_DEFAULT_URL + "/api/congresos/info", {
      cache: "no-cache",
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setTituloCongresos(data.titulo);
        setCongresosInfo(data);
      });
  }, [contador]);
  useEffect(() => {}, [cambios]);
  const updateThing = (info, endpoint) => {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(info),
      };
      fetch(process.env.REACT_APP_DEFAULT_URL + endpoint, requestOptions, {
        cache: "no-cache",
      })
        .then((res) => {
          return res.json();
        })
        .then((datos) => {
          resolve(datos);
        });
    });
  };
  const uploadFile = (formData) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          process.env.REACT_APP_DEFAULT_URL + "/api/files/upload/s3",
          formData,
          {}
        )
        .then((res) => {
          resolve(res.data.resultado.Location);
        });
    });
  };
  const onSubmitAgenda = (evt) => {
    const form = evt.currentTarget;
    if (form.checkValidity() === false) {
      evt.preventDefault();
      evt.stopPropagation();
    } else {
      evt.preventDefault();
      //
      setCargando(true);
      setCambios(cambios + 1);
      if (imagenAgenda != null) {
        const dataImagenAgenda = new FormData();
        dataImagenAgenda.append("file", imagenAgenda);
        uploadFile(dataImagenAgenda).then((urlImagenAgenda) => {
          if (docAgenda != null) {
            const dataDocAgenda = new FormData();
            dataDocAgenda.append("file", docAgenda);
            uploadFile(dataDocAgenda).then((urlDocAgenda) => {
              updateThing(
                {
                  titulo: tituloAgenda,
                  imagen: urlImagenAgenda,
                  doc: urlDocAgenda,
                },
                "/api/agenda/info"
              ).then((data) => {
                if (data.codigo == 200) {
                  window.location.reload();
                } else {
                  console.log(data);
                }
              });
            });
          } else {
            updateThing(
              {
                titulo: tituloAgenda,
                imagen: urlImagenAgenda,
                doc: agendaInfo.agenda,
              },
              "/api/agenda/info"
            ).then((data) => {
              if (data.codigo == 200) {
                window.location.reload();
              } else {
                console.log(data);
              }
            });
          }
        });
      } else {
        if (docAgenda != null) {
          const dataDocAgenda = new FormData();
          dataDocAgenda.append("file", docAgenda);
          uploadFile(dataDocAgenda).then((urlDocAgenda) => {
            updateThing(
              {
                titulo: tituloAgenda,
                imagen: agendaInfo.imagen,
                doc: urlDocAgenda,
              },
              "/api/agenda/info"
            ).then((data) => {
              if (data.codigo == 200) {
                window.location.reload();
              } else {
                console.log(data);
              }
            });
          });
        } else {
          updateThing(
            {
              titulo: tituloAgenda,
              imagen: agendaInfo.imagen,
              doc: agendaInfo.agenda,
            },
            "/api/agenda/info"
          ).then((data) => {
            if (data.codigo == 200) {
              window.location.reload();
            } else {
              console.log(data);
            }
          });
        }
      }
    }
    setValidatedAgenda(true);
    setCambios(cambios + 1);
  };
  const onSubmitCongresos = (evt) => {
    const form = evt.currentTarget;
    if (form.checkValidity() === false) {
      evt.preventDefault();
      evt.stopPropagation();
    } else {
      evt.preventDefault();
      setCargando(true);
      setCambios(cambios + 1);
      if (imagenCongresos != null) {
        const dataImagenCongresos = new FormData();
        dataImagenCongresos.append("file", imagenCongresos);
        uploadFile(dataImagenCongresos).then((urlImagenCongresos) => {
          updateThing(
            {
              titulo: tituloCongresos,
              imagen: urlImagenCongresos,
            },
            "/api/congresos/info"
          ).then((data) => {
            if (data.codigo == 200) {
              window.location.reload();
            } else {
              console.log(data);
            }
          });
        });
      } else {
        updateThing(
          {
            titulo: tituloCongresos,
            imagen: congresosInfo.imagen,
          },
          "/api/congresos/info"
        ).then((data) => {
          if (data.codigo == 200) {
            window.location.reload();
          } else {
            console.log(data);
          }
        });
      }
    }
    setValidatedCongresos(true);
    setCambios(cambios + 1);
  };
  const onSubmitEbc = (evt) => {
    const form = evt.currentTarget;
    if (form.checkValidity() === false) {
      evt.preventDefault();
      evt.stopPropagation();
    } else {
      evt.preventDefault();
      setCargando(true);
      setCambios(cambios + 1);
      if (imagenEbc != null) {
        const dataImagenEbc = new FormData();
        dataImagenEbc.append("file", imagenEbc);
        uploadFile(dataImagenEbc).then((urlImagenEbc) => {
          updateThing(
            {
              titulo: tituloEbc,
              imagen: urlImagenEbc,
            },
            "/api/ebc/info"
          ).then((data) => {
            if (data.codigo == 200) {
              window.location.reload();
            } else {
              console.log(data);
            }
          });
        });
      } else {
        updateThing(
          {
            titulo: tituloEbc,
            imagen: ebcInfo.imagen,
          },
          "/api/ebc/info"
        ).then((data) => {
          if (data.codigo == 200) {
            window.location.reload();
          } else {
            console.log(data);
          }
        });
      }
    }
    setValidatedEbc(true);
    setCambios(cambios + 1);
  };
  const onChange = (evt) => {
    if (evt.target.name == "titulo-agenda") {
      setTituloAgenda(evt.target.value);
    } else if (evt.target.name == "titulo-congresos") {
      setTituloCongresos(evt.target.value);
    } else if (evt.target.name == "titulo-ebc") {
      setTituloEbc(evt.target.value);
    } else if (evt.target.name == "imagen-agenda") {
      setImagenAgenda(evt.target.files[0]);
    } else if (evt.target.name == "imagen-congresos") {
      setImagenCongresos(evt.target.files[0]);
    } else if (evt.target.name == "imagen-ebc") {
      setImagenEbc(evt.target.files[0]);
    } else if (evt.target.name == "doc-agenda") {
      setDocAgenda(evt.target.files[0]);
    }
    setCambios(cambios + 1);
  };
  return (
    <Container fluid>
      <Row>
        <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
          <Container>
            <Navbar.Brand href={process.env.REACT_APP_SELF_URL + "/variables"}>
              Manejo variables web
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav>
                <Nav.Link
                  eventKey={2}
                  href={process.env.REACT_APP_SELF_URL + "/home"}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-90deg-left"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1.146 4.854a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H12.5A2.5 2.5 0 0 1 15 6.5v8a.5.5 0 0 1-1 0v-8A1.5 1.5 0 0 0 12.5 5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4z"
                    />
                  </svg>
                  &nbsp;&nbsp;&nbsp;Regresar a sistema
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </Row>
      <Row
        style={{
          padding: "2%",
          textAlign: "left",
          borderTop: "5px solid #003188",
          borderBottom: "5px solid #003188",
          backgroundColor: "rgb(230, 230, 230)",
        }}
      >
        <Container fluid>
          <Row>
            <h1>Agenda mensual</h1>
          </Row>
          <Row>
            <Col xs={12} md={6} lg={4} style={{ padding: "2%" }}>
              <Button
                variant="outline-success"
                onClick={() => {
                  if (!lockAgenda) {
                    setTituloAgenda(agendaInfo.titulo);
                    setImagenAgenda(null);
                    setDocAgenda(null);
                  }
                  setLockAgenda(!lockAgenda);
                  setCambios(cambios + 1);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-pencil"
                  viewBox="0 0 16 16"
                >
                  <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                </svg>
                &nbsp;&nbsp;&nbsp;
                {lockAgenda && <strong>Editar información</strong>}
                {!lockAgenda && <strong>Cancelar edición</strong>}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col
              xs={12}
              md={6}
              lg={4}
              style={{ padding: "5%", textAlign: "center" }}
            >
              <h2>Imagen representativa</h2>
              <img
                loading="lazy"
                src={agendaInfo.imagen}
                style={{ width: "100%" }}
              />
            </Col>
            <Col
              xs={12}
              md={6}
              lg={4}
              style={{ padding: "5%", textAlign: "center" }}
            >
              <h2>Documento representativo</h2>
              <iframe
                loading="lazy"
                src={agendaInfo.agenda}
                style={{ width: "100%", height: "100%" }}
              />
            </Col>
            <Col
              xs={12}
              md={6}
              lg={4}
              style={{ padding: "5%", textAlign: "center" }}
            >
              <h2>Título representativo</h2>
              <p>{agendaInfo.titulo}</p>
              <br />
              <br />
              <br />
              <h3>Editar información</h3>
              <Form
                onSubmit={onSubmitAgenda}
                noValidate
                validated={validatedAgenda}
              >
                <Row>
                  <Form.Group className="mb-3" controlId="nombres">
                    <Form.Label>Título representativo de agenda</Form.Label>
                    <Form.Control
                      value={tituloAgenda}
                      disabled={cargando || error || lockAgenda}
                      required
                      type="text"
                      rows={5}
                      style={{ resize: "none" }}
                      placeholder="Ingrese el titulo representativo para la agenda mensual"
                      name="titulo-agenda"
                      onChange={onChange}
                    />
                    <Form.Control.Feedback>Ok</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      Por favor, completa este campo
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className="mb-3" controlId="nombres">
                    <Form.Label>Archivo para imagen representativa</Form.Label>
                    <Form.Control
                      disabled={cargando || error || lockAgenda}
                      type="file"
                      rows={5}
                      style={{ resize: "none" }}
                      name="imagen-agenda"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={onChange}
                    />
                    <Form.Control.Feedback>Ok</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      Por favor, completa este campo
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className="mb-3" controlId="nombres">
                    <Form.Label>
                      Archivo para documento representativo
                    </Form.Label>
                    <Form.Control
                      disabled={cargando || error || lockAgenda}
                      type="file"
                      rows={5}
                      style={{ resize: "none" }}
                      name="doc-agenda"
                      accept="application/pdf"
                      onChange={onChange}
                    />
                    <Form.Control.Feedback>Ok</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      Por favor, completa este campo
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row>
                  <Button
                    variant="outline-success"
                    type="submit"
                    disabled={cargando || error || lockAgenda}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-patch-check"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10.354 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708 0z"
                      />
                      <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911l-1.318.016z" />
                    </svg>
                    &nbsp;&nbsp;&nbsp;&nbsp;Guardar cambios
                  </Button>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </Row>
      <Row
        style={{
          padding: "2%",
          textAlign: "left",
          borderTop: "5px solid #003188",
          marginTop: "50px",
          borderBottom: "5px solid #003188",
          backgroundColor: "rgb(230, 230, 230)",
        }}
      >
        <Container fluid>
          <Row>
            <h1>Escuela Bancaria Centroamericana</h1>
          </Row>
          <Row>
            <Col xs={12} md={6} lg={4} style={{ padding: "2%" }}>
              <Button
                variant="outline-success"
                onClick={() => {
                  if (!lockEbc) {
                    setTituloEbc(ebcInfo.titulo);
                    setImagenEbc(null);
                  }
                  setLockEbc(!lockEbc);
                  setCambios(cambios + 1);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-pencil"
                  viewBox="0 0 16 16"
                >
                  <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                </svg>
                &nbsp;&nbsp;&nbsp;
                {lockEbc && <strong>Editar información</strong>}
                {!lockEbc && <strong>Cancelar edición</strong>}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col
              xs={12}
              md={6}
              lg={6}
              style={{ padding: "2%", textAlign: "center" }}
            >
              <h2>Imagen representativa</h2>
              <img
                loading="lazy"
                src={ebcInfo.imagen}
                style={{ width: "100%" }}
              />
            </Col>
            <Col
              xs={12}
              md={6}
              lg={6}
              style={{ padding: "2%", textAlign: "center" }}
            >
              <h2>Título representativo</h2>
              <p>{ebcInfo.titulo}</p>
              <br />
              <br />
              <br />
              <h3>Editar información</h3>
              <Form onSubmit={onSubmitEbc} noValidate validated={validatedEbc}>
                <Row>
                  <Form.Group className="mb-3" controlId="nombres">
                    <Form.Label>Título representativo de EBC</Form.Label>
                    <Form.Control
                      value={tituloEbc}
                      disabled={cargando || error || lockEbc}
                      required
                      type="text"
                      rows={5}
                      style={{ resize: "none" }}
                      placeholder="Ingrese el titulo representativo para la EBC"
                      name="titulo-ebc"
                      onChange={onChange}
                    />
                    <Form.Control.Feedback>Ok</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      Por favor, completa este campo
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className="mb-3" controlId="nombres">
                    <Form.Label>Archivo para imagen representativa</Form.Label>
                    <Form.Control
                      disabled={cargando || error || lockEbc}
                      type="file"
                      rows={5}
                      style={{ resize: "none" }}
                      name="imagen-ebc"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={onChange}
                    />
                    <Form.Control.Feedback>Ok</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      Por favor, completa este campo
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row>
                  <Button
                    variant="outline-success"
                    type="submit"
                    disabled={cargando || error || lockEbc}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-patch-check"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10.354 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708 0z"
                      />
                      <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911l-1.318.016z" />
                    </svg>
                    &nbsp;&nbsp;&nbsp;&nbsp;Guardar cambios
                  </Button>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </Row>
      <Row
        style={{
          padding: "2%",
          textAlign: "left",
          borderTop: "5px solid #003188",
          marginTop: "50px",
          borderBottom: "5px solid #003188",
          backgroundColor: "rgb(230, 230, 230)",
        }}
      >
        <Container fluid>
          <Row>
            <h1>Congresos</h1>
          </Row>
          <Row>
            <Col xs={12} md={6} lg={4} style={{ padding: "2%" }}>
              <Button
                variant="outline-success"
                onClick={() => {
                  if (!lockCongresos) {
                    setTituloCongresos(congresosInfo.titulo);
                    setImagenCongresos(null);
                  }
                  setLockCongresos(!lockCongresos);
                  setCambios(cambios + 1);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-pencil"
                  viewBox="0 0 16 16"
                >
                  <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                </svg>
                &nbsp;&nbsp;&nbsp;
                {lockCongresos && <strong>Editar información</strong>}
                {!lockCongresos && <strong>Cancelar edición</strong>}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col
              xs={12}
              md={6}
              lg={6}
              style={{ padding: "2%", textAlign: "center" }}
            >
              <h2>Imagen representativa</h2>
              <img
                loading="lazy"
                src={congresosInfo.imagen}
                style={{ width: "100%" }}
              />
            </Col>
            <Col
              xs={12}
              md={6}
              lg={6}
              style={{ padding: "2%", textAlign: "center" }}
            >
              <h2>Título representativo</h2>
              <p>{congresosInfo.titulo}</p>
              <br />
              <br />
              <br />
              <h3>Editar información</h3>
              <Form
                onSubmit={onSubmitCongresos}
                noValidate
                validated={validatedCongresos}
              >
                <Row>
                  <Form.Group className="mb-3" controlId="nombres">
                    <Form.Label>Título representativo de congresos</Form.Label>
                    <Form.Control
                      value={tituloCongresos}
                      disabled={cargando || error || lockCongresos}
                      required
                      type="text"
                      rows={5}
                      style={{ resize: "none" }}
                      placeholder="Ingrese el titulo representativo para los congresos"
                      name="titulo-congresos"
                      onChange={onChange}
                    />
                    <Form.Control.Feedback>Ok</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      Por favor, completa este campo
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className="mb-3" controlId="nombres">
                    <Form.Label>Archivo para imagen representativa</Form.Label>
                    <Form.Control
                      disabled={cargando || error || lockCongresos}
                      type="file"
                      rows={5}
                      style={{ resize: "none" }}
                      name="imagen-congresos"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={onChange}
                    />
                    <Form.Control.Feedback>Ok</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      Por favor, completa este campo
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row>
                  <Button
                    variant="outline-success"
                    type="submit"
                    disabled={cargando || error || lockCongresos}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-patch-check"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10.354 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708 0z"
                      />
                      <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911l-1.318.016z" />
                    </svg>
                    &nbsp;&nbsp;&nbsp;&nbsp;Guardar cambios
                  </Button>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </Row>
    </Container>
  );
};

export default Variables;
