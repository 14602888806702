import { useState, useEffect } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
//import DEFAULT_URL from '../../api/url';
const Users = () => {
    const [users, setUsers] = useState([]);
    const axiosPrivate = useAxiosPrivate();
    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        const getUsers = async () => {
            try {
                const response = await axiosPrivate.get(process.env.REACT_APP_DEFAULT_URL+ '/api/read/asesor', {
                    signal: controller.signal
                });
                isMounted && setUsers(response.data.rows);
            } catch (err) {
                console.log(err);
            }
        }
        getUsers();
        return () => {
            isMounted = false;
            controller.abort();
        }
    });
    return (
        <article>
            <h2>Users List</h2>
            {users?.length
                ? (
                    <ul>
                        {users.map((user, i) => <li key={i}>{user.Nombres + ' ' + user.Apellidos}</li>)}</ul>
                ) : <p>No users to display</p>
            }
        </article>
    );
};

export default Users;
