import React, { useState, useEffect } from "react";
import { Container, Row, Form, Button, Tab, Tabs } from "react-bootstrap";
import DetalleCosto from "./DetalleCosto";
const CostosTarifa = (props) => {
  const [contador] = useState(0);
  const [descripcion, setDescripcion] = useState("");
  const [cambios, setCambios] = useState("");
  const [validated, setValidated] = useState(false);
  const [cargando, setCargando] = useState(false);
  const [error, setError] = useState(false);
  const [adicional, setAdicional] = useState("");
  const [precio, setPrecio] = useState(0);
  const [moneda, setMoneda] = useState("");
  const [costos, setCostos] = useState([]);
  useEffect(() => {
    fetch(
      process.env.REACT_APP_DEFAULT_URL +
        "/api/costos-tarifas/read/" +
        props.id,
      {
        cache: "no-cache",
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setCostos(data.rows);
      });
  }, [contador]);
  useEffect(() => {}, [cambios]);
  const onSubmit = (evt) => {
    const form = evt.currentTarget;
    if (form.checkValidity() === false) {
      evt.preventDefault();
      evt.stopPropagation();
    } else {
      evt.preventDefault();
      setCargando(true);
      setCambios(cambios + 1);
      const info = {
        descripcion: descripcion,
        precio: precio,
        moneda: moneda,
        adicional: adicional,
        tarifa: props.id,
      };
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(info),
      };
      fetch(
        process.env.REACT_APP_DEFAULT_URL + "/api/costos-tarifas/create",
        requestOptions,
        { cache: "no-cache" }
      )
        .then((res) => {
          return res.json();
        })
        .then((datos) => {
          window.location.reload();
        });
    }
    setValidated(true);
    setCambios(cambios + 1);
  };
  const deleteCosto = (id) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    };
    fetch(
      process.env.REACT_APP_DEFAULT_URL + "/api/tarifas/delete/" + id,
      requestOptions,
      { cache: "no-cache" }
    )
      .then((res) => {
        return res.json();
      })
      .then((datos) => {
        window.location.reload();
      });
  };
  const onChange = (evt) => {
    if (evt.target.name == "descripcion") {
      setDescripcion(evt.target.value);
    } else if (evt.target.name == "adicional") {
      setAdicional(evt.target.value);
    } else if (evt.target.name == "precio") {
      setPrecio(evt.target.value);
    } else if (evt.target.name == "moneda") {
      setMoneda(evt.target.value);
    }
    setCambios(cambios + 1);
  };
  return (
    <Tabs defaultActiveKey="registro" className="mb-3">
      <Tab eventKey="registro" title="Registrar Costo">
        <Container style={{ padding: "3%" }}>
          <Row style={{ padding: "2%", textAlign: "left" }}>
            <h3>Registrar costo</h3>
          </Row>
          <Form onSubmit={onSubmit} noValidate validated={validated}>
            <Row>
              <Form.Group className="mb-3" controlId="nombres">
                <Form.Label>Perfil del participante a aplicar costo</Form.Label>
                <Form.Control
                  value={descripcion}
                  disabled={cargando || error}
                  required
                  as="textarea"
                  rows={5}
                  style={{ resize: "none" }}
                  placeholder="Ingrese el perfil del participante quien aplica a costo"
                  name="descripcion"
                  onChange={onChange}
                />
                <Form.Control.Feedback>Ok</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Por favor, completa este campo
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3" controlId="nombres">
                <Form.Label>Detalles adicionales o beneficios</Form.Label>
                <Form.Control
                  value={adicional}
                  disabled={cargando || error}
                  as="textarea"
                  rows={5}
                  style={{ resize: "none" }}
                  placeholder="Ingrese detalles adicionales o beneficios"
                  name="adicional"
                  onChange={onChange}
                />
                <Form.Control.Feedback>Ok</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Por favor, completa este campo
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3" controlId="nombres">
                <Form.Label>Precio</Form.Label>
                <Form.Control
                  value={precio}
                  disabled={cargando || error}
                  type="number"
                  step={0.01}
                  style={{ resize: "none" }}
                  placeholder="Ingrese el precio"
                  name="precio"
                  onChange={onChange}
                  required
                  min={0.01}
                />
                <Form.Control.Feedback>Ok</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Por favor, completa este campo
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group controlId="moneda" className="mb-3">
                <Form.Label>Tipo moneda</Form.Label>
                <Form.Select
                  required
                  onChange={onChange}
                  name="moneda"
                  value={moneda}
                  disabled={cargando || error}
                >
                  <option value="">Seleccione la moneda</option>
                  <option value="0">GTQ</option>
                  <option value="1">USD</option>
                </Form.Select>
                <Form.Control.Feedback>Ok</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Por favor, completa este campo
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              <Button
                variant="outline-primary"
                type="submit"
                disabled={cargando || error}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-tags"
                  viewBox="0 0 16 16"
                >
                  <path d="M3 2v4.586l7 7L14.586 9l-7-7H3zM2 2a1 1 0 0 1 1-1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 2 6.586V2z" />
                  <path d="M5.5 5a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm0 1a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zM1 7.086a1 1 0 0 0 .293.707L8.75 15.25l-.043.043a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 0 7.586V3a1 1 0 0 1 1-1v5.086z" />
                </svg>
                &nbsp;&nbsp;&nbsp;&nbsp;Registrar costo
              </Button>
            </Row>
          </Form>
        </Container>
      </Tab>
      <Tab eventKey="Costos registrados" title="Costos registrados">
        <Container style={{ padding: "3%" }}>
          <Row style={{ padding: "2%", textAlign: "left" }}>
            <h3>Costos registrados: {costos.length}</h3>
          </Row>
          {costos.map((actual, index) => (
            <Row key={index}>
              <DetalleCosto costo={actual}/>
            </Row>
          ))}
        </Container>
      </Tab>
    </Tabs>
  );
};

export default CostosTarifa;
