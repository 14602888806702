import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Alert } from "react-bootstrap";
import View from "../../helpers/View";
import Read from "../../helpers/read";
const helpers = require("../../helpers/helpers");
const ViewPropuesta = () => {
  const [contador] = useState(0);
  const [config, setConfig] = useState(null);
  const [propuesta, setPropuesta] = useState(null);
  const [configFacilitadores, setConfigFacilitadores] = useState(null);
  const [configTemas, setConfigTemas] = useState(null);
  const { UniqueID } = useParams();
  useEffect(() => {
    helpers
      .getData("/api/view/propuesta/" + UniqueID)
      .then((response) => {
        setPropuesta(response.data.rows[0]);
        setConfig({
          endpoint: "/api/view/propuesta/" + UniqueID,
          onDelete: "/api/delete/propuesta/" + UniqueID,
          onUpdate: null,
          key: "UniqueID",
          withActions: true,
          confirmationMensage:
            "¿Está segur@ de eliminar la propuesta seleccionada??",
          name: "Propuesta IN-HOUSE",
          properties: [
            {
              label: "Grado académico del solicitante",
              field: "grado_academico",
              type: 1,
            },
            {
              label: "Nombre de solicitante",
              field: "nombre",
              type: 1,
            },
            {
              label: "Puesto del solicitante",
              field: "puesto",
              type: 1,
            },
            {
              label: "Correo electrónico del solicitante",
              field: "correo",
              type: 1,
            },
            {
              label: "Teléfono del solicitante",
              field: "telefono",
              type: 1,
            },
            {
              label: "Institución solicitante",
              field: "institucion",
              type: 1,
            },
            {
              label: "PDF adjunto",
              field: "archivo",
              type: 3,
            },
            {
              label: "Horas estimadas del evento",
              field: "horas",
              type: 1,
            },
            {
              label: "Tema propuesto",
              field: "temaPropuesto",
              type: 1,
            },
            {
              label: "Perfil de participante",
              field: "perfil",
              type: 1,
            },
            {
              label: "No. de participantes",
              field: "participantes",
              type: 1,
            },
            {
              label: "Horario propuesto",
              field: "horario",
              type: 1,
            },
            {
              label: "Fecha propuesta",
              field: "fechaPropuesta",
              type: 1,
            },
            {
              label: "Servicio",
              field: "servicio",
              type: 1,
            },
            {
              label: "Honorarios docentes",
              field: "honorarios",
              type: 1,
            },
            {
              label: "Lugar a ejecutar evento",
              field: "lugar",
              type: 1,
            },
            {
              label: "Descripción general de lo que incluye el servicio",
              field: "descripcion_paquete",
              type: 1,
            },
            {
              label: "Descripción general del curso",
              field: "descripcion_general",
              type: 1,
            },
            {
              label: "Comentarios adicionales",
              field: "comentarios",
              type: 1,
            },
          ],
          extraActions: [
            {
              configuration: {
                type: 5,
                icon: "file",
                variant: "outline-primary",
                title: "Generar PDF",
                url: "/in-house/solicitud/" + response.data.rows[0].hash,
              },
            },
            {
              configuration: {
                type: 1,
                url: "/in-house/seguimiento",
                icon: "list",
                variant: "outline-primary",
                title: "Ver propuestas",
              },
            },
          ],
        });
        setConfigFacilitadores({
          endpoint: "/api/facilitadores/read/by-propuesta/" + UniqueID,
          onDelete: null,
          onUpdate: null,
          onRead: null,
          key: "UniqueID",
          properties: [
            { label: "Nombres Facilitador", field: "Nombres" },
            { label: "Apellidos Facilitador", field: "Apellidos" },
            { label: "Tarifa Facilitador", field: "Tarifa" },
          ],
          withActions: false,

          placeHolder: "puedes buscar por nombre o apellido de facilitador",
        });
        setConfigTemas({
          endpoint: "/api/temas/read/by-propuesta/" + UniqueID,
          onDelete: null,
          onUpdate: null,
          onRead: null,
          key: "UniqueID",
          properties: [
            { label: "Catálogo", field: "catalogo" },
            { label: "Tema", field: "nombre" },
          ],
          withActions: false,

          placeHolder: "puedes buscar por tema o catalogo",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [contador]);
  const filterEvtFacilitador = (actual, valor) => {
    return (
      actual.Nombres.search(valor) != -1 ||
      actual.Apellidos.search(valor) != -1 ||
      `${actual.Nombres.search(valor)} ${actual.Apellidos.search(valor)}` != -1
    );
  };
  const filterEvtTemas = (actual, valor) => {
    return (
      actual.catalogo.search(valor) != -1 || actual.nombre.search(valor) != -1
    );
  };
  return (
    <Container fluid style={{ padding: "5%", textAlign: "left" }}>
      <Row>
        {propuesta && -1 * propuesta.dias == 0 && (
          <Alert variant="success">La propuesta fue registrada hoy</Alert>
        )}
        {propuesta && -1 * propuesta.dias < 5 && (
          <Alert variant="primary">
            La propuesta fue registrada hace {-1 * propuesta.dias} dia(s)
          </Alert>
        )}
        {propuesta && -1 * propuesta.dias >= 5 && -1 * propuesta.dias < 10 && (
          <Alert variant="warning">
            La propuesta fue registrada hace {-1 * propuesta.dias} dia(s)
          </Alert>
        )}
        {propuesta && -1 * propuesta.dias >= 10 && (
          <Alert variant="danger">
            La propuesta fue registrada hace {-1 * propuesta.dias} dia(s)
          </Alert>
        )}
      </Row>
      {config != null && (
        <Row>
          <View
            configuration={config}
            actionAfterDelete={() => {
              window.location.href = "/in-house/seguimiento";
            }}
          />
        </Row>
      )}
      {configFacilitadores != null && (
        <Row className="mt-5">
          <h2 style={{ textAlign: "left" }}>Docentes</h2>

          <Read
            configuration={configFacilitadores}
            filterEvt={filterEvtFacilitador}
            actionAfterDelete={() => {
              window.location.reload();
            }}
          />
        </Row>
      )}
      {configTemas != null && (
        <Row className="mt-5">
          <h2 style={{ textAlign: "left" }}>Contenidos</h2>
          <Read
            configuration={configTemas}
            filterEvt={filterEvtTemas}
            actionAfterDelete={() => {
              window.location.reload();
            }}
          />
        </Row>
      )}
    </Container>
  );
};

export default ViewPropuesta;
