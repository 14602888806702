import React from "react";
import { Container, Row } from "react-bootstrap";
const Img = (props) => {
  return (
    <Container fluid>
      <Row>
        <img src={props.src} loading="lazy" />
      </Row>
    </Container>
  );
};

export default Img;
