import React, { useEffect, useState } from 'react'
import './../Cartera.css'
//import DEFAULT_URL from '../../../../api/url';
import { ModalInfo } from './ModalInfo';
import { Row, Col, ListGroup, Container, Popover } from "react-bootstrap";
function InscripcionesPendientes(props) {
    const [inscripciones, setInscripciones] = useState([]);
    const [contador, setContador] = useState(0);
    const [modalInfo, setModalInfo] = useState(false);
    const [detalle, setDetalle] = useState([]);
    useEffect(async () => {
        async function fetchData() {
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/auth/userinfo', {cache: "no-cache",  credentials: 'include' })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/asesor/inscripcion/procesado/no/' + data.UniqueID + "/" + props.UniqueID, { cache: "no-cache" })
                        .then((res) => {
                            return res.json();
                        })
                        .then(data => {
                            setInscripciones(data.rows);
                        });
                    fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/asesor/inscripcion/procesado/no/detalle/' + data.UniqueID + "/" + props.UniqueID, { cache: "no-cache" })
                        .then((res) => {
                            return res.json();
                        })
                        .then(data => {
                            setDetalle(data.rows);
                        });
                });
        }
        fetchData();
    }, [contador]);
    const hideModal = () => {
        setModalInfo(false);
        setContador(contador + 1);
    }
    const refrescar = () => {
        setContador(contador + 1);
    }
    return (
        <Container>
            <Row style={{ marginBottom: '25px' }}>
                <Col xs={1} md={1} lg={1}>
                    <div style={{ color: 'white', cursor: 'pointer' }} data-toggle="tooltip" data-placement="top" title="Refrescar" onClick={() => setContador(contador + 1)} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                            <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                        </svg>
                    </div>
                </Col>
            </Row>
            <Row>
                <ListGroup as="ol">
                    {inscripciones.map((inscripcion) =>
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start"
                        >
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">{inscripcion.empresa}</div>
                                {inscripcion.fecha}
                            </div>
                            <div data-toggle="tooltip" data-placement="top" title="Más Información" style={{ cursor: 'pointer', color: 'black' }} onClick={() => { setModalInfo(true); setContador(contador + 1); }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
                                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                                </svg>
                            </div>
                            <ModalInfo detalle={detalle} show={modalInfo} onHide={hideModal} evento={props.UniqueID} inscripcion={inscripcion.Id} refrescar={refrescar} />
                        </ListGroup.Item>
                    )}
                </ListGroup>
            </Row>
        </Container>
    )
}

export default InscripcionesPendientes