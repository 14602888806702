import React from 'react';
import { Row, Container, Table } from "react-bootstrap";
import Evento from './Evento';
import './Gerencia.css'
const TipoEvento = (props) => {

    return (
        <Row style={{ marginTop: '50px' }}>
            <Container>
                <Row style={{ color: 'white', marginBottom: '10px', textAlign: 'left' }}>
                    <h1 >{props.eventos.tipo}</h1>
                </Row>
                <Row className='fila-tabla-reporte'>
                    <div style={{ minWidth: '3000px', height: '300px' }}>
                        <Table striped bordered hover variant="light" style={{ borderCollapse: 'collapse', minWidth: '3000px' }}>
                            <thead>
                                <tr>
                                    <th style={{ position: 'sticky', top: '0' }}>Evento</th>
                                    <th style={{ position: 'sticky', top: '0' }} colSpan={7}>Ejecutado</th>
                                    <th style={{ position: 'sticky', top: '0' }} colSpan={2}>Total inscritos</th>
                                    <th style={{ position: 'sticky', top: '0' }} colSpan={4}>Proyectado</th>
                                    <th style={{ position: 'sticky', top: '0' }} colSpan={4}>Detalles</th>
                                </tr>
                                <tr>
                                    <th style={{ position: 'sticky', top: '0' }}>Nombre del evento</th>
                                    <th style={{ position: 'sticky', top: '0' }}>Fecha</th>
                                    <th style={{ position: 'sticky', top: '0' }}>Ingresos</th>
                                    <th style={{ position: 'sticky', top: '0' }}>Egresos</th>
                                    <th style={{ position: 'sticky', top: '0' }}>Resultado</th>
                                    <th style={{ position: 'sticky', top: '0' }}>Cortesías</th>
                                    <th style={{ position: 'sticky', top: '0' }}>Pagados</th>
                                    <th style={{ position: 'sticky', top: '0' }}>Sin validación de contabilidad</th>
                                    <th style={{ position: 'sticky', top: '0' }}>Ejecutado EBC</th>
                                    <th style={{ position: 'sticky', top: '0' }}>Ejecutado EBG</th>
                                    <th style={{ position: 'sticky', top: '0' }}>Ingresos Proyectados</th>
                                    <th style={{ position: 'sticky', top: '0' }}>Egresos Proyectados</th>
                                    <th style={{ position: 'sticky', top: '0' }}>Resultado Proyectado</th>
                                    <th style={{ position: 'sticky', top: '0' }}>Mínimo participantes</th>
                                    <th style={{ position: 'sticky', top: '0' }}>Ventas</th>
                                    <th style={{ position: 'sticky', top: '0' }}>Inscripciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.eventos.eventos.map((evento) => <Evento key={evento.UniqueID} evento={evento} />)}
                            </tbody>
                        </Table>
                    </div>
                </Row>
            </Container>
        </Row>)
}

export default TipoEvento