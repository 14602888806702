import React, { Component } from 'react';
import { Modal, Row, Button, Form, Container } from "react-bootstrap";
import './comon.css'
export class ModalNuevaActividad extends Component {

    state = { Nombre: '', Ponderacion: '', Descripcion: '', evento_UniqueID: '', Criterio: '0' }

    componentDidMount = () => {
        this.setState({ evento_UniqueID: this.props.evento_UniqueID });
    }

    onchangehandler = (evt) => {
        this.setState({ [evt.target.name]: evt.target.value });
    }

    onsubmit = (evt) => {
        evt.preventDefault();
        this.props.submitnewactividad(this.state);
        this.setState({ Nombre: '', Ponderacion: '', Descripcion: '', Criterio: '0' });
    }
    render() {
        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
            >
                <Modal.Header closeButton style={{ backgroundColor: "#d3d3d4" }}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Registrar Actividad
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: "#d3d3d4" }} className="modal-nueva-actividad">
                    <Form onSubmit={this.onsubmit}>
                        <Container>
                            <Row>
                                <Form.Group className="mb-3" controlId="Nombre">
                                    <Form.Label>Nombre De Actividad</Form.Label>
                                    <Form.Control required type="text" placeholder="Nombre de la actividad" value={this.state.Nombre} name='Nombre' onChange={this.onchangehandler} />
                                </Form.Group>
                            </Row>
                            <Row>

                                <Form.Group className="mb-3" controlId="Descripcion">
                                    <Form.Label>Descripción De Actividad</Form.Label>
                                    <Form.Control required as="textarea" rows={3} style={{ resize: 'none' }} placeholder="Descripción de la actividad" value={this.state.Descripcion} name='Descripcion' onChange={this.onchangehandler} />
                                </Form.Group>
                            </Row>

                            <Row>
                                <Form.Group className="mb-3" controlId="Ponderacion">
                                    <Form.Label>Ponderación De La Actividad</Form.Label>
                                    <Form.Control required type="text" placeholder="Ponderación de la actividad" value={this.state.Ponderacion} name='Ponderacion' onChange={this.onchangehandler} />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group className="mb-3" controlId="Criterio">
                                    <Form.Label>Criterio De Calificación</Form.Label>
                                    <Form.Control as="select" required aria-label="Criterio" name="Criterio" onChange={this.onchangehandler} value={this.state.Criterio} >
                                        <option value="" key="0">Seleccione el criterio de calificacion</option>
                                        <option value="1">Nota más alta</option>
                                        <option value="2">Última entrega</option>
                                        <option value="3">Promedio</option>
                                    </Form.Control>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Button variant="outline-dark" className="font-bold" type="submit">Guardar</Button>
                            </Row>
                        </Container>
                    </Form>
                </Modal.Body>
            </Modal>
        );
    }
}