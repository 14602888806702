import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Form } from 'react-bootstrap'
//import DEFAULT_URL from '../../api/url';
const PagosNoVinculados = () => {
    const [contador] = useState(0);
    const [cambios, setCambios] = useState(0);
    const [pagos, setPagos] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [filterData, setFilterData] = useState('');
    useEffect(() => {
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/pago-no-cobro/read', { cache: "no-cache", credentials: 'include' })
            .then((res) => {
                return res.json();
            })
            .then(data => {
                setPagos(data.resultado);
                setFilteredData(data.resultado);
            });
        return true;
    }, [contador]);
    useEffect(() => {
    }, [cambios]);
    const filterEvent = (evt) => {
        setFilterData(evt.target.value);
        setCambios(cambios + 1);
        let result = [];
        result = pagos.filter((actual) => {
            return actual.nombres.search(evt.target.value) != -1 || actual.apellidos.search(evt.target.value) != -1 || actual.email.search(evt.target.value) != -1 || actual.tel.search(evt.target.value) != -1 || actual.descripcion.search(evt.target.value) != -1 || actual.total.search(evt.target.value) != -1 || actual.fecha.search(evt.target.value) != -1 || actual.moneda.search(evt.target.value) != -1;
        });
        setFilteredData(result);
        setCambios(cambios + 1);
    }
    return (
        <Container style={{ padding: '2%' }}>
            <Row style={{ padding: '2%', textAlign: 'left', borderLeft: '5px solid #1e3d52 ', backgroundColor: 'rgb(250,250,250)' }}>
                <h1>Pagos no vinculados</h1>
            </Row>
            <Row style={{ padding: '2%', textAlign: 'left' }}>
                <Form.Group className="mb-3">
                    <Form.Label>Buscar</Form.Label>
                    <Form.Control value={filterData} onChange={filterEvent} placeholder="Puedes buscar por  nombres, apellidos, email, telefono, descripcion, total, moneda" />
                </Form.Group>
            </Row>
            {filteredData.map((actual, index) => <Row key={'cobro-all-' + index} style={{ padding: '2%', textAlign: 'left' ,borderLeft: '5px solid #1e3d52 ',borderBottom: '5px solid #1e3d52 '}}>
                <h3>{actual.moneda + ' ' + actual.total}</h3>
                <p style={{ textAlign: 'justify' }}><strong>DESCRIPCIÓN:</strong><br />{actual.descripcion}</p>
                <p><strong>PERSONA QUE REALIZÓ EL PAGO: </strong>{actual.nombres + ' ' + actual.apellidos}</p>
                <p><strong>TELÉFONO: </strong>{actual.tel}</p>
                <p><strong>CORREO ELECTRÓNICO: </strong>{actual.email}</p>
                <p><strong>FECHA: </strong>{actual.fecha}</p>
            </Row>)}
        </Container>)
}

export default PagosNoVinculados