import React from 'react';
import { Modal, Button, Container } from "react-bootstrap";
import {
    ComposedChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';

const ModalRendimiento = (props) => {
    return (
        <Modal
            {...props}
            size="fullscreen"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
        >
            <Modal.Header closeButton style={{ backgroundColor: "#E0E0E2" }}>
                <Modal.Title id="contained-modal-title-vcenter" className="font-bold">
                    Rendimiento
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: "#E0E0E2", height: '60vh' }} id="body-modal">
                <Container >
                    <ResponsiveContainer width="100%" height="100%" id='chart'>
                        <ComposedChart
                            layout="vertical"
                            width={500}
                            height={400}
                            data={props.data}
                            margin={{
                                top: 20,
                                right: 20,
                                bottom: 20,
                                left: 20,
                            }}
                        >
                            <CartesianGrid stroke="#f5f5f5" />
                            <XAxis type="number" />
                            <YAxis dataKey="asesor" type="category" scale="band" />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="inscritos" barSize={20} fill="#416788" />
                        </ComposedChart>
                    </ResponsiveContainer>
                </Container>
            </Modal.Body>
            <Modal.Footer style={{ backgroundColor: "#E0E0E2" }}>
                <Button variant="secondary" className="font-bold" onClick={props.onHide}>Cerrar</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalRendimiento;
