import React, { useState, useEffect } from 'react'
//import DEFAULT_URL, { SELF_URL } from '../../../../../api/url';
import { Row, Alert, Button, Spinner, Popover, Col, Form, Container, Accordion, ButtonToolbar, ButtonGroup, DropdownButton, InputGroup } from "react-bootstrap";
import axios from 'axios';
const popover = (
    <Popover id="popover-basic">
        <Popover.Header as="h3">¡Listo!</Popover.Header>
        <Popover.Body>
            Enlace copiado exitosamente.
        </Popover.Body>
    </Popover>
);
const DetalleAgenda = (props) => {
    const [contador, setContador] = useState(0);
    const [detalle, setDetalle] = useState('none');
    const [portada, setPortada] = useState('block');
    const [exito, setExito] = useState('none');
    const [error, setError] = useState('none');
    const [cargando, setCargando] = useState('none');
    const [confirmacion, setConfirmacion] = useState('none');
    const [mensaje, setMensaje] = useState('');
    const [inicioAgenda, setInicioAgenda] = useState('');
    const [finAgenda, setFinAgenda] = useState('');
    const [nombreAgenda, setNombreAgenda] = useState('');
    const [puestoAgenda, setPuestoAgenda] = useState('');
    const [institucionAgenda, setInstitucionAgenda] = useState('');
    const [paisAgenda, setPaisAgenda] = useState('');
    const [conceptoAgenda, setConceptoAgenda] = useState('');
    const [fotoAgenda, setFotoAgenda] = useState(null);
    const [cambios, setCambios] = useState(0);
    const [paises, setPaises] = useState([]);

    useEffect(() => {
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/paises')
            .then((res) => {
                return res.json()
            })
            .then(data => {
                let paises_arr = [];
                data.paises.map((actual) => { paises_arr.push({ id: actual.code, nombre: actual.name }) });
                paises_arr.sort(function (a, b) { return a.nombre.localeCompare(b.nombre) });
                setPaises(paises_arr);
            });
        setInicioAgenda(props.agenda.fechaInicio);
        setFinAgenda(props.agenda.fechaFin);
        setNombreAgenda(props.agenda.nombre);
        setPuestoAgenda(props.agenda.puesto);
        setInstitucionAgenda(props.agenda.institucion);
        setPaisAgenda(props.agenda.pais);
        setConceptoAgenda(props.agenda.concepto);
    }, [contador, props.contador]);
    useEffect(() => {

    }, [cambios]);

    const deleteSelf = () => {
        cargar('Sincronizando base de datos');
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/agenda-jornada/delete/' + props.agenda.UniqueID, requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                if (data.codigo === 200) {
                    operacionExito('Enlace eliminado exitosamente');
                } else {
                    operacionError('Error al eliminar registro en db');
                }
            });

    }
    const cargar = (mensaje) => {
        setExito('none');
        setDetalle('none');
        setPortada('none');
        setError('none');
        setCargando('block');
        setConfirmacion('none');
        setMensaje(mensaje);
        setCambios(cambios + 1);
    }
    const confirmar = () => {
        setExito('none');
        setDetalle('none');
        setPortada('none');
        setError('none');
        setCargando('none');
        setConfirmacion('block');
        setCambios(cambios + 1);
    }
    const operacionExito = (mensaje) => {
        setExito('block');
        setDetalle('none');
        setPortada('none');
        setError('none');
        setCargando('none');
        setMensaje(mensaje);
        props.refresh();
        setContador(contador + 1);
    }
    const operacionError = (mensaje) => {
        setDetalle('none');
        setPortada('none');
        setExito('none');
        setError('block');
        setCargando('none');
        setMensaje(mensaje);
        setCambios(cambios + 1);
    }

    const goBack = () => {
        setDetalle('none');
        setPortada('block');
        setExito('none');
        setError('none');
        setCargando('none');
        setConfirmacion('none');
        setMensaje('');
        setInicioAgenda('');
        setFinAgenda('');
        setNombreAgenda('');
        setPuestoAgenda('');
        setInstitucionAgenda('');
        setPaisAgenda('');
        setConceptoAgenda('');
        setFotoAgenda(null);
        Array.from(document.querySelectorAll("input")).forEach(
            input => (input.value = "")
        );
        props.refresh();
        setContador(contador + 1);
    }
    const onChange = (evt) => {
        if (evt.target.name == 'inicio') {
            setInicioAgenda(evt.target.value);
        } else if (evt.target.name == 'fin') {
            setFinAgenda(evt.target.value);
        } else if (evt.target.name == 'nombre') {
            setNombreAgenda(evt.target.value);
        } else if (evt.target.name == 'puesto') {
            setPuestoAgenda(evt.target.value);
        } else if (evt.target.name == 'institucion') {
            setInstitucionAgenda(evt.target.value);
        } else if (evt.target.name == 'pais') {
            setPaisAgenda(evt.target.value);
        } else if (evt.target.name == 'concepto') {
            setConceptoAgenda(evt.target.value);
        } else if (evt.target.name == 'foto') {
            setFotoAgenda(evt.target.files[0]);
        }
        setCambios(cambios + 1);
    }
    const submitEditAgenda = (evt) => {
        evt.preventDefault();
        cargar('Sincronizando base de datos');
        if (fotoAgenda != null) {
            cargar('Subiendo foto...');
            const data = new FormData();
            data.append('file', fotoAgenda);
            axios.post(process.env.REACT_APP_DEFAULT_URL+ "/api/files/upload/s3", data, {}).then(res => {
                let img = null;
                if (res.data.resultado.Location != null) {
                    img = res.data.resultado.Location;
                }
                cargar('Sincronizando base de datos...');
                const info = {
                    inicio: inicioAgenda,
                    fin: finAgenda,
                    nombre: nombreAgenda,
                    puesto: puestoAgenda,
                    institucion: institucionAgenda,
                    pais: paisAgenda,
                    concepto: conceptoAgenda,
                    foto: img,
                    UniqueID: props.agenda.UniqueID
                };
                const requestOptions = {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(info)
                };
                fetch(process.env.REACT_APP_DEFAULT_URL + '/api/agenda-jornada/update', requestOptions)
                    .then((res) => {
                        return res.json();
                    })
                    .then(data => {
                        if (data.codigo == 200) {
                            operacionExito('Programación ingresada exitosamente');
                        } else {
                            operacionError('Error al grabar registro en db');
                        }
                    });

            });
        } else {
            cargar('Sincronizando base de datos...');
            const info = {
                inicio: inicioAgenda,
                fin: finAgenda,
                nombre: nombreAgenda,
                puesto: puestoAgenda,
                institucion: institucionAgenda,
                pais: paisAgenda,
                concepto: conceptoAgenda,
                foto: props.agenda.foto,
                UniqueID: props.agenda.UniqueID

            };
            const requestOptions = {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(info)
            };
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/agenda-jornada/update', requestOptions)
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    if (data.codigo == 200) {
                        operacionExito('Programación ingresada exitosamente');
                    } else {
                        operacionError('Error al grabar registro en db');
                    }
                });

        }
    }
    return (
        <Row style={{ padding: '2%' }}>
            <Container fluid>
                <Row style={{ display: portada }}>
                    <Container fluid>
                        <Row >
                            <h3>Editar información de programación en jornada</h3>
                        </Row>
                        <Row>
                            <Form onSubmit={submitEditAgenda}>
                                <Container>
                                    <Row>
                                        <Col xs={12} md={12} lg={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Fecha y hora de inicio</Form.Label>
                                                <Form.Control required type="datetime-local" value={inicioAgenda} name="inicio" placeholder="Fecha y hora de inicio" onChange={onChange} />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={12} lg={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Fecha y hora de finalización</Form.Label>
                                                <Form.Control required type="datetime-local" value={finAgenda} name="fin" placeholder="Fecha y hora de finalización" onChange={onChange} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={12} lg={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Nombre de la persona que lidera</Form.Label>
                                                <Form.Control required type="text" value={nombreAgenda} name="nombre" placeholder="Nombre de la persona que lidera" onChange={onChange} />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={12} lg={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Puesto de la persona que lidera</Form.Label>
                                                <Form.Control required type="text" value={puestoAgenda} name="puesto" placeholder="Puesto de la persona que lidera" onChange={onChange} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={12} lg={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Institución de la persona que lidera</Form.Label>
                                                <Form.Control required type="text" value={institucionAgenda} name="institucion" placeholder="Institución de la persona que lidera" onChange={onChange} />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={12} lg={6}>
                                            <Form.Group className="mb-3" controlId={"pais"}>
                                                <Form.Label>País de la persona que lidera</Form.Label>
                                                <Form.Control as="select" required aria-label="Pais" name="pais" onChange={onChange} value={paisAgenda}>
                                                    <option value="" key="0">Seleccione el país de la persona que lidera</option>
                                                    {paises.map(pais => <option key={pais.id} value={pais.nombre}>{pais.nombre}</option>)}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={12} lg={6}>
                                            <Form.Group className="mb-3" controlId={"concepto"}>
                                                <Form.Label>Concepto del espacio en programación</Form.Label>
                                                <Form.Control required type="text" value={conceptoAgenda} name="concepto" placeholder="Concepto del espacio en programación" onChange={onChange} />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={12} lg={6}>
                                            <Form.Group className="mb-3" controlId={"foto"}>
                                                <Form.Label>Foto de la persona que lidera (opcional)</Form.Label>
                                                <Form.Control type="file" accept="image/*" name="foto" placeholder="Foto de la persona que lidera" onChange={onChange} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row style={{ padding: '2%' }}>
                                        <Col xs={12} md={12} lg={8}>
                                            <h3>Vista previa de imagen almacenada actualmente</h3>
                                        </Col>
                                        <Col xs={12} md={12} lg={2}>
                                            <img src={props.agenda.foto} style={{ width: '100%', objectFit: 'contain' }} alt='foto' />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Button type='submit' variant='outline-dark'>Guardar cambios</Button>
                                    </Row>
                                </Container>
                            </Form>
                        </Row>
                        <Row>
                            <Col xs={12} md={12} lg={4} style={{ padding: '2%' }}>
                                <Row>
                                    <Button variant='outline-danger' onClick={confirmar}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3" viewBox="0 0 16 16">
                                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                    </svg>&nbsp;&nbsp;&nbsp;&nbsp;Eliminar</Button>
                                </Row>
                            </Col>
                            <Col xs={12} md={12} lg={4} style={{ padding: '2%' }}>
                                <Row>
                                    <Button variant='outline-primary' onClick={() => { setContador(contador + 1); }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                                        <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                                    </svg>&nbsp;&nbsp;&nbsp;&nbsp;Refrescar información</Button>
                                </Row>
                            </Col>
                            <Col xs={12} md={12} lg={4} style={{ padding: '2%' }}>
                                <Row>
                                    <Button variant='outline-secondary' href={process.env.REACT_APP_SELF_URL + '/control-tiempo/' + props.agenda.UniqueID} target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-stopwatch" viewBox="0 0 16 16">
                                        <path d="M8.5 5.6a.5.5 0 1 0-1 0v2.9h-3a.5.5 0 0 0 0 1H8a.5.5 0 0 0 .5-.5V5.6z" />
                                        <path d="M6.5 1A.5.5 0 0 1 7 .5h2a.5.5 0 0 1 0 1v.57c1.36.196 2.594.78 3.584 1.64a.715.715 0 0 1 .012-.013l.354-.354-.354-.353a.5.5 0 0 1 .707-.708l1.414 1.415a.5.5 0 1 1-.707.707l-.353-.354-.354.354a.512.512 0 0 1-.013.012A7 7 0 1 1 7 2.071V1.5a.5.5 0 0 1-.5-.5zM8 3a6 6 0 1 0 .001 12A6 6 0 0 0 8 3z" />
                                    </svg>&nbsp;&nbsp;&nbsp;&nbsp;Control de tiempo</Button>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </Row>
                <Row style={{ display: exito }}>
                    <Alert variant='success'>
                        <Alert.Heading>
                            Operación realizada con éxito
                        </Alert.Heading>
                        <p>
                            {mensaje}
                        </p>
                        <hr />
                        <div className="d-flex justify-content-end">
                            <Button variant='outline-success' onClick={() => { goBack(); props.refresh(); }}>
                                Ok
                            </Button>
                        </div>
                    </Alert>
                </Row>
                <Row style={{ display: error }}>
                    <Alert variant='danger'>
                        <Alert.Heading>
                            Ocurrió un error al ingresar la propuesta
                        </Alert.Heading>
                        <p>
                            {mensaje}
                        </p>
                        <hr />
                        <div className="d-flex justify-content-end">
                            <Button variant='outline-danger' onClick={goBack}>
                                Ok
                            </Button>
                        </div>
                    </Alert>
                </Row>
                <Row style={{ display: cargando }}>
                    <Alert variant='dark'>
                        <Alert.Heading>
                            <Spinner animation="border" role="status">
                            </Spinner>&nbsp;&nbsp;&nbsp;
                            Ingresando información
                        </Alert.Heading>
                        <p>
                            {mensaje}
                        </p>
                        <hr />
                        <p>
                            Por favor espere...
                        </p>
                    </Alert>
                </Row>
                <Row style={{ display: confirmacion }}>
                    <Alert variant='warning'>
                        <Alert.Heading>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
                                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                            </svg>&nbsp;&nbsp;&nbsp;Se necesita confirmación
                        </Alert.Heading>
                        <p>
                            ¿Está segur@ de eliminar la programación seleccionada?
                            <br />

                        </p>
                        <hr />
                        <div className="d-flex justify-content-end">
                            <Button variant='outline-danger' onClick={deleteSelf}>
                                si
                            </Button>&nbsp;&nbsp;&nbsp;
                            <Button variant='outline-dark' onClick={goBack}>
                                no
                            </Button>
                        </div>
                    </Alert>
                </Row>
            </Container >
        </Row >
    )
}

export default DetalleAgenda