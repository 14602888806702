import React, { Component } from 'react';
import { Row, Modal, Col, Container, ListGroup, Popover, OverlayTrigger } from "react-bootstrap";
import { ModalArchivo } from '../../Inscripcion/ModalArchivo'
//import { DOWNLOAD_URL }} from '../../../../api/url';
const popover = (
    <Popover id="popover-basic">
        <Popover.Header as="h3">¡Listo!</Popover.Header>
        <Popover.Body>
            Enlace copiado exitosamente.
        </Popover.Body>
    </Popover>
);
export class ModalInfo extends Component {
    state = {

        archivos: [],
        modalArchivo: false
    };
    entering = () => {
        console.log(this.props.detalle);
        this.setState({});
        this.setState({
            archivos: this.props.detalle
        });

    }
    hideModalArcihvo = () => {
        this.props.refrescar();
        this.setState({ modalArchivo: false });
    }
    render() {
        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
                onEnter={this.entering}
            >
                <Modal.Header closeButton style={{ backgroundColor: "#b5bad0" }}>
                    <Modal.Title id="contained-modal-title-vcenter" className="font-bold">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-info-lg" viewBox="0 0 16 16">
                            <path d="m9.708 6.075-3.024.379-.108.502.595.108c.387.093.464.232.38.619l-.975 4.577c-.255 1.183.14 1.74 1.067 1.74.72 0 1.554-.332 1.933-.789l.116-.549c-.263.232-.65.325-.905.325-.363 0-.494-.255-.402-.704l1.323-6.208Zm.091-2.755a1.32 1.32 0 1 1-2.64 0 1.32 1.32 0 0 1 2.64 0Z" />
                        </svg>
                        &nbsp;&nbsp;&nbsp;Información De Inscripción No Procesada
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: "#b5bad0" }} className="cuerpo-modal-nuevo-cliente">
                    <Container>
                        <Row>
                            <Col>
                                <div style={{ cursor: 'pointer' }} data-toggle="tooltip" data-placement="top" title="Subir nueva carga masiva" onClick={() => this.setState({ modalArchivo: true })}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                                    </svg>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Container>
                                <Row style={{ textAlign: 'center' }}><h2>Archivos De Carga Masiva</h2></Row>
                                {this.state.archivos.map((detalle) =>
                                    <Row style={{ padding: '10px' }}>
                                        <ListGroup as="ol">
                                            <ListGroup.Item
                                                as="li"
                                                className="d-flex justify-content-between align-items-start"
                                            >
                                                <div className="ms-2 me-auto">
                                                    <div className="fw-bold">{detalle.Nombre}</div>
                                                    <p>
                                                        <strong>Fecha de subida: </strong>{detalle.fecha}
                                                        <br />
                                                        <strong>Archivo: </strong>{detalle.Path}
                                                        <br />
                                                        <strong>Estado: </strong>{detalle.Estado}
                                                        <br />
                                                        <strong>Retroalimentación: </strong>{detalle.ret}
                                                    </p>
                                                </div>
                                                <a href={process.env.REACT_APP_DOWNLOAD_URL + detalle.Path} target="_blank" data-toggle="tooltip" data-placement="top" title="Descargar Archivo" style={{ cursor: 'pointer', color: 'black' }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-cloud-arrow-down-fill" viewBox="0 0 16 16">
                                                        <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 6.854-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5a.5.5 0 0 1 1 0v3.793l1.146-1.147a.5.5 0 0 1 .708.708z" />
                                                    </svg>
                                                    <i className="bi bi-cloud-arrow-down-fill"></i>
                                                </a>
                                                <OverlayTrigger trigger="click" placement="left" overlay={popover} rootClose>
                                                    <div data-toggle="tooltip" data-placement="top" title="Copiar Enlace De Archivo" style={{ cursor: 'pointer', paddingLeft: '10px' }} onClick={() => navigator.clipboard.writeText(process.env.REACT_APP_DOWNLOAD_URL + detalle.Path)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard-check" viewBox="0 0 16 16">
                                                            <path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                                                            <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                                                            <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                                                        </svg>
                                                    </div>
                                                </OverlayTrigger>
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </Row>
                                )}
                            </Container>
                        </Row>
                    </Container>
                </Modal.Body>
                <ModalArchivo
                    show={this.state.modalArchivo}
                    onHide={this.hideModalArcihvo}
                    evento_Unique_ID={this.props.evento}
                    llave={this.props.inscripcion}
                />
            </Modal>
        );
    }
}