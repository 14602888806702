import React, {  useState, useEffect } from 'react';
import { Modal, Row,  Button, Col, Form, Container } from "react-bootstrap";
import { ErrorModal } from '../../../Utils/ErrorModal/ErrorModal';
import { SuccessModal } from '../../../Utils/SuccessModal/SuccessModal';
//import DEFAULT_URL from '../../../../api/url';
import { ModalCargando } from '../../../Utils/Modal-Cargando/ModalCargando';
import './ModalMaterial.css'

const ModalEditarMaterial = (props) => {
    const [nombre, setNombre] = useState('');
    const [promocional, setPromocional] = useState('');
    const [contador, setContador] = useState(0);
    const [modalSucc, setModalSucc] = useState(false);
    const [modalError, setModalError] = useState(false);
    const [modalCargando, setModalCargando] = useState(false);
    const [mensaje, setMensaje] = useState(false);
    const [mensajeCargando, setMensajeCargando] = useState('');
    const [segundos, setSegundos] = useState(0);
    useEffect(() => {
    }, [contador]);
    const ocultar = () => {
        setModalSucc(false);
        setModalError(false);
        props.refrescar();
        props.onHide();
    }
    const onChange = (evt) => {
        if (evt.target.name == "nombre") {
            setNombre(evt.target.value);
        } else if (evt.target.name == "segundos") {
            setSegundos(evt.target.value);
        } else {
            setPromocional(evt.target.value);
        }
        setContador(contador + 1);
    }
    const ocultarModalCargando = () => {
        setModalCargando(false);
    }
    const entering = () => {
        setNombre('');
        setPromocional('');
        setNombre(props.nombre);
        if (props.permiso == 4) {
            setPromocional(props.promocional);
        } else if (props.permiso == 6) {
            setSegundos(props.segundos);
        }
        setContador(contador + 1);
    }
    const submit = (evt) => {
        evt.preventDefault();
        setMensajeCargando('Se está editando el material, por favor espere.');
        setModalCargando(true);
        if (props.permiso == 4) {
            const info = { nombre: nombre, UniqueID: props.UniqueID, promocional: promocional, segundos: null };
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(info)
            };
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/update/material', requestOptions)
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    if (data.codigo == 200) {
                        setModalCargando(false);
                        setMensaje('Material editado exitosamente');
                        setContador(contador + 1);
                        setModalSucc(true);
                    } else {
                        setMensaje(data.respuesta);
                        setModalError(true);
                    }
                    Array.from(document.querySelectorAll("input")).forEach(
                        input => (input.value = "")
                    );
                });
            setNombre('');
            setPromocional('');
        } else if (props.permiso == 6) {
            const info = { nombre: nombre, UniqueID: props.UniqueID, promocional: null, segundos: segundos };
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(info)
            };
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/update/material', requestOptions)
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    if (data.codigo == 200) {
                        setModalCargando(false);
                        setMensaje('Material editado exitosamente');
                        setContador(contador + 1);
                        setModalSucc(true);
                    } else {
                        setMensaje(data.respuesta);
                        setModalError(true);
                    }
                    Array.from(document.querySelectorAll("input")).forEach(
                        input => (input.value = "")
                    );
                });
            setNombre('');
            setPromocional('');
        } else {
            const info = { nombre: nombre, UniqueID: props.UniqueID, promocional: null, segundos: null };
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(info)
            };
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/update/material', requestOptions)
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    if (data.codigo == 200) {
                        setModalCargando(false);
                        setMensaje('Material editado exitosamente');
                        setContador(contador + 1);
                        setModalSucc(true);
                    } else {
                        setMensaje(data.respuesta);
                        setModalError(true);
                    }
                    Array.from(document.querySelectorAll("input")).forEach(
                        input => (input.value = "")
                    );
                });
            setNombre('');
            setPromocional('');
        }
    }
    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
            onEnter={entering}
            onExit={() => {
                setNombre('');
                setPromocional('');
                setContador(contador + 1);
            }}
        >
            <Modal.Header closeButton style={{ backgroundColor: "#617785", borderColor: 'transparent', color: 'black' }}>
                <Modal.Title id="contained-modal-title-vcenter" className="font-bold">
                    Editar Material
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: "#617785", borderColor: 'transparent', color: 'black' }} id="body-modal-edit-evento">
                <Form onSubmit={submit}>
                    <Container>
                        <Row>
                            <Col sm={12} md={12} lg={12}>
                                <Form.Group className="mb-3" controlId="material">
                                    <Form.Label>Nombre del material</Form.Label>
                                    <Form.Control required type="text" name="nombre" placeholder="Ingrese el nombre del material" onChange={onChange} value={nombre} />
                                </Form.Group>
                            </Col>
                        </Row>
                        {
                            props.permiso == 4 &&
                            <Row>
                                <Col sm={12} md={12} lg={12}>
                                    <Form.Group className="mb-3" controlId="material">
                                        <Form.Label>Imagen promocional para APP</Form.Label>
                                        <Form.Control required type="text" name="promocional" placeholder="Ingrese el url de la imagen promocional" onChange={onChange} value={promocional} />
                                    </Form.Group>
                                </Col>
                            </Row>
                        }
                        {
                            props.permiso == 6 &&
                            <Row>
                                <Form.Group className="mb-3" controlId="promocional">
                                    <Form.Label>Duración en espacio publicitario de APP</Form.Label>
                                    <Form.Control required readonly type="number" name="segundos" placeholder="tiempo en segundos" onChange={onChange} value={segundos} />
                                </Form.Group>
                            </Row>
                        }
                        <Row>
                            <Col>
                                <Button variant="light" className="font-bold" type="submit">Guardar</Button>
                            </Col>
                        </Row>
                    </Container>
                </Form>
            </Modal.Body>
            <SuccessModal
                color={"#b5bad0"}
                show={modalSucc}
                onHide={ocultar}
                mensaje={mensaje}
                consideracion={''}
                enlace={''}
                llevaenlace={false}
            />
            <ErrorModal
                color={"#b5bad0"}
                show={modalError}
                onHide={ocultar}
                mensaje={mensaje}
                consideracion={''}
            />
            <ModalCargando
                color={"#b5bad0"}
                show={modalCargando}
                onHide={ocultarModalCargando}
                mensaje={mensajeCargando}
            />
        </Modal>
    )
}

export default ModalEditarMaterial