import React, { useState, useEffect } from 'react'
import { Modal, Container, Row, Col, Form, Button, Alert, Spinner } from 'react-bootstrap';
//import DEFAULT_URL from '../../../../api/url';
const ModalInfo = (props) => {
    const [contador, setContador] = useState(0);
    const [nombres, setNombres] = useState('');
    const [apellidos, setApellidos] = useState('');
    const [email, setEmail] = useState('');
    const [institucion, setInstitucion] = useState('');
    const [telefono, setTelefono] = useState('');
    const [exito, setExito] = useState('none');
    const [formulario, setFormulario] = useState('block');
    const [error, setError] = useState('none');
    const [cargando, setCargando] = useState('none');
    const [mensaje, setMensaje] = useState('');
    useEffect(() => {

    }, [contador]);
    const entering = () => {
        setNombres('');
        setApellidos('');
        setEmail('');
        setInstitucion('');
        setTelefono('');
        setContador(contador + 1);
    }
    const onChange = (evt) => {
        if (evt.target.name == "nombres") {
            setNombres(evt.target.value);
        } else if (evt.target.name == "apellidos") {
            setApellidos(evt.target.value);
        } else if (evt.target.name == "telefono") {
            setTelefono(evt.target.value);
        } else if (evt.target.name == "email") {
            setEmail(evt.target.value);
        } else if (evt.target.name == "institucion") {
            setInstitucion(evt.target.value);
        }
        setContador(contador + 1);
    }
    const cargar = (mensaje) => {
        setExito('none');
        setFormulario('none');
        setError('none');
        setCargando('block');
        setMensaje(mensaje);
        setContador(contador + 1);
    }
    const operacionExito = (mensaje) => {
        setExito('block');
        setFormulario('none');
        setError('none');
        setCargando('none');
        setMensaje(mensaje);        
        setContador(contador + 1);
    }
    const operacionError = (mensaje) => {
        setExito('none');
        setFormulario('none');
        setError('block');
        setCargando('none');
        setMensaje(mensaje);
        setContador(contador + 1);
    }
    const onSubmit = (evt) => {
        evt.preventDefault();
        cargar('Procesando informacion')
        const info = {
            nombres: nombres,
            apellidos: apellidos,
            telefono: telefono,
            email: email,
            institucion: institucion,
            evento: props.evento
        };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/email/info-request', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                if (data.codigo == 200) {
                    operacionExito('Información registrada exitosamente');
                } else {
                    operacionError('Error al grabar registro en db');
                }
            });

    }
    const goBack = () => {
        setNombres('');
        setApellidos('');
        setEmail('');
        setInstitucion('');
        setTelefono('');
        setExito('none');
        setFormulario('block');
        setError('none');
        setCargando('none');
        Array.from(document.querySelectorAll("input")).forEach(
            input => (input.value = "")
        );
        setContador(contador + 1);
    }
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            onEnter={entering}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-info-square-fill" viewBox="0 0 16 16">
                        <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                    </svg>&nbsp;&nbsp;&nbsp;Solicitar información
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row style={{ display: formulario }}>
                        <p>
                            Por favor, llene el siguiente formulario, un asesor se comunicará con usted para dar seguimiento.
                        </p>
                        <Form onSubmit={onSubmit}>
                            <Container>
                                <Row>
                                    <Col xs={12} md={12} lg={6}>
                                        <Form.Group className="mb-3" controlId="nombres">
                                            <Form.Label>Nombres</Form.Label>
                                            <Form.Control required type="text" placeholder="Ingrese sus nombres" name="nombres" value={nombres} onChange={onChange} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={12} lg={6}>
                                        <Form.Group className="mb-3" controlId="apellidos">
                                            <Form.Label>Apellidos</Form.Label>
                                            <Form.Control required type="text" placeholder="Ingrese sus apellidos" name="apellidos" value={apellidos} onChange={onChange} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={12} lg={6}>
                                        <Form.Group className="mb-3" controlId="email">
                                            <Form.Label>Correo electrónico</Form.Label>
                                            <Form.Control required type="email" placeholder="Ingrese su correo electrónico" name="email" value={email} onChange={onChange} />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={12} lg={6}>
                                        <Form.Group className="mb-3" controlId="telefono">
                                            <Form.Label>Teléfono</Form.Label>
                                            <Form.Control required type="text" placeholder="Ingrese su número de teléfono" name="telefono" value={telefono} onChange={onChange} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Form.Group className="mb-3" controlId="institucion">
                                        <Form.Label>Institución</Form.Label>
                                        <Form.Control required type="text" placeholder="Ingrese la institución donde labora" name="institucion" value={institucion} onChange={onChange} />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Button type="submit" variant='outline-secondary'>Solicitar información</Button>
                                </Row>
                            </Container>
                        </Form>
                    </Row>
                    <Row style={{ display: exito }}>
                        <Alert variant='success'>
                            <Alert.Heading>
                                Operación realizada con éxito
                            </Alert.Heading>
                            <p>
                                {mensaje}
                            </p>
                            <hr />
                            <div className="d-flex justify-content-end">
                                <Button variant='outline-success' onClick={goBack}>
                                    Ok
                                </Button>
                            </div>
                        </Alert>
                    </Row>
                    <Row style={{ display: error }}>
                        <Alert variant='danger'>
                            <Alert.Heading>
                                Ocurrió un error al ingresar la propuesta
                            </Alert.Heading>
                            <p>
                                {mensaje}
                            </p>
                            <hr />
                            <div className="d-flex justify-content-end">
                                <Button variant='outline-danger' onClick={goBack}>
                                    Ok
                                </Button>
                            </div>
                        </Alert>
                    </Row>
                    <Row style={{ display: cargando }}>
                        <Alert variant='dark'>
                            <Alert.Heading>
                                <Spinner animation="border" role="status">
                                </Spinner>&nbsp;&nbsp;&nbsp;
                                Ingresando información
                            </Alert.Heading>
                            <p>
                                {mensaje}
                            </p>
                            <hr />
                            <p>
                                Por favor espere...
                            </p>
                        </Alert>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>)
}

export default ModalInfo