import React, { useEffect, useState } from 'react';
import { Row, Spinner, Col, Container, Form, Alert, Button } from "react-bootstrap";
import axios from 'axios';
//import DEFAULT_URL from '../../../../../api/url';
const CargaMasiva = (props) => {
    const [contador, setContador] = useState(0);
    const [selectedFile, setSelectedFile] = useState(null);
    const [formulario, setFormulario] = useState('block');
    const [cargando, setCargando] = useState('none');
    const [exito, setExito] = useState('none');
    const [error, setError] = useState('none');
    const [mensaje, setMensaje] = useState('');
    useEffect(() => {

    }, [contador]);
    const atras = () => {
        setFormulario('block');
        setCargando('none');
        setExito('none');
        setError('none');
        setContador(contador + 1);
    }
    const onChange = (evt) => {
        switch (evt.target.name) {
            case "file":
                setSelectedFile(evt.target.files[0]);
                break;
        }
        setContador(contador + 1);
    }
    const cargar = () => {
        setFormulario('none');
        setCargando('block');
        setExito('none');
        setError('none');
        setMensaje('Procesando información...')
        setContador(contador + 1);
    }
    const operacionExito = (log) => {
        setFormulario('none');
        setCargando('none');
        setExito('block');
        setError('none');
        setMensaje(log)
        setContador(contador + 1);
    }
    const operacionError = (log) => {
        setFormulario('none');
        setCargando('none');
        setExito('none');
        setError('block');
        setMensaje(log)
        setContador(contador + 1);
    }
    const submit = () => {
        if (selectedFile != null) {
            cargar();
            const data = new FormData();
            data.append('file', selectedFile);
            axios.post(process.env.REACT_APP_DEFAULT_URL+ "/api/files/upload/inscripcion/memory", data, {}).then(res => {
                console.log(res);
                if (res.data.codigo == 200) {
                    operacionExito(`Archivo procesado exitosamente, se han cargado ${res.data.data.data.length} participantes`);
                    props.cargarParticipantes(res.data.data.data);
                } else {
                    operacionError(res.data.ret);
                }
            });
            Array.from(document.querySelectorAll("input")).forEach(
                input => (input.value = "")
            );
            setSelectedFile(null);
            setContador(contador + 1);
        } else {
            operacionError('Por favor ingresa un archivo para procesar');
        }
    }
    return (
        <Container fluid>
            <Row style={{ display: formulario }}>
                <h2 style={{ color: 'black' }}>Subir participantes por medio de carga masiva</h2>
                <Col xs={12} md={12} lg={12} style={{ marginTop: '45px', marginBottom: '45px' }}>
                    <Button variant='outline-primary' href="https://test-escuelabancaria.s3.us-east-2.amazonaws.com/Medios+General/MUESTRA+FORMULARIO+DE+INSCRIPCION.xlsx" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                    </svg>&nbsp;&nbsp;&nbsp;Descargar plantilla de excel para carga masiva</Button>
                </Col>
                <Col xs={12} md={12} lg={12}>
                    <Form.Group controlId="formFileMultiple" className="mb-3" style={{ color: 'black' }}>
                        <Form.Label>Archivo con información de participantes</Form.Label>
                        <Form.Control type="file" onChange={onChange} name="file" />
                    </Form.Group>
                    <Button onClick={submit} variant="outline-success"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-plus-fill" viewBox="0 0 16 16">
                        <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                        <path fillRule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z" />
                    </svg>&nbsp;&nbsp;&nbsp;&nbsp;Subir participantes</Button>
                </Col>
            </Row>
            <Row style={{ display: cargando }}>
                <Alert variant="secondary">
                    <Alert.Heading><Spinner animation="grow" />&nbsp;&nbsp;&nbsp;Procesando solicitud</Alert.Heading>
                    <p>
                        {mensaje}
                    </p>
                    <hr />
                    <p className="mb-0">
                        Por favor espere...
                    </p>
                </Alert>
            </Row>
            <Row style={{ display: exito }}>
                <Alert variant="success" onClose={atras} dismissible>
                    <Alert.Heading>Operación realizada con éxito</Alert.Heading>
                    <p>
                        {mensaje}
                    </p>
                </Alert>
            </Row>
            <Row style={{ display: error }}>
                <Alert variant="danger" onClose={atras} dismissible>
                    <Alert.Heading>Error</Alert.Heading>
                    <p>
                        {mensaje}
                    </p>
                </Alert>
            </Row>
        </Container>
    )
}

export default CargaMasiva