import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Button, Col } from "react-bootstrap";
const ReadGafetesFisicos = () => {
  const { hash } = useParams();
  const [contador] = useState(0);
  const [evento, setEvento] = useState({});
  const [gafetes, setGafetes] = useState([]);
  useEffect(() => {
    async function fetchData() {
      fetch(
        process.env.REACT_APP_DEFAULT_URL + "/api/read/evento/landing/" + hash,
        {
          cache: "no-cache",
          credentials: "include",
        }
      )
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setEvento(data.rows[0]);
          fetch(
            process.env.REACT_APP_DEFAULT_URL +
              "/api/gafetes-fisicos/read/" +
              data.rows[0].UniqueID,
            {
              cache: "no-cache",
              credentials: "include",
            }
          )
            .then((res) => {
              return res.json();
            })
            .then((data) => {
              setGafetes(data.rows);
            });
        });
    }
    fetchData();
  }, [contador]);
  return (
    <Container fluid>
      <Row style={{ padding: "5%" }}>
        <Container>
          <Row
            style={{
              padding: "5%",
              borderBottom: "5px solid #1e3d52",
              borderLeft: "5px solid #1e3d52",
              backgroundColor: "rgb(230,230,230)",
            }}
          >
            <Col xs={12} md={6} lg={4}>
              <img
                loading="lazy"
                src={evento.flier}
                style={{ width: "100%" }}
              />
            </Col>
            <Col xs={12} md={6} lg={8} style={{ textAlign: "left" }}>
              <h1>GAFETES FÍSICOS</h1>
              {evento != null && (
                <p>
                  <br />
                  <strong>EVENTO: </strong>
                  {evento.Nombre}
                  <br />
                  <strong>FECHA: </strong>
                  {evento.Fecha_Inicio}
                  <br />
                  <strong>GAFETES REGISTRADOS: </strong>
                  {gafetes.length}
                </p>
              )}
            </Col>
          </Row>
        </Container>
      </Row>
      <Row>
        <h2>Detalle de gafetes registrados</h2>
      </Row>
      <Row style={{ padding: "2%" }}>
        {gafetes.map((actual, index) => (
          <Col key={index} xs={12} md={6} lg={3} style={{ padding: "1%" }}>
            <Container fluid>
              <Row>
                <Button
                  variant="outline-primary"
                  href={
                    process.env.REACT_APP_SELF_URL +
                    "/gafetes-fisicos/" +
                    hash +
                    "/view/" +
                    actual.UniqueID
                  }
                >
                  {actual.descripcion}
                </Button>
              </Row>
            </Container>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default ReadGafetesFisicos;
