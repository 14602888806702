import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Formulario from "../../helpers/form";
import "./style.css";
import ActionButton from "../../helpers/Action";
const helpers = require("../../helpers/helpers");
const FormInHouse = (props) => {
  const [idAsesor, setIdAsesor] = useState("");
  const [contador] = useState(0);
  const [correo, setCorreo] = useState("");
  const [docentes, setDocentes] = useState([]);
  const [formConfig, setFormConfig] = useState({});
  useEffect(() => {
    async function fetchData() {
      helpers
        .getData("/api/auth/userinfo")
        .then((usr_info) => {
          setCorreo(usr_info.data.email);
          setIdAsesor(usr_info.data.UniqueID);
          helpers
            .getData("/api/temas/read")
            .then((temas_info) => {
              helpers
                .getData("/api/facilitadores/read")
                .then((facilitadores_info) => {
                  setDocentes(facilitadores_info.data.rows);
                  setFormConfig({
                    title: "Registro de propuesta INHOUSE",
                    submitTitle: "Registrar institución",
                    customSubmit: true,
                    formConfig: [
                      {
                        name: "grado_academico",
                        type: 3,
                        options: [
                          { label: "Licenciado", value: "Licenciado" },
                          { label: "Licenciada", value: "Licenciada" },
                          { label: "Doctor", value: "Doctor" },
                          { label: "Doctora", value: "Doctora" },
                          { label: "Ingeniero", value: "Ingeniero" },
                          { label: "Ingeniera", value: "Ingeniera" },
                          { label: "Arquitecto", value: "Arquitecto" },
                          { label: "Arquitecta", value: "Arquitecta" },
                        ],
                        required: true,
                        title: "Grado académico del solicitante",
                        placeholder: "Selecciona un grado académico",
                        value: "",
                      },
                      {
                        name: "nombre",
                        type: 1,
                        required: true,
                        title: "Nombre de solicitante",
                        placeholder: "Ingrese el nombre de solicitante",
                        value: "",
                      },
                      {
                        name: "puesto",
                        type: 1,
                        required: true,
                        title: "Puesto del solicitante",
                        placeholder: "Ingrese el puesto del solicitante",
                        value: "",
                      },
                      {
                        name: "correo",
                        type: 1,
                        required: true,
                        title: "Correo electrónico del solicitante",
                        placeholder:
                          "Ingrese el correo electrónico del solicitante",
                        value: "",
                      },
                      {
                        name: "telefono",
                        type: 1,
                        required: true,
                        title: "Teléfono del solicitante",
                        placeholder:
                          "Ingrese número de teléfono del solicitante",
                        value: "",
                      },
                      {
                        name: "institucion",
                        type: 1,
                        required: true,
                        title: "Institución solicitante",
                        placeholder: "Ingrese la institución solicitante",
                        value: "",
                      },
                      {
                        name: "archivo",
                        type: 6,
                        required: true,
                        title: "PDF a adjuntar en propuesta",
                        placeholder: "Ingrese lo solicitado",
                        value: "",
                      },
                      {
                        name: "horas",
                        type: 7,
                        required: true,
                        title: "Horas estimadas del evento",
                        placeholder: "Ingrese las horas estimadas del evento",
                        step: 0.1,
                        value: "",
                      },
                      {
                        name: "temaPropuesto",
                        type: 1,
                        required: true,
                        title: "Tema propuesto",
                        placeholder: "Ingrese el tema propuesto",
                        value: "",
                      },
                      {
                        name: "perfil",
                        type: 1,
                        required: true,
                        title: "Perfil de participante",
                        placeholder: "Ingrese el perfil de los participantes",
                        value: "",
                      },
                      {
                        name: "participantes",
                        type: 7,
                        required: true,
                        title: "No. de participantes",
                        step: 0,
                        placeholder: "Ingrese el número de participantes",
                        value: "",
                      },
                      {
                        name: "horario",
                        type: 1,
                        required: true,
                        title: "Horario propuesto",
                        placeholder: "Ingrese el horario del evento",
                        value: "",
                      },
                      {
                        name: "fechaPropuesta",
                        type: 5,
                        required: true,
                        title: "Fecha propuesta",
                        placeholder: "Ingrese la fecha del evento",
                        value: "",
                      },

                      {
                        name: "servicio",
                        type: 1,
                        required: true,
                        title: "Servicio",
                        placeholder: "Ingrese el servicio a brindar",
                        value: "",
                      },
                      {
                        name: "honorarios",
                        type: 7,
                        required: true,
                        title: "Honorarios docentes",
                        step: 0.01,
                        placeholder: "Ingrese los honorarios del los docentes",
                        value: "",
                      },
                      {
                        name: "lugar",
                        type: 8,
                        required: true,
                        title: "Lugar a ejecutar evento",
                        placeholder:
                          "Ingrese el lugar donde se ejecutará el evento",
                        value: "",
                      },

                      {
                        name: "descripcion_paquete",
                        type: 8,
                        required: true,
                        title:
                          "Descripción general de lo que incluye el servicio",
                        placeholder: "Ingrese lo solicitado",
                        value: "",
                      },
                      {
                        name: "descripcion_general",
                        type: 8,
                        required: true,
                        title: "Descripción general del curso",
                        placeholder: "Ingrese lo solicitado",
                        value: "",
                      },
                      {
                        name: "comentarios",
                        type: 8,
                        required: true,
                        title: "Comentarios adicionales",
                        placeholder: "Ingrese comentarios adicionales",
                        value: "",
                      },
                      {
                        name: "objetivos",
                        type: 10,
                        title: "Objetivos del programa",
                        subtitle:
                          "Acá puede redactar y adjuntar los objetivos del programa de la propuesta de evento INHOUSE",
                        value: [],
                        field: {
                          name: "objetivo",
                          type: 8,
                          required: true,
                          title: "Objetivo del programa",
                          placeholder: "Ingresa un objetivo del programa",
                          value: "",
                        },
                      },
                      {
                        name: "metodologia",
                        type: 10,
                        title: "Metodología",
                        subtitle:
                          "Acá puede redactar y adjuntar la metodología activo/participativa",
                        value: [],
                        field: {
                          name: "metodologia",
                          type: 8,
                          required: true,
                          title: "Metodología",
                          placeholder: "Ingresa una metodología",
                          value: "",
                        },
                      },
                      {
                        name: "facilitadores",
                        type: 10,
                        title: "Facilitadores EBG",
                        subtitle:
                          "Acá puede seleccionar los facilitadores EBG quienes impartirán el evento",
                        value: [],
                        field: {
                          name: "facilitador",
                          type: 3,
                          options: facilitadores_info.data.rows.map(
                            (actual) => {
                              return {
                                label: actual.Nombres + " " + actual.Apellidos,
                                value: actual.UniqueID,
                              };
                            }
                          ),
                          required: true,
                          title: "Facilitador EBG",
                          placeholder: "Selecciona un facilitador de EBG",
                          value: "",
                        },
                      },
                      {
                        name: "contenido",
                        type: 11,
                        title: "Contenidos a desarrollar",
                        subtitle:
                          "Acá puede ingresar los contenidos a desarrollar en el evento por unidades",
                        item_name: "contenido",
                        head: [
                          {
                            name: "nombre",
                            type: 1,
                            required: true,
                            title: "Nombre de unidad",
                            placeholder: "Ingrese el nombre de la unidad",
                            value: "",
                          },
                          {
                            name: "duracion",
                            type: 7,
                            step: 0,
                            required: true,
                            title: "Duracion de la unidad en horas",
                            placeholder:
                              "Ingrese la duración de la unidad en horas",
                            value: "",
                          },
                        ],
                        fields: [
                          {
                            name: "tema",
                            type: 3,
                            options: temas_info.data.salida.map((actual) => {
                              return {
                                label: actual.nombre,
                                value: actual.UniqueID,
                              };
                            }),
                            required: true,
                            title: "Tema de oferta formativa",
                            placeholder:
                              "Selecciona un tema de la oferta formativa de EBG",
                            value: "",
                          },
                        ],
                        value: [],
                      },
                    ],
                  });
                })
                .catch((error) => {
                  console.log(error);
                });
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    }
    fetchData();
  }, [contador]);

  const afterSubmit = (formConfig) => {
    return new Promise((resolve, reject) => {
      let body = {};
      formConfig.formConfig.map((actual, indice) => {
        body[actual.name] = actual.value;
        if (indice == formConfig.formConfig.length - 1) {
          body.asesor_UniqueID = idAsesor;
          body.costo = 0;
          let presupuesto = parseInt(body.honorarios);
          body.facilitadores.map((docente, index) => {
            const index_finder = (facilitador) =>
              facilitador.UniqueID == docente.value;
            presupuesto +=
              docentes[docentes.findIndex(index_finder)].costo *
              parseInt(body.horas);
            if (index == body.facilitadores.length - 1) {
              body.costo = presupuesto;
              if (
                body.facilitadores.length == 0 ||
                body.objetivos.length == 0 ||
                body.metodologia.length == 0 ||
                body.contenido.length == 0
              ) {
                reject("Falta información");
              } else {
                helpers
                  .postData("/api/create/propuesta", body)
                  .then((data) => {
                    window.location.href = "/in-house/seguimiento";
                    resolve(data);
                  })
                  .catch((error) => {
                    reject(error);
                  });
              }
            }
          });
        }
      });
    });
  };
  return (
    <Container fluid style={{ padding: "2%" }}>
      <Row>
        <Col xs={12} md={6} lg={4}>
          <ActionButton
            configuration={{
              type: 1,
              url: "/in-house/seguimiento",
              icon: "eye",
              variant: "outline-primary",
              title: "Ver propuestas",
            }}
          />
        </Col>
      </Row>
      <Row className="mt-5">
        <Formulario formConfig={formConfig} afterSubmit={afterSubmit} />
      </Row>
    </Container>
  );
};

export default FormInHouse;
