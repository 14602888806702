import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Container, Row, Col, Button, Form } from 'react-bootstrap'
//import DEFAULT_URL from '../../api/url'
const ViewPago = () => {
  const [contador] = useState(0);
  const [cobro, setCobro] = useState({});
  const [pagos, setPagos] = useState([]);
  const { id } = useParams();
  const [filteredData, setFilteredData] = useState([]);
  const [filterData, setFilterData] = useState('');
  const [cambios, setCambios] = useState(0);
  const [permiso, setPermiso] = useState(0);
  useEffect(() => {
    fetch(process.env.REACT_APP_DEFAULT_URL + '/api/cobro/view/' + id, { cache: "no-cache", credentials: 'include' })
      .then((res) => {
        return res.json();
      })
      .then(data => {
        setCobro(data.rows[0]);
      });
    fetch(process.env.REACT_APP_DEFAULT_URL + '/api/pago/read/' + id, { cache: "no-cache", credentials: 'include' })
      .then((res) => {
        return res.json();
      })
      .then(data => {
        setFilteredData(data.rows);
        setPagos(data.rows);
      });
    fetch(process.env.REACT_APP_DEFAULT_URL + '/api/auth/userinfo', { cache: "no-cache", credentials: 'include' })
      .then((res) => {
        return res.json();
      })
      .then(data => {
        setPermiso(data.tipo);
      });
    return true;
  }, [contador]);
  useEffect(() => {
  }, [cambios]);
  const filterEvent = (evt) => {
    setFilterData(evt.target.value);
    setCambios(cambios + 1);
    let result = [];
    result = pagos.filter((actual) => {
      return actual.nombres.search(evt.target.value) != -1 || actual.apellidos.search(evt.target.value) != -1 || actual.fecha.search(evt.target.value) != -1 || actual.tarjeta.search(evt.target.value) != -1 || actual.tel.search(evt.target.value) != -1;
    });
    setFilteredData(result);
    setCambios(cambios + 1);
  }
  const deleteCobro = () => {
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
    };
    fetch(process.env.REACT_APP_DEFAULT_URL + '/api/cobro/delete/' + id, requestOptions)
      .then((res) => {
        return res.json();
      })
      .then(data => {
        if (data.codigo == 200) {
          window.location.replace('/pagos-electronicos/all');
        } else {
          window.location.reload();
        }
      });
  }
  return (
    <Container style={{ padding: '2%' }}>
      <Row style={{ padding: '2%', textAlign: 'left', borderLeft: '5px solid #1e3d52 ', backgroundColor: 'rgb(250,250,250)' }}>
        <Container fluid>
          <Row>
            <h1>Cobro electrónico</h1>
          </Row>
          <Row>
            <Col xs={12} md={2} lg={2}>
              <img src={cobro.flier} style={{ width: '100%', padding: '2%' }} />
            </Col>
            <Col xs={12} md={10} lg={10} style={{ padding: '2%' }}>
              <h3>CONCEPTO: {cobro.concepto}</h3>
              <p style={{ textAlign: 'justify' }}><strong>DESCRIPCIÓN:</strong><br />{cobro.descripcion}</p>
              <p><strong>EVENTO: </strong>{cobro.Nombre + ' (' + cobro.fecha_inicio + ')'}</p>
              <p><strong>TOTAL A COBRAR: </strong>{cobro.moneda + ' ' + cobro.total}</p>
              <Container fluid>
                <Row style={{ display: 'flex', placeItems: 'center' }}>
                  <Col xs={12} md={12} lg={3} style={{ marginTop: '25px', padding: '2%' }}>
                    <img src={cobro.img_QR} style={{ width: '100%' }} alt='qr-cobro' />
                  </Col>
                  <Col xs={12} md={12} lg={3} style={{ marginTop: '25px', padding: '2%' }}>
                    <Row>
                      <Button variant='outline-primary' href={'https://ebg.edu.gt/pago-electronico/' + cobro.hash}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                      </svg>&nbsp;&nbsp;&nbsp;&nbsp;Ir a cobro</Button>
                    </Row>
                  </Col>
                  <Col xs={12} md={12} lg={3} style={{ marginTop: '25px', padding: '2%' }}>
                    <Row>
                      {permiso != 1 && <Button variant='outline-success' href={'/pagos-electronicos/update/' + cobro.UniqueID}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-fill" viewBox="0 0 16 16">
                        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                      </svg>&nbsp;&nbsp;&nbsp;&nbsp;Editar información de cobro</Button>}
                    </Row>
                  </Col>
                  <Col xs={12} md={12} lg={3} style={{ marginTop: '25px', padding: '2%' }}>
                    <Row>
                      {permiso != 1 && <Button variant='outline-danger' onClick={deleteCobro}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3" viewBox="0 0 16 16">
                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                      </svg>&nbsp;&nbsp;&nbsp;&nbsp;Eliminar cobro</Button>}
                    </Row>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </Row >
      {permiso != 1 &&
        <Row style={{ padding: '2%', textAlign: 'left' }}>
          <Form.Group className="mb-3">
            <Form.Label>Buscar</Form.Label>
            <Form.Control value={filterData} onChange={filterEvent} placeholder="Puedes buscar por concepto, descripcion, evento, fecha, total a cobrar y moneda" />
          </Form.Group>
        </Row>
      }
      {
        filteredData.map((actual, index) => <Row key={'pagos-all-' + index} style={{ padding: '2%', textAlign: 'left' }}>
          <Col xs={12} md={12} lg={12} style={{ borderBottom: '5px solid #1e3d52', borderLeft: '5px solid #1e3d52', padding: '2%' }}>
            <h3> {actual.nombres + ' ' + actual.apellidos}</h3>
            <p style={{ textAlign: 'justify' }}><strong>Correo electrónico:</strong><br />{actual.email}</p>
            <p><strong>Teléfono: </strong>{actual.tel}</p>
            <p><strong>Fecha de pago: </strong>{actual.fecha}</p>
            <p><strong>Tarjeta: </strong>{actual.tarjeta}</p>
            <p><strong>Descripción del pago: </strong>{actual.descripcion}</p>
            <Button variant='outline-dark' href={'/pagos-electronicos/view/' + actual.UniqueID}>Ver detalle</Button>
          </Col>
        </Row>)
      }
    </Container >
  )
}

export default ViewPago