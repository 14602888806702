import React, { Component } from 'react';
import { Modal, Row, Button, Container } from "react-bootstrap";
import { Participantes } from '../Participantes/Participantes';

export class ModalParticipantes extends Component {
    render() {
        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
            >
                <Modal.Header closeButton style={{ backgroundColor: "#E0E0E2" }}>
                    <Modal.Title id="contained-modal-title-vcenter" className="font-bold">
                        Registro de participantes
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: "#E0E0E2", height: '60vh' }} id="body-modal">
                    <Container>
                        {/* Stack the columns on mobile by making one full-width and the other half-width */}
                        <Row style={{ height: '20%', width: '20%' }}>
                            <div style={{ cursor: 'pointer', position: 'relative', left: '0%' }} data-toggle="tooltip" data-placement="top" title="Nuevo Participante" onClick={this.props.addparticipante}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                </svg>
                            </div>
                        </Row>
                        <Row>
                            <Participantes paises={this.props.paises} participantes={this.props.participantes} deleteparticipante={this.props.deleteparticipante} eventocambio={this.props.eventocambio} inversion = {this.props.inversion}/>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer style={{ backgroundColor: "#E0E0E2" }}>
                    <Button variant="secondary" className="font-bold" onClick={this.props.onHide}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}