import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { Row, Button, Col, Form, Container, Accordion, Table, InputGroup, Card } from "react-bootstrap";
import "../InscripcionCliente.css"
import { ErrorModal } from '../../Utils/ErrorModal/ErrorModal';
import { SuccessModal } from '../../Utils/SuccessModal/SuccessModal';
//import DEFAULT_URL from '../../../api/url';
//import { SELF_URL } from '../../../api/url';
import { Participantes } from '../Participantes/Participantes';
import ModalFacturacionData from './ModalFacturacionData';
import { ModalCargando } from '../../Utils/Modal-Cargando/ModalCargando';
import ModalFinal from './ModalFinal';
import CargaMasiva from './CargaMasiva';
const InscripcionCupon = () => {
    var today = new Date();
    let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    const { evento, asesor, cupon } = useParams();
    const [modalFinal, setModalFinal] = useState(false);
    const [modalCargando, setModalCargando] = useState(false);
    const [modalError, setModalError] = useState(false);
    const [modalSucc, setModalSucc] = useState(false);
    const [modalFacturacion, setModalFacturacion] = useState(false);
    const [enlaceActual, setEnlaceActual] = useState('https://www.ebg.edu.gt/');
    const [precio, setPrecio] = useState(0);
    const [participantes, setParticipantes] = useState([
        {
            No: 0,
            identificacion: '',
            Nombres: '',
            Apellidos: '',
            Puesto: '',
            Correo: '',
            Tel: '',
            Celular: '',
            Pais: ''
        }
    ]);
    const [precio_agremiados, setPrecioAgremiados] = useState('');
    const [precio_no_agremiados, setPrecioNoAgremiados] = useState('');
    const [costo_agremiados, setCostoAgremiados] = useState(0);
    const [costo_no_agremiados, setCostoNoAgremiados] = useState(0);
    const [agremiado, setAgremiado] = useState(false);
    const [inversion, setInversion] = useState(0);
    const [fecha_Max_FACT, setFecha_Max_FACT] = useState(date);
    const [nombre_FACT, setNombre_FACT] = useState('');
    const [nIT, setNIT] = useState('');
    const [direccion, setDireccion] = useState('');
    const [correo_FACT, setCorreo_FACT] = useState('');
    const [responsable, setResponsable] = useState('PENDIENTE');
    const [correo_RESP, setCorreo_RESP] = useState('PENDIENTE');
    const [tel_RESP, setTel_RESP] = useState('PENDIENTE');
    const [responsable_PAGO, setResponsable_PAGO] = useState('PENDIENTE');
    const [correo_RP, setCorreo_RP] = useState('PENDIENTE');
    const [tel_RP, setTel_RP] = useState('PENDIENTE');
    const [comentario, setComentario] = useState('');
    const [evento_Unique_ID, setEvento_Unique_ID] = useState('');
    const [forma_Pago_UniqueID, setForma_Pago_UniqueID] = useState(15);
    const [moneda_Unique_ID, setMoneda_Unique_ID] = useState(8);
    const [moneda, setMoneda] = useState('Q');
    const [eventos, setEventos] = useState([]);
    const [formas_pago, setFormas_pago] = useState([]);
    const [asesores, setAsesores] = useState([]);
    const [mensaje, setMensaje] = useState('');
    const [consideracion, setConsideracion] = useState('');
    const [asesor_UniqueID, setAsesor_UniqueID] = useState(0);
    const [empresa, setEmpresa] = useState('');
    const [contador, setContador] = useState(0);
    const [promocional, setPromocional] = useState('');
    const [titulo, setTitulo] = useState('');
    const [dia, setDia] = useState('');
    const [mes_anio, setMesAnio] = useState('');
    const [paises, setPaises] = useState([]);
    const [tipo, setTipo] = useState(0);
    const [tarifario, setTarifario] = useState([]);
    const [descuento, setDescuento] = useState({});
    const [agremiados, setAgremiados] = useState([]);
    const [cambios, setCambios] = useState(0);
    useEffect(async () => {
        async function fetchData() {
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read_hash/asesor/' + asesor, { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setAsesor_UniqueID(data.rows[0].UniqueID);
                });
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/evento/agenda', { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setEventos(data.rows);
                });
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/evento/landing/' + evento, { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    if (data.rows[0].disponible == '0') {
                        window.location.replace('/unauthorized');
                    } else {
                        setEvento_Unique_ID(data.rows[0].UniqueID);
                        setPrecioAgremiados(data.rows[0].precio_agremiados);
                        setPrecioNoAgremiados(data.rows[0].precio_no_agremiados);
                        setCostoAgremiados(data.rows[0].costo_agremiados);
                        setCostoNoAgremiados(data.rows[0].costo_no_agremiados);
                        setPromocional(data.rows[0].flier);
                        setTitulo(data.rows[0].Nombre);
                        setMesAnio(data.rows[0].mes_anio);
                        setDia(data.rows[0].dia);
                        setTipo(data.rows[0].tipo_id);
                        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/evento/tarifas/' + data.rows[0].UniqueID, { cache: "no-cache" })
                            .then((res) => {
                                return res.json();
                            })
                            .then(data => {
                                setTarifario(data.salida);
                            });
                    }

                });
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/forma_pago', { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setFormas_pago(data.rows);
                });
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/asesor', { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setAsesores(data.rows);
                });
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/paises', { cache: "no-cache" })
                .then((res) => {
                    return res.json()
                })
                .then(data => {
                    let paises_arr = [];
                    data.paises.map((actual) => { paises_arr.push({ id: actual.code, nombre: actual.name}) });
                    paises_arr.sort(function (a, b) { return a.nombre.localeCompare(b.nombre) });
                    setPaises(paises_arr);
                });
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/agremiados', { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setAgremiados(data.agremiados);
                });
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/descuentos/read/' + evento + '/' + asesor + '/' + cupon, { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    //console.log(data);
                    if (data.rows.length == 0) {
                        window.location.replace('/unauthorized');
                    } else if (data.rows[0].dias < 0) {
                        window.location.replace('/unauthorized');
                    } else {
                        setDescuento(data.rows[0]);
                        setComentario(data.rows[0].ret);
                    }
                });
        }
        fetchData();
        if (agremiado === true) {
            setInversion(costo_agremiados * participantes.length);
        } else {
            setInversion(costo_no_agremiados * participantes.length);
        }
    }, [contador]);
    useEffect(() => {
        if (agremiado === true) {
            setInversion(costo_agremiados * participantes.length);
        } else {
            setInversion(costo_no_agremiados * participantes.length);
        }
    }, [cambios])

    const cargarParticipantes = (array) => {
        let tmp = [];
        array.map((curr) => {
            tmp.push({
                No: tmp.length,
                identificacion: '',
                Nombres: curr.NOMBRES,
                Apellidos: curr.APELLIDOS,
                Puesto: curr.PUESTO,
                Correo: curr['CORREO ELECTRONICO'],
                Tel: curr['TELEFONO Y EXTENSION'],
                Celular: curr.CELULAR,
                Pais: curr.PAIS
            });
        });
        setParticipantes(tmp);
        setCambios(cambios + 1);
    }
    const ocultar = () => {
        setModalError(false);
        setModalSucc(false);
        setModalFacturacion(false);
        setCambios(cambios + 1);
    }
    const addparticipante = () => {
        const newParticipante = { No: participantes.length, Nombres: '', Apellidos: '', Puesto: '', Correo: '', Tel: '', Celular: '' };
        setParticipantes([...participantes, newParticipante]);
        setCambios(cambios + 1);
    }
    const deleteparticipante = (No) => {
        var new_array = participantes.filter(participante => participante.No !== No)
        if (new_array.length > 0) {
            for (let i = 0; i < new_array.length; i++) {
                new_array[i].No = i;
            }
            setParticipantes(new_array);
            setCambios(cambios + 1);
            return 0;
        } else {
            var nuevo_array = participantes;
            nuevo_array[0].Nombres = '';
            nuevo_array[0].Apellidos = '';
            nuevo_array[0].Puesto = '';
            nuevo_array[0].Correo = '';
            nuevo_array[0].Tel = '';
            nuevo_array[0].Celular = '';
            nuevo_array[0].Pais = '';
            setParticipantes(nuevo_array);
            setCambios(cambios + 1);
        }
        setCambios(cambios + 1);
    }

    const eventocambio = (evt) => {
        const arr_name = evt.target.name.split("-");
        var new_array = participantes;
        switch (arr_name[0]) {
            case "nombres":
                new_array[arr_name[1]].Nombres = evt.target.value;
                setParticipantes(new_array);
                break;
            case "apellidos":
                new_array[arr_name[1]].Apellidos = evt.target.value;
                setParticipantes(new_array);
                break;
            case "puesto":
                new_array[arr_name[1]].Puesto = evt.target.value;
                setParticipantes(new_array);
                break;
            case "correo":
                new_array[arr_name[1]].Correo = evt.target.value;
                setParticipantes(new_array);
                break;
            case "telefono":
                new_array[arr_name[1]].Tel = evt.target.value;
                setParticipantes(new_array);
                break;
            case "celular":
                new_array[arr_name[1]].Celular = evt.target.value;
                setParticipantes(new_array);
                break;
            case "identificacion":
                new_array[arr_name[1]].identificacion = evt.target.value;
                setParticipantes(new_array);
                break;
            case "pais":
                new_array[arr_name[1]].Pais = evt.target.value;
                setParticipantes(new_array);
                break;
            default:
                break;
        }
        setCambios(cambios + 1);
    }
    const cambioencabezado = (evt) => {
        if (evt.target.name === "agremiado") {
            setAgremiado(evt.target.checked);
            setEmpresa('');

        } else {

            switch (evt.target.name) {
                case "asesor_UniqueID":
                    setAsesor_UniqueID(evt.target.value)
                    break;
                case "inversion":
                    setInversion(evt.target.value);
                    break;
                case "evento_Unique_ID":
                    setEvento_Unique_ID(evt.target.value);
                    break;
                case "fecha_Max_FACT":
                    setFecha_Max_FACT(evt.target.value);
                    break;
                case "nombre_FACT":
                    setNombre_FACT(evt.target.value);
                    break;
                case "nIT":
                    setNIT(evt.target.value);
                    fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/nits', { cache: "no-cache" })
                        .then((res) => {
                            return res.json();
                        })
                        .then(data => {
                            const index_finder = (nit_searched) => nit_searched.NIT === evt.target.value.trim();

                            if (data.rows.findIndex(index_finder) > -1 && (evt.target.value != '' || evt.target.value != 'cf' || evt.target.value != 'CF')) {
                                let nombre = data.rows[data.rows.findIndex(index_finder)].Nombre_FACT
                                let dir = data.rows[data.rows.findIndex(index_finder)].Direccion;
                                console.log(data.rows.findIndex(index_finder));
                                setDireccion(dir);
                                setNombre_FACT(nombre);
                                setCambios(cambios + 1);
                            }

                        });
                    break;
                case "direccion":
                    setDireccion(evt.target.value);
                    break;
                case "correo_FACT":
                    setCorreo_FACT(evt.target.value);
                    break;
                case "empresa":
                    setEmpresa(evt.target.value);
                    break;
                case "responsable":
                    setResponsable(evt.target.value);
                    break;
                case "correo_RESP":
                    setCorreo_RESP(evt.target.value);
                    break;
                case "tel_RESP":
                    setTel_RESP(evt.target.value);
                    break;
                case "responsable_PAGO":
                    setResponsable_PAGO(evt.target.value);
                    break;
                case "correo_RP":
                    setCorreo_RP(evt.target.value);
                    break;
                case "tel_RP":
                    setTel_RP(evt.target.value);
                    break;
                case "forma_Pago_UniqueID":
                    setForma_Pago_UniqueID(evt.target.value);
                    break;
                case "comentario":
                    setComentario(evt.target.value);
                    break;
                case "seleccion-agremiados":
                    setEmpresa(evt.target.value);
                    if (agremiado === true) {
                        setInversion(costo_agremiados * participantes.length);
                    } else {
                        setInversion(costo_no_agremiados * participantes.length);
                    }
                    break;
            }
        }
        setCambios(cambios + 1);
    }
    const eventoenvio = () => {
        setMensaje('Procesando información, por favor espere...');
        setModalCargando(true);
        setCambios(cambios + 1);
        const validar_participantes = participantes.filter(participante => participante.Nombres === '' || participante.Nombres === 'Nuevo Participante' || participante.Apellidos === '' || participante.Celular === '' || participante.Correo === '' || participante.Puesto === '' || participante.Tel === '' || participante.Pais === '');
        if (validar_participantes.length > 0) {
            setMensaje('Por favor, revise la información que ingresó en el apartado de participantes');
            setConsideracion('Los participantes se ingresan en Registrar/Participantes');
            setModalError(true);
            setCambios(cambios + 1);
        } else {
            const info =
            {
                participantes: participantes,
                Agremiado: agremiado,
                Inversion: inversion - descuento.descuento,
                Fecha_Max_FACT: fecha_Max_FACT,
                Nombre_FACT: nombre_FACT,
                NIT: nIT,
                Direccion: direccion,
                Correo_FACT: correo_FACT,
                Responsable: responsable,
                Correo_RESP: correo_RESP,
                Tel_RESP: tel_RESP,
                Responsable_PAGO: responsable_PAGO,
                Correo_RP: correo_RP,
                Tel_RP: tel_RP,
                Comentario: comentario,
                Evento_Unique_ID: evento_Unique_ID,
                Forma_Pago_UniqueID: forma_Pago_UniqueID,
                Moneda_Unique_ID: moneda_Unique_ID,
                moneda: moneda,
                Asesor_UniqueID: asesor_UniqueID,
                empresa: empresa
            };
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(info)
            };
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/inscribir', requestOptions)
                .then((res) => {
                    return res.json();
                })
                .then(datos_inscripcion => {
                    if (datos_inscripcion.codigo == 200) {
                        const info =
                        {
                            hash: cupon
                        };
                        const requestOptions = {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(info)
                        };
                        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/descuentos/canjear', requestOptions)
                            .then((res) => {
                                return res.json();
                            })
                            .then(data => {
                                if (data.codigo == 200) {
                                    setPrecio(inversion - descuento.descuento);
                                    setEnlaceActual(process.env.REACT_APP_SELF_URL + '/inscripcion/public/ver/' + datos_inscripcion.id);
                                    const index_finder = (asesor) => asesor.UniqueID == asesor_UniqueID;
                                    let email_asesor = asesores[asesores.findIndex(index_finder)].email;
                                    if ((inversion - descuento.descuento) > 0) {
                                        const info = { participantes: participantes, titulo: titulo, promocional: promocional, enlace: process.env.REACT_APP_SELF_URL + '/inscripcion/public/ver/' + datos_inscripcion.id, enlace_seguimiento: process.env.REACT_APP_SELF_URL + '/inscripcion/seguimiento/' + datos_inscripcion.id, dia: dia, mes_anio: mes_anio, email_asesor: email_asesor };
                                        const requestOptions = {
                                            method: 'POST',
                                            headers: {
                                                'Content-Type': 'application/json'
                                            },
                                            body: JSON.stringify(info)
                                        };
                                        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/email/informar/inscripcion/pagada', requestOptions)
                                            .then((res) => {
                                                return res.json();
                                            })
                                            .then(data => {
                                                clean_state();
                                                setModalFinal(true);
                                                setModalCargando(false);
                                                setCambios(cambios + 1);
                                            });
                                    } else {
                                        const info = { participantes: participantes, titulo: titulo, promocional: promocional, enlace: process.env.REACT_APP_SELF_URL + '/inscripcion/public/ver/' + datos_inscripcion.id, dia: dia, mes_anio: mes_anio, email_asesor: email_asesor };
                                        const requestOptions = {
                                            method: 'POST',
                                            headers: {
                                                'Content-Type': 'application/json'
                                            },
                                            body: JSON.stringify(info)
                                        };
                                        const info_2 = { UniqueID: datos_inscripcion.id };
                                        const requestOptions_2 = {
                                            method: 'POST',
                                            headers: {
                                                'Content-Type': 'application/json'
                                            },
                                            body: JSON.stringify(info_2)
                                        };
                                        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/update/inscripcion/confirmar_pago', requestOptions_2)
                                            .then((res) => {
                                                return res.json();
                                            })
                                            .then(data => {
                                                if (data.codigo == 200) {
                                                    fetch(process.env.REACT_APP_DEFAULT_URL + '/api/email/informar/inscripcion/cortesia', requestOptions)
                                                        .then((res) => {
                                                            return res.json();
                                                        })
                                                        .then(data => {
                                                            clean_state();
                                                            setModalFinal(true);
                                                            setModalCargando(false);
                                                            setCambios(cambios + 1);
                                                        });
                                                } else {
                                                    setMensaje('Ocurrió un error al intentar almacenar la inscripción');
                                                    setConsideracion('');
                                                    setModalError(true);
                                                    setCambios(cambios + 1);
                                                }
                                            });
                                    }
                                } else {
                                    setMensaje('Ocurrió un error al canjear cupón');
                                    setConsideracion('');
                                    setModalError(true);
                                    setCambios(cambios + 1);
                                }
                            });
                    } else {
                        setMensaje('Ocurrió un error al intentar almacenar la inscripción');
                        setConsideracion('');
                        setModalError(true);
                        setCambios(cambios + 1);
                    }
                });
        }
    }
    const siguientes = (evt) => {
        evt.preventDefault();
        setMensaje('Validando información, por favor espere...');
        setModalCargando(true);
        setCambios(cambios + 1);
        const info = { participantes: participantes };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/detalle_inscripcion/search/email/' + evento_Unique_ID, requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                if (data.salida.length > 0) {
                    setModalCargando(false);
                    setMensaje('Hay uno o varios emails registrados en el sistema, por favor corregir información');
                    setConsideracion('');
                    setModalError(true);
                    setCambios(cambios + 1);
                } else {
                    setModalCargando(false);
                    if ((inversion - descuento.descuento) > 0) {
                        if (agremiado === true) {
                            const index_finder = (institu) => institu.INSTITUCION === empresa;
                            let forma_pago = agremiados[agremiados.findIndex(index_finder)].FORMA_PAGO;
                            let nit = agremiados[agremiados.findIndex(index_finder)].NIT;
                            let dir = agremiados[agremiados.findIndex(index_finder)].DIRECCION;
                            setNIT(nit);
                            setDireccion(dir);
                            setNombre_FACT(empresa);
                            setForma_Pago_UniqueID(forma_pago.toString());
                        } else {
                            setForma_Pago_UniqueID('');
                        }
                        setModalFacturacion(true);
                        setFecha_Max_FACT('');
                        setCambios(cambios + 1);
                    } else {
                        eventoenvio();
                    }
                }
            });
    }
    const seleccion_moneda = (evt) => {
        if (evt.target.checked) {
            setMoneda('$');
            setMoneda_Unique_ID(7)
        } else {
            setMoneda('Q');
            setMoneda_Unique_ID(8);
        }
        setCambios(cambios + 1);
    }
    const clean_state = () => {
        setParticipantes([
            {
                No: 0,
                identificacion: '',
                Nombres: '',
                Apellidos: '',
                Puesto: '',
                Correo: '',
                Tel: '',
                Celular: ''
            }
        ]);
        setAgremiado(false);
        setInversion('');
        setFecha_Max_FACT('');
        setNombre_FACT('');
        setNIT('');
        setDireccion('');
        setCorreo_FACT('');
        setResponsable('');
        setCorreo_RESP('');
        setTel_RESP('');
        setResponsable_PAGO('');
        setCorreo_RP('');
        setTel_RP('');
        setComentario('');
        setEvento_Unique_ID('');
        setForma_Pago_UniqueID('');
        setMoneda_Unique_ID(8);
        setMoneda('Q');
        setMensaje('');
        setConsideracion('');
        setAsesor_UniqueID(0);
        setEmpresa('');
        setCambios(cambios + 1);
    }
    return (
        <div className="contenedor-inscripcion-cliente poster-info" style={{ overflow: 'scroll' }}>
            <Container style={{ height: '100%' }}>
                <Row style={{ marginTop: '45px' }}>
                    <Col xs={12} md={12} lg={6}>
                        <img src='https://ik.imagekit.io/p5ethkcnzwh/Blanco_Logo_final_2022_ABG_6V4gmyglP.png?ik-sdk-version=javascript-1.4.3&updatedAt=1657298859263' style={{ width: '75%' }} />
                    </Col>
                    <Col xs={12} md={12} lg={6} style={{ marginTop: '25px' }}>
                        <h1 className="font-bold blanco" style={{ fontWeight: 'bolder' }}>ESCUELA BANCARIA DE GUATEMALA</h1>
                    </Col>
                </Row>
                <Row>
                    <Form onSubmit={siguientes}>
                        <Container style={{ marginTop: '45px' }}>
                            <Row>
                                <Col xs={12} md={6} lg={6}>
                                    <img src={promocional} style={{ width: '100%', objectFit: 'cover' }} />
                                    {tarifario.length > 0 &&
                                        <Row style={{ marginTop: '45px' }}>
                                            <h4 style={{ color: 'white' }}>Inversión</h4>
                                            {tarifario.map((tarifa) =>
                                                <Col xs={12} md={12} lg={12}>
                                                    <Row>
                                                        <h5 style={{ color: 'white' }}>{tarifa.tarifa}</h5>
                                                        <Table striped bordered hover variant="light">
                                                            <thead>
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>Participantes</th>
                                                                    <th>Precio</th>
                                                                    <th>Beneficio</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {tarifa.costos.map((costo, index) =>
                                                                    <tr>
                                                                        <td>{index + 1}</td>
                                                                        <td>{costo.descripcion}</td>
                                                                        <td>{costo.precio}</td>
                                                                        <td style={{ fontStyle: 'oblique' }}>{costo.adicional}</td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </Table>
                                                    </Row>
                                                </Col>
                                            )}
                                        </Row>}
                                </Col>
                                <Col xs={12} md={6} lg={6} style={{ marginTop: '55px' }} className="font-bold">
                                    <Row>
                                        <h2 style={{ color: 'white', fontWeight: 'bold' }} className='font-bold'>{titulo}</h2>
                                        <h3 className="font-bold" style={{ marginTop: '5%', marginBottom: '5%', color: 'white', }} variant="light">FORMULARIO DE INSCRIPCIÓN</h3>
                                    </Row>
                                    {tarifario.length == 0 && <Row style={{ color: 'white' }}>
                                        <h4>Inversión</h4>
                                    </Row>}
                                    {tarifario.length == 0 &&
                                        <Row style={{ color: 'white', marginTop: '15px' }}>
                                            <Col xs={12} md={6} lg={6} style={{ borderRadius: '100px', height: '50px', display: 'flex', placeItems: 'center', textAlign: 'center' }}><h6 style={{ width: '100%' }}><strong>Agremiados: </strong> {precio_agremiados}</h6></Col>
                                            <Col xs={12} md={6} lg={6} style={{ borderRadius: '100px', height: '50px', display: 'flex', placeItems: 'center', textAlign: 'center' }}><h6 style={{ width: '100%' }}><strong>No Agremiados: </strong> {precio_no_agremiados}</h6></Col>
                                        </Row>
                                    }
                                    <Row>
                                        <Container>
                                            <Row style={{ color: 'white', marginTop: '25px' }} className='font-bold'>
                                                <h4>Participantes</h4>
                                                <p style={{ fontStyle: 'oblique' }}>Para ver e ingresar la información de los participantes, por favor presionar en las ventanas desplegables a continuación.</p>
                                            </Row>
                                            <Row className='participantes-list'>
                                                <Participantes participantes={participantes} deleteparticipante={deleteparticipante} eventocambio={eventocambio} paises={paises} inversion={inversion} />
                                            </Row>
                                            <Row style={{ color: 'white', marginTop: '25px', marginBottom: '25px' }}>
                                                <Col xs={2} md={2} lg={2}></Col>
                                                <Col xs={8} md={8} lg={8}>
                                                    <Button variant="light" onClick={addparticipante}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-plus-fill" viewBox="0 0 16 16" style={{ marginRight: '12px' }}>
                                                            <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                                            <path fillRule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z" />
                                                        </svg>
                                                        Agregar Participante
                                                    </Button>
                                                </Col>
                                                <Col xs={2} md={2} lg={2}></Col>
                                            </Row>
                                            <Row>
                                                <CargaMasiva cargarParticipantes={cargarParticipantes} />
                                            </Row>
                                        </Container>
                                    </Row>
                                    <Row>
                                        <div className="columna-formulario " style={{ padding: '2%', textAlign: 'center' }}>
                                            <Form.Group className="mb-3" controlId="Agremiado" style={{ color: 'white' }}>
                                                <Form.Check
                                                    type="switch"
                                                    id="custom-switch"
                                                    className="font-normal"
                                                    name="agremiado"
                                                    label="Agremiados"
                                                    style={{ textAlign: 'left' }}
                                                    onChange={cambioencabezado}
                                                    value={agremiado}

                                                />
                                            </Form.Group>
                                            <Accordion defaultActiveKey="0" className="bg-success" style={{ marginTop: '5%' }} alwaysOpen>
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header className="font-bold">INFORMACIÓN GENERAL</Accordion.Header>
                                                    <Accordion.Body className="font-normal">
                                                        <Form.Group className="mb-3" controlId="Forma_Pago_UniqueID">
                                                            <Form.Label>Asesor</Form.Label>
                                                            <Form.Control as="select" disabled required aria-label="Asesor" name="asesor_UniqueID" onChange={cambioencabezado} value={asesor_UniqueID}>
                                                                <option value="" key="0">Seleccione el asesor contactado</option>
                                                                {asesores.map(asesor => <option key={asesor.UniqueID} value={asesor.UniqueID}>{asesor.Nombres + " " + asesor.Apellidos}</option>)}
                                                            </Form.Control>
                                                        </Form.Group>

                                                        {inversion > 0 && <Form.Group className="mb-3" controlId="Inversion">
                                                            <Form.Label>Inversión</Form.Label>
                                                            <InputGroup className="mb-3">
                                                                <InputGroup.Text>{moneda}</InputGroup.Text>
                                                                <Form.Control required disabled name="inversion" placeholder="Ingrese el monto de inversión" onChange={cambioencabezado} value={inversion.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} />
                                                            </InputGroup>
                                                            <Form.Check
                                                                type="switch"
                                                                id="switch-dolar"
                                                                className="font-normal"
                                                                label="$ (USD)"
                                                                onChange={seleccion_moneda}
                                                                style={{ textAlign: 'left', display: 'none' }}
                                                            />
                                                        </Form.Group>}
                                                        {agremiado === false && <p>Inversión por participante:  <strong>{precio_no_agremiados}</strong></p>}
                                                        {agremiado === true && <p>Inversión por participante:  <strong>{precio_agremiados}</strong></p>}
                                                        <p>Descuento autorizado: <strong>{descuento.letrero_descuento}</strong></p>
                                                        {(inversion - descuento.descuento > 0) && <p>Total con descuento: <strong>Q {(inversion - descuento.descuento).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</strong></p>}
                                                        <Form.Group className="mb-3" controlId="Evento_Unique_ID" style={{ display: 'none' }}>
                                                            <Form.Label>Evento</Form.Label>
                                                            <Form.Control as="select" required disabled aria-label="Evento" name="evento_Unique_ID" onChange={cambioencabezado} value={evento_Unique_ID} >
                                                                <option value="" key="0">Seleccione el evento</option>
                                                                {eventos.map(evento => <option key={evento.UniqueID} value={evento.UniqueID}>{evento.Nombre}</option>)}
                                                            </Form.Control>
                                                        </Form.Group>
                                                        {agremiado === false && <Form.Group className="mb-3" controlId="Empresa">
                                                            <Form.Label>Institución donde labora</Form.Label>
                                                            <Form.Control required type="text" placeholder="Ingrese el nombre de la institución donde labora" name="empresa" onChange={cambioencabezado} value={empresa} />
                                                        </Form.Group>}
                                                        {agremiado === true && <Form.Group className="mb-3" controlId="seleccion-agremiados">
                                                            <Form.Label>Institución donde labora</Form.Label>
                                                            <Form.Control as="select" required aria-label="Institucion" name="seleccion-agremiados" onChange={cambioencabezado} value={empresa}>
                                                                <option value="" key="0">Seleccione la institución donde labora</option>
                                                                {agremiados.map(actual => <option key={actual.NO} value={actual.INSTITUCION}>{actual.INSTITUCION}</option>)}
                                                            </Form.Control>
                                                        </Form.Group>}
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    </Row>
                                    <Row>
                                        <Card className="text-center" style={{ marginTop: '35px' }}>
                                            <Card.Header>Políticas y Condiciones de Inscripción</Card.Header>
                                            <Card.Body style={{ height: '250px', overflowY: 'scroll' }}>
                                                {inversion > 0 &&
                                                    <ul style={{ textAlign: 'justify' }}>
                                                        <li>
                                                            Para inscribirse en el evento, deberá completar el formulario de inscripción correspondiente. La persona responsable de realizar la inscripción por cuenta de una entidad, asume el compromiso ante la ABG de realizar las gestiones pertinentes para honrar el pago de las inscripciones formalizadas
                                                        </li>
                                                        <li>
                                                            Toda inscripción al evento se formaliza con la recepción por parte de la ABG del formulario de inscripción. La EBG EBC enviará por correo una confirmación de inscripción, ambos documentos representarán el compromiso de pago de la entidad a que pertenecen las personas inscritas.
                                                        </li>
                                                        <li>
                                                            La ABG emitirá las facturas conforme los datos consignados en el formulario de inscripción, por tanto no se aceptarán solicitudes de cambio de facturas. Si las circunstancias tributarias y administrativas lo permiten, la sustitución de una factura, tendrá un costo de Q200.00 en concepto de gastos de administración.
                                                        </li>
                                                        <li>
                                                            Cancelación: La Inasistencia al evento de uno a más participantes inscritos, por causas ajenas a la ABG EBG EBC UC, no exime a la entidad respectiva del compromiso de pago adquirido por las inscripciones formalizadas.
                                                        </li>
                                                        <li>
                                                            No habrá reembolso por pagos efectuados cuando la(s) inasistencia(s) sean por causas ajenas a ABG EBG EBC UC.
                                                        </li>
                                                        <li>
                                                            A los participantes que por causas ajenas a la Asociación, no pudieron asistir a los eventos inscritos, en los horarios y días establecidos, podrán optar a la grabación de su interés, la cuál será habilitada por 24 horas, <strong>es importante indicar que, toda solicitud de grabaciones de los programas virtuales de las Unidades Educativas de la ABG, (EBG – EBC), tienen un costo adicional, para mayor información consultar con su asesor asignado.</strong> Es indispendable que el participante envié una carta de la Institución, firmada y sellada, por la persona responsable de la inscripción, solicitando el acceso a la grabación del evento.
                                                        </li>
                                                    </ul>
                                                }
                                                {inversion == 0 &&
                                                    <ul style={{ textAlign: 'justify' }}>
                                                        <li>
                                                            Para inscribirse en el evento, deberá completar el formulario de inscripción correspondiente.
                                                        </li>
                                                        <li>
                                                            Toda inscripción al evento se formaliza con la recepción por parte de la EBG del formulario de inscripción. La EBG EBC enviará por correo una confirmación de inscripción.
                                                        </li>
                                                        <li>
                                                            La información recopilada por los formularios de inscripción de la EBG EBC, será distribuida a las diferentes organizaciones que hacen posible los eventos de la EBG EBC.
                                                        </li>
                                                        <li>
                                                            Al aceptar todos los términos y condiciones de inscripción gratuita a los eventos de la EBG EBC, está autorizando a la EBG EBC la distribución de la información que usted llenó en los formularios a las organizaciones patrocinadoras.
                                                        </li>
                                                        <li>
                                                            Al aceptar todos los términos y condiciones de inscripción gratuita a los eventos de la EBG EBC, está autorizando a las diferentes organizaciones patrocinadoras a contactarse con su persona.
                                                        </li>
                                                        <li>
                                                            Usted al llenar el formulario acepta todos los términos y condiciones de inscripción gratuita a los eventos de la EBG EBC.
                                                        </li>
                                                    </ul>
                                                }
                                            </Card.Body>
                                            <Card.Footer className="text-muted" style={{ fontStyle: 'oblique' }}>La educación es el arma más poderosa<br /> que puedes usar para cambiar el mundo<br /><strong>Nelson Mandela</strong></Card.Footer>
                                        </Card>
                                    </Row>
                                    <Row style={{ marginTop: '25px', marginBottom: '25px' }}>
                                        <Button variant="light" className="font-bold" type="submit">Registrar inscripción</Button>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </Form>
                    <ErrorModal
                        color={"#E0E0E2"}
                        show={modalError}
                        onHide={() => { setModalError(false); setContador(contador + 1); }}
                        mensaje={mensaje}
                        consideracion={consideracion}
                    />
                    <SuccessModal
                        color={"#E0E0E2"}
                        show={modalSucc}
                        onHide={ocultar}
                        mensaje={mensaje}
                        consideracion={consideracion}
                        enlace={''}
                        llevaenlace={false}
                    />
                    <ModalFacturacionData
                        show={modalFacturacion}
                        onHide={() => { setModalFacturacion(false); setContador(contador + 1); }}
                        submit={eventoenvio}
                        cambioencabezado={cambioencabezado}
                        formas_pago={formas_pago}
                        fecha_Max_FACT={fecha_Max_FACT}
                        nombre_FACT={nombre_FACT}
                        nIT={nIT}
                        direccion={direccion}
                        correo_FACT={correo_FACT}
                        forma_Pago_UniqueID={forma_Pago_UniqueID}
                        comentario={descuento.ret}
                        lock={1}
                        agremiado={agremiado}
                    />
                    <ModalCargando show={modalCargando} onHide={() => setModalCargando(false)} mensaje={mensaje} />
                    <ModalFinal enlace={enlaceActual} show={modalFinal} onHide={() => { setModalFinal(false); window.location.reload(); }} promocional={promocional} inversion={precio} hash={evento} tipo={tipo} />
                </Row>
            </Container>

        </div>
    );
}
export default InscripcionCupon;


/*

                                                        <Form.Group className="mb-3" controlId="Fecha_Max_FACT">
                                                            <Form.Label>Fecha máxima que reciben facturas</Form.Label>
                                                            <Form.Control required type="date" name="fecha_Max_FACT" placeholder="Seleccione la fecha" onChange={cambioencabezado} value={fecha_Max_FACT} />
                                                        </Form.Group>
                                                        <Form.Group className="mb-3" controlId="Nombre_FACT">
                                                            <Form.Label>Nombre para la factura</Form.Label>
                                                            <Form.Control required type="text" name="nombre_FACT" placeholder="Consumidor Final" onChange={cambioencabezado} value={nombre_FACT} />
                                                        </Form.Group>
                                                        <Form.Group className="mb-3" controlId="NIT">
                                                            <Form.Label>NIT</Form.Label>
                                                            <Form.Control required type="text" placeholder="CF" name="nIT" onChange={cambioencabezado} value={nIT} />
                                                        </Form.Group>
                                                        <Form.Group className="mb-3" controlId="Direccion">
                                                            <Form.Label>Dirección</Form.Label>
                                                            <Form.Control required type="text" placeholder="Ciudad" name="direccion" onChange={cambioencabezado} value={direccion} />
                                                        </Form.Group>
                                                        <Form.Group className="mb-3" controlId="Correo_FACT">
                                                            <Form.Label>Correo para enviar factura electrónica</Form.Label>
                                                            <Form.Control required type="email" placeholder="Por favor ingrese el correo" name="correo_FACT" onChange={cambioencabezado} value={correo_FACT} />
                                                        </Form.Group>
                                                        <Form.Group className="mb-3" controlId="Forma_Pago_UniqueID">
                                                            <Form.Label>Indique su forma de pago</Form.Label>
                                                            <Form.Control as="select" required aria-label="Evento" name="forma_Pago_UniqueID" onChange={cambioencabezado} value={forma_Pago_UniqueID}>
                                                                <option value="" key="0">Seleccione la forma de pago</option>
                                                                {formas_pago.map(forma_pago => <option key={forma_pago.UniqueID} value={forma_pago.UniqueID}>{forma_pago.Nombre}</option>)}
                                                            </Form.Control>
                                                        </Form.Group>
                                                        <Form.Group className="mb-3" controlId="Comentario">
                                                            <Form.Label>Comentario sobre el pago</Form.Label>
                                                            <Form.Control as="textarea" rows={3} style={{ resize: 'none' }} name="comentario" onChange={cambioencabezado} value={comentario} />
                                                        </Form.Group>


*/