import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
//import DEFAULT_URL from '../../../../../api/url';
import { Container, Row, Col, Button } from "react-bootstrap";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
const Recepcion = () => {
  const { hash } = useParams();
  const [contador] = useState(0);
  const [evento, setEvento] = useState({});
  const [datos, setDatos] = useState({});
  useEffect(() => {
    async function fetchData() {
      fetch(
        process.env.REACT_APP_DEFAULT_URL + "/api/read/evento/landing/" + hash,
        { cache: "no-cache" }
      )
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setEvento(data.rows[0]);
        });
    }
    fetchData();
  }, [contador]);

  useEffect(() => {
    fetch(
      process.env.REACT_APP_DEFAULT_URL + "/api/read/evento/asistencia/gafete",
      { cache: "no-cache" }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setDatos({});
        setDatos({
          actual: data.resultado_Actual[0],
        });
      });
  });
  const exportExcelFile = () => {
    fetch(
      process.env.REACT_APP_DEFAULT_URL + "/api/read/asistencia-excel-file",
      { cache: "no-cache" }
    )
      .then((res) => {
        return res.json();
      })
      .then((datos) => {
        const fileType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const ws = XLSX.utils.json_to_sheet(datos.resultado);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, Date.now() + fileExtension);
      });
  };
  const emptyAsistencia = () => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    };
    fetch(
      process.env.REACT_APP_DEFAULT_URL + "/api/delete/asistencia-mongo",
      requestOptions,
      { cache: "no-cache" }
    )
      .then((res) => {
        return res.json();
      })
      .then((datos) => {
        window.location.reload();
      });
  };
  return (
    <Container fluid style={{ backgroundColor: "rgb(230, 230, 230)" }}>
      <Row
        style={{
          padding: "5%",
          borderBottom: "5px solid #1e3d52",
          textAlign: "left",
        }}
      >
        <Container fluid>
          <Row>
            <Col xs={12} md={12} lg={6}>
              <Container fluid>
                <Row>
                  <section>
                    <video
                      autoPlay
                      muted
                      loop
                      style={{ width: "100%" }}
                      src={evento.video_promo}
                      type="video/mp4"
                    ></video>
                  </section>
                </Row>
              </Container>
            </Col>
            <Col xs={12} md={12} lg={6}>
              <Container fluid>
                <Row
                  style={{
                    display: "grid",
                    placeItems: "center",
                    padding: "1%",
                  }}
                >
                  {datos.actual != null && datos.actual.foto == null && (
                    <img
                      src="https://ik.imagekit.io/p5ethkcnzwh/person-icon_B5aNsHlZl.png?ik-sdk-version=javascript-1.4.3&updatedAt=1659026017200"
                      loading="lazy"
                      alt="foto"
                      style={{ width: "50%", objectFit: "cover" }}
                    />
                  )}
                  {datos.actual != null && datos.actual.foto != null && (
                    <img
                      src={datos.actual.foto}
                      loading="lazy"
                      alt="foto"
                      style={{ width: "50%", objectFit: "cover" }}
                    />
                  )}
                </Row>
                <Row style={{ textAlign: "center", padding: "1%" }}>
                  {datos.actual != null && (
                    <h1>
                      <strong>Bienvenid@ </strong>
                      <br />
                      {datos.actual.persona}
                      <br />
                      {datos.actual.institucion}
                    </h1>
                  )}
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </Row>
      <Row style={{ padding: "2%" }}>
        <Button variant="outline-success" onClick={exportExcelFile}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-file-earmark-excel"
            viewBox="0 0 16 16"
          >
            <path d="M5.884 6.68a.5.5 0 1 0-.768.64L7.349 10l-2.233 2.68a.5.5 0 0 0 .768.64L8 10.781l2.116 2.54a.5.5 0 0 0 .768-.641L8.651 10l2.233-2.68a.5.5 0 0 0-.768-.64L8 9.219l-2.116-2.54z" />
            <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
          </svg>
          &nbsp;&nbsp;&nbsp;&nbsp; Descargar reporte asistencia en excel
        </Button>
      </Row>
      <Row style={{ padding: "2%" }}>
        <Button variant="outline-danger" onClick={emptyAsistencia} disabled>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-trash3"
            viewBox="0 0 16 16"
          >
            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
          </svg>
          &nbsp;&nbsp;&nbsp;&nbsp; Vaciar asistencia
        </Button>
      </Row>
    </Container>
  );
};

export default Recepcion;
