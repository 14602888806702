import React, { useState, useEffect } from 'react';
import { Row, Tab, ListGroup, Col } from "react-bootstrap";
import "./Eventos.css"
import EventoDetalle from './Evento-Detalle/EventoDetalle'
//import DEFAULT_URL from '../../api/url';
const Eventos = (props) => {
    const [eventos, setEventos] = useState([]);
    const [editando, setEditando] = useState(false);
    const [contador, setContador] = useState(0);
    useEffect(async () => {
        if (editando == false) {
            setEventos([]);
            fetch(process.env.REACT_APP_DEFAULT_URL + props.endpoint, { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setEventos(data.rows);
                });
        }
    }, [editando, contador]);

    const eventochange = (evt) => {
        setContador(contador + 1);
        setEditando(true);
        var id = evt.target.name.split("-")[1];
        var name = evt.target.name.split("-")[0];
        var new_arr = eventos;
        const index_finder = (evento) => evento.UniqueID == id;
        switch (name) {
            case "Nombre":
                new_arr[new_arr.findIndex(index_finder)].Nombre = evt.target.value;
                break;
            case "Fecha_Inicio":
                new_arr[new_arr.findIndex(index_finder)].Fecha_Inicia = evt.target.value;
                break;
            case "Hora":
                new_arr[new_arr.findIndex(index_finder)].Hora = evt.target.value;
                break;
            case "Minimo":
                new_arr[new_arr.findIndex(index_finder)].Minimo = evt.target.value;
                break;
            case "Descripcion":
                new_arr[new_arr.findIndex(index_finder)].Descripcion = evt.target.value;
                break;
            case "Ingresos":
                new_arr[new_arr.findIndex(index_finder)].ingresos = evt.target.value;
                break;
            case "Egresos":
                new_arr[new_arr.findIndex(index_finder)].egresos = evt.target.value;
                break;
            case "Resultado":
                new_arr[new_arr.findIndex(index_finder)].resultado = evt.target.value;
                break;
            case "tipo":
                new_arr[new_arr.findIndex(index_finder)].tipo_id = evt.target.value;
                break;
            case "estado":
                new_arr[new_arr.findIndex(index_finder)].estado_id = evt.target.value;
                break;
            case "modalidad":
                new_arr[new_arr.findIndex(index_finder)].modalidad_id = evt.target.value;
                break;
            case "modalidad":
                new_arr[new_arr.findIndex(index_finder)].modalidad_id = evt.target.value;
                break;
            case "dias":
                new_arr[new_arr.findIndex(index_finder)].dias = evt.target.value;
                break;
            case "duracion":
                new_arr[new_arr.findIndex(index_finder)].duracion = evt.target.value;
                break;
            case "sesiones":
                new_arr[new_arr.findIndex(index_finder)].sesiones = evt.target.value;
                break;
            case "precio_agremiados":
                new_arr[new_arr.findIndex(index_finder)].costo_agremiados = evt.target.value;
                break;
            case "precio_no_agremiados":
                new_arr[new_arr.findIndex(index_finder)].costo_no_agremiados = evt.target.value;
                break;
            case "imagen":
                new_arr[new_arr.findIndex(index_finder)].imagen = evt.target.value;
                break;
            case "flier":
                new_arr[new_arr.findIndex(index_finder)].flier = evt.target.value;
                break;
            case "Fecha_Fin":
                new_arr[new_arr.findIndex(index_finder)].Fecha_Finaliza = evt.target.value;
                break;
            default:
                break;
        }
        setEventos(new_arr);
        setContador(contador + 1);
    };
    const edit = (field_name, value) => {
        setEditando(true);
        var id = field_name.split("-")[1];
        var name = field_name.split("-")[0];
        var new_arr = eventos;
        const index_finder = (evento) => evento.UniqueID == id;
        switch (name) {
            case "imagen":
                new_arr[new_arr.findIndex(index_finder)].imagen = value;
                break;
            case "flier":
                new_arr[new_arr.findIndex(index_finder)].flier = value;
                console.log(new_arr[new_arr.findIndex(index_finder)]);
                break;
        }
        setEventos(new_arr);
    }
    const refrescar = () => {
        setEditando(false);
        setContador(contador + 1);
    }
    return (
        <div className="contenedor-eventos">
            <Tab.Container id="list-group-tabs-example">
                <Row>
                    <Col sm={4} className="columna-eventos" >
                        <ListGroup style={{ textAlign: 'left' }}>
                            {eventos.map(evento => <ListGroup.Item className="tab-home cliente" key={evento.UniqueID} action href={"#" + evento.UniqueID}>{evento.Nombre}</ListGroup.Item>)}
                        </ListGroup>
                    </Col>
                    <Col sm={8}>
                        <Tab.Content>
                            {eventos.map(evento => <Tab.Pane key={evento.UniqueID} eventKey={"#" + evento.UniqueID}><EventoDetalle edit={edit} evento={evento} eventochange={eventochange} rol={props.rol} refrescar={refrescar} /></Tab.Pane>)}
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </div>
    );
}
export default Eventos;