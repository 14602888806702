import React, { useState, useEffect } from 'react'
import { SuccessModal } from '../../../../Utils/SuccessModal/SuccessModal';
import { ErrorModal } from '../../../../Utils/ErrorModal/ErrorModal';
import { ModalCargando } from '../../../../Utils/Modal-Cargando/ModalCargando';
//import DEFAULT_URL from '../../../../../api/url';
import { Modal, Alert, Row, Button, Form, Container } from "react-bootstrap";
import './common.css'
const EditarRespuesta = (props) => {
    const [modalError, setModalError] = useState(false);
    const [mensaje, setMensaje] = useState('');
    const [modalCargando, setModalCargando] = useState(false);
    const [mensajeCargando, setMensajeCargando] = useState('');
    const [modalSucc, setModalSucc] = useState(false);
    const [contador, setContador] = useState(0);
    const [preguntas, setPreguntas] = useState([]);
    const [respuesta, setRespuesta] = useState([]);
    const [asesores, setAsesores] = useState([]);
    useEffect(async () => {
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/asesor', { cache: "no-cache" })
            .then((res) => {
                return res.json();
            })
            .then(data => {
                setAsesores(data.rows);
            });
    }, [contador]);
    const ocultar = () => {
        setModalError(false);
        setModalSucc(false);
        setContador(contador + 1);
        props.onHide();
    }
    const ocultarModalCargando = () => {

    }
    const submit = (evt) => {
        evt.preventDefault();
        const info = { respuesta: respuesta };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        setMensajeCargando("Almacenando información, por favor espere...")
        setModalCargando(true);
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/submit/formulario/update', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                if (data.codigo == 200) {
                    setModalCargando(false);
                    setMensaje('Respuestas almacenadas exitosamente');
                    setModalSucc(true);
                } else {
                    setMensaje(data.respuesta);
                    setModalError(true);
                }
            });
    }
    const hideSuccess = () => {
        setModalSucc(false);
        setContador(contador + 1);
        props.onHide();
    }
    const onChange = (evt) => {
        const index_finder = (respuesta) => respuesta.pregunta_UniqueID == evt.target.name;
        let submision = respuesta;
        submision.respuestas[submision.respuestas.findIndex(index_finder)].texto = evt.target.value;
        setRespuesta(submision);

        setContador(contador + 1);
    }
    const entering = () => {
        setPreguntas(props.preguntas);
        setRespuesta(props.respuesta);
        console.log(props.respuesta);
        setContador(contador + 1);
    }
    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
            style={{ color: 'black' }}
            onEnter={entering}
        >
            <Modal.Header closeButton style={{ backgroundColor: "#617785" }}>
                <Modal.Title id="contained-modal-title-vcenter" className="font-bold">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-pencil-fill" viewBox="0 0 16 16">
                        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                    </svg>
                    &nbsp;&nbsp;&nbsp;Editar respuestas
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: "#617785", height: '80vh' }} className="cuerpo-modal-nuevo-cliente">
                <Form onSubmit={submit}>
                    <Container style={{ padding: '2%' }}>
                        {(respuesta != null && respuesta.enlazado == 1) &&
                            <Alert variant="danger">
                                <Alert.Heading>Respuesta asociada a inscripción</Alert.Heading>
                                <p>
                                    Cualquier cambio en este registro se verá reflejado en los reportes de inscripción de gerencia
                                </p>
                                <hr />
                                <p className="mb-0">
                                    Cualquier acción con este registro afecta la integridad de la información
                                </p>
                            </Alert>
                        }
                        {respuesta != null &&
                            preguntas.map((pregunta, index) =>
                                <Row style={{ backgroundColor: 'white', borderRadius: '15px', padding: '10px', marginBottom: '25px', color: 'black' }} key={pregunta.UniqueID}>
                                    {pregunta.tipo_pregunta_UniqueID == '1' &&
                                        <Form.Group className="mb-3" controlId={pregunta.UniqueID}>
                                            <Form.Label>{pregunta.pregunta}</Form.Label>
                                            <Form.Control type="text" required placeholder="Ingrese la pregunta " name={pregunta.UniqueID} style={{ backgroundColor: '#617785', color: 'white' }} onChange={onChange} value={respuesta.respuestas[index].texto} />
                                        </Form.Group>
                                    }
                                    {pregunta.tipo_pregunta_UniqueID == '2' &&
                                        <Form.Group className="mb-3" controlId={pregunta.UniqueID}>
                                            <Form.Label>{pregunta.pregunta}</Form.Label>
                                            <Form.Control type="text" required placeholder="Ingrese la pregunta " name={pregunta.UniqueID} style={{ backgroundColor: '#617785', color: 'white' }} onChange={onChange} value={respuesta.respuestas[index].texto} />
                                        </Form.Group>
                                    }
                                    {pregunta.tipo_pregunta_UniqueID == '3' &&
                                        <Form.Group className="mb-3" controlId={pregunta.UniqueID}>
                                            <Form.Label>{pregunta.pregunta}</Form.Label>
                                            <Form.Control as="select" required aria-label="Asesor" name={pregunta.UniqueID} style={{ backgroundColor: '#617785', color: 'white' }} onChange={onChange} value={respuesta.respuestas[index].texto}>
                                                <option value="" key="0">Seleccione el asesor</option>
                                                {asesores.map(asesor => <option key={asesor.UniqueID} value={asesor.Nombres + " " + asesor.Apellidos}>{asesor.Nombres + " " + asesor.Apellidos}</option>)}
                                            </Form.Control>
                                        </Form.Group>
                                    }
                                </Row>
                            )
                        }
                        <Row>
                            <Button variant="light" className="font-bold" type="submit">Guardar</Button>
                        </Row>
                    </Container>
                </Form>
            </Modal.Body>
            <ErrorModal
                color={"#b5bad0"}
                show={modalError}
                onHide={ocultar}
                mensaje={mensaje}
                consideracion={''}
            />
            <ModalCargando
                color={"#b5bad0"}
                show={modalCargando}
                onHide={ocultarModalCargando}
                mensaje={mensajeCargando}
            />
            <SuccessModal
                color={"#b5bad0"}
                show={modalSucc}
                onHide={ocultar}
                mensaje={mensaje}
                consideracion={''}
                enlace={''}
                llevaenlace={false}
            />
        </Modal >
    )
}

export default EditarRespuesta