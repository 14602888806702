import React, { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import Formulario from "../../helpers/form";
import ActionButton from "../../helpers/Action";
const helpers = require("../../helpers/helpers");
const DashboardFormularios = () => {
  const [formConfig, setFormConfig] = useState({});
  const [contador] = useState(0);
  const [url, setUrl] = useState("");
  const [cambios, setCambios] = useState(0);
  useEffect(() => {
    async function fetchData() {
      helpers
        .getData("/api/read/evento/modalidades")
        .then((modalidades_info) => {
          setFormConfig({
            title: "REPORTERÍA FORMULARIOS - ENCUESTAS DE SATISFACCIÓN",
            submitTitle: "Generar reporte",
            customSubmit: true,
            formConfig: [
              {
                name: "fecha_inicio",
                type: 5,
                required: true,
                title: "Fecha inicio de reporte",
                placeholder: "Ingrese la fecha de inicio del reporte",
                value: "",
              },
              {
                name: "fecha_fin",
                type: 5,
                required: true,
                title: "Fecha fin de reporte",
                placeholder: "Ingrese la fecha de fin del reporte",
                value: "",
              },
              {
                name: "tipo",
                type: 3,
                options: modalidades_info.data.rows.map((actual) => {
                  return {
                    label: actual.Nombre,
                    value: actual.UniqueID,
                  };
                }),
                required: true,
                title: "Modalidad del evento",
                placeholder: "Seleccione la modalidad del evento",
                value: "",
              },
            ],
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
    fetchData();
  }, [contador]);
  useEffect(() => {}, [cambios]);
  const afterSubmit = (formConfig) => {
    return new Promise((resolve, reject) => {
      let body = {};
      formConfig.formConfig.map((actual, indice) => {
        body[actual.name] = actual.value;
        if (indice == formConfig.formConfig.length - 1) {
          helpers
            .getData(
              `/api/formulario/export/encuestas-satisfaccion/time-period-pdf/${body.fecha_inicio}/${body.fecha_fin}/${body.tipo}`
            )
            .then((data) => {
              if (data.data.status) {
                setUrl(data.data.enlace_S3);
                setCambios(cambios+1);
                resolve(data);
              } else {
                // window.location.reload();
              }
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    });
  };
  return (
    <Container
      style={{
        padding: "5%",
        border: "0.5px dotted black",
        borderRadius: "1%",
      }}
    >
      <Row>
        <Container>
          <Row>
            <Formulario formConfig={formConfig} afterSubmit={afterSubmit} />
          </Row>
          <Row className="mt-5 p-5">
            <ActionButton
              configuration={{
                type: 6,
                url: url,
                icon: "eye",
                variant: "outline-success",
                title: "Ver reporte",
                disabled: url == "",
              }}
            />
          </Row>
        </Container>
      </Row>
    </Container>
  );
};

export default DashboardFormularios;
