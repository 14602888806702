import React, { useEffect, useState } from 'react';
import { Modal, Row, Button, Col, Form, Container } from "react-bootstrap";
import axios from 'axios';
import { ErrorModal } from '../../../Utils/ErrorModal/ErrorModal';
import { SuccessModal } from '../../../Utils/SuccessModal/SuccessModal';
//import DEFAULT_URL from '../../../../api/url';

const ModalSubirNotas = (props) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [modalSucc, setModalSucc] = useState(false);
    const [modalError, setModalError] = useState(false);
    const [mensaje, setMensaje] = useState('');
    const [consideracion, setConsideracion] = useState('');
    const [contador, setContador] = useState(0);
    useEffect(() => {

    }, [contador]);

    const onChangeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setContador(contador + 1);
    };
    const submitForm = (evt) => {
        evt.preventDefault();
        const data = new FormData();
        data.append('file', selectedFile);
        axios.post(process.env.REACT_APP_DEFAULT_URL+ "/api/upload/registro_academico/notas", data, {})
            .then(res => {
                if (res.status == 200) {
                    const info = { path: res.data.res, actividad_UniqueID: props.actividad };
                    axios.post(process.env.REACT_APP_DEFAULT_URL+ "/api/upload/registro_academico/notas/procesar", info, {})
                        .then(resultado => {
                            if (resultado.status == 200) {
                                props.onHide();
                                if (resultado.data.errores.length > 0) {
                                    setMensaje('Revisar el formato del archivo subido');
                                    setModalError(true);
                                } else {
                                    setMensaje('Notas Cargadas Al Sistema Exitosamente');
                                    setConsideracion('Por favor validar la información ingresada.');
                                    setModalSucc(true);
                                }
                            } else {
                                setMensaje(resultado.data);
                                setModalError(true);
                            }
                        });
                } else {
                    setMensaje(res.data);
                    setModalError(true);
                }
            });
    }
    const ocultar = () => {
        setModalError(false);
        setModalSucc(false);
        setContador(contador + 1);
        props.refrescar();
    }
    return (
        <Container>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton style={{ backgroundColor: "#b5bad0" }}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Carga de Notas
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: "#b5bad0" }}>
                    <Container>
                        <Form onSubmit={submitForm}>
                            <Row>
                                <Form.Group controlId="formFileMultiple" className="mb-3">
                                    <Form.Label>Seleccione el archivo con las notas</Form.Label>
                                    <Form.Control required type="file" onChange={onChangeHandler} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Col>
                                    <Button variant="secondary" className="font-bold" type="submit">Subir Notas</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Container>
                </Modal.Body>
                <Modal.Footer style={{ backgroundColor: "#b5bad0" }}>
                    <Button variant="secondary" className="font-bold" onClick={props.onHide}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
            <SuccessModal
                color={"#b5bad0"}
                show={modalSucc}
                onHide={ocultar}
                mensaje={mensaje}
                consideracion={consideracion}
                enlace={''}
                llevaenlace={false}
            />
            <ErrorModal
                color={"#b5bad0"}
                show={modalError}
                onHide={ocultar}
                mensaje={mensaje}
                consideracion={consideracion}
            />
        </Container>

    );
}

export default ModalSubirNotas
/*
export class ModalSubirNotas extends Component {
    state = { selectedFile: null, loaded: 0, Nombre: '', modalSucc: false, modalError: false, Mensaje: '', pathactual: '', Consideracion: '', enlace: '', id_actual: '', llevaenlace: false, materiales: [], mensajeConfirmacion: false };
    onChangeHandler = (event) => {
        this.setState({ selectedFile: event.target.files[0], loaded: 0 });
    };

    submitForm = (evt) => {
        const data = new FormData();
        data.append('file', this.state.selectedFile);
        axios.post(process.env.REACT_APP_DEFAULT_URL+ "/api/upload/registro_academico/notas", data, {})
            .then(res => {
                if (res.status == 200) {
                    const info = { path: res.data.res, actividad_UniqueID: this.props.actividad };
                    axios.post(process.env.REACT_APP_DEFAULT_URL+ "/api/upload/registro_academico/notas/procesar", info, {})
                        .then(resultado => {
                            if (resultado.status == 200) {
                                this.props.onHide();
                                if (resultado.data.errores.length > 0) {
                                    this.setState({ Mensaje: 'Revisar el formato del archivo subido' });
                                    this.setState({ modalError: true });
                                } else {
                                    this.setState({ Mensaje: 'Notas Cargadas Al Sistema Exitosamente' });
                                    this.setState({ Consideracion: 'Para validar las notas cargadas, por favor recargue la página.' });
                                    this.setState({ modalSucc: true });
                                }
                            } else {
                                this.setState({ Mensaje: resultado.data });
                                this.setState({ modalError: true });
                            }
                        });
                } else {
                    this.setState({ Mensaje: res.data });
                    this.setState({ modalError: true });
                }
            });
        evt.target.reset();
    }
    ocultar = () => {
        this.setState({ modalError: false, modalSucc: false });
    }

    ocultarConfirmacion = () => {
        this.setState({ mensajeConfirmacion: false });
    }
    render() {
        return (
            <Container>
                <Modal
                    {...this.props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton style={{ backgroundColor: "#b5bad0" }}>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Carga de Notas
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ backgroundColor: "#b5bad0" }}>
                        <Container>
                            <Form onSubmit={this.submitForm}>
                                <Row>
                                    <Form.Group controlId="formFileMultiple" className="mb-3">
                                        <Form.Label>Seleccione el archivo con las notas</Form.Label>
                                        <Form.Control required type="file" onChange={this.onChangeHandler} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Col>
                                        <Button variant="secondary" className="font-bold" type="submit">Subir Notas</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer style={{ backgroundColor: "#b5bad0" }}>
                        <Button variant="secondary" className="font-bold" onClick={this.props.onHide}>Cerrar</Button>
                    </Modal.Footer>
                </Modal>
                <SuccessModal
                    color={"#b5bad0"}
                    show={this.state.modalSucc}
                    onHide={this.ocultar}
                    mensaje={this.state.Mensaje}
                    consideracion={this.state.Consideracion}
                    enlace={''}
                    llevaenlace={false}
                />
                <ErrorModal
                    color={"#b5bad0"}
                    show={this.state.modalError}
                    onHide={this.ocultar}
                    mensaje={this.state.Mensaje}
                    consideracion={this.state.Consideracion}
                />
            </Container>

        );
    }
}
*/