import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Card, Tab, Tabs, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom'
//import DEFAULT_URL from '../../api/url';
import NuevaIntervencion from './view-lead/NuevaIntervencion';
import Intervenciones from './view-lead/Intervenciones';
const View = () => {
  const [contador] = useState(0);
  const [lead, setLead] = useState({});
  const { id } = useParams();
  useEffect(() => {
    async function fetchData() {
      fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/lead/' + id, { cache: "no-cache", credentials: 'include' })
        .then((res) => {
          return res.json();
        })
        .then(data => {
          setLead(data.rows[0]);
        });
    }
    fetchData();
  }, [contador]);
  const cancelar = () => {
    const info = { UniqueID: id };
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(info)
    };
    fetch(process.env.REACT_APP_DEFAULT_URL + '/api/update/leads/cancelar', requestOptions)
      .then((res) => {
        return res.json();
      })
      .then(data => {
        if (data.codigo == 200) {
          window.location.replace(process.env.REACT_APP_SELF_URL + '/crm/seguimiento');
        } else {
          window.location.reload();
        }
      });
  }
  return (
    <Container fluid>
      <Row style={{ padding: '5%' }}>
        <Container>
          <Row style={{ padding: '5%', borderBottom: '5px solid #1e3d52', borderLeft: '5px solid #1e3d52', backgroundColor: 'rgb(230,230,230)' }}>
            <Col xs={12} md={6} lg={4}>
              <Card style={{ width: '100%' }}>
                <Card.Header>
                  {lead != null && <Card.Title>id: {lead.UniqueID}</Card.Title>}
                  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="currentColor" className="bi bi-file-earmark-person-fill" viewBox="0 0 16 16">
                    <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0zm2 5.755V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-.245S4 12 8 12s5 1.755 5 1.755z" />
                  </svg>
                </Card.Header>
              </Card>
            </Col>
            <Col xs={12} md={6} lg={8} style={{ textAlign: 'left' }}>
              <h1>Seguimiento de lead</h1>
              {lead != null && <p>
                INFORMACIÓN
                <br />
                <strong>Fecha de ingreso: </strong>{lead.fecha}
                <br />
                <strong>Nombre: </strong>{lead.nombre}
                <br />
                <strong>Puesto: </strong>{lead.puesto}
                <br />
                <strong>Institución: </strong>{lead.institucion}
                <br />
                <strong>Email: </strong>{lead.correo}
                <br />
                <strong>Teléfono: </strong>{lead.telefono}
                <br />
                <strong>Origen de lead: </strong>{lead.origen}
                <br />
                <strong>Evento de interés: </strong>{lead.evento}
                <br />
                <strong>Asesor que ingresó lead: </strong>{lead.asesor}
              </p>}
              <Container>
                <Row>
                  <Col xs={12} md={12} lg={4} style={{ padding: '2%' }}>
                    <Row>
                      <Button variant='outline-success' href={process.env.REACT_APP_EBG_URL + '/lead/inscripciones/' + lead.hash_evt+'/'+lead.UniqueID}>Inscribir</Button>
                    </Row>
                  </Col>
                  <Col xs={12} md={12} lg={4} style={{ padding: '2%' }}>
                    <Row>
                      <Button variant='outline-primary' href={process.env.REACT_APP_SELF_URL + '/crm/seguimiento/edit/' + id}>Editar información</Button>
                    </Row>
                  </Col>
                  <Col xs={12} md={12} lg={4} style={{ padding: '2%' }}>
                    <Row>
                      <Button variant='outline-danger' onClick={cancelar}>Descontinuar lead</Button>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </Row>
      <Row style={{ padding: '5%' }}>
        <Tabs
          defaultActiveKey="intervenciones"
        >
          <Tab eventKey="intervenciones" title="Intervenciones">
            <Intervenciones lead={id} />
          </Tab>
          <Tab eventKey="nueva-intervencion" title="Nueva intervención">
            <NuevaIntervencion lead={id} />
          </Tab>
        </Tabs>
      </Row>
    </Container>
  )
}

export default View