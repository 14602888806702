import React, { useEffect, useState } from "react";
import { Container, Row, Col, Table, Spinner, Button } from "react-bootstrap";
import ActionButton from "./Action";
import Img from "./Img";
import DOMPurify from "dompurify";
const helpers = require("./helpers");
const View = (props) => {
  const [contador] = useState(0);
  const [view, setView] = useState({});
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    function fetchData() {
      helpers
        .getDataAuth(props.configuration.endpoint)
        .then((data) => {
          if (
            data.data.rows[1] != null &&
            data.data.rows[1].serverStatus != null
          ) {
            setView(data.data.rows[0][0]);
          } else {
            setView(data.data.rows[0]);
          }
          setLoaded(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchData();
  }, [contador]);
 
  return !loaded ? (
    <Container
      style={{ width: "100vw", height: "100vh" }}
      className="d-flex justify-content-center align-items-center"
    >
      <Spinner animation="border" role="status" />
    </Container>
  ) : (
    <Container fluid>
      {view[props.configuration.key] && (
        <Row style={{ padding: "2%" }}>
          <h1>
            {
              props.configuration
                .name /*+ ": " + view[props.configuration.key]*/
            }
          </h1>
        </Row>
      )}
      {props.configuration.withActions && view[props.configuration.key] && (
        <Row style={{ padding: "2%" }}>
          {props.configuration.onUpdate && (
            <Col className="mt-3" xs={12} md={6} lg={4}>
              <ActionButton
                configuration={{
                  type: 1,
                  url: props.configuration.onUpdate,
                  icon: "pencil",
                  variant: "outline-warning",
                  title: "Editar información",
                }}
              />
            </Col>
          )}
          {props.configuration.onDelete && (
            <Col className="mt-3" xs={12} md={6} lg={4}>
              <ActionButton
                configuration={{
                  type: 2,
                  url: props.configuration.onDelete,
                  icon: "trash",
                  variant: "outline-danger",
                  mensaje: props.configuration.confirmationMensage,
                  title: "Eliminar registro",
                  lock: props.configuration.deleteLock,
                }}
                action={props.actionAfterDelete}
              />
            </Col>
          )}
          {props.configuration.extraActions &&
            props.configuration.extraActions.map((actual, indice) => (
              <Col
                className="mt-3"
                xs={12}
                md={6}
                lg={4}
                key={"accion-" + indice}
              >
                <ActionButton
                  configuration={actual.configuration}
                  action={actual.action}
                />
              </Col>
            ))}
        </Row>
      )}
      {view[props.configuration.key] && (
        <Row style={{ padding: "2%" }}>
          <Table
            striped
            bordered
            hover
            variant="light"
            style={{ textAlign: "center" }}
          >
            <tbody>
              {props.configuration.properties &&
                props.configuration.properties.map((actual, index) => (
                  <tr key={"propiedad-" + index}>
                    <td>{actual.label}</td>
                    {actual.type == 1 && <td>{view[actual.field]}</td>}
                    {actual.type == 2 && (
                      <td>
                        <Img
                          aws_key={view[actual.field]}
                          style={{ width: "20%", pointerEvents: "none" }}
                        />
                      </td>
                    )}
                    {actual.type == 3 && (
                      <td>
                        <a
                          href={view[actual.field]}
                          target="_blank"
                          className="mx-auto"
                        >
                          Click para ir a url
                        </a>
                      </td>
                    )}
              
                    {actual.type == 5 && (
                      <td>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(view[actual.field]),
                          }}
                        ></div>
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </Table>
        </Row>
      )}
    </Container>
  );
};

export default View;
