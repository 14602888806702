import React, { useState, useEffect } from 'react'
import {  Row, Button, Col, Form,  Container } from "react-bootstrap";
import "../Login.css"
//import DEFAULT_URL from '../../../api/url';
import ModalFoto from './ModalFoto';
import { SuccessModal } from '../../Utils/SuccessModal/SuccessModal';
import { ErrorModal } from '../../Utils/ErrorModal/ErrorModal';
import { ModalCargando } from '../../Utils/Modal-Cargando/ModalCargando';
import useLogout from '../../../hooks/useLogout';
const Actualizar = () => {
  const [contador, setContador] = useState(0);
  const [nombres, setNombres] = useState('');
  const [apellidos, setApellidos] = useState('');
  const [foto, setFoto] = useState('');
  const [uniqueID, setUniqueID] = useState('');
  const [email, setEmail] = useState('');
  const [modalFoto, setModalFoto] = useState(false);
  const [modalSucc, setModalSucc] = useState(false);
  const [mensaje, setMensaje] = useState('');
  const [consideracion, setConsideracion] = useState('');
  const [modalError, setModalError] = useState(false);
  const [modalCargando, setModalCargando] = useState(false);
  const [puesto, setPuesto] = useState('');
  const [whatsApp, setWhatsApp] = useState('');
  const [correoInstitucion, setCorreoInstitucion] = useState('');
  const [institucion, setInstitucion] = useState('');
  const [telefonoInstitucion, setTelefonoInstitucion] = useState('');
  const logout = useLogout();
  useEffect(() => {
    async function fetchData() {
      fetch(process.env.REACT_APP_DEFAULT_URL + '/api/auth/userinfo' , { cache: "no-cache", credentials: 'include' })
        .then((res) => {
          return res.json();
        })
        .then(data => {
          setNombres(data.nombres);
          setApellidos(data.apellidos);
          setFoto(data.foto);
          setUniqueID(data.UniqueID);
          setEmail(data.email);
          setPuesto(data.puesto);
          setWhatsApp(data.whatsapp);
          setCorreoInstitucion(data.correo_institucion);
          setInstitucion(data.institucion);
          setTelefonoInstitucion(data.telefono_institucion);
        });
    }
    fetchData();
  }, [contador]);
  const singOut = async () => {
    await logout();
    window.location.replace('/')
  }
  const ocultar = () => {
    setModalSucc(false);
    setModalError(false);
    singOut();
  }
  const ocultarModalCargando = () => {
    setModalCargando(false);
  }
  const asignarFoto = (link) => {
    console.log(link);
    setFoto(link);
  }
  const hideModalFoto = () => {
    setModalFoto(false);
  }
  const onChange = (evt) => {
    switch (evt.target.name) {
      case "nombres":
        setNombres(evt.target.value);
        break;
      case "apellidos":
        setApellidos(evt.target.value);
        break;
      case "puesto":
        setPuesto(evt.target.value);
        break;
      case "whatsapp":
        setWhatsApp(evt.target.value);
        break;
      case "correo_institucion":
        setCorreoInstitucion(evt.target.value);
        break;
      case "institucion":
        setInstitucion(evt.target.value);
        break;
      case "telefono_institucion":
        setTelefonoInstitucion(evt.target.value);
        break;
    }
  }
  const submit = (evt) => {
    evt.preventDefault();
    setMensaje('Guardando información');
    setConsideracion('Por favor espere...');
    setModalCargando(true);
    const info = { nombres: nombres, apellidos: apellidos, foto: foto, email: email, UniqueID: uniqueID, puesto: puesto, whatsapp: whatsApp, correo_institucion: correoInstitucion, institucion: institucion, telefono_institucion: telefonoInstitucion };
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(info)
    };
    fetch(process.env.REACT_APP_DEFAULT_URL + '/api/update/asesor/no-pass', requestOptions)
      .then((res) => {
        return res.json();
      })
      .then(data => {
        if (data.codigo == 200) {
          setModalSucc(true);
          setMensaje('Información ingresada al sistema exitosamente');
        } else {
          setModalError(true);
          setMensaje('ERROR');
        }
      });
  }
  return (
    <div className="contenedor-login">
      <Container>
        <Row style={{ height: '100vh' }}>
          <Col xs={12} md={12} lg={6}></Col>
          <Col xs={12} md={12} lg={6} className="col-formulario">
            <div className="container-form">
              <div style={{ right: '55%', position: 'relative', cursor: 'pointer' }} data-toggle="tooltip" data-placement="top" title="Ir a inicio" onClick={() => window.location.replace('/')}>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-house-fill" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6zm5-.793V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z" />
                  <path fillRule="evenodd" d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z" />
                </svg>
              </div>
              <h1 style={{ fontFamily: 'Montserrat, sans-serif' }}>Bienvenid@</h1>
              <h5>{nombres + ' ' + apellidos}</h5>
              <h6>Por favor, confirme su información</h6>
              <div style={{ marginTop: '10%' }}>
                <Form onSubmit={submit}>
                  <Container>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="nombres">
                          <Form.Label>Nombres</Form.Label>
                          <Form.Control required type="text" name="nombres" placeholder="Ingrese sus nombres" value={nombres} onChange={onChange} />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="apellidos">
                          <Form.Label>Apellidos</Form.Label>
                          <Form.Control required type="text" name="apellidos" placeholder="Ingrese sus apellidos" value={apellidos} onChange={onChange} />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="puesto">
                          <Form.Label>Puesto</Form.Label>
                          <Form.Control required type="text" name="puesto" placeholder="Ingrese su puesto" value={puesto} onChange={onChange} />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="whatsapp">
                          <Form.Label>WhatsApp</Form.Label>
                          <Form.Control required type="text" name="whatsapp" placeholder="Ingrese su numero de whatsapp" value={whatsApp} onChange={onChange} />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="correo_institucion">
                          <Form.Label>Email secundario</Form.Label>
                          <Form.Control required type="text" name="correo_institucion" placeholder="Ingrese su email secunadrio" value={correoInstitucion} onChange={onChange} />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="institucion">
                          <Form.Label>Institución</Form.Label>
                          <Form.Control required type="text" name="institucion" placeholder="Ingrese la institución " value={institucion} onChange={onChange} />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="telefono_institucion">
                          <Form.Label>Teléfono de institución</Form.Label>
                          <Form.Control required type="text" name="telefono_institucion" placeholder="Ingrese el teléfono de su institución" value={telefonoInstitucion} onChange={onChange} />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row >
                      <h4>Foto de perfil</h4>
                    </Row>
                    <Row style={{ display: 'grid', placeItems: 'center' }}>
                      <img src={foto} style={{ width: '85%', border: '2px solid black', padding: '10%', backgroundColor: '#1e3d52' }} />
                    </Row>
                    <Row style={{ display: 'grid', marginTop: '10%', placeItems: 'center' }}>
                      <Button variant="secondary" className="font-bold" style={{ backgroundColor: '#1e3d52', width: '50%' }} onClick={() => setModalFoto(true)}>Subir Foto</Button>
                    </Row>
                    <Row style={{ marginTop: '10%' }}>
                      <Button variant="secondary" className="font-bold" type="submit" >Confirmar Información</Button>
                    </Row>
                  </Container>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
        <ModalFoto show={modalFoto} onHide={hideModalFoto} asignar={asignarFoto} />
        <SuccessModal
          color={"#b5bad0"}
          show={modalSucc}
          onHide={ocultar}
          mensaje={mensaje}
          consideracion={consideracion}
          enlace={''}
          llevaenlace={false}
        />
        <ErrorModal
          color={"#b5bad0"}
          show={modalError}
          onHide={ocultar}
          mensaje={mensaje}
          consideracion={consideracion}
        />
        <ModalCargando
          color={"#b5bad0"}
          show={modalCargando}
          onHide={ocultarModalCargando}
          mensaje={''}
        />
      </Container>
    </div>
  )
}

export default Actualizar