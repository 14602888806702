import axios from 'axios';
//import DEFAULT_URL from './url';


export default axios.create({
    baseURL: process.env.REACT_APP_DEFAULT_URL
});

export const axiosPrivate = axios.create({
    baseURL: process.env.REACT_APP_DEFAULT_URL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
});