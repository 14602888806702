import React, { Component } from 'react'
import './unauthorized.css'
export default class Unauthorized extends Component {
  render() {
    return (
      <div className="contenedor-no-autorizado">
        <h1 className = 'aviso-titulo'>Acceso temporalmente restringido</h1>
      </div>
    )
  }
}
