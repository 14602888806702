import React from 'react';
import { Row, Container, Table } from "react-bootstrap";
import Evento from './Evento';
import './Gerencia.css'
const TipoEvento = (props) => {

    return (
        <Row style={{ marginTop: '50px' }}>
            <Container>
                <Row style={{ color: 'black', marginBottom: '10px', textAlign: 'left' }}>
                    <h1 >{props.eventos.tipo}</h1>
                </Row>
                <Row className='fila-tabla-reporte'>
                    <div style={{ minWidth: '3150px', height: '300px' }}>
                        <Table striped bordered hover variant="light" style={{ borderCollapse: 'collapse', minWidth: '4150px' }}>
                            <thead>
                                <tr>
                                    <th style={{ position: 'sticky', top: '0' }} colSpan={2}>Evento</th>
                                    <th style={{ position: 'sticky', top: '0' }} colSpan={13}>Ejecutado</th>
                                    <th style={{ position: 'sticky', top: '0' }} colSpan={4}>Proyectado</th>
                                    <th style={{ position: 'sticky', top: '0' }} colSpan={5}>Detalles</th>
                                </tr>
                                <tr>
                                    <th style={{ position: 'sticky', top: '0' }}>Modalidad del evento</th>
                                    <th style={{ position: 'sticky', top: '0' }}>Nombre del evento</th>
                                    <th style={{ position: 'sticky', top: '0' }}>Fecha</th>
                                    <th style={{ position: 'sticky', top: '0' }}>Ingresos <br />(inscripciones confirmadas)</th>
                                    <th style={{ position: 'sticky', top: '0' }}>Ingresos <br />(inscripciones no confirmadas)</th>
                                    <th style={{ position: 'sticky', top: '0' }}>Ingresos <br />(inscripciones total)</th>
                                    <th style={{ position: 'sticky', top: '0' }}>Ingresos (patrocinios)</th>
                                    <th style={{ position: 'sticky', top: '0' }}>Egresos</th>
                                    <th style={{ position: 'sticky', top: '0' }}>Resultado (ingresos confirmados)</th>
                                    <th style={{ position: 'sticky', top: '0' }}>Resultado (ingresos no confirmados)</th>
                                    <th style={{ position: 'sticky', top: '0' }}>Cortesías</th>
                                    <th style={{ position: 'sticky', top: '0' }}>Pagados</th>
                                    <th style={{ position: 'sticky', top: '0' }}>Sin validación de contabilidad</th>
                                    <th style={{ position: 'sticky', top: '0' }}>Total inscritos</th>
                                    <th style={{ position: 'sticky', top: '0' }}>Ejecutado EBC (Inscritos)</th>
                                    <th style={{ position: 'sticky', top: '0' }}>Ejecutado EBG (Inscritos)</th>
                                    <th style={{ position: 'sticky', top: '0' }}>Ejecutado EBC (Ingresos)</th>
                                    <th style={{ position: 'sticky', top: '0' }}>Ejecutado EBG (Ingresos)</th>
                                    <th style={{ position: 'sticky', top: '0' }}>Ingresos Proyectados</th>
                                    <th style={{ position: 'sticky', top: '0' }}>Egresos Proyectados</th>
                                    <th style={{ position: 'sticky', top: '0' }}>Resultado Proyectado</th>
                                    <th style={{ position: 'sticky', top: '0' }}>Mínimo participantes</th>
                                    <th style={{ position: 'sticky', top: '0' }}>Ventas</th>
                                    <th style={{ position: 'sticky', top: '0' }}>Inscripciones</th>
                                    <th style={{ position: 'sticky', top: '0' }}>Patrocinios</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.eventos.eventos.map((evento) => <Evento key={evento.UniqueID} evento={evento} />)}
                                <tr>
                                    <td><strong>TOTALES</strong></td>
                                    {props.eventos.eventos.length === 1 && <td><strong>1 evento</strong></td>}
                                    {props.eventos.eventos.length > 1 && <td><strong>{props.eventos.eventos.length} eventos</strong></td>}
                                    <td></td>
                                    <td><strong>Q {(props.eventos.eventos.reduce((accumulator, object) => {
                                        return accumulator + object['IngresosNumber'];
                                    }, 0).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</strong></td>
                                    <td><strong>Q {(props.eventos.eventos.reduce((accumulator, object) => {
                                        return accumulator + object['IngresosNoConfirmadosNumber'];
                                    }, 0).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</strong></td>
                                    <td><strong>Q {(props.eventos.eventos.reduce((accumulator, object) => {
                                        return accumulator + object['IngresosTotalesNumber'];
                                    }, 0).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</strong></td>
                                    <td><strong>Q {(props.eventos.eventos.reduce((accumulator, object) => {
                                        return accumulator + object['PatrociniosNumber'];
                                    }, 0).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</strong></td>
                                    <td><strong>Q {(props.eventos.eventos.reduce((accumulator, object) => {
                                        return accumulator + object['EgresosNumber'];
                                    }, 0).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</strong></td>
                                    <td><strong>Q {(props.eventos.eventos.reduce((accumulator, object) => {
                                        return accumulator + object['ResultadoNumber'];
                                    }, 0).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</strong></td>
                                    <td><strong>Q {(props.eventos.eventos.reduce((accumulator, object) => {
                                        return accumulator + object['ResultadoNoConfirmadoNumber'];
                                    }, 0).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</strong></td>
                                    <td><strong>{(props.eventos.eventos.reduce((accumulator, object) => {
                                        return accumulator + object['Cortesías'];
                                    }, 0))}</strong></td>
                                    <td><strong>{(props.eventos.eventos.reduce((accumulator, object) => {
                                        return accumulator + object['Pagados'];
                                    }, 0))}</strong></td>
                                    <td><strong>{(props.eventos.eventos.reduce((accumulator, object) => {
                                        return accumulator + object['Sin validación de contabilidad'];
                                    }, 0))}</strong></td>
                                    <td><strong>{(props.eventos.eventos.reduce((accumulator, object) => {
                                        return accumulator + object['Total Inscritos'];
                                    }, 0))}</strong></td>
                                    <td><strong>{(props.eventos.eventos.reduce((accumulator, object) => {
                                        return accumulator + object['Ejecutado EBC (Inscritos)'];
                                    }, 0))}</strong></td>
                                    <td><strong>{(props.eventos.eventos.reduce((accumulator, object) => {
                                        return accumulator + object['Ejecutado EBG (Inscritos)'];
                                    }, 0))}</strong></td>
                                    <td><strong>Q {(props.eventos.eventos.reduce((accumulator, object) => {
                                        return accumulator + object['EjecutadoIngresosEBC'];
                                    }, 0).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</strong></td>
                                    <td><strong>Q {(props.eventos.eventos.reduce((accumulator, object) => {
                                        return accumulator + object['EjecutadoIngresosEBG'];
                                    }, 0).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</strong></td>
                                    <td><strong>Q {(props.eventos.eventos.reduce((accumulator, object) => {
                                        return accumulator + object['IngresosProyectados'];
                                    }, 0).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</strong></td>
                                    <td><strong>Q {(props.eventos.eventos.reduce((accumulator, object) => {
                                        return accumulator + object['EgresosProyectados'];
                                    }, 0).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</strong></td>
                                    <td><strong>Q {(props.eventos.eventos.reduce((accumulator, object) => {
                                        return accumulator + object['ResultadoProyectado'];
                                    }, 0).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</strong></td>
                                    <td><strong>{(props.eventos.eventos.reduce((accumulator, object) => {
                                        return accumulator + object['Mínimo de participantes'];
                                    }, 0))}</strong></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>

                            </tbody>
                        </Table>
                    </div>
                </Row>
            </Container>
        </Row>)
}

export default TipoEvento