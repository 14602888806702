import React, { useEffect, useState } from 'react';
//import DEFAULT_URL from '../../../../../api/url';
import CargaMasiva from './CargaMasiva';
import { Row, Container, Form, Button, ProgressBar, Alert, Col, Accordion, Spinner } from "react-bootstrap";
const InscripcionesExtraemporaneas = (props) => {
    const [contador, setContador] = useState(0);
    const [participantes, setParticipantes] = useState([]);
    const [institucion, setInstitucion] = useState('');
    const [exito, setExito] = useState('none');
    const [error, setError] = useState('none');
    const [cargando, setCargando] = useState('none');
    const [formulario, setFormulario] = useState('block');
    const [mensaje, setMensaje] = useState('');
    const [paises, setPaises] = useState([]);
    const [init, setInit] = useState(0);
    const [inversion, setInversion] = useState(0);
    const [asesores, setAsesores] = useState([]);
    const [asesor, setAsesor] = useState('');
    useEffect(() => {
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/paises', { cache: "no-cache" })
            .then((res) => {
                return res.json()
            })
            .then(data => {
                let paises_arr = [];
                data.paises.map((actual) => { paises_arr.push({ id: actual.code, nombre: actual.name}) });
                paises_arr.sort(function (a, b) { return a.nombre.localeCompare(b.nombre) });
                setPaises(paises_arr);
            });
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/asesor', { cache: "no-cache" })
            .then((res) => {
                return res.json();
            })
            .then(data => {
                setAsesores(data.rows);
            });
    }, [init]);

    useEffect(() => {

    }, [contador]);
    const onSubmit = (evt) => {
        evt.preventDefault();
        cargar('Validando información');
        if (participantes.length == 0) {
            operacionError('Debes añadir 1 o más participantes');
        } else {
            const info = { participantes: participantes };
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(info)
            };
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/detalle_inscripcion/search/email/' + props.evento, requestOptions)
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    if (data.salida.length > 0) {
                        operacionError('Hay uno o varios emails registrados en el sistema, por favor corregir información');
                    } else {
                        cargar('Sincronizando base de datos');
                        const info = {
                            participantes: participantes,
                            Agremiado: false,
                            Inversion: inversion,
                            Fecha_Max_FACT: new Date(Date.now()).getFullYear() + '-' + (new Date(Date.now()).getMonth() + 1) + '-' + new Date(Date.now()).getDate(),
                            Nombre_FACT: 'CF',
                            NIT: 'CF',
                            Direccion: 'Ciudad',
                            Correo_FACT: 'PENDIENTE',
                            Responsable: 'PENDIENTE',
                            Correo_RESP: 'PENDIENTE',
                            Tel_RESP: 'PENDIENTE',
                            Responsable_PAGO: 'PENDIENTE',
                            Correo_RP: 'PENDIENTE',
                            Tel_RP: 'PENDIENTE',
                            Comentario: 'INSCRIPCIÓN EXTEMPORÁNEA AUTORIZADA POR CONTROL ACADÉMICO AL DÍA ' + new Date(Date.now()).getFullYear() + '-' + (new Date(Date.now()).getMonth() + 1) + '-' + new Date(Date.now()).getDate(),
                            Evento_Unique_ID: props.evento,
                            Forma_Pago_UniqueID: 15,
                            Moneda_Unique_ID: 8,
                            moneda: 'Q',
                            Asesor_UniqueID: asesor,
                            empresa: institucion
                        };
                        const requestOptions = {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(info)
                        };
                        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/inscribir', requestOptions)
                            .then((res) => {
                                return res.json();
                            })
                            .then(info_inscripcion => {
                                if (info_inscripcion.codigo == 200) {
                                    if (inversion == 0) {
                                        const info_2 = { UniqueID: info_inscripcion.id };
                                        const requestOptions_2 = {
                                            method: 'POST',
                                            headers: {
                                                'Content-Type': 'application/json'
                                            },
                                            body: JSON.stringify(info_2)
                                        };
                                        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/update/inscripcion/confirmar_pago', requestOptions_2)
                                            .then((res) => {
                                                return res.json();
                                            })
                                            .then(data => {
                                                if (data.codigo == 200) {
                                                    operacionExito('Inscripción registrada exitosamente');
                                                } else {
                                                    operacionError('Error al grabar registro en db');
                                                }
                                            });
                                    } else {
                                        operacionExito('Inscripción registrada exitosamente');
                                    }
                                } else {
                                    operacionError('Error al grabar registro en db');
                                }
                            });
                    }
                });
        }
    }
    const onChange = (evt) => {
        if (evt.target.name == 'institucion') {
            setInstitucion(evt.target.value);
            setContador(contador + 1);
        } else if (evt.target.name == 'asesor') {
            setAsesor(evt.target.value);
            setContador(contador + 1);
        }
        else if (evt.target.name == 'inversion') {
            setInversion(evt.target.value);
            setContador(contador + 1);
        }
    }
    const addPerson = () => {
        participantes.push({ Nombres: '', Apellidos: '', Puesto: '', Correo: '', Tel: '', Celular: '', Pais: '' });
        setContador(contador + 1);
    }
    const onChangeParticipante = (evt) => {
        let arr = evt.target.name.split('-');
        let valor_nuevo = evt.target.value;
        let arr_actual = participantes;
        if (arr[1] == 'nombres') {
            arr_actual[arr[0]].Nombres = valor_nuevo;
        } else if (arr[1] == 'apellidos') {
            arr_actual[arr[0]].Apellidos = valor_nuevo;
        } else if (arr[1] == 'puesto') {
            arr_actual[arr[0]].Puesto = valor_nuevo;
        } else if (arr[1] == 'email') {
            arr_actual[arr[0]].Correo = valor_nuevo;
        } else if (arr[1] == 'telefono') {
            arr_actual[arr[0]].Tel = valor_nuevo;
        } else if (arr[1] == 'celular') {
            arr_actual[arr[0]].Celular = valor_nuevo;
        } else if (arr[1] == 'pais') {
            arr_actual[arr[0]].Pais = valor_nuevo;
        }
        setParticipantes(arr_actual);
        setContador(contador + 1);
    }
    const goBack = () => {
        setFormulario('block');
        setError('none');
        setCargando('none');
        setExito('none');
        setInstitucion('');
        setParticipantes([]);
        setInversion(0);
        setAsesor('');
        setContador(contador + 1);
    }
    const cargar = (mensaje) => {
        setExito('none');
        setFormulario('none');
        setError('none');
        setCargando('block');
        setMensaje(mensaje);
        setContador(contador + 1);
    }
    const operacionExito = (mensaje) => {
        setExito('block');
        setFormulario('none');
        setError('none');
        setCargando('none');
        setMensaje(mensaje);
        setContador(contador + 1);
    }
    const operacionError = (mensaje) => {
        setExito('none');
        setFormulario('none');
        setError('block');
        setCargando('none');
        setMensaje(mensaje);
        setContador(contador + 1);
    }
    const cargarParticipantes = (array) => {
        let tmp = [];
        array.map((curr) => {
            tmp.push({
                No: tmp.length,
                identificacion: '',
                Nombres: curr.NOMBRES,
                Apellidos: curr.APELLIDOS,
                Puesto: curr.PUESTO,
                Correo: curr['CORREO ELECTRONICO'],
                Tel: curr['TELEFONO Y EXTENSION'],
                Celular: curr.CELULAR,
                Pais: curr.PAIS
            });
        });
        setParticipantes(tmp);
        setContador(contador + 1);
    }
    return (
        <Container fluid style={{ marginTop: '45px', border: '0.5px dashed black', borderRadius: '15px', padding: '25px' }}>
            <Row style={{ display: formulario }}>
                <h1>Ingresar inscripción</h1>
            </Row>
            <Row style={{ display: formulario }}>
                <Container fluid>
                    <Form onSubmit={onSubmit}>
                        <Row style={{ marginTop: '15px', marginBottom: '15px' }}>
                            <Form.Group>
                                <Form.Label>Institución</Form.Label>
                                <Form.Control required type="text" name='institucion' value={institucion} placeholder="Institución donde laboran las personas con cortesía" onChange={onChange} />
                            </Form.Group>
                        </Row>
                        <Row style={{ marginTop: '15px', marginBottom: '15px' }}>
                            <Form.Group>
                                <Form.Label>Inversión</Form.Label>
                                <Form.Control required type="text" name='inversion' value={inversion} placeholder="Inversión de inscripción" onChange={onChange} />
                            </Form.Group>
                        </Row>
                        <Row style={{ marginTop: '15px', marginBottom: '15px' }}>
                            <Form.Group className="mb-3" controlId="asesor">
                                <Form.Label>Asesor</Form.Label>
                                <Form.Control as="select" required aria-label="asesor" name="asesor" onChange={onChange} value={asesor}>
                                    <option value="" key="0">Seleccione el asesor</option>
                                    {asesores.map(asesor => <option key={asesor.UniqueID} value={asesor.UniqueID}>{asesor.Nombres + " " + asesor.Apellidos}</option>)}
                                </Form.Control>
                            </Form.Group>
                        </Row>
                        <Row style={{ marginTop: '15px', marginBottom: '15px' }}>
                            <CargaMasiva cargarParticipantes={cargarParticipantes} />
                        </Row>
                        {participantes.map((actual, index) => <Row style={{ marginTop: '25px', marginBottom: '25px' }} key={index}>
                            <Col xs={12} md={12} lg={6} style={{ marginTop: '15px', marginBottom: '15px' }}>
                                <h2>Participante #{index + 1}</h2>
                            </Col>
                            <Col xs={12} md={12} lg={6} style={{ marginTop: '15px', marginBottom: '15px' }}>
                                <Container>
                                    <Row>
                                        <Button variant='outline-danger' onClick={() => { participantes.splice(index, 1); setContador(contador + 1); }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3" viewBox="0 0 16 16">
                                            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                        </svg>&nbsp;&nbsp;&nbsp;&nbsp;Eliminar participante</Button>
                                    </Row>
                                </Container>
                            </Col>
                            <Col xs={12} md={12} lg={12} style={{ marginTop: '15px', marginBottom: '15px' }}>
                                <Form.Group>
                                    <Form.Label>País</Form.Label>
                                    <Form.Control as="select" required aria-label="Pais" name={index + '-pais'} onChange={onChangeParticipante} value={actual.Pais}>
                                        <option value="" key="0">Seleccione el país</option>
                                        {paises.map(pais => <option key={pais.id} value={pais.nombre}>{pais.nombre}</option>)}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={12} lg={6} style={{ marginTop: '15px', marginBottom: '15px' }}>
                                <Form.Group>
                                    <Form.Label>Nombres</Form.Label>
                                    <Form.Control required type="text" name={index + '-nombres'} value={actual.Nombres} placeholder="Nombres del participante" onChange={onChangeParticipante} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={12} lg={6} style={{ marginTop: '15px', marginBottom: '15px' }}>
                                <Form.Group>
                                    <Form.Label>Apellidos</Form.Label>
                                    <Form.Control required type="text" name={index + '-apellidos'} value={actual.Apellidos} placeholder="Apellidos del participante" onChange={onChangeParticipante} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={12} lg={6} style={{ marginTop: '15px', marginBottom: '15px' }}>
                                <Form.Group>
                                    <Form.Label>Puesto</Form.Label>
                                    <Form.Control required type="text" name={index + '-puesto'} value={actual.Puesto} placeholder="Puesto del participante" onChange={onChangeParticipante} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={12} lg={6} style={{ marginTop: '15px', marginBottom: '15px' }}>
                                <Form.Group>
                                    <Form.Label>Correo electrónico</Form.Label>
                                    <Form.Control required type="text" name={index + '-email'} value={actual.Correo} placeholder="Correo electrónico del participante" onChange={onChangeParticipante} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={12} lg={6} style={{ marginTop: '15px', marginBottom: '15px' }}>
                                <Form.Group>
                                    <Form.Label>Teléfono y extensión</Form.Label>
                                    <Form.Control required type="text" name={index + '-telefono'} value={actual.Tel} placeholder="Teléfono y extensión del participante" onChange={onChangeParticipante} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={12} lg={6} style={{ marginTop: '15px', marginBottom: '15px' }}>
                                <Form.Group>
                                    <Form.Label>Celular</Form.Label>
                                    <Form.Control required type="text" name={index + '-celular'} value={actual.Celular} placeholder="Celular del participante" onChange={onChangeParticipante} />
                                </Form.Group>
                            </Col>
                        </Row>)}
                        <Row style={{ marginTop: '15px', marginBottom: '15px' }}>
                            <Button onClick={addPerson} variant='outline-success'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-plus-fill" viewBox="0 0 16 16">
                                <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                <path fillRule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z" />
                            </svg>&nbsp;&nbsp;&nbsp;&nbsp;Agregar participante</Button>
                        </Row>
                        <Row>
                            <Button variant='outline-dark' type='submit'>Guardar</Button>
                        </Row>
                    </Form>
                </Container>
            </Row>
            <Row style={{ display: exito }}>
                <Alert variant='success'>
                    <Alert.Heading>
                        Operación realizada con éxito
                    </Alert.Heading>
                    <p>
                        {mensaje}
                    </p>
                    <hr />
                    <div className="d-flex justify-content-end">
                        <Button variant='outline-success' onClick={goBack}>
                            Ok
                        </Button>
                    </div>
                </Alert>
            </Row>
            <Row style={{ display: error }}>
                <Alert variant='danger'>
                    <Alert.Heading>
                        Ocurrió un error al ingresar la propuesta
                    </Alert.Heading>
                    <p>
                        {mensaje}
                    </p>
                    <hr />
                    <div className="d-flex justify-content-end">
                        <Button variant='outline-danger' onClick={goBack}>
                            Ok
                        </Button>
                    </div>
                </Alert>
            </Row>
            <Row style={{ display: cargando }}>
                <Alert variant='dark'>
                    <Alert.Heading>
                        <Spinner animation="border" role="status">
                        </Spinner>&nbsp;&nbsp;&nbsp;
                        Ingresando información
                    </Alert.Heading>
                    <p>
                        {mensaje}
                    </p>
                    <hr />
                    <p>
                        Por favor espere...
                    </p>
                </Alert>
            </Row>
        </Container>
    )
}

export default InscripcionesExtraemporaneas