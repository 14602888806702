import React, { Component } from 'react';
import { Row, Button, Col, Form, Container, InputGroup } from "react-bootstrap";
import { ErrorModal } from '../Utils/ErrorModal/ErrorModal';
import { SuccessModal } from '../Utils/SuccessModal/SuccessModal';
import './CrudEvento.css';
//import DEFAULT_URL from '../../api/url';

export class CrudEvento extends Component {
    state = { tipos: [], modalidades: [], tipo: '', Sesiones: '', PrecioAgremiados: '', PrecioNoAgremiados: '', Duracion: '', modalidad: '', Dias: '', Nombre: '', Descripcion: '', Fecha_Inicio: '', Hora: '', Minimo: '', ingresos: '', egresos: '', resultado: '', Fecha_Fin: '', modalSucc: false, modalError: false, Mensaje: '', Consideracion: '' };
    componentDidMount = () => {
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/evento/tipos', { cache: "no-cache" })
            .then((res) => {
                return res.json();
            })
            .then(data => {
                this.setState({ tipos: data.rows });
            });
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/evento/modalidades', { cache: "no-cache" })
            .then((res) => {
                return res.json();
            })
            .then(data => {
                this.setState({ modalidades: data.rows });
            });
    }
    changeHandler = (evt) => {

        this.setState({ [evt.target.name]: evt.target.value });
        if (evt.target.name == 'modalidad') {
            if (evt.target.value == '1') {
                this.setState({ ingresos: '0', egresos: '0', resultado: '0' });
            } else {
                if (this.state.modalidad === '1') {
                    this.setState({ ingresos: '', egresos: '', resultado: '' });
                }
            }
        }

    }
    ocultar = () => {
        this.setState({ modalSucc: false });
    }
    ocultar_err = () => {
        this.setState({ modalError: false });
    }
    submitHandler = (evt) => {
        evt.preventDefault();
        const info = { Dias: this.state.Dias, Sesiones: this.state.Sesiones, PrecioAgremiados: this.state.PrecioAgremiados, PrecioNoAgremiados: this.state.PrecioNoAgremiados, Duracion: this.state.Duracion, Nombre: this.state.Nombre, Descripcion: this.state.Descripcion, Fecha_Inicio: this.state.Fecha_Inicio, Hora: this.state.Hora, Minimo: this.state.Minimo, ingresos: this.state.ingresos, egresos: this.state.egresos, resultado: this.state.resultado, Fecha_Fin: this.state.Fecha_Fin, tipo: this.state.tipo, modalidad: this.state.modalidad };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/create/evento', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                if (data.codigo == 200) {
                    fetch(process.env.REACT_APP_DEFAULT_URL + '/api/helpers/carpetas/' + data.id)
                        .then((res) => {
                            return res.json();
                        })
                        .then(data => {
                            if (data.codigo == 200) {
                                fetch(process.env.REACT_APP_DEFAULT_URL + '/api/helpers/encriptar/evento')
                                    .then((res) => {
                                        return res.json();
                                    })
                                    .then(data => {
                                        if (data.codigo == 200) {
                                            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/helpers/generar-pagos-electronicos')
                                                .then((res) => {
                                                    return res.json();
                                                })
                                                .then(data => {
                                                    if (data.codigo == 200) {
                                                        this.setState({ Mensaje: 'Evento Registrado Exitosamente' });
                                                        this.setState({ Consideración: 'Puede verificar la información almacenada en el módulo de agenda' });
                                                        this.setState({ modalSucc: true });
                                                        this.setState({ tipos: [], modalidades: [], tipo: '', Sesiones: '', PrecioAgremiados: '', PrecioNoAgremiados: '', Duracion: '', modalidad: '', Dias: '', Nombre: '', Descripcion: '', Fecha_Inicio: '', Hora: '', Minimo: '', ingresos: '', egresos: '', resultado: '', Fecha_Fin: '', modalSucc: false, modalError: false, Mensaje: '', Consideracion: '' });
                                                        this.componentDidMount();
                                                        this.props.refresh();
                                                    } else {
                                                        this.setState({ Mensaje: 'Ocurrió un error' });
                                                        this.setState({ modalError: true });
                                                    }
                                                });
                                        } else {
                                            this.setState({ Mensaje: data.respuesta.sqlState + ": " + data.respuesta.sqlMessage });
                                            this.setState({ modalError: true });
                                        }
                                    });
                            } else {
                                this.setState({ Mensaje: data.respuesta.sqlState + ": " + data.respuesta.sqlMessage });
                                this.setState({ modalError: true });
                            }
                        });
                } else {
                    this.setState({ Mensaje: data.respuesta.sqlState + ": " + data.respuesta.sqlMessage });
                    this.setState({ modalError: true });
                }
            });
    }

    render() {
        return (
            <Form onSubmit={this.submitHandler}>
                <Container >
                    <Row className="segmento-formulario">
                        <h1>Información general</h1>
                        <Col xs={12} md={12} lg={12}>
                            <Form.Group className="mb-3" controlId="Nombre" >
                                <Form.Label>Nombre de evento</Form.Label>
                                <Form.Control required onChange={this.changeHandler} type="text" placeholder="Ingrese el nombre del Evento" name="Nombre" value={this.state.Nombre} />
                                <Form.Control.Feedback type="invalid">
                                    Por favor, ingrese el nombre del evento.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={12} lg={12}>
                            <Form.Group className="mb-3" controlId="Descripcion">
                                <Form.Label>Descripción de evento</Form.Label>
                                <Form.Control required onChange={this.changeHandler} as="textarea" rows={3} style={{ resize: 'none' }} name="Descripcion" placeholder="Ingrese la descripción del evento" value={this.state.Descripcion} />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6} lg={6}>
                            <Form.Group className="mb-3" controlId="Minimo">
                                <Form.Label>Mínimo de participantes</Form.Label>
                                <Form.Control required onChange={this.changeHandler} type="text" placeholder="Ingrese el mínimo de participantes" name="Minimo" value={this.state.Minimo} />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6} lg={6}>
                            <Form.Group className="mb-3" controlId="Hora">
                                <Form.Label>Hora del evento</Form.Label>
                                <Form.Control required onChange={this.changeHandler} type="text" placeholder="Ingrese la hora del evento" name="Hora" value={this.state.Hora} />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6} lg={6}>
                            <Form.Group className="mb-3" controlId="Fecha_Inicio">
                                <Form.Label>Fecha de inicio</Form.Label>
                                <Form.Control required onChange={this.changeHandler} type="date" name="Fecha_Inicio" value={this.state.Fecha_Inicio} />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6} lg={6}>

                            <Form.Group className="mb-3" controlId="Fecha_Fin">
                                <Form.Label>Fecha de finalizacion</Form.Label>
                                <Form.Control required onChange={this.changeHandler} type="date" name="Fecha_Fin" value={this.state.Fecha_Fin} />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6} lg={6}>
                            <Form.Group className="mb-3" controlId="Tipo_Evento_UniqueID">
                                <Form.Label>Tipo de evento</Form.Label>
                                <Form.Control as="select" required aria-label="Tipo" name="tipo" onChange={this.changeHandler}>
                                    <option value="" key="0">Seleccione el tipo de evento</option>
                                    {this.state.tipos.map(tipo => <option key={tipo.UniqueID} value={tipo.UniqueID}>{tipo.Nombre}</option>)}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6} lg={6}>
                            <Form.Group className="mb-3" controlId="Tipo_Evento_UniqueID">
                                <Form.Label>Modalidad de evento</Form.Label>
                                <Form.Control as="select" required aria-label="Tipo" name="modalidad" onChange={this.changeHandler}>
                                    <option value="" key="0">Seleccione la modalidad del evento</option>
                                    {this.state.modalidades.map(modalidad => <option key={modalidad.UniqueID} value={modalidad.UniqueID}>{modalidad.Nombre}</option>)}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={4} lg={4}>

                            <Form.Group className="mb-3" controlId="Dias">
                                <Form.Label>Días a impartir</Form.Label>
                                <Form.Control required onChange={this.changeHandler} type="text" placeholder="Ingrese los días a impartir" name="Dias" value={this.state.Dias} />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={4} lg={4}>

                            <Form.Group className="mb-3" controlId="Duracion">
                                <Form.Label>Duracion de evento</Form.Label>
                                <Form.Control required onChange={this.changeHandler} type="text" placeholder="Ingrese los días a impartir" name="Duracion" value={this.state.Duracion} />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={4} lg={4}>

                            <Form.Group className="mb-3" controlId="Sesiones">
                                <Form.Label>Número de sesiones</Form.Label>
                                <Form.Control required onChange={this.changeHandler} type="text" placeholder="Ingrese el número de sesiones" name="Sesiones" value={this.state.Sesiones} />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6} lg={6}>

                            <Form.Group className="mb-3" controlId="PrecioAgremiados">
                                <Form.Label>Precio para agremiados</Form.Label>
                                <Form.Control required onChange={this.changeHandler} type="text" placeholder="Ingrese el precio para agremiados" name="PrecioAgremiados" value={this.state.PrecioAgremiados} />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6} lg={6}>

                            <Form.Group className="mb-3" controlId="PrecioNoAgremiados">
                                <Form.Label>Precio para no agremiados</Form.Label>
                                <Form.Control required onChange={this.changeHandler} type="text" placeholder="Ingrese el precio para no agremiados" name="PrecioNoAgremiados" value={this.state.PrecioNoAgremiados} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="segmento-formulario">
                        <h1>Proyecciones</h1>
                        <Col xs={12} md={4} lg={4}>
                            <Form.Group className="mb-3" controlId="Ingresos">
                                <Form.Label>Ingresos proyectados</Form.Label>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>QTZ</InputGroup.Text>
                                    <Form.Control required readOnly={this.state.modalidad == '1'} onChange={this.changeHandler} type="text" placeholder="Ingrese Los Ingresos Proyectados" name="ingresos" value={this.state.ingresos} />
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={4} lg={4}>
                            <Form.Group className="mb-3" controlId="Egresos" >
                                <Form.Label>Egresos proyectados</Form.Label>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>QTZ</InputGroup.Text>
                                    <Form.Control required readOnly={this.state.modalidad == '1'} onChange={this.changeHandler} type="text" placeholder="Ingrese Los Egresos Proyectados" name="egresos" value={this.state.egresos} />
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={4} lg={4}>
                            <Form.Group className="mb-3" controlId="Resultado">
                                <Form.Label>Resultado proyectado</Form.Label>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>QTZ</InputGroup.Text>
                                    <Form.Control required readOnly={this.state.modalidad == '1'} onChange={this.changeHandler} type="text" placeholder="Ingrese El Resultado Proyectado" name="resultado" value={this.state.resultado} />
                                </InputGroup>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: '30px', paddingBottom: '30px' }}>
                        <Button variant="secondary" className="font-bold" type="submit" >Guardar</Button>
                    </Row>
                    <SuccessModal
                        color={"#b5bad0"}
                        show={this.state.modalSucc}
                        onHide={this.ocultar}
                        mensaje={this.state.Mensaje}
                        consideracion={this.state.Consideracion}
                        enlace={''}
                        llevaenlace={false}
                    />
                    <ErrorModal
                        color={"#b5bad0"}
                        show={this.state.modalError}
                        onHide={this.ocultar_err}
                        mensaje={this.state.Mensaje}
                        consideracion={this.state.Consideracion}
                    />
                </Container>
            </Form>
        );
    }
}