import React from 'react'
import './Asesores.css'
import { Row, Col, Container } from "react-bootstrap";
function Asesores() {
    return (
        <div className="contenedor-portal">
            <Container>
                <Row style={{ height: '100%' }}>
                    <Col xs={12} md={12} lg={4} id="columna-foto">
                        <div className='contenedor-foto' style={{ marginTop: '48%', marginLeft: '35%', backgroundImage: 'url(https://ik.imagekit.io/p5ethkcnzwh/Fotografia_Lic_Byron_9y9ZH49Pb.png?ik-sdk-version=javascript-1.4.3&updatedAt=1652366172462)' }}>

                        </div>
                    </Col>
                    <Col xs={1} md={1} lg={1} id="columnas-azul">
                        <Container style={{ height: '100%' }}>
                            <Row style={{ height: '92%' }}>
                                <Col>
                                    <div className="columna-azul" id="arriba"></div>
                                </Col>
                            </Row>
                            <Row style={{ height: '8%' }}>
                                <Col >
                                    <div className="columna-azul" id="abajo"></div>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                    <Col xs={12} md={12} lg={7} style={{ height: '100vh' }}>
                        <Container>
                            <Row style={{ height: '200px', marginTop: '5%' }}>
                                <Col xs={12} md={12} lg={8}>
                                    <div className='logos'></div>
                                </Col>
                            </Row>
                            <Row id="informacion">
                                <Container>
                                    <Row id="foto-responsive">
                                        <Col xs={12} md={12} lg={12} align="center">
                                            <div className='contenedor-foto' style={{ backgroundImage: 'url(https://ik.imagekit.io/p5ethkcnzwh/Fotografia_Lic_Byron_9y9ZH49Pb.png?ik-sdk-version=javascript-1.4.3&updatedAt=1652366172462)' }}>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <h1 className='nombres'><strong>LIC. BYRON</strong></h1>
                                        <h1 className='apellidos'>CLAUDIO COBON</h1>
                                    </Row>
                                    <Row>
                                        <div className='contenedor-puesto'>
                                            <h5 className='titulo-puesto'>Director de Promoción y Comunicación Institucional</h5>
                                        </div>
                                    </Row>
                                    <Row style={{ marginTop: '20px' }}>
                                        <h5 className='titulo-entidad'>Asociación Bancaria de Guatemala -ABG</h5>
                                    </Row>
                                    <Row>
                                        <h5 className='titulo-entidad'>Escuela Bancaria Centroamericana -EBC</h5>
                                    </Row>
                                    <Row style={{ marginTop: '10px', marginBottom: '10px', height: '10vh' }}>
                                        <div className='titulo-contacto'>
                                            <h1 style={{ textAlign: 'left' }}>CONTACTO</h1>
                                        </div>
                                    </Row>
                                    <Row className="fila-control-height">
                                        <Col xs={2} md={2} lg={2}>
                                            <a href="mailto:bclaudio@ebg.edu.gt" target='_blank'>
                                                <div className='imagen-icono' data-toggle="tooltip" data-placement="top" title="Enviar un email" style={{ cursor: 'pointer', backgroundImage: 'url(https://ik.imagekit.io/p5ethkcnzwh/icono_correo_0x383IQJm.png?ik-sdk-version=javascript-1.4.3&updatedAt=1652366169860)' }}>

                                                </div>
                                            </a>
                                        </Col>
                                        <Col xs={10} md={10} lg={10} style={{ marginTop: '10px' }} className='correos'>
                                            <h4 style={{ textAlign: 'left' }} className='titulo-entidad'>bclaudio@ebg.edu.gt</h4>
                                        </Col>
                                    </Row>
                                    <Row className="fila-control-height">
                                        <Col xs={2} md={2} lg={2}>
                                            <a href="mailto:escuelabancariacentroamericana@ebg.edu.gt" target='_blank'>
                                                <div className='imagen-icono' data-toggle="tooltip" data-placement="top" title="Enviar un email" style={{ cursor: 'pointer', backgroundImage: 'url(https://ik.imagekit.io/p5ethkcnzwh/icono_correo_0x383IQJm.png?ik-sdk-version=javascript-1.4.3&updatedAt=1652366169860)' }}>

                                                </div>
                                            </a>
                                        </Col>
                                        <Col xs={10} md={10} lg={10} style={{ marginTop: '10px' }} className='correos'>
                                            <h4 style={{ textAlign: 'left' }} className='titulo-entidad'> escuelabancariacentroamericana@ebg.edu.gt</h4>
                                        </Col>
                                    </Row>
                                    <Row className="fila-control-height">
                                        <Col xs={2} md={2} lg={2}>
                                            <a href="tel://+50223827200" target='_blank'>
                                                <div className='imagen-icono' data-toggle="tooltip" data-placement="top" title="Llamar" style={{ cursor: 'pointer', backgroundImage: 'url(https://ik.imagekit.io/p5ethkcnzwh/Icono_telefono_yMYSGXNeF.png?ik-sdk-version=javascript-1.4.3&updatedAt=1652366169561)' }}>

                                                </div>
                                            </a>
                                        </Col>
                                        <Col xs={10} md={10} lg={10}>
                                            <h4 style={{ textAlign: 'left', lineHeight: '50px' }} className='titulo-entidad'>(+502) 2382-7200</h4>
                                        </Col>
                                    </Row>
                                    <Row className="fila-control-height">
                                        <Col xs={2} md={2} lg={2}>
                                            <a href="https://wa.me/+50257480970" target='_blank'>
                                                <div className='imagen-icono' data-toggle="tooltip" data-placement="top" title="Contactar por whatsapp" style={{ cursor: 'pointer', backgroundImage: 'url(https://ik.imagekit.io/p5ethkcnzwh/Icono_whatsapp_Akz-jIYgk.png?ik-sdk-version=javascript-1.4.3&updatedAt=1652366169464)' }}>

                                                </div>
                                            </a>
                                        </Col>
                                        <Col xs={10} md={10} lg={10}>
                                            <h4 style={{ textAlign: 'left', lineHeight: '50px' }} className='titulo-entidad'>(+502) 5748-0970</h4>
                                        </Col>
                                    </Row>
                                    <Row className="fila-control-height">
                                        <Col xs={2} md={2} lg={2}>
                                            <a href="https://www.ebg.edu.gt" target='_blank'>
                                                <div className='imagen-icono' data-toggle="tooltip" data-placement="top" title="Navegar a site" style={{ cursor: 'pointer', backgroundImage: 'url(https://ik.imagekit.io/p5ethkcnzwh/Icono_web_nh15_0W59.png?ik-sdk-version=javascript-1.4.3&updatedAt=1652366169464)' }}>

                                                </div>
                                            </a>
                                        </Col>
                                        <Col xs={10} md={10} lg={10}>
                                            <h4 style={{ textAlign: 'left', lineHeight: '50px' }} className='titulo-entidad'>www.ebg.edu.gt</h4>
                                        </Col>
                                    </Row>
                                    <Row style={{ marginTop: '10px', height: '10vh' }}>
                                        <Col xs={4} md={4} lg={4}></Col>
                                        <Col xs={2} md={2} lg={2}>
                                            <a href="https://www.instagram.com/ebg.guatemala/?hl=es-la" target='_blank'>
                                                <div className='imagen-icono' data-toggle="tooltip" data-placement="top" title="Encuéntranos en instagram" style={{ cursor: 'pointer', backgroundImage: 'url(https://ik.imagekit.io/p5ethkcnzwh/Icono_Instagram_gfrM0BdRz.png?ik-sdk-version=javascript-1.4.3&updatedAt=1652366169828)' }}>

                                                </div>
                                            </a>
                                        </Col>
                                        <Col xs={2} md={2} lg={2}>
                                            <a href="https://www.facebook.com/abg.ebg" target='_blank'>
                                                <div className='imagen-icono' data-toggle="tooltip" data-placement="top" title="Encuéntranos en facebook" style={{ cursor: 'pointer', backgroundImage: 'url(https://ik.imagekit.io/p5ethkcnzwh/Icono_Facebook_w8zUSAC38.png?ik-sdk-version=javascript-1.4.3&updatedAt=1652366169746)' }}>

                                                </div>
                                            </a>
                                        </Col>
                                        <Col xs={2} md={2} lg={2}>
                                            <a href="https://twitter.com/ABG_EBG" target='_blank'>
                                                <div className='imagen-icono' data-toggle="tooltip" data-placement="top" title="Encuéntranos en twitter" style={{ cursor: 'pointer', backgroundImage: 'url(https://ik.imagekit.io/p5ethkcnzwh/Icono_Twitter_Ww6TzsuXS.png?ik-sdk-version=javascript-1.4.3&updatedAt=1652366169591)' }}>

                                                </div>
                                            </a>
                                        </Col>
                                        <Col xs={2} md={2} lg={2}>
                                            <a href="https://linkedin.com/in/byron-claudio-b4146a20 " target='_blank'>
                                                <div className='imagen-icono' data-toggle="tooltip" data-placement="top" title="Encuéntranos en linkedin" style={{ cursor: 'pointer', backgroundImage: 'url(https://ik.imagekit.io/p5ethkcnzwh/Icono_linkedin_4IbLb60pn.png?ik-sdk-version=javascript-1.4.3&updatedAt=1652366169464)' }}>

                                                </div>
                                            </a>
                                        </Col>
                                    </Row>
                                </Container>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Asesores