import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Form, Button, Col, Card } from "react-bootstrap";
const helpers = require("../../../../../helpers/helpers");
const ManejoParticipantes = () => {
  const { hash } = useParams();
  const [cambios, setCambios] = useState(0);
  const [contador] = useState(0);
  const [evento, setEvento] = useState({});
  const [participantes, setParticipantes] = useState([]);
  const [filterdedData, setFilteredData] = useState([]);
  const [filterData, setFilterData] = useState("");
  const [cargando, setCargando] = useState(false);
  const [permiso, setPermiso] = useState(0);
  useEffect(() => {
    async function fetchData() {
      fetch(process.env.REACT_APP_DEFAULT_URL + "/api/auth/userinfo", {
        cache: "no-cache",
        credentials: "include",
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setPermiso(data.tipo);
          fetch(
            process.env.REACT_APP_DEFAULT_URL +
              "/api/read/evento/landing/" +
              hash,
            {
              cache: "no-cache",
              credentials: "include",
            }
          )
            .then((res) => {
              return res.json();
            })
            .then((data) => {
              setEvento(data.rows[0]);
              fetch(
                process.env.REACT_APP_DEFAULT_URL +
                  "/api/read/detalle_inscripcion/evento/" +
                  data.rows[0].UniqueID,
                { cache: "no-cache" }
              )
                .then((res) => {
                  return res.json();
                })
                .then((data) => {
                  let tmp = [];
                  data.rows.map((actual, indice) => {
                    actual.selected = false;
                    tmp.push(actual);
                    if (indice == data.rows.length - 1) {
                      setParticipantes(tmp);
                      setFilteredData(tmp);
                    }
                  });
                });
            });
        });
    }
    fetchData();
  }, [contador]);
  useEffect(() => {}, [cambios]);
  const onChange = (evt) => {
    if (evt.target.name == "filter") {
      setFilterData(evt.target.value);
      let result = [];
      result = participantes.filter((actual) => {
        return (
          actual.Nombres.toLowerCase().search(evt.target.value.toLowerCase()) !=
            -1 ||
          actual.Apellidos.toLowerCase().search(
            evt.target.value.toLowerCase()
          ) != -1 ||
          actual.Correo.toLowerCase().search(evt.target.value.toLowerCase()) !=
            -1 ||
          actual.Puesto.toLowerCase().search(evt.target.value.toLowerCase()) !=
            -1 ||
          actual.institucion
            .toLowerCase()
            .search(evt.target.value.toLowerCase()) != -1 ||
          actual.name.toLowerCase().search(evt.target.value.toLowerCase()) != -1
        );
      });
      setFilteredData(result);
    } else {
      let id = evt.target.name.split("-")[1];
      let result = filterdedData;
      let tmp = participantes;
      const index_finder_1 = (participante) => participante.UniqueID == id;
      if (result.findIndex(index_finder_1) >= 0) {
        let item = result[result.findIndex(index_finder_1)];
        item.selected = evt.target.checked;
        result[result.findIndex(index_finder_1)] = item;
        setFilteredData(result);
      }
      const index_finder = (participante) => participante.UniqueID == id;
      if (tmp.findIndex(index_finder) >= 0) {
        let item = tmp[tmp.findIndex(index_finder)];
        item.selected = evt.target.checked;
        tmp[tmp.findIndex(index_finder)] = item;
        setParticipantes(tmp);
      }
    }
    setCambios(cambios + 1);
  };
  const selectAll = () => {
    let tmp_participantes = participantes;
    let tmp = filterdedData;
    tmp.map((actual, indice) => {
      actual.selected = true;
      const index_finder = (participante) =>
        participante.UniqueID == actual.UniqueID;
      if (tmp_participantes.findIndex(index_finder) >= 0) {
        let item = tmp_participantes[tmp_participantes.findIndex(index_finder)];
        item.selected = true;
        tmp_participantes[tmp_participantes.findIndex(index_finder)] = item;
      }
      if (indice == tmp.length - 1) {
        setParticipantes(tmp_participantes);
        setFilteredData(tmp);
        setCambios(cambios + 1);
      }
    });
  };
  const unSelectAll = () => {
    let tmp_participantes = participantes;
    let tmp = filterdedData;
    tmp.map((actual, indice) => {
      actual.selected = false;
      const index_finder = (participante) =>
        participante.UniqueID == actual.UniqueID;
      if (tmp_participantes.findIndex(index_finder) >= 0) {
        let item = tmp_participantes[tmp_participantes.findIndex(index_finder)];
        item.selected = false;
        tmp_participantes[tmp_participantes.findIndex(index_finder)] = item;
      }
      if (indice == tmp.length - 1) {
        setParticipantes(tmp_participantes);
        setFilteredData(tmp);
        setCambios(cambios + 1);
      }
    });
  };
  const sendGafeteElectronico = (id) => {
    setCargando(true);
    setCambios(cambios + 1);
    const info = { UniqueID: id };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(info),
    };
    fetch(
      process.env.REACT_APP_DEFAULT_URL +
        "/api/send/detalle_inscripcion/gafete",
      requestOptions
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.codigo == 200) {
          window.location.reload();
        } else {
          window.location.replace(
            process.env.REACT_APP_SELF_URL + "/gafetes-fisicos/" + hash + "/all"
          );
        }
      });
  };
  const deleteParticipante = (id) => {
    setCargando(true);
    setCambios(cambios + 1);
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    };
    fetch(
      process.env.REACT_APP_DEFAULT_URL +
        "/api/delete/detalle_inscripcion/" +
        id,
      requestOptions
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.codigo == 200) {
          window.location.reload();
        } else {
          console.log(data);
        }
      });
  };
  const checkAttendance = (persona) => {
    setCargando(true);
    setCambios(cambios + 1);
    const info = {
      hash: persona.hash,
      evento_UniqueID: evento.UniqueID,
      persona: persona.Nombres + " " + persona.Apellidos,
      institucion: persona.institucion,
      foto: persona.foto,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(info),
    };
    fetch(
      process.env.REACT_APP_DEFAULT_URL +
        "/api/create/evento/marcar/asistencia/gafete",
      requestOptions
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.codigo == 200) {
          window.location.reload();
        } else {
          console.log(data);
        }
      });
  };
  const enviarGafeteElectronico = () => {
    let participantes_gafete = participantes.filter(
      (actual) => actual.selected == true
    );
    let body = {
      participantes: participantes_gafete.map((actual) => actual.UniqueID),
    };
    ///api/send/detalle_inscripcion/gafete
    helpers
      .postData("/api/send/detalle_inscripcion/gafete/list", body)
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <Container fluid>
      <Row style={{ padding: "5%" }}>
        <Container>
          <Row
            style={{
              padding: "5%",
              borderBottom: "5px solid #1e3d52",
              borderLeft: "5px solid #1e3d52",
              backgroundColor: "rgb(230,230,230)",
            }}
          >
            <Col xs={12} md={6} lg={4}>
              <img
                loading="lazy"
                src={evento.flier}
                style={{ width: "100%" }}
                alt="arte"
              />
            </Col>
            <Col xs={12} md={6} lg={8} style={{ textAlign: "left" }}>
              <h1>MANEJO PARTICIPANTES</h1>
              {evento != null && (
                <p>
                  <br />
                  <strong>EVENTO: </strong>
                  {evento.Nombre}
                  <br />
                  <strong>FECHA: </strong>
                  {evento.Fecha_Inicio}
                  <br />
                  <strong>
                    PARTICIPANTES REGISTRADOS: {participantes.length}
                  </strong>
                </p>
              )}
            </Col>
          </Row>
        </Container>
      </Row>
      {participantes.length == 0 && (
        <Row style={{ padding: "2%" }}>
          <h3>No hay participantes registrados</h3>
        </Row>
      )}
      {participantes.length > 0 && (
        <Row>
          <Container fluid style={{ padding: "2%" }}>
            <Row>
              <Form.Group className="mb-3" controlId="descripcion">
                <Form.Label>Buscar participantes</Form.Label>
                <Form.Control
                  value={filterData}
                  type="text"
                  placeholder="Puede buscar por nombres, apellidos, correo electrónico, puesto e institución"
                  onChange={onChange}
                  name="filter"
                  disabled={cargando}
                />
              </Form.Group>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={4} style={{ padding: "3%" }}>
                <Button
                  variant="outline-success"
                  disabled={cargando}
                  onClick={selectAll}
                >
                  Seleccionar todos
                </Button>
              </Col>
              <Col xs={12} md={12} lg={4} style={{ padding: "3%" }}>
                <Button
                  variant="outline-danger"
                  disabled={cargando}
                  onClick={unSelectAll}
                >
                  Quitar selección de todos
                </Button>
              </Col>
              <Col xs={12} md={12} lg={4} style={{ padding: "3%" }}>
                <Button
                  variant="outline-primary"
                  disabled={
                    cargando ||
                    participantes.filter((actual) => actual.selected == true)
                      .length == 0
                  }
                  onClick={enviarGafeteElectronico}
                >
                  Enviar Gafete Electrónico
                </Button>
              </Col>
            </Row>
            <Row>
              <h1>Participantes: {filterdedData.length}</h1>
            </Row>
            <Row style={{ padding: "1%" }}>
              {filterdedData.map((actual, index) => (
                <Col
                  key={"participante-" + index}
                  xs={12}
                  md={6}
                  lg={4}
                  style={{ padding: "2%" }}
                >
                  <Card>
                    <Card.Header>
                      <Card.Title>
                        {actual.Nombres + " " + actual.Apellidos}
                      </Card.Title>
                    </Card.Header>
                    <Card.Body style={{ textAlign: "left" }}>
                      <Card.Text>
                        <strong>ID: </strong>
                        {actual.UniqueID}
                      </Card.Text>
                      <Card.Text>
                        <strong>Email: </strong>
                        {actual.Correo}
                      </Card.Text>
                      <Card.Text>
                        <strong>Institución: </strong>
                        {actual.institucion}
                      </Card.Text>
                      <Card.Text>
                        <strong>Puesto: </strong>
                        {actual.Puesto}
                      </Card.Text>
                      <Card.Text>
                        <strong>Pais: </strong>
                        {actual.pais}
                      </Card.Text>
                      <Card.Text>
                        <strong>Asesor: </strong>
                        {actual.asesor}
                      </Card.Text>
                      <Container fluid>
                        <Row style={{ padding: "1%" }}>
                          <Button
                            variant="outline-primary"
                            disabled={cargando}
                            href={"/participantes/edit/" + actual.hash}
                            target="_blank"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-pencil"
                              viewBox="0 0 16 16"
                            >
                              <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                            </svg>
                            &nbsp;&nbsp;&nbsp;&nbsp; Editar participante
                          </Button>
                        </Row>
                        <Row style={{ padding: "1%" }}>
                          <Button
                            variant="outline-danger"
                            onClick={() => {
                              deleteParticipante(actual.UniqueID);
                            }}
                            disabled={cargando || permiso != 4}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-trash"
                              viewBox="0 0 16 16"
                            >
                              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                              <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                            </svg>
                            &nbsp;&nbsp;&nbsp;&nbsp; Eliminar participante
                          </Button>
                        </Row>
                        <Row style={{ padding: "1%" }}>
                          <Button
                            variant="outline-secondary"
                            href={
                              process.env.REACT_APP_EBG_URL +
                              "/mi-gafete-electronico/" +
                              actual.hash
                            }
                            target="_blank"
                            disabled={cargando}
                          >
                            Ir a gafete electrónico
                          </Button>
                        </Row>
                        <Row style={{ padding: "1%" }}>
                          <Button
                            variant="outline-secondary"
                            onClick={() => {
                              sendGafeteElectronico(actual.UniqueID);
                            }}
                            disabled={cargando}
                          >
                            Enviar gafete electrónico
                          </Button>
                        </Row>
                        <Row style={{ padding: "1%" }}>
                          <Button
                            variant="outline-success"
                            onClick={() => {
                              checkAttendance(actual);
                            }}
                            disabled={cargando}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-person-check"
                              viewBox="0 0 16 16"
                            >
                              <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm1.679-4.493-1.335 2.226a.75.75 0 0 1-1.174.144l-.774-.773a.5.5 0 0 1 .708-.708l.547.548 1.17-1.951a.5.5 0 1 1 .858.514ZM11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" />
                              <path d="M8.256 14a4.474 4.474 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1h5.256Z" />
                            </svg>
                            &nbsp;&nbsp;&nbsp;&nbsp; Marcar asistencia
                          </Button>
                        </Row>
                      </Container>
                    </Card.Body>
                    <Card.Footer>
                      <Form.Check
                        checked={actual.selected}
                        onChange={onChange}
                        style={{ textAlign: "left" }}
                        type="checkbox"
                        name={"selected-" + actual.UniqueID}
                        label="Seleccionar"
                        disabled={cargando}
                      />
                    </Card.Footer>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        </Row>
      )}
    </Container>
  );
};

export default ManejoParticipantes;
