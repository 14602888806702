import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";
export class ErrorModal extends Component {
    render() {
        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
            >
                <Modal.Header closeButton style={{ backgroundColor: this.props.color }}>
                    <Modal.Title id="contained-modal-title-vcenter" className="font-bold">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16" style={{ marginRight: '15px' }}>
                            <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                        </svg>
                        ¡Error!
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: this.props.color }} className="cuerpo-modal-err">
                    <p>{this.props.mensaje}</p>
                    <p>{this.props.consideracion}</p>
                </Modal.Body>
                <Modal.Footer style={{ backgroundColor: this.props.color }}>
                    <Button variant="dark" className="font-bold" onClick={this.props.onHide}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}