import React, { useEffect, useState } from 'react'
import { Container, Row, Form, Col, Button } from 'react-bootstrap'
//import DEFAULT_URL, { SELF_URL } from '../../api/url'
const NuevoLead = () => {
    const [contador] = useState(0);
    const [cambios, setCambios] = useState(0);
    const [origenes, setOrigenes] = useState([]);
    const [nombre, setNombre] = useState('');
    const [puesto, setPuesto] = useState('');
    const [institucion, setInstitucion] = useState('');
    const [correo, setCorreo] = useState('');
    const [telefono, setTelefono] = useState('');
    const [origen, setOrigen] = useState('');
    const [usuario, setUsuario] = useState({});
    const [validated, setValidated] = useState(false);
    const [eventos, setEventos] = useState([]);
    const [evento, setEvento] = useState('');
    const [cargando, setCargando] = useState(false);
    const [exito, setExito] = useState(false);
    const [error, setError] = useState(false);
    useEffect(() => {
        async function fetchData() {
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/leads/origen', { cache: "no-cache", credentials: 'include' })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setOrigenes(data.rows);
                });
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/auth/userinfo', { cache: "no-cache", credentials: 'include' })
                .then((res) => {
                    if (res.status == 401) {
                        window.location.reload();
                    } else {
                        return res.json();
                    }
                })
                .then(data => {
                    setUsuario(data);
                });
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/evento/inscripciones/abiertas', { cache: "no-cache", credentials: 'include' })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setEventos(data.rows);
                });
        }
        fetchData();
    }, [contador]);
    useEffect(() => {

    }, [cambios]);

    const onChange = (evt) => {
        switch (evt.target.name) {
            case "Nombre":
                setNombre(evt.target.value);
                break;
            case "Puesto":
                setPuesto(evt.target.value);
                break;
            case "Institucion":
                setInstitucion(evt.target.value);
                break;
            case "Correo":
                setCorreo(evt.target.value);
                break;
            case "Telefono":
                setTelefono(evt.target.value);
                break;
            case "Origen":
                setOrigen(evt.target.value);
                break;
            case "Evento":
                setEvento(evt.target.value);
                break;
        }
        setCambios(cambios + 1);
    }
    const submit = (evt) => {
        const form = evt.currentTarget;
        if (form.checkValidity() === false) {
            evt.preventDefault();
            evt.stopPropagation();
        } else {
            setCargando(true);
            setCambios(cambios + 1);
            evt.preventDefault();
            const info = { Nombre: nombre, Puesto: puesto, Institucion: institucion, Correo: correo, Telefono: telefono, Origen: origen, Asesor: usuario.UniqueID, evento_UniqueID: evento };
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(info)
            };
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/create/leads', requestOptions)
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setCargando(false);
                    setCambios(cambios + 1);
                    if (data.codigo == 200) {
                        //crm/seguimiento/view/:id
                        window.location.replace(process.env.REACT_APP_SELF_URL + '/crm/seguimiento/view/' + data.id);
                        setExito(true);
                        setCambios(cambios + 1);
                    } else {
                        setError(true);
                        setCambios(cambios + 1);
                    }
                });
        }
        setValidated(true);
        setCambios(cambios + 1);
    }
    return (
        <Container >
            <Row style={{ padding: '5%', border: '5px solid #1e3d52', backgroundColor: 'rgb(230,230,230)', textAlign: 'left' }}>
                <h1>Ingreso de lead</h1>
            </Row>
            <Row style={{ paddingTop: '2%', paddingBottom: '2%', paddingLeft: '15%', paddingRight: '15%', textAlign: 'left' }}>
                <Form onSubmit={submit} noValidate validated={validated} >
                    <Row>
                        <Form.Group className="mb-3" controlId="Nombre">
                            <Form.Label>Nombre de la persona que solicita el evento</Form.Label>
                            <Form.Control value={nombre} disabled={cargando || error} required type="text" placeholder="Ingrese el nombre del lead" name="Nombre" onChange={onChange} />
                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                            <Form.Control.Feedback type='invalid'>Por favor, completa este campo</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group className="mb-3" controlId="Direccion">
                            <Form.Label>Puesto de trabajo (opcional)</Form.Label>
                            <Form.Control value={puesto} disabled={cargando || error} type="text" rows={3} style={{ resize: 'none' }} placeholder="Ingrese el puesto de trabajo del lead" name="Puesto" onChange={onChange} />
                            <Form.Control.Feedback>Ok, este campo no es obligatorio</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group className="mb-3" controlId="Institucion">
                            <Form.Label>Nombre de la institución (opcional)</Form.Label>
                            <Form.Control value={institucion} disabled={cargando || error} type="text" placeholder="Ingrese la institución lead" name="Institucion" onChange={onChange} />
                            <Form.Control.Feedback>Ok, este campo no es obligatorio</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group className="mb-3" controlId="Correo">
                            <Form.Label>Correo electrónico (opcional)</Form.Label>
                            <Form.Control value={correo} disabled={cargando || error} type="text" placeholder="Ingrese el correo del lead" name="Correo" onChange={onChange} />
                            <Form.Control.Feedback>Ok, este campo no es obligatorio</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group className="mb-3" controlId="Telefono">
                            <Form.Label>Teléfono (opcional)</Form.Label>
                            <Form.Control value={telefono} disabled={cargando || error} type="text" placeholder="Ingrese el teléfono del lead" name="Telefono" onChange={onChange} />
                            <Form.Control.Feedback>Ok, este campo no es obligatorio</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group className="mb-3" controlId="origen">
                            <Form.Label>Origen</Form.Label>
                            <Form.Control value={origen} as="select" disabled={cargando || error} required aria-label="origen" name="Origen" onChange={onChange}>
                                <option value="" key="-1">Seleccione el origen del lead</option>
                                {origenes.map((origen) =>
                                    <option value={origen.UniqueID} key={origen.UniqueID}>{origen.nombre}</option>
                                )}
                            </Form.Control>
                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                            <Form.Control.Feedback type='invalid'>Por favor, completa este campo</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group className="mb-3" controlId="origen">
                            <Form.Label>Evento de interés del Lead</Form.Label>
                            <Form.Control value={evento} disabled={cargando || error} as="select" required aria-label="origen" name="Evento" onChange={onChange}>
                                <option value="" key="-1">Seleccione el evento de interés</option>
                                {eventos.map((evento) =>
                                    <option value={evento.UniqueID} key={evento.UniqueID}>{evento.Nombre + ' (' + evento.fechaInicioMes + ')'}</option>
                                )}
                            </Form.Control>
                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                            <Form.Control.Feedback type='invalid'>Por favor, completa este campo</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row>
                        {(!error) && <Button variant="outline-secondary" className="font-bold" type="submit" disabled={cargando || error}>Guardar</Button>}
                        {(error) && <Button variant="outline-secondary" className="font-bold" onClick={() => { setError(false); setCambios(cambios + 1); }} disabled={cargando || error}>Intentar de nuevo</Button>}
                    </Row>
                </Form>
            </Row>
        </Container>
    )
}

export default NuevoLead