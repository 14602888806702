import React, { useState, useEffect } from 'react'
//import DEFAULT_URL from '../../../../api/url'
import { Row, Col, ListGroup, Tab, Container } from "react-bootstrap";
import './Common.css'
import Inscripciones from './Inscripciones';
import ModalMaterial from '../../../Eventos/Evento-Detalle/Modal-Material/ModalMaterial';
const Procesadas = (props) => {
    const [eventos, setEventos] = useState([])
    const [contador, setContador] = useState(0);
    const [modalMaterial, setModalMaterial] = useState(false);
    const [id_actual, setIdActual] = useState('');
    useEffect(async () => {
        setEventos([]);
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/evento/asesor', { cache: "no-cache" })
            .then((res) => {
                return res.json();
            })
            .then(data => {
                setEventos(data.rows);
            });
    }, [contador]);
    return (
        <Container style={{ color: 'white' }}>
            <Row>
                <Col xs={1} md={1} lg={1}>
                    <div style={{ color: 'white', cursor: 'pointer' }} data-toggle="tooltip" data-placement="top" title="Refrescar" onClick={() => setContador(contador + 1)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                            <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                        </svg>
                    </div>
                </Col>
            </Row>
            <Row style={{ marginTop: '35px' }}>
                <Tab.Container id="list-group-tabs-example">
                    <Row>
                        <Col sm={4} className="columna-eventos-procesadas">
                            <ListGroup>
                                {eventos.map((evento) =>
                                    <ListGroup.Item action href={"#" + evento.UniqueID} style={{ textAlign: 'left' }} className="tab-cartera cliente">
                                        {evento.Nombre}
                                    </ListGroup.Item>

                                )}
                            </ListGroup>
                        </Col>
                        <Col sm={8} className="columna-eventos-procesadas">
                            <Tab.Content>
                                {eventos.map((evento) =>
                                    <Tab.Pane eventKey={"#" + evento.UniqueID}>
                                        <Container>
                                            <Row>
                                                <Col xs={1} md={1}>
                                                    <div style={{ cursor: 'pointer', width: '20%', color: 'white' }} data-toggle="tooltip" data-placement="top" title="Material" onClick={() => { setModalMaterial(true); setIdActual(evento.UniqueID); setContador(contador + 1); }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-inboxes-fill" viewBox="0 0 16 16">
                                                            <path d="M4.98 1a.5.5 0 0 0-.39.188L1.54 5H6a.5.5 0 0 1 .5.5 1.5 1.5 0 0 0 3 0A.5.5 0 0 1 10 5h4.46l-3.05-3.812A.5.5 0 0 0 11.02 1H4.98zM3.81.563A1.5 1.5 0 0 1 4.98 0h6.04a1.5 1.5 0 0 1 1.17.563l3.7 4.625a.5.5 0 0 1 .106.374l-.39 3.124A1.5 1.5 0 0 1 14.117 10H1.883A1.5 1.5 0 0 1 .394 8.686l-.39-3.124a.5.5 0 0 1 .106-.374L3.81.563zM.125 11.17A.5.5 0 0 1 .5 11H6a.5.5 0 0 1 .5.5 1.5 1.5 0 0 0 3 0 .5.5 0 0 1 .5-.5h5.5a.5.5 0 0 1 .496.562l-.39 3.124A1.5 1.5 0 0 1 14.117 16H1.883a1.5 1.5 0 0 1-1.489-1.314l-.39-3.124a.5.5 0 0 1 .121-.393z" />
                                                        </svg>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <h1 style={{ marginBottom: '25px', marginTop: '25px', color: 'white' }}>Datos del evento</h1>
                                                <ListGroup as="ol">
                                                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                                        <div className="ms-2 me-auto">
                                                            <div className="fw-bold">
                                                                Evento
                                                            </div>
                                                        </div>
                                                        {evento.Nombre}
                                                    </ListGroup.Item>
                                                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                                        <div className="ms-2 me-auto">
                                                            <div className="fw-bold">
                                                                Fecha de inicio del evento
                                                            </div>
                                                        </div>
                                                        {evento.Fecha_Inicio}
                                                    </ListGroup.Item>
                                                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                                        <div className="ms-2 me-auto">
                                                            <div className="fw-bold">
                                                                Fecha de finalización del evento
                                                            </div>
                                                        </div>
                                                        {evento.Fecha_Fin}
                                                    </ListGroup.Item>
                                                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                                        <div className="ms-2 me-auto">
                                                            <div className="fw-bold">
                                                                Hora del evento
                                                            </div>
                                                        </div>
                                                        {evento.Hora}
                                                    </ListGroup.Item>
                                                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                                        <div className="ms-2 me-auto">
                                                            <div className="fw-bold">
                                                                Mínimo de participantes
                                                            </div>
                                                        </div>
                                                        {evento.Minimo}
                                                    </ListGroup.Item>
                                                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                                        <div className="ms-2 me-auto">
                                                            <div className="fw-bold">
                                                                Tipo de evento
                                                            </div>
                                                        </div>
                                                        {evento.tipo}
                                                    </ListGroup.Item>
                                                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                                        <div className="ms-2 me-auto">
                                                            <div className="fw-bold">
                                                                Estado del evento
                                                            </div>
                                                        </div>
                                                        {evento.estado}
                                                    </ListGroup.Item>
                                                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                                        <div className="ms-2 me-auto">
                                                            <div className="fw-bold">
                                                                Modalidad del evento
                                                            </div>
                                                        </div>
                                                        {evento.modalidad}
                                                    </ListGroup.Item>
                                                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                                        <div className="ms-2 me-auto">
                                                            <div className="fw-bold">
                                                                Días a impartir
                                                            </div>
                                                        </div>
                                                        {evento.dias}
                                                    </ListGroup.Item>
                                                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                                        <div className="ms-2 me-auto">
                                                            <div className="fw-bold">
                                                                Duración del evento
                                                            </div>
                                                        </div>
                                                        {evento.duracion}
                                                    </ListGroup.Item>
                                                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                                        <div className="ms-2 me-auto">
                                                            <div className="fw-bold">
                                                                Número de sesiones del evento
                                                            </div>
                                                        </div>
                                                        {evento.sesiones}
                                                    </ListGroup.Item>
                                                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                                        <div className="ms-2 me-auto">
                                                            <div className="fw-bold">
                                                                Precio para agremiados
                                                            </div>
                                                        </div>
                                                        {evento.precio_agremiados}
                                                    </ListGroup.Item>
                                                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                                        <div className="ms-2 me-auto">
                                                            <div className="fw-bold">
                                                                Precio para no agremiados
                                                            </div>
                                                        </div>
                                                        {evento.precio_no_agremiados}
                                                    </ListGroup.Item>
                                                </ListGroup>
                                            </Row>
                                            <Row>
                                                <h1 style={{ marginBottom: '25px', marginTop: '25px', color: 'white' }}>Listado inscripciones</h1>
                                                <Inscripciones UniqueID={evento.UniqueID} asesor={props.uniqueID} />
                                            </Row>
                                        </Container>
                                    </Tab.Pane>
                                )}
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Row>
            <ModalMaterial
                show={modalMaterial}
                onHide={() => { setModalMaterial(false); setContador(contador + 1); }}
                evento_uniqueid={id_actual}
                admin={true}
                rol={1}
            />
        </Container>
    )
}

export default Procesadas