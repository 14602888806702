import React, { Component } from 'react';
import {  Container } from "react-bootstrap";
import "./Participantes.css"
import { Participante } from '../Participante/Participante'
export class Participantes extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div style={{ width: '100%' }}>
                <Container>
                    {this.props.participantes.map(prcpnt => <Participante participante={prcpnt} key={prcpnt.No} deleteparticipante={this.props.deleteparticipante} eventocambio={this.props.eventocambio} paises={this.props.paises} inversion={this.props.inversion} />)}
                </Container>
            </div>
        );
    }
}
