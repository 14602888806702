import React, { useState, useEffect } from 'react';
import { Row, Popover, OverlayTrigger, Tabs, Tab, Button, Col, Form, Container, ListGroup } from "react-bootstrap";
import axios from 'axios';
import { ErrorModal } from '../../../Utils/ErrorModal/ErrorModal';
import { SuccessModal } from '../../../Utils/SuccessModal/SuccessModal';
import { Confirmacion } from '../../../Utils/ConfirmationModal/Confirmacion';
//import DEFAULT_URL from '../../../../api/url';
//import { DOWNLOAD_URL }} from '../../../../api/url';
import { ModalCargando } from '../../../Utils/Modal-Cargando/ModalCargando';
//import { SELF_URL } from '../../../../api/url';
import ModalEditarMaterial from './ModalEditarMaterial';
import ModalEditarCarpeta from './ModalEditarCarpeta';
import ModalVistaPrevia from './ModalVistaPrevia';
import './ModalMaterial.css'
const DetalleCarpeta = (props) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [loaded, setLoaded] = useState(0);
    const [nombre, setNombre] = useState('');
    const [modalSucc, setModalSucc] = useState(false);
    const [modalError, setModalError] = useState(false);
    const [mensaje, setMensaje] = useState('');
    const [pathActual, setPathActual] = useState('');
    const [consideracion, setConsideracion] = useState('');
    const [enlace, setEnlace] = useState('');
    const [id_actual, setId_Actual] = useState('');
    const [llevaEnlace, setLlevaEnlace] = useState(false);
    const [mensajeConfirmacion, setMensajeConfirmacion] = useState(false);
    const [mensajeCargando, setMensajeCargando] = useState('');
    const [modalCargando, setModalCargando] = useState(false);
    const [contador, setContador] = useState(0);
    const [materiales, setMateriales] = useState([]);
    const [confirmacionEliminar, setConfirmacionEliminar] = useState(false);
    const [editarCarpeta, setEditarCarpeta] = useState(false);
    const [promocional, setPromocional] = useState('');
    const [segundos, setSegundos] = useState(0);
    const [editarMaterial, setEditarMaterial] = useState(false);
    const [nombre_actual, setNombre_actual] = useState('');
    const [promocional_actual, setPromocional_actual] = useState('');
    const [segundos_actual, setSegundosActual] = useState(0);
    const [modalVistaPrevia, setModalVistaPrevia] = useState(false);
    const [url, setUrl] = useState('');
    const popover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">¡Listo!</Popover.Header>
            <Popover.Body>
                Enlace copiado exitosamente.
            </Popover.Body>
        </Popover>
    );
    useEffect(() => {
        async function fetchData() {
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/material/carpetas/materiales/' + props.carpeta, { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setMateriales(data.rows);
                });
        }
        fetchData();
    }, [contador])

    const onChangeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setLoaded(0);
    };

    const onChange = (event) => {
        if (event.target.name == "nombre") {
            setNombre(event.target.value);
        } else if (event.target.name == "segundos") {
            setSegundos(event.target.value);
        } else {
            setPromocional(event.target.value);
        }

    }
    const ocultarModalCargando = () => {
        setModalCargando(false);
        setContador(contador + 1);
    }
    const submitForm = (evt) => {
        evt.preventDefault();
        const data = new FormData();
        data.append('file', selectedFile);
        setMensajeCargando('Se está subiendo el archivo al servidor, por favor espere.');
        setModalCargando(true);
        axios.post(process.env.REACT_APP_DEFAULT_URL+ "/api/files/upload", data, {
        }).then(res => {
            if (res.status == 200) {
                const info = { Nombre: nombre, Path: res.data.res, carpeta: props.carpeta, promocional: promocional, segundos: segundos };
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(info)
                };
                fetch(process.env.REACT_APP_DEFAULT_URL + '/api/create/material', requestOptions)
                    .then((res) => {
                        return res.json();
                    })
                    .then(data => {
                        if (data.codigo == 200) {
                            setModalCargando(false);
                            setMensaje('Archivo almacenado exitosamente');
                            setContador(contador + 1);
                            setModalSucc(true);
                        } else {
                            setMensaje(data.respuesta);
                            setModalError(true);
                        }
                        Array.from(document.querySelectorAll("input")).forEach(
                            input => (input.value = "")
                        );
                    });
            } else {
                setMensaje(res.data);
                setModalError(true);
            }
        });
    }
    const ocultar = () => {
        setModalError(false);
        setModalSucc(false);
        setContador(contador + 1);
        props.refrescar();
    }

    const deleteFile = (path, id) => {
        var confirmatinomessage = "¿Está segur@ que desea elimnar el archivo " + path;
        setConsideracion('Esta acción no se puede revertir');
        setMensaje(confirmatinomessage);
        setId_Actual(id);
        setPathActual(path);
        setMensajeConfirmacion(true);
    }
    const accionEliminarCarpeta = (id, path) => {
        setConfirmacionEliminar(false);
        const info = { UniqueID: props.carpeta };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/delete/carpeta', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                if (data.codigo == 200) {
                    setMensaje('Carpeta eliminada exitosamente');
                    setModalSucc(true);
                } else {
                    setMensaje(data.respuesta);
                    setModalError(true);
                }
                setContador(contador + 1);
            });
    }

    const accioneliminarmaterial = (id, path) => {
        setMensajeConfirmacion(false);
        const info = { "UniqueID": id, "path": path };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/delete/material', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                if (data.codigo == 200) {
                    setMensaje('Material eliminado exitosamente');
                    setModalSucc(true);
                } else {
                    setMensaje(data.respuesta);
                    setModalError(true);
                }
                setContador(contador + 1);
            });
    }
    return (
        <div style={{ height: '100%' }}>
            <Container >
                <Row style={{ marginBottom: '25px' }}>
                    {
                        (props.permiso == 5 && props.rol != 1) &&
                        <Col xs={1} md={1} lg={1}>
                            <div style={{ color: 'white', cursor: 'pointer' }} data-toggle="tooltip" data-placement="top" title="Eliminar Carpeta" onClick={() => { setMensaje('¿Está segur@ de elminar la carpeta seleccionada?'); setConsideracion('Esta acción no se puede revertir'); setConfirmacionEliminar(true); }} >
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-trash3-fill" viewBox="0 0 16 16">
                                    <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                                </svg>
                            </div>
                        </Col>
                    }
                    {
                        props.permiso == 5 &&
                        <Col xs={1} md={1} lg={1}>
                            <div style={{ color: 'white', cursor: 'pointer' }} data-toggle="tooltip" data-placement="top" title="Actualizar Carpeta" onClick={() => setEditarCarpeta(true)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-pencil-fill" viewBox="0 0 16 16">
                                    <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                </svg>
                            </div>
                        </Col>
                    }
                    {
                        (props.permiso == 5 && materiales.length > 0) &&
                        <Col xs={1} md={1} lg={1}>
                            <a href={process.env.REACT_APP_SELF_URL + '/carpetas/' + props.correlativo} target="_blank" data-toggle="tooltip" data-placement="top" title="Ver carpeta" style={{ cursor: 'pointer', color: 'white' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16">
                                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                    <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                </svg>
                                <i className="bi bi-cloud-arrow-down-fill"></i>
                            </a>
                        </Col>
                    }
                    {
                        (props.permiso == 5 && materiales.length > 0) &&
                        < Col xs={1} md={1} lg={1}>
                            <OverlayTrigger trigger="click" placement="left" overlay={popover} rootClose>
                                <div style={{ color: 'white', cursor: 'pointer' }} data-toggle="tooltip" data-placement="top" title="Copiar enlace de carpeta" onClick={() => navigator.clipboard.writeText(process.env.REACT_APP_SELF_URL + '/carpetas/' + props.correlativo)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-clipboard-check" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                                        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                                        <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                                    </svg>
                                </div>
                            </OverlayTrigger>
                        </Col>
                    }
                    <Col xs={1} md={1} lg={1}>
                        <div style={{ color: 'white', cursor: 'pointer' }} data-toggle="tooltip" data-placement="top" title="Refrescar" onClick={() => setContador(contador + 1)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                                <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                            </svg>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Tabs defaultActiveKey="detalle" id="uncontrolled-tab-example" className="mb-3" variant='pills'>
                        {((props.permiso == 1 || props.permiso == 2 || props.permiso == 4 || props.permiso == 5 || props.permiso == 6) && props.rol != 1) &&
                            <Tab tabClassName='tab-material' eventKey="formulario" title="Subir Material">
                                <Container>
                                    <Form onSubmit={submitForm}>
                                        <Row>
                                            <Form.Group className="mb-3" controlId="material">
                                                <Form.Label>Nombre del material</Form.Label>
                                                <Form.Control required type="text" name="nombre" placeholder="Ingrese el nombre del material" onChange={onChange} />
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Form.Group controlId="formFileMultiple" className="mb-3">
                                                <Form.Label>Seleccione el archivo que desee adjuntar como material al evento</Form.Label>
                                                <Form.Control required type="file" onChange={onChangeHandler} />
                                            </Form.Group>
                                        </Row>
                                        {props.permiso == 4 &&
                                            <Row>
                                                <Form.Group className="mb-3" controlId="promocional">
                                                    <Form.Label>Imagen promocional para APP</Form.Label>
                                                    <Form.Control required type="text" name="promocional" placeholder="Ingrese el url de la imagen promocional" onChange={onChange} />
                                                </Form.Group>
                                            </Row>
                                        }
                                        {
                                            props.permiso == 6 &&
                                            <Row>
                                                <Form.Group className="mb-3" controlId="promocional">
                                                    <Form.Label>Duración en espacio publicitario de APP</Form.Label>
                                                    <Form.Control required readonly type="number" name="segundos" placeholder="tiempo en segundos" onChange={onChange} />
                                                </Form.Group>
                                            </Row>
                                        }
                                        <Row>
                                            <Col>
                                                <Button variant="light" className="font-bold" type="submit">Subir Material</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Container>
                            </Tab>
                        }
                        <Tab tabClassName='tab-material' eventKey="detalle" title="Ver Material">
                            <Container>
                                <Row>
                                    <ListGroup as="ol" numbered>
                                        {
                                            materiales.map((material) =>
                                                <ListGroup.Item key={material.UniqueID}
                                                    as="li"
                                                    className="d-flex justify-content-between align-items-start"
                                                    style={{ marginBottom: '20px', borderRadius: '20px' }}
                                                >
                                                    <div className="ms-2 me-auto">
                                                        <div className="fw-bold">{material.Nombre}</div>
                                                        {material.Path}<br />
                                                        <p>ESTADO: {material.estado == 0 && <strong>no procesado</strong>}{material.estado == 1 && <strong>procesado</strong>}</p>
                                                        {(material.estado == 1 && material.ret != null) && <p>RETROALIMENTACIÓN: {material.ret}</p>}
                                                    </div>
                                                    <div data-toggle="tooltip" data-placement="top" title="Vista Previa" style={{ cursor: 'pointer', paddingRight: '10px' }} onClick={() => { setModalVistaPrevia(true); setUrl(process.env.REACT_APP_DOWNLOAD_URL + material.Path); setContador(contador + 1); }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16">
                                                            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                                            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                                        </svg>
                                                    </div>
                                                    {(material.estado == 1 && (props.permiso == 1 || props.permiso == 2 || props.permiso == 4 || props.permiso == 5 || props.permiso == 6) && props.rol != 1) &&
                                                        <div data-toggle="tooltip" data-placement="top" title="Eliminar Archivo" style={{ cursor: 'pointer', paddingRight: '10px' }} onClick={deleteFile.bind(this, material.Path, material.UniqueID)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3-fill" viewBox="0 0 16 16">
                                                                <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                                                            </svg>
                                                        </div>
                                                    }
                                                    {
                                                        (props.permiso != 3 && props.rol != 1) &&
                                                        <div data-toggle="tooltip" data-placement="top" title="Editar material" style={{ cursor: 'pointer', paddingRight: '10px', color: 'black' }} onClick={() => {
                                                            setEditarMaterial(true); setId_Actual(material.UniqueID); setNombre_actual(material.Nombre); if (props.permiso == 4) { setPromocional_actual(material.imagen_promocional); } else if (props.permiso == 6) { setSegundosActual(material.segundos) } else { setPromocional_actual(null); } setContador(contador + 1);
                                                        }}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-fill" viewBox="0 0 16 16">
                                                                <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                                            </svg>
                                                        </div>
                                                    }
                                                    <a href={process.env.REACT_APP_DOWNLOAD_URL + material.Path} target="_blank" data-toggle="tooltip" data-placement="top" title="Descargar Archivo" style={{ cursor: 'pointer', color: 'black' }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-cloud-arrow-down-fill" viewBox="0 0 16 16">
                                                            <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 6.854-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5a.5.5 0 0 1 1 0v3.793l1.146-1.147a.5.5 0 0 1 .708.708z" />
                                                        </svg>
                                                        <i className="bi bi-cloud-arrow-down-fill"></i>
                                                    </a>
                                                    <OverlayTrigger trigger="click" placement="left" overlay={popover} rootClose>
                                                        <div data-toggle="tooltip" data-placement="top" title="Copiar Enlace De Archivo" style={{ cursor: 'pointer', paddingLeft: '10px' }} onClick={() => navigator.clipboard.writeText(process.env.REACT_APP_DOWNLOAD_URL + material.Path)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard-check" viewBox="0 0 16 16">
                                                                <path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                                                                <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                                                                <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                                                            </svg>
                                                        </div>
                                                    </OverlayTrigger>
                                                    {
                                                        (props.permiso == 4 && props.rol != 1) &&
                                                        <div data-toggle="tooltip" data-placement="top" title="Ver imagen promocional" style={{ cursor: 'pointer', paddingLeft: '10px', color: 'black' }}>
                                                            <a href={material.imagen_promocional} target="_blank" style={{ color: 'black' }}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-image-fill" viewBox="0 0 16 16">
                                                                    <path d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707v5.586l-2.73-2.73a1 1 0 0 0-1.52.127l-1.889 2.644-1.769-1.062a1 1 0 0 0-1.222.15L2 12.292V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zm-1.498 4a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0z" />
                                                                    <path d="M10.564 8.27 14 11.708V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-.293l3.578-3.577 2.56 1.536 2.426-3.395z" />
                                                                </svg>
                                                            </a>
                                                        </div>
                                                    }
                                                </ListGroup.Item>
                                            )
                                        }
                                    </ListGroup>
                                </Row>
                            </Container>
                        </Tab>
                    </Tabs>
                </Row>
                <SuccessModal
                    color={"#b5bad0"}
                    show={modalSucc}
                    onHide={ocultar}
                    mensaje={mensaje}
                    consideracion={consideracion}
                    enlace={enlace}
                    llevaenlace={llevaEnlace}
                />
                <ErrorModal
                    color={"#b5bad0"}
                    show={modalError}
                    onHide={ocultar}
                    mensaje={mensaje}
                    consideracion={consideracion}
                />
                <Confirmacion
                    show={mensajeConfirmacion}
                    onHide={() => setMensajeConfirmacion(false)}
                    accion={accioneliminarmaterial}
                    mensaje={mensaje}
                    uniqueid={id_actual}
                    consideracion={consideracion}
                    param={pathActual}
                />
                <Confirmacion
                    show={confirmacionEliminar}
                    onHide={() => setConfirmacionEliminar(false)}
                    accion={accionEliminarCarpeta}
                    mensaje={mensaje}
                    uniqueid={''}
                    consideracion={consideracion}
                    param={''}
                />
                <ModalCargando
                    color={"#b5bad0"}
                    show={modalCargando}
                    onHide={ocultarModalCargando}
                    mensaje={mensajeCargando}
                />
                <ModalEditarCarpeta show={editarCarpeta} onHide={() => setEditarCarpeta(false)} nombre={props.nombre_carpeta} UniqueId={props.carpeta} refrescar={props.refrescar} />
                <ModalEditarMaterial show={editarMaterial} onHide={() => setEditarMaterial(false)} refrescar={() => setContador(contador + 1)} permiso={props.permiso} UniqueID={id_actual} nombre={nombre_actual} promocional={promocional_actual} segundos={segundos_actual} />
                <ModalVistaPrevia show={modalVistaPrevia} onHide={() => { setModalVistaPrevia(false); setContador(contador + 1); }} url={url} />
            </Container>
        </div >
    )
}

export default DetalleCarpeta