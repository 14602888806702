import React, { Component } from 'react';
import { Modal, Row, Button, Container, Form } from "react-bootstrap";
import { Participantes } from './Participantes/Participantes';
import './ModalEditarParticipantes.css'
export class ModalEditarParticipantes extends Component {
    render() {
        return (
            <Modal
                {...this.props}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
                style = {{color:'white'}}
            >
                <Form onSubmit={this.props.submitchangesparticipantes} >
                    <Modal.Header closeButton style={{ backgroundColor: "#1e3d52", borderColor:'transparent' }}>
                        <Modal.Title id="contained-modal-title-vcenter" className="font-bold">
                            Información de participantes
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ backgroundColor: "#1e3d52", borderColor:'transparent', height: '60vh',overflowX:'hidden' }} id="body-modal-edit-participantes" className ="modal-body-acordion">
                        <Container>
                            {/* Stack the columns on mobile by making one full-width and the other half-width */}
                            <Row>
                                <Participantes participantes={this.props.participantes} deleteparticipante={this.props.deleteparticipante} eventocambio={this.props.eventocambio} />
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer style={{ backgroundColor: "#1e3d52", borderColor:'transparent' }}>
                        <Button variant="light" className="font-bold" type = "submit">Guardar Cambios</Button>
                        <Button variant="secondary" className="font-bold" onClick={this.props.onHide}>Cerrar</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    }
}