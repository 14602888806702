import React, { Component } from 'react';
import { Modal, Button, Container } from "react-bootstrap";
import {
    ComposedChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';
export class ModalRendimiento extends Component {
    render() {
        return (
            <Modal
                {...this.props}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
            >
                <Modal.Header closeButton style={{ backgroundColor: "#E0E0E2" }}>
                    <Modal.Title id="contained-modal-title-vcenter" className="font-bold">
                        Rendimiento
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: "#E0E0E2", height: '60vh' }} id="body-modal">
                    <Container>
                        <ResponsiveContainer width="100%" height="100%">
                            <ComposedChart
                                width={500}
                                height={400}
                                data={this.props.data}
                                margin={{
                                    top: 10,
                                    right: 10,
                                    bottom: 10,
                                    left: 10,
                                }}
                            >
                                <CartesianGrid stroke="#f5f5f5" />
                                <XAxis dataKey="asesor" scale="band" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="inscritos" barSize={20} fill="#416788" />
                            </ComposedChart>
                        </ResponsiveContainer>
                    </Container>
                </Modal.Body>
                <Modal.Footer style={{ backgroundColor: "#E0E0E2" }}>
                    <Button variant="secondary" className="font-bold" onClick={this.props.onHide}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
