import React, { Component } from 'react';
import { Modal, Row, Popover, OverlayTrigger, Tabs, Tab, Button, Col, Form, Container, Accordion, ButtonToolbar, ButtonGroup, DropdownButton, InputGroup, Badge, ListGroup } from "react-bootstrap";
import axios from 'axios';
import { ErrorModal } from '../../Utils/ErrorModal/ErrorModal';
import { SuccessModal } from '../../Utils/SuccessModal/SuccessModal';
import { Confirmacion } from '../../Utils/ConfirmationModal/Confirmacion';
//import DEFAULT_URL from '../../../api/url';
//import {DOWNLOAD_URL} from '../../../api/url';
import { ModalCargando } from '../../Utils/Modal-Cargando/ModalCargando';

const popover = (
    <Popover id="popover-basic">
        <Popover.Header as="h3">¡Listo!</Popover.Header>
        <Popover.Body>
            Enlace copiado exitosamente.
        </Popover.Body>
    </Popover>
);
export class ModalArchivo extends Component {
    state = {
        selectedFile: null,
        loaded: 0,
        Nombre: '',
        modalSucc: false,
        modalError: false,
        Mensaje: '',
        pathactual: '',
        Consideracion: '',
        enlace: '',
        id_actual: '',
        llevaenlace: false,
        materiales: [],
        mensajeConfirmacion: false,
        mensajeCargando: '',
        modalCargando: false,
    };
    onChangeHandler = (event) => {
        this.setState({ selectedFile: event.target.files[0], loaded: 0 });
    };

    onChange = (event) => {
        this.setState({ Nombre: event.target.value });
    }

    ocultarModalCargando = () => {
        this.setState({ modalCargando: false });
    }
    submitForm = (evt) => {
        evt.preventDefault();
        const data = new FormData();
        data.append('file', this.state.selectedFile);
        this.setState({ modalCargando: true, mensajeCargando: 'Se está subiendo el archivo al servidor, por favor espere.' })
        axios.post(process.env.REACT_APP_DEFAULT_URL+ "/api/files/upload", data, {
        }).then(res => {
            if (res.status == 200) {
                const info = { Path: res.data.res, evento_Unique_ID: this.props.evento_Unique_ID, llave: this.props.llave };
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(info)
                };
                fetch(process.env.REACT_APP_DEFAULT_URL + '/api/create/material/inscripcion', requestOptions)
                    .then((res) => {
                        return res.json();
                    })
                    .then(data => {
                        if (data.codigo == 200) {
                            this.setState({ modalCargando: false });
                            this.setState({ Mensaje: 'Inscripcion almacenada exitosamente' });
                            this.setState({ modalSucc: true });
                        } else {
                            this.setState({ Mensaje: data.respuesta });
                            this.setState({ modalError: true });
                        }
                    });
            } else {
                this.setState({ Mensaje: res.data });
                this.setState({ modalError: true });
            }
        });
    }
    ocultar = () => {
        this.setState({ modalError: false, modalSucc: false });
        this.props.onHide();
    }

    render() {
        return (
            <Modal
                {...this.props}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
                backdrop='static'
            >
                <Modal.Header style={{ backgroundColor: "#E0E0E2" }}>
                    <Modal.Title id="contained-modal-title-vcenter" className="font-bold">
                        Subir Archivo Para Carga Masiva De Participantes
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: "#E0E0E2", height: '60vh' }} id="body-modal-edit-evento">
                    <Container>
                        <Form onSubmit={this.submitForm}>
                            <Row>
                                <a href={process.env.REACT_APP_DOWNLOAD_URL + "MUESTRA%20FORMULARIO%20DE%20INSCRIPCION.xlsx"} target="_blank" data-toggle="tooltip" data-placement="top" title="Descargar Archivo" style={{ cursor: 'pointer' }}>
                                    <p>Descarga la plantilla de carga masiva</p>

                                    <i className="bi bi-cloud-arrow-down-fill"></i>
                                </a>
                            </Row>
                            <Row>
                                <Form.Group controlId="formFileMultiple" className="mb-3">
                                    <Form.Label>Seleccione el archivo</Form.Label>
                                    <Form.Control required type="file" onChange={this.onChangeHandler} />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Col>
                                    <Button variant="secondary" className="font-bold" type="submit">Inscribir</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Container>
                    <SuccessModal
                        color={"#b5bad0"}
                        show={this.state.modalSucc}
                        onHide={this.ocultar}
                        mensaje={this.state.Mensaje}
                        consideracion={this.state.Consideracion}
                        enlace={this.state.enlace}
                        llevaenlace={this.state.llevaenlace}
                    />
                    <ErrorModal
                        color={"#b5bad0"}
                        show={this.state.modalError}
                        onHide={this.ocultar}
                        mensaje={this.state.Mensaje}
                        consideracion={this.state.Consideracion}
                    />
                    <ModalCargando
                        color={"#b5bad0"}
                        show={this.state.modalCargando}
                        onHide={this.ocultarModalCargando}
                        mensaje={this.state.mensajeCargando}
                    />
                </Modal.Body>
            </Modal>
        );
    }
}