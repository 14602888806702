import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom'
//import DEFAULT_URL from '../../../api/url';
//import { SELF_URL } from '../../../api/url';
const EditLead = () => {
    const [contador] = useState(0);
    const [lead, setLead] = useState({});
    const { id } = useParams();
    const [cambios, setCambios] = useState(0);
    const [origenes, setOrigenes] = useState([]);
    const [nombre, setNombre] = useState('');
    const [puesto, setPuesto] = useState('');
    const [institucion, setInstitucion] = useState('');
    const [correo, setCorreo] = useState('');
    const [telefono, setTelefono] = useState('');
    const [origen, setOrigen] = useState('');
    const [usuario, setUsuario] = useState({});
    const [validated, setValidated] = useState(false);
    const [eventos, setEventos] = useState([]);
    const [evento, setEvento] = useState('');
    const [cargando, setCargando] = useState(false);
    const [exito, setExito] = useState(false);
    const [error, setError] = useState(false);
    useEffect(() => {
        async function fetchData() {
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/lead/' + id, { cache: "no-cache", credentials: 'include' })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setNombre(data.rows[0].nombre);
                    setPuesto(data.rows[0].puesto);
                    setInstitucion(data.rows[0].institucion);
                    setCorreo(data.rows[0].correo);
                    setTelefono(data.rows[0].telefono);
                    setOrigen(data.rows[0].origen_id);
                    setEvento(data.rows[0].evento_UniqueID);
                    setLead(data.rows[0]);
                });
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/leads/origen', { cache: "no-cache", credentials: 'include' })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setOrigenes(data.rows);
                });
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/auth/userinfo', { cache: "no-cache", credentials: 'include' })
                .then((res) => {
                    if (res.status == 401) {
                        window.location.reload();
                    } else {
                        return res.json();
                    }
                })
                .then(data => {
                    setUsuario(data);
                });
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/evento/inscripciones/abiertas', { cache: "no-cache", credentials: 'include' })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setEventos(data.rows);
                });
        }
        fetchData();
    }, [contador]);
    useEffect(() => {

    }, [cambios]);

    const onChange = (evt) => {
        switch (evt.target.name) {
            case "Nombre":
                setNombre(evt.target.value);
                break;
            case "Puesto":
                setPuesto(evt.target.value);
                break;
            case "Institucion":
                setInstitucion(evt.target.value);
                break;
            case "Correo":
                setCorreo(evt.target.value);
                break;
            case "Telefono":
                setTelefono(evt.target.value);
                break;
            case "Origen":
                setOrigen(evt.target.value);
                break;
            case "Evento":
                setEvento(evt.target.value);
                break;
        }
        setCambios(cambios + 1);
    }
    const submit = (evt) => {
        const form = evt.currentTarget;
        if (form.checkValidity() === false) {
            evt.preventDefault();
            evt.stopPropagation();
        } else {
            setCargando(true);
            setCambios(cambios + 1);
            evt.preventDefault();
            const info = { Nombre: nombre, Puesto: puesto, Institucion: institucion, Correo: correo, Telefono: telefono, Origen: origen, Asesor: usuario.UniqueID, evento_UniqueID: evento, UniqueID: id };
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(info)
            };
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/update/leads', requestOptions)
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setCargando(false);
                    setCambios(cambios + 1);
                    if (data.codigo == 200) {
                        //crm/seguimiento/view/:id
                        window.location.replace(process.env.REACT_APP_SELF_URL + '/crm/seguimiento/view/' + id);
                        setExito(true);
                        setCambios(cambios + 1);
                    } else {
                        setError(true);
                        setCambios(cambios + 1);
                    }
                });
        }
        setValidated(true);
        setCambios(cambios + 1);
    }
    return (
        <Container fluid>
            <Row style={{ padding: '5%' }}>
                <Container>
                    <Row style={{ padding: '5%', borderBottom: '5px solid #1e3d52', borderLeft: '5px solid #1e3d52', backgroundColor: 'rgb(230,230,230)' }}>
                        <Col xs={12} md={6} lg={4}>
                            <Card style={{ width: '100%' }}>
                                <Card.Header>
                                    {lead != null && <Card.Title>id: {lead.UniqueID}</Card.Title>}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="currentColor" className="bi bi-file-earmark-person-fill" viewBox="0 0 16 16">
                                        <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0zm2 5.755V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-.245S4 12 8 12s5 1.755 5 1.755z" />
                                    </svg>
                                </Card.Header>
                            </Card>
                        </Col>
                        <Col xs={12} md={6} lg={8} style={{ textAlign: 'left' }}>
                            <h1>Seguimiento de lead</h1>
                            {lead != null && <p>
                                INFORMACIÓN
                                <br />
                                <strong>Fecha de ingreso: </strong>{lead.fecha}
                                <br />
                                <strong>Nombre: </strong>{lead.nombre}
                                <br />
                                <strong>Puesto: </strong>{lead.puesto}
                                <br />
                                <strong>Institución: </strong>{lead.institucion}
                                <br />
                                <strong>Email: </strong>{lead.correo}
                                <br />
                                <strong>Teléfono: </strong>{lead.telefono}
                                <br />
                                <strong>Origen de lead: </strong>{lead.origen}
                                <br />
                                <strong>Evento de interés: </strong>{lead.evento}
                                <br />
                                <strong>Asesor que ingresó lead: </strong>{lead.asesor}
                            </p>}
                        </Col>
                    </Row>
                </Container>
            </Row>
            <Row style={{ paddingTop: '2%', paddingBottom: '2%', paddingLeft: '15%', paddingRight: '15%', textAlign: 'left' }}>
                <h2>Editar información</h2>
                <Form onSubmit={submit} noValidate validated={validated} >
                    <Row>
                        <Form.Group className="mb-3" controlId="Nombre">
                            <Form.Label>Nombre de la persona que solicita el evento</Form.Label>
                            <Form.Control disabled={cargando || error} required type="text" placeholder="Ingrese el nombre del lead" name="Nombre" value={nombre} onChange={onChange} />
                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                            <Form.Control.Feedback type='invalid'>Por favor, completa este campo</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group className="mb-3" controlId="Direccion">
                            <Form.Label>Puesto de trabajo (opcional)</Form.Label>
                            <Form.Control disabled={cargando || error} type="text" rows={3} style={{ resize: 'none' }} value={puesto} placeholder="Ingrese el puesto de trabajo del lead" name="Puesto" onChange={onChange} />
                            <Form.Control.Feedback>Ok, este campo no es obligatorio</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group className="mb-3" controlId="Institucion">
                            <Form.Label>Nombre de la institución (opcional)</Form.Label>
                            <Form.Control disabled={cargando || error} type="text" placeholder="Ingrese la institución lead" value={institucion} name="Institucion" onChange={onChange} />
                            <Form.Control.Feedback>Ok, este campo no es obligatorio</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group className="mb-3" controlId="Correo">
                            <Form.Label>Correo electrónico (opcional)</Form.Label>
                            <Form.Control disabled={cargando || error} type="text" placeholder="Ingrese el correo del lead" name="Correo" value={correo} onChange={onChange} />
                            <Form.Control.Feedback>Ok, este campo no es obligatorio</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group className="mb-3" controlId="Telefono">
                            <Form.Label>Teléfono (opcional)</Form.Label>
                            <Form.Control disabled={cargando || error} type="text" placeholder="Ingrese el teléfono del lead" name="Telefono" value={telefono} onChange={onChange} />
                            <Form.Control.Feedback>Ok, este campo no es obligatorio</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group className="mb-3" controlId="origen">
                            <Form.Label>Origen</Form.Label>
                            <Form.Control as="select" disabled={cargando || error} required aria-label="origen" name="Origen" value={origen} onChange={onChange}>
                                <option value="" key="-1">Seleccione el origen del lead</option>
                                {origenes.map((origen) =>
                                    <option value={origen.UniqueID} key={origen.UniqueID}>{origen.nombre}</option>
                                )}
                            </Form.Control>
                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                            <Form.Control.Feedback type='invalid'>Por favor, completa este campo</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group className="mb-3" controlId="origen">
                            <Form.Label>Evento de interés del Lead</Form.Label>
                            <Form.Control disabled={cargando || error} as="select" required aria-label="evento" name="Evento" value={evento} onChange={onChange}>
                                <option value="" key="-1">Seleccione el evento de interés</option>
                                {eventos.map((evento) =>
                                    <option value={evento.UniqueID} key={evento.UniqueID}>{evento.Nombre + ' (' + evento.fechaInicioMes + ')'}</option>
                                )}
                            </Form.Control>
                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                            <Form.Control.Feedback type='invalid'>Por favor, completa este campo</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row>
                        {(!error) && <Button variant="outline-secondary" className="font-bold" type="submit" disabled={cargando || error}>Guardar</Button>}
                        {(error) && <Button variant="outline-secondary" className="font-bold" onClick={() => { setError(false); setCambios(cambios + 1); }} disabled={cargando || error}>Intentar de nuevo</Button>}
                    </Row>
                </Form>
            </Row>
        </Container>
    )
}

export default EditLead