import React, { useEffect, useState } from 'react';
import { Row, Accordion, Table, Col, Container, ListGroup, Form, Alert, Popover, Button, OverlayTrigger } from "react-bootstrap";
import "./EventoDetalle.css"
import { ModalProyecciones } from './Modal-Proyecciones/ModalProyecciones';
import ModalEditarEvento from './Modal-Editar-Evento/ModalEditarEvento';
import { ModalRendimiento } from './Modal-Rendimiento/ModalRendimiento';
import { ModalEditarParticipantes } from './Modal-Editar-Participantes/ModalEditarParticipantes';
import { ErrorModal } from '../../Utils/ErrorModal/ErrorModal';
import { SuccessModal } from '../../Utils/SuccessModal/SuccessModal';
import { Confirmacion } from '../../Utils/ConfirmationModal/Confirmacion'
import ModalMaterial from './Modal-Material/ModalMaterial'
import ModalActividades from './Modal-Actividades/ModalActividades'
import { ModalIngresos } from './Modal-Ingresos/ModalIngresos';
import { ModalCargando } from '../../Utils/Modal-Cargando/ModalCargando';
import Formularios from './Formularios/Formularios';
//import DEFAULT_URL from '../../../api/url';
import ModalEnlaces from './Modal-Enlaces/ModalEnlaces';
//import { SELF_URL } from '../../../api/url';
import ModalPatrocinadores from './Modal-Patrocinadores/ModalPatrocinadores';
import axios from 'axios';
import DetalleInscripcion from '../../Contabilidad/Inscripciones/DetalleInscripcion';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import Notificaciones from './Notificaciones/Notificaciones';
const popover = (
    <Popover id="popover-basic">
        <Popover.Header as="h3">¡Listo!</Popover.Header>
        <Popover.Body>
            Enlace copiado exitosamente.
        </Popover.Body>
    </Popover>
);
const EventoDetalle = (props) => {
    const [modalMaterial, setModalMaterial] = useState(false);
    const [modalIngresos, setModalIngresos] = useState(false);
    const [modalShowProyecciones, setModalShowProyecciones] = useState(false);
    const [modalEditarEvento, setModalEditarEvento] = useState(false);
    const [modalActividades, setModalActividades] = useState(false);
    const [ventas, setVentas] = useState([]);
    const [total, setTotal] = useState(0);
    const [modalRendimiento, setModalRendimiento] = useState(false);
    const [modalSucc, setModalSucc] = useState(false);
    const [mensaje, setMensaje] = useState('');
    const [consideracion, setConsideracion] = useState('');
    const [modalError, setModalError] = useState(false);
    const [participantes, setParticipantes] = useState([]);
    const [modalEditarParticipantes, setModalEditarParticipantes] = useState(false);
    const [mensajeConfirmacion, setMensajeConfirmacion] = useState(false);
    const [id_actual, setId_actual] = useState('');
    const [mensajeCargando, setMensajeCargando] = useState('');
    const [modalCargando, setModalCargando] = useState(false);
    const [confirmacionEliminar, setConfirmacionEliminar] = useState(false);
    const [modalFormularios, setModalFormularios] = useState(false);
    const [contador, setContador] = useState(0);
    const [changeFlag, setChangeFlag] = useState(0);
    const [modalEnlaces, setModalEnlaces] = useState(false);
    const [confirmacionOcultarLanding, setConfirmacionOcultarLanding] = useState(false);
    const [confirmacionPublicarLanding, setConfirmacionPublicarLanding] = useState(false);
    const [modalPatrocinadores, setModalPatrocinadores] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedImg, setSelectedImg] = useState(null);
    const [asesores, setAsesores] = useState([]);

    useEffect(() => {
        async function fetchData() {
            setAsesores([]);
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/asesor', { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setAsesores(data.rows);
                });
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/evento/ventas/' + props.evento.UniqueID, { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setVentas(data.rows);
                    if (data.total[0] == null) {
                        setTotal(0);
                    } else {
                        setTotal(data.total[0].total);
                    }
                });
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/detalle_inscripcion/evento/' + props.evento.UniqueID, { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setParticipantes(data.rows);
                });
        }
        fetchData();
    }, [contador]);
    useEffect(() => {

    }, [changeFlag])

    const ocultarProyecciones = () => {
        setModalShowProyecciones(false);
        setContador(contador + 1);
    }
    const ocultarEditarEvento = () => {
        setModalEditarEvento(false);
        setContador(contador + 1);
    }
    const ocultarModalIngresos = () => {
        setModalIngresos(false);
        setContador(contador + 1);
    }
    const ocultarRendimiento = () => {
        setModalRendimiento(false);
        setContador(contador + 1);
    }
    const ocultarEditParticipantes = () => {
        setModalEditarParticipantes(false);
        setContador(contador + 1);
    }
    const ocultarModalMaterial = () => {
        setModalMaterial(false);
        setContador(contador + 1);
    }
    const ocultarModalAsistencia = () => {
        setContador(contador + 1);

    }
    const ocultarModalActividades = () => {
        setModalActividades(false);
        setContador(contador + 1);
    }
    const ocultarModalCargando = () => {
        setModalCargando(false);
        setContador(contador + 1);
    }
    const ocultarModalEnlaces = () => {
        setModalEnlaces(false);
        setContador(contador + 1);
    }
    const deleteparticipante = (UniqueID, Nombres) => {
        var confirmatinomessage = "¿Está segur@ que desea elimnar al participante " + Nombres + " ?";
        setConsideracion('Esta acción no se puede revertir');
        setMensaje(confirmatinomessage);
        setId_actual(UniqueID);
        setMensajeConfirmacion(true);
        setContador(contador + 1);
    }
    const ocultarModalFormularios = () => {
        setModalFormularios(false);
    }
    const accioneliminarparticipante = (UniqueID, param) => {
        const info = { "UniqueID": UniqueID };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/delete/detalle_inscripcion', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                if (data.codigo == 200) {
                    setMensaje('Participante eliminado exitosamente');
                    setModalSucc(true);
                    ocultarEditConfirmacion();
                } else {
                    ocultarEditConfirmacion();
                    setMensaje(data.respuesta);
                    setModalError(true);
                }
            });
    }
    const eventocambio = (evt) => {
        const arr_name = evt.target.name.split("-");
        var new_array = participantes;
        const index_finder = (participante) => participante.UniqueID == arr_name[1];
        switch (arr_name[0]) {
            case "nombres":
                new_array[new_array.findIndex(index_finder)].Nombres = evt.target.value;
                setParticipantes(new_array);
                break;
            case "apellidos":
                new_array[new_array.findIndex(index_finder)].Apellidos = evt.target.value;
                setParticipantes(new_array);
                break;
            case "puesto":
                new_array[new_array.findIndex(index_finder)].Puesto = evt.target.value;
                setParticipantes(new_array);
                break;
            case "correo":
                new_array[new_array.findIndex(index_finder)].Correo = evt.target.value;
                setParticipantes(new_array);
                break;
            case "telefono":
                new_array[new_array.findIndex(index_finder)].Telefono = evt.target.value;
                setParticipantes(new_array);
                break;
            case "celular":
                new_array[new_array.findIndex(index_finder)].Celular = evt.target.value;
                setParticipantes(new_array);
                break;
            case "identificacion":
                new_array[new_array.findIndex(index_finder)].Identificacion = evt.target.value;
                setParticipantes(new_array);
                break;
            default:
                break;
        }
        setChangeFlag(changeFlag + 1);
    }
    const submitchanges = () => {
        setModalCargando(true);
        const info = props.evento;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/update/evento', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                if (data.codigo == 200) {
                    setMensaje('Evento Modificado Exitosamente');
                    setConsideracion('Por favor, notifique al personal de sus cambios');
                    setModalSucc(true);
                } else {
                    setMensaje(data.respuesta);
                    setModalError(true);
                }
            });
    }
    const ocultar = () => {
        setModalSucc(false);
        setModalError(false);
        props.refrescar();
    }

    const ocultarEditConfirmacion = () => {
        setMensajeConfirmacion(false);
        setConfirmacionEliminar(false);
    }
    const publicarLanging = (id, param) => {
        const info = { "UniqueID": id };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/update/evento/publicarLanding', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                if (data.codigo == 200) {
                    setMensaje('Landing page publicada exitosamente');
                    setModalSucc(true);
                } else {
                    setMensaje(data.respuesta);
                    setModalError(true);
                }
            });
    }
    const ocultarLanging = (id, param) => {
        const info = { "UniqueID": id };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/update/evento/ocultarLanding', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                if (data.codigo == 200) {
                    setMensaje('Landing page oculta exitosamente');
                    setModalSucc(true);
                } else {
                    setMensaje(data.respuesta);
                    setModalError(true);
                }
            });
    }

    const eliminar = (id, param) => {
        ocultarEditConfirmacion();
        const info = { "UniqueID": id };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/delete/evento', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                if (data.codigo == 200) {
                    setMensaje('Evento Eliminado Exitosamente');
                    setModalSucc(true);
                } else {
                    setMensaje(data.respuesta);
                    setModalError(true);
                }
            });
    }

    const submitchangesparticipantes = (evt) => {
        evt.preventDefault();
        const info = participantes;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/update/detalle_inscripcion/batch', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                if (data.codigo == 200) {
                    setMensaje('Información Modificada Exitosamente');
                    setModalSucc(true);
                } else {
                    setMensaje(data.respuesta);
                    setModalError(true);
                }
            });
    }
    const onChangeFile = (evt) => {
        if (evt.target.name == "img") {
            setSelectedImg(evt.target.files[0]);
            setContador(contador + 1);
        } else {
            setSelectedFile(evt.target.files[0]);
            setContador(contador + 1);
        }
    }
    const submitImageHandler = async (endpoint, fileSelected) => {
        if (fileSelected != null) {
            setModalCargando(true);
            setMensajeCargando('Subiendo imagenes a repositorio de medios');
            setConsideracion('Por favor espere...');
            const data = new FormData();
            data.append('file', fileSelected);
            axios.post(process.env.REACT_APP_DEFAULT_URL+ "/api/files/upload/imagekit", data, {}).then(res => {
                if (res.data.codigo == 200) {
                    setMensajeCargando('Sincronizando base de datos...');
                    const info = { enlace: res.data.resultado.url, UniqueID: props.evento.UniqueID };
                    const requestOptions = {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(info)
                    };
                    fetch(process.env.REACT_APP_DEFAULT_URL + endpoint, requestOptions)
                        .then((res) => {
                            return res.json();
                        })
                        .then(data => {
                            if (data.codigo == 200) {
                                setMensaje('Evento Modificado Exitosamente');
                                setConsideracion('Por favor, notifique al personal de sus cambios');
                                setModalSucc(true);
                            } else {
                                setMensaje(data.respuesta);
                                setModalError(true);
                            }
                        });
                }
            });
        } else {
            setMensaje('Seleccione un archivo');
            setConsideracion('Inténtelo de nuevo');
            setModalError(true);
        }
    }
    const reporte_admin = () => {
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/detalle_inscripcion/admin/report/' + props.evento.UniqueID, { cache: "no-cache" })
            .then((res) => {
                return res.json();
            })
            .then(data => {
                var excel_contenido = data.rows;
                var asesores = [];
                var Sheets = [];
                var SheetNames = [];
                var asesores_tabla = [];
                const sw = XLSX.utils.json_to_sheet(excel_contenido);
                Sheets['Datos'] = sw;
                SheetNames.push('Datos');
                for (let i = 0; i < data.rows.length; i++) {
                    if (asesores.length == 0 || !asesores.includes(data.rows[i].asesor)) {
                        asesores.push(data.rows[i].asesor);
                    }
                }
                for (let i = 0; i < asesores.length; i++) {
                    let arr_respuestas = data.rows.filter((actual) => actual.asesor == asesores[i]);
                    arr_respuestas.sort(function (a, b) { return a['Apellidos'].localeCompare(b['Apellidos']) });
                    const ws = XLSX.utils.json_to_sheet(arr_respuestas);
                    Sheets[asesores[i] + '(' + arr_respuestas.length + ')'] = ws;
                    SheetNames.push(asesores[i] + '(' + arr_respuestas.length + ')');
                    asesores_tabla.push({ 'Asesor': asesores[i], 'Respuestas': arr_respuestas.length })
                }
                asesores_tabla.sort(function (a, b) { return b.Respuestas - a.Respuestas });
                asesores_tabla.push({ 'Asesor': 'TOTAL', 'Respuestas': data.rows.length });
                const ws = XLSX.utils.json_to_sheet(asesores_tabla);
                Sheets['RESUMEN'] = ws;
                SheetNames.push('RESUMEN');
                const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                const fileExtension = '.xlsx';
                const wb = { Sheets, SheetNames };
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const datos = new Blob([excelBuffer], { type: fileType });
                FileSaver.saveAs(datos, Date.now() + fileExtension);
            });
    }
    return (
        <div className="contenedor-evento-detalle" style={{ paddingTop: '10px' }}>
            <Container>
                <Row>
                    {(props.rol == 2 || props.rol == 3 || props.rol == 4) &&
                        <Col xs={1} md={1}>
                            <div style={{ cursor: 'pointer', width: '20%', color: 'white' }} data-toggle="tooltip" data-placement="top" title="Editar Información" onClick={() => setModalEditarEvento(true)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-pencil-fill" viewBox="0 0 16 16">
                                    <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                </svg>
                            </div>
                        </Col>
                    }
                    {(props.rol == 2 || props.rol == 4) &&
                        <Col xs={1} md={1}>
                            <div style={{ cursor: 'pointer', width: '20%', color: 'white' }} data-toggle="tooltip" data-placement="top" title="Participantes" onClick={() => setModalEditarParticipantes(true)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-person-check-fill" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M15.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                                    <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                </svg>
                            </div>
                        </Col>
                    }
                    {(props.rol == 2 || props.rol == 4 || props.rol == 7) &&
                        <Col xs={1} md={1}>
                            <div style={{ cursor: 'pointer', width: '20%', color: 'white' }} data-toggle="tooltip" data-placement="top" title="Material" onClick={() => setModalMaterial(true)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-inboxes-fill" viewBox="0 0 16 16">
                                    <path d="M4.98 1a.5.5 0 0 0-.39.188L1.54 5H6a.5.5 0 0 1 .5.5 1.5 1.5 0 0 0 3 0A.5.5 0 0 1 10 5h4.46l-3.05-3.812A.5.5 0 0 0 11.02 1H4.98zM3.81.563A1.5 1.5 0 0 1 4.98 0h6.04a1.5 1.5 0 0 1 1.17.563l3.7 4.625a.5.5 0 0 1 .106.374l-.39 3.124A1.5 1.5 0 0 1 14.117 10H1.883A1.5 1.5 0 0 1 .394 8.686l-.39-3.124a.5.5 0 0 1 .106-.374L3.81.563zM.125 11.17A.5.5 0 0 1 .5 11H6a.5.5 0 0 1 .5.5 1.5 1.5 0 0 0 3 0 .5.5 0 0 1 .5-.5h5.5a.5.5 0 0 1 .496.562l-.39 3.124A1.5 1.5 0 0 1 14.117 16H1.883a1.5 1.5 0 0 1-1.489-1.314l-.39-3.124a.5.5 0 0 1 .121-.393z" />
                                </svg>
                            </div>
                        </Col>
                    }
                    {(props.rol == 2 || props.rol == 4) &&
                        <Col xs={1} md={1}>
                            <div style={{ cursor: 'pointer', width: '20%', color: 'white' }} data-toggle="tooltip" data-placement="top" title="Actividades" onClick={() => setModalActividades(true)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-journal-bookmark-fill" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M6 1h6v7a.5.5 0 0 1-.757.429L9 7.083 6.757 8.43A.5.5 0 0 1 6 8V1z" />
                                    <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z" />
                                    <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z" />
                                </svg>
                            </div>
                        </Col>
                    }
                    {(props.rol == 2 || props.rol == 4) &&
                        <Col xs={1} md={1}>
                            <div style={{ cursor: 'pointer', width: '20%', color: 'white' }} data-toggle="tooltip" data-placement="top" title="Formularios" onClick={() => setModalFormularios(true)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-ui-radios" viewBox="0 0 16 16">
                                    <path d="M7 2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1zM0 12a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm7-1.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1zm0-5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 8a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zM3 1a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm0 4.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                                </svg>
                            </div>
                        </Col>
                    }
                    {(props.rol == 3 || props.rol == 4) &&
                        <Col xs={1} md={1}>
                            <div style={{ cursor: 'pointer', width: '20%', color: 'white' }} data-toggle="tooltip" data-placement="top" title="Proyección" onClick={() => setModalShowProyecciones(true)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-file-earmark-bar-graph-fill" viewBox="0 0 16 16">
                                    <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zm.5 10v-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-2.5.5a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1zm-3 0a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-1z" />
                                </svg>
                            </div>
                        </Col>}
                    {(props.rol == 3 || props.rol == 4) &&
                        <Col xs={1} md={1}>
                            <div style={{ cursor: 'pointer', width: '20%', color: 'white' }} data-toggle="tooltip" data-placement="top" title="Rendimiento" onClick={() => setModalRendimiento(true)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-graph-up-arrow" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M0 0h1v15h15v1H0V0Zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5Z" />
                                </svg>
                            </div>
                        </Col>
                    }
                    {(props.rol == 3 || props.rol == 4) &&
                        <Col xs={1} md={1}>
                            <div style={{ cursor: 'pointer', width: '20%', color: 'white' }} data-toggle="tooltip" data-placement="top" title="Ingresos" onClick={() => setModalIngresos(true)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-currency-dollar" viewBox="0 0 16 16">
                                    <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z" />
                                </svg>
                            </div>
                        </Col>
                    }
                    {(props.rol == 4) &&
                        <Col xs={1} md={1}>
                            <div style={{ cursor: 'pointer', width: '20%', color: 'white' }} data-toggle="tooltip" data-placement="top" title="Enlaces" onClick={() => { setModalEnlaces(true); }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-share-fill" viewBox="0 0 16 16">
                                    <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                                </svg>
                            </div>
                        </Col>
                    }
                    {(props.rol == 4) &&
                        <Col xs={1} md={1}>
                            <div style={{ cursor: 'pointer', width: '20%', color: 'white' }} data-toggle="tooltip" data-placement="top" title="Eliminar" onClick={() => { setConfirmacionEliminar(true); setMensaje('¿Está segur@ de eliminar el evento ' + props.evento.Nombre + '?'); setConsideracion('Esta acción no se puede revertir'); }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-trash-fill" viewBox="0 0 16 16">
                                    <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                                </svg>
                            </div>
                        </Col>
                    }
                    {(props.rol == 2 || props.rol == 4 || props.rol == 6) &&

                        <Col xs={1} md={1}>
                            <div style={{ cursor: 'pointer', width: '20%', color: 'white' }} data-toggle="tooltip" data-placement="top" title="Patrocinadores" onClick={() => setModalPatrocinadores(true)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-file-person-fill" viewBox="0 0 16 16">
                                    <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm-1 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0zm-3 4c2.623 0 4.146.826 5 1.755V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-1.245C3.854 11.825 5.377 11 8 11z" />
                                </svg>
                            </div>
                        </Col>
                    }
                    <Col xs={1} md={1}>
                        <div style={{ cursor: 'pointer', width: '20%', color: 'white' }} data-toggle="tooltip" data-placement="top" title="Refrescar" onClick={props.refrescar}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                                <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                            </svg>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <h1 style={{ marginBottom: '25px', marginTop: '25px', color: 'white' }}>Datos del evento</h1>
                    <ListGroup as="ol">
                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">
                                    Evento
                                </div>
                            </div>
                            {props.evento.Nombre}
                        </ListGroup.Item>
                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">
                                    Fecha de inicio del evento
                                </div>
                            </div>
                            {props.evento.Fecha_Inicio}
                        </ListGroup.Item>
                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">
                                    Fecha de finalización del evento
                                </div>
                            </div>
                            {props.evento.Fecha_Fin}
                        </ListGroup.Item>
                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">
                                    Hora del evento
                                </div>
                            </div>
                            {props.evento.Hora}
                        </ListGroup.Item>
                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">
                                    Mínimo de participantes
                                </div>
                            </div>
                            {props.evento.Minimo}
                        </ListGroup.Item>
                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">
                                    Tipo de evento
                                </div>
                            </div>
                            {props.evento.tipo}
                        </ListGroup.Item>
                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">
                                    Estado del evento
                                </div>
                            </div>
                            {props.evento.estado}
                        </ListGroup.Item>
                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">
                                    Modalidad del evento
                                </div>
                            </div>
                            {props.evento.modalidad}
                        </ListGroup.Item>
                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">
                                    Días a impartir
                                </div>
                            </div>
                            {props.evento.dias}
                        </ListGroup.Item>
                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">
                                    Duración del evento
                                </div>
                            </div>
                            {props.evento.duracion}
                        </ListGroup.Item>
                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">
                                    Número de sesiones del evento
                                </div>
                            </div>
                            {props.evento.sesiones}
                        </ListGroup.Item>
                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">
                                    Precio para agremiados
                                </div>
                            </div>
                            {props.evento.precio_agremiados}
                        </ListGroup.Item>
                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">
                                    Precio para no agremiados
                                </div>
                            </div>
                            {props.evento.precio_no_agremiados}
                        </ListGroup.Item>
                        {(props.rol == 4) &&
                            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className="fw-bold">
                                        Landing Page
                                    </div>
                                </div>
                                {props.evento.disponible == 0
                                    && <Alert variant="danger" style={{ height: '10px', display: 'flex', placeItems: 'center', cursor: 'pointer' }} onClick={() => { setConfirmacionPublicarLanding(true); setMensaje('¿Está segur@ de publicar esta landing page?'); setConsideracion('Esta acción se puede revertir'); setContador(contador + 1); }}>
                                        <div data-toggle="tooltip" data-placement="top" title="Click para publicar landing page">
                                            No Disponible
                                        </div>
                                    </Alert>
                                }
                                {props.evento.disponible == 1
                                    &&
                                    <Alert variant="success" style={{ height: '10px', display: 'flex', placeItems: 'center', cursor: 'pointer' }} onClick={() => { setConfirmacionOcultarLanding(true); setMensaje('¿Está segur@ de ocultar esta landing page?'); setConsideracion('Esta acción se puede revertir'); setContador(contador + 1); }}>
                                        <div data-toggle="tooltip" data-placement="top" title="Click para ocultar landing page">
                                            Disponible
                                        </div>
                                    </Alert>
                                }
                                {props.evento.disponible == 1 &&
                                    <OverlayTrigger trigger="click" placement="left" overlay={popover} rootClose>
                                        <div style={{ cursor: 'pointer', width: '20%', color: 'black' }} data-toggle="tooltip" data-placement="top" title="Copiar enlace al portapapeles" onClick={() => { navigator.clipboard.writeText(process.env.REACT_APP_SELF_URL + '/evento/' + props.evento.hash); }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-clipboard-check" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                                                <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                                                <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                                            </svg>
                                        </div>
                                    </OverlayTrigger>}
                                {props.evento.disponible == 1 &&
                                    <div style={{ cursor: 'pointer', width: '20%', color: 'black' }} data-toggle="tooltip" data-placement="top" title="Ver landing page">
                                        <a href={process.env.REACT_APP_SELF_URL + '/evento/' + props.evento.hash} target="_blank" style={{ color: 'black' }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16">
                                                <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                                <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                            </svg>
                                        </a>
                                    </div>
                                }
                            </ListGroup.Item>
                        }
                        {(props.rol != 4) &&
                            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className="fw-bold">
                                        Landing Page
                                    </div>
                                </div>
                                {props.evento.disponible == 0
                                    && <Alert variant="danger" style={{ height: '10px', display: 'flex', placeItems: 'center' }}>
                                        No Disponible
                                    </Alert>
                                }
                                {props.evento.disponible == 1
                                    &&
                                    <Alert variant="success" style={{ height: '10px', display: 'flex', placeItems: 'center' }}>
                                        Disponible
                                    </Alert>
                                }
                                {props.evento.disponible == 1 &&
                                    <OverlayTrigger trigger="click" placement="left" overlay={popover} rootClose>
                                        <div style={{ cursor: 'pointer', width: '20%', color: 'black' }} data-toggle="tooltip" data-placement="top" title="Copiar enlace al portapapeles" onClick={() => { navigator.clipboard.writeText(process.env.REACT_APP_SELF_URL + '/evento/' + props.evento.hash); }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-clipboard-check" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                                                <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                                                <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                                            </svg>
                                        </div>
                                    </OverlayTrigger>}
                                {props.evento.disponible == 1 &&
                                    <div style={{ cursor: 'pointer', width: '20%', color: 'black' }} data-toggle="tooltip" data-placement="top" title="Ver landing page">
                                        <a href={process.env.REACT_APP_SELF_URL + '/evento/' + props.evento.hash} target="_blank" style={{ color: 'black' }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16">
                                                <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                                <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                            </svg>
                                        </a>
                                    </div>
                                }
                            </ListGroup.Item>
                        }
                    </ListGroup>
                </Row>
                <Row>
                    <h1 style={{ marginBottom: '25px', marginTop: '25px', color: 'white' }}>Descripción del evento</h1>
                    <Form.Group className="mb-3" controlId="Comentario">
                        <Form.Control as="textarea" disabled rows={3} style={{ resize: 'none', width: '100%' }} name="Comentario" value={props.evento.Descripcion} />
                    </Form.Group>
                </Row>
                <Row style={{ marginBottom: '25px' }}>
                    <Col xs={12} md={12} lg={12}>
                        <Form.Group className="mb-3" controlId={"imagen-" + props.evento.UniqueID}>
                            <Form.Label>Enlace de imagen 1</Form.Label>
                            <Form.Control readOnly onChange={props.eventochange} type="text" placeholder="Ingrese el enlace de la imagen" name={"imagen-" + props.evento.UniqueID} value={props.evento.imagen} />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={12} lg={8} style={{ marginBottom: '15px' }}>
                        <Form.Group controlId="formFileMultiple" className="mb-3">
                            <Form.Control type="file" onChange={onChangeFile} accept=".jpeg, .png, .JPG, .JPEG, .PNG" name="img" />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={12} lg={4} style={{ marginBottom: '15px' }}>
                        <Button variant='light' style={{ width: '100%' }} onClick={() => submitImageHandler('/api/update/evento/img', selectedImg)}>Cambiar imagen</Button>
                    </Col>
                    <Col xs={12} md={12} lg={6}>
                        <Row style={{ marginBottom: '15px' }}>
                            <h1 style={{ textAlign: 'center' }}>Vista previa</h1>
                        </Row>
                        <Row style={{ padding: '15%', border: '5px dotted black' }}>
                            <img src={props.evento.imagen} width='100%' height='100%' />
                        </Row>
                    </Col>
                    <Col xs={12} md={12} lg={6} style={{ display: 'flex', placeItems: 'center' }}>
                        <Alert variant="light">
                            <Alert.Heading>Aclaración</Alert.Heading>
                            <p>
                                Esta imagen se visualizará en la ABG APP, si es un congreso, esta imagen
                                aparecerá en el listado de congresos.
                            </p>
                            <hr />
                            <p className="mb-0">
                                De lo contrario aparecerá como imagen de la portada de cada evento de la agenda en la app.
                            </p>
                        </Alert>
                    </Col>
                </Row>
                <Row style={{ marginBottom: '25px' }}>
                    <Col xs={12} md={12} lg={12}>
                        <Form.Group className="mb-3" controlId={"imagen-" + props.evento.UniqueID}>
                            <Form.Label>Enlace de imagen 2</Form.Label>
                            <Form.Control readOnly onChange={props.eventochange} type="text" placeholder="Ingrese el enlace de la imagen" name={"flier-" + props.evento.UniqueID} value={props.evento.flier} />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={12} lg={8} style={{ marginBottom: '15px' }}>
                        <Form.Group controlId="formFileMultiple" className="mb-3">
                            <Form.Control type="file" onChange={onChangeFile} accept=".jpeg, .png, .JPG, .JPEG, .PNG" name="flier" />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={12} lg={4} style={{ marginBottom: '15px' }}>
                        <Button variant='light' style={{ width: '100%' }} onClick={() => submitImageHandler('/api/update/evento/flier', selectedFile)}>Cambiar imagen</Button>
                    </Col>
                    <Col xs={12} md={12} lg={6}>
                        <Row style={{ marginBottom: '15px' }}>
                            <h1 style={{ textAlign: 'center' }}>Vista previa</h1>
                        </Row>
                        <Row style={{ padding: '15%', border: '5px dotted black' }}>
                            <img src={props.evento.flier} width='100%' height='100%' />
                        </Row>
                    </Col>
                    <Col xs={12} md={12} lg={6} style={{ display: 'flex', placeItems: 'center' }}>
                        <Alert variant="light">
                            <Alert.Heading>Aclaración</Alert.Heading>
                            <p>
                                Esta imagen aparecerá en la app exclusivamente para congresos, aparecerá como
                                foto de la portada del evento.
                            </p>
                            <hr />
                            <p className="mb-0">
                                Para todos los tipos de eventos, aparecerá esta imagen en su respectiva
                                landing page.
                            </p>
                        </Alert>
                    </Col>
                </Row>
                <Row style={{ marginTop: '25px' }}>
                    <Notificaciones evento={props.evento} />
                </Row>
                {(props.rol == 2 || props.rol == 3 || props.rol == 4) &&
                    <Row style={{ color: 'white' }}>
                        <h1 style={{ marginBottom: '25px', marginTop: '20px' }}>Ventas por asesor</h1>
                        <Col xs={1} md={1}>
                            <div style={{ cursor: 'pointer', color: 'white' }} data-toggle="tooltip" data-placement="top" title="Descargar listado excel" onClick={reporte_admin} >
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-cloud-download-fill" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M8 0a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 4.095 0 5.555 0 7.318 0 9.366 1.708 11 3.781 11H7.5V5.5a.5.5 0 0 1 1 0V11h4.188C14.502 11 16 9.57 16 7.773c0-1.636-1.242-2.969-2.834-3.194C12.923 1.999 10.69 0 8 0zm-.354 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V11h-1v3.293l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z" />
                                </svg>
                            </div>
                        </Col>
                        <Col style={{ marginBottom: '25px' }} xs={12} md={12} lg={12} className='columna-admin-inscripciones'>
                            <Accordion>
                                {
                                    asesores.map((asesor) =>
                                        <Accordion.Item eventKey={asesor.UniqueID} >
                                            <Accordion.Header>{asesor.Nombres + ' ' + asesor.Apellidos}</Accordion.Header>
                                            <Accordion.Body className="acordeon">
                                                <DetalleInscripcion evento={props.evento} asesor={asesor} refrescar={props.refrescar} permiso={props.rol} />
                                            </Accordion.Body>
                                        </Accordion.Item>)
                                }
                            </Accordion>
                        </Col>
                        <Col xs={12} md={12} lg={12}>
                            <Table striped bordered hover responsive variant="light">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Asesor</th>
                                        <th>Ventas</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ventas.map((venta, index) => <tr key={index}><td>{index + 1}</td><td>{venta.asesor}</td><td>{venta.inscritos}</td></tr>)}
                                    <tr>
                                        <td colSpan={2} style={{ fontWeight: 'bolder' }}>TOTAL VENTAS</td>
                                        <td style={{ fontWeight: 'bolder' }}>{total}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                }
            </Container>
            <ModalProyecciones
                show={modalShowProyecciones}
                onHide={ocultarProyecciones}
                data={[
                    { name: 'Ingresos Proyectados', monto: props.evento.ingresos, "Mínimo Participantes": props.evento.Minimo },
                    { name: 'Egresos Proyectados', monto: props.evento.egresos, "Mínimo Participantes": props.evento.Minimo },
                    { name: 'Resultados Proyectados', monto: props.evento.resultado, "Mínimo Participantes": props.evento.Minimo }
                ]}

            />
            <ModalRendimiento
                show={modalRendimiento}
                onHide={ocultarRendimiento}
                data={ventas}

            />
            <ModalIngresos
                show={modalIngresos}
                onHide={ocultarModalIngresos}
                data={ventas}

            />
            <ModalEditarEvento
                show={modalEditarEvento}
                onHide={ocultarEditarEvento}
                evento={props.evento}
                eventochange={props.eventochange}
                submitchanges={submitchanges}
                edit={props.edit}
            />
            <SuccessModal
                color={"#b5bad0"}
                show={modalSucc}
                onHide={ocultar}
                mensaje={mensaje}
                consideracion={consideracion}
                enlace={''}
                llevaenlace={''}
            />
            <ModalCargando
                color={"#b5bad0"}
                show={modalCargando}
                onHide={ocultarModalCargando}
                mensaje={mensajeCargando}
            />
            <ErrorModal
                color={"#b5bad0"}
                show={modalError}
                onHide={ocultar}
                mensaje={mensaje}
                consideracion={consideracion}
            />
            <ModalEditarParticipantes
                show={modalEditarParticipantes}
                onHide={ocultarEditParticipantes}
                participantes={participantes}
                deleteparticipante={deleteparticipante}
                eventocambio={eventocambio}
                submitchangesparticipantes={submitchangesparticipantes}
            />
            <Confirmacion
                show={mensajeConfirmacion}
                onHide={ocultarEditConfirmacion}
                accion={accioneliminarparticipante}
                mensaje={mensaje}
                uniqueid={id_actual}
                consideracion={consideracion}
                param=''
            />

            <Confirmacion
                show={confirmacionEliminar}
                onHide={ocultarEditConfirmacion}
                accion={eliminar}
                mensaje={mensaje}
                uniqueid={props.evento.UniqueID}
                consideracion={consideracion}
                param=''
            />
            <Confirmacion
                show={confirmacionPublicarLanding}
                onHide={() => setConfirmacionPublicarLanding(false)}
                accion={publicarLanging}
                mensaje={mensaje}
                uniqueid={props.evento.UniqueID}
                consideracion={consideracion}
                param=''
            />
            <Confirmacion
                show={confirmacionOcultarLanding}
                onHide={() => setConfirmacionOcultarLanding(false)}
                accion={ocultarLanging}
                mensaje={mensaje}
                uniqueid={props.evento.UniqueID}
                consideracion={consideracion}
                param=''
            />
            {
                props.rol == 4 &&
                <ModalMaterial
                    show={modalMaterial}
                    onHide={ocultarModalMaterial}
                    evento_uniqueid={props.evento.UniqueID}
                    admin={true}
                    rol={props.rol}
                />
            }
            {
                props.rol != 4 &&
                <ModalMaterial
                    show={modalMaterial}
                    onHide={ocultarModalMaterial}
                    evento_uniqueid={props.evento.UniqueID}
                    admin={false}
                    rol={props.rol}
                />
            }
            <ModalEnlaces
                show={modalEnlaces}
                onHide={ocultarModalEnlaces}
                evento_uniqueid={props.evento.UniqueID}
            />
            <ModalPatrocinadores
                show={modalPatrocinadores}
                onHide={() => setModalPatrocinadores(false)}
                evento_uniqueid={props.evento.UniqueID}
            />
            {
                (props.rol == 4 || props.rol == 2) &&
                <Formularios evento={props.evento} show={modalFormularios} onHide={ocultarModalFormularios} />
            }
            <ModalActividades
                show={modalActividades}
                onHide={ocultarModalActividades}
                evento_uniqueid={props.evento.UniqueID}
                participantes={participantes}
            />

        </div>
    );
}

export default EventoDetalle;