import React, {  useState, useEffect } from 'react';
import { Dropdown, Alert, Row, Popover, OverlayTrigger, Tabs, Tab, Button, Col, Form, Container, Breadcrumb} from "react-bootstrap";
import axios from 'axios';
import { ErrorModal } from '../../../../Utils/ErrorModal/ErrorModal';
import { SuccessModal } from '../../../../Utils/SuccessModal/SuccessModal';
import { Confirmacion } from '../../../../Utils/ConfirmationModal/Confirmacion';
//import DEFAULT_URL from '../../../../../api/url';
import { ModalCargando } from '../../../../Utils/Modal-Cargando/ModalCargando';
//import { SELF_URL } from '../../../../../api/url';
import ModalEditarMaterial from './ModalEditarMaterial';
import ModalEditarCarpeta from './ModalEditarCarpeta';
import ModalVistaPrevia from './ModalVistaPrevia';
import './ModalMaterial.css'
import FormEditCarpeta from './FormEditCarpeta';
import FormEditMaterial from './FormEditMaterial';
const DetalleCarpeta = (props) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [nombre, setNombre] = useState('');
    const [modalSucc, setModalSucc] = useState(false);
    const [modalError, setModalError] = useState(false);
    const [mensaje, setMensaje] = useState('');
    const [pathActual, setPathActual] = useState('');
    const [consideracion, setConsideracion] = useState('');
    const [id_actual, setId_Actual] = useState('');
    const [mensajeConfirmacion, setMensajeConfirmacion] = useState(false);
    const [modalCargando, setModalCargando] = useState(false);
    const [contador, setContador] = useState(0);
    const [materiales, setMateriales] = useState([]);
    const [confirmacionEliminar, setConfirmacionEliminar] = useState(false);
    const [editarCarpeta, setEditarCarpeta] = useState(false);
    const [promocional, setPromocional] = useState('');
    const [segundos, setSegundos] = useState(0);
    const [editarMaterial, setEditarMaterial] = useState(false);
    const [nombre_actual, setNombre_actual] = useState('');
    const [promocional_actual, setPromocional_actual] = useState('');
    const [segundos_actual, setSegundosActual] = useState(0);
    const [modalVistaPrevia, setModalVistaPrevia] = useState(false);
    const [url, setUrl] = useState('');
    const popover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">¡Listo!</Popover.Header>
            <Popover.Body>
                Enlace copiado exitosamente.
            </Popover.Body>
        </Popover>
    );
    const [detalle, setDetalle] = useState('block');
    const [edit, setEdit] = useState('none');
    const [confirmacion, setConfirmacion] = useState('none');
    const [confirmacionMaterial, setConfirmacionMaterial] = useState('none');
    const [pathSeleccionado, setPathSeleccionado] = useState('');
    const [idSeleccionado, setIdSeleccionado] = useState('');
    const [editMaterial, setEditMaterial] = useState('none');
    useEffect(() => {
        async function fetchData() {
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/material/carpetas/materiales/' + props.carpeta, { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setMateriales(data.rows);
                });
        }
        fetchData();
    }, [contador]);


    const onChangeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const onChange = (event) => {
        if (event.target.name == "nombre") {
            setNombre(event.target.value);
        } else if (event.target.name == "segundos") {
            setSegundos(event.target.value);
        } else {
            setPromocional(event.target.value);
        }

    }
    const ocultarModalCargando = () => {
        setModalCargando(false);
        setContador(contador + 1);
    }
    const submitForm = (evt) => {
        evt.preventDefault();
        props.cargar('Se está subiendo el archivo al servidor', 'Por favor espere...')
        const data = new FormData();
        data.append('file', selectedFile);
        axios.post(process.env.REACT_APP_DEFAULT_URL+ "/api/files/upload/s3", data, {
        }).then(res => {
            if (res.status == 200) {
                const info = { Nombre: nombre, Path: res.data.resultado.Location, carpeta: props.carpeta, promocional: promocional, segundos: segundos };
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(info)
                };
                fetch(process.env.REACT_APP_DEFAULT_URL + '/api/create/material', requestOptions)
                    .then((res) => {
                        return res.json();
                    })
                    .then(data => {
                        setContador(contador + 1);
                        if (data.codigo == 200) {
                            props.ok_det('Archivo almacenado exitosamente', 'Por favor valide e informe a todos');
                        } else {
                            props.err('Ocurrió un error al cargar el archivo', 'Por favor recarge la página e intente nuevamente');
                        }
                        Array.from(document.querySelectorAll("input")).forEach(
                            input => (input.value = "")
                        );
                    });
            } else {
                props.err('Ocurrió un error al cargar el archivo', 'Por favor recarge la página e intente nuevamente');
            }
        });
    }
    const ocultar = () => {
        setModalError(false);
        setModalSucc(false);
        setContador(contador + 1);
        props.refrescar();
    }

    const deleteFile = (path, id) => {
        var confirmatinomessage = "¿Está segur@ que desea elimnar el archivo " + path;
        setConsideracion('Esta acción no se puede revertir');
        setMensaje(confirmatinomessage);
        setId_Actual(id);
        setPathActual(path);
        setMensajeConfirmacion(true);
    }
    const accionEliminarCarpeta = () => {
        props.cargar('Sincronizando base de datos', 'Por favor espere...');
        const info = { UniqueID: props.carpeta };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/delete/carpeta', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                if (data.codigo == 200) {
                    props.ok('Carpeta eliminada exitosamente', 'Esta acción no se puede revertir');
                } else {
                    props.err('Ocurrió un error al eliminar la carpeta', 'Por favor recargue la página e intente nuevamente');
                }
            });
    }

    const accioneliminarmaterial = () => {
        props.cargar('Sincronizando base de datos', 'Por favor espere...');
        if (idSeleccionado != '' && pathSeleccionado != '') {
            const info = { "UniqueID": idSeleccionado, "path": pathSeleccionado };
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(info)
            };
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/delete/material', requestOptions)
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    props.refrescar();
                    setContador(0);
                    setConfirmacionMaterial('none');
                    setDetalle('block');
                    if (data.codigo == 200) {
                        props.ok_det('Material eliminado exitosamente', 'Esta acción no se puede revertir');
                    } else {
                        props.err('Ocurrió un error al eliminar el material', 'Por favor recargue la página e intente nuevamente');
                    }
                    setContador(contador + 1);
                });
        }
    }
    const back = () => {
        setEdit('none');
        setDetalle('block');
        setConfirmacion('none');
        setConfirmacionMaterial('none');
        setEditMaterial('none');
        setContador(contador + 1);
    }
    return (
        <div style={{ height: '100%' }}>
            <Container>
                <Row>
                    <Breadcrumb variant="dark">
                        <Breadcrumb.Item active>{props.nombre_evento}</Breadcrumb.Item>
                        <Breadcrumb.Item active>Material</Breadcrumb.Item>
                        <Breadcrumb.Item active>Carpetas</Breadcrumb.Item>
                        <Breadcrumb.Item active>{props.nombre_carpeta}</Breadcrumb.Item>
                        <Breadcrumb.Item active style={{ display: detalle }}>Detalle</Breadcrumb.Item>
                        <Breadcrumb.Item active style={{ display: edit }}>Editar</Breadcrumb.Item>
                    </Breadcrumb>
                </Row>
                <Row style={{ marginBottom: '25px', display: detalle }}>
                    <Col xs={12} md={12} lg={12}>
                        <Dropdown>
                            <Dropdown.Toggle variant="dark" >
                                Acciones
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => { props.back(); }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-backspace-fill" viewBox="0 0 16 16">
                                        <path d="M15.683 3a2 2 0 0 0-2-2h-7.08a2 2 0 0 0-1.519.698L.241 7.35a1 1 0 0 0 0 1.302l4.843 5.65A2 2 0 0 0 6.603 15h7.08a2 2 0 0 0 2-2V3zM5.829 5.854a.5.5 0 1 1 .707-.708l2.147 2.147 2.146-2.147a.5.5 0 1 1 .707.708L9.39 8l2.146 2.146a.5.5 0 0 1-.707.708L8.683 8.707l-2.147 2.147a.5.5 0 0 1-.707-.708L7.976 8 5.829 5.854z" />
                                    </svg>&nbsp;&nbsp;&nbsp;Atrás
                                </Dropdown.Item>
                                {
                                    props.permiso == 5 &&
                                    <Dropdown.Item onClick={() => { setEdit('block'); setDetalle('none'); setContador(contador + 1); }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-fill" viewBox="0 0 16 16">
                                            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                        </svg>&nbsp;&nbsp;&nbsp;Editar Carpeta
                                    </Dropdown.Item>
                                }
                                {
                                    (props.permiso == 5 && materiales.length > 0) &&
                                    <Dropdown.Item href={process.env.REACT_APP_SELF_URL + '/carpetas/' + props.correlativo} target="_blank">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16">
                                            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                        </svg>
                                        <i className="bi bi-cloud-arrow-down-fill"></i>&nbsp;&nbsp;&nbsp;Ver Carpeta
                                    </Dropdown.Item>
                                }
                                {
                                    (props.permiso == 5 && materiales.length > 0) &&
                                    <Dropdown.Item onClick={() => navigator.clipboard.writeText(process.env.REACT_APP_SELF_URL + '/carpetas/' + props.correlativo)}>
                                        <OverlayTrigger trigger="click" placement="left" overlay={popover} rootClose>
                                            <div style={{ color: 'black', cursor: 'pointer' }} data-toggle="tooltip" data-placement="top" title="Copiar enlace de carpeta" >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard-check" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                                                    <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                                                    <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                                                </svg>
                                                &nbsp;&nbsp;&nbsp;Copiar Enlace de la carpeta
                                            </div>
                                        </OverlayTrigger>
                                    </Dropdown.Item>
                                }
                                <Dropdown.Item onClick={() => setContador(contador + 1)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                                        <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                                    </svg>&nbsp;&nbsp;&nbsp;Refrescar
                                </Dropdown.Item>
                                {
                                    (props.permiso == 5 && (props.rol != 1 && props.rol != 6)) && <Dropdown.Divider></Dropdown.Divider>
                                }
                                {
                                    (props.permiso == 5 && (props.rol != 1 && props.rol != 6)) &&
                                    <Dropdown.Item style={{ color: 'red' }} onClick={() => { setConfirmacionMaterial('none'); setDetalle('none'); setEdit('none'); setConfirmacion('block'); setContador(contador + 1); }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3-fill" viewBox="0 0 16 16">
                                            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                                        </svg>&nbsp;&nbsp;&nbsp;Eliminar Carpeta
                                    </Dropdown.Item>
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>
                <Row style={{ display: detalle }}>
                    <Tabs defaultActiveKey="detalle" id="uncontrolled-tab-example" className="mb-3">
                        {((props.permiso == 1 || props.permiso == 2 || props.permiso == 4 || props.permiso == 5 || props.permiso == 6) && (props.rol != 1 && props.rol != 6)) &&
                            <Tab variant="dark" eventKey="formulario" title="Subir Material">
                                <Container>
                                    <Form onSubmit={submitForm}>
                                        <Row>
                                            <Form.Group className="mb-3" controlId="material">
                                                <Form.Label>Nombre del material</Form.Label>
                                                <Form.Control required type="text" name="nombre" placeholder="Ingrese el nombre del material" onChange={onChange} />
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Form.Group controlId="formFileMultiple" className="mb-3">
                                                <Form.Label>Seleccione el archivo que desee adjuntar como material al evento</Form.Label>
                                                <Form.Control required type="file" onChange={onChangeHandler} />
                                            </Form.Group>
                                        </Row>
                                        {props.permiso == 4 &&
                                            <Row>
                                                <Form.Group className="mb-3" controlId="promocional">
                                                    <Form.Label>Imagen promocional para APP</Form.Label>
                                                    <Form.Control required type="text" name="promocional" placeholder="Ingrese el url de la imagen promocional" onChange={onChange} />
                                                </Form.Group>
                                            </Row>
                                        }
                                        {
                                            props.permiso == 6 &&
                                            <Row>
                                                <Form.Group className="mb-3" controlId="promocional">
                                                    <Form.Label>Duración en espacio publicitario de APP</Form.Label>
                                                    <Form.Control required readonly type="number" name="segundos" placeholder="tiempo en segundos" onChange={onChange} />
                                                </Form.Group>
                                            </Row>
                                        }
                                        <Row>
                                            <Col>
                                                <Button variant="dark" className="font-bold" type="submit">Subir Material</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Container>
                            </Tab>
                        }
                        <Tab eventKey="detalle" title="Ver Material">
                            <Container>
                                {materiales.length > 0 &&
                                    materiales.map((material, index) =>
                                        <Row key={material.UniqueID} style={{ marginBottom: '25px', border: '1px dashed black', padding: '15px', borderRadius: '15px' }}>
                                            <Col xs={12} md={6} lg={6}>
                                                <h5>Nombre del material</h5>
                                                <Form.Group className="mb-3">
                                                    <Form.Control disabled type="textarea" rows={3} style={{ resize: 'none', width: '100%' }} value={material.Nombre} />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={6} lg={6}>
                                                <h5>Ruta material</h5>
                                                <Form.Group className="mb-3">
                                                    <Form.Control disabled type="textarea" rows={3} style={{ resize: 'none', width: '100%' }} value={material.Path} />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={6} lg={6} style={{ textAlign: 'center' }}>
                                                <h5>Estado material</h5>
                                                <p>{material.estado == 0 && <strong>no procesado</strong>}{material.estado == 1 && <strong>procesado</strong>}</p>
                                                {(material.estado == 1 && material.ret != null) && <p>RETROALIMENTACIÓN: {material.ret}</p>}
                                            </Col>
                                            <Col xs={12} md={6} lg={6} style={{ textAlign: 'center' }}>
                                                <Row>
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="dark" >
                                                            Acciones
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item onClick={() => { setModalVistaPrevia(true); setUrl(material.Path); setContador(contador + 1); }}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16">
                                                                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                                                    <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                                                </svg>&nbsp;&nbsp;&nbsp;Vista Previa
                                                            </Dropdown.Item>
                                                            {
                                                                (props.permiso != 3 && (props.rol != 1 && props.rol != 6)) &&
                                                                <Dropdown.Item onClick={() => {
                                                                    setEditMaterial('block'); setDetalle('none'); setId_Actual(material.UniqueID); setNombre_actual(material.Nombre); setContador(contador + 1); if (props.permiso == 4) { setPromocional_actual(material.imagen_promocional); setContador(contador + 1); } else if (props.permiso == 6) { setSegundosActual(material.segundos); setContador(contador + 1); } else { setPromocional_actual(null); setContador(contador + 1); }
                                                                }}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-fill" viewBox="0 0 16 16">
                                                                        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                                                    </svg>&nbsp;&nbsp;&nbsp;Editar Material
                                                                </Dropdown.Item>
                                                            }
                                                            <Dropdown.Item href={material.Path} target="_blank">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cloud-arrow-down-fill" viewBox="0 0 16 16">
                                                                    <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 6.854-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5a.5.5 0 0 1 1 0v3.793l1.146-1.147a.5.5 0 0 1 .708.708z" />
                                                                </svg>
                                                                <i className="bi bi-cloud-arrow-down-fill"></i>
                                                                &nbsp;&nbsp;&nbsp;Descargar Archivo
                                                            </Dropdown.Item>
                                                            <Dropdown.Item onClick={() => navigator.clipboard.writeText(material.Path)}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard-check" viewBox="0 0 16 16">
                                                                    <path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                                                                    <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                                                                    <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                                                                </svg>&nbsp;&nbsp;&nbsp; Copiar Enlace De Archivo
                                                            </Dropdown.Item>
                                                            {
                                                                (props.permiso == 4 && (props.rol != 1 && props.rol != 6)) &&
                                                                <Dropdown.Item href={material.imagen_promocional} target="_blank">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-image-fill" viewBox="0 0 16 16">
                                                                        <path d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707v5.586l-2.73-2.73a1 1 0 0 0-1.52.127l-1.889 2.644-1.769-1.062a1 1 0 0 0-1.222.15L2 12.292V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zm-1.498 4a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0z" />
                                                                        <path d="M10.564 8.27 14 11.708V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-.293l3.578-3.577 2.56 1.536 2.426-3.395z" />
                                                                    </svg>&nbsp;&nbsp;&nbsp;Ver imagen promocional
                                                                </Dropdown.Item>
                                                            }
                                                            {(material.estado == 1 && (props.permiso == 1 || props.permiso == 2 || props.permiso == 4 || props.permiso == 5 || props.permiso == 6) && (props.rol != 1 && props.rol != 6)) &&
                                                                <Dropdown.Divider></Dropdown.Divider>
                                                            }
                                                            {(material.estado == 1 && (props.permiso == 1 || props.permiso == 2 || props.permiso == 4 || props.permiso == 5 || props.permiso == 6) && (props.rol != 1 && props.rol != 6)) &&
                                                                <Dropdown.Item onClick={() => { setConfirmacionMaterial('block'); setDetalle('none'); setPathSeleccionado(material.Path); setIdSeleccionado(material.UniqueID); setContador(contador + 1); }} style={{ color: 'red' }}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3-fill" viewBox="0 0 16 16">
                                                                        <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                                                                    </svg>&nbsp;&nbsp;&nbsp;Eliminar Material
                                                                </Dropdown.Item>
                                                            }
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </Row>
                                            </Col>
                                        </Row>
                                    )
                                }
                                {
                                    materiales.length == 0 && <Row>
                                        <Alert variant="primary">
                                            <Alert.Heading>Esta carpeta está vacía</Alert.Heading>
                                            <p>
                                                Carpeta sin archivos
                                            </p>
                                            <hr />
                                            <p className="mb-0">
                                                Puedes almacenar archivos en la pestaña de "Subir Material"
                                            </p>
                                        </Alert>
                                    </Row>
                                }
                            </Container>
                        </Tab>
                    </Tabs>
                </Row>
                <Row style={{ display: editMaterial }}>
                    <FormEditMaterial permiso={props.permiso} UniqueID={id_actual} nombre={nombre_actual} promocional={promocional_actual} segundos={segundos_actual} refrescar={props.refrescar} back_det={props.back_det} back={back} cargar={props.cargar} ok={props.ok} err={props.err} ok_det={props.ok_det} />
                </Row>
                <Row style={{ display: confirmacion }}>
                    <Alert variant="warning">
                        <Alert.Heading>Se requiere confirmación</Alert.Heading>
                        <p>
                            ¿Esta usted segur@ de eliminar la carpeta seleccionada?
                        </p>
                        <p className="mb-0">
                            Esta acción no se puede revertir
                        </p>
                        <hr />
                        <div className="d-flex justify-content-end">
                            <Button variant="outline-warning" style={{ marginRight: '25px' }} onClick={accionEliminarCarpeta}>
                                Eliminar
                            </Button>
                            <Button variant="outline-dark" onClick={() => { setConfirmacion('none'); setEdit('none'); setDetalle('block'); setContador(contador + 1); }}>
                                Cancelar
                            </Button>
                        </div>
                    </Alert>
                </Row>
                <Row style={{ display: confirmacionMaterial }}>
                    <Alert variant="warning">
                        <Alert.Heading>Se requiere confirmación</Alert.Heading>
                        <p>
                            ¿Esta usted segur@ de eliminar el material seleccionado?
                        </p>
                        <p className="mb-0">
                            Esta acción no se puede revertir
                        </p>
                        <hr />
                        <div className="d-flex justify-content-end">
                            <Button variant="outline-warning" style={{ marginRight: '25px' }} onClick={accioneliminarmaterial}>
                                Eliminar
                            </Button>
                            <Button variant="outline-dark" onClick={() => { setConfirmacion('none'); setEdit('none'); setDetalle('block'); setConfirmacionMaterial('none'); setContador(contador + 1); }}>
                                Cancelar
                            </Button>
                        </div>
                    </Alert>
                </Row>
                <Row style={{ marginBottom: '25px', display: edit }}>
                    <FormEditCarpeta nombre={props.nombre_carpeta} UniqueId={props.carpeta} refrescar={props.refrescar} back={back} cargar={props.cargar} ok={props.ok} err={props.err} back_det={props.back_det} />
                </Row>
                <SuccessModal
                    color={"#b5bad0"}
                    show={modalSucc}
                    onHide={ocultar}
                    mensaje={mensaje}
                    consideracion={consideracion}
                    enlace={''}
                    llevaenlace={false}
                />
                <ErrorModal
                    color={"#b5bad0"}
                    show={modalError}
                    onHide={ocultar}
                    mensaje={mensaje}
                    consideracion={consideracion}
                />
                <Confirmacion
                    show={mensajeConfirmacion}
                    onHide={() => setMensajeConfirmacion(false)}
                    accion={accioneliminarmaterial}
                    mensaje={mensaje}
                    uniqueid={id_actual}
                    consideracion={consideracion}
                    param={pathActual}
                />
                <Confirmacion
                    show={confirmacionEliminar}
                    onHide={() => setConfirmacionEliminar(false)}
                    accion={accionEliminarCarpeta}
                    mensaje={mensaje}
                    uniqueid={''}
                    consideracion={consideracion}
                    param={''}
                />
                <ModalCargando
                    color={"#b5bad0"}
                    show={modalCargando}
                    onHide={ocultarModalCargando}
                    mensaje={''}
                />
                <ModalEditarCarpeta show={editarCarpeta} onHide={() => setEditarCarpeta(false)} nombre={props.nombre_carpeta} UniqueId={props.carpeta} refrescar={props.refrescar} />
                <ModalEditarMaterial show={editarMaterial} onHide={() => setEditarMaterial(false)} refrescar={() => setContador(contador + 1)} permiso={props.permiso} UniqueID={id_actual} nombre={nombre_actual} promocional={promocional_actual} segundos={segundos_actual} />
                <ModalVistaPrevia show={modalVistaPrevia} onHide={() => { setModalVistaPrevia(false); setContador(contador + 1); }} url={url} />
            </Container>
        </div >
    )
}

export default DetalleCarpeta