import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import './Asesores.css';
import { Row, Button, Col, Container, Card } from "react-bootstrap";
//import DEFAULT_URL from '../../api/url';
//import { SELF_URL } from '../../api/url';
const Page = () => {
    const { hash } = useParams();
    const [contador, setContador] = useState(0);
    const [asesor, setAsesor] = useState({});
    const [cartera, setCartera] = useState([]);
    const [titulo, setTitulo] = useState('');
    const [img, setImg] = useState('');
    useEffect(async () => {
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read_hash/asesor/' + hash, { cache: "no-cache" })
            .then((res) => {
                return res.json();
            })
            .then(data => {
                if (data.rows.length == 0) {
                    window.location.replace('/unauthorized');
                } else {
                    setAsesor(data.rows[0]);
                    fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/evento/inscripciones/abiertas', { cache: "no-cache" })
                        .then((res) => {
                            return res.json();
                        })
                        .then(data => {
                            setCartera(data.rows);
                        });
                }
                //

            });
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/agenda/info', { cache: "no-cache" })
            .then((res) => {
                return res.json();
            })
            .then(data => {
                setTitulo(data.titulo);
                setImg(data.imagen);
            });
    }, [contador]);

    const redireccionar = (id, hash) => {
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/formulario/evento/' + id, { cache: "no-cache" })
            .then((res) => {
                return res.json();
            })
            .then(data => {
                setContador(contador + 1);
                if (data.formularios.length == 0 || data.formularios.length > 1) {
                    window.open(process.env.REACT_APP_SELF_URL + '/inscripciones/' + hash + '/' + asesor.hash, "_blank");
                } else {
                    window.open(process.env.REACT_APP_SELF_URL + '/formularios/' + data.formularios[0].correlativo, "_blank");
                }
            });
    }

    return (
        <Container fluid style={{ background: 'rgb(2,0,36)', background: 'linear-gradient(322deg, rgba(2,0,36,1) 0%, rgba(30,61,82,1) 51%, rgba(138,138,138,1) 100%)' }}>
            <Row style={{ display: 'flex', placeItems: 'center', padding: '5%' }}>
                <Col xs={12} md={12} lg={6} style={{ display: 'flex', placeItems: 'center', marginTop: '35px' }}>
                    <img style={{ width: '100%', objectFit: 'contain' }} src='https://ik.imagekit.io/p5ethkcnzwh/Blanco_Logo_final_2022_ABG_6V4gmyglP.png?ik-sdk-version=javascript-1.4.3&updatedAt=1657298859263' />
                </Col>
                <Col xs={12} md={12} lg={6} style={{ marginTop: '35px' }}>
                    <img style={{ width: '100%', objectFit: 'contain', borderRadius: '10%' }} src={img} />
                </Col>
            </Row>
            <Row>
                <Container style={{ marginTop: '15px' }}>
                    <Row>
                        {

                            cartera.map((formulario) =>
                                <Col style={{ marginBottom: '15px' }} xs={12} md={6} lg={4}>
                                    <Card style={{ height: '100%' }}>
                                        <Card.Img variant="top" src={formulario.flier} />
                                        <Card.Body>
                                            <Card.Title>{formulario.Nombre}</Card.Title>
                                            <Card.Text>
                                                {formulario.Descripcion}
                                            </Card.Text>
                                            <small className="text-muted">{formulario.Fecha_Inicio}</small>
                                        </Card.Body>
                                        <Card.Footer>
                                            {formulario.link_alternativo == null && <Button variant="light" style={{ width: '100%' }} onClick={() => redireccionar(formulario.UniqueID, formulario.hash)}>Inscribirse</Button>}
                                            {formulario.link_alternativo != null && <Button variant="light" style={{ width: '100%' }} href={formulario.link_alternativo} target="_blank">Inscribirse</Button>}
                                        </Card.Footer>
                                    </Card>
                                </Col>
                            )

                        }
                    </Row>
                </Container>
            </Row>
            <Row style={{ padding: '5%' }}>
                <Col xs={12} md={12} lg={6} style={{ display: 'grid', placeItems: 'center', marginTop: '25px' }}>
                    <Card style={{ width: '60%', backgroundColor: 'transparent', color: 'white', border: '1px solid transparent' }}>
                        <Card.Img src={asesor.foto} style={{ borderRadius: '10%' }}></Card.Img>
                    </Card>
                </Col>
                <Col xs={12} md={12} lg={6} style={{ color: 'white', textAlign: 'left', marginTop: '25px' }}>
                    <Container>
                        <Row>
                            <img src='https://ik.imagekit.io/p5ethkcnzwh/Blanco_Logo_final_2022_ABG_6V4gmyglP.png?ik-sdk-version=javascript-1.4.3&updatedAt=1657298859263' style={{ width: '100%' }} />
                        </Row>
                        <Row style={{ marginTop: '25px' }}>
                            <h1>{asesor.Nombres + ' ' + asesor.Apellidos}</h1>
                            <h2>{asesor.puesto}</h2>
                            <h2>Asociación Bancaria de Guatemala - ABG</h2>
                            <h2>{asesor.institucion}</h2>
                        </Row>
                        <Row>
                            <h1>CONTACTO</h1>
                        </Row>
                        <Row>
                            <h4><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                                <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                            </svg>&nbsp;&nbsp;&nbsp;&nbsp;<a href={"mailto:" + asesor.email} target='_blank' className='link'>{asesor.email}</a></h4>
                        </Row>
                        <Row>
                            <h4><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-envelope-fill" viewBox="0 0 16 16">
                                <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
                            </svg>&nbsp;&nbsp;&nbsp;&nbsp;<a href={"mailto:" + asesor.correo_institucion} target='_blank' className='link'>{asesor.correo_institucion}</a></h4>
                        </Row>
                        <Row>
                            <h4><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-telephone" viewBox="0 0 16 16">
                                <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                            </svg>&nbsp;&nbsp;&nbsp;&nbsp;<a href={"tel://" + asesor.telefono_institucion} target='_blank' className='link'>{asesor.telefono_institucion}</a></h4>
                        </Row>
                        <Row>
                            <h4><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-whatsapp" viewBox="0 0 16 16">
                                <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                            </svg>&nbsp;&nbsp;&nbsp;&nbsp;<a href={"https://wa.me/" + asesor.whatsapp} target='_blank' className='link'>{asesor.whatsapp}</a></h4>
                        </Row>
                        <Row>
                            <h4><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-globe2" viewBox="0 0 16 16">
                                <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855-.143.268-.276.56-.395.872.705.157 1.472.257 2.282.287V1.077zM4.249 3.539c.142-.384.304-.744.481-1.078a6.7 6.7 0 0 1 .597-.933A7.01 7.01 0 0 0 3.051 3.05c.362.184.763.349 1.198.49zM3.509 7.5c.036-1.07.188-2.087.436-3.008a9.124 9.124 0 0 1-1.565-.667A6.964 6.964 0 0 0 1.018 7.5h2.49zm1.4-2.741a12.344 12.344 0 0 0-.4 2.741H7.5V5.091c-.91-.03-1.783-.145-2.591-.332zM8.5 5.09V7.5h2.99a12.342 12.342 0 0 0-.399-2.741c-.808.187-1.681.301-2.591.332zM4.51 8.5c.035.987.176 1.914.399 2.741A13.612 13.612 0 0 1 7.5 10.91V8.5H4.51zm3.99 0v2.409c.91.03 1.783.145 2.591.332.223-.827.364-1.754.4-2.741H8.5zm-3.282 3.696c.12.312.252.604.395.872.552 1.035 1.218 1.65 1.887 1.855V11.91c-.81.03-1.577.13-2.282.287zm.11 2.276a6.696 6.696 0 0 1-.598-.933 8.853 8.853 0 0 1-.481-1.079 8.38 8.38 0 0 0-1.198.49 7.01 7.01 0 0 0 2.276 1.522zm-1.383-2.964A13.36 13.36 0 0 1 3.508 8.5h-2.49a6.963 6.963 0 0 0 1.362 3.675c.47-.258.995-.482 1.565-.667zm6.728 2.964a7.009 7.009 0 0 0 2.275-1.521 8.376 8.376 0 0 0-1.197-.49 8.853 8.853 0 0 1-.481 1.078 6.688 6.688 0 0 1-.597.933zM8.5 11.909v3.014c.67-.204 1.335-.82 1.887-1.855.143-.268.276-.56.395-.872A12.63 12.63 0 0 0 8.5 11.91zm3.555-.401c.57.185 1.095.409 1.565.667A6.963 6.963 0 0 0 14.982 8.5h-2.49a13.36 13.36 0 0 1-.437 3.008zM14.982 7.5a6.963 6.963 0 0 0-1.362-3.675c-.47.258-.995.482-1.565.667.248.92.4 1.938.437 3.008h2.49zM11.27 2.461c.177.334.339.694.482 1.078a8.368 8.368 0 0 0 1.196-.49 7.01 7.01 0 0 0-2.275-1.52c.218.283.418.597.597.932zm-.488 1.343a7.765 7.765 0 0 0-.395-.872C9.835 1.897 9.17 1.282 8.5 1.077V4.09c.81-.03 1.577-.13 2.282-.287z" />
                            </svg>&nbsp;&nbsp;&nbsp;&nbsp;<a href="https://www.ebg.edu.gt" target='_blank' className='link'>www.ebg.edu.gt</a></h4>
                        </Row>
                        <Row style={{ marginTop: '15px' }}>
                            <Col xs={3} md={3} lg={3}>
                                <a href="https://www.instagram.com/ebg.guatemala/?hl=es-la" target='_blank' className='link'><svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" className="bi bi-instagram" viewBox="0 0 16 16">
                                    <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                                </svg></a>
                            </Col>
                            <Col xs={3} md={3} lg={3}>
                                <a href="https://www.facebook.com/abg.ebg" target='_blank' className='link'><svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" className="bi bi-facebook" viewBox="0 0 16 16">
                                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                                </svg></a>
                            </Col>
                            <Col xs={3} md={3} lg={3}>
                                <a href="https://twitter.com/ABG_EBG" target='_blank' className='link'><svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" className="bi bi-twitter" viewBox="0 0 16 16">
                                    <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                                </svg></a>
                            </Col>
                            <Col xs={3} md={3} lg={3}>
                                <a href="https://www.linkedin.com/in/escuela-bancaria-de-guatemala-b0286b9b/" target='_blank' className='link'><svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">
                                    <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                                </svg></a>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>
    )
}

export default Page