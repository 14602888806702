import React, { useEffect, useState } from 'react';
//import DEFAULT_URL from '../../../../../api/url';
import { Container, Form, Row, Col, Button } from "react-bootstrap";
import Participante from './Participante';
const Diploma = (props) => {
    const [participantes, setParticipantes] = useState([]);
    const [contador, setContador] = useState(0);
    const [cambios, setCambios] = useState(0);
    const [filteredData, setFilteredData] = useState([]);
    const [filterData, setFilterData] = useState('');
    const [accionesSeleccionMultiple, setAccionesSeleccionMultiple] = useState(false);
    const [seleccionarTodos, setSeleccionarTodos] = useState(false);
    useEffect(() => {
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/detalle_inscripcion/sin-diploma/evento/' + props.evento.UniqueID + '/' + props.diploma, { cache: "no-cache" })
            .then((res) => {
                return res.json();
            })
            .then(data => {
                setParticipantes([]);
                setFilteredData([]);
                let tmp = data.rows;
                tmp.map((actual, index) => {
                    actual.checked = false;
                    if (index === (tmp.length - 1)) {
                        setParticipantes(data.rows);
                        setFilteredData(data.rows);
                        setAccionesSeleccionMultiple(true);
                        return true;
                    }
                });
            });
    }, [contador]);
    useEffect(() => {
        multipleSelect();
    }, [cambios]);

    const checkEvent = (evt) => {
        let tmp = filteredData;
        let tmp2 = participantes;
        const index_finder = (participante) => participante.UniqueID == evt.target.name;
        if (tmp.findIndex(index_finder) >= 0) {
            tmp[tmp.findIndex(index_finder)].checked = evt.target.checked;
            setFilteredData(tmp);
            tmp2[tmp2.findIndex(index_finder)].checked = evt.target.checked;
            setParticipantes(tmp2);
            setCambios(cambios + 1);
        }
    }
    const filterEvent = (evt) => {
        setFilterData(evt.target.value);
        setCambios(cambios + 1);
        let result = [];
        result = participantes.filter((actual) => {
            return actual.Nombres.search(evt.target.value) != -1 || actual.Apellidos.search(evt.target.value) != -1 || actual.Correo.search(evt.target.value) != -1;
        });
        setFilteredData(result);
        setSeleccionarTodos(false);
        setCambios(cambios + 1);
    }
    const multipleSelect = () => {
        let contador = 0;
        participantes.map((actual, index) => {
            if (actual.checked == true) {
                contador++;
            }
            if (index === (participantes.length - 1)) {
                if (contador > 0) {
                    setAccionesSeleccionMultiple(false);
                } else {
                    setAccionesSeleccionMultiple(true);
                }
            }
        });
    }
    const sendDiplomaSelected = () => {
        props.cargar('Enviando diplomas...');
        participantes.map((actual, index) => {
            if (actual.checked === true) {
                const info = { diploma: props.diploma, participante: actual.UniqueID };
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(info)
                };
                fetch(process.env.REACT_APP_DEFAULT_URL + '/api/diplomas/send', requestOptions)
                    .then((res) => {
                        return res.json();
                    })
                    .then(datos => {
                        if (datos.codigo == 200) {
                            props.changeStatus((index / participantes.length) * 100);
                            setContador(contador + 1);
                            if (index == (participantes.length - 1)) {
                                props.operacionExito('Diplomas enviados exitosamente');
                                return true;
                            }
                        } else {
                            props.operacionError('Ocurrió un error al enviar los diplomas');
                        }
                    });
            } else {
                props.changeStatus((index / participantes.length) * 100);
                if (index == (participantes.length - 1)) {
                    props.operacionExito('Diplomas enviados exitosamente');
                    return true;
                }
            }
        });
    }
    const downloadDiplomaSelected = () => {
        props.cargar('Descargando diplomas...');
        participantes.map((actual, index) => {
            if (actual.checked === true) {
                fetch(process.env.REACT_APP_DEFAULT_URL + '/api/diplomas/getDiplomaPDF/' + props.diploma + '/' + actual.UniqueID)
                    .then((res) => {
                        return res.json();
                    })
                    .then(datos => {
                        if (datos.codigo == 200) {
                            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/diplomas/getObject/' + datos.llave).then(response => {
                                response.blob().then(blob => {
                                    props.changeStatus((index / participantes.length) * 100);
                                    setContador(contador + 1);
                                    const fileURL = window.URL.createObjectURL(blob);
                                    let alink = document.createElement('a');
                                    alink.href = fileURL;
                                    alink.download = 'diploma-' + Date.now() + '-' + actual.UniqueID + '.pdf';
                                    alink.click();
                                    if (index == (participantes.length - 1)) {
                                        props.operacionExito('Diplomas generados exitosamente');
                                        return true;
                                    }
                                });
                            })
                        } else {
                            props.operacionError('Ocurrió un error al generar los diplomas');
                        }
                    });
            } else {
                props.changeStatus((index / participantes.length) * 100);
                if (index == (participantes.length - 1)) {
                    props.operacionExito('Diplomas generados exitosamente');
                    return true;
                }
            }
        });
    }
    const checkAll = (evt) => {
        if (evt.target.checked === true) {
            const tmp = filteredData;
            tmp.map((actual, index) => {
                actual.checked = true;
                if (index === (tmp.length - 1)) {
                    setFilteredData(tmp);
                    setCambios(cambios + 1);
                }
            });
        } else {
            const tmp = filteredData;
            tmp.map((actual, index) => {
                actual.checked = false;
                if (index === (tmp.length - 1)) {
                    setFilteredData(tmp);
                    setCambios(cambios + 1);
                }
            });
        }
        setSeleccionarTodos(evt.target.checked);
    }
    const genDiplomaSeleccionados = () => {
        props.cargar('Generando certificados...');
        let selected = [];
        participantes.map((actual, index) => {
            if (actual.checked === true) {
                selected.push(actual);
                if (index == (participantes.length - 1)) {
                    props.changeStatus(30);
                    const info = { diploma: props.diploma, participantes: selected };
                    const requestOptions = {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(info)
                    };
                    fetch(process.env.REACT_APP_DEFAULT_URL + '/api/diploma/gen/lote', requestOptions)
                        .then((res) => {
                            return res.json();
                        })
                        .then(datos => {
                            if (datos.codigo == 200) {
                                props.changeStatus((index / participantes.length) * 100);
                                setContador(contador + 1);
                                if (index == (participantes.length - 1)) {
                                    props.operacionExito('Diplomas generados exitosamente');
                                    return true;
                                }
                            } else {
                                props.operacionError('Ocurrió un error al generar los certificados');
                            }
                        });
                }
            } else {
                if (index == (participantes.length - 1)) {
                    props.changeStatus(30);
                    const info = { diploma: props.diploma, participantes: selected };
                    const requestOptions = {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(info)
                    };
                    fetch(process.env.REACT_APP_DEFAULT_URL + '/api/diploma/gen/lote', requestOptions)
                        .then((res) => {
                            return res.json();
                        })
                        .then(datos => {
                            if (datos.codigo == 200) {
                                props.changeStatus((index / participantes.length) * 100);
                                setContador(contador + 1);
                                if (index == (participantes.length - 1)) {
                                    props.operacionExito('Diplomas generados exitosamente');
                                    setContador(contador + 1);
                                    return true;
                                }
                            } else {
                                setContador(contador + 1);
                                props.operacionError('Ocurrió un error al generar los certificados');
                            }
                        });
                }
            }
        });
    }
    return (
        <Container style={{ marginTop: '45px' }}>
            <Row style={{ marginTop: '15px', marginBottom: '15px' }}>
                <Button variant='outline-dark' onClick={() => { setContador(contador + 1); }}>Refrescar</Button>
            </Row>
            <Row>
                <Form.Group className="mb-3">
                    <Form.Label>Buscar</Form.Label>
                    <Form.Control value={filterData} onChange={filterEvent} placeholder="Puedes buscar por nombres, apellidos o correo" />
                </Form.Group>
            </Row>
            <Row style={{ marginTop: '15px' }}>
                <Col xs={12} md={12} lg={12} style={{ marginBottom: '35px', display: 'none' }}>
                    <Row style={{ padding: '5%' }}>
                        <Button disabled={accionesSeleccionMultiple} onClick={sendDiplomaSelected} variant='success'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-envelope-check" viewBox="0 0 16 16">
                            <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2H2Zm3.708 6.208L1 11.105V5.383l4.708 2.825ZM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2-7-4.2Z" />
                            <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686Z" />
                        </svg>&nbsp;&nbsp;&nbsp;Enviar diploma a participantes seleccionados</Button>
                    </Row>
                </Col>
                <Col xs={12} md={12} lg={12} style={{ marginBottom: '35px', display: 'none' }}>
                    <Row style={{ padding: '5%' }}>
                        <Button disabled={accionesSeleccionMultiple} onClick={downloadDiplomaSelected} variant='success'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-file-earmark-arrow-down" viewBox="0 0 16 16">
                            <path d="M8.5 6.5a.5.5 0 0 0-1 0v3.793L6.354 9.146a.5.5 0 1 0-.708.708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 10.293V6.5z" />
                            <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                        </svg>&nbsp;&nbsp;&nbsp;Descargar diploma de estudiantes seleccionados</Button>
                    </Row>
                </Col>
                <Col xs={12} md={12} lg={12} style={{ marginBottom: '35px' }}>
                    <Row style={{ padding: '5%' }}>
                        <Button disabled={accionesSeleccionMultiple} onClick={genDiplomaSeleccionados} variant='success'>Generar diploma a seleccionados</Button>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Form.Group className="mb-3" controlId='seleccionar-todo'>
                    <Form.Check onChange={checkAll} name='seleccionar-todo' checked={seleccionarTodos} type="checkbox" label='Seleccionar todo' />
                </Form.Group>
            </Row>
            <Row style={{ overflowY: 'scroll', maxHeight: '350px' }}>
                <Container fluid>
                    {filteredData.map((actual) => <Participante checkEvent={checkEvent} diploma={props.diploma} participante={actual} tipo={1} />)}
                </Container>
            </Row>
        </Container>
    )
}

export default Diploma