import React, { useEffect, useState } from 'react';
//import DEFAULT_URL from '../../../../../api/url';
import { Container, Form, Row, Col, Button } from "react-bootstrap";
import Participante from './Participante';
const Certificado = (props) => {
    const [participantes, setParticipantes] = useState([]);
    const [contador, setContador] = useState(0);
    const [cambios, setCambios] = useState(0);
    const [filteredData, setFilteredData] = useState([]);
    const [filterData, setFilterData] = useState('');
    const [accionesSeleccionMultiple, setAccionesSeleccionMultiple] = useState(false);
    const [seleccionarTodos, setSeleccionarTodos] = useState(false);
    const [anterior, setAnterior] = useState(null);
    const [siguiente, setSiguiente] = useState(null);
    useEffect(() => {
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/detalle_inscripcion/sin-diploma/evento/' + props.evento.UniqueID + '/' + props.cert, { cache: "no-cache" })
            .then((res) => {
                return res.json();
            })
            .then(data => {
                setParticipantes([]);
                setFilteredData([]);
                setAccionesSeleccionMultiple(true);
                let tmp = data.rows;
                tmp.map((actual, index) => {
                    actual.checked = false;
                    if (index === (tmp.length - 1)) {
                        setParticipantes(data.rows);
                        setFilteredData(data.rows);
                        return true;
                    }
                });
            });
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/certificado/continuaciones/' + props.cert, { cache: "no-cache" })
            .then((res) => {
                return res.json();
            })
            .then(data => {
                setAnterior(null);
                setSiguiente(null);
                if (data.resultado_anterior.length > 0) {
                    setAnterior(data.resultado_anterior[0]);
                }
                if (data.resultado_siguiente.length > 0) {
                    setSiguiente(data.resultado_siguiente[0]);
                }
            });
    }, [contador]);
    useEffect(() => {
        multipleSelect();
    }, [cambios]);

    const checkEvent = (evt) => {
        let tmp = filteredData;
        let tmp2 = participantes;
        const index_finder = (participante) => participante.UniqueID == evt.target.name;
        if (tmp.findIndex(index_finder) >= 0) {
            tmp[tmp.findIndex(index_finder)].checked = evt.target.checked;
            setFilteredData(tmp);
            tmp2[tmp2.findIndex(index_finder)].checked = evt.target.checked;
            setParticipantes(tmp2);
            setCambios(cambios + 1);
        }
    }
    const filterEvent = (evt) => {
        setFilterData(evt.target.value);
        setCambios(cambios + 1);
        let result = [];
        result = participantes.filter((actual) => {
            return actual.Nombres.search(evt.target.value) != -1 || actual.Apellidos.search(evt.target.value) != -1 || actual.Correo.search(evt.target.value) != -1;
        });
        setFilteredData(result);
        setSeleccionarTodos(false);
        setCambios(cambios + 1);
    }
    const multipleSelect = () => {
        let contador = 0;
        participantes.map((actual, index) => {
            if (actual.checked == true) {
                contador++;
            }
            if (index === (participantes.length - 1)) {
                if (contador > 0) {
                    setAccionesSeleccionMultiple(false);
                } else {
                    setAccionesSeleccionMultiple(true);
                }
            }
        });
    }
    const checkAll = (evt) => {
        if (evt.target.checked === true) {
            const tmp = filteredData;
            tmp.map((actual, index) => {
                actual.checked = true;
                if (index === (tmp.length - 1)) {
                    setFilteredData(tmp);
                    setCambios(cambios + 1);
                }
            });
        } else {
            const tmp = filteredData;
            tmp.map((actual, index) => {
                actual.checked = false;
                if (index === (tmp.length - 1)) {
                    setFilteredData(tmp);
                    setCambios(cambios + 1);
                }
            });
        }
        setSeleccionarTodos(evt.target.checked);
    }
    const genCertSeleccionados = () => {
        props.cargar('Generando certificados...');
        let selected = [];
        participantes.map((actual, index) => {
            if (actual.checked === true) {
                selected.push(actual);
                if (index == (participantes.length - 1)) {
                    props.changeStatus(30);
                    const info = { cert: props.cert, participantes: selected };
                    const requestOptions = {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(info)
                    };
                    fetch(process.env.REACT_APP_DEFAULT_URL + '/api/certificado/gen/lote', requestOptions)
                        .then((res) => {
                            return res.json();
                        })
                        .then(datos => {
                            if (datos.codigo == 200) {
                                props.changeStatus((index / participantes.length) * 100);
                                setContador(contador + 1);
                                if (index == (participantes.length - 1)) {
                                    props.operacionExito('Certificados generados exitosamente');
                                    return true;
                                }
                            } else {
                                props.operacionError('Ocurrió un error al generar los certificados');
                            }
                        });
                }
            } else {
                if (index == (participantes.length - 1)) {
                    props.changeStatus(30);
                    const info = { cert: props.cert, participantes: selected };
                    const requestOptions = {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(info)
                    };
                    fetch(process.env.REACT_APP_DEFAULT_URL + '/api/certificado/gen/lote', requestOptions)
                        .then((res) => {
                            return res.json();
                        })
                        .then(datos => {
                            if (datos.codigo == 200) {
                                props.changeStatus((index / participantes.length) * 100);
                                setContador(contador + 1);
                                if (index == (participantes.length - 1)) {
                                    props.operacionExito('Certificados generados exitosamente');
                                    setContador(contador + 1);
                                    return true;
                                }
                            } else {
                                setContador(contador + 1);
                                props.operacionError('Ocurrió un error al generar los certificados');
                            }
                        });
                }
            }
        });
    }
    return (
        <Container style={{ marginTop: '45px' }}>
            <Row>
                {anterior!=null && <p><strong>Certificado anterior: </strong>{anterior.nombre + ' ('+anterior.fecha+')'}</p>}
                {siguiente!=null && <p><strong>Certificado que continúa numeración: </strong>{siguiente.nombre + ' ('+siguiente.fecha+')'}</p>}
            </Row>
            <Row>
                <h1>Participantes sin certificado</h1>
            </Row>
            <Row style={{ marginTop: '15px', marginBottom: '15px' }}>
                <Button variant='outline-dark' onClick={() => { setContador(contador + 1); }}>Refrescar</Button>
            </Row>
            <Row>
                <Form.Group className="mb-3">
                    <Form.Label>Buscar</Form.Label>
                    <Form.Control value={filterData} onChange={filterEvent} placeholder="Puedes buscar por nombres, apellidos o correo" />
                </Form.Group>
            </Row>
            <Row style={{ marginTop: '25px', marginBottom: '25px' }}>
                <Button variant="success" disabled={accionesSeleccionMultiple} onClick={genCertSeleccionados}>Generar certificado a seleccionados</Button>
            </Row>
            <Row>
                <Form.Group className="mb-3" controlId='seleccionar-todo'>
                    <Form.Check onChange={checkAll} name='seleccionar-todo' checked={seleccionarTodos} type="checkbox" label='Seleccionar todo' />
                </Form.Group>
            </Row>
            <Row style={{ overflowY: 'scroll', maxHeight: '350px' }}>
                <Container fluid>
                    {filteredData.map((actual) => <Participante checkEvent={checkEvent} diploma={props.diploma} participante={actual} tipo={2} />)}
                </Container>
            </Row>
        </Container>
    )
}

export default Certificado