import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Form, InputGroup, Button } from 'react-bootstrap'
//import DEFAULT_URL from '../../api/url';
const CreatePago = () => {
    const [contador] = useState(0);
    const [cambios, setCambios] = useState(0);
    const [moneda, setMoneda] = useState('');
    const [total, setTotal] = useState(0.00);
    const [concepto, setConcepto] = useState('');
    const [descripcion, setDescripcion] = useState('');
    const [cargando, setCargando] = useState(false);
    const [exito, setExito] = useState(false);
    const [error, setError] = useState(false);
    const [form, setForm] = useState(true);
    const [eventos, setEventos] = useState([]);
    const [evento, setEvento] = useState('');
    const [validated, setValidated] = useState(false);
    useEffect(() => {
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/evento/inscripciones/abiertas', { cache: "no-cache", credentials: 'include' })
            .then((res) => {
                return res.json();
            })
            .then(data => {
                setEventos(data.rows);
            });
        return true;
    }, [contador]);
    useEffect(() => {
        return true;
    }, [cambios]);
    const onChange = (evt) => {
        if (evt.target.name == 'moneda') {
            setMoneda(evt.target.value);
        } else if (evt.target.name == 'total') {
            setTotal(evt.target.value);
        } else if (evt.target.name == 'concepto') {
            setConcepto(evt.target.value);
        } else if (evt.target.name == 'descripcion') {
            setDescripcion(evt.target.value);
        } else if (evt.target.name == 'evento') {
            setEvento(evt.target.value);
        }
        setCambios(cambios + 1);
    }
    const onSubmit = (evt) => {
        const form = evt.currentTarget;
        if (form.checkValidity() === false) {
            evt.preventDefault();
            evt.stopPropagation();
        } else {
            evt.preventDefault();
            setCargando(true);
            setCambios(cambios + 1);
            let info = { moneda, total, concepto, evento, descripcion };
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(info)
            };
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/cobro/create', requestOptions)
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    if (data.codigo == 200) {
                        setCargando(false);
                        setExito(true);
                        window.location.replace('/pagos-electronicos/view/'+data.id);
                    } else {
                        setCargando(false);
                        setError(true);
                    }
                    setCambios(cambios + 1);
                });
        }
        setValidated(true);
        setCambios(cambios + 1);
    }
    return (
        <Container style={{ padding: '2%' }}>
            <Row style={{ padding: '2%', textAlign: 'left', borderLeft: '5px solid #1e3d52 ', backgroundColor: 'rgb(250,250,250)' }}>
                <h1>Habilitar cobro electrónico</h1>
            </Row>
            <Row style={{ padding: '2%', textAlign: 'left' }}>
                <Form noValidate validated={validated} onSubmit={onSubmit}>
                    <Row style={{ padding: '1%' }}>
                        <Form.Group controlId="concepto">
                            <Form.Label>Concepto del cobro</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                value={concepto}
                                placeholder="Concepto del cobro"
                                onChange={onChange}
                                name='concepto'
                                disabled={cargando || exito || error}
                            />
                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                            <Form.Control.Feedback type='invalid'>Por favor, completa este campo</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row style={{ padding: '1%' }}>
                        <Form.Group controlId="descripion">
                            <Form.Label>Breve descripcion del cobro</Form.Label>
                            <Form.Control
                                required
                                as='textarea'
                                rows={3}
                                value={descripcion}
                                style={{ resize: 'none' }}
                                placeholder="Breve descripcion del cobro"
                                onChange={onChange}
                                name='descripcion'
                                disabled={cargando || exito || error}
                            />
                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                            <Form.Control.Feedback type='invalid'>Por favor, completa este campo</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row style={{ padding: '1%' }}>
                        <Form.Group controlId="total">
                            <Form.Label>Total a cobrar</Form.Label>
                            <Form.Control
                                required
                                type="number"
                                step={0.01}
                                value={total}
                                placeholder="Total a cobrar"
                                onChange={onChange}
                                name='total'
                                disabled={cargando || exito || error}
                            />
                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                            <Form.Control.Feedback type='invalid'>Por favor, completa este campo</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row style={{ padding: '1%' }}>
                        <Form.Group controlId="moneda">
                            <Form.Label>Tipo moneda</Form.Label>
                            <Form.Select required onChange={onChange} name='moneda' value={moneda} disabled={cargando || exito || error}>
                                <option value=''>Seleccione la moneda</option>
                                <option value="GTQ">GTQ</option>
                                <option value="USD">USD</option>
                            </Form.Select>
                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                            <Form.Control.Feedback type='invalid'>Por favor, completa este campo</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row style={{ padding: '1%' }}>
                        <Form.Group controlId="evento">
                            <Form.Label>Evento</Form.Label>
                            <Form.Select required onChange={onChange} name='evento' value={evento} disabled={cargando || exito || error}>
                                <option value=''>Seleccione el evento</option>
                                {eventos.map((actual, index) => <option value={actual.UniqueID} key={'evento-seleccion-' + index}>{actual.Nombre + ' (' + actual.Fecha_Inicio + ')'}</option>)}
                            </Form.Select>
                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                            <Form.Control.Feedback type='invalid'>Por favor, completa este campo</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row style={{ padding: '1%' }}>
                        <Button variant='outline-dark' type='submit' disabled={cargando || exito || error}>Habilitar cobro electrónico</Button>
                    </Row>
                </Form>
            </Row>
        </Container>
    )
}

export default CreatePago