import React, { useState, useEffect } from "react";
import DateTimeDisplay from "./DateTimeDisplay";
import { useCountdown } from "./hooks/useCountdown";
import { Container, Row, Col } from "react-bootstrap";
const ExpiredNotice = () => {
  return (
    <Container fluid style={{ padding: "5%" }}>
      <Row>
        <Col xs={4} md={4} lg={4}></Col>
        <Col xs={4} md={4} lg={4}>
          <img
            src="https://test-escuelabancaria.s3.us-east-2.amazonaws.com/Medios+General/Logotipos+Finales+ABG+2022-01.png"
            style={{ width: "100%", objectFit: "cover" }}
          />
        </Col>
        <Col xs={4} md={4} lg={4}></Col>
      </Row>
      <Row>
        <h1>Espacio en agenda finalizado</h1>
      </Row>
    </Container>
  );
};

const ShowCounter = ({ days, hours, minutes, seconds }) => {
  const [color, setColor] = useState("5px solid #1e3d52");
  const [background, setBackground] = useState("transparent");
  const [fontColor, setFontColor] = useState("black");
  useEffect(() => {
    if (minutes > 8 && hours == 0 && days == 0) {
      setColor("5px solid #1e3d52");
      setBackground("transparent");
    } else if (minutes < 8 && minutes >= 4 && hours == 0 && days == 0) {
      setColor("5px solid yellow");
      setBackground("yellow");
      setFontColor('red')
    } else if (minutes < 4 && hours == 0 && days == 0) {
      setColor("5px solid red");
      setBackground("red");
      setFontColor('white');
    }
  }, [days, hours, minutes, seconds]);

  return (
    <Container fluid style={{ padding: "5%" }}>
      <Row
        style={{
          border: color,
          backgroundColor: background,
          padding: "2%",
          color: fontColor,
        }}
      >
        {/*<DateTimeDisplay value={days} type={'DIAS'} isDanger={days <= 3} />
                <DateTimeDisplay value={hours} type={'HORAS'} isDanger={false} />*/}
        <DateTimeDisplay value={minutes} type={"MINUTOS"} isDanger={false} />
        <DateTimeDisplay value={seconds} type={"SEGUNDOS"} isDanger={false} />
      </Row>
    </Container>
  );
};

const CountdownTimer = ({ targetDate }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  if (days + hours + minutes + seconds <= 0) {
    return <ExpiredNotice />;
  } else {
    return (
      <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    );
  }
};

export default CountdownTimer;
