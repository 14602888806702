import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import "./Notificaciones.css"
import { ModalCargando } from '../../../Utils/Modal-Cargando/ModalCargando';
import { SuccessModal } from '../../../Utils/SuccessModal/SuccessModal';
import { ErrorModal } from '../../../Utils/ErrorModal/ErrorModal';
//import DEFAULT_URL from '../../../../api/url';
const Notificaciones = (props) => {
    const [contador, setContador] = useState(0);
    const [notificacion, setNotificacion] = useState(1);
    const [mensajeCargando, setMensajeCargando] = useState('');
    const [modalCargando, setModalCargando] = useState(false);
    const [modalSucc, setModalSucc] = useState(false);
    const [modalError, setModalError] = useState(false);
    const [mensaje, setMensaje] = useState('');
    const [consideracion, setConsideracion] = useState('');
    useEffect(() => {

    }, [contador])

    const onChange = (evt) => {
        setNotificacion(evt.target.value);
        setContador(contador + 1);
    }
    const sendMail = (evt) => {
        evt.preventDefault();
        setModalCargando(true);
        setMensajeCargando('Informando a todos...');
        setContador(contador + 1);
        const info = { UniqueID: props.evento.UniqueID, evento: props.evento.Nombre, fecha_inicio: props.evento.Fecha_Inicio, fecha_fin: props.evento.Fecha_Fin, horas: props.evento.duracion, precio_agremiados: props.evento.precio_agremiados, precio_no_agremiados: props.evento.precio_no_agremiados, arte: props.evento.flier };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        if (notificacion == 1) {
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/email/informar/cambios/agenda', requestOptions)
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    if (data.codigo == 200) {
                        setMensaje('Notificación enviada exitosamente');
                        setConsideracion('');
                        setModalSucc(true);
                        setModalCargando(false);
                    } else {
                        setModalCargando(false);
                        setMensaje(data.respuesta);
                        setModalError(true);
                    }
                    setContador(contador + 1);
                });
        } else if (notificacion == 2) {
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/email/informar/cambios/material', requestOptions)
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    if (data.codigo == 200) {
                        setMensaje('Notificación enviada exitosamente');
                        setConsideracion('');
                        setModalSucc(true);
                        setModalCargando(false);
                    } else {
                        setMensaje(data.respuesta);
                        setModalError(true);
                        setModalCargando(false);
                    }
                    setContador(contador + 1);
                });
        } else if (notificacion == 3) {
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/send/gafetes/all', requestOptions)
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    if (data.codigo == 200) {
                        setMensaje('Notificación enviada exitosamente');
                        setConsideracion(data.log);
                        setModalSucc(true);
                        setModalCargando(false);
                    } else {
                        setMensaje(data.respuesta);
                        setModalError(true);
                        setModalCargando(false);
                    }
                    setContador(contador + 1);
                });
        } else {
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/print/detalle_inscripcion/gafete/' + props.evento.UniqueID)
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    if (data.codigo == 200) {
                        setMensaje('Notificación enviada exitosamente');
                        setConsideracion(data.url);
                        setModalSucc(true);
                        setModalCargando(false);
                    } else {
                        setMensaje(data.respuesta);
                        setModalError(true);
                        setModalCargando(false);
                    }
                    setContador(contador + 1);
                });
        }
    }
    return (
        <Container>
            <h1>Notificaciones</h1>
            <Form onSubmit={sendMail}>
                <Row>
                    <Col xs={12} md={6} lg={6}>
                        <Form.Group className="mb-3" controlId="notificacion">
                            <Form.Control as="select" required aria-label="modalidad" name="notificacion" onChange={onChange} value={notificacion}>
                                <option disabled>Seleccione la modalidad del evento</option>
                                <option value={1}>Cambio en información de evento</option>
                                <option value={2}>Cambio en material de evento</option>
                                <option value={3}>Envío de gafete virtual a participantes</option>
                                <option value={4}>Envío de gafete virtual en PDF a personal autorizado</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6} lg={6}>
                        <Button variant="dark" type="submit">Enviar Notificación</Button>
                    </Col>
                </Row>
            </Form>
            <SuccessModal
                color={"#b5bad0"}
                show={modalSucc}
                onHide={() => { setModalSucc(false); setContador(contador + 1); }}
                mensaje={mensaje}
                consideracion={consideracion}
                enlace={''}
                llevaenlace={false}
            />
            <ModalCargando
                color={"#b5bad0"}
                show={modalCargando}
                onHide={() => { setModalCargando(false); setContador(contador + 1); }}
                mensaje={mensajeCargando}
            />
            <ErrorModal
                color={"#b5bad0"}
                show={modalError}
                onHide={() => { setModalError(false); setContador(contador + 1); }}
                mensaje={mensaje}
                consideracion={consideracion}
            />
        </Container>
    );
}

export default Notificaciones