import React from "react";
import { Container, Row, Col } from "react-bootstrap";
const OkInHouse = () => {
  return (
    <Container
      fluid
      style={{
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        display: "flex",
        placeItems: "center",
      }}
    >
      <Row style={{ padding: "1%" }}>
        <Container
          fluid
          style={{
            boxShadow: "10px 10px 56px 10px rgba(30,61,82,0.91)",
            borderRadius: "2em",
          }}
        >
          <Row
            style={{ display: "flex", placeItems: "center", padding: "5%" }}
            className="me-auto"
          >
            <Col xs={12} md={12} lg={6}>
              <img
                src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/1674081393254-LOGOS+azul.png"
                style={{ width: "100%" }}
              />
            </Col>
            <Col xs={12} md={12} lg={6}>
              <h1 style={{ textAlign: "justify" }}>
                MUCHAS GRACIAS POR SU TIEMPO
              </h1>
              <p style={{ textAlign: "justify" }}>
                Su formulario ha sido almacenado, se estará comunicando vía
                email el equipo de dirección académica para dar seguimiento a la
                propuesta.
                <br />
              </p>
              <a
                style={{ textAlign: "justify" }}
                href="https://escuelabancaria.gt/in-house/solicitud"
              >
                Enviar otra propuesta
              </a>
            </Col>
          </Row>
        </Container>
      </Row>
    </Container>
  );
};

export default OkInHouse;
