import React, { useState, useEffect } from 'react';
import { Button } from "react-bootstrap";
//import DEFAULT_URL from '../../api/url';
import './Gerencia.css'
import ModalIngresos from '../Eventos/Evento-Detalle/Dashboard/Modal-Ingresos/ModalIngresos';
import ModalRendimiento from '../Eventos/Evento-Detalle/Dashboard/Modal-Rendimiento/ModalRendimiento';
const Evento = (props) => {
    const [contador, setContador] = useState(0);
    const [ventas, setVentas] = useState([]);
    const [ingresos, setIngresos] = useState([]);
    const [ejecutado, setEjecutado] = useState({});
    const [modalIngresos, setModalIngresos] = useState(false);
    const [modalRendimiento, setModalRendimiento] = useState(0);
    useEffect(() => {
        async function fetchData() {
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/evento/ventas/' + props.evento.UniqueID, { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setVentas(data.rows);
                });

            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/evento/ingresos/reporte/' + props.evento.UniqueID, { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    console.log(data);
                    setEjecutado(data);
                });
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/evento/ingresos/' + props.evento.UniqueID, { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setIngresos(data.rows);
                });
        }
        fetchData();
    }, [contador,props.evento.UniqueID]);
    return (
        <tr key={props.evento.UniqueID}>
            <td>{props.evento.Nombre}</td>
            <td>{props.evento.Fecha_Inicio}</td>
            {(ejecutado.ingresos != null && ejecutado.ingresos[0].ingresos != null) && <td>{ejecutado.ingresos[0].ingresos}</td>}
            {(ejecutado.ingresos != null && ejecutado.ingresos[0].ingresos == null) && <td>Q 0.00</td>}
            <td>Q 0.00</td>
            {(ejecutado.ingresos != null && ejecutado.ingresos[0].ingresos != null) && <td>{ejecutado.ingresos[0].ingresos}</td>}
            {(ejecutado.ingresos != null && ejecutado.ingresos[0].ingresos == null) && <td>Q 0.00</td>}
            {(ejecutado.cortesias != null && ejecutado.cortesias[0].inscritos != null) && <td>{ejecutado.cortesias[0].inscritos}</td>}
            {(ejecutado.cortesias != null && ejecutado.cortesias[0].inscritos == null) && <td>0</td>}
            {(ejecutado.pagados != null && ejecutado.pagados[0].inscritos != null) && <td>{ejecutado.pagados[0].inscritos}</td>}
            {(ejecutado.pagados != null && ejecutado.pagados[0].inscritos == null) && <td>0</td>}
            {(ejecutado.sin_confirmar != null && ejecutado.sin_confirmar[0].inscritos != null) && <td>{ejecutado.sin_confirmar[0].inscritos}</td>}
            {(ejecutado.sin_confirmar != null && ejecutado.sin_confirmar[0].inscritos == null) && <td>0</td>}
            {(ejecutado.resultado_total_inscritos_ebc != null && ejecutado.resultado_total_inscritos_ebc[0].inscritos != null) && <td>{ejecutado.resultado_total_inscritos_ebc[0].inscritos}</td>}
            {(ejecutado.resultado_total_inscritos_ebc != null && ejecutado.resultado_total_inscritos_ebc[0].inscritos == null) && <td>0</td>}
            {(ejecutado.total_inscritos_ebg != null && ejecutado.total_inscritos_ebg[0].inscritos != null) && <td>{ejecutado.total_inscritos_ebg[0].inscritos}</td>}
            {(ejecutado.total_inscritos_ebg != null && ejecutado.total_inscritos_ebg[0].inscritos == null) && <td>0</td>}
            <td>{props.evento.ingresos}</td>
            <td>{props.evento.egresos}</td>
            <td>{props.evento.resultado}</td>
            <td>{props.evento.Minimo}</td>
            <td><Button variant="outline-dark" onClick={() => { setModalIngresos(true); setContador(contador + 1); }}>Ver detalle</Button></td>
            <td><Button variant="outline-dark" onClick={() => { setModalRendimiento(true); setContador(contador + 1); }}>Ver detalle</Button></td>
            <ModalIngresos
                show={modalIngresos}
                onHide={() => { setModalIngresos(false); setContador(contador + 1); }}
                data={ingresos}
                ventas={ventas}
            />
            <ModalRendimiento
                show={modalRendimiento}
                onHide={() => { setModalRendimiento(false); setContador(contador + 1); }}
                data={ventas}
                evt={props.evento.Nombre}
            />
        </tr >
    )
}

export default Evento