import React, { Component } from 'react';
import { Row, Button, Col, Form, Container } from "react-bootstrap";
export class EditarActividad extends Component {

    render() {
        return (
            <Container>
                <Row>
                    <Col>
                        <Form name={"form-" + this.props.actividad.UniqueID} onSubmit={this.props.update_actividad}>
                            <Form.Group className="mb-3" controlId="Actividad">
                                <Form.Label>Nombre De Actividad</Form.Label>
                                <Form.Control required type="text" placeholder="Nombre de la actividad" value={this.props.actividad.Nombre} name={'Nombre-' + this.props.actividad.UniqueID} onChange={this.props.onchangehandler} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="Descripcion">
                                <Form.Label>Descripción De Actividad</Form.Label>
                                <Form.Control required as="textarea" rows={3} style={{ resize: 'none' }} placeholder="Descripción de la actividad" value={this.props.actividad.Descripcion} name={'Descripcion-' + this.props.actividad.UniqueID} onChange={this.props.onchangehandler} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="Ponderacion">
                                <Form.Label>Ponderación De La Actividad</Form.Label>
                                <Form.Control required type="text" placeholder="Ponderación de la actividad" value={this.props.actividad.Ponderacion} name={'Ponderacion-' + this.props.actividad.UniqueID} onChange={this.props.onchangehandler} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="Criterio">
                                <Form.Label>Criterio De Calificación</Form.Label>
                                <Form.Control as="select" required aria-label="Criterio" name={'Criterio-' + this.props.actividad.UniqueID} onChange={this.props.onchangehandler} value={this.props.actividad.Criterio}  >
                                    <option value="">Seleccione el criterio de calificacion</option>
                                    <option value="1">Nota más alta</option>
                                    <option value="2">Última entrega</option>
                                    <option value="3">Promedio</option>
                                </Form.Control>
                            </Form.Group>
                            <Button variant="outline-dark" className="font-bold" type="submit">Guardar</Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        );
    }
}