import React, { useEffect, useState } from 'react'
//import DEFAULT_URL from '../../../api/url';
import { Container, Row, Form, Button, Col } from 'react-bootstrap';
const NuevaIntervencion = (props) => {
    const [contador] = useState(0);
    const [cambios, setCambios] = useState(0);
    const [intervenciones, setIntervencinoes] = useState([]);
    const [descripcion, setDescripcion] = useState('');
    const [tipo, setTipo] = useState('');
    const [validated, setValidated] = useState(false);
    const [cargando, setCargando] = useState(false);
    const [exito, setExito] = useState(false);
    const [error, setError] = useState(false);
    useEffect(() => {
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/leads/intervenciones', { cache: "no-cache", credentials: 'include' })
            .then((res) => {
                return res.json();
            })
            .then(data => {
                setIntervencinoes(data.rows);
            });
    }, [contador]);
    useEffect(() => {

    }, [cambios]);


    const onChange = (evt) => {
        switch (evt.target.name) {
            case "Descripcion":
                setDescripcion(evt.target.value);
                break;
            case "Tipo":
                setTipo(evt.target.value);
                break;
        }
    }
    const submit = (evt) => {
        const form = evt.currentTarget;
        if (form.checkValidity() === false) {
            evt.preventDefault();
            evt.stopPropagation();
        } else {
            evt.preventDefault();
            setCargando(true);
            setCambios(cambios + 1);
            const info = { descripcion: descripcion, tipo: tipo, lead_UniqueID: props.lead };
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(info)
            };
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/create/leads/intervencion', requestOptions)
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    if (data.codigo == 200) {
                        setExito(true);
                        setCambios(cambios + 1);
                    } else {
                        setError(true);
                        setCambios(cambios + 1);
                    }
                });
        }
        setValidated(true);
        setCambios(cambios + 1);
    }
    return (
        <Container fluid>
            <Row style={{ padding: '5%' }}>
                <h2>Ingresar intervención con lead</h2>
            </Row>
            <Row style={{ paddingTop: '2%', paddingBottom: '2%', paddingLeft: '5%', paddingRight: '5%', textAlign: 'left' }}>
                <Form onSubmit={submit} noValidate validated={validated}>
                    <Row>
                        <Form.Group className="mb-4" controlId="Descripcion">
                            <Form.Label>Descripción de intervención</Form.Label>
                            <Form.Control disabled={cargando || error} required as="textarea" placeholder="Ingrese la descripción de la intervención" rows={3} style={{ resize: 'none' }} name="Descripcion" onChange={onChange} value={descripcion} />
                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                            <Form.Control.Feedback type='invalid'>Por favor, completa este campo</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group className="mb-4" controlId="Tipo">
                            <Form.Label>Tipo de intervención</Form.Label>
                            <Form.Control value={tipo} as="select" disabled={cargando || error} required aria-label="Tipo" name="Tipo" onChange={onChange}>
                                <option value="" key="-1">Seleccione el tipo de intervención</option>
                                {intervenciones.map((intervencion) =>
                                    <option value={intervencion.UniqueID} key={intervencion.UniqueID}>{intervencion.descripcion}</option>
                                )}
                            </Form.Control>
                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                            <Form.Control.Feedback type='invalid'>Por favor, completa este campo</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row>
                        {(!error && !exito) && <Button variant="outline-secondary" className="font-bold" type="submit" disabled={cargando || error}>Guardar</Button>}
                        {(error) && <Button variant="outline-danger" className="font-bold" onClick={() => { setError(false); setCambios(cambios + 1); }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-octagon-fill" viewBox="0 0 16 16">
                            <path d="M11.46.146A.5.5 0 0 0 11.107 0H4.893a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353L11.46.146zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                        </svg>&nbsp;&nbsp;&nbsp;&nbsp;Intentar de nuevo</Button>}
                        {(exito) && <Button variant="outline-success" className="font-bold" onClick={() => {
                            setCargando(false); setExito(false); setDescripcion('');
                            setTipo(''); setCambios(cambios + 1);
                        }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
                                <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                            </svg>&nbsp;&nbsp;&nbsp;&nbsp;Registrar otra intervención</Button>}
                    </Row>
                </Form>
            </Row>
        </Container>

    )
}

export default NuevaIntervencion