import React, { useEffect, useState } from 'react';
//import DEFAULT_URL from '../../../../../api/url';
//import { SELF_URL } from '../../../../../api/url';
import { Container, Form, Row, Col, Button, Alert, ProgressBar } from "react-bootstrap";
import Participante from './Participante';
const CertificadosGenerados = (props) => {
    const [participantes, setParticipantes] = useState([]);
    const [contador, setContador] = useState(0);
    const [cambios, setCambios] = useState(0);
    const [filteredData, setFilteredData] = useState([]);
    const [filterData, setFilterData] = useState('');
    const [accionesSeleccionMultiple, setAccionesSeleccionMultiple] = useState(false);
    const [seleccionarTodos, setSeleccionarTodos] = useState(false);
    const [confirmacion, setConfirmacion] = useState('none');
    const [exito, setExito] = useState('none');
    const [error, setError] = useState('none');
    const [detalle, setDetalle] = useState('block');
    const [mensaje, setMensaje] = useState('');
    const [cargando, setCargando] = useState('none');
    const [status, setStatus] = useState(100);
    useEffect(() => {
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/detalle_inscripcion/diploma/evento/' + props.evento.UniqueID + '/' + props.cert, { cache: "no-cache" })
            .then((res) => {
                return res.json();
            })
            .then(data => {
                setAccionesSeleccionMultiple(true);
                setParticipantes([]);
                setFilteredData([]);
                let tmp = data.rows;
                tmp.map((actual, index) => {
                    actual.checked = false;
                    if (index === (tmp.length - 1)) {
                        setParticipantes(data.rows);
                        setFilteredData(data.rows);
                        return true;
                    }
                });
            });
    }, [contador]);
    useEffect(() => {
        multipleSelect();
    }, [cambios]);
    const goBackToDetail = () => {
        setExito('none');
        setError('none');
        setConfirmacion('none');
        setCargando('none');
        setDetalle('block');
        setCambios(cambios + 1);
    }
    const checkEvent = (evt) => {
        let tmp = filteredData;
        let tmp2 = participantes;
        const index_finder = (participante) => participante.UniqueID == evt.target.name;
        if (tmp.findIndex(index_finder) >= 0) {
            tmp[tmp.findIndex(index_finder)].checked = evt.target.checked;
            setFilteredData(tmp);
            tmp2[tmp2.findIndex(index_finder)].checked = evt.target.checked;
            setParticipantes(tmp2);
            setCambios(cambios + 1);
        }
    }
    const filterEvent = (evt) => {
        setFilterData(evt.target.value);
        setCambios(cambios + 1);
        let result = [];
        result = participantes.filter((actual) => {
            return actual.Nombres.search(evt.target.value) != -1 || actual.Apellidos.search(evt.target.value) != -1 || actual.Correo.search(evt.target.value) != -1;
        });
        setFilteredData(result);
        setSeleccionarTodos(false);
        setCambios(cambios + 1);
    }
    const multipleSelect = () => {
        let contador = 0;
        participantes.map((actual, index) => {
            if (actual.checked == true) {
                actual.display = 'block';
                contador++;
            } else {
                actual.display = 'none';
            }
            if (index === (participantes.length - 1)) {
                if (contador > 0) {
                    setAccionesSeleccionMultiple(false);
                } else {
                    setAccionesSeleccionMultiple(true);
                }
            }
        });
    }
    const checkAll = (evt) => {
        if (evt.target.checked === true) {
            const tmp = filteredData;
            tmp.map((actual, index) => {
                actual.checked = true;
                if (index === (tmp.length - 1)) {
                    setFilteredData(tmp);
                    setCambios(cambios + 1);
                }
            });
        } else {
            const tmp = filteredData;
            tmp.map((actual, index) => {
                actual.checked = false;
                if (index === (tmp.length - 1)) {
                    setFilteredData(tmp);
                    setCambios(cambios + 1);
                }
            });
        }
        setSeleccionarTodos(evt.target.checked);
    }
    const notificarSeleccionados = () => {
        props.cargar('Notificando...');
        let selected = [];
        participantes.map((actual, index) => {
            if (actual.checked === true) {
                selected.push(actual);
                if (index == (participantes.length - 1)) {
                    props.changeStatus(30);
                    const info = { cert: props.cert, participantes: selected };
                    const requestOptions = {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(info)
                    };
                    fetch(process.env.REACT_APP_DEFAULT_URL + '/api/diploma/notificar', requestOptions)
                        .then((res) => {
                            return res.json();
                        })
                        .then(datos => {
                            if (datos.codigo == 200) {
                                props.changeStatus((index / participantes.length) * 100);
                                setContador(contador + 1);
                                if (index == (participantes.length - 1)) {
                                    props.operacionExito('Notificaciones enviadas exitosamente');
                                    return true;
                                }
                            } else {
                                props.operacionError('Ocurrió un error al enviar las notificaciones');
                            }
                        });
                }
            } else {
                if (index == (participantes.length - 1)) {
                    props.changeStatus(30);
                    const info = { cert: props.cert, participantes: selected };
                    const requestOptions = {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(info)
                    };
                    fetch(process.env.REACT_APP_DEFAULT_URL + '/api/diploma/notificar', requestOptions)
                        .then((res) => {
                            return res.json();
                        })
                        .then(datos => {
                            if (datos.codigo == 200) {
                                props.changeStatus((index / participantes.length) * 100);
                                setContador(contador + 1);
                                if (index == (participantes.length - 1)) {
                                    props.operacionExito('Notificaciones enviadas exitosamente');
                                    setContador(contador + 1);
                                    return true;
                                }
                            } else {
                                setContador(contador + 1);
                                props.operacionError('Ocurrió un error al enviar las notificaciones');
                            }
                        });
                }
            }
        });
    }
    const cargar = () => {
        setExito('none');
        setError('none');
        setConfirmacion('block');
        setCargando('none');
        setDetalle('none');
        setCambios(cambios + 1);
    }
    const eliminarEntregas = () => {
        props.cargar('Eliminando certificados...');
        participantes.map((actual, index) => {
            if (actual.checked === true) {
                const info = { cert: props.cert, participante: actual.UniqueID };
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(info)
                };
                fetch(process.env.REACT_APP_DEFAULT_URL + '/api/certificado/del', requestOptions)
                    .then((res) => {
                        return res.json();
                    })
                    .then(datos => {
                        if (datos.codigo == 200) {
                            props.changeStatus((index / participantes.length) * 100);
                            setExito('none');
                            setError('none');
                            setConfirmacion('none');
                            setCargando('none');
                            setDetalle('block');
                            setCambios(cambios + 1);
                            setContador(contador + 1);
                            if (index == (participantes.length - 1)) {
                                props.operacionExito('Certificados eliminados exitosamente');
                                return true;
                            }
                        } else {
                            props.operacionError('Ocurrió un error al eliminar los certificados');
                        }
                    });
            } else {
                props.changeStatus((index / participantes.length) * 100);
                if (index == (participantes.length - 1)) {
                    props.operacionExito('Certificados eliminados exitosamente');
                    return true;
                }
            }
        });
    }
    const emailControlAcademico = () => {
        props.cargar('Notificando...');
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/email/informar/control-academico/diplomas-y-certificados/' + props.evento.UniqueID + '/' + props.cert)
            .then((res) => {
                return res.json();
            })
            .then(datos => {
                console.log(datos);
                if (datos.codigo == 200) {
                    props.changeStatus(100);
                    props.operacionExito('Notificación a control académico exitosa');
                } else {
                    props.operacionError('Ocurrió un error al notificar a control académico');
                }
            });
    }
    const downloadAll = () => {
        props.cargar('Generando y descargando...');
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/diplomas-y-certificados/download/' + props.evento.UniqueID + '/' + props.cert)
            .then((res) => {
                return res.json();
            })
            .then(datos => {
                console.log(datos);
                if (datos.codigo == 200) {
                    window.open(datos.url, "_blank");
                    props.changeStatus(100);
                    props.operacionExito('Operación realizada con exito');
                } else {
                    props.operacionError('Ocurrió un error al generar los diplomas o certificados');
                }
            });
    }
    return (
        <Container style={{ marginTop: '45px' }}>
            <Row>
                {props.tipo == 1 && <h1>Diplomas generados</h1>}
                {props.tipo == 2 && <h1>Certificados generados</h1>}
            </Row>
            <Row style={{ marginTop: '25px', marginBottom: '25px', display: detalle }}>
                <Button variant="outline-success" onClick={emailControlAcademico}>Notificar a control académico</Button>
            </Row>
            {/*props.tipo == 1 && <Row style={{ marginTop: '25px', marginBottom: '25px', display: detalle }}>
                <Button variant="outline-success" onClick={downloadAll}>Descargar todos los diplomas generados</Button>
            </Row>*/}
            {/*props.tipo == 2 && <Row style={{ marginTop: '25px', marginBottom: '25px', display: detalle }}>
                <Button variant="outline-success" onClick={downloadAll}>Descargar todos los certificados generados</Button>
            </Row>*/}
            <Row style={{ marginTop: '25px', marginBottom: '25px', display: detalle }}>
                <Button variant="outline-dark" onClick={() => { setContador(contador + 1); }}>Refrescar</Button>
            </Row>
            <Row style={{ display: detalle }}>
                <Form.Group className="mb-3">
                    <Form.Label>Buscar</Form.Label>
                    <Form.Control value={filterData} onChange={filterEvent} placeholder="Puedes buscar por nombres, apellidos o correo" />
                </Form.Group>
            </Row>
            <Row style={{ marginTop: '25px', marginBottom: '25px', display: detalle }}>
                <Button variant="success" disabled={accionesSeleccionMultiple} onClick={notificarSeleccionados}>Enviar detalle a seleccionados</Button>
            </Row>
            <Row style={{ marginTop: '25px', marginBottom: '25px', display: detalle }}>
                <Button variant="outline-danger" disabled={accionesSeleccionMultiple} onClick={cargar}>Eliminar seleccionados</Button>
            </Row>
            <Row style={{ display: detalle }}>
                <Form.Group className="mb-3" controlId='seleccionar-todo-certs-entregados'>
                    <Form.Check onChange={checkAll} name='seleccionar-todo' checked={seleccionarTodos} type="checkbox" label='Seleccionar todo' />
                </Form.Group>
            </Row>
            <Row style={{ overflowY: 'scroll', maxHeight: '350px', display: detalle }}>
                <Container fluid>
                    {filteredData.length > 0 && filteredData.map((actual) => <Participante checkEvent={checkEvent} diploma={props.diploma} participante={actual} tipo={2} enlace={actual.diploma} />)}
                </Container>
            </Row>
            <Row style={{ display: cargando }}>
                <Alert variant='dark'>
                    <Alert.Heading>Procesando operación</Alert.Heading>
                    <p>
                        Esto puede demorar unos minutos
                    </p>
                    <hr />
                    <p className="mb-0">
                        Por favor espere...<br /><br />
                        {mensaje}
                        <br /><br />
                        <ProgressBar animated now={status} variant='dark' />
                    </p>
                </Alert>
            </Row>
            <Row style={{ display: exito }}>
                <Alert variant='success'>
                    <Alert.Heading>Operación procesada con éxito</Alert.Heading>
                    <p>
                        {mensaje}
                    </p>
                    <Row>
                        <Col xs={8} md={10} lg={10}>
                        </Col>
                        <Col xs={4} md={2} lg={2}>
                            <Row>
                                <Button variant='outline-success' onClick={goBackToDetail}>Ok</Button>
                            </Row>
                        </Col>
                    </Row>
                </Alert>
            </Row>
            <Row style={{ display: error }}>
                <Alert variant='danger'>
                    <Alert.Heading>{mensaje}</Alert.Heading>
                    <Row>
                        <Col xs={8} md={10} lg={10}>
                        </Col>
                        <Col xs={4} md={2} lg={2}>
                            <Row>
                                <Button variant='outline-danger' onClick={goBackToDetail}>Ok</Button>
                            </Row>
                        </Col>
                    </Row>
                </Alert>
            </Row>
            <Row style={{ display: confirmacion }}>
                <Alert variant='warning'>
                    <Alert.Heading>Se requiere confirmación del usuario</Alert.Heading>
                    {props.tipo == 1 && <p>¿Está segur@ de eliminar los diplomas generados para los participantes seleccionados?</p>}
                    {props.tipo == 2 && <p>¿Está segur@ de eliminar los certificados generados para los participantes seleccionados?</p>}
                    <h5 style={{ textAlign: 'left' }}>Participantes seleccionados:</h5>
                    <ul>
                        {participantes.map((actual) => <li style={{ display: actual.display, textAlign: 'left' }}>{actual.correlativo != null && <p>{actual.Nombres + ' ' + actual.Apellidos + ' (' + actual.correlativo + ')'}</p>}{actual.correlativo == null && <p>{actual.Nombres + ' ' + actual.Apellidos}</p>}</li>)}
                    </ul>
                    <Row>
                        <Col xs={4} md={8} lg={8}>
                        </Col>
                        <Col xs={4} md={2} lg={2}>
                            <Row>
                                <Button variant='outline-danger' onClick={eliminarEntregas}>Sí</Button>
                            </Row>
                        </Col>
                        <Col xs={4} md={2} lg={2}>
                            <Row>
                                <Button variant='outline-warning' onClick={goBackToDetail}>No</Button>
                            </Row>
                        </Col>
                    </Row>
                </Alert>
            </Row>
        </Container>
    )
}

export default CertificadosGenerados