import React from 'react';
import { Row, Col, Container, Accordion } from "react-bootstrap";
import './Politicas.css'

const Politicas = () => {
    return (
        <div className="contenedor-politicas">
            <Container>
                <Row style={{ width: '100%', height: '100%' }}>
                    <Col xs={12} md={6} lg={6}>
                        <div className="columna-politicas " style={{ padding: '2%', textAlign: 'center' }}>
                            <h1 className="font-bold blanco">ASOCIACIÓN BANCARIA DE GUATEMALA</h1>
                            <h2 className="font-bold blanco" style={{ marginTop: '5%', marginBottom: '5%' }}>POLÍTICAS DE PRIVACIDAD</h2>
                            <Accordion defaultActiveKey="0" className="bg-success" style={{ marginTop: '5%' }}>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header className="font-bold">GENERAL</Accordion.Header>
                                    <Accordion.Body className="font-normal">
                                        <p className="parrafo">
                                            La presente política de privacidad establece los términos en que la Asociación Bancaria de Guatemala <strong>(ABG)</strong>, por medio de sus marcas educativas <strong>(EBG-EBC-UC)</strong>, usa y protege la información que es proporcionada por sus usuarios al momento de tener acceso a sus portales web y aplicaciones móviles. La Asociación Bancaria de Guatemala <strong>(ABG-EBG-EBC-UC)</strong> está comprometida con la seguridad de los datos de sus usuarios. Cuando le pedimos llenar los campos de información personal con la cual usted pueda ser identificado, lo hacemos asegurando que solo se emplea de acuerdo a los términos de este documento; sin embargo, esta política de privacidad puede cambiar con el tiempo o ser actualizada, por lo que le recomendamos y enfatizamos revisar continuamente esta página para asegurarse que está de acuerdo con dichos cambios.                                        </p>
                                        <img className='imagen-complementaria' src="https://ik.imagekit.io/p5ethkcnzwh/GENERAL_3HOR_P7f8.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1651784086214" />
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header className="font-bold">INFORMACIÓN QUE ES RECOLECTADA</Accordion.Header>
                                    <Accordion.Body className="font-normal">
                                        <p className="parrafo">
                                            Nuestros portales web y aplicaciones móviles podrán recoger información personal, por ejemplo: información de contacto como su dirección de correo electrónico, nombre, teléfono e información demográfica. Asimismo, cuando sea necesario, podrá ser requerida información específica para realizar una facturación.                                        </p>
                                        <img className='imagen-complementaria' src="https://ik.imagekit.io/p5ethkcnzwh/RECOLECCION-DATOS_0c45tz7iPS.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1651784086068" />
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header className="font-bold">USO DE LA INFORMACIÓN RECOLECTADA</Accordion.Header>
                                    <Accordion.Body className="font-normal">
                                        <p className="parrafo">
                                            Nuestro sitio web emplea la información con el fin de mantener un registro de usuarios y mejorar nuestros servicios.  Es posible que sean enviados correos electrónicos periódicamente por medio de nuestro sitio web, con ofertas especiales y otra información publicitaria que consideremos relevante para usted o que pueda brindar algún beneficio.  Estos correos electrónicos serán enviados a la dirección que usted proporcione y podrán ser cancelados en cualquier momento.
                                            <br /><br />
                                            La Asociación Bancaria de Guatemala <strong>(ABG-EBG-EBC-UC)</strong> está altamente comprometida para cumplir con la obligación de mantener su información segura. Usamos los sistemas más avanzados y los actualizamos constantemente para asegurarnos que no exista algún acceso no autorizado.
                                        </p>
                                        <img className='imagen-complementaria' src="https://ik.imagekit.io/p5ethkcnzwh/USO-INFO_6TTsIdpFk.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1651784086011" />
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header className="font-bold">COOKIES</Accordion.Header>
                                    <Accordion.Body className="font-normal">
                                        <p className="parrafo">
                                            Una cookie se refiere a un fichero que es enviado con la finalidad de solicitar permiso para almacenarse en su ordenador; al aceptar dicho fichero se crea y la cookie sirve entonces para tener información respecto al tráfico web.  También facilita las futuras visitas a una web recurrente. Otra función que tienen las cookies es que con ellas las webs pueden reconocerse individualmente y, por tanto, brindarle el mejor servicio personalizado de su web.
                                            <br /><br />
                                            Nuestro sitio web emplea las cookies para poder identificar las páginas que son visitadas y su frecuencia. Esta información es empleada únicamente para análisis estadístico y después la información se elimina de forma permanente. Usted puede eliminar las cookies en cualquier momento desde su ordenador; sin embargo, las cookies ayudan a proporcionar un mejor servicio de los sitios web.  Estas no dan acceso a información de su ordenador ni de usted, a menos de que usted así lo quiera y la proporcione directamente. Usted puede aceptar o negar el uso de cookies, no obstante lo cual, la mayoría de navegadores aceptan cookies automáticamente, pues ello sirve para tener un mejor servicio web. También usted puede cambiar la configuración de su ordenador para declinar las cookies. Si se declinan es posible que no pueda utilizar algunos de nuestros servicios.
                                        </p>
                                        <img className='imagen-complementaria' src="https://ik.imagekit.io/p5ethkcnzwh/COOKIES_IuA1NpN0N8.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1651784086180" />
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header className="font-bold">ENLACES A TERCEROS</Accordion.Header>
                                    <Accordion.Body className="font-normal">
                                        <p className="parrafo">
                                            Este sitio web pudiera contener enlaces a otros sitios que pudieran ser de su interés. Una vez usted dé clic en estos enlaces y abandone nuestra página, ya no tenemos control sobre el sitio al que es redirigido y, por lo tanto, no somos responsables de los términos o privacidad ni de la protección de sus datos en esos sitios terceros. Dichos sitios están sujetos a sus propias políticas de privacidad, por lo cual, es recomendable que los consulte para confirmar que usted está de acuerdo con las mismas.
                                        </p>
                                        <img className='imagen-complementaria' src="https://ik.imagekit.io/p5ethkcnzwh/ENLACES-TERCEROS_lLJ89esG5O.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1651784085914" />
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header className="font-bold">CONTROL DE SU INFORMACIÓN PERSONAL</Accordion.Header>
                                    <Accordion.Body className="font-normal">
                                        <p className="parrafo">
                                            En cualquier momento usted puede restringir la recopilación o el uso de la información personal que es proporcionada a nuestro sitio web.  Cada vez que se le solicite rellenar un formulario, como el de alta de usuario, puede marcar o desmarcar la opción de recibir información por correo electrónico.  En caso haya marcado la opción de recibir nuestro boletín o publicidad, usted puede cancelarla en cualquier momento.
                                            <br /><br />

                                            Esta entidad no venderá, cederá ni distribuirá, sin su consentimiento, la información personal que es recopilada, salvo que sea requerido mediando para el efecto una orden judicial.
                                            <br /><br />
                                            La Asociación Bancaria de Guatemala <strong>(ABG-EBG-EBC-UC)</strong> se reserva el derecho de cambiar los términos de la presente política de privacidad, en cualquier momento.
                                        </p>
                                        <img className='imagen-complementaria' src="https://ik.imagekit.io/p5ethkcnzwh/CONTROL-INFO_WD17T0QBM.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1651784086019" />
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </Col>
                    <Col xs={12} md={6} lg={6}></Col>
                </Row>
            </Container>
        </div>
    );
}


export default Politicas;