import React, { useEffect, useState } from 'react';
import { Modal, Button, Container } from "react-bootstrap";
import {
    ComposedChart,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Line
} from 'recharts';


const ModalPatrocinios = (props) => {
    const [contador, setContador] = useState(0);
    const [info, setInfo] = useState([]);
    useEffect(() => {

    }, [contador, props.data, props.ventas]);

    const enter = () => {
        if (props.data.length > 0) {
            let temp = [];
            setInfo(props.data);
            setContador(contador + 1);
        }
    }

    return (
        <Modal
            {...props}
            size="fullscreen"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
            onEnter={enter}
        >
            <Modal.Header closeButton style={{ backgroundColor: "#E0E0E2" }}>
                <Modal.Title id="contained-modal-title-vcenter" className="font-bold">
                    Patrocinios
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: "#E0E0E2", height: '60vh' }} id="body-modal">
                <Container>
                    <ResponsiveContainer width="100%" height="100%">
                        <ComposedChart
                            width={500}
                            height={400}
                            data={info}
                            margin={{
                                top: 20,
                                right: 20,
                                bottom: 20,
                                left: 20,
                            }}
                        >
                            <CartesianGrid stroke="#f5f5f5" />
                            <XAxis dataKey="asesor" scale="band" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="Ingresos por patrocinio" stroke="#ff7300" />
                        </ComposedChart>
                    </ResponsiveContainer>
                </Container>
            </Modal.Body>
            <Modal.Footer style={{ backgroundColor: "#E0E0E2" }}>
                <Button variant="secondary" className="font-bold" onClick={props.onHide}>Cerrar</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalPatrocinios;
