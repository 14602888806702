import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";
import './SuccessModal.css';

export class SuccessModal extends Component {
    render() {
        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
            >
                <Modal.Header closeButton style={{ backgroundColor: this.props.color }}>
                    <Modal.Title id="contained-modal-title-vcenter" className="font-bold">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16" style={{ marginRight: '25px' }}>
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                        </svg>
                        Operación Completada Con Éxito
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: this.props.color }} className="cuerpo-modal-succs">
                    <p>{this.props.mensaje}</p>
                    <p>{this.props.consideracion}{this.props.llevaenlace && <a href={this.props.enlace} target="_blank" rel="noreferrer">aquí</a>}</p>
                </Modal.Body>
                <Modal.Footer style={{ backgroundColor: this.props.color }}>
                    <Button variant="dark" className="font-bold" onClick={this.props.onHide}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}