import React from "react";
import { Modal, Button } from "react-bootstrap";
const AskModal = (props) => {
  return (
    <Modal show={props.show} centered backdrop="static" onClose={props.onClose}>
      <Modal.Header>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{props.text}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={props.onClose}
          className="custom-button"
          style={{
            backgroundColor: "#7f98c2",
            color: "white",
            border: "0.5px solid #7f98c2",
          }}
        >
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AskModal;
