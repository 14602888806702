import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
//import DEFAULT_URL from '../../../../../../api/url';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import CountdownTimer from './CountdownTimer';
const ControlTiempo = () => {
    const { UniqueID } = useParams();
    const [contador] = useState(0);
    const [cambios, setCambios] = useState(0);
    const [agenda, setAgenda] = useState({});
    const [tiempo, setTiempo] = useState(0);
    const [minutos, setMinutos] = useState(0);
    useEffect(() => {
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/agenda-jornada/view/' + UniqueID)
            .then((res) => {
                return res.json()
            })
            .then(data => {
                setAgenda(data.rows[0]);
            });
    }, [contador]);
    useEffect(() => {
    }, [cambios]);
    const onChange = (evt) => {
        setMinutos(evt.target.value);
        setCambios(cambios + 1);
    }
    const onSubmit = (evt) => {
        evt.preventDefault();
        const NOW_IN_MS = new Date().getTime();
        const MINUTES_IN_MS = minutos * 60 * 1000;
        setTiempo(NOW_IN_MS + MINUTES_IN_MS);
        setMinutos(0);
        setCambios(cambios + 1);
    }
    return (
        <Container fluid style={{ backgroundColor: 'rgb(230, 230, 230)' }}>
            <Row style={{ padding: '2%', borderBottom: '5px solid #1e3d52', textAlign: 'left' }}>
                <Col xs={12} md={6} lg={3}>
                    <img src={agenda.imgEvento} style={{ width: '100%', objectFit: 'cover' }} />
                </Col>
                <Col xs={12} md={6} lg={6}>
                    <h1>{agenda.evento}</h1>
                    <p>
                        <strong>ESPACIO DE AGENDA: {agenda.concepto}</strong><br />
                        {agenda.nombre}<br />
                        Hora de inicio: <strong>{agenda.hora_inicio}</strong><br />
                        Hora de finalización: <strong>{agenda.hora_fin}</strong><br />
                        Total en espacio: <strong>{agenda.minutos}&nbsp;minutos</strong>
                    </p>
                </Col>
                <Col xs={12} md={6} lg={3}>
                    <img src={agenda.foto} style={{ width: '100%', objectFit: 'cover' }} />
                </Col>
            </Row>
            <Row style={{ padding: '2%' }}>
                <h1>CONTEO REGRESIVO PARA FINALIZAR</h1>
                <CountdownTimer targetDate={tiempo} />
            </Row>
            <Row style={{ padding: '2%' }}>
                <Container>
                    <Form onSubmit={onSubmit}>
                        <Row>
                            <Col xs={12} md={12} lg={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Tiempo en minutos</Form.Label>
                                    <Form.Control required type="number" step='0' value={minutos} name="minutos" placeholder="Tiempo en minutos" onChange={onChange} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={12} lg={6}>
                                <Row style={{ padding: '2%' }}>
                                    <Button type='submit' variant='outline-primary' disabled={tiempo != 0}>Empezar cuenta regresiva</Button>
                                </Row>
                                <Row style={{ padding: '2%' }}>
                                    <Button onClick={() => { setTiempo(0); setCambios(cambios + 1); }} variant='outline-danger' disabled={tiempo == 0}>Detener cuenta regresiva</Button>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </Container >
            </Row >
        </Container >

    )
}

export default ControlTiempo