import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./common.css";
import EditarRespuesta from "./EditarRespuesta";
import { ErrorModal } from "../../../../Utils/ErrorModal/ErrorModal";
import { SuccessModal } from "../../../../Utils/SuccessModal/SuccessModal";
import { Confirmacion } from "../../../../Utils/ConfirmationModal/Confirmacion";
import VerRespuestas from "./VerRespuestas";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import {
  Row,
  Table,
  Col,
  Container,
  Toast,
  Button,
  ToastContainer,
} from "react-bootstrap";
import { PieChart, Pie } from "recharts";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
const RespuestasFormulario = () => {
  const { correlativo } = useParams();
  const [contador, setContador] = useState(0);
  const [formulario, setFormulario] = useState({});
  const [preguntas, setPreguntas] = useState([]);
  const [salida, setSalida] = useState([]);
  const [usuario, setUsuario] = useState({});
  const [editarRespuesta, setEditarRespuesta] = useState(false);
  const [respuestaActual, setRespuestaActual] = useState({});
  const [modalSucc, setModalSucc] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [consideracion, setConsideracion] = useState("");
  const [mensajeConfirmacion, setMensajeConfirmacion] = useState(false);
  const [actual, setActual] = useState({});
  const [verRespuesta, setVerRespuesta] = useState(false);
  const [showPDFConf, setShowPDFConf] = useState(false);
  const [urlPDF, setUrlPDF] = useState("");
  const [stadistics, setStadistics] = useState(null);
  useEffect(() => {
    async function fetchData() {
      fetch(process.env.REACT_APP_DEFAULT_URL + "/api/auth/userinfo", {
        cache: "no-cache",
        credentials: "include",
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setUsuario(data);
        });
      fetch(
        process.env.REACT_APP_DEFAULT_URL +
          "/api/read/formulario/" +
          correlativo,
        { cache: "no-cache" }
      )
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data.preguntas.length == 0) {
            window.location.replace("/unauthorized");
          } else {
            setFormulario(data.formulario);
            setPreguntas(data.preguntas);
            fetch(
              process.env.REACT_APP_DEFAULT_URL +
                "/api/read/formulario/report/" +
                data.formulario.UniqueID,
              { cache: "no-cache" }
            )
              .then((res) => {
                return res.json();
              })
              .then((data) => {
                setSalida(data.salida);
              });
            if (data.formulario.tipo == 5) {
              fetch(
                process.env.REACT_APP_DEFAULT_URL +
                  "/api/formulario/export/RAW/" +
                  data.formulario.UniqueID,
                { cache: "no-cache" }
              )
                .then((res) => {
                  return res.json();
                })
                .then((data) => {
                  setStadistics(data);
                });
            }
          }
        });
    }
    fetchData();
  }, [contador]);
  const ocultar = () => {
    setModalSucc(false);
    setModalError(false);
    setContador(contador + 1);
  };
  const accionEliminar = (id, param) => {
    setMensajeConfirmacion(false);
    const info = { respuesta: id };
    console.log(info);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(info),
    };
    fetch(
      process.env.REACT_APP_DEFAULT_URL + "/api/submit/formulario/delete",
      requestOptions
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.codigo == 200) {
          setMensaje("Respuestas eliminadas exitosamente");
          setModalSucc(true);
        } else {
          setMensaje(data.respuesta);
          setModalError(true);
        }
      });
  };

  const exportExcel = () => {
    var excel_contenido = [];
    for (let i = 0; i < salida.length; i++) {
      let actual = {};
      actual["FECHA"] = salida[i].fecha;
      for (let j = 0; j < preguntas.length; j++) {
        actual[preguntas[j].pregunta] = salida[i].respuestas[j].texto;
      }
      excel_contenido.push(actual);
    }
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(excel_contenido);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, Date.now() + fileExtension);
  };
  const exportExcelAsesor = () => {
    var excel_contenido = [];
    for (let i = 0; i < salida.length; i++) {
      let actual = {};
      actual["FECHA"] = salida[i].fecha;
      for (let j = 0; j < preguntas.length; j++) {
        actual[preguntas[j].pregunta] = salida[i].respuestas[j].texto;
      }
      excel_contenido.push(actual);
    }
    var asesores = [];
    var Sheets = [];
    var SheetNames = [];
    var asesores_tabla = [];
    const sw = XLSX.utils.json_to_sheet(excel_contenido);
    Sheets["Datos"] = sw;
    SheetNames.push("Datos");
    for (let i = 0; i < salida.length; i++) {
      if (
        asesores.length == 0 ||
        !asesores.includes(salida[i].respuestas[7].texto)
      ) {
        asesores.push(salida[i].respuestas[7].texto);
      }
    }
    for (let i = 0; i < asesores.length; i++) {
      let arr_respuestas = salida.filter(
        (actual) => actual.respuestas[7].texto == asesores[i]
      );
      let responses = [];
      for (let j = 0; j < arr_respuestas.length; j++) {
        let actual = {};
        actual["FECHA"] = arr_respuestas[j].fecha;
        for (let k = 0; k < preguntas.length; k++) {
          if (k == 6) {
            actual[preguntas[k].pregunta] =
              arr_respuestas[j].respuestas[k].texto.toUpperCase();
          } else {
            actual[preguntas[k].pregunta] =
              arr_respuestas[j].respuestas[k].texto;
          }
        }
        responses.push(actual);
      }
      responses.sort(function (a, b) {
        return a[preguntas[6].pregunta].localeCompare(b[preguntas[6].pregunta]);
      });
      const ws = XLSX.utils.json_to_sheet(responses);
      Sheets[asesores[i] + "(" + responses.length + ")"] = ws;
      SheetNames.push(asesores[i] + "(" + responses.length + ")");
      asesores_tabla.push({
        Asesor: asesores[i],
        Respuestas: responses.length,
      });
    }
    asesores_tabla.sort(function (a, b) {
      return b.Respuestas - a.Respuestas;
    });
    asesores_tabla.push({ Asesor: "TOTAL", Respuestas: salida.length });
    const ws = XLSX.utils.json_to_sheet(asesores_tabla);
    Sheets["RESUMEN"] = ws;
    SheetNames.push("RESUMEN");
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const wb = { Sheets, SheetNames };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, Date.now() + fileExtension);
  };
  const exportExcelPais = () => {
    var excel_contenido = [];
    for (let i = 0; i < salida.length; i++) {
      let actual = {};
      actual["FECHA"] = salida[i].fecha;
      for (let j = 0; j < preguntas.length; j++) {
        actual[preguntas[j].pregunta] = salida[i].respuestas[j].texto;
      }
      excel_contenido.push(actual);
    }
    var paises = [];
    var Sheets = [];
    var SheetNames = [];
    var paises_tabla = [];
    const sw = XLSX.utils.json_to_sheet(excel_contenido);
    Sheets["Datos"] = sw;
    SheetNames.push("Datos");
    for (let i = 0; i < salida.length; i++) {
      if (
        paises.length == 0 ||
        !paises.includes(salida[i].respuestas[6].texto.trim().toUpperCase())
      ) {
        paises.push(salida[i].respuestas[6].texto.trim().toUpperCase());
      }
    }
    for (let i = 0; i < paises.length; i++) {
      let arr_respuestas = salida.filter(
        (actual) => actual.respuestas[6].texto.trim().toUpperCase() == paises[i]
      );
      let responses = [];

      for (let j = 0; j < arr_respuestas.length; j++) {
        let actual = {};
        actual["FECHA"] = arr_respuestas[j].fecha;
        for (let k = 0; k < preguntas.length; k++) {
          if (k == 6) {
            actual[preguntas[k].pregunta] =
              arr_respuestas[j].respuestas[k].texto.toUpperCase();
          } else {
            actual[preguntas[k].pregunta] =
              arr_respuestas[j].respuestas[k].texto;
          }
        }
        responses.push(actual);
      }
      responses.sort(function (a, b) {
        return a[preguntas[7].pregunta].localeCompare(b[preguntas[7].pregunta]);
      });
      const ws = XLSX.utils.json_to_sheet(responses);
      Sheets[paises[i] + "(" + responses.length + ")"] = ws;
      SheetNames.push(paises[i] + "(" + responses.length + ")");
      paises_tabla.push({ Pais: paises[i], Respuestas: responses.length });
    }
    paises_tabla.sort(function (a, b) {
      return b.Respuestas - a.Respuestas;
    });
    paises_tabla.push({ Pais: "TOTAL", Respuestas: salida.length });
    const ws = XLSX.utils.json_to_sheet(paises_tabla);
    Sheets["RESUMEN"] = ws;
    SheetNames.push("RESUMEN");
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const wb = { Sheets, SheetNames };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, Date.now() + fileExtension);
  };

  const exportPDF = () => {
    fetch(
      process.env.REACT_APP_DEFAULT_URL +
        "/api/formulario/export/PDF/" +
        formulario.UniqueID
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setShowPDFConf(true);
        setUrlPDF(data.enlace);
        setContador(contador + 1);
      });
  };
  return (
    <Container fluid>
      <Row>
        <ToastContainer position="top-end" className="p-3">
          <Toast
            bg="secondary"
            onClose={() => setShowPDFConf(false)}
            show={showPDFConf}
            delay={25000}
            autohide
          >
            <Toast.Header>
              <strong className="me-auto">Operación realizada con éxito</strong>
            </Toast.Header>
            <Toast.Body>
              <h5 style={{ color: "white" }}>
                Haz click en el enlace para acceder al PDF
              </h5>
              <Button variant="outline-light" href={urlPDF} target="_blank">
                Ir a reporte
              </Button>
            </Toast.Body>
          </Toast>
        </ToastContainer>
      </Row>
      <Row style={{ padding: "5%" }}>
        <Container>
          <Row
            style={{
              borderRadius: "25px",
              display: "flex",
              placeItems: "center",
            }}
          >
            <Col sm={12} md={12} lg={6}>
              <img
                width={"100%"}
                src="https://ik.imagekit.io/p5ethkcnzwh/logotipos_J3ALOjtXZ.png?ik-sdk-version=javascript-1.4.3&updatedAt=1652370601113"
              />
            </Col>
            <Col sm={12} md={12} lg={6}>
              <h2 style={{ color: "black", padding: "3%" }}>
                Respuestas formulario:
                <br /> <strong>{formulario.titulo}</strong>
              </h2>
            </Col>
          </Row>
          <Row style={{ marginTop: "25px", marginBottom: "25px" }}>
            <Col xs={1} md={1} lg={1}>
              <div
                style={{ color: "black", cursor: "pointer" }}
                data-toggle="tooltip"
                data-placement="top"
                title="Refrescar"
                onClick={() => setContador(contador + 1)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  fill="currentColor"
                  className="bi bi-arrow-clockwise"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
                  />
                  <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                </svg>
              </div>
            </Col>
            <Col xs={1} md={1} lg={1}>
              <div
                style={{ color: "black", cursor: "pointer" }}
                data-toggle="tooltip"
                data-placement="top"
                title="Exportar a excel"
                onClick={exportExcel}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  fill="currentColor"
                  className="bi bi-file-earmark-spreadsheet-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M6 12v-2h3v2H6z" />
                  <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM3 9h10v1h-3v2h3v1h-3v2H9v-2H6v2H5v-2H3v-1h2v-2H3V9z" />
                </svg>
              </div>
            </Col>
            {formulario.tipo == 5 && stadistics != null && (
              <Col xs={1} md={1} lg={1}>
                <div
                  style={{ color: "black", cursor: "pointer" }}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Exportar a PDF"
                  onClick={exportPDF}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    className="bi bi-file-pdf-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5.523 10.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.035 21.035 0 0 0 .5-1.05 11.96 11.96 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.888 3.888 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 4.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z" />
                    <path
                      fillRule="evenodd"
                      d="M4 0h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm.165 11.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.64 11.64 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.707 19.707 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z"
                    />
                  </svg>
                </div>
              </Col>
            )}
            {(formulario.tipo == 1 || formulario.tipo == 4) && (
              <Col xs={1} md={1} lg={1}>
                <div
                  style={{ color: "black", cursor: "pointer" }}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Exportar a excel (por asesor)"
                  onClick={exportExcelAsesor}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    className="bi bi-person-circle"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                    <path
                      fillRule="evenodd"
                      d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                    />
                  </svg>
                </div>
              </Col>
            )}
            {(formulario.tipo == 1 || formulario.tipo == 4) && (
              <Col xs={1} md={1} lg={1}>
                <div
                  style={{ color: "black", cursor: "pointer" }}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Exportar a excel (por país)"
                  onClick={exportExcelPais}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    className="bi bi-flag-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001" />
                  </svg>
                </div>
              </Col>
            )}
          </Row>
          <Row className="row-tabla-respuestas">
            <Col sm={12} md={12} lg={12}>
              <Table
                striped
                hover
                variant="light"
                style={{
                  width: (450 * (2 + preguntas.length)).toString() + "px",
                  borderCollapse: "collapse",
                }}
                id="tabla-respuestas"
              >
                <thead>
                  <tr>
                    <th style={{ position: "sticky", top: "0" }}>#</th>
                    <th style={{ position: "sticky", top: "0" }}>
                      Fecha y hora
                    </th>
                    {preguntas.map((pregunta) => (
                      <th
                        style={{ position: "sticky", top: "0" }}
                        key={pregunta.UniqueID}
                      >
                        {pregunta.pregunta}
                      </th>
                    ))}
                    <th style={{ position: "sticky", top: "0" }}>acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {salida != null &&
                    salida.map((actual, index) => (
                      <tr key={"respuesta-tabla-" + index + "-" + Date.now}>
                        <td>{index + 1}</td>
                        <td>{actual.fecha}</td>
                        {actual.respuestas.map((respuesta, indicador) => (
                          <td
                            key={"respuesta-cell-" + indicador + "-" + Date.now}
                          >
                            {respuesta.texto}
                          </td>
                        ))}
                        <td>
                          <div
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Ver"
                            style={{
                              cursor: "pointer",
                              color: "black",
                              flex: "1 1 auto",
                            }}
                            onClick={() => {
                              setVerRespuesta(true);
                              setRespuestaActual(actual);
                              setContador(contador + 1);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="25"
                              fill="currentColor"
                              className="bi bi-eye-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                              <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                            </svg>
                          </div>
                          {usuario.tipo == 4 && (
                            <div
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Editar"
                              style={{
                                cursor: "pointer",
                                color: "black",
                                flex: "1 1 auto",
                              }}
                              onClick={() => {
                                setEditarRespuesta(true);
                                setRespuestaActual(actual);
                                setContador(contador + 1);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                fill="currentColor"
                                className="bi bi-pencil-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                              </svg>
                            </div>
                          )}
                          {usuario.tipo == 4 && (
                            <div
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Eliminar"
                              style={{
                                cursor: "pointer",
                                color: "black",
                                flex: "1 1 auto",
                              }}
                              onClick={() => {
                                var confirmatinomessage =
                                  "¿Está segur@ que desea elimnar la respuesta? ";
                                setConsideracion(
                                  "Esta acción no se puede revertir"
                                );
                                setMensaje(confirmatinomessage);
                                setActual(actual);
                                setMensajeConfirmacion(true);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                fill="currentColor"
                                className="bi bi-trash-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                              </svg>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Col>
          </Row>
          {stadistics != null && (
            <Row>
              <Container fluid>
                <Row style={{ marginTop: "25px" }}>
                  <h1>RESUMEN DE RESPUESTAS</h1>
                </Row>
                {stadistics.info.map((curr, index) => (
                  <Row
                    style={{
                      marginTop: "35px",
                      display: "grid",
                      placeItems: "center",
                    }}
                    key={"panel-respuesta-" + Date.now + "-" + index}
                  >
                    <h3>{curr.pregunta}</h3>
                    {curr.tipo == 5 && (
                      <PieChart width={400} height={400}>
                        <Pie
                          dataKey="value"
                          isAnimationActive={false}
                          data={curr.datos}
                          cx="50%"
                          cy="50%"
                          outerRadius={80}
                          fill="#1e3d52"
                          label
                        />
                        <Tooltip />
                      </PieChart>
                    )}
                    {/*(index == 1) &&
                                    <PieChart width={400} height={400}>
                                        <Pie
                                            dataKey="value"
                                            isAnimationActive={false}
                                            data={curr.datos}
                                            cx="50%"
                                            cy="50%"
                                            outerRadius={80}
                                            fill="#006479"
                                            label
                                        />
                                        <Tooltip />
                                    </PieChart>*/}
                    {/*(index == 2) &&
                                    <PieChart width={400} height={400}>
                                        <Pie
                                            dataKey="value"
                                            isAnimationActive={false}
                                            data={curr.datos}
                                            cx="50%"
                                            cy="50%"
                                            outerRadius={80}
                                            fill="#008E8D"
                                            label
                                        />
                                        <Tooltip />
                                    </PieChart>*/}
                    {/*(index == 3) &&
                                    <PieChart width={400} height={400}>
                                        <Pie
                                            dataKey="value"
                                            isAnimationActive={false}
                                            data={curr.datos}
                                            cx="50%"
                                            cy="50%"
                                            outerRadius={80}
                                            fill="#39B78C"
                                            label
                                        />
                                        <Tooltip />
                                    </PieChart>*/}
                    {/*(index == 4) &&
                                    <PieChart width={400} height={400}>
                                        <Pie
                                            dataKey="value"
                                            isAnimationActive={false}
                                            data={curr.datos}
                                            cx="50%"
                                            cy="50%"
                                            outerRadius={80}
                                            fill="#9DADBC"
                                            label
                                        />
                                        <Tooltip />
                                    </PieChart>*/}
                    {/*(index == 6) &&
                                    <PieChart width={400} height={400}>
                                        <Pie
                                            dataKey="value"
                                            isAnimationActive={false}
                                            data={curr.datos}
                                            cx="50%"
                                            cy="50%"
                                            outerRadius={80}
                                            fill="#4F6C83"
                                            label
                                        />
                                        <Tooltip />
                                    </PieChart>*/}
                    {/*index == 5 && <BarChart
                                    width={500}
                                    height={300}
                                    data={curr.datos}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="value" fill="#3F5271" />
                                </BarChart>*/}
                    {curr.tipo == 1 && (
                      <ul>
                        {curr.lista.map((actual, indice) => (
                          <li key={"response-" + Date.now + "-" + indice}>
                            {actual}
                          </li>
                        ))}
                      </ul>
                    )}
                  </Row>
                ))}
              </Container>
            </Row>
          )}
        </Container>
      </Row>
      <EditarRespuesta
        show={editarRespuesta}
        onHide={() => {
          setEditarRespuesta(false);
          setContador(contador + 1);
        }}
        respuesta={respuestaActual}
        preguntas={preguntas}
      />
      <VerRespuestas
        show={verRespuesta}
        onHide={() => {
          setVerRespuesta(false);
          setContador(contador + 1);
        }}
        respuesta={respuestaActual}
        preguntas={preguntas}
      />
      <SuccessModal
        color={"#b5bad0"}
        show={modalSucc}
        onHide={ocultar}
        mensaje={mensaje}
        consideracion={consideracion}
        enlace={""}
        llevaenlace={false}
      />
      <ErrorModal
        color={"#b5bad0"}
        show={modalError}
        onHide={ocultar}
        mensaje={mensaje}
        consideracion={consideracion}
      />
      <Confirmacion
        show={mensajeConfirmacion}
        onHide={() => setMensajeConfirmacion(false)}
        accion={accionEliminar}
        mensaje={mensaje}
        uniqueid={actual}
        consideracion={consideracion}
        param={""}
      />
    </Container>
  );
};
export default RespuestasFormulario;
