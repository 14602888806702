import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Form } from 'react-bootstrap'
//import DEFAULT_URL from '../../api/url';
const AllPagos = () => {
    const [contador] = useState(0);
    const [cambios, setCambios] = useState(0);
    const [cobros, setCobros] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [filterData, setFilterData] = useState('');
    const [permiso, setPermiso] = useState(0);
    useEffect(() => {
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/cobro/read', { cache: "no-cache", credentials: 'include' })
            .then((res) => {
                return res.json();
            })
            .then(data => {
                setCobros(data.rows);
                setFilteredData(data.rows);
            });
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/auth/userinfo', { cache: "no-cache", credentials: 'include' })
            .then((res) => {
                return res.json();
            })
            .then(data => {
                setPermiso(data.tipo);
            });
        return true;
    }, [contador]);
    useEffect(() => {
    }, [cambios]);
    const filterEvent = (evt) => {
        setFilterData(evt.target.value);
        setCambios(cambios + 1);
        let result = [];
        result = cobros.filter((actual) => {
            return actual.concepto.search(evt.target.value) != -1 || actual.descripcion.search(evt.target.value) != -1 || actual.Nombre.search(evt.target.value) != -1 || actual.fecha_inicio.search(evt.target.value) != -1 || actual.moneda.search(evt.target.value) != -1 || actual.total.search(evt.target.value) != -1;
        });
        setFilteredData(result);
        setCambios(cambios + 1);
    }
    return (
        <Container style={{ padding: '2%' }}>
            <Row style={{ padding: '2%', textAlign: 'left', borderLeft: '5px solid #1e3d52 ', backgroundColor: 'rgb(250,250,250)' }}>
                <h1>Cobros electrónicos</h1>
            </Row>
            <Row style={{ padding: '2%', textAlign: 'left' }}>
                <Form.Group className="mb-3">
                    <Form.Label>Buscar</Form.Label>
                    <Form.Control value={filterData} onChange={filterEvent} placeholder="Puedes buscar por concepto, descripcion, evento, fecha, total a cobrar y moneda" />
                </Form.Group>
            </Row>
            {filteredData.map((actual, index) => <Row key={'cobro-all-' + index} style={{ padding: '2%', textAlign: 'left' }}>
                <Col xs={12} md={2} lg={2}>
                    <img src={actual.flier} style={{ width: '100%' }} />
                </Col>
                <Col xs={12} md={10} lg={10} style={{ borderBottom: '5px solid #1e3d52', borderLeft: '5px solid #1e3d52', padding: '2%' }}>
                    <h3>CONCEPTO: {actual.concepto}</h3>
                    <p style={{ textAlign: 'justify' }}><strong>DESCRIPCIÓN:</strong><br />{actual.descripcion}</p>
                    <p><strong>EVENTO: </strong>{actual.Nombre + ' (' + actual.fecha_inicio + ')'}</p>
                    <p><strong>TOTAL A COBRAR: </strong>{actual.moneda + ' ' + actual.total}</p>
                    <Button variant='outline-dark' href={'/pagos-electronicos/view/' + actual.UniqueID}>Ver detalle</Button>
                </Col>
            </Row>)}
        </Container>
    )
}

export default AllPagos