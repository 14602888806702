import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Navbar, Nav, Col } from "react-bootstrap";
import { Outlet } from "react-router-dom";
const Tarifas = () => {
  const { hash } = useParams();
  const [contador] = useState(0);
  const [redireccion, setRedireccion] = useState("/");
  const [evento, setEvento] = useState({});
  useEffect(() => {
    fetch(process.env.REACT_APP_DEFAULT_URL + "/api/auth/userinfo", {
      cache: "no-cache",
      credentials: "include",
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.tipo == 2) {
          setRedireccion("/control-academico");
        } else if (data.tipo == 1) {
          setRedireccion("/asesor");
        } else if (data.tipo == 4) {
          setRedireccion("/home");
        }
      });
    fetch(
      process.env.REACT_APP_DEFAULT_URL + "/api/read/evento/landing/" + hash,
      { cache: "no-cache" }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setEvento(data.rows[0]);
        /*
        fetch(
          process.env.REACT_APP_DEFAULT_URL +
            "/api/read/evento/tarifas/" +
            data.rows[0].UniqueID,
          { cache: "no-cache" }
        )
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            //console.log(data);
            setTarifario(data.salida);
          });
          */
      });
  }, [contador]);
  return (
    <Container fluid>
      <Row>
        <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
          <Container>
            <Navbar.Brand
              href={
                process.env.REACT_APP_SELF_URL + "/tarifas/" + hash + "/all"
              }
            >
              Manejo tarifas
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav>
                <Nav.Link
                  eventKey={2}
                  href={
                    process.env.REACT_APP_SELF_URL +
                    "/tarifas/" +
                    hash +
                    "/create"
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-tag-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2 1a1 1 0 0 0-1 1v4.586a1 1 0 0 0 .293.707l7 7a1 1 0 0 0 1.414 0l4.586-4.586a1 1 0 0 0 0-1.414l-7-7A1 1 0 0 0 6.586 1H2zm4 3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                  </svg>
                  &nbsp;&nbsp;&nbsp;Registrar tarifa
                </Nav.Link>
                <Nav.Link eventKey={2} href={redireccion}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-90deg-left"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1.146 4.854a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H12.5A2.5 2.5 0 0 1 15 6.5v8a.5.5 0 0 1-1 0v-8A1.5 1.5 0 0 0 12.5 5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4z"
                    />
                  </svg>
                  &nbsp;&nbsp;&nbsp;Regresar a sistema
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </Row>
      <Row style={{ padding: "5%" }}>
        <Container>
          <Row
            style={{
              padding: "5%",
              borderBottom: "5px solid #1e3d52",
              borderLeft: "5px solid #1e3d52",
              backgroundColor: "rgb(230,230,230)",
            }}
          >
            <Col xs={12} md={6} lg={4}>
              <img
                loading="lazy"
                src={evento.flier}
                style={{ width: "100%" }}
                alt="arte"
              />
            </Col>
            <Col xs={12} md={6} lg={8} style={{ textAlign: "left" }}>
              <h1>TARIFAS</h1>
              {evento != null && (
                <p>
                  <br />
                  <strong>EVENTO: </strong>
                  {evento.Nombre}
                  <br />
                  <strong>FECHA: </strong>
                  {evento.Fecha_Inicio}
                  <br />
                  <strong></strong>
                </p>
              )}
            </Col>
          </Row>
        </Container>
      </Row>
      <Row>
        <Outlet />
      </Row>
    </Container>
  );
};

export default Tarifas;
