import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Modal } from "react-bootstrap";
//import DEFAULT_URL from '../../api/url';
import ViewTema from './ViewTema';
const ModalTema = (props) => {
    const [contador, setContador] = useState(0);
    const [docente, setDocente] = useState({});
    const [temas, setTemas] = useState([]);
    const [experiencia, setExperiencia] = useState([]);
    useEffect(() => {
        /*
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/temas/read/by-docente/' + props.docente.UniqueID, { cache: "no-cache" })
            .then((res) => {
                return res.json();
            })
            .then(data => {
                setTemas(data.salida);
            });
        ///api/facilitadores/read/experiencia/
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/facilitadores/read/experiencia/' + props.docente.UniqueID, { cache: "no-cache" })
            .then((res) => {
                return res.json();
            })
            .then(data => {
                setExperiencia(data.rows);
            });
            */
    }, [contador]);
    const onEnter = () => {
        setContador(contador + 1);
    }
    return (
        <Modal
            {...props}
            size="fullscreen"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            onEnter={onEnter}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    BIBLIOTECA: {props.biblioteca}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container fluid>
                    <Row style={{ marginTop: '25px' }}>
                        <ViewTema tema={props.tema} refresh={props.refresh} onHide={props.onHide} permiso = {props.permiso}/>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>)
}

export default ModalTema