import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import { ListGroup, Row, Col,  Container,  Card, Popover, OverlayTrigger } from "react-bootstrap";
//import DEFAULT_URL from '../../api/url';
//import { DOWNLOAD_URL }} from '../../api/url';
import "./Carpeta.css"
const Carpeta = () => {
    const { correlativo } = useParams();
    const [contador, setContador] = useState(0);
    const [materiales, setMateriales] = useState([]);
    const [encabezado, setEncabezado] = useState({});
    useEffect(() => {
        async function fetchData() {
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/material/carpetas/info/public/' + correlativo, { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    if (data.rows.length == 0) {
                        window.location.replace('/unauthorized')
                    } else {
                        setEncabezado(data.rows[0]);
                        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/material/carpetas/materiales/public/' + correlativo, { cache: "no-cache" })
                            .then((res) => {
                                return res.json();
                            })
                            .then(data => {
                                setMateriales(data.rows);
                            });
                    }
                });
        }
        fetchData();
    }, [contador]);
    const popover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">¡Listo!</Popover.Header>
            <Popover.Body>
                Enlace copiado exitosamente.
            </Popover.Body>
        </Popover>
    );
    return (
        <div className="contenedor-view-carpeta">
            <Container style={{ width: '100%', height: '100%' }}>
                <Row style={{ height: '100%' }}>
                    <Col sm={12} md={12} lg={6} style={{ display: 'grid', placeItems: 'center' }} >
                        <Card className="text-center" style={{ width: '100%' }}>
                            <Card.Img variant="top" src='https://ik.imagekit.io/p5ethkcnzwh/logotipos_J3ALOjtXZ.png?ik-sdk-version=javascript-1.4.3&updatedAt=1652370601113' style={{ height: '400px' }} />
                            <Card.Body>
                                <Card.Title>{encabezado.Nombre}</Card.Title>
                                <Card.Text>
                                    {encabezado.fecha}
                                </Card.Text>
                            </Card.Body>
                            {
                                encabezado.Contador == 1 && <Card.Footer className="text-muted">
                                    {encabezado.Contador + ' archivo'}
                                </Card.Footer>
                            }
                            {
                                encabezado.Contador > 1 && <Card.Footer className="text-muted">
                                    {encabezado.Contador + ' archivos'}
                                </Card.Footer>
                            }
                        </Card>
                    </Col>
                    <Col sm={12} md={12} lg={6} style={{ display: 'grid', placeItems: 'center' }}>
                        <Container style={{ height: '90%' }}>
                            <Row style={{ height: '20%', color: 'white', display: 'grid', placeItems: 'center', backgroundColor: '#617785', borderRadius: '45px' }}><h1>Archivos</h1></Row>
                            <Row style={{ height: '10%', color: 'white', marginTop: '25px' }}>
                                <Col xs={1} md={1} lg={1}>
                                    <div style={{ color: 'white', cursor: 'pointer' }} data-toggle="tooltip" data-placement="top" title="Refrescar" onClick={() => setContador(contador + 1)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                                            <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                                        </svg>
                                    </div>
                                </Col>
                            </Row>
                            <Row style={{ height: '60%' }} className="contenedor-archivos-carpeta">
                                <ListGroup as="ol" numbered>
                                    {
                                        materiales.map((material) =>
                                            <ListGroup.Item key={material.UniqueID}
                                                as="li"
                                                className="d-flex justify-content-between align-items-start"
                                                style={{ marginTop: '20px', borderRadius: '20px' }}
                                            >
                                                <div className="ms-2 me-auto">
                                                    <div className="fw-bold">{material.Nombre}</div>
                                                    {material.fecha}<br />
                                                </div>
                                                <a href={process.env.REACT_APP_DOWNLOAD_URL + material.Path} target="_blank" data-toggle="tooltip" data-placement="top" title="Descargar Archivo" style={{ cursor: 'pointer', color: 'black' }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-cloud-arrow-down-fill" viewBox="0 0 16 16">
                                                        <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 6.854-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5a.5.5 0 0 1 1 0v3.793l1.146-1.147a.5.5 0 0 1 .708.708z" />
                                                    </svg>
                                                    <i className="bi bi-cloud-arrow-down-fill"></i>
                                                </a>
                                                <OverlayTrigger trigger="click" placement="left" overlay={popover} rootClose>
                                                    <div data-toggle="tooltip" data-placement="top" title="Copiar Enlace De Archivo" style={{ cursor: 'pointer', paddingLeft: '10px' }} onClick={() => navigator.clipboard.writeText(process.env.REACT_APP_DOWNLOAD_URL + material.Path)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard-check" viewBox="0 0 16 16">
                                                            <path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                                                            <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                                                            <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                                                        </svg>
                                                    </div>
                                                </OverlayTrigger>
                                            </ListGroup.Item>
                                        )
                                    }
                                </ListGroup>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </div >
    )
}

export default Carpeta