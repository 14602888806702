import React, { Component } from 'react';
import { Modal, Spinner } from "react-bootstrap";
import './ModalCargando.css';

export class ModalCargando extends Component {
    render() {
        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
                animation
                backdrop='static'
            >
                <Modal.Header style={{ backgroundColor: this.props.color }}>
                    <Modal.Title id="contained-modal-title-vcenter" className="font-bold">
                        <Spinner animation="border" role="status" style={{ marginLeft: '10px', marginRight: '10px' }}>
                            <span className="visually-hidden">Cargando...</span>
                        </Spinner>
                        Cargando...
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: this.props.color }} className="cuerpo-modal-cargando">
                    <p>{this.props.mensaje}</p>
                </Modal.Body>
            </Modal>
        );
    }
}