import React, { Component } from 'react';
import { Accordion } from "react-bootstrap";
import { Participante } from '../Participante/Participante'
export class Participantes extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div style={{ width: '100%' }}>
                <Accordion defaultActiveKey="0">
                    {this.props.participantes.map(prcpnt => <Participante participante={prcpnt} key={prcpnt.UniqueID} deleteparticipante={this.props.deleteparticipante} sendGafete={this.props.sendGafete} eventocambio={this.props.eventocambio} />)}
                </Accordion>
            </div>
        );
    }
}
