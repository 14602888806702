import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
//import { DEFAULT_URL, SELF_URL } from '../../../../api/url';
import { Row, Card, Button, Col, Form, Container, Carousel } from "react-bootstrap";
import "./Participante.css"
import { ModalCargando } from '../../../Utils/Modal-Cargando/ModalCargando';
import { SuccessModal } from '../../../Utils/SuccessModal/SuccessModal';
import { ErrorModal } from '../../../Utils/ErrorModal/ErrorModal';
import axios from 'axios';
const Participante = () => {
    const { hash } = useParams();
    const [contador, setContador] = useState(0);
    const [gafete, setGafete] = useState({});
    const [selectedImg, setSelectedImg] = useState(null);
    const [mensajeCargando, setMensajeCargando] = useState('');
    const [modalCargando, setModalCargando] = useState(false);
    const [modalSucc, setModalSucc] = useState(false);
    const [modalError, setModalError] = useState(false);
    const [mensaje, setMensaje] = useState('');
    const [consideracion, setConsideracion] = useState('');
    const [evento, setEvento] = useState({});
    const [patrocinadores, setPatrocinadores] = useState([]);
    const [index, setIndex] = useState(0);
    const [index2, setIndex2] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    const handleSelect2 = (selectedIndex, e) => {
        setIndex2(selectedIndex);
    };
    useEffect(async () => {
        async function fetchData() {
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/gafete/' + hash, { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setGafete(data.salida[0]);
                    fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/evento/landing/' + data.salida[0].hash, { cache: "no-cache" })
                        .then((res) => {
                            return res.json();
                        })
                        .then(data => {
                            if (data.rows.length == 0) {
                                window.location.replace('/unauthorized');
                            } else {
                                setEvento(data.rows[0]);
                                fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/evento/patrocinadores/' + data.rows[0].UniqueID, { cache: "no-cache" })
                                    .then((res) => {
                                        return res.json();
                                    })
                                    .then(data => {
                                        setPatrocinadores(data.rows);
                                        console.log(data.rows);
                                    });
                            }
                        });
                });
        }
        fetchData();
    }, [contador]);
    const onSubmit = (evt) => {
        evt.preventDefault();
        if (selectedImg != null) {
            setModalCargando(true);
            setMensajeCargando('Subiendo imagenes a repositorio de medios');
            const data = new FormData();
            data.append('file', selectedImg);
            axios.post(process.env.REACT_APP_DEFAULT_URL+ "/api/files/upload/imagekit", data, {}).then(res => {
                if (res.data.codigo == 200) {
                    setMensajeCargando('Sincronizando base de datos...');
                    const info = { foto: res.data.resultado.url, hash: hash };
                    const requestOptions = {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(info)
                    };
                    fetch(process.env.REACT_APP_DEFAULT_URL + '/api/update/gafete', requestOptions)
                        .then((res) => {
                            return res.json();
                        })
                        .then(data => {
                            setModalCargando(false);
                            if (data.codigo == 200) {
                                setMensaje('Fotografía actualizada correctamente');
                                setModalSucc(true);
                            } else {
                                setMensaje(data.respuesta);
                                setModalError(true);
                            }
                            setContador(contador + 1);
                        });

                }
            });
        } else {
            setMensaje('Seleccione un archivo');
            setConsideracion('Inténtelo de nuevo');
            setModalError(true);
        }
    }
    const onSubmitAsistencia = (evt) => {
        evt.preventDefault();
        setModalCargando(true);
        setMensajeCargando('Subiendo asistencia');
        const info = { nombres: gafete.Nombres, apellidos: gafete.Apellidos, correo: gafete.Correo, institucion: gafete.empresa, evento_UniqueID: evento.UniqueID };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/create/evento/marcar/asistencia', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                setModalCargando(false);
                if (data.codigo == 200) {
                    setMensaje('Asistencia marcada exitosamente');
                    setModalSucc(true);
                } else {
                    setMensaje('Ocurrió un error al marcar la asistencia');
                    setModalError(true);
                }
                setContador(contador + 1);
            });
    }
    const asistencia_stand = (id) => {
        setModalCargando(true);
        setMensajeCargando('Subiendo asistencia stand');
        const info = { hash: hash, id: id };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/create/evento/marcar/asistencia/stand', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                setModalCargando(false);
                if (data.codigo == 200) {
                    setMensaje('Asistencia a stand marcada exitosamente');
                    setModalSucc(true);
                } else {
                    setMensaje('Ocurrió un error al marcar la asistencia a stand');
                    setModalError(true);
                }
                setContador(contador + 1);
            });
    }
    return (
        <div className='contenedor-gafete'>
            <Container>
                <Row style={{ marginTop: '45px' }}>
                    <Col xs={12} md={12} lg={6}>
                        <img src='https://ik.imagekit.io/p5ethkcnzwh/Blanco_Logo_final_2022_ABG_6V4gmyglP.png?ik-sdk-version=javascript-1.4.3&updatedAt=1657298859263' style={{ width: '75%' }} />
                    </Col>
                    <Col xs={12} md={12} lg={6} style={{ marginTop: '25px' }}>
                        <h1 className="font-bold blanco" style={{ fontWeight: 'bolder' }}>ESCUELA BANCARIA DE GUATEMALA</h1>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6} lg={6} style={{ marginTop: '45px' }}>
                        <Card style={{ width: '100%' }}>
                            <Card.Header>Gafete electrónico</Card.Header>
                            {gafete.foto == null && <Card.Img variant="top" src="https://ik.imagekit.io/p5ethkcnzwh/person-icon_B5aNsHlZl.png?ik-sdk-version=javascript-1.4.3&updatedAt=1659026017200" />}
                            {gafete.foto != null && <Card.Img variant="top" src={gafete.foto} />}
                            <Card.Body>
                                <Card.Title>{gafete.Nombres + ' ' + gafete.Apellidos}</Card.Title>
                                <Card.Text>
                                    {gafete.Puesto}
                                </Card.Text>
                                <Card.Text>
                                    {gafete.empresa}
                                </Card.Text>
                                <Card.Text>
                                    {gafete.pais}
                                </Card.Text>
                                <Card.Text>
                                    {gafete.Correo}
                                </Card.Text>
                                <Card.Text>
                                    {gafete.Telefono}
                                </Card.Text>
                                <Card.Text>
                                    {gafete.celular}
                                </Card.Text>
                                <Button variant="secondary" onClick={() => { window.open(process.env.REACT_APP_SELF_URL + '/inscripcion/public/ver/' + gafete.UniqueID, "_blank"); }}>Ver ficha de inscripción</Button>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} md={6} lg={6} style={{ marginTop: '45px' }}>
                        <Carousel activeIndex={index} onSelect={handleSelect}>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={evento.flier}
                                    alt="Third slide"
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={evento.imagen}
                                    alt="Third slide"
                                />
                                {
                                    evento.diff == 0 && <Carousel.Caption>
                                        <p style={{ color: 'black' }}>¡Es Hoy!</p>
                                    </Carousel.Caption>
                                }
                                {
                                    evento.diff == 1 && <Carousel.Caption>
                                        <p style={{ color: 'black' }}>¡Falta 1 día !</p>
                                    </Carousel.Caption>
                                }
                                {
                                    evento.diff > 1 && <Carousel.Caption>
                                        <p style={{ color: 'black' }}>¡Faltan {evento.diff} días!</p>
                                    </Carousel.Caption>
                                }
                            </Carousel.Item>
                        </Carousel>
                    </Col>
                </Row>
                <Row style={{ marginTop: '45px', marginBottom: '45px' }}>
                    <Col xs={12} md={12} lg={12} style={{ color: 'white' }}>
                        <h1>Personalizar foto de gafete</h1>
                        <Form onSubmit={onSubmit}>
                            <Form.Group controlId="formFileMultiple" className="mb-3">
                                <Form.Control type="file" onChange={(evt) => { setSelectedImg(evt.target.files[0]); setContador(contador + 1); }} accept=".jpeg, .png, .JPG, .JPEG, .PNG" name="img" />
                            </Form.Group>
                            <Button variant="light" type="submit">Cambiar imagen</Button>
                        </Form>
                    </Col>
                </Row>
                {evento.diff == 0 && <Row style={{ marginBottom: '50px' }}>
                    <Col xs={12} md={12} lg={12}>
                        <h1 style={{ color: 'white' }}>Marcar asistencia</h1>
                        <Form onSubmit={onSubmitAsistencia} >
                            <Button variant="light" type="submit">Marcar</Button>
                        </Form>
                    </Col>
                </Row>}
                {evento.diff == 0 && <Row>
                    <Col xs={12} md={12} lg={3}>
                    </Col>
                    <Col xs={12} md={12} lg={6} style={{ backgroundColor: 'white', padding: '50px', borderRadius: '50px' }}>
                        <h1>Gracias a nuestros patrocinadores</h1>
                        <Carousel activeIndex={index2} onSelect={handleSelect2} variant="dark">
                            {patrocinadores.map((patrocinador) => <Carousel.Item onClick={() => { asistencia_stand(patrocinador.UniqueID) }} style={{ height: '300px' }}>
                                <img
                                    className="d-block w-100"
                                    src={patrocinador.promocional_landing}
                                    alt="Third slide"
                                    style={{ objectFit: 'contain', width: '100%', height: '100%' }}
                                />
                            </Carousel.Item>
                            )}
                        </Carousel>
                    </Col>
                    <Col xs={12} md={12} lg={3}>
                    </Col>
                </Row>}
            </Container>
            <SuccessModal
                color={"#b5bad0"}
                show={modalSucc}
                onHide={() => { setModalSucc(false); setContador(contador + 1); }}
                mensaje={mensaje}
                consideracion={consideracion}
                enlace={''}
                llevaenlace={false}
            />
            <ModalCargando
                color={"#b5bad0"}
                show={modalCargando}
                onHide={() => { setModalCargando(false); setContador(contador + 1); }}
                mensaje={mensajeCargando}
            />
            <ErrorModal
                color={"#b5bad0"}
                show={modalError}
                onHide={() => { setModalError(false); setContador(contador + 1); }}
                mensaje={mensaje}
                consideracion={consideracion}
            />
        </div>
    )
}

export default Participante