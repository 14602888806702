import React, { useState, useEffect } from 'react'
import { SuccessModal } from '../../../../Utils/SuccessModal/SuccessModal'
import { ErrorModal } from '../../../../Utils/ErrorModal/ErrorModal'
import { ModalCargando } from '../../../../Utils/Modal-Cargando/ModalCargando'
//import DEFAULT_URL from '../../../../../api/url'
import { Alert, Spinner, Row, Button, Col, Form, Container, ProgressBar } from "react-bootstrap";
import './common.css'
const NuevoFormulario = (props) => {
    const [error, setError] = useState('none');
    const [mensaje, setMensaje] = useState('');
    const [cargando, setCargando] = useState('none');
    const [exito, setExito] = useState('none');
    const [form, setForm] = useState('block');
    const [prefijo, setPrefijo] = useState('');
    const [titulo, setTitulo] = useState('');
    const [preguntas, setPreguntas] = useState([]);
    const [contador, setContador] = useState(0);
    const [promocional, setPromocional] = useState('');
    const [showAlert, setShowAlert] = useState(true);
    const [tipos, setTipos] = useState([]);
    const [tiposForm, setTiposForm] = useState([]);
    const [tipoFormulario, setTipoFormulario] = useState('');
    const [status, setStatus] = useState(0);
    useEffect(async () => {
        async function fetchData() {
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/formulario/pregunta/tipos', { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setTipos(data.tipos);
                });
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/formulario/tipos/formulario', { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setTiposForm(data.rows);
                });
        }
        fetchData();
        setPromocional(props.evento.flier);
    }, [contador]);
    const onChange = (evt) => {
        switch (evt.target.name) {
            case "prefijo":
                setPrefijo(evt.target.value);
                break;
            case 'titulo':
                setTitulo(evt.target.value);
                break;
            case "promocional":
                setPromocional(evt.target.value);
                break;
            case "tipo":
                setTipoFormulario(evt.target.value);
                if (evt.target.value == '1' || evt.target.value == '4') {
                    setPrefijo('INSCRIPCION');
                    setTitulo('Formulario de inscripción para ' + props.evento.Nombre);
                    setPreguntas([...preguntas, { pregunta: 'Institución', tipo: '1' }, { pregunta: 'Puesto', tipo: '1' }, { pregunta: 'Teléfono', tipo: '1' }, { pregunta: 'País', tipo: '4' }, { pregunta: 'Seleccione el asesor que le atendió', tipo: '3' }]);
                } else if (evt.target.value == '5') {
                    setPrefijo('ENCUESTA-SATISFACCION');
                    setPreguntas([...preguntas, { pregunta: '¿El(los) Facilitador(es) cuenta(n) con la capacidad y dominio del tema?', tipo: '5', opciones: ['Excelente', 'Bueno', 'Regular', 'No'] }, { pregunta: '¿Los materiales, talleres y herramientas didácticas utilizadas en el programa por el facilitador apoyaron el desarrollo de sus competencias?', tipo: '5', opciones: ['Excelente', 'Bueno', 'Regular', 'No'] }, { pregunta: '¿Los contenidos del evento recibido, le serán de utilidad para la toma de decisiones en su área de trabajo?', tipo: '5', opciones: ['Excelente', 'Bueno', 'Regular', 'No'] }, { pregunta: '¿Cómo califica la intervención y apoyo al Facilitador y equipo de EBG durante el evento?', tipo: '5', opciones: ['Excelente', 'Bueno', 'Regular', 'No'] }, { pregunta: '¿El evento en términos generales cumplió sus expectativas?', tipo: '5', opciones: ['Sí', 'No'] }, { pregunta: '¿En una escala del 0 al 5 que probabilidad hay que recomiende nuestros servicios a sus familiares, colegas o amigos? Siendo el 5 la calificación más alta y excelente', tipo: '5', opciones: ['1', '2', '3', '4', '5'] }, { pregunta: '¿Podría y/o volvería a tomar en cuenta los programas académicos de la EBG para sus planes de actualización profesional y personal?', tipo: '5', opciones: ['Sí', 'No'] }, { pregunta: 'Sugerencia de temas para próximos eventos virtuales, para su desarrollo', tipo: '1' }, { pregunta: '¿Tiene algún comentario u opinión del evento recibido?', tipo: '1' }, { pregunta: 'Sugerencias de mejora para próximos eventos', tipo: '1' }]);
                    setTitulo('Encuesta de satisfacción para ' + props.evento.Nombre);
                } else {
                    setPrefijo('');
                    setTitulo('');
                    setPreguntas([]);
                }
                break;
        }
        setContador(contador + 1);
    }
    const submit = (evt) => {
        evt.preventDefault();
        setStatus(0);
        setMensaje('Sincronizando base de datos');
        cargar();
        if (preguntas.length == 0) {
            setMensaje("No hay preguntas ingresadas");
            operacionError();
        } else {
            setStatus(25);
            setContador(contador + 1);
            const prefix = prefijo + '-' + Date.now();
            const info = { titulo: titulo, correlativo: prefix, promocional: promocional, preguntas: preguntas, evento: props.evento.UniqueID, tipoFormulario: tipoFormulario };
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(info)
            };
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/create/formulario', requestOptions)
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    if (data.codigo == 200) {
                        setMensaje('Formulario almacenado exitosamente');
                        operacionExito();
                    } else {
                        setMensaje('Ocurrió un error al almacenar el formulario en base de datos');
                        operacionError();
                    }
                });
        }
    }
    const nuevaPregunta = () => {
        setPreguntas([...preguntas, { pregunta: '', tipo: '1' }]);
        setContador(contador + 1);
    }
    const nuevaOpcion = (indice) => {
        let arr = preguntas;
        arr[indice].opciones = [...arr[indice].opciones, ''];
        setPreguntas(arr);
        setContador(contador + 1);
    }
    const porDefecto = () => {
        setPreguntas([...preguntas, { pregunta: 'Institución', tipo: '1' }, { pregunta: 'Puesto', tipo: '1' }, { pregunta: 'Teléfono', tipo: '1' }, { pregunta: 'País', tipo: '4' }, { pregunta: 'Seleccione el asesor que le atendió', tipo: '3' }]);
        setContador(contador + 1);
    }
    const onChangePregunta = (evt) => {
        let arr = evt.target.name.split('-');
        let valor_nuevo = evt.target.value;
        let arr_actual = preguntas;
        if (arr[0] == 'pregunta') {
            arr_actual[arr[1]].pregunta = valor_nuevo;
        } else {
            arr_actual[arr[1]].tipo = valor_nuevo;
            if (valor_nuevo == 5) {
                arr_actual[arr[1]].opciones = [];
            }
        }
        setPreguntas(arr_actual);
        setContador(contador + 1);

    }
    const onChangeOpcion = (evt) => {
        let arr = evt.target.name.split('-');
        let valor_nuevo = evt.target.value;
        let arr_actual = preguntas;
        let opciones = arr_actual[arr[3]].opciones;
        opciones[arr[1]] = valor_nuevo;
        arr_actual[arr[3]].opciones = opciones;
        setPreguntas(arr_actual);
        setContador(contador + 1);
    }
    const desplazarAbajo = (indice) => {
        let siguiente = preguntas[indice + 1];
        let actual = preguntas[indice];
        let arr_actual = preguntas;
        arr_actual[indice] = siguiente;
        arr_actual[indice + 1] = actual;
        setPreguntas(arr_actual);
        setContador(contador + 1);
    }
    const desplazarArriba = (indice) => {
        let siguiente = preguntas[indice - 1];
        let actual = preguntas[indice];
        let arr_actual = preguntas;
        arr_actual[indice] = siguiente;
        arr_actual[indice - 1] = actual;
        setPreguntas(arr_actual);
        setContador(contador + 1);
    }
    const eliminar = (indice) => {
        let arr_actual = preguntas;
        preguntas.splice(indice, 1);
        setPreguntas(arr_actual);
        setContador(contador + 1);
    }
    const eliminarOpcion = (indice, indice_opcion) => {
        let arr_actual = preguntas;
        preguntas[indice].opciones.splice(indice_opcion, 1);
        setPreguntas(arr_actual);
        setContador(contador + 1);
    }
    const porDefectoSatisfaccion = () => {
        setPreguntas([...preguntas, { pregunta: '¿El(los) Facilitador(es) cuenta(n) con la capacidad y dominio del tema?', tipo: '5', opciones: ['Excelente', 'Bueno', 'Regular', 'No'] }, { pregunta: '¿Los materiales, talleres y herramientas didácticas utilizadas en el programa por el facilitador apoyaron el desarrollo de sus competencias?', tipo: '5', opciones: ['Excelente', 'Bueno', 'Regular', 'No'] }, { pregunta: '¿Los contenidos del evento recibido, le serán de utilidad para la toma de decisiones en su área de trabajo?', tipo: '5', opciones: ['Excelente', 'Bueno', 'Regular', 'No'] }, { pregunta: '¿Cómo califica la intervención y apoyo al Facilitador y equipo de EBG durante el evento?', tipo: '5', opciones: ['Excelente', 'Bueno', 'Regular', 'No'] }, { pregunta: '¿El evento en términos generales cumplió sus expectativas?', tipo: '5', opciones: ['Sí', 'No'] }, { pregunta: '¿En una escala del 0 al 5 que probabilidad hay que recomiende nuestros servicios a sus familiares, colegas o amigos? Siendo el 5 la calificación más alta y excelente', tipo: '5', opciones: ['1', '2', '3', '4', '5'] }, { pregunta: '¿Podría y/o volvería a tomar en cuenta los programas académicos de la EBG para sus planes de actualización profesional y personal?', tipo: '5', opciones: ['Sí', 'No'] }, { pregunta: 'Sugerencia de temas para próximos eventos virtuales, para su desarrollo', tipo: '1' }, { pregunta: '¿Tiene algún comentario u opinión del evento recibido?', tipo: '1' }, { pregunta: 'Sugerencias de mejora para próximos eventos', tipo: '1' }]);
        setContador(contador + 1);
    }
    const desplazarOpcionAbajo = (indice, indice_opcion) => {
        let arr_actual = preguntas;
        let opciones = arr_actual[indice].opciones;
        let siguiente = opciones[indice_opcion + 1];
        let actual = opciones[indice_opcion];
        opciones[indice_opcion] = siguiente;
        opciones[indice_opcion + 1] = actual;
        arr_actual[indice].opciones = opciones;
        setPreguntas(arr_actual);
        setContador(contador + 1);
    }
    const desplazarOpcionArriba = (indice, indice_opcion) => {
        let arr_actual = preguntas;
        let opciones = arr_actual[indice].opciones;
        let siguiente = opciones[indice_opcion - 1];
        let actual = opciones[indice_opcion];
        opciones[indice_opcion] = siguiente;
        opciones[indice_opcion - 1] = actual;
        arr_actual[indice].opciones = opciones;
        setPreguntas(arr_actual);
        setContador(contador + 1);
    }
    const goBack = () => {
        setError('none');
        setCargando('none');
        setExito('none');
        setForm('block');
        setStatus(0);
        setPrefijo('');
        setTitulo('');
        setPreguntas([]);
        setPromocional('');
        setTipoFormulario('');
        setContador(contador + 1);
    }
    const cargar = () => {
        setError('none');
        setCargando('block');
        setExito('none');
        setForm('none');
        setContador(contador + 1);
    }
    const operacionExito = () => {
        setError('none');
        setCargando('none');
        setExito('block');
        setForm('none');
        setContador(contador + 1);
    }
    const operacionError = () => {
        setError('block');
        setCargando('none');
        setExito('none');
        setForm('none');
        setContador(contador + 1);
    }
    return (
        <Container>
            <Row style={{ display: form }}>
                <Container>
                    <Form onSubmit={submit} style={{ height: '100%' }}>
                        {showAlert &&
                            <Row style={{ padding: '1%' }}>
                                <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible>
                                    <Alert.Heading>Aclaración</Alert.Heading>
                                    <p>
                                        No ingresar preguntas solicitando correo electrónico, nombres y apellidos, estos campos
                                        están por defecto en todos los formularios.
                                    </p>
                                    <hr />
                                    <p className="mb-0">
                                        Si usted ingresa este tipo de preguntas, en el formulario resultante, se tendrán estas preguntas repetidas.
                                    </p>
                                </Alert>
                            </Row>
                        }
                        <Row>
                            <h1 style={{ textAlign: 'center' }}>Encabezado de formulario</h1>
                        </Row>
                        <Row>
                            <Container>
                                <Row>
                                    <Col xs={12} md={12} lg={12}>
                                        <Form.Group className="mb-3" controlId="tipo">
                                            <Form.Label>Tipo de formulario</Form.Label>
                                            <Form.Control as="select" required aria-label="tipo" name="tipo" onChange={onChange} value={tipoFormulario}>
                                                <option value="" key="0">Seleccione el tipo de formulario</option>
                                                {tiposForm.map(tipo => <option key={tipo.UniqueID} value={tipo.UniqueID}>{tipo.Nombre}</option>)}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={12} lg={12}>
                                        <Form.Group className="mb-3" controlId="prefijo">
                                            <Form.Label>Prefijo de acceso</Form.Label>
                                            <Form.Control readOnly={tipoFormulario == 1 || tipoFormulario == 4 || tipoFormulario == 5} required type="text" placeholder="Ingrese el prefijo de acceso" name="prefijo" value={prefijo} onChange={onChange} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={12} lg={12}>
                                        <Form.Group className="mb-3" controlId="titulo">
                                            <Form.Label>Título</Form.Label>
                                            <Form.Control readOnly={tipoFormulario == 1 || tipoFormulario == 4 || tipoFormulario == 5} type="text" required placeholder="Ingrese el título " name="titulo" value={titulo} onChange={onChange} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={12} lg={12}>
                                        <Form.Group className="mb-3" controlId="titulo">
                                            <Form.Label>Promocional</Form.Label>
                                            <Form.Control readOnly={true} type="text" required placeholder="Ingrese enlace a flier promocional " name="promocional" value={promocional} onChange={onChange} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Container>
                        </Row>
                        <Row>
                            <h1 style={{ textAlign: 'center' }}>Preguntas</h1>
                        </Row>
                        <Row>
                            <Container>
                                <Row style={{ marginBottom: '15px' }}>
                                    <Col xs={12} md={12} lg={12}>
                                        <Row style={{ padding: '1%' }}>
                                            <Button variant='outline-success' onClick={() => nuevaPregunta()}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                                                </svg>&nbsp;&nbsp;&nbsp;&nbsp;Nueva pregunta
                                            </Button>
                                        </Row>
                                    </Col>
                                    {/*                            <Col xs={12} md={12} lg={12}>
                                <Row style={{ padding: '1%' }}>
                                    <Button variant='outline-success' onClick={() => porDefecto()}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-lightbulb" viewBox="0 0 16 16">
                                            <path d="M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13a.5.5 0 0 1 0 1 .5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1 0-1 .5.5 0 0 1 0-1 .5.5 0 0 1-.46-.302l-.761-1.77a1.964 1.964 0 0 0-.453-.618A5.984 5.984 0 0 1 2 6zm6-5a5 5 0 0 0-3.479 8.592c.263.254.514.564.676.941L5.83 12h4.342l.632-1.467c.162-.377.413-.687.676-.941A5 5 0 0 0 8 1z" />
                                        </svg>&nbsp;&nbsp;&nbsp;&nbsp;Preguntas genéricas para formulario de inscripción
                                    </Button>
                                </Row>
                            </Col>
                            <Col xs={12} md={12} lg={12}>
                                <Row style={{ padding: '1%' }}>
                                    <Button variant='outline-success' onClick={() => porDefectoSatisfaccion()}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-lightning-charge" viewBox="0 0 16 16">
                                            <path d="M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09zM4.157 8.5H7a.5.5 0 0 1 .478.647L6.11 13.59l5.732-6.09H9a.5.5 0 0 1-.478-.647L9.89 2.41 4.157 8.5z" />
                                        </svg>&nbsp;&nbsp;&nbsp;&nbsp;Preguntas genéricas para encuesta de satisfacción
                                    </Button>
                                </Row>
                            </Col>*/}
                                </Row>
                                <Row>
                                    <Container>
                                        {preguntas.map((pregunta, indice) =>
                                            <Row key={indice} style={{ border: '1px dashed black', borderRadius: '50px', marginBottom: '15px', padding: '5%' }}>
                                                <Container>
                                                    <Row>
                                                        {indice != 0 &&
                                                            <Col xs={9} md={9} lg={9}>
                                                            </Col>
                                                        }{(indice == 0 || indice == (preguntas.length - 1)) &&
                                                            <Col xs={10} md={10} lg={10}>
                                                            </Col>
                                                        }
                                                        {indice != 0 &&
                                                            <Col xs={12} md={12} lg={4}>
                                                                <Row style={{ padding: '1%' }}>
                                                                    <Button variant='outline-success' onClick={() => desplazarArriba(indice)}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up" viewBox="0 0 16 16">
                                                                            <path d="M3.204 11h9.592L8 5.519 3.204 11zm-.753-.659 4.796-5.48a1 1 0 0 1 1.506 0l4.796 5.48c.566.647.106 1.659-.753 1.659H3.204a1 1 0 0 1-.753-1.659z" />
                                                                        </svg>&nbsp;&nbsp;&nbsp;Desplazar pregunta arriba
                                                                    </Button>
                                                                </Row>
                                                            </Col>
                                                        }
                                                        {indice < (preguntas.length - 1) &&
                                                            <Col xs={12} md={12} lg={4}>
                                                                <Row style={{ padding: '1%' }}>
                                                                    <Button variant='outline-success' onClick={() => desplazarAbajo(indice)}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down" viewBox="0 0 16 16">
                                                                            <path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z" />
                                                                        </svg>&nbsp;&nbsp;&nbsp;Desplazar pregunta abajo
                                                                    </Button>
                                                                </Row>
                                                            </Col>
                                                        }
                                                        <Col xs={12} md={12} lg={4}>
                                                            <Row style={{ padding: '1%' }}>
                                                                <Button variant='outline-danger' onClick={() => eliminar(indice)}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash-fill" viewBox="0 0 16 16">
                                                                        <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                                                                    </svg>&nbsp;&nbsp;&nbsp;Eliminar pregunta
                                                                </Button>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <h4>Pregunta</h4>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={12} md={12} lg={12}>
                                                            <Form.Group className="mb-3" controlId="titulo">
                                                                <Form.Label>Pregunta #{indice + 1}</Form.Label>
                                                                <Form.Control type="text" required placeholder="Ingrese la pregunta " name={"pregunta-" + indice} value={pregunta.pregunta} onChange={onChangePregunta} />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <h4>Tipo de pregunta</h4>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={12} md={12} lg={12}>
                                                            <Form.Group className="mb-3" controlId="tipo">
                                                                <Form.Label>Indique el tipo de pregunta</Form.Label>
                                                                <Form.Control required as="select" aria-label="tipo" name={"tipo-" + indice} value={pregunta.tipo} onChange={onChangePregunta}>
                                                                    <option value="" key="0">Seleccione el tipo de pregunta</option>
                                                                    {tipos.map(tipo => <option key={tipo.UniqueID} value={tipo.UniqueID}>{tipo.nombre}</option>)}
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    {pregunta.tipo == '5' && <Row>
                                                        {pregunta.opciones != null && <Container>
                                                            <Row>
                                                                <h4>Opciones</h4>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={12} md={12} lg={4}>
                                                                    <Row>
                                                                        <Button variant='outline-success' onClick={() => nuevaOpcion(indice)}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                                                                                <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                                                                            </svg>&nbsp;&nbsp;&nbsp;&nbsp;Nueva opción
                                                                        </Button>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                            <Row style={{ padding: '3%' }}>
                                                                <Container>
                                                                    {pregunta.opciones.map((actual, indice_opcion) => <Row>
                                                                        <Container>
                                                                            <Row>
                                                                                {indice_opcion != 0 &&
                                                                                    <Col xs={9} md={9} lg={9}>
                                                                                    </Col>
                                                                                }{(indice_opcion == 0 || indice_opcion == (pregunta.opciones.length - 1)) &&
                                                                                    <Col xs={10} md={10} lg={10}>
                                                                                    </Col>
                                                                                }
                                                                                {indice_opcion != 0 &&
                                                                                    <Col xs={12} md={12} lg={4}>
                                                                                        <Row style={{ padding: '1%' }}>
                                                                                            <Button variant='outline-success' onClick={() => desplazarOpcionArriba(indice, indice_opcion)}>
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up" viewBox="0 0 16 16">
                                                                                                    <path d="M3.204 11h9.592L8 5.519 3.204 11zm-.753-.659 4.796-5.48a1 1 0 0 1 1.506 0l4.796 5.48c.566.647.106 1.659-.753 1.659H3.204a1 1 0 0 1-.753-1.659z" />
                                                                                                </svg>&nbsp;&nbsp;&nbsp;Desplazar opción arriba
                                                                                            </Button>
                                                                                        </Row>
                                                                                    </Col>
                                                                                }
                                                                                {indice_opcion < (pregunta.opciones.length - 1) &&
                                                                                    <Col xs={12} md={12} lg={4}>
                                                                                        <Row style={{ padding: '1%' }}>
                                                                                            <Button variant='outline-success' onClick={() => desplazarOpcionAbajo(indice, indice_opcion)}>
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down" viewBox="0 0 16 16">
                                                                                                    <path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z" />
                                                                                                </svg>&nbsp;&nbsp;&nbsp;Desplazar opción abajo
                                                                                            </Button>
                                                                                        </Row>
                                                                                    </Col>
                                                                                }
                                                                                <Col xs={12} md={12} lg={4}>
                                                                                    <Row style={{ padding: '1%' }}>
                                                                                        <Button variant='outline-danger' onClick={() => eliminarOpcion(indice, indice_opcion)}>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash-fill" viewBox="0 0 16 16">
                                                                                                <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                                                                                            </svg>&nbsp;&nbsp;&nbsp;Eliminar opción
                                                                                        </Button>
                                                                                    </Row>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Form.Group className="mb-3" controlId={"opcion-" + indice_opcion + '-' + indice}>
                                                                                    <Form.Label>Opción #{indice_opcion + 1}</Form.Label>
                                                                                    <Form.Control type="text" required placeholder="Ingrese la pregunta " name={"opcion-" + indice_opcion + "-pregunta-" + indice} value={actual} onChange={onChangeOpcion} />
                                                                                </Form.Group>
                                                                            </Row>
                                                                        </Container>

                                                                    </Row>)}
                                                                </Container>
                                                            </Row>
                                                        </Container>}
                                                        {pregunta.opciones == null && <Container>
                                                            <Row>
                                                                <h4>Opciones</h4>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={12} md={12} lg={4}>
                                                                    <Row>
                                                                        <Button variant='outline-success' onClick={() => nuevaOpcion(indice)}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                                                                                <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                                                                            </svg>&nbsp;&nbsp;&nbsp;&nbsp;Nueva opción
                                                                        </Button>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        </Container>}
                                                    </Row>}
                                                </Container>
                                            </Row>
                                        )}
                                    </Container>
                                </Row>
                            </Container>
                        </Row>
                        <Row>
                            <Button variant="secondary" className="font-bold" type="submit">Guardar</Button>
                        </Row>
                    </Form>
                </Container>
            </Row>
            <Row style={{ display: error }}>
                <Alert variant='danger'>
                    <Alert.Heading>{mensaje}</Alert.Heading>
                    <Row>
                        <Col xs={8} md={10} lg={10}>
                        </Col>
                        <Col xs={4} md={2} lg={2}>
                            <Row>
                                <Button variant='outline-danger' onClick={goBack}>Ok</Button>
                            </Row>
                        </Col>
                    </Row>
                </Alert>
            </Row>
            <Row style={{ display: exito }}>
                <Alert variant='success'>
                    <Alert.Heading>Operación procesada con éxito</Alert.Heading>
                    <p>
                        {mensaje}
                    </p>
                    <Row>
                        <Col xs={8} md={10} lg={10}>
                        </Col>
                        <Col xs={4} md={2} lg={2}>
                            <Row>
                                <Button variant='outline-success' onClick={goBack}>Ok</Button>
                            </Row>
                        </Col>
                    </Row>
                </Alert>
            </Row>
            <Row style={{ display: cargando }}>
                <Alert variant='dark'>
                    <Alert.Heading>Procesando operación</Alert.Heading>
                    <p>
                        Esto puede demorar unos minutos
                    </p>
                    <hr />
                    <p className="mb-0">
                        Por favor espere...<br /><br />
                        {mensaje}
                        <br /><br />
                        <ProgressBar animated now={status} variant='dark' />
                    </p>
                </Alert>
            </Row>
        </Container>
    )
}

export default NuevoFormulario