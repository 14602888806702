import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Form, Button, Col, Card } from "react-bootstrap";
const ViewGafetesFisicos = () => {
  const { hash, UniqueID } = useParams();
  const [cambios, setCambios] = useState(0);
  const [contador] = useState(0);
  const [evento, setEvento] = useState({});
  const [gafete, setGafete] = useState({});
  const [participantes, setParticipantes] = useState([]);
  const [filterdedData, setFilteredData] = useState([]);
  const [filterData, setFilterData] = useState("");
  const [cargando, setCargando] = useState(false);
  useEffect(() => {
    async function fetchData() {
      fetch(
        process.env.REACT_APP_DEFAULT_URL + "/api/read/evento/landing/" + hash,
        {
          cache: "no-cache",
          credentials: "include",
        }
      )
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setEvento(data.rows[0]);
          fetch(
            process.env.REACT_APP_DEFAULT_URL +
              "/api/read/detalle_inscripcion/evento/" +
              data.rows[0].UniqueID,
            { cache: "no-cache" }
          )
            .then((res) => {
              return res.json();
            })
            .then((data) => {
              let tmp = [];
              data.rows.map((actual, indice) => {
                actual.selected = false;
                tmp.push(actual);
                if (indice == data.rows.length - 1) {
                  setParticipantes(tmp);
                  setFilteredData(tmp);
                }
              });
            });
        });
      fetch(
        process.env.REACT_APP_DEFAULT_URL +
          "/api/gafetes-fisicos/view/" +
          UniqueID,
        {
          cache: "no-cache",
          credentials: "include",
        }
      )
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setGafete(data.rows[0]);
        });
    }
    fetchData();
  }, [contador]);
  useEffect(() => {}, [cambios]);
  const onChange = (evt) => {
    if (evt.target.name == "filter") {
      setFilterData(evt.target.value);
      let result = [];
      result = participantes.filter((actual) => {
        return (
          actual.Nombres.search(evt.target.value) != -1 ||
          actual.Apellidos.search(evt.target.value) != -1 ||
          actual.Correo.search(evt.target.value) != -1 ||
          actual.Puesto.search(evt.target.value) != -1 ||
          actual.institucion.search(evt.target.value) != -1
        );
      });
      setFilteredData(result);
    } else {
      let id = evt.target.name.split("-")[1];
      let result = filterdedData;
      let tmp = participantes;
      const index_finder_1 = (participante) => participante.UniqueID == id;
      if (result.findIndex(index_finder_1) >= 0) {
        let item = result[result.findIndex(index_finder_1)];
        item.selected = evt.target.checked;
        result[result.findIndex(index_finder_1)] = item;
        setFilteredData(result);
      }
      const index_finder = (participante) => participante.UniqueID == id;
      if (tmp.findIndex(index_finder) >= 0) {
        let item = tmp[tmp.findIndex(index_finder)];
        item.selected = evt.target.checked;
        tmp[tmp.findIndex(index_finder)] = item;
        setParticipantes(tmp);
      }
    }
    setCambios(cambios + 1);
  };
  const deleteSelf = () => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    };
    fetch(
      process.env.REACT_APP_DEFAULT_URL +
        "/api/gafetes-fisicos/delete/" +
        gafete.UniqueID,
      requestOptions
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.codigo == 200) {
          window.location.replace(
            process.env.REACT_APP_SELF_URL + "/gafetes-fisicos/" + hash + "/all"
          );
        } else {
          window.location.reload();
        }
      });
  };
  const genBulk = () => {
    fetch(
      process.env.REACT_APP_DEFAULT_URL +
        "/api/gafetes-fisicos/gen-bulk/" +
        gafete.UniqueID
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.codigo == 200) {
          window.location.reload();
        } else {
          window.location.replace(
            process.env.REACT_APP_SELF_URL + "/gafetes-fisicos/" + hash + "/all"
          );
        }
      });
  };
  const selectAll = () => {
    let tmp_participantes = participantes;
    let tmp = filterdedData;
    tmp.map((actual, indice) => {
      actual.selected = true;
      const index_finder = (participante) =>
        participante.UniqueID == actual.UniqueID;
      if (tmp_participantes.findIndex(index_finder) >= 0) {
        let item = tmp_participantes[tmp_participantes.findIndex(index_finder)];
        item.selected = true;
        tmp_participantes[tmp_participantes.findIndex(index_finder)] = item;
      }
      if (indice == tmp.length - 1) {
        setParticipantes(tmp_participantes);
        setFilteredData(tmp);
        setCambios(cambios + 1);
      }
    });
  };
  const unSelectAll = () => {
    let tmp_participantes = participantes;
    let tmp = filterdedData;
    tmp.map((actual, indice) => {
      actual.selected = false;
      const index_finder = (participante) =>
        participante.UniqueID == actual.UniqueID;
      if (tmp_participantes.findIndex(index_finder) >= 0) {
        let item = tmp_participantes[tmp_participantes.findIndex(index_finder)];
        item.selected = false;
        tmp_participantes[tmp_participantes.findIndex(index_finder)] = item;
      }
      if (indice == tmp.length - 1) {
        setParticipantes(tmp_participantes);
        setFilteredData(tmp);
        setCambios(cambios + 1);
      }
    });
  };
  const genGafetePDFSelected = () => {
    let tmp = [];
    participantes.map((currnt, index) => {
      if (currnt.selected == true) {
        tmp.push(currnt.UniqueID);
      }
      if (index == participantes.length - 1) {
        if (tmp.length == 0) {
          alert("No se ha seleccionado participantes");
        } else {
          setCargando(true);
          setCambios(cambios + 1);
          let info = { UniqueID: gafete.UniqueID, participantes: tmp };
          const requestOptions = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(info),
          };
          fetch(
            process.env.REACT_APP_DEFAULT_URL +
              "/api/gafetes-fisicos/gen-selected",
            requestOptions
          )
            .then((res) => {
              return res.json();
            })
            .then((data) => {
              setCargando(false);
              setCambios(cambios + 1);
              if (data.codigo == 200) {
                window.location.reload();
              } else {
                window.location.replace(
                  process.env.REACT_APP_SELF_URL +
                    "/gafetes-fisicos/" +
                    hash +
                    "/all"
                );
              }
            });
        }
      }
    });
  };
  const sendGafeteElectronico = (id) => {
    setCargando(true);
    setCambios(cambios + 1);
    const info = { UniqueID: id };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(info),
    };
    fetch(
      process.env.REACT_APP_DEFAULT_URL +
        "/api/send/detalle_inscripcion/gafete",
      requestOptions
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.codigo == 200) {
          window.location.reload();
        } else {
          window.location.replace(
            process.env.REACT_APP_SELF_URL + "/gafetes-fisicos/" + hash + "/all"
          );
        }
      });
  };
  return (
    <Container fluid>
      <Row style={{ padding: "5%" }}>
        <Container>
          <Row
            style={{
              padding: "5%",
              borderBottom: "5px solid #1e3d52",
              borderLeft: "5px solid #1e3d52",
              backgroundColor: "rgb(230,230,230)",
            }}
          >
            <Col xs={12} md={6} lg={4}>
              <img
                loading="lazy"
                src={evento.flier}
                style={{ width: "100%" }}
                alt="arte"
              />
            </Col>
            <Col xs={12} md={6} lg={8} style={{ textAlign: "left" }}>
              <h1>GAFETE FÍSICO</h1>
              {evento != null && gafete != null && (
                <p>
                  <br />
                  <strong>EVENTO: </strong>
                  {evento.Nombre}
                  <br />
                  <strong>FECHA: </strong>
                  {evento.Fecha_Inicio}
                  <br />
                  <strong>DESCRIPCIÓN DE GAFETE: </strong>
                  {gafete.descripcion}
                </p>
              )}
              <Button
                variant="outline-success"
                onClick={genBulk}
                disabled={cargando}
              >
                Generar gafete a todos los inscritos
              </Button>
              <br />
              <br />
              <Button
                variant="outline-danger"
                onClick={deleteSelf}
                disabled={cargando}
              >
                Eliminar gafete
              </Button>
            </Col>
          </Row>
        </Container>
      </Row>
      {gafete != null && (
        <Row>
          <Col xs={12} md={12} lg={6} style={{}}>
            <h2>Vista previa de frente</h2>
            {gafete.frente != null && (
              <img
                src={
                  process.env.REACT_APP_DEFAULT_URL +
                  "/api/diplomas/getObject/" +
                  gafete.frente
                }
                style={{ width: "100%", padding: "5%" }}
                className="frame-diploma"
                alt="vista previa"
              />
            )}
          </Col>
          <Col xs={12} md={12} lg={6} style={{}}>
            <h2>Vista previa de reverso</h2>
            {gafete.reverso != null && (
              <img
                src={
                  process.env.REACT_APP_DEFAULT_URL +
                  "/api/diplomas/getObject/" +
                  gafete.reverso
                }
                style={{ width: "100%", padding: "5%" }}
                className="frame-diploma"
                alt="vista-previa"
              />
            )}
          </Col>
        </Row>
      )}
      <Row>
        <h2>Generar gafete para participantes seleccionados</h2>
      </Row>
      {participantes.length == 0 && (
        <Row style={{ padding: "2%" }}>
          <h3>No hay participantes registrados</h3>
        </Row>
      )}
      {participantes.length > 0 && (
        <Row>
          <Container fluid style={{ padding: "2%" }}>
            <Row>
              <Form.Group className="mb-3" controlId="descripcion">
                <Form.Label>Buscar participantes</Form.Label>
                <Form.Control
                  value={filterData}
                  type="text"
                  placeholder="Puede buscar por nombres, apellidos, correo electrónico, puesto e institución"
                  onChange={onChange}
                  name="filter"
                  disabled={cargando}
                />
              </Form.Group>
            </Row>
            <Row style={{ padding: "3%" }}>
              <Button
                variant="outline-primary"
                disabled={cargando}
                onClick={genGafetePDFSelected}
              >
                Generar gafete a seleccionados
              </Button>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={6} style={{ padding: "3%" }}>
                <Button
                  variant="outline-success"
                  disabled={cargando}
                  onClick={selectAll}
                >
                  Seleccionar todos
                </Button>
              </Col>
              <Col xs={12} md={12} lg={6} style={{ padding: "3%" }}>
                <Button
                  variant="outline-danger"
                  disabled={cargando}
                  onClick={unSelectAll}
                >
                  Quitar selección de todos
                </Button>
              </Col>
            </Row>
            <Row>
              <h1>Participantes: {filterdedData.length}</h1>
            </Row>
            <Row style={{ padding: "1%" }}>
              {filterdedData.map((actual, index) => (
                <Col
                  key={"participante-" + index}
                  xs={12}
                  md={6}
                  lg={3}
                  style={{ padding: "2%" }}
                >
                  <Card>
                    <Card.Header>
                      <Card.Title>
                        {actual.Nombres + " " + actual.Apellidos}
                      </Card.Title>
                    </Card.Header>
                    <Card.Body style={{ textAlign: "left" }}>
                      <Card.Text>
                        <strong>ID: </strong>
                        {actual.UniqueID}
                      </Card.Text>
                      <Card.Text>
                        <strong>Email: </strong>
                        {actual.Correo}
                      </Card.Text>
                      <Card.Text>
                        <strong>Institución: </strong>
                        {actual.institucion}
                      </Card.Text>
                      <Card.Text>
                        <strong>Puesto: </strong>
                        {actual.Puesto}
                      </Card.Text>
                      <Card.Text>
                        <strong>Pais: </strong>
                        {actual.pais}
                      </Card.Text>
                      <Container fluid>
                        <Row style={{ padding: "1%" }}>
                          <Button
                            variant="outline-secondary"
                            href={
                              process.env.REACT_APP_EBG_URL +
                              "/mi-gafete-electronico/" +
                              actual.hash
                            }
                            target="_blank"
                            disabled={cargando}
                          >
                            Ir a gafete electrónico
                          </Button>
                        </Row>
                        <Row style={{ padding: "1%" }}>
                          <Button
                            variant="outline-secondary"
                            onClick={() => {
                              sendGafeteElectronico(actual.UniqueID);
                            }}
                            disabled={cargando}
                          >
                            Enviar gafete electrónico
                          </Button>
                        </Row>
                      </Container>
                    </Card.Body>
                    <Card.Footer>
                      <Form.Check
                        checked={actual.selected}
                        onChange={onChange}
                        style={{ textAlign: "left" }}
                        type="checkbox"
                        name={"selected-" + actual.UniqueID}
                        label="Seleccionar"
                        disabled={cargando}
                      />
                    </Card.Footer>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        </Row>
      )}
    </Container>
  );
};

export default ViewGafetesFisicos;
