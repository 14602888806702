import React, { useState, useEffect } from 'react';
import { Modal, Spinner, Alert, Row, Tab, Col, ListGroup, Breadcrumb, Dropdown, Button } from "react-bootstrap";
//import DEFAULT_URL from '../../../../../api/url';
import DetalleCarpeta from './DetalleCarpeta';
import './ModalMaterial.css'
import FormCarpeta from './FormCarpeta';
const ModalMaterial = (props) => {
    const [contador, setContador] = useState(0);
    const [carpetas, setCarpetas] = useState([]);
    const [allCarpetas, setAllCarpetas] = useState('block');
    const [detalle, setDetalle] = useState('none');
    const [formCarpeta, setFormCarpeta] = useState('none');
    const [cargando, setCargando] = useState('none');
    const [mensaje, setMensaje] = useState('');
    const [consideracion, setConsideracion] = useState('');
    const [exito, setExito] = useState('none');
    const [exito_det, setExitoDet] = useState('none');
    const [error, setError] = useState('none');
    useEffect(() => {
        async function fetchData() {
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/material/carpetas/iniciales/' + props.evento_uniqueid, { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setCarpetas(data.rows);
                });
        }
        fetchData();
    }, [contador]);
    const entering = () => {
        setAllCarpetas('block');
        setDetalle('none');
        setContador(contador + 1);
    }
    const cargar = (msg, cnsdr) => {
        setFormCarpeta('none');
        setDetalle('none');
        setAllCarpetas('none');
        setError('none');
        setExito('none');
        setExitoDet('none');
        setCargando('block');
        setMensaje(msg);
        setConsideracion(cnsdr);
        setContador(contador + 1);
    }
    const ok = (msg, cnsdr) => {
        setCargando('none');
        setFormCarpeta('none');
        setDetalle('none');
        setAllCarpetas('none');
        setExitoDet('none');
        setError('none');
        setExito('block');
        setCargando('none');
        setMensaje(msg);
        setConsideracion(cnsdr);
        setContador(contador + 1);
    }
    const err = (msg, cnsdr) => {
        setCargando('none');
        setFormCarpeta('none');
        setDetalle('none');
        setAllCarpetas('none');
        setError('block');
        setExito('none');
        setExitoDet('none');
        setCargando('none');
        setMensaje(msg);
        setConsideracion(cnsdr);
        setContador(contador + 1);
    }
    const back = () => {
        setCargando('none');
        setFormCarpeta('none');
        setDetalle('none');
        setAllCarpetas('block');
        setError('none');
        setExito('none');
        setCargando('none');
        setMensaje('');
        setExitoDet('none');
        setConsideracion('');
        setContador(contador + 1);
    }
    const back_det = () => {
        setCargando('none');
        setFormCarpeta('none');
        setDetalle('block');
        setAllCarpetas('none');
        setError('none');
        setExito('none');
        setCargando('none');
        setExitoDet('none');
        setMensaje('');
        setConsideracion('');
        setContador(contador + 1);
    }
    const ok_det = (msg, cnsdr) => {
        setCargando('none');
        setFormCarpeta('none');
        setDetalle('none');
        setAllCarpetas('none');
        setError('none');
        setExito('none');
        setCargando('none');
        setExitoDet('block');
        setMensaje(msg);
        setConsideracion(cnsdr);
        setContador(contador + 1);
    }
    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
            onEnter={entering}
            variant="light"
            backdrop='static'
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter" className="font-bold">
                    Material Del Evento
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tab.Container id="list-group-tabs-example">
                    <Row style={{ display: allCarpetas }}>
                        <Col xs={12} md={12} lg={12} style={{ marginBottom: '45px' }}>
                            <Row>
                                <Breadcrumb variant="dark">
                                    <Breadcrumb.Item active>{props.nombre_evento}</Breadcrumb.Item>
                                    <Breadcrumb.Item active>Material</Breadcrumb.Item>
                                    <Breadcrumb.Item active>Carpetas</Breadcrumb.Item>
                                </Breadcrumb>
                            </Row>
                            <Row>
                                <Col xs={12} md={6} lg={4}>
                                    <Row>
                                        <Dropdown>
                                            <Dropdown.Toggle variant="dark" id="dropdown-basic">
                                                Acciones
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {(props.rol != 1 && props.rol != 6) &&
                                                    <Dropdown.Item onClick={() => { setAllCarpetas('none'); setDetalle('none'); setFormCarpeta('block'); setContador(contador + 1); }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-folder-plus" viewBox="0 0 16 16">
                                                            <path d="m.5 3 .04.87a1.99 1.99 0 0 0-.342 1.311l.637 7A2 2 0 0 0 2.826 14H9v-1H2.826a1 1 0 0 1-.995-.91l-.637-7A1 1 0 0 1 2.19 4h11.62a1 1 0 0 1 .996 1.09L14.54 8h1.005l.256-2.819A2 2 0 0 0 13.81 3H9.828a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 6.172 1H2.5a2 2 0 0 0-2 2zm5.672-1a1 1 0 0 1 .707.293L7.586 3H2.19c-.24 0-.47.042-.683.12L1.5 2.98a1 1 0 0 1 1-.98h3.672z" />
                                                            <path d="M13.5 10a.5.5 0 0 1 .5.5V12h1.5a.5.5 0 1 1 0 1H14v1.5a.5.5 0 1 1-1 0V13h-1.5a.5.5 0 0 1 0-1H13v-1.5a.5.5 0 0 1 .5-.5z" />
                                                        </svg>
                                                        &nbsp;&nbsp;&nbsp;Nueva Carpeta
                                                    </Dropdown.Item>
                                                }
                                                <Dropdown.Item onClick={() => setContador(contador + 1)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                                        <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                                                        <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                                                    </svg>
                                                    &nbsp;&nbsp;&nbsp;Refrescar
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <ListGroup style={{ textAlign: 'left' }}>
                            <Col xs={12} md={12} lg={12}>
                                <Row>
                                    {carpetas.map(carpeta =>
                                        <Col xs={12} md={12} lg={4} style={{ marginBottom: '25px' }} onClick={() => { setAllCarpetas('none'); setDetalle('block'); setContador(contador + 1); }}>
                                            <ListGroup.Item variant="dark" key={carpeta.UniqueID} action href={"#" + carpeta.UniqueID}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-folder-fill" viewBox="0 0 16 16">
                                                    <path d="M9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.825a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31L.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3zm-8.322.12C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139z" />
                                                </svg>
                                                &nbsp;&nbsp;&nbsp;&nbsp;{carpeta.Nombre}
                                            </ListGroup.Item>
                                        </Col>
                                    )}
                                </Row>
                            </Col>
                        </ListGroup>
                    </Row>
                    <Row style={{ display: detalle }}>
                        <Tab.Content style={{ height: '100%' }}>
                            {carpetas.map(carpeta => <Tab.Pane key={carpeta.UniqueID} eventKey={"#" + carpeta.UniqueID} style={{ height: '100%' }}><DetalleCarpeta carpeta={carpeta.UniqueID} permiso={carpeta.permiso} nombre_carpeta={carpeta.Nombre} correlativo={carpeta.Correlativo} refrescar={() => setContador(contador + 1)} rol={props.rol} back={() => { setAllCarpetas('block'); setDetalle('none'); setContador(contador + 1); }} nombre_evento={props.nombre_evento} cargar={cargar} ok={ok} err={err} back_det={back_det} ok_det={ok_det} /></Tab.Pane>)}
                        </Tab.Content>
                    </Row>
                    <Row style={{ display: formCarpeta }}>
                        <FormCarpeta refrescar={() => setContador(contador + 1)} evento_UniqueID={props.evento_uniqueid} back={back} cargar={cargar} ok={ok} err={err} />
                    </Row>
                    <Row style={{ display: cargando }}>
                        <Button variant="dark" disabled>
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            &nbsp;&nbsp;&nbsp;{mensaje}
                            <p>{consideracion}</p>
                        </Button>
                    </Row>
                    <Row style={{ display: exito }}>
                        <Col xs={12} md={12} lg={12} style={{ marginBottom: '25px' }}>
                            <Row>
                                <Button variant="dark" onClick={back}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-backspace-fill" viewBox="0 0 16 16">
                                        <path d="M15.683 3a2 2 0 0 0-2-2h-7.08a2 2 0 0 0-1.519.698L.241 7.35a1 1 0 0 0 0 1.302l4.843 5.65A2 2 0 0 0 6.603 15h7.08a2 2 0 0 0 2-2V3zM5.829 5.854a.5.5 0 1 1 .707-.708l2.147 2.147 2.146-2.147a.5.5 0 1 1 .707.708L9.39 8l2.146 2.146a.5.5 0 0 1-.707.708L8.683 8.707l-2.147 2.147a.5.5 0 0 1-.707-.708L7.976 8 5.829 5.854z" />
                                    </svg>&nbsp;&nbsp;&nbsp;Atrás
                                </Button>
                            </Row>
                        </Col>
                        <Col xs={12} md={12} lg={12}>
                            <Row>
                                <Alert variant="success">
                                    <Alert.Heading>Operación realizada con éxito</Alert.Heading>
                                    <p>
                                        {mensaje}
                                    </p>
                                    <hr />
                                    <p className="mb-0">
                                        {consideracion}
                                    </p>
                                </Alert>
                            </Row>
                        </Col>
                    </Row>
                    <Row style={{ display: exito_det }}>
                        <Col xs={12} md={12} lg={12} style={{ marginBottom: '25px' }}>
                            <Row>
                                <Button variant="dark" onClick={back_det}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-backspace-fill" viewBox="0 0 16 16">
                                        <path d="M15.683 3a2 2 0 0 0-2-2h-7.08a2 2 0 0 0-1.519.698L.241 7.35a1 1 0 0 0 0 1.302l4.843 5.65A2 2 0 0 0 6.603 15h7.08a2 2 0 0 0 2-2V3zM5.829 5.854a.5.5 0 1 1 .707-.708l2.147 2.147 2.146-2.147a.5.5 0 1 1 .707.708L9.39 8l2.146 2.146a.5.5 0 0 1-.707.708L8.683 8.707l-2.147 2.147a.5.5 0 0 1-.707-.708L7.976 8 5.829 5.854z" />
                                    </svg>&nbsp;&nbsp;&nbsp;Atrás
                                </Button>
                            </Row>
                        </Col>
                        <Col xs={12} md={12} lg={12}>
                            <Row>
                                <Alert variant="success">
                                    <Alert.Heading>Operación realizada con éxito</Alert.Heading>
                                    <p>
                                        {mensaje}
                                    </p>
                                    <hr />
                                    <p className="mb-0">
                                        {consideracion}
                                    </p>
                                </Alert>
                            </Row>
                        </Col>
                    </Row>
                    <Row style={{ display: error }}>
                        <Alert variant="danger">
                            <Alert.Heading>Ocurrió un error</Alert.Heading>
                            <p>
                                {mensaje}
                            </p>
                            <hr />
                            <p className="mb-0">
                                {consideracion}
                            </p>
                        </Alert>
                    </Row>
                </Tab.Container>
            </Modal.Body >
            <Modal.Footer style={{ display: allCarpetas }}>
                <Button variant="secondary" className='font-bold' onClick={props.onHide} >Cerrar</Button>
            </Modal.Footer>
            <Modal.Footer style={{ display: detalle }}>
                <Button variant="secondary" className='font-bold' onClick={props.onHide} >Cerrar</Button>
            </Modal.Footer>
            <Modal.Footer style={{ display: formCarpeta }}>
                <Button variant="secondary" className='font-bold' onClick={props.onHide} >Cerrar</Button>
            </Modal.Footer>
            <Modal.Footer style={{ display: error }}>
                <Button variant="secondary" className='font-bold' onClick={props.onHide} >Cerrar</Button>
            </Modal.Footer>
            <Modal.Footer style={{ display: exito }}>
                <Button variant="secondary" className='font-bold' onClick={() => { props.onHide(); }} >Cerrar</Button>
            </Modal.Footer>
            {/*<ModalNuevaCarpeta show={modalNuevaCarpeta} onHide={hideModalNuevaCarpeta} refrescar={() => setContador(contador + 1)} evento_UniqueID={props.evento_uniqueid} />*/}
        </Modal >
    );
}

export default ModalMaterial