import React, { Component } from 'react';
import { Row, Col, Form, Container, Accordion } from "react-bootstrap";
import "./Participante.css"

export class Participante extends Component {
    render() {
        return (
            <Accordion.Item className="bg-light">
                <Accordion.Header>{this.props.participante.Nombres + " " + this.props.participante.Apellidos}</Accordion.Header>
                <Accordion.Body>
                    <Container>
                        <Row >
                            <div style={{ cursor: 'pointer', position: 'relative', left: '98%', width: '10%' }} data-toggle="tooltip" data-placement="top" title="Eliminar Participante" onClick={this.props.deleteparticipante.bind(this, this.props.participante.No)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3-fill" viewBox="0 0 16 16">
                                    <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                                </svg>
                            </div>
                        </Row>
                        <Row>
                            <Col xs={12} md={12} lg={12} style={{ height: '100%' }}>
                                <Form.Group className="mb-3" controlId={"pais-" + this.props.participante.No}>
                                    <Form.Label>País</Form.Label>
                                    <Form.Control as="select" required aria-label="Pais" name={"pais-" + this.props.participante.No} onChange={this.props.eventocambio.bind(this)} value={this.props.participante.Pais}>
                                        <option value="" key="0">Seleccione el país</option>
                                        {this.props.paises.map(pais => <option key={pais.id} value={pais.nombre}>{pais.nombre}</option>)}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6} lg={6}>
                                <Form.Group className="mb-3" controlId={"nombres-" + this.props.participante.No}>
                                    <Form.Label>Nombres</Form.Label>
                                    <Form.Control required type="text" value={this.props.participante.Nombres} name={"nombres-" + this.props.participante.No} placeholder="Ingrese los nombres" onChange={this.props.eventocambio.bind(this)} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6} lg={6}>
                                <Form.Group className="mb-3" controlId={"apellidos-" + this.props.participante.No}>
                                    <Form.Label>Apellidos</Form.Label>
                                    <Form.Control required type="text" value={this.props.participante.Apellidos} name={"apellidos-" + this.props.participante.No} placeholder="Ingrese los apellidos" onChange={this.props.eventocambio.bind(this)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-3" controlId={"puesto-" + this.props.participante.No}>
                                    <Form.Label>Puesto</Form.Label>
                                    <Form.Control required type="text" value={this.props.participante.Puesto} name={"puesto-" + this.props.participante.No} placeholder="Ingrese el puesto" onChange={this.props.eventocambio.bind(this)} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-3" controlId={"correo-" + this.props.participante.No}>
                                    <Form.Label>Correo Electrónico</Form.Label>
                                    <Form.Control required type="email" value={this.props.participante.Correo} name={"correo-" + this.props.participante.No} placeholder="Ingrese el correo" onChange={this.props.eventocambio.bind(this)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-3" controlId={"telefono-" + this.props.participante.No}>
                                    <Form.Label>Teléfono y extensión</Form.Label>
                                    <Form.Control required type="text" value={this.props.participante.Tel} name={"telefono-" + this.props.participante.No} placeholder="Ingrese el teléfono" onChange={this.props.eventocambio.bind(this)} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-3" controlId={"celular-" + this.props.participante.No}>
                                    <Form.Label>Celular</Form.Label>
                                    <Form.Control type="text" value={this.props.participante.Celular} name={"celular-" + this.props.participante.No} placeholder="Ingrese el número de celular" onChange={this.props.eventocambio.bind(this)} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Container>
                </Accordion.Body>
            </Accordion.Item>
        );
    }
}
