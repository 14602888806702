import React, { useState, useEffect } from 'react';
import { Row, Button, Col, Table, Badge, Container, Alert } from "react-bootstrap";
import { IngresarCalificacion } from './IngresarCalificacion';
import { ErrorModal } from '../../../../Utils/ErrorModal/ErrorModal';
import { SuccessModal } from '../../../../Utils/SuccessModal/SuccessModal';
import { Confirmacion } from '../../../../Utils/ConfirmationModal/Confirmacion';
import { EditarCalificacion } from './EditarCalificacion';
//import DEFAULT_URL from '../../../../../api/url';
const Entregable = (props) => {
    const [detalle, setDetalle] = useState({});
    const [entregables, setEntregables] = useState([]);
    const [calificacion, setCalificacion] = useState(false);
    const [mensajeConfirmacion, setMensajeConfirmacion] = useState(false);
    const [id_actual, setId_actual] = useState('');
    const [indice_actual, setIndice_actual] = useState(0);
    const [modalSucc, setModalSucc] = useState(false);
    const [modalError, setModalError] = useState(false);
    const [mensaje, setMensaje] = useState('');
    const [consideracion, setConsideracion] = useState('');
    const [entregable_actual, setEntregable_actual] = useState({});
    const [modalEdit, setModalEdit] = useState(false);
    const [nota_Calificada, setNota_Calificada] = useState('');
    const [contador, setContador] = useState(0);
    const [bandera, setBandera] = useState(0);
    useEffect(() => {
        async function fetchData() {
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/registro_academico/detalle/' + props.registro_academico + '/' + props.actividad, { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setDetalle(data.rows[0]);
                    setEntregables(data.entregable);
                    if (data.entregable.length > 0) {
                        setNota_Calificada(data.calificacion[0].Ponderacion);
                    }
                });
        }
        fetchData();
    }, [contador]);

    useEffect(() => {
    }, [bandera])


    const ocultarCalificacion = () => {
        setCalificacion(false);
    }

    const ocultar = () => {
        setModalSucc(false);
        setModalError(false);
        setContador(contador + 1);
    }

    const ocultarEdit = () => {
        setModalEdit(false);
        setBandera(bandera + 1);
    }
    const changeHandler = (evt) => {
        var arr_name = evt.target.name.split("-");
        const index_finder = (entregable) => entregable.UniqueID == arr_name[1];
        var new_array = entregables;
        switch (arr_name[0]) {
            case "nota":
                new_array[new_array.findIndex(index_finder)].Ponderacion = evt.target.value;
                setEntregables(new_array);
                break;
            case "comentario":
                new_array[new_array.findIndex(index_finder)].Comentario = evt.target.value;
                setEntregables(new_array);
                break;
        }
        setBandera(bandera + 1);
    }

    const submitEdit = (evt) => {
        evt.preventDefault();
        const uniqueid = evt.target.name.split("-")[1];
        const index_finder = (entregable) => entregable.UniqueID == uniqueid;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(entregables[entregables.findIndex(index_finder)])
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/update/registro_academico/entregable', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data_ => {
                if (data_.codigo == 200) {
                    setMensaje('Calificación Actualizada Exitosamente');
                    setConsideracion('');
                    setModalSucc(true);
                } else {
                    setMensaje(data_.respuesta.sqlState + ": " + data_.respuesta.sqlMessage);
                    setModalError(true);
                }
            });
        ocultarEdit();
    }
    const calificar = (data) => {
        const newNota = { Ponderacion: data.nota, Comentario: data.comentario, Fecha: new Date().toLocaleDateString(), detalle_academico_UniqueID: detalle.UniqueID };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(newNota)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/create/registro_academico/entregable', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data_ => {
                if (data_.codigo == 200) {
                    setMensaje('Calificación Registrada Exitosamente');
                    setContador(contador + 1);
                    setConsideracion('');
                    setModalSucc(true);
                } else {
                    setMensaje(data_.respuesta.sqlState + ": " + data_.respuesta.sqlMessage);
                    setModalError(true);
                }
            });
    }

    const ocultarConfirmacion = () => {
        setMensajeConfirmacion(false);
    }

    const accioneliminarentregable = (id, indice) => {
        const cuerpo = { UniqueID: id };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(cuerpo)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/delete/registro_academico/entregable', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data_ => {
                if (data_.codigo == 200) {
                    setContador(contador + 1);
                    setMensaje('Calificación Eliminada Exitosamente');
                    setConsideracion('');
                    setModalSucc(true);
                } else {
                    setMensaje(data_.respuesta.sqlState + ": " + data_.respuesta.sqlMessage);
                    setModalError(true);
                }
                setContador(contador + 1);
            });

        ocultarConfirmacion();
    }

    const eliminarentregable = (id, indice) => {
        var confirmatinomessage = "¿Está segur@ que desea elimnar este entregable?";
        setConsideracion('Esta acción no se puede revertir');
        setMensaje(confirmatinomessage);
        setId_actual(id);
        setIndice_actual(indice);
        setMensajeConfirmacion(true);
    }

    return (
        <Container>
            {entregables.length == 0 &&
                <Row>
                    <Alert variant="light">
                        <Alert.Heading>¡Sin Calificación!</Alert.Heading>
                        <p>
                            {'El/La participante ' + props.nombres + ' no posee calificación registrada para la actividad.'}
                        </p>
                        <hr />
                    </Alert>
                </Row>}
            {entregables.length > 0 &&
                <Row>
                    <h4>
                        Nota Obtenida: <Badge bg="primary">{nota_Calificada}</Badge>
                    </h4>
                </Row>}
            {entregables.length > 0 && entregables.map((entregable, index) =>
                <Row>
                    <Container key={entregable.UniqueID}>
                        <Row style={{ marginBottom: '20px' }}>
                            <Col xs={1} md={1}>
                                <div style={{ cursor: 'pointer', width: '20%' }} data-toggle="tooltip" data-placement="top" title="Editar Calificación" onClick={() => { setModalEdit(true); setEntregable_actual(entregable); }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-fill" viewBox="0 0 16 16">
                                        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                    </svg>
                                </div>
                            </Col>
                            <Col xs={1} md={1}>
                                <div style={{ cursor: 'pointer', width: '20%' }} data-toggle="tooltip" data-placement="top" title="Eliminar Calificación" onClick={() => eliminarentregable(entregable.UniqueID, index)} >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3-fill" viewBox="0 0 16 16">
                                        <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                                    </svg>
                                </div>
                            </Col>
                        </Row>
                        <Row >
                            <Table striped bordered hover size="sm" responsive variant="light">
                                <thead>
                                    <tr>
                                        <th>Fecha</th>
                                        <th>Nota</th>
                                        <th>Comentario</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{entregable.Fecha}</td>
                                        <td>{entregable.Ponderacion}</td>
                                        <td>{entregable.Comentario}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Row>
                    </Container>
                </Row>
            )}
            <IngresarCalificacion
                show={calificacion}
                onHide={ocultarCalificacion}
                ponderacion={props.ponderacion}
                calificar={calificar}
            />
            <SuccessModal
                color={"#d3d3d4"}
                show={modalSucc}
                onHide={ocultar}
                mensaje={mensaje}
                consideracion={consideracion}
                enlace={''}
                llevaenlace={false}
            />
            <ErrorModal
                color={"#d3d3d4"}
                show={modalError}
                onHide={ocultar}
                mensaje={mensaje}
                consideracion={consideracion}
            />

            <EditarCalificacion
                ponderacion={props.ponderacion}
                entregable={entregable_actual}
                show={modalEdit}
                onHide={ocultarEdit}
                changeHandler={changeHandler}
                submit={submitEdit}
            />

            <Confirmacion
                show={mensajeConfirmacion}
                onHide={ocultarConfirmacion}
                accion={accioneliminarentregable}
                mensaje={mensaje}
                uniqueid={id_actual}
                consideracion={consideracion}
                param={indice_actual}
            />
        </Container>
    );

}

export default Entregable