import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Formulario from "../../helpers/form";
const helpers = require("../../helpers/helpers");
const FormCliente = () => {
  const [contador, setContador] = useState(0);
  const [formConfig, setFormConfig] = useState(null);
  useEffect(() => {
    helpers
      .getData("/api/temas/read")
      .then((response_temas) => {
        helpers
          .getData("/api/read/asesor")
          .then((response_asesores) => {
            setFormConfig({
              title: "Registro de propuesta de diseño",
              submitTitle: "Registrar propuesta",
              customSubmit: true,
              formConfig: [
                {
                  name: "grado_academico",
                  type: 3,
                  options: [
                    { label: "Licenciado", value: "Licenciado" },
                    { label: "Licenciada", value: "Licenciada" },
                    { label: "Doctor", value: "Doctor" },
                    { label: "Doctora", value: "Doctora" },
                    { label: "Ingeniero", value: "Ingeniero" },
                    { label: "Ingeniera", value: "Ingeniera" },
                    { label: "Arquitecto", value: "Arquitecto" },
                    { label: "Arquitecta", value: "Arquitecta" },
                  ],
                  required: true,
                  title: "Grado académico del solicitante",
                  placeholder: "Selecciona un grado académico",
                  value: "",
                },
                {
                  name: "nombre",
                  type: 1,
                  required: true,
                  title: "Nombre de solicitante",
                  placeholder: "Ingrese el nombre de solicitante",
                  value: "",
                },
                {
                  name: "puesto",
                  type: 1,
                  required: true,
                  title: "Puesto del solicitante",
                  placeholder: "Ingrese el puesto del solicitante",
                  value: "",
                },
                {
                  name: "correo",
                  type: 1,
                  required: true,
                  title: "Correo electrónico del solicitante",
                  placeholder: "Ingrese el correo electrónico del solicitante",
                  value: "",
                },
                {
                  name: "telefono",
                  type: 1,
                  required: true,
                  title: "Teléfono del solicitante",
                  placeholder: "Ingrese número de teléfono del solicitante",
                  value: "",
                },
                {
                  name: "institucion",
                  type: 1,
                  required: true,
                  title: "Institución solicitante",
                  placeholder: "Ingrese la institución solicitante",
                  value: "",
                },
                {
                  name: "asesor_UniqueID",
                  type: 3,
                  options: response_asesores.data.rows
                    .filter(
                      (asesor) =>
                        asesor.UniqueID != 28 &&
                        asesor.UniqueID != 27 &&
                        asesor.UniqueID != 33 &&
                        asesor.UniqueID != 34 &&
                        asesor.UniqueID != 35 &&
                        asesor.UniqueID != 36
                    )
                    .map((actual) => {
                      return {
                        label: actual.Nombres + " " + actual.Apellidos,
                        value: actual.UniqueID,
                      };
                    }),
                  required: true,
                  title: "Asesor que le atiende",
                  placeholder: "Selecciona un Asesor Comercial",
                  value: "",
                },
                {
                  name: "archivo",
                  type: 6,
                  required: true,
                  title: "PDF a adjuntar en propuesta",
                  placeholder: "Ingrese lo solicitado",
                  value: "",
                },
                {
                  name: "horas",
                  type: 7,
                  required: true,
                  title: "Horas estimadas del evento",
                  placeholder: "Ingrese las horas estimadas del evento",
                  step: 0.1,
                  value: "",
                },
                {
                  name: "temaPropuesto",
                  type: 1,
                  required: true,
                  title: "Tema propuesto",
                  placeholder: "Ingrese el tema propuesto",
                  value: "",
                },
                {
                  name: "perfil",
                  type: 1,
                  required: true,
                  title: "Perfil de participante",
                  placeholder: "Ingrese el perfil de los participantes",
                  value: "",
                },
                {
                  name: "participantes",
                  type: 7,
                  required: true,
                  title: "No. de participantes",
                  step: 0,
                  placeholder: "Ingrese el número de participantes",
                  value: "",
                },
                {
                  name: "horario",
                  type: 1,
                  required: true,
                  title: "Horario propuesto",
                  placeholder: "Ingrese el horario del evento",
                  value: "",
                },
                {
                  name: "fechaPropuesta",
                  type: 5,
                  required: true,
                  title: "Fecha propuesta",
                  placeholder: "Ingrese la fecha del evento",
                  value: "",
                },
                {
                  name: "servicio",
                  type: 1,
                  required: true,
                  title: "Servicio",
                  placeholder: "Ingrese el servicio a brindar",
                  value: "",
                },
                {
                  name: "lugar",
                  type: 8,
                  required: true,
                  title: "Lugar a ejecutar evento",
                  placeholder: "Ingrese el lugar donde se ejecutará el evento",
                  value: "",
                },
                {
                  name: "descripcion_general",
                  type: 8,
                  required: true,
                  title: "Descripción general del curso",
                  placeholder: "Ingrese lo solicitado",
                  value: "",
                },
                {
                  name: "comentarios",
                  type: 8,
                  required: true,
                  title: "Comentarios adicionales",
                  placeholder: "Ingrese comentarios adicionales",
                  value: "",
                },
                {
                  name: "objetivos",
                  type: 10,
                  title: "Objetivos del programa",
                  subtitle:
                    "Acá puede redactar y adjuntar los objetivos del programa de la propuesta de evento INHOUSE",
                  value: [],
                  field: {
                    name: "objetivo",
                    type: 8,
                    required: true,
                    title: "Objetivo del programa",
                    placeholder: "Ingresa un objetivo del programa",
                    value: "",
                  },
                },
                {
                  name: "metodologia",
                  type: 10,
                  title: "Metodología",
                  subtitle:
                    "Acá puede redactar y adjuntar la metodología activo/participativa",
                  value: [],
                  field: {
                    name: "metodologia",
                    type: 8,
                    required: true,
                    title: "Metodología",
                    placeholder: "Ingresa una metodología",
                    value: "",
                  },
                },
                {
                  name: "contenido",
                  type: 11,
                  title: "Contenidos a desarrollar",
                  subtitle:
                    "Acá puede ingresar los contenidos a desarrollar en el evento por unidades",
                  item_name: "contenido",
                  head: [
                    {
                      name: "nombre",
                      type: 1,
                      required: true,
                      title: "Nombre de unidad",
                      placeholder: "Ingrese el nombre de la unidad",
                      value: "",
                    },
                    {
                      name: "duracion",
                      type: 7,
                      step: 0,
                      required: true,
                      title: "Duracion de la unidad en horas",
                      placeholder: "Ingrese la duración de la unidad en horas",
                      value: "",
                    },
                  ],
                  fields: [
                    {
                      name: "tema",
                      type: 3,
                      options: response_temas.data.salida.map((actual) => {
                        return {
                          label: actual.nombre,
                          value: actual.UniqueID,
                        };
                      }),
                      required: true,
                      title: "Tema de oferta formativa",
                      placeholder:
                        "Selecciona un tema de la oferta formativa de EBG",
                      value: "",
                    },
                  ],
                  value: [],
                },
              ],
            });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [contador]);

  const afterSubmit = (formConfig) => {
    return new Promise((resolve, reject) => {
      let body = {};
      formConfig.formConfig.map((actual, indice) => {
        body[actual.name] = actual.value;
        if (indice == formConfig.formConfig.length - 1) {
          body.costo = 0;
          body.honorarios = 0;
          body.descripcion_paquete = `Constancia de participación digital, emitida por Asociación Bancaria
          de Guatemala - ABG- y Escuela Bancaria de Guatemala –EBG-. A
          requerimiento puede incorporarse el logotipo de Entidad. Material de
          apoyo que se requiera para el desarrollo del Programa en medio
          virtual. Se programarán 4 grupos con contenidos especificados en la
          presente propuesta. No incluye grabaciones del evento, las cuales
          tienen un costo adicional.`;
          if (
            body.objetivos.length == 0 ||
            body.metodologia.length == 0 ||
            body.contenido.length == 0
          ) {
            reject("Falta información");
          } else {
            helpers
              .postData("/api/create/propuesta", body)
              .then((data) => {
                window.location.href =
                  process.env.REACT_APP_SELF_URL + "/in-house/outlet";
                resolve(data);
              })
              .catch((error) => {
                reject(error);
              });
          }
        }
      });
    });
  };
  return (
    <Container fluid>
      <Row style={{ padding: "1%" }}>
        <Container
          fluid
          style={{
            boxShadow: "10px 10px 56px 10px rgba(30,61,82,0.91)",
            borderRadius: "2em",
          }}
        >
          <Row style={{ display: "flex", placeItems: "center", padding: "5%" }}>
            <Col xs={12} md={12} lg={6}>
              <img
                src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/1674081393254-LOGOS+azul.png"
                style={{ width: "100%" }}
              />
            </Col>
            <Col xs={12} md={12} lg={6}>
              <h1 style={{ textAlign: "justify" }}>
                FORMULARIO PARA PROPUESTAS DE DISEÑO DE PROGRAMAS IN HOUSE
              </h1>
              <p style={{ textAlign: "justify" }}>
                <strong>INSTRUCCIONES: </strong>Reciba un cordial saludo de
                parte de la Escuela Bancaria de Guatemala. Con el propósito de
                brindarles un mejor servicio en las solicitudes de Formación
                Profesional IN HOUSE para su personal, por favor llenar los
                siguientes datos, para elaborar la propuesta solicitada.
                <br />
              </p>
              <a
                style={{ textAlign: "justify" }}
                href="https://ebg.edu.gt/educacion-y-formacion"
                target="_blank"
              >
                Ver oferta formativa
              </a>
            </Col>
          </Row>
        </Container>
      </Row>
      <Row className="mt-5" style={{ padding: "1%" }}>
        {formConfig != null && (
          <Formulario formConfig={formConfig} afterSubmit={afterSubmit} />
        )}
      </Row>
    </Container>
  );
};

export default FormCliente;
