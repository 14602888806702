import React, { Component } from 'react';
import { Row, Button, Modal, Col, Form, Alert, Container } from "react-bootstrap";
import { ModalCargando } from '../../../Utils/Modal-Cargando/ModalCargando';
import { ErrorModal } from '../../../Utils/ErrorModal/ErrorModal';
//import DEFAULT_URL from '../../../../api/url';
import { SuccessModal } from '../../../Utils/SuccessModal/SuccessModal';
import axios from 'axios';
//import { DOWNLOAD_URL }} from '../../../../api/url';
export class ModalEditarPropuesta extends Component {
    state = {
        Nombre: '',
        Puesto: '',
        Correo: '',
        Telefono: '',
        Institucion: '',
        Archivo: '',
        selectedFile: null,
        loaded: 0,
        mensajeCargando: '',
        modalCargando: false,
        modalError: false,
        Mensaje: '',
        modalSucc: false,
        Consideracion: '',
        uniqueID: '',
        cartera: [],
        propuesta: ''
    };
    changeSeleccion = (id) => {
        let seleccionado = this.state.cartera.find(cliente => cliente.Empresa_UniqueID == id);
        this.setState({ Nombre: seleccionado.ResponsableInscripcion, Correo: seleccionado.CorreoResponsable, Telefono: seleccionado.TelefonoResponsable, Institucion: seleccionado.Empresa });
    }
    ocultar = () => {
        this.setState({ modalError: false, modalSucc: false });
        this.props.onHide();
    }
    ocultarModalCargando = () => {
        this.setState({ modalCargando: false });
    }
    onChange = (evt) => {
        if (evt.target.name == 'empresa') {
            this.changeSeleccion(evt.target.value);
        } else {
            this.setState({ [evt.target.name]: evt.target.value });
        }
    }
    submit = (evt) => {
        evt.preventDefault();
        if (this.state.selectedFile != null) {
            const data = new FormData();
            data.append('file', this.state.selectedFile);
            this.setState({ modalCargando: true, mensajeCargando: 'Se está subiendo el archivo al servidor, por favor espere.' })
            axios.post(process.env.REACT_APP_DEFAULT_URL+ "/api/files/upload", data, {
            }).then(res => {
                if (res.status == 200) {
                    const info = {
                        Path: res.data.res,
                        Nombre: this.state.Nombre,
                        Puesto: this.state.Puesto,
                        Correo: this.state.Correo,
                        Telefono: this.state.Telefono,
                        Institucion: this.state.Institucion,
                        Archivo: process.env.REACT_APP_DOWNLOAD_URL + res.data.res,
                        UniqueID: this.state.propuesta
                    };
                    const requestOptions = {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(info)
                    };
                    fetch(process.env.REACT_APP_DEFAULT_URL + '/api/update/propuesta', requestOptions)
                        .then((res) => {
                            return res.json();
                        })
                        .then(data => {
                            if (data.codigo == 200) {
                                this.setState({ modalCargando: false });
                                this.setState({ Mensaje: 'Propuesta actualizada exitosamente' });
                                this.setState({ modalSucc: true });
                                this.setState({});
                            } else {
                                this.setState({ Mensaje: data.respuesta });
                                this.setState({ modalError: true });
                            }
                        });
                } else {
                    this.setState({ Mensaje: res.data });
                    this.setState({ modalError: true });
                }
            });
        } else {
            const info = {
                Nombre: this.state.Nombre,
                Puesto: this.state.Puesto,
                Correo: this.state.Correo,
                Telefono: this.state.Telefono,
                Institucion: this.state.Institucion,
                UniqueID: this.state.propuesta
            };
            console.log(info);
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(info)
            };
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/update_no_archivo/propuesta', requestOptions)
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    if (data.codigo == 200) {
                        this.setState({ modalCargando: false });
                        this.setState({ Mensaje: 'Propuesta actualizada exitosamente' });
                        this.setState({ modalSucc: true });
                        this.setState({});
                    } else {
                        this.setState({ Mensaje: data.respuesta });
                        this.setState({ modalError: true });
                    }
                });
        }
    }
    onChangeHandler = (event) => {
        this.setState({ selectedFile: event.target.files[0], loaded: 0 });
    };
    entering = () => {
        this.setState({
            Nombre: this.props.propuesta.nombre,
            Puesto: this.props.propuesta.puesto,
            Correo: this.props.propuesta.correo,
            Telefono: this.props.propuesta.telefono,
            Institucion: this.props.propuesta.institucion,
            Archivo: this.props.propuesta.archivo,
            selectedFile: null,
            loaded: 0,
            mensajeCargando: '',
            modalCargando: false,
            modalError: false,
            Mensaje: '',
            modalSucc: false,
            Consideracion: '',
            uniqueID: '',
            propuesta: this.props.propuesta.UniqueID
        });
        console.log(this.state);
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/auth/userinfo', {cache: "no-cache",  credentials: 'include' })
            .then((res) => {
                return res.json();
            })
            .then(data => {
                this.setState({ uniqueID: data.UniqueID });
                fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/cartera/' + data.UniqueID, { cache: "no-cache" })
                    .then((res) => {
                        return res.json();
                    })
                    .then(data => {
                        this.setState({ cartera: data.rows });
                    });
            });
    }
    render() {
        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
                onEnter={this.entering}
            >
                <Modal.Header closeButton style={{ backgroundColor: "#b5bad0" }}>
                    <Modal.Title id="contained-modal-title-vcenter" className="font-bold">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                        </svg>
                        &nbsp;&nbsp;&nbsp;Actualizar propuesta in house
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: "#b5bad0" }} className="cuerpo-modal-nuevo-cliente">
                    <Container>
                        <Form onSubmit={this.submit}>
                            <Row>
                                <Col xs={12} md={12}>
                                    <Form.Group className="mb-3" controlId="empresa">
                                        <Form.Label>Empresa</Form.Label>
                                        <Form.Control as="select" aria-label="Empresa" name="empresa" onChange={this.onChange}>
                                            <option value="" key="0">Seleccione el cliente (si ya existe en cartera)</option>
                                            {this.state.cartera.map(cliente => <option key={cliente.Empresa_UniqueID} value={cliente.Empresa_UniqueID}>{cliente.Empresa}</option>)}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12}>
                                    <Form.Group className="mb-3" controlId="Nombre">
                                        <Form.Label>Nombre de la persona que solicita el evento</Form.Label>
                                        <Form.Control required type="text" placeholder="Ingrese el nombre del solicitante" name="Nombre" value={this.state.Nombre} onChange={this.onChange} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Form.Group className="mb-3" controlId="Direccion">
                                    <Form.Label>Puesto de trabajo</Form.Label>
                                    <Form.Control type="text" required rows={3} style={{ resize: 'none' }} placeholder="Ingrese el puesto de trabajo del solicitante" name="Puesto" value={this.state.Puesto} onChange={this.onChange} />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Col xs={12} md={12}>
                                    <Form.Group className="mb-3" controlId="Correo">
                                        <Form.Label>Correo electrónico</Form.Label>
                                        <Form.Control required type="text" placeholder="Ingrese el correo del solicitante" name="Correo" value={this.state.Correo} onChange={this.onChange} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12}>
                                    <Form.Group className="mb-3" controlId="Telefono">
                                        <Form.Label>Teléfono</Form.Label>
                                        <Form.Control required type="text" placeholder="Ingrese el teléfono del solicitante" name="Telefono" value={this.state.Telefono} onChange={this.onChange} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12}>
                                    <Form.Group className="mb-3" controlId="Institucion">
                                        <Form.Label>Nombre de la institución</Form.Label>
                                        <Form.Control required type="text" placeholder="Ingrese la institución solicitante" name="Institucion" value={this.state.Institucion} onChange={this.onChange} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Form.Group controlId="formFileMultiple" className="mb-3">
                                    <Form.Label>Seleccione el archivo con la propuesta del solicitante</Form.Label>
                                    <Form.Control type="file" onChange={this.onChangeHandler} />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Alert variant="light">
                                    <p>Si selecciona un nuevo archivo, este sustituirá el que se <strong><a style={{ color: 'black' }} href={this.state.Archivo} target="_blank">cargó inicialmente</a></strong></p>
                                </Alert>
                            </Row>
                            <Row>
                                <Button variant="secondary" className="font-bold" type="submit">Guardar</Button>
                            </Row>
                        </Form>
                    </Container>
                </Modal.Body>
                <Modal.Footer style={{ backgroundColor: "#b5bad0" }}>
                    <Button variant="secondary" className="font-bold" onClick={this.props.onHide}>Cancelar</Button>
                </Modal.Footer>

                <ErrorModal
                    color={"#b5bad0"}
                    show={this.state.modalError}
                    onHide={this.ocultar}
                    mensaje={this.state.Mensaje}
                    consideracion={this.state.Consideracion}
                />
                <ModalCargando
                    color={"#b5bad0"}
                    show={this.state.modalCargando}
                    onHide={this.ocultarModalCargando}
                    mensaje={this.state.mensajeCargando}
                />
                <SuccessModal
                    color={"#b5bad0"}
                    show={this.state.modalSucc}
                    onHide={this.ocultar}
                    mensaje={this.state.Mensaje}
                    consideracion={this.state.Consideracion}
                    enlace={''}
                    llevaenlace={false}
                />
            </Modal>
        );
    }
}