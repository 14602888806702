import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import useRefreshToken from "../../hooks/useRefreshToken";
import useAuth from "../../useAuth";

const PersistLogin = () => {
    const [isLoading, setIsLoading] = useState(true);
    const refresh = useRefreshToken();
    const { auth } = useAuth();

    useEffect(() => {
        const verifyRefreshToken = async () => {
            try {
                await refresh();
            } catch (err) {
                console.log(err);
            } finally {
                setIsLoading(false);
            }
        }
        !auth?.accessToken ? verifyRefreshToken() : setIsLoading(false);
    }, []);

    useEffect(() => {
        //console.log(`isLoading: ${isLoading}`);
        //console.log(`aT: ${JSON.stringify(auth?.accessToken)}`);
    }, [isLoading]);


    return (
        <>
            {isLoading
                ? <div style = {{backgroundRepeat:'no-repeat', backgroundPosition:'center center', backgroundSize:'cover', width:'100vw', height:'100vh', overflow:'hidden', backgroundImage:'url(https://ik.imagekit.io/p5ethkcnzwh/PORTADA-CARGANDO_zrnGXPrab.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1651185621397)'}}></div>
                : <Outlet />
            }

        </>
    );
}
/*

    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-image: url('https://ik.imagekit.io/p5ethkcnzwh/PORTADA-1-_UtSeRnmii.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1650642947423');

*/



export default PersistLogin;