import React, { useEffect, useState } from 'react'
import './../Cartera.css'
//import DEFAULT_URL from '../../../../api/url';
//import { SELF_URL } from '../../../../api/url';
import { Row, Button, Col, ListGroup, Badge, Container, Popover, OverlayTrigger } from "react-bootstrap";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { ModalCargando } from '../../../Utils/Modal-Cargando/ModalCargando';
function Inscripciones(props) {
  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">¡Listo!</Popover.Header>
      <Popover.Body>
        Enlace copiado exitosamente.
      </Popover.Body>
    </Popover>
  );
  const [inscripciones, setInscripciones] = useState([]);
  const [contador, setContador] = useState(0);
  const [total, setTotal] = useState(0);
  const [edit, setEdit] = useState(0);
  const [modalCargando, setModalCargando] = useState(false);
  const [mensajeCargando, setMensajeCargando] = useState('');

  useEffect(() => {

  }, [edit]);
  useEffect(async () => {
    async function fetchData() {
      fetch(process.env.REACT_APP_DEFAULT_URL + '/api/auth/userinfo', {cache: "no-cache",  credentials: 'include' })
        .then((res) => {
          return res.json();
        })
        .then(data => {
          fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/asesor/inscripcion/procesado/' + data.UniqueID + "/" + props.UniqueID, { cache: "no-cache" })
            .then((res) => {
              return res.json();
            })
            .then(data => {
              let semi = 0;
              data.rows.map((actual) => {
                semi += actual.inscritos;
              });
              setTotal(semi);
              let tmp = data.rows;
              tmp.map((actual) => {
                actual.enlacePDF = '';
              });
              setInscripciones(tmp);
            });
        });
    }
    fetchData();
  }, [contador]);
  const reporte_excel = () => {
    fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/detalle_inscripcion/asesor/report/' + props.UniqueID + '/' + props.asesor, { cache: "no-cache" })
      .then((res) => {
        return res.json();
      })
      .then(data => {
        var excel_contenido = data.rows;
        var instituciones = [];
        var Sheets = [];
        var SheetNames = [];
        var instituciones_tabla = [];
        const sw = XLSX.utils.json_to_sheet(excel_contenido);
        Sheets['Datos'] = sw;
        SheetNames.push('Datos');
        for (let i = 0; i < data.rows.length; i++) {
          if (instituciones.length == 0 || !instituciones.includes(data.rows[i].institucion.toUpperCase())) {
            instituciones.push(data.rows[i].institucion.toUpperCase());
          }
        }
        for (let i = 0; i < instituciones.length; i++) {
          let arr_respuestas = data.rows.filter((actual) => actual.institucion.toUpperCase() == instituciones[i].toUpperCase());
          arr_respuestas.sort(function (a, b) { return a['Apellidos'].localeCompare(b['Apellidos']) });
          const ws = XLSX.utils.json_to_sheet(arr_respuestas);
          Sheets[(i + 1).toString() + '. ' + instituciones[i].slice(0, 20).replace(/[^a-zA-Z0-9 ]/g, '').toUpperCase() + '(' + arr_respuestas.length + ')'] = ws;
          SheetNames.push((i + 1).toString() + '. ' + instituciones[i].slice(0, 20).replace(/[^a-zA-Z0-9 ]/g, '').toUpperCase() + '(' + arr_respuestas.length + ')');
          instituciones_tabla.push({ 'Institución': instituciones[i], 'Respuestas': arr_respuestas.length })
        }
        instituciones_tabla.sort(function (a, b) { return b.Respuestas - a.Respuestas });
        instituciones_tabla.push({ 'Institución': 'TOTAL', 'Respuestas': data.rows.length });
        const ws = XLSX.utils.json_to_sheet(instituciones_tabla);
        Sheets['RESUMEN'] = ws;
        SheetNames.unshift('RESUMEN');
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const wb = { Sheets, SheetNames };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const datos = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(datos, Date.now() + fileExtension);
      });
  }
  const goToPrint = (id, indice) => {
    setMensajeCargando('Generando archivo PDF');
    setModalCargando(true);
    fetch(process.env.REACT_APP_DEFAULT_URL + '/api/print/sheet/' + id, { cache: "no-cache" })
      .then((res) => {
        return res.json();
      })
      .then(data => {
        let tmp = inscripciones;
        tmp[indice].enlacePDF = data.enlace_S3;
        setInscripciones(tmp);
        setModalCargando(false);
        setEdit(edit + 1);
      });
  }
  return (
    <Container>
      <Row>
        <h3>Total de personas inscritas: {total}</h3>
      </Row>
      <Row style={{ marginBottom: '25px' }}>
        <Col xs={1} md={1} lg={1}>
          <div style={{ color: 'black', cursor: 'pointer' }} data-toggle="tooltip" data-placement="top" title="Refrescar" onClick={() => setContador(contador + 1)} >
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
              <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
            </svg>
          </div>
        </Col>
      </Row>
      <Row style={{ marginBottom: '25px' }}>
        <Button variant="dark" onClick={reporte_excel}>
          Descargar reporte inscritos
        </Button>
      </Row>
      <Row style={{ height: '600px', overflowY: 'scroll' }}>
        <ListGroup as="ol" numbered>
          {inscripciones.map((inscripcion, index) =>
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start"
            >
              <div className="ms-2 me-auto">
                <div className="fw-bold">{inscripcion.empresa}</div>
                {inscripcion.fecha}
              </div>
              {inscripcion.inscritos === 1 && <Badge bg="success" style={{ marginRight: '15px' }}>
                {inscripcion.inscritos} inscrito
              </Badge>}
              {inscripcion.inscritos > 1 && <Badge bg="success" style={{ marginRight: '15px' }}>
                {inscripcion.inscritos} inscritos
              </Badge>}
              {inscripcion.enlacePDF != '' && <Badge bg="dark" style={{ marginRight: '15px', cursor: 'pointer' }} onClick={() => { window.open(inscripcion.enlacePDF, '_blank'); }}>Click para ver PDF</Badge>}
              {inscripcion.estado == '1' && <Badge bg="danger" style={{ marginRight: '15px' }}>Pendiente de autorización</Badge>}
              {inscripcion.estado == '2' && <Badge bg="primary" style={{ marginRight: '15px' }}>Autorizado</Badge>}
              <div data-toggle="tooltip" data-placement="top" title="Generar PDF" style={{ cursor: 'pointer', color: 'black', marginRight: '15px' }} onClick={() => { goToPrint(inscripcion.Id, index); }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-printer-fill" viewBox="0 0 16 16">
                  <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" />
                  <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                </svg>
              </div>
              <a href={process.env.REACT_APP_SELF_URL + "/inscripcion/public/ver/" + inscripcion.Id} target="_blank" data-toggle="tooltip" data-placement="top" title="Ver Inscripción" style={{ cursor: 'pointer', color: 'black' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16">
                  <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                  <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                </svg>
                <i className="bi bi-cloud-arrow-down-fill"></i>
              </a>
              <OverlayTrigger trigger="click" placement="left" overlay={popover} rootClose>
                <div data-toggle="tooltip" data-placement="top" title="Copiar Enlace De Inscripción" style={{ cursor: 'pointer', paddingLeft: '10px' }} onClick={() => navigator.clipboard.writeText(process.env.REACT_APP_SELF_URL + "/inscripcion/public/ver/" + inscripcion.Id)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard-check" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                    <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                    <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                  </svg>
                </div>
              </OverlayTrigger>
            </ListGroup.Item>
          )}
        </ListGroup>
      </Row>
      <ModalCargando
        color={"#b5bad0"}
        show={modalCargando}
        onHide={() => { setModalCargando(false); setContador(contador + 1); }}
        mensaje={mensajeCargando}
      />
    </Container>
  )
}

export default Inscripciones