import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import './Asesores.css';
import { Row, Col, Container, Card } from "react-bootstrap";
//import DEFAULT_URL from '../../api/url';
const PageForos = () => {
    const { hash } = useParams();
    const [contador, setContador] = useState(0);
    const [asesor, setAsesor] = useState({});
    const [cartera, setCartera] = useState([]);
    useEffect(() => {
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read_hash/asesor/' + hash, { cache: "no-cache" })
            .then((res) => {
                return res.json();
            })
            .then(data => {
                if (data.rows.length == 0) {
                    window.location.replace('/unauthorized');
                } else {
                    setAsesor(data.rows[0]);
                    fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/evento/foros/vigente', { cache: "no-cache" })
                        .then((res) => {
                            return res.json();
                        })
                        .then(data => {
                            setCartera(data.rows);
                        });
                }
            });
    }, [contador]);

    return (
        <div className="contenedor-portal">
            <Container>
                <Row style={{ backgroundColor: '#1e3d52', borderRadius: '25px', display: 'flex', placeItems: 'center', padding: '5%', marginTop: '25px' }}>
                    <Col xs={12} md={4} lg={4} style={{ display: 'flex', placeItems: 'center' }}><img style={{ width: '100%', objectFit: 'contain' }} src='https://ik.imagekit.io/p5ethkcnzwh/Blanco_Logo_final_2022_ABG_6V4gmyglP.png?ik-sdk-version=javascript-1.4.3&updatedAt=1657298859263' /></Col>
                    <Col xs={12} md={8} lg={8} style={{ padding: '1%' }}>
                        <h1 style={{ color: 'white', fontWeight: 'bold' }}>FOROS REGIONALES</h1>
                    </Col>
                </Row>
                <Row>
                    <Container style={{ marginTop: '15px', marginBottom: '25px' }}>
                        <Row>
                            {

                                cartera.map((formulario, index) =>
                                    <Col style={{ marginBottom: '15px' }} xs={12} md={6} lg={4}>
                                        <Card style={{ height: '100%' }}>
                                            <Card.Img variant="top" src={formulario.flier} />
                                            <Card.Body>
                                                <Card.Title>{formulario.Nombre}</Card.Title>
                                                <Card.Text>
                                                    {formulario.Descripcion}
                                                </Card.Text>
                                                <small className="text-muted">{formulario.Fecha_Inicio}</small>
                                            </Card.Body>
                                            <Card.Footer>
                                                {/*<Button variant="light" style={{ width: '100%' }} href={process.env.REACT_APP_SELF_URL + '/inscripciones/' + formulario.hash + '/' + asesor.hash} target="_blank">Inscribirse</Button>*/}
                                            </Card.Footer>
                                        </Card>
                                    </Col>
                                )

                            }
                        </Row>
                    </Container>
                </Row>
                <Row style={{ height: '100%' }}>
                    <Col xs={12} md={12} lg={4} id="columna-foto">
                        <div className='contenedor-foto' style={{ marginTop: '48%', marginLeft: '35%', backgroundImage: 'url(' + asesor.foto + ')' }}>

                        </div>
                    </Col>
                    <Col xs={1} md={1} lg={1} id="columnas-azul">
                        <Container style={{ height: '100%' }}>
                            <Row style={{ height: '92%' }}>
                                <Col>
                                    <div className="columna-azul" id="arriba"></div>
                                </Col>
                            </Row>
                            <Row style={{ height: '8%' }}>
                                <Col >
                                    <div className="columna-azul" id="abajo"></div>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                    <Col xs={12} md={12} lg={7} style={{ height: '100vh' }}>
                        <Container>
                            <Row style={{ height: '200px', marginTop: '5%' }}>
                                <Col xs={12} md={12} lg={8}>
                                    <div className='logos'></div>
                                </Col>
                            </Row>
                            <Row id="informacion">
                                <Container>
                                    <Row id="foto-responsive">
                                        <Col xs={12} md={12} lg={12} align="center">
                                            <div className='contenedor-foto' style={{ backgroundImage: 'url(' + asesor.foto + ')' }}>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <h1 className='nombres'><strong>{asesor.Nombres}</strong></h1>
                                        <h1 className='apellidos'>{asesor.Apellidos}</h1>
                                    </Row>
                                    <Row>
                                        <div className='contenedor-puesto'>
                                            <h5 className='titulo-puesto'>{asesor.puesto}</h5>
                                        </div>
                                    </Row>
                                    <Row style={{ marginTop: '20px' }}>
                                        <h5 className='titulo-entidad'>Asociación Bancaria de Guatemala -ABG</h5>
                                    </Row>
                                    <Row>
                                        <h5 className='titulo-entidad'>{asesor.institucion}</h5>
                                    </Row>
                                    <Row style={{ marginTop: '10px', marginBottom: '10px', height: '10vh' }}>
                                        <div className='titulo-contacto'>
                                            <h1 style={{ textAlign: 'left' }}>CONTACTO</h1>
                                        </div>
                                    </Row>
                                    <Row className="fila-control-height">
                                        <Col xs={2} md={2} lg={2}>
                                            <a href={"mailto:" + asesor.email} target='_blank'>
                                                <div className='imagen-icono' data-toggle="tooltip" data-placement="top" title="Enviar un email" style={{ cursor: 'pointer', backgroundImage: 'url(https://ik.imagekit.io/p5ethkcnzwh/icono_correo_0x383IQJm.png?ik-sdk-version=javascript-1.4.3&updatedAt=1652366169860)' }}>

                                                </div>
                                            </a>
                                        </Col>
                                        <Col xs={10} md={10} lg={10} style={{ marginTop: '10px' }} className='correos'>
                                            <h4 style={{ textAlign: 'left' }} className='titulo-entidad'>{asesor.email}</h4>
                                        </Col>
                                    </Row>
                                    <Row className="fila-control-height">
                                        <Col xs={2} md={2} lg={2}>
                                            <a href={"mailto:" + asesor.correo_institucion} target='_blank'>
                                                <div className='imagen-icono' data-toggle="tooltip" data-placement="top" title="Enviar un email" style={{ cursor: 'pointer', backgroundImage: 'url(https://ik.imagekit.io/p5ethkcnzwh/icono_correo_0x383IQJm.png?ik-sdk-version=javascript-1.4.3&updatedAt=1652366169860)' }}>

                                                </div>
                                            </a>
                                        </Col>
                                        <Col xs={10} md={10} lg={10} style={{ marginTop: '10px' }} className='correos'>
                                            <h4 style={{ textAlign: 'left' }} className='titulo-entidad'> {asesor.correo_institucion}</h4>
                                        </Col>
                                    </Row>
                                    <Row className="fila-control-height">
                                        <Col xs={2} md={2} lg={2}>
                                            <a href={"tel://" + asesor.telefono_institucion} target='_blank'>
                                                <div className='imagen-icono' data-toggle="tooltip" data-placement="top" title="Llamar" style={{ cursor: 'pointer', backgroundImage: 'url(https://ik.imagekit.io/p5ethkcnzwh/Icono_telefono_yMYSGXNeF.png?ik-sdk-version=javascript-1.4.3&updatedAt=1652366169561)' }}>

                                                </div>
                                            </a>
                                        </Col>
                                        <Col xs={10} md={10} lg={10}>
                                            <h4 style={{ textAlign: 'left', lineHeight: '50px' }} className='titulo-entidad'>{asesor.telefono_institucion}</h4>
                                        </Col>
                                    </Row>
                                    <Row className="fila-control-height">
                                        <Col xs={2} md={2} lg={2}>
                                            <a href={"https://wa.me/" + asesor.whatsapp} target='_blank'>
                                                <div className='imagen-icono' data-toggle="tooltip" data-placement="top" title="Contactar por whatsapp" style={{ cursor: 'pointer', backgroundImage: 'url(https://ik.imagekit.io/p5ethkcnzwh/Icono_whatsapp_Akz-jIYgk.png?ik-sdk-version=javascript-1.4.3&updatedAt=1652366169464)' }}>

                                                </div>
                                            </a>
                                        </Col>
                                        <Col xs={10} md={10} lg={10}>
                                            <h4 style={{ textAlign: 'left', lineHeight: '50px' }} className='titulo-entidad'>{asesor.whatsapp}</h4>
                                        </Col>
                                    </Row>
                                    <Row className="fila-control-height">
                                        <Col xs={2} md={2} lg={2}>
                                            <a href="https://www.ebg.edu.gt" target='_blank'>
                                                <div className='imagen-icono' data-toggle="tooltip" data-placement="top" title="Navegar a site" style={{ cursor: 'pointer', backgroundImage: 'url(https://ik.imagekit.io/p5ethkcnzwh/Icono_web_nh15_0W59.png?ik-sdk-version=javascript-1.4.3&updatedAt=1652366169464)' }}>

                                                </div>
                                            </a>
                                        </Col>
                                        <Col xs={10} md={10} lg={10}>
                                            <h4 style={{ textAlign: 'left', lineHeight: '50px' }} className='titulo-entidad'>www.ebg.edu.gt</h4>
                                        </Col>
                                    </Row>
                                    <Row style={{ marginTop: '10px', height: '10vh' }}>
                                        <Col xs={4} md={4} lg={4}></Col>
                                        <Col xs={2} md={2} lg={2}>
                                            <a href="https://www.instagram.com/ebg.guatemala/?hl=es-la" target='_blank'>
                                                <div className='imagen-icono' data-toggle="tooltip" data-placement="top" title="Encuéntranos en instagram" style={{ cursor: 'pointer', backgroundImage: 'url(https://ik.imagekit.io/p5ethkcnzwh/Icono_Instagram_gfrM0BdRz.png?ik-sdk-version=javascript-1.4.3&updatedAt=1652366169828)' }}>

                                                </div>
                                            </a>
                                        </Col>
                                        <Col xs={2} md={2} lg={2}>
                                            <a href="https://www.facebook.com/abg.ebg" target='_blank'>
                                                <div className='imagen-icono' data-toggle="tooltip" data-placement="top" title="Encuéntranos en facebook" style={{ cursor: 'pointer', backgroundImage: 'url(https://ik.imagekit.io/p5ethkcnzwh/Icono_Facebook_w8zUSAC38.png?ik-sdk-version=javascript-1.4.3&updatedAt=1652366169746)' }}>

                                                </div>
                                            </a>
                                        </Col>
                                        <Col xs={2} md={2} lg={2}>
                                            <a href="https://twitter.com/ABG_EBG" target='_blank'>
                                                <div className='imagen-icono' data-toggle="tooltip" data-placement="top" title="Encuéntranos en twitter" style={{ cursor: 'pointer', backgroundImage: 'url(https://ik.imagekit.io/p5ethkcnzwh/Icono_Twitter_Ww6TzsuXS.png?ik-sdk-version=javascript-1.4.3&updatedAt=1652366169591)' }}>

                                                </div>
                                            </a>
                                        </Col>
                                        <Col xs={2} md={2} lg={2}>
                                            <a href="https://www.linkedin.com/in/escuela-bancaria-de-guatemala-b0286b9b/ " target='_blank'>
                                                <div className='imagen-icono' data-toggle="tooltip" data-placement="top" title="Encuéntranos en linkedin" style={{ cursor: 'pointer', backgroundImage: 'url(https://ik.imagekit.io/p5ethkcnzwh/Icono_linkedin_4IbLb60pn.png?ik-sdk-version=javascript-1.4.3&updatedAt=1652366169464)' }}>

                                                </div>
                                            </a>
                                        </Col>
                                    </Row>
                                </Container>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default PageForos