import React, { useEffect, useState } from 'react';
//import DEFAULT_URL from '../../../../../api/url';
import axios from 'axios';
import { Row, Container, Form, Button, ProgressBar, Alert, Col, Accordion, Dropdown } from "react-bootstrap";
import Diploma from './Diploma';
import Certificado from './Certificado';
import CertificadosGenerados from './CertificadosGenerados';
import Especificaciones from './Especificaciones';
const Diplomas = (props) => {
    const [contador, setContador] = useState(0);
    const [formulario, setFormulario] = useState('none');
    const [nombre, setNombre] = useState('');
    const [file, setFile] = useState(null);
    const [diplomas, setDiplomas] = useState([]);
    const [exito, setExito] = useState('none');
    const [error, setError] = useState('none');
    const [confirmacion, setConfirmacion] = useState('none');
    const [cargando, setCargando] = useState('none');
    const [detalle, setDetalle] = useState('block');
    const [mensaje, setMensaje] = useState('');
    const [selectedKey, setSelectedKey] = useState('');
    const [selectedId, setSelectedId] = useState('');
    const [status, setStatus] = useState(100);
    const [tipo, setTipo] = useState(0);
    const [reverso, setReverso] = useState(null);
    const [cert, setCert] = useState('none');
    const [conteo, setConteo] = useState(1);
    const [numeracion, setNumeracion] = useState(1);
    const [prefijo, setPrefijo] = useState('');
    const [certificados, setCertificados] = useState([]);
    const [continuacion, setContinuacion] = useState(0);
    const [continuar, setContinuar] = useState(false);
    const [continuaciones, setContinuaciones] = useState([]);
    const [continuarDisplay, setContinuarDisplay] = useState('none');
    const [x, setX] = useState(0);
    const [y, setY] = useState(0);
    const [w, setW] = useState(0);
    const [h, setH] = useState(0);
    useEffect(() => {
        async function fetchData() {
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/diplomas/read/' + props.evento.UniqueID, { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setDiplomas(data.resultado);
                });
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/certificado/all', { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setCertificados(data.resultado);
                });
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/certificado/disponibles', { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setContinuaciones(data.resultado);
                });
        }
        fetchData();
    }, [contador]);
    const submit = (evt) => {
        evt.preventDefault();
        setStatus(0);
        if (file != null && nombre != '') {
            setMensaje('Almacenando diploma en repositorio de medios...');
            cargar();
            if (tipo == 2) {
                setStatus(30);
                if (prefijo.length > 10) {
                    setMensaje('Error: el prefijo no puede ser mayor a 10 carácteres.');
                    operacionError();
                } else {
                    const data = new FormData();
                    data.append('file', file);
                    axios.post(process.env.REACT_APP_DEFAULT_URL+ "/api/files/upload/s3/diplomas", data, {}).then(res => {
                        if (res.data.codigo == 200) {
                            setStatus(30);
                            const dataReverso = new FormData();
                            dataReverso.append('file', reverso);
                            axios.post(process.env.REACT_APP_DEFAULT_URL+ "/api/files/upload/s3/diplomas", dataReverso, {}).then(response => {
                                if (response.data.codigo == 200) {
                                    setStatus(60);
                                    setMensaje('Registrando certificado en base de datos...');
                                    setContador(contador + 1);
                                    const info = { img: res.data.resultado.Key, reverso: response.data.resultado.Key, evento_UniqueID: props.evento.UniqueID, nombre: nombre, tipo: tipo, conteo: conteo, prefijo: prefijo, numeracion: numeracion, x_qr: x, y_qr: y, w_qr: w, h_qr: h };
                                    const requestOptions = {
                                        method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json'
                                        },
                                        body: JSON.stringify(info)
                                    };
                                    fetch(process.env.REACT_APP_DEFAULT_URL + '/api/certificado/create', requestOptions)
                                        .then((res) => {
                                            return res.json();
                                        })
                                        .then(datos => {
                                            if (datos.codigo == 200) {
                                                if (continuacion) {
                                                    setStatus(80);
                                                    setMensaje('Registrando continuación de numeración...');
                                                    setContador(contador + 1);
                                                    const info = { anterior: continuacion, siguiente: datos.resultado };
                                                    const requestOptions = {
                                                        method: 'POST',
                                                        headers: {
                                                            'Content-Type': 'application/json'
                                                        },
                                                        body: JSON.stringify(info)
                                                    };
                                                    fetch(process.env.REACT_APP_DEFAULT_URL + '/api/certificado/create/continuacion', requestOptions)
                                                        .then((res) => {
                                                            return res.json();
                                                        })
                                                        .then(informacion => {
                                                            if (informacion.codigo == 200) {
                                                                setStatus(100);
                                                                setMensaje('Certificado registrado exitosamente');
                                                                operacionExito();
                                                            } else {
                                                                setMensaje('Ocurrió un error al registrar la continuación del certificado en su numeración en db');
                                                                operacionError();
                                                            }
                                                        });
                                                } else {
                                                    setStatus(100);
                                                    setMensaje('Certificado registrado exitosamente');
                                                    operacionExito();
                                                }
                                            } else {
                                                console.log(datos);
                                                setMensaje('Ocurrió un error al registrar el certificado en db');
                                                operacionError();
                                            }
                                        });
                                } else {
                                    setMensaje('Ocurrió un error al almacenar el certificado en repositorio de medios (reverso)');
                                    operacionError();
                                }
                            });
                        } else {
                            setMensaje('Ocurrió un error al almacenar el certificado en repositorio de medios');
                            operacionError();
                        }
                    });
                }
            } else {
                const data = new FormData();
                data.append('file', file);
                axios.post(process.env.REACT_APP_DEFAULT_URL+ "/api/files/upload/s3/diplomas", data, {}).then(res => {
                    if (res.data.codigo == 200) {
                        setStatus(50);
                        setMensaje('Registrando diploma en base de datos...');
                        setContador(contador + 1);
                        const info = { img: res.data.resultado.Key, evento_UniqueID: props.evento.UniqueID, nombre: nombre, x_qr: x, y_qr: y, w_qr: w, h_qr: h };
                        const requestOptions = {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(info)
                        };
                        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/diplomas/create', requestOptions)
                            .then((res) => {
                                return res.json();
                            })
                            .then(datos => {
                                if (datos.codigo == 200) {
                                    setStatus(100);
                                    setMensaje('Diploma registrado exitosamente');
                                    operacionExito();
                                } else {
                                    setMensaje('Ocurrió un error al registrar el diploma en db');
                                    operacionError();
                                }
                            });
                    } else {
                        setMensaje('Ocurrió un error al almacenar el diploma en repositorio de medios');
                        operacionError();
                    }
                });
            }
            Array.from(document.querySelectorAll("input")).forEach(
                input => (input.value = "")
            );
            setFile(null);
            setReverso(null);
            setNombre('');
            setPrefijo('');
            setConteo(1);
            setNumeracion(1);
            setContinuacion(0);
            setContinuar(false);
            setContinuarDisplay('none');
            setContador(contador + 1);
        }
    }

    const onChange = (evt) => {
        if (evt.target.name == "nombre") {
            setNombre(evt.target.value);
        } else if (evt.target.name === "seleccion-tipo") {
            setTipo(evt.target.value);
            if (evt.target.value == 2) {
                setCert('block');
                fetch(process.env.REACT_APP_DEFAULT_URL + '/api/certificado/conteo')
                    .then((res) => {
                        return res.json();
                    })
                    .then(datos => {
                        setConteo(datos.resultado_conteo[0].conteo + 1);
                    });
            } else {
                setConteo(1);
                setCert('none');
            }
        } else if (evt.target.name === "reverso") {
            setReverso(evt.target.files[0]);
        } else if (evt.target.name === "conteo") {
            setConteo(evt.target.value);
        } else if (evt.target.name === "prefijo") {
            setPrefijo(evt.target.value);
        } else if (evt.target.name === "numeracion") {
            setNumeracion(evt.target.value);
        } else if (evt.target.name === "continuar-numeracion") {
            setContinuar(evt.target.checked);
            if (continuacion != 0 && evt.target.checked) {
                fetch(process.env.REACT_APP_DEFAULT_URL + '/api/certificado/numeracion/' + continuacion)
                    .then((res) => {
                        return res.json();
                    })
                    .then(datos => {
                        setNumeracion(datos.resultado_numeracion[0].numeracion + 1);
                    });
                setContinuarDisplay('block');
            } else if (evt.target.checked) {
                setContinuarDisplay('block');
            }
            if (!evt.target.checked) {
                setNumeracion(1);
                setContinuarDisplay('none');
            }
        } else if (evt.target.name === "continuacion") {
            setContinuacion(evt.target.value);
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/certificado/numeracion/' + evt.target.value)
                .then((res) => {
                    return res.json();
                })
                .then(datos => {
                    setNumeracion(datos.resultado_numeracion[0].numeracion + 1);
                });
        } else if (evt.target.name === "x") {
            setX(evt.target.value);
        } else if (evt.target.name === "y") {
            setY(evt.target.value);
        } else if (evt.target.name === "w") {
            setW(evt.target.value);
        } else if (evt.target.name === "h") {
            setH(evt.target.value);
        } else {
            setFile(evt.target.files[0]);
        }
        setContador(contador + 1);
    }
    const cargar = () => {
        setExito('none');
        setError('none');
        setConfirmacion('none');
        setCargando('block');
        setDetalle('none');
        setFormulario('none');
        setContador(contador + 1);
    }
    const cargarChild = (mensaje) => {
        setExito('none');
        setError('none');
        setConfirmacion('none');
        setCargando('block');
        setDetalle('none');
        setFormulario('none');
        setMensaje(mensaje);
        setContador(contador + 1);
    }
    const operacionExitoChild = (mensaje) => {
        setExito('block');
        setError('none');
        setConfirmacion('none');
        setCargando('none');
        setDetalle('none');
        setFormulario('none');
        setMensaje(mensaje);
        setContador(contador + 1);
    }
    const operacionExito = () => {
        setExito('block');
        setError('none');
        setConfirmacion('none');
        setCargando('none');
        setDetalle('none');
        setFormulario('none');
        setContador(contador + 1);
    }
    const operacionErrorChild = (mensaje) => {
        setExito('none');
        setError('block');
        setConfirmacion('none');
        setCargando('none');
        setDetalle('none');
        setFormulario('none');
        setMensaje(mensaje);
        setContador(contador + 1);
    }
    const operacionError = () => {
        setExito('none');
        setError('block');
        setConfirmacion('none');
        setCargando('none');
        setDetalle('none');
        setFormulario('none');
        setContador(contador + 1);
    }
    const goBackToDetail = () => {
        setExito('none');
        setError('none');
        setConfirmacion('none');
        setCargando('none');
        setDetalle('block');
        setFormulario('none');
        setContador(contador + 1);
    }
    const goToForm = () => {
        setFormulario('block');
        setExito('none');
        setError('none');
        setConfirmacion('none');
        setCargando('none');
        setDetalle('none');
        setContador(contador + 1);
    }
    const confirmar = (id, key, nombre) => {
        setFormulario('none');
        setExito('none');
        setError('none');
        setConfirmacion('block');
        setCargando('none');
        setDetalle('none');
        setMensaje('¿Está segur@ de eliminar el diploma ' + nombre + '?');
        setSelectedId(id);
        setSelectedKey(key);
        setContador(contador + 1);
    }
    const eliminarDiploma = () => {
        setStatus(0);
        setMensaje('Eliminando diploma...');
        cargar();
        const info = { UniqueID: selectedId, key: selectedKey };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/diplomas/delete', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(datos => {
                setStatus(100);
                setContador(contador + 1);
                if (datos.codigo == 200) {
                    setMensaje('Diploma eliminado exitosamente');
                    operacionExito();
                } else {
                    setMensaje('Ocurrió un error al eliminar el');
                    operacionError();
                }
            });
    }
    const changeStatus = (num) => {
        setStatus(num);
        setContador(contador + 1);
    }
    const sendDiplomasATodos = (diploma) => {
        setStatus(0);
        setMensaje('Consultando participantes...');
        cargar();
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/detalle_inscripcion/evento/' + props.evento.UniqueID, { cache: "no-cache" })
            .then((res) => {
                return res.json();
            })
            .then(data => {
                setMensaje('Enviando Diplomas...');
                data.rows.map((actual, index) => {
                    const info = { diploma: diploma, participante: actual.UniqueID };
                    const requestOptions = {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(info)
                    };
                    fetch(process.env.REACT_APP_DEFAULT_URL + '/api/diplomas/send', requestOptions)
                        .then((res) => {
                            return res.json();
                        })
                        .then(datos => {
                            if (datos.codigo == 200) {
                                setStatus((index / data.rows.length) * 100);
                                setContador(contador + 1);
                                if (index == (data.rows.length - 1)) {
                                    setMensaje('Diplomas enviados exitosamente');
                                    operacionExito();
                                    return true;
                                }
                            } else {
                                setMensaje('Ocurrió un error al enviar los diplomas');
                                operacionError();
                            }
                        });
                });
            });
    }
    const getAllDiplomas = (diploma) => {
        setStatus(0);
        setMensaje('Consultando participantes...');
        cargar();
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/detalle_inscripcion/evento/' + props.evento.UniqueID, { cache: "no-cache" })
            .then((res) => {
                return res.json();
            })
            .then(data => {
                setMensaje('Generando Diplomas...');
                data.rows.map((actual, index) => {
                    fetch(process.env.REACT_APP_DEFAULT_URL + '/api/diplomas/getDiplomaPDF/' + diploma + '/' + actual.UniqueID)
                        .then((res) => {
                            return res.json();
                        })
                        .then(datos => {
                            if (datos.codigo == 200) {
                                fetch(process.env.REACT_APP_DEFAULT_URL + '/api/diplomas/getObject/' + datos.llave).then(response => {
                                    response.blob().then(blob => {
                                        setStatus((index / data.rows.length) * 100);
                                        setContador(contador + 1);
                                        const fileURL = window.URL.createObjectURL(blob);
                                        let alink = document.createElement('a');
                                        alink.href = fileURL;
                                        alink.download = 'diploma-' + Date.now() + '-' + actual.UniqueID + '.pdf';
                                        alink.click();
                                        if (index == (data.rows.length - 1)) {
                                            setMensaje('Diplomas enviados exitosamente');
                                            operacionExito();
                                            return true;
                                        }
                                    });
                                })
                            } else {
                                setMensaje('Ocurrió un error al enviar los diplomas');
                                operacionError();
                            }
                        });
                });
            });
    }
    return (
        <Row style={{ marginTop: '45px', border: '0.5px dashed black', borderRadius: '15px', padding: '25px' }}>
            <Container fluid>
                <Row>
                    <h1>Diplomas y certificados</h1>
                </Row>
                <Row style={{ display: detalle, marginTop: '45px' }}>
                    <Button variant="outline-success" onClick={goToForm}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-patch-check-fill" viewBox="0 0 16 16">
                            <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z" />
                        </svg>&nbsp;&nbsp;&nbsp;&nbsp;Registrar Diploma
                    </Button>
                </Row>
                <Row style={{ display: detalle, marginTop: '45px' }}>
                    <Button variant="outline-success" onClick={() => { setContador(contador + 1); }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                            <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                        </svg>&nbsp;&nbsp;&nbsp;&nbsp;Refrescar Info
                    </Button>
                </Row>
                <Row style={{ display: detalle, marginTop: '30px' }}>
                    <Accordion flush style={{ width: '100%' }}>
                        {diplomas.length > 0 && diplomas.map((actual) => <Accordion.Item eventKey={actual.UniqueID} key={actual.UniqueID}>
                            <Accordion.Header>{actual.nombre}</Accordion.Header>
                            <Accordion.Body>
                                <Container fluid>
                                    {actual.tipo == 1 && <Row style={{ marginTop: '25px', marginBottom: '35px' }}><h1>Información del diploma</h1></Row>}
                                    {actual.tipo == 2 && <Row style={{ marginTop: '25px', marginBottom: '35px' }}>
                                        <h1>Información del certificado</h1>
                                        <h5>Certificado generado el <strong>{actual.fecha}</strong></h5>
                                        <h5>Conteo inicial del certificado: <strong>{actual.conteo}</strong></h5>
                                        <h5>Numeración inncia del certificado: <strong>{actual.numeracion}</strong></h5>
                                    </Row>}
                                    {actual.tipo == 1 && <Row style={{ marginTop: '25px', marginBottom: '35px' }}><h1>Diploma generado el {actual.fecha}</h1></Row>}
                                    <Row>
                                        <Col xs={12} md={12} lg={6}>
                                            <Row>
                                                <h3>Vista Previa</h3>
                                            </Row>
                                            <Row>
                                                <img src={process.env.REACT_APP_DEFAULT_URL + '/api/diplomas/getObject/' + actual.img} loading='lazy' alt='Vista previa diploma' style={{ width: '100%' }} />
                                            </Row>
                                            {actual.tipo == 2 && <Row style={{ marginTop: '15px' }}>
                                                <img src={process.env.REACT_APP_DEFAULT_URL + '/api/diplomas/getObject/' + actual.reverso} loading='lazy' alt='Vista previa diploma (reverso)' style={{ width: '100%' }} />
                                            </Row>}
                                            <Row><Especificaciones refrescar={() => { setContador(contador + 1); }} diploma={actual.UniqueID} x_qr={actual.x_qr} y_qr={actual.y_qr} w_qr={actual.w_qr} h_qr={actual.h_qr} /></Row>
                                        </Col>
                                        <Col xs={12} md={12} lg={6} style={{ marginTop: '60px' }}>
                                            <Row>
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="outline-dark" id="dropdown-basic">
                                                        Acciones Generales
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        {/*actual.tipo == 1 && <Dropdown.Item onClick={() => { sendDiplomasATodos(actual.UniqueID); }}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-envelope-check-fill" viewBox="0 0 16 16">
                                                            <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 4.697v4.974A4.491 4.491 0 0 0 12.5 8a4.49 4.49 0 0 0-1.965.45l-.338-.207L16 4.697Z" />
                                                            <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686Z" />
                                                        </svg>&nbsp;&nbsp;&nbsp;&nbsp;Enviar diploma a todos los participantes
    </Dropdown.Item>*/}
                                                        {/*actual.tipo == 1 && <Dropdown.Item onClick={() => { getAllDiplomas(actual.UniqueID); }}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-cloud-arrow-down-fill" viewBox="0 0 16 16">
                                                            <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 6.854-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5a.5.5 0 0 1 1 0v3.793l1.146-1.147a.5.5 0 0 1 .708.708z" />
                                                        </svg>&nbsp;&nbsp;&nbsp;&nbsp;Descargar todos los diplomas
</Dropdown.Item>*/}
                                                        <Dropdown.Item style={{ color: 'red' }} onClick={() => { confirmar(actual.UniqueID, actual.img, actual.nombre); }}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-envelope-check-fill" viewBox="0 0 16 16">
                                                            <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 4.697v4.974A4.491 4.491 0 0 0 12.5 8a4.49 4.49 0 0 0-1.965.45l-.338-.207L16 4.697Z" />
                                                            <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686Z" />
                                                        </svg>&nbsp;&nbsp;&nbsp;&nbsp;Eliminar diploma</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </Row>
                                            {actual.tipo == 1 && <Row>
                                                <Diploma changeStatus={changeStatus} evento={props.evento} diploma={actual.UniqueID} cargar={cargarChild} operacionExito={operacionExitoChild} operacionError={operacionErrorChild} />
                                            </Row>}
                                            {actual.tipo == 2 && <Row>
                                                <Certificado changeStatus={changeStatus} evento={props.evento} cert={actual.UniqueID} cargar={cargarChild} operacionExito={operacionExitoChild} operacionError={operacionErrorChild} />
                                            </Row>}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <CertificadosGenerados changeStatus={changeStatus} evento={props.evento} cert={actual.UniqueID} cargar={cargarChild} operacionExito={operacionExitoChild} operacionError={operacionErrorChild} tipo={actual.tipo} />
                                    </Row>
                                </Container>
                            </Accordion.Body>
                        </Accordion.Item>
                        )}
                    </Accordion>
                </Row>
                <Row style={{ display: formulario, marginTop: '25px' }}>
                    <Form onSubmit={submit}>
                        <Container>
                            <Row>
                                <Col xs={12} md={12} lg={6}>
                                    <Form.Group className="mb-3" controlId="nombre">
                                        <Form.Label>Nombre</Form.Label>
                                        <Form.Control type="text" required placeholder="Ingrese un nombre para identificar el diploma" name="nombre" onChange={onChange} value={nombre} />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={12} lg={6}>
                                    <Form.Group className="mb-3" controlId="tipo" >
                                        <Form.Label>Tipo</Form.Label>
                                        <Form.Control as="select" required aria-label="Tipo" name="seleccion-tipo" onChange={onChange} value={tipo}>
                                            <option value="" key="0">Seleccione el tipo</option>
                                            <option value={1}>Diploma</option>
                                            <option value={2}>Certificado</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={12} lg={6}>
                                    <Form.Group controlId="archivo" className="mb-3">
                                        <Form.Label>Archivo con fondo</Form.Label>
                                        <Form.Control required type="file" onChange={onChange} name="file" />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={12} lg={6}>
                                    <Form.Group controlId="archivo" className="mb-3" style={{ display: cert }}>
                                        <Form.Label>Archivo con fondo (reverso)</Form.Label>
                                        <Form.Control required disabled={cert === 'none'} type="file" onChange={onChange} name="reverso" />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={12} lg={6} style={{ display: cert }}>
                                    <Form.Group className="mb-3" controlId="conteo">
                                        <Form.Label>Contador inicial</Form.Label>
                                        <Form.Control type="text" required disabled={cert === 'none'} placeholder="Ingrese el conteo inicial" name="conteo" onChange={onChange} value={conteo} />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={12} lg={6} style={{ display: cert }}>
                                    <Form.Group className="mb-3" controlId="prefijo">
                                        <Form.Label>Prefijo</Form.Label>
                                        <Form.Control type="text" required disabled={cert === 'none'} placeholder="Ingrese el prefijo para el correlativo" name="prefijo" onChange={onChange} value={prefijo} maxLength="10" />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={12} lg={6} style={{ display: cert }}>
                                    <Form.Group className="mb-3" controlId='continuar-numeracion'>
                                        <Form.Check onChange={onChange} name='continuar-numeracion' checked={continuar} type="checkbox" label='Continuar numeración de otro certificado' />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={12} lg={6} style={{ display: continuarDisplay }}>
                                    <Form.Group className="mb-3" controlId="continuaciones">
                                        <Form.Label>Certificado a continuar numeración</Form.Label>
                                        <Form.Control as="select" required disabled={continuarDisplay === 'none'} aria-label="Continuación" name="continuacion" onChange={onChange} value={continuacion} >
                                            <option value="" key="0">Seleccione el certificado</option>
                                            {continuaciones.map(actual => <option key={actual.UniqueID} value={actual.UniqueID}>{actual.nombre + ' (' + actual.fecha + ')'}</option>)}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={12} lg={6} style={{ display: cert }}>
                                    <Form.Group className="mb-3" controlId="numeracion">
                                        <Form.Label>Numeración inicial</Form.Label>
                                        <Form.Control type="text" required disabled={cert === 'none' || continuar} placeholder="Ingrese la numeracion inicial" name="numeracion" onChange={onChange} value={numeracion} />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={12} lg={6}>
                                    <Form.Group>
                                        <Form.Label>Coordenada x para código qr</Form.Label>
                                        <Form.Control type="number" step=".01" required placeholder="Ingrese la coordenada en x para código qr" name="x" onChange={onChange} value={x} />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={12} lg={6}>
                                    <Form.Group>
                                        <Form.Label>Coordenada y para código qr</Form.Label>
                                        <Form.Control type="number" step=".01" required placeholder="Ingrese la coordenada en y para código qr" name="y" onChange={onChange} value={y} />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={12} lg={6}>
                                    <Form.Group>
                                        <Form.Label>Ancho del código qr en pixeles</Form.Label>
                                        <Form.Control type="number" step=".01" required placeholder="Ingrese el ancho del código qr en pixeles" name="w" onChange={onChange} value={w} />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={12} lg={6}>
                                    <Form.Group>
                                        <Form.Label>Alto del código qr en pixeles</Form.Label>
                                        <Form.Control type="number" step=".01" required placeholder="Ingrese el alto del código qr en pixeles" name="h" onChange={onChange} value={h} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '35px' }}>
                                <Button type="submit" variant="success">Registrar diploma</Button>
                            </Row>
                            <Row style={{ marginTop: '35px' }}>
                                <Button onClick={goBackToDetail} variant="outline-dark">Cancelar</Button>
                            </Row>
                        </Container>
                    </Form>
                </Row>
                <Row style={{ display: cargando }}>
                    <Alert variant='dark'>
                        <Alert.Heading>Procesando operación</Alert.Heading>
                        <p>
                            Esto puede demorar unos minutos
                        </p>
                        <hr />
                        <p className="mb-0">
                            Por favor espere...<br /><br />
                            {mensaje}
                            <br /><br />
                            <ProgressBar animated now={status} variant='dark' />
                        </p>
                    </Alert>
                </Row>
                <Row style={{ display: exito }}>
                    <Alert variant='success'>
                        <Alert.Heading>Operación procesada con éxito</Alert.Heading>
                        <p>
                            {mensaje}
                        </p>
                        <Row>
                            <Col xs={8} md={10} lg={10}>
                            </Col>
                            <Col xs={4} md={2} lg={2}>
                                <Row>
                                    <Button variant='outline-success' onClick={goBackToDetail}>Ok</Button>
                                </Row>
                            </Col>
                        </Row>
                    </Alert>
                </Row>
                <Row style={{ display: error }}>
                    <Alert variant='danger'>
                        <Alert.Heading>{mensaje}</Alert.Heading>
                        <Row>
                            <Col xs={8} md={10} lg={10}>
                            </Col>
                            <Col xs={4} md={2} lg={2}>
                                <Row>
                                    <Button variant='outline-danger' onClick={goBackToDetail}>Ok</Button>
                                </Row>
                            </Col>
                        </Row>
                    </Alert>
                </Row>
                <Row style={{ display: confirmacion }}>
                    <Alert variant='warning'>
                        <Alert.Heading>Se requiere confirmación del usuario</Alert.Heading>
                        <p>
                            {mensaje}
                        </p>
                        <Row>
                            <Col xs={4} md={8} lg={8}>
                            </Col>
                            <Col xs={4} md={2} lg={2}>
                                <Row>
                                    <Button variant='outline-danger' onClick={eliminarDiploma}>Sí</Button>
                                </Row>
                            </Col>
                            <Col xs={4} md={2} lg={2}>
                                <Row>
                                    <Button variant='outline-warning' onClick={goBackToDetail}>No</Button>
                                </Row>
                            </Col>
                        </Row>
                    </Alert>
                </Row>
            </Container>
        </Row>
    )
}

export default Diplomas