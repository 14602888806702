import React, { useState, useEffect } from 'react';
import { Row, Alert, Container, Accordion, Form, Button, ProgressBar } from "react-bootstrap";
import Entregable from './Entregable';
import ModalSubirNotas from './ModalSubirNotas';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
//import DEFAULT_URL from '../../../../../api/url';
import axios from 'axios';
const PonderarParticipantes = (props) => {
    const [contador, setContador] = useState(0);
    const [participantes, setParticipantes] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [filterData, setFilterData] = useState('');
    const [cambios, setCambios] = useState(0);
    const [detalle, setDetalle] = useState('block');
    const [subirNotas, setSubirNotas] = useState('none');
    const [cargando, setCargando] = useState('none');
    const [error, setError] = useState('none');
    const [exito, setExito] = useState('none');
    const [status, setStatus] = useState(0);
    const [selectedFile, setSelectedFile] = useState(null);
    const [mensaje, setMensaje] = useState('');
    useEffect(() => {
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/detalle_inscripcion/evento/' + props.evento, { cache: "no-cache" })
            .then((res) => {
                return res.json();
            })
            .then(data => {
                setFilteredData(data.rows);
                setParticipantes(data.rows);
            });
    }, [contador]);
    useEffect(() => {

    }, [cambios]);
    const downloadReporte = () => {
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/actividad/grades/' + props.evento + '/' + props.actividad, { cache: "no-cache" })
            .then((res) => {
                return res.json();
            })
            .then(data_ => {
                const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                const fileExtension = '.xlsx';
                const ws = XLSX.utils.json_to_sheet(data_.respuesta);
                const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const data = new Blob([excelBuffer], { type: fileType });
                FileSaver.saveAs(data, Date.now() + fileExtension);
            });
    }
    const onChange = (evt) => {
        setFilterData(evt.target.value);
        setCambios(cambios + 1);
        let result = [];
        result = participantes.filter((actual) => {
            return actual.Nombres.search(evt.target.value) != -1 || actual.Apellidos.search(evt.target.value) != -1 || actual.Correo.search(evt.target.value) != -1;
        });
        setFilteredData(result);
        setCambios(cambios + 1);
    }
    const goSubirNotas = () => {
        setDetalle('none');
        setSubirNotas('block');
        setCargando('none');
        setError('none');
        setExito('none');
        setCambios(cambios + 1);
    }
    const goBack = () => {
        setDetalle('block');
        setSubirNotas('none');
        setCargando('none');
        setError('none');
        setExito('none');
        setCambios(cambios + 1);
    }
    const cargar = (msg, sts) => {
        setDetalle('none');
        setSubirNotas('none');
        setCargando('block');
        setError('none');
        setExito('none');
        setMensaje(msg);
        setStatus(sts);
        setCambios(cambios + 1);
    }
    const operacionExito = (msg) => {
        setStatus(100);
        setDetalle('none');
        setSubirNotas('none');
        setCargando('none');
        setError('none');
        setExito('block');
        setMensaje(msg);
        setCambios(cambios + 1);
        setContador(contador + 1);
    }
    const operacionError = (msg) => {
        setStatus(100);
        setDetalle('none');
        setSubirNotas('none');
        setCargando('none');
        setError('block');
        setExito('none');
        setMensaje(msg);
        setCambios(cambios + 1);
    }
    const onChangeHandler = (evt) => {
        setSelectedFile(evt.target.files[0]);
        setCambios(cambios + 1);
    }
    const submitForm = (evt) => {
        evt.preventDefault();
        cargar('Subiendo archivo...', 0);
        const data = new FormData();
        data.append('file', selectedFile);
        axios.post(process.env.REACT_APP_DEFAULT_URL+ "/api/upload/registro_academico/notas", data, {})
            .then(res => {
                if (res.status == 200) {
                    cargar('Procesando archivo...', 30);
                    const info = { path: res.data.res, actividad_UniqueID: props.actividad };
                    axios.post(process.env.REACT_APP_DEFAULT_URL+ "/api/upload/registro_academico/notas/procesar", info, {})
                        .then(resultado => {
                            if (resultado.status == 200) {
                                if (resultado.data.errores.length > 0) {
                                    operacionError('Revisar el formato del archivo subido');
                                } else {
                                    operacionExito('Notas Cargadas Al Sistema Exitosamente');
                                }
                            } else {
                                operacionError(resultado.data);
                            }
                        });
                } else {
                    operacionError(res.data);
                }
            });
    }
    return (
        <Container>
            <Row style={{ display: detalle }}>
                <Form.Group className="mb-3">
                    <Form.Label>Buscar</Form.Label>
                    <Form.Control value={filterData} onChange={onChange} placeholder="Puedes buscar por nombres, apellidos o correo" />
                </Form.Group>
            </Row>
            <Row style={{ marginBottom: '20px', display: detalle }}>
                <Button onClick={goSubirNotas} variant='outline-dark'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-upload" viewBox="0 0 16 16">
                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                    <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
                </svg>&nbsp;&nbsp;&nbsp;&nbsp;Subir notas</Button>
            </Row>
            <Row style={{ marginBottom: '20px', display: detalle }}>
                <Button onClick={downloadReporte} variant='outline-dark'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                </svg>&nbsp;&nbsp;&nbsp;&nbsp;Descargar reporte de actividad</Button>
            </Row>
            <Row style={{ display: detalle }}>
                <Accordion flush variant="dark">
                    {filteredData.map((participante) =>
                        <Accordion.Item eventKey={participante.UniqueID} key={participante.UniqueID}>
                            <Accordion.Header>{participante.Nombres + ' ' + participante.Apellidos + ' (' + participante.Correo + ')'}</Accordion.Header>
                            <Accordion.Body>
                                <Entregable key={participante.registro_academico} actividad={props.actividad} registro_academico={participante.registro_academico} nombres={participante.Nombres + ' ' + participante.Apellidos} ponderacion={props.ponderacion} />
                            </Accordion.Body>
                        </Accordion.Item>
                    )}
                </Accordion>
            </Row>
            <Row style={{ display: subirNotas }}>
                <Form onSubmit={submitForm}>
                    <Container>
                        <Row>
                            <Form.Group controlId="formFileMultiple" className="mb-3">
                                <Form.Label>Seleccione el archivo con las notas</Form.Label>
                                <Form.Control required type="file" onChange={onChangeHandler} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                            </Form.Group>
                        </Row>
                        <Row style={{ marginBottom: '25px' }}>
                            <Button variant="outline-secondary" type="submit">Subir Notas</Button>
                        </Row>
                        <Row>
                            <Button onClick={goBack} variant='outline-dark'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                            </svg>&nbsp;&nbsp;&nbsp;&nbsp;Cancelar</Button>
                        </Row>
                    </Container>
                </Form>
            </Row>
            <Row style={{ display: error, padding: '2%' }}>
                <Alert variant="danger">
                    <Alert.Heading>Ocurrió un error al procesar el archivo</Alert.Heading>
                    <p>
                        {mensaje}
                    </p>
                    <hr />
                    <div className="d-flex justify-content-end">
                        <Button onClick={goBack} variant='outline-danger'>Ok</Button>
                    </div>
                </Alert>
            </Row>
            <Row style={{ display: exito, padding: '2%' }}>
                <Alert variant="success">
                    <Alert.Heading>Operación realizada con exito</Alert.Heading>
                    <p>
                        {mensaje}
                    </p>
                    <hr />
                    <div className="d-flex justify-content-end">
                        <Button onClick={goBack} variant='outline-success'>Ok</Button>
                    </div>
                </Alert>
            </Row>
            <Row style={{ display: cargando, padding: '2%' }}>
                <Alert variant="secondary">
                    <Alert.Heading>Procesando el archivo</Alert.Heading>
                    <p>
                        {mensaje}
                    </p>
                    <ProgressBar striped variant="dark" now={status} />
                    <hr />
                </Alert>
            </Row>
        </Container>
    );
}

export default PonderarParticipantes