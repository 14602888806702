import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Form } from 'react-bootstrap'
//import DEFAULT_URL from '../../api/url';
import axios from '../../api/axios';
const VerMails = () => {
    const [contador, setContador] = useState(0);
    const [cambios, setCambios] = useState(0);
    const [usuario, setUsuario] = useState({});
    const [mails, setMails] = useState([]);
    useEffect(() => {
        //'/api/email/ver-correos-programados'
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/auth/userinfo', { cache: "no-cache", credentials: 'include' })
            .then((res) => {
                if (res.status == 401) {
                    window.location.reload();
                } else {
                    return res.json();
                }
            })
            .then(data => {
                setUsuario(data);
                fetch(process.env.REACT_APP_DEFAULT_URL + '/api/email/ver-correos-programados/' + data.UniqueID)
                    .then((res) => {
                        return res.json();
                    })
                    .then(data => {
                        setMails(data.resultado);
                    });

            });
    }, [contador]);
    useEffect(() => {

    }, [cambios]);
    const sendMailDePrueba = (id) => {
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/email/sent-prueba/' + id)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                //console.log(data);
            });
    }
    const deleteEnvio = (id) => {
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/email/delete-envio/' + id)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                //console.log(data);
                setContador(contador + 1);
            });
    }
    const sendNow = (id) => {
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/email/send-now/' + id)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                //console.log(data);
                setContador(contador + 1);
            });
    }
    return (

        <Container fluid>
            {mails.map((actual, index) => <Row key={'mail-' + index} style={{ padding: '2%' }}>
                <Col xs={12} md={12} lg={4}>
                    <h3 style={{ textAlign: 'left' }}><strong>{actual.subject}</strong></h3>
                    <p style={{ textAlign: 'left' }}>
                        <strong>Fecha de envío: </strong>{actual.fecha}<br />
                        <strong>Destinatarios: </strong>{actual.to.length}
                    </p>
                    <Container style={{ marginTop: '25px' }}>
                        <Row>
                            <Col xs={12} md={12} lg={12} style={{ marginTop: '25px' }}>
                                <Button variant='outline-success' onClick={() => { sendNow(actual._id) }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-send-check-fill" viewBox="0 0 16 16">
                                    <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 1.59 2.498C8 14 8 13 8 12.5a4.5 4.5 0 0 1 5.026-4.47L15.964.686Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z" />
                                    <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686Z" />
                                </svg>&nbsp;&nbsp;&nbsp;&nbsp;Enviar ahora</Button>
                            </Col>
                            <Col xs={12} md={12} lg={12} style={{ marginTop: '25px' }}>
                                <Button variant='outline-danger' onClick={() => { deleteEnvio(actual._id) }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-send-x-fill" viewBox="0 0 16 16">
                                    <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 1.59 2.498C8 14 8 13 8 12.5a4.5 4.5 0 0 1 5.026-4.47L15.964.686Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z" />
                                    <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-4.854-1.354a.5.5 0 0 0 0 .708l.647.646-.647.646a.5.5 0 0 0 .708.708l.646-.647.646.647a.5.5 0 0 0 .708-.708l-.647-.646.647-.646a.5.5 0 0 0-.708-.708l-.646.647-.646-.647a.5.5 0 0 0-.708 0Z" />
                                </svg>&nbsp;&nbsp;&nbsp;&nbsp;Cancelar envio</Button>
                            </Col>
                            <Col xs={12} md={12} lg={12} style={{ marginTop: '25px' }}>
                                <Button variant='outline-warning' onClick={() => { sendMailDePrueba(actual._id) }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-send-exclamation" viewBox="0 0 16 16">
                                    <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855a.75.75 0 0 0-.124 1.329l4.995 3.178 1.531 2.406a.5.5 0 0 0 .844-.536L6.637 10.07l7.494-7.494-1.895 4.738a.5.5 0 1 0 .928.372l2.8-7Zm-2.54 1.183L5.93 9.363 1.591 6.602l11.833-4.733Z" />
                                    <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm.5-5v1.5a.5.5 0 0 1-1 0V11a.5.5 0 0 1 1 0Zm0 3a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z" />
                                </svg>&nbsp;&nbsp;&nbsp;&nbsp;Enviar correo de prueba</Button>
                            </Col>
                        </Row>
                    </Container>
                </Col>
                <Col xs={12} md={12} lg={8}>
                    <Container style={{ marginTop: '25px', borderLeft: '4px solid #1e3d52' }}>
                        <Row style={{ padding: '5%' }}>
                            <h4>Vista previa</h4>
                            <iframe srcDoc={actual.html} loading='lazy' />
                        </Row>
                    </Container>
                </Col>
            </Row >)}
        </Container >
    )
}

export default VerMails