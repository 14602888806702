import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Container, Row, Col, Button, Form } from 'react-bootstrap'
//import DEFAULT_URL from '../../api/url'
const UpdatePago = () => {
  const [contador] = useState(0);
  const [cobro, setCobro] = useState({});
  const { id } = useParams();
  const [cambios, setCambios] = useState(0);
  const [moneda, setMoneda] = useState('');
  const [total, setTotal] = useState(0.00);
  const [concepto, setConcepto] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [cargando, setCargando] = useState(false);
  const [exito, setExito] = useState(false);
  const [error, setError] = useState(false);
  const [form, setForm] = useState(true);
  const [eventos, setEventos] = useState([]);
  const [evento, setEvento] = useState('');
  const [validated, setValidated] = useState(false);
  useEffect(() => {
    fetch(process.env.REACT_APP_DEFAULT_URL + '/api/cobro/view/' + id, { cache: "no-cache", credentials: 'include' })
      .then((res) => {
        return res.json();
      })
      .then(data => {
        setCobro(data.rows[0]);
        setEvento(data.rows[0].evento);
        setMoneda(data.rows[0].moneda);
        setTotal(data.rows[0].total_num);
        setConcepto(data.rows[0].concepto);
        setDescripcion(data.rows[0].descripcion);
      });
    fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/evento/inscripciones/abiertas', { cache: "no-cache", credentials: 'include' })
      .then((res) => {
        return res.json();
      })
      .then(data => {
        setEventos(data.rows);
      });
    return true;
  }, [contador]);
  useEffect(() => {
    return true;
  }, [cambios]);
  const onChange = (evt) => {
    if (evt.target.name == 'moneda') {
      setMoneda(evt.target.value);
    } else if (evt.target.name == 'total') {
      setTotal(evt.target.value);
    } else if (evt.target.name == 'concepto') {
      setConcepto(evt.target.value);
    } else if (evt.target.name == 'descripcion') {
      setDescripcion(evt.target.value);
    } else if (evt.target.name == 'evento') {
      setEvento(evt.target.value);
    }
    setCambios(cambios + 1);
  }
  const onSubmit = (evt) => {
    const form = evt.currentTarget;
    if (form.checkValidity() === false) {
      evt.preventDefault();
      evt.stopPropagation();
    } else {
      evt.preventDefault();
      setCargando(true);
      setCambios(cambios + 1);
      /*
                              req.body.moneda,
                        req.body.total,
                        req.body.concepto,
                        req.body.descripcion, 
                        req.body.UniqueID
      */
      let info = { moneda: moneda, total: total, concepto: concepto, evento: evento, descripcion: descripcion, UniqueID: id };
      const requestOptions = {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(info)
      };
      fetch(process.env.REACT_APP_DEFAULT_URL + '/api/cobro/update', requestOptions)
        .then((res) => {
          return res.json();
        })
        .then(data => {
          if (data.codigo == 200) {
            setCargando(false);
            setExito(true);
            window.location.replace('/pagos-electronicos/view/' + id);
          } else {
            setCargando(false);
            setError(true);
          }
          setCambios(cambios + 1);
        });
    }
    setValidated(true);
    setCambios(cambios + 1);
  }
  return (
    <Container style={{ padding: '2%' }}>
      <Row style={{ padding: '2%', textAlign: 'left', borderLeft: '5px solid #1e3d52 ', backgroundColor: 'rgb(250,250,250)' }}>
        <Container fluid>
          <Row>
            <h1>Editar Cobro electrónico</h1>
          </Row>
          <Row>
            <Col xs={12} md={2} lg={2}>
              <img src={cobro.flier} style={{ width: '100%', padding: '2%' }} />
            </Col>
            <Col xs={12} md={10} lg={10} style={{ padding: '2%' }}>
              <h3>CONCEPTO: {cobro.concepto}</h3>
              <p style={{ textAlign: 'justify' }}><strong>DESCRIPCIÓN:</strong><br />{cobro.descripcion}</p>
              <p><strong>EVENTO: </strong>{cobro.Nombre + ' (' + cobro.fecha_inicio + ')'}</p>
              <p><strong>TOTAL A COBRAR: </strong>{cobro.moneda + ' ' + cobro.total}</p>
              <Button variant='outline-primary' href={'/pagos-electronicos/view/' + cobro.UniqueID}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-escape" viewBox="0 0 16 16">
                <path d="M8.538 1.02a.5.5 0 1 0-.076.998 6 6 0 1 1-6.445 6.444.5.5 0 0 0-.997.076A7 7 0 1 0 8.538 1.02Z" />
                <path d="M7.096 7.828a.5.5 0 0 0 .707-.707L2.707 2.025h2.768a.5.5 0 1 0 0-1H1.5a.5.5 0 0 0-.5.5V5.5a.5.5 0 0 0 1 0V2.732l5.096 5.096Z" />
              </svg>&nbsp;&nbsp;&nbsp;&nbsp;Salir de edición</Button>&nbsp;&nbsp;&nbsp;&nbsp;
            </Col>
          </Row>
        </Container>
      </Row>
      <Row style={{ padding: '2%', textAlign: 'left' }}>
        <Form noValidate validated={validated} onSubmit={onSubmit}>
          <Row style={{ padding: '1%' }}>
            <Form.Group controlId="concepto">
              <Form.Label>Concepto del cobro</Form.Label>
              <Form.Control
                required
                type="text"
                value={concepto}
                placeholder="Concepto del cobro"
                onChange={onChange}
                name='concepto'
                disabled={cargando || exito || error}
              />
              <Form.Control.Feedback>Ok</Form.Control.Feedback>
              <Form.Control.Feedback type='invalid'>Por favor, completa este campo</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row style={{ padding: '1%' }}>
            <Form.Group controlId="descripion">
              <Form.Label>Breve descripcion del cobro</Form.Label>
              <Form.Control
                required
                as='textarea'
                rows={3}
                value={descripcion}
                style={{ resize: 'none' }}
                placeholder="Breve descripcion del cobro"
                onChange={onChange}
                name='descripcion'
                disabled={cargando || exito || error}
              />
              <Form.Control.Feedback>Ok</Form.Control.Feedback>
              <Form.Control.Feedback type='invalid'>Por favor, completa este campo</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row style={{ padding: '1%' }}>
            <Form.Group controlId="total">
              <Form.Label>Total a cobrar</Form.Label>
              <Form.Control
                required
                type="number"
                step={0.01}
                value={total}
                placeholder="Total a cobrar"
                onChange={onChange}
                name='total'
                disabled={cargando || exito || error}
              />
              <Form.Control.Feedback>Ok</Form.Control.Feedback>
              <Form.Control.Feedback type='invalid'>Por favor, completa este campo</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row style={{ padding: '1%' }}>
            <Form.Group controlId="moneda">
              <Form.Label>Tipo moneda</Form.Label>
              <Form.Select required onChange={onChange} name='moneda' value={moneda} disabled={cargando || exito || error}>
                <option value=''>Seleccione la moneda</option>
                <option value="GTQ">GTQ</option>
                <option value="USD">USD</option>
              </Form.Select>
              <Form.Control.Feedback>Ok</Form.Control.Feedback>
              <Form.Control.Feedback type='invalid'>Por favor, completa este campo</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row style={{ padding: '1%' }}>
            <Form.Group controlId="evento">
              <Form.Label>Evento</Form.Label>
              <Form.Select required onChange={onChange} name='evento' value={evento} disabled={cargando || exito || error}>
                <option value=''>Seleccione el evento</option>
                {eventos.map((actual, index) => <option value={actual.UniqueID} key={'evento-seleccion-' + index}>{actual.Nombre + ' (' + actual.Fecha_Inicio + ')'}</option>)}
              </Form.Select>
              <Form.Control.Feedback>Ok</Form.Control.Feedback>
              <Form.Control.Feedback type='invalid'>Por favor, completa este campo</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row style={{ padding: '1%' }}>
            <Button variant='outline-dark' type='submit' disabled={cargando || exito || error}>Guardar cambios</Button>
          </Row>
        </Form>
      </Row>
    </Container>
  )
}

export default UpdatePago