import './ModalMaterial.css'
import React, { useState, useEffect } from 'react';
import { Row, Button, Col, Form, Container } from "react-bootstrap";
//import DEFAULT_URL from '../../../../../api/url';
const FormEditMaterial = (props) => {
    const [nombre, setNombre] = useState(props.nombre);
    const [promocional, setPromocional] = useState(props.promocional);
    const [contador, setContador] = useState(0);
    const [segundos, setSegundos] = useState(props.segundos);
    const [edit, setEdit] = useState(0);
    useEffect(() => {
        setPromocional(props.promocional);
        setSegundos(props.segundos);
        setNombre(props.nombre);
    }, [contador, props.promocional, props.segundos, props.nombre]);
    useEffect(() => {

    }, [edit]);
    const onChange = (evt) => {
        if (evt.target.name == "nombre") {
            setNombre(evt.target.value);
        } else if (evt.target.name == "segundos") {
            setSegundos(evt.target.value);
        } else {
            setPromocional(evt.target.value);
        }
        setEdit(edit + 1);
    }
    const submit = (evt) => {
        evt.preventDefault();
        props.cargar('Sincronizando base de datos', 'Por favor espere...')
        if (props.permiso == 4) {
            const info = { nombre: nombre, UniqueID: props.UniqueID, promocional: promocional, segundos: null };
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(info)
            };
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/update/material', requestOptions)
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    props.back();
                    props.refrescar();
                    setContador(0);
                    if (data.codigo == 200) {
                        props.ok_det();
                    } else {
                        props.err('Ocurrió un error al guardar la información', 'Por recarge la página e intentelo de nuevo.');
                    }
                    Array.from(document.querySelectorAll("input")).forEach(
                        input => (input.value = "")
                    );
                });

        } else if (props.permiso == 6) {
            const info = { nombre: nombre, UniqueID: props.UniqueID, promocional: null, segundos: segundos };
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(info)
            };
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/update/material', requestOptions)
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    props.back();
                    props.refrescar();
                    setContador(0);
                    if (data.codigo == 200) {
                        props.ok_det();
                    } else {
                        props.err('Ocurrió un error al guardar la información', 'Por recarge la página e intentelo de nuevo.');
                    }
                    Array.from(document.querySelectorAll("input")).forEach(
                        input => (input.value = "")
                    );
                });

        } else {
            const info = { nombre: nombre, UniqueID: props.UniqueID, promocional: null, segundos: null };
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(info)
            };
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/update/material', requestOptions)
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    props.back();
                    props.refrescar();
                    setContador(0);
                    if (data.codigo == 200) {
                        props.ok_det();
                    } else {
                        props.err('Ocurrió un error al guardar la información', 'Por recarge la página e intentelo de nuevo.');
                    }
                    Array.from(document.querySelectorAll("input")).forEach(
                        input => (input.value = "")
                    );
                });
        }
    }
    const back = () => {
        setContador(contador + 1);
        props.back();
    }
    return (
        <Form onSubmit={submit}>
            <Container>
                <Row style={{ marginBottom: '45px' }}>
                    <Col xs={12} md={12} lg={12}>
                        <Button variant="dark" onClick={back}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-backspace-fill" viewBox="0 0 16 16">
                                <path d="M15.683 3a2 2 0 0 0-2-2h-7.08a2 2 0 0 0-1.519.698L.241 7.35a1 1 0 0 0 0 1.302l4.843 5.65A2 2 0 0 0 6.603 15h7.08a2 2 0 0 0 2-2V3zM5.829 5.854a.5.5 0 1 1 .707-.708l2.147 2.147 2.146-2.147a.5.5 0 1 1 .707.708L9.39 8l2.146 2.146a.5.5 0 0 1-.707.708L8.683 8.707l-2.147 2.147a.5.5 0 0 1-.707-.708L7.976 8 5.829 5.854z" />
                            </svg>&nbsp;&nbsp;&nbsp;Atrás
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={12} lg={12}>
                        <Form.Group className="mb-3" controlId="material">
                            <Form.Label>Nombre del material</Form.Label>
                            <Form.Control required type="text" name="nombre" placeholder="Ingrese el nombre del material" onChange={onChange} value={nombre} />
                        </Form.Group>
                    </Col>
                </Row>
                {
                    props.permiso == 4 &&
                    <Row>
                        <Col sm={12} md={12} lg={12}>
                            <Form.Group className="mb-3" controlId="material">
                                <Form.Label>Imagen promocional para APP</Form.Label>
                                <Form.Control required type="text" name="promocional" placeholder="Ingrese el url de la imagen promocional" onChange={onChange} value={promocional} />
                            </Form.Group>
                        </Col>
                    </Row>
                }
                {
                    props.permiso == 6 &&
                    <Row>
                        <Form.Group className="mb-3" controlId="promocional">
                            <Form.Label>Duración en espacio publicitario de APP</Form.Label>
                            <Form.Control required readonly type="number" name="segundos" placeholder="tiempo en segundos" onChange={onChange} value={segundos} />
                        </Form.Group>
                    </Row>
                }
                <Row>
                    <Col>
                        <Button variant="dark" className="font-bold" type="submit">Guardar</Button>
                    </Col>
                </Row>
            </Container>
        </Form>
    )
}

export default FormEditMaterial