import React, { Component } from 'react';
import { Row, Button, Modal, Col, Form, Container } from "react-bootstrap";
//import DEFAULT_URL from '../../../../api/url';
export class ModalNuevoCliente extends Component {
    state = {
        empresa: '',
        direccion: '',
        telefono: '',
        NombreFactura: '',
        NIT: '',
        CorreoFacturacion: '',
        ResponsableInscripcion: '',
        TelefonoResponsable: '',
        CorreoResponsable: '',
        ResponsablePago: '',
        CorreoPago: '',
        TelefonoPago: '',
        FormaPago: '',
        formas_pago: [],
        Comentario: '',
        Prioridad: 0
    };
    onChange = (evt) => {
        this.setState({ [evt.target.name]: evt.target.value });
    }
    submit = (evt) => {
        evt.preventDefault();
        if (this.props.submitCliente(this.state)) {
            this.setState({
                empresa: '',
                direccion: '',
                telefono: '',
                NombreFactura: '',
                NIT: '',
                CorreoFacturacion: '',
                ResponsableInscripcion: '',
                TelefonoResponsable: '',
                CorreoResponsable: '',
                ResponsablePago: '',
                CorreoPago: '',
                TelefonoPago: '',
                FormaPago: '',
                Comentario: '',
                Prioridad: 0
            });
        }
    }
    entering = () => {
        this.setState({});
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/forma_pago', { cache: "no-cache" })
            .then((res) => {
                return res.json();
            })
            .then(data => {
                this.setState({ formas_pago: data.rows })
            });
    }
    render() {
        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
                onEnter={this.entering}
            >
                <Modal.Header closeButton style={{ backgroundColor: "#b5bad0" }}>
                    <Modal.Title id="contained-modal-title-vcenter" className="font-bold">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-person-plus-fill" viewBox="0 0 16 16">
                            <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                            <path fillRule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z" />
                        </svg>
                        &nbsp;&nbsp;&nbsp;Ingresar prospecto
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: "#b5bad0" }} className="cuerpo-modal-nuevo-cliente">
                    <Container>
                        <Form onSubmit={this.submit}>
                            <Row>
                                <Col xs={12} md={12}>
                                    <Form.Group className="mb-3" controlId="empresa">
                                        <Form.Label>Nombre De La Empresa o Cliente</Form.Label>
                                        <Form.Control required type="text" placeholder="Ingrese el nombre de la empresa" name="empresa" value={this.state.empresa} onChange={this.onChange} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Form.Group className="mb-3" controlId="direccion">
                                    <Form.Label>Direccion De La Empresa o Cliente</Form.Label>
                                    <Form.Control as="textarea" rows={3} style={{ resize: 'none' }} placeholder="Ingrese la dirección de la empresa" name="direccion" value={this.state.direccion} onChange={this.onChange} />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Col xs={12} md={12}>
                                    <Form.Group className="mb-3" controlId="telefono">
                                        <Form.Label>Teléfono De La Empresa</Form.Label>
                                        <Form.Control required type="text" placeholder="Ingrese el teléfono de la empresa" name="telefono" value={this.state.telefono} onChange={this.onChange} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12}>
                                    <Form.Group className="mb-3" controlId="NombreFactura">
                                        <Form.Label>Razón social</Form.Label>
                                        <Form.Control required type="text" placeholder="Ingrese la razón social de la empresa" name="NombreFactura" value={this.state.NombreFactura} onChange={this.onChange} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12} lg={6}>
                                    <Form.Group className="mb-3" controlId="NIT">
                                        <Form.Label>NIT</Form.Label>
                                        <Form.Control required type="text" placeholder="Ingrese el NIT de la empresa" name="NIT" value={this.state.NIT} onChange={this.onChange} />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={12} lg={6}>
                                    <Form.Group className="mb-3" controlId="CorreoFacturacion">
                                        <Form.Label>Correo Para Recibir La Factura</Form.Label>
                                        <Form.Control required type="email" placeholder="Ingrese el correo para recibir la factura" name="CorreoFacturacion" value={this.state.CorreoFacturacion} onChange={this.onChange} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12}>
                                    <Form.Group className="mb-3" controlId="ResponsableInscripcion">
                                        <Form.Label>Responsable De Inscripcion</Form.Label>
                                        <Form.Control required type="text" placeholder="Ingrese el responsable de inscripcion de la empresa" name="ResponsableInscripcion" value={this.state.ResponsableInscripcion} onChange={this.onChange} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12} lg={6}>
                                    <Form.Group className="mb-3" controlId="CorreoResponsable">
                                        <Form.Label>Correo Electrónico Responsable Inscripción</Form.Label>
                                        <Form.Control required type="email" placeholder="Ingrese el Correo Electrónico Del Responsable De Inscripción" name="CorreoResponsable" value={this.state.CorreoResponsable} onChange={this.onChange} />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={12} lg={6}>
                                    <Form.Group className="mb-3" controlId="TelefonoResponsable">
                                        <Form.Label>Teléfono Responsable Inscripción</Form.Label>
                                        <Form.Control required type="text" placeholder="Ingrese el Teléfono Del Responsable De Inscripción" name="TelefonoResponsable" value={this.state.TelefonoResponsable} onChange={this.onChange} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12}>
                                    <Form.Group className="mb-3" controlId="ResponsablePago">
                                        <Form.Label>Responsable De Pago</Form.Label>
                                        <Form.Control required type="text" placeholder="Ingrese el responsable de pago" name="ResponsablePago" value={this.state.ResponsablePago} onChange={this.onChange} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12} lg={6}>
                                    <Form.Group className="mb-3" controlId="CorreoPago">
                                        <Form.Label>Correo Electrónico Responsable Pago</Form.Label>
                                        <Form.Control required type="email" placeholder="Ingrese el Correo Electrónico Del Responsable De Pago" name="CorreoPago" value={this.state.CorreoPago} onChange={this.onChange} />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={12} lg={6}>
                                    <Form.Group className="mb-3" controlId="TelefonoPago">
                                        <Form.Label>Teléfono Responsable Pago</Form.Label>
                                        <Form.Control required type="text" placeholder="Ingrese el Teléfono Del Responsable  De Pago" name="TelefonoPago" value={this.state.TelefonoPago} onChange={this.onChange} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12}>
                                    <Form.Group className="mb-3" controlId="FormaPago">
                                        <Form.Label>Indique su forma de pago</Form.Label>
                                        <Form.Control as="select" required aria-label="Evento" name="FormaPago" onChange={this.onChange} value={this.state.FormaPago}>
                                            <option value="" key="0">Seleccione la forma de pago</option>
                                            {this.state.formas_pago.map(forma_pago => <option key={forma_pago.UniqueID} value={forma_pago.UniqueID}>{forma_pago.Nombre}</option>)}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12}>
                                    <Form.Group className="mb-3" controlId="comentario">
                                        <Form.Label>Comentario de envío de solicitud</Form.Label>
                                        <Form.Control as="textarea" placeholder="Ingrese sus comentarios sobre la solicitud" rows={3} style={{ resize: 'none' }} name="Comentario" onChange={this.onChange} value={this.state.Comentario} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12}>
                                    <Form.Group className="mb-3" controlId="prioridad">
                                        <Form.Label>Prioridad</Form.Label>
                                        <Form.Control as="select" required aria-label="prioridad" name="Prioridad" onChange={this.onChange} value={this.state.Prioridad}>
                                            <option value="" key="0">Seleccione la prioridad de su solicitud</option>
                                            <option value="0">Baja</option>
                                            <option value="1">Normal</option>
                                            <option value="2">Urgente</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Button variant="secondary" className="font-bold" type="submit">Guardar</Button>
                            </Row>
                        </Form>
                    </Container>
                </Modal.Body>
                <Modal.Footer style={{ backgroundColor: "#b5bad0" }}>
                    <Button variant="secondary" className="font-bold" onClick={this.props.onHide}>Cancelar</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}