import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { Container, Row, Navbar, NavDropdown, Nav } from 'react-bootstrap'
//import DEFAULT_URL, { SELF_URL } from '../../api/url'
const CRM = () => {
    const [contador, setContador] = useState(0);
    const [usuario, setUsuario] = useState({});
    const [redireccion, setRedireccion] = useState('/');
    useEffect(() => {
        async function fetchData() {
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/auth/userinfo', { cache: "no-cache", credentials: 'include' })
                .then((res) => {
                    if (res.status == 401) {
                        window.location.reload();
                    } else {
                        return res.json();
                    }
                })
                .then(data => {
                    setUsuario(data);
                    if (data.tipo == 2) {
                        setRedireccion('/control-academico');
                    } else if (data.tipo == 1) {
                        setRedireccion('/asesor');
                    } else if (data.tipo == 4) {
                        setRedireccion('/home');
                    }
                });
        }
        fetchData();
    }, [contador]);

    return (
        <Container fluid>
            <Row>
                <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
                    <Container>
                        <Navbar.Brand href={process.env.REACT_APP_SELF_URL+'/crm/dashboard'}><strong>C</strong>ustomer <strong>R</strong>elationship <strong>M</strong>anagement</Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="me-auto">
                                <Nav.Link href={process.env.REACT_APP_SELF_URL+'/crm/nuevo-lead'}>Nuevo Lead</Nav.Link>
                                <Nav.Link href={process.env.REACT_APP_SELF_URL+'/crm/seguimiento'}>Seguimiento Leads</Nav.Link>
                                <Nav.Link href={process.env.REACT_APP_SELF_URL+'/crm/ventas'}>Ventas</Nav.Link>
                            </Nav>
                            <Nav>
                                <Nav.Link eventKey={2} href={redireccion}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-90deg-left" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M1.146 4.854a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H12.5A2.5 2.5 0 0 1 15 6.5v8a.5.5 0 0 1-1 0v-8A1.5 1.5 0 0 0 12.5 5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4z" />
                                    </svg>&nbsp;&nbsp;&nbsp;Regresar a sistema
                                </Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </Row>
            <Row>
                <Outlet />
            </Row>
        </Container>
    )
}

export default CRM