import React, { useState, useEffect } from 'react'
import { Row, Button, Form, Container, Tab, ListGroup, Card, Breadcrumb, Accordion, Col, Tabs } from "react-bootstrap";
import "./style.css"
import DetalleTema from './DetalleTema';
//import DEFAULT_URL from '../../api/url';//:UniqueID
const DetalleDocente = (props) => {
    const [contador, setContador] = useState(0);
    const [filteredData, setFilteredData] = useState([]);
    const [criterio, setCriterio] = useState('');
    const [biblioteca, setBiblioteca] = useState([]);
    const [edit, setEdit] = useState('');
    const [todasLasBibliotecas, setTodasLasBibliotecas] = useState('block');
    const [detalleBiblioteca, setDetalleBiblioteca] = useState('none');
    useEffect(() => {
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/informacion-catalogo/read-catalogo-docente/' + props.docente.UniqueID, { cache: "no-cache" })
            .then((res) => {
                return res.json();
            })
            .then(data => {
                let tmp = [];
                data.salida.map((actual, index) => {
                    const index_finder = (info) => info.idBiblioteca == actual.idBiblioteca;
                    if (tmp.findIndex(index_finder) >= 0) {
                        let item = tmp[tmp.findIndex(index_finder)];
                        item.listado.push(actual);
                        tmp[tmp.findIndex(index_finder)] = item;
                    } else {
                        tmp.push({ idBiblioteca: actual.idBiblioteca, biblioteca: { id: actual.idBiblioteca, nombre: actual.biblioteca, descripcion: actual.descripcionBiblioteca, foto: actual.fotoBiblioteca }, listado: [actual] });
                    }
                    if (index == (data.salida.length - 1)) {
                        setBiblioteca(tmp);
                        setFilteredData(tmp);
                    }
                });
                setCriterio('');
            });
    }, [contador, props.contador]);
    useEffect(() => {
    }, [edit]);

    const onChange = (evt) => {
        setCriterio(evt.target.value);
        setEdit(edit + 1);
        let result = [];
        result = biblioteca.filter((actual) => {
            return actual.biblioteca.nombre.toLowerCase().search(evt.target.value.toLowerCase()) != -1;
        });
        setFilteredData(result);
        setEdit(edit + 1);
    }
    const showDetailBiblioteca = () => {
        setTodasLasBibliotecas('none');
        setDetalleBiblioteca('block');
        setEdit(edit + 1);
    }
    const backToAllBibliotecas = () => {
        setTodasLasBibliotecas('block');
        setDetalleBiblioteca('none');
        setEdit(edit + 1);
    }
    return (
        <Container fluid>
            <Row style={{ marginBottom: '25px', display: todasLasBibliotecas }}>
                <Button variant='outline-dark' onClick={() => { setContador(contador + 1); }}>Refrescar</Button>
            </Row>
            <Row style={{ marginTop: '25px', marginBottom: '25px', display: todasLasBibliotecas }}>
                <Form.Group className="mb-3" controlId="institucion">
                    <Form.Label>Buscar</Form.Label>
                    <Form.Control required type="text" placeholder="puede buscar la o las bibliotecas por nombre" name="criterio" value={criterio} onChange={onChange} />
                </Form.Group>
            </Row>
            <Row style={{ marginTop: '25px' }}>
                <Container fluid>
                    <Tab.Container variant='dark'>
                        <Row style={{ marginBottom: '45px', marginTop: '45px', display: todasLasBibliotecas }}>
                            <ListGroup style={{ textAlign: 'left' }}>
                                <Col xs={12} md={12} lg={12} >
                                    <Row style={{ marginBottom: '45px' }}>
                                        <Breadcrumb variant="dark">
                                            <Breadcrumb.Item active>Oferta formativa</Breadcrumb.Item>
                                        </Breadcrumb>
                                    </Row>
                                    <Row>
                                        {filteredData.map(actual =>
                                            <Col xs={12} md={6} lg={6} style={{ marginBottom: '25px' }} key={actual.idBiblioteca}>
                                                <Card style={{ height: '100%' }}>
                                                    <Card.Img loading="lazy" variant="top" alt='img-catalogo' src={actual.biblioteca.foto} />
                                                    <Card.Body style={{ display: 'grid', placeItems: 'center' }}>
                                                        <Card.Title style={{ textAlign: 'center' }}>{actual.biblioteca.nombre}</Card.Title>
                                                        <ListGroup.Item variant='light' key={actual.idBiblioteca} action href={"#" + actual.idBiblioteca}>
                                                            <h4 style={{ textAlign: 'center', width: '100%', height: '100%' }} onClick={showDetailBiblioteca}>SELECCIONAR</h4>
                                                        </ListGroup.Item>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        )}
                                    </Row>
                                </Col>
                            </ListGroup>
                        </Row>
                        <Row style={{ display: detalleBiblioteca }}>
                            <Tab.Content>
                                {filteredData.map((actual, index) => <Tab.Pane key={actual.idBiblioteca} eventKey={"#" + actual.idBiblioteca}>
                                    <Container fluid>
                                        <Row style={{ marginTop: '25px' }}>
                                            <Col xs={2} md={2} lg={2}>
                                                <svg onClick={backToAllBibliotecas} style={{ cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-backspace-fill" viewBox="0 0 16 16">
                                                    <path d="M15.683 3a2 2 0 0 0-2-2h-7.08a2 2 0 0 0-1.519.698L.241 7.35a1 1 0 0 0 0 1.302l4.843 5.65A2 2 0 0 0 6.603 15h7.08a2 2 0 0 0 2-2V3zM5.829 5.854a.5.5 0 1 1 .707-.708l2.147 2.147 2.146-2.147a.5.5 0 1 1 .707.708L9.39 8l2.146 2.146a.5.5 0 0 1-.707.708L8.683 8.707l-2.147 2.147a.5.5 0 0 1-.707-.708L7.976 8 5.829 5.854z" />
                                                </svg>
                                            </Col>
                                            <Col xs={10} md={10} lg={10}>
                                                <Breadcrumb variant="dark">
                                                    <Breadcrumb.Item active>{props.docente.Nombres + ' ' + props.docente.Apellidos}</Breadcrumb.Item>
                                                    <Breadcrumb.Item active>Oferta formativa</Breadcrumb.Item>
                                                    <Breadcrumb.Item active>{actual.biblioteca.nombre}</Breadcrumb.Item>
                                                </Breadcrumb>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: '25px' }}>
                                            <Container fluid>
                                                <Row style={{ padding: '5%', display: 'flex', placeItems: 'center' }}>
                                                    <Col xs={12} md={12} lg={6}>
                                                        <img src={actual.biblioteca.foto} alt='img-catalogo' style={{ width: '100%' }} />
                                                    </Col>
                                                    <Col xs={12} md={12} lg={6}>
                                                        <h3>{actual.biblioteca.nombre}</h3>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </Row>
                                        <Row style={{ marginTop: '25px' }}>
                                            {actual.listado.map((curr, indice) => <Col xs={12} md={12} lg={6} style={{ marginTop: '15px', marginBottom: '15px', padding: '1%' }} key={indice}>
                                                <DetalleTema biblioteca={actual.biblioteca.nombre} tema={curr} refresh={() => { setContador(contador + 1); }} permiso = {0} />
                                            </Col>)}
                                        </Row>
                                    </Container>
                                </Tab.Pane>)}
                            </Tab.Content>
                        </Row>
                    </Tab.Container>
                </Container>
            </Row >
        </Container>
    )
}

export default DetalleDocente