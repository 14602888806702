import React, { Component } from 'react'
import { Row, Col, Container } from "react-bootstrap";
import './Init.css'
export default class Init extends Component {
    render() {
        return (
            <div className="contenedor-inicio">
                <Container className="contenedor-botones-inicio">
                    <Row className="fila-botones">
                        <Col xs={12} md={6} lg={6} style={{ height: 'auto' }} onClick={() => window.location.replace('/asesor')}>
                            <h2 className="titulo-inicio">Asesores</h2>
                            <div className="imagen-boton columna-botones" style={{ backgroundImage: 'url(https://ik.imagekit.io/p5ethkcnzwh/ASESORES_Vm1NNZyLI.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1651185621089)' }}></div>
                        </Col>
                        <Col xs={12} md={6} lg={6} onClick={() => window.location.replace('/control-academico')}>
                            <h2 className="titulo-inicio">Control Académico</h2>
                            <div className="imagen-boton columna-botones" style={{ backgroundImage: 'url(https://ik.imagekit.io/p5ethkcnzwh/CONTROL-A_6EKLRRQI_.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1650642947533)' }}></div>
                        </Col>
                        <Col xs={12} md={6} lg={6} onClick={() => window.location.replace('/home/direccion-academica')}>
                            <h2 className="titulo-inicio">Dirección Académica</h2>
                            <div className="imagen-boton columna-botones" style={{ backgroundImage: 'url(https://ik.imagekit.io/p5ethkcnzwh/DIRECCION-A_5amXcXLDV.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1650642947159)' }}></div>
                        </Col>
                        <Col xs={12} md={6} lg={6} onClick={() => window.location.replace('/contabilidad')}>
                            <h2 className="titulo-inicio">Contabilidad</h2>
                            <div className="imagen-boton columna-botones" style={{ backgroundImage: 'url(https://ik.imagekit.io/p5ethkcnzwh/IMG-CONTABILIDAD_FJpueI615.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1655752857080)' }}></div>
                        </Col>
                        <Col xs={12} md={6} lg={6} onClick={() => window.location.replace('/home/sub-gerencia-ebg')}>
                            <h2 className="titulo-inicio">Subgerencia EBG</h2>
                            <div className="imagen-boton columna-botones" style={{ backgroundImage: 'url(https://ik.imagekit.io/p5ethkcnzwh/IMG-GERENCIA__1__n4CEwAYuz.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1655751920911)' }}></div>
                        </Col>
                        <Col xs={12} md={6} lg={6} onClick={() => window.location.replace('/diseno')}>
                            <h2 className="titulo-inicio">Diseño</h2>
                            <div className="imagen-boton columna-botones" style={{ backgroundImage: 'url(https://ik.imagekit.io/dmbtwqtjj/Disen%CC%83o_tPS1Ecgg7.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1658254125508)' }}></div>
                        </Col>
                        <Col xs={12} md={12} lg={12} onClick={() => window.location.replace('/home')}>
                            <h2 className="titulo-inicio">Administrador</h2>
                            <div className="imagen-boton columna-botones" style={{ backgroundImage: 'url(https://ik.imagekit.io/p5ethkcnzwh/ADMINISTRADOR_gK3Jf9KSM.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1650903936550)' }}></div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
