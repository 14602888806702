//import DEFAULT_URL from '../api/url';
import useAuth from '../useAuth'
export const useRefreshToken = () => {
  const { setAuth } = useAuth();
  const refresh = async () => {
    
    await fetch(process.env.REACT_APP_DEFAULT_URL + '/api/auth/verify', { cache: "no-cache", credentials: 'include', })
      .then((res) => {
        return res.json();
      })
      .then(data => {
        //const response = await axios.get(process.env.REACT_APP_DEFAULT_URL+ '/api/auth/verify', { withCredentials: true });
        setAuth(prev => {
          //console.log(JSON.stringify(prev));
          //console.log(response.data.accessToken);
          //localStorage.setItem("CEA", data.accessToken );
          return { ...prev, roles: [data.rol], accessToken: data.accessToken }
        });
        return data.accessToken;
      });


    /*
        const response = await axios.get(process.env.REACT_APP_DEFAULT_URL+ '/api/auth/verify', { withCredentials: true });
        setAuth(prev => {
          //console.log(JSON.stringify(prev));
          //console.log(response.data.accessToken);
          return { ...prev, roles: [response.data.rol], accessToken: response.data.accessToken }
        });
        return response.data.accessToken;
        */
  }
  return refresh;
}

export default useRefreshToken;
