import React, { useEffect, useState } from 'react'
import { ListGroup, Row, Badge, Button, Col, Form, Container, Popover, OverlayTrigger } from "react-bootstrap";
//import { SELF_URL } from '../../../../api/url';
//import DEFAULT_URL from '../../../../api/url';
import ModalEditarFormulario from './ModalEditarFormulario';
import { Confirmacion } from '../../../Utils/ConfirmationModal/Confirmacion';
import { SuccessModal } from '../../../Utils/SuccessModal/SuccessModal';
import { ErrorModal } from '../../../Utils/ErrorModal/ErrorModal';
import axios from 'axios';
const DetalleFormulario = (props) => {
    const [contador, setContador] = useState(0);
    const [respuestas, setRespuestas] = useState(0);
    const [preguntas, setPreguntas] = useState([]);
    const [editarFormulario, setEditarFormulario] = useState(false);
    const [confirmacionEliminar, setConfirmacionEliminar] = useState(false);
    const [confirmacionOcultar, setConfirmacionOcultar] = useState(false);
    const [confirmacionPublicar, setConfirmacionPublicar] = useState(false);
    const [mensaje, setMensaje] = useState('');
    const [consideracion, seConsideracion] = useState('');
    const [modalSucc, setModalSucc] = useState(false);
    const [modalError, setModalError] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    useEffect(() => {
        async function fetchData() {
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/formulario/respuestas/' + props.formulario.UniqueID, { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    if (data.respuestas == null) {
                        setRespuestas(0);
                    } else {
                        setRespuestas(data.respuestas.respuestas);
                    }
                });
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/formulario/preguntas/' + props.formulario.UniqueID, { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    let pregunts = [];
                    if (data.preguntas != null) {
                        data.preguntas.map((pregunta) => {
                            pregunts.push({ pregunta: pregunta.pregunta, tipo: pregunta.tipo_pregunta_UniqueID });
                        });
                    }
                    setPreguntas(pregunts);
                });
        }
        fetchData();
    }, [contador]);
    const onChangeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setContador(contador + 1);
    };
    const ocultarEliminarConfirmacion = () => {
        setConfirmacionEliminar(false);
    }
    const ocultarOcultarConfirmacion = () => {
        setConfirmacionOcultar(false);
    }
    const ocultarPublicarConfirmacion = () => {
        setConfirmacionPublicar(false);
    }
    const cerrar = () => {
        setModalError(false);
        setModalSucc(false);
        props.refresh();
    }
    const eliminar = (id, param) => {
        const info = {
            UniqueID: id
        }
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/delete/formulario', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                if (data.codigo == 200) {
                    setModalSucc(true);
                    setMensaje('Formulario eliminado del sistema exitosamente');
                    ocultarEliminarConfirmacion();
                    setContador(contador + 1);
                    props.refrescarInfo();
                } else {
                    setModalError(true);
                    ocultarEliminarConfirmacion();
                    setMensaje('ERROR');
                }
            });
    }
    const hideEditar = () => {
        setEditarFormulario(false);
    }
    const popover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">¡Listo!</Popover.Header>
            <Popover.Body>
                Enlace copiado exitosamente.
            </Popover.Body>
        </Popover>
    );
    const ocultarFormulario = (id, param) => {
        const info = {
            UniqueID: id
        }
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/ocultar/formulario', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                if (data.codigo == 200) {
                    setModalSucc(true);
                    setMensaje('Formulario oculto exitosamente');
                    ocultarOcultarConfirmacion();
                    setContador(contador + 1);
                    props.refrescarInfo();
                } else {
                    setModalError(true);
                    ocultarOcultarConfirmacion();
                    setMensaje('ERROR');
                }
            });
    }
    const publicarFormulario = (id, param) => {
        const info = {
            UniqueID: id
        }
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/publicar/formulario', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                if (data.codigo == 200) {
                    setModalSucc(true);
                    setMensaje('Formulario publicado exitosamente');
                    ocultarPublicarConfirmacion();
                    setContador(contador + 1);
                    props.refrescarInfo();
                } else {
                    setModalError(true);
                    ocultarPublicarConfirmacion();
                    setMensaje('ERROR');
                }
            });
    }
    const submitFile = (evt) => {
        evt.preventDefault();
        const data = new FormData();
        data.append('file', selectedFile);
        axios.post(process.env.REACT_APP_DEFAULT_URL+ "/api/upload/registro_academico/notas", data, {})
            .then(res => {
                if (res.status == 200) {
                    const info = { path: res.data.res, id: props.formulario.UniqueID };
                    axios.post(process.env.REACT_APP_DEFAULT_URL+ "/api/submit/formulario/archivo/procesar", info, {})
                        .then(resultado => {
                            if (resultado.status == 200) {
                                props.onHide();
                                if (resultado.data.errores.length > 0) {
                                    setMensaje('Revisar el formato del archivo subido');
                                    setModalError(true);
                                } else {
                                    setMensaje('Archivo procesado exitosamente');
                                    setModalSucc(true);
                                }
                            } else {
                                setMensaje(resultado.data);
                                setModalError(true);
                            }
                        });
                } else {
                    setMensaje(res.data);
                    setModalError(true);
                }
            });
    }
    return (
        <Container>
            <Row style={{ marginBottom: '20px' }}>
                <Col xs={1} md={1} lg={1}>
                    <div style={{ color: 'white', cursor: 'pointer' }} data-toggle="tooltip" data-placement="top" title="Eliminar formulario" onClick={() => { setMensaje('¿Está seguro@ de eliminar el formulario seleccionado?'); seConsideracion('Esta acción no se puede revertir'); setConfirmacionEliminar(true); }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-trash3-fill" viewBox="0 0 16 16">
                            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                        </svg>
                    </div>
                </Col>
                <Col xs={1} md={1} lg={1}>
                    <div style={{ color: 'white', cursor: 'pointer' }} data-toggle="tooltip" data-placement="top" title="Editar formulario" onClick={() => { setContador(contador + 1); setEditarFormulario(true); }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-pencil-fill" viewBox="0 0 16 16">
                            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                        </svg>
                    </div>
                </Col>
                <Col xs={1} md={1} lg={1}>
                    <div style={{ color: 'white', cursor: 'pointer' }} data-toggle="tooltip" data-placement="top" title="Refrescar" onClick={() => setContador(contador + 1)} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                            <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                        </svg>
                    </div>
                </Col>
            </Row>
            <Row>
                <ListGroup as="ol" >
                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start"
                    >
                        <div className="ms-2 me-auto">
                            <div className="fw-bold">Fecha de creación</div>
                            {props.formulario.fecha}
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start"
                    >
                        <div className="ms-2 me-auto">
                            <div className="fw-bold">Título de formulario</div>
                            {props.formulario.titulo}
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start"
                    >
                        <div className="ms-2 me-auto">
                            <div className="fw-bold">Correlativo</div>
                            {props.formulario.correlativo}
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start"
                    >
                        <div className="ms-2 me-auto">
                            <div className="fw-bold">Imagen promocional</div>
                        </div>
                        <a href={props.formulario.promocional} target="_blank" data-toggle="tooltip" data-placement="top" title="Ver imagen promocional" style={{ cursor: 'pointer', color: 'black' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16">
                                <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                            </svg>
                            <i className="bi bi-cloud-arrow-down-fill"></i>
                        </a>
                        <OverlayTrigger trigger="click" placement="left" overlay={popover} rootClose>
                            <div data-toggle="tooltip" data-placement="top" title="Copiar enlace de promocional" style={{ cursor: 'pointer', paddingLeft: '10px' }} onClick={() => navigator.clipboard.writeText(props.formulario.promocional)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard-check" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                                    <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                                    <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                                </svg>
                            </div>
                        </OverlayTrigger>
                    </ListGroup.Item>
                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start"
                    >
                        <div className="ms-2 me-auto" style={{ cursor: 'pointer' }}>
                            <div className="fw-bold">Disponible</div>
                            {props.formulario.disponible == 0 && <div data-toggle="tooltip" data-placement="top" title="Click para publicar formulario"><Badge bg="danger" onClick={() => { setConfirmacionPublicar(true); setMensaje('¿Está segur@ de publicar este formulario?'); seConsideracion('Esta acción se puede revertir'); setContador(contador + 1); }}>No disponible</Badge></div>}
                            {props.formulario.disponible == 1 && <div data-toggle="tooltip" data-placement="top" title="Click para ocultar formulario"><Badge bg="success" onClick={() => { setConfirmacionOcultar(true); setMensaje('¿Está segur@ de ocultar este formulario?'); seConsideracion('Esta acción se puede revertir'); setContador(contador + 1); }}>Disponible</Badge></div>}
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start"
                    >
                        <div className="ms-2 me-auto">
                            <div className="fw-bold">Formulario</div>
                        </div>
                        <a href={process.env.REACT_APP_SELF_URL + '/formularios/' + props.formulario.correlativo} target="_blank" data-toggle="tooltip" data-placement="top" title="Ver formulario" style={{ cursor: 'pointer', color: 'black' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16">
                                <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                            </svg>
                            <i className="bi bi-cloud-arrow-down-fill"></i>
                        </a>
                        <OverlayTrigger trigger="click" placement="left" overlay={popover} rootClose>
                            <div data-toggle="tooltip" data-placement="top" title="Copiar enlace de formulario" style={{ cursor: 'pointer', paddingLeft: '10px' }} onClick={() => navigator.clipboard.writeText(process.env.REACT_APP_SELF_URL + '/formularios/' + props.formulario.correlativo)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard-check" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                                    <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                                    <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                                </svg>
                            </div>
                        </OverlayTrigger>
                    </ListGroup.Item>
                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start"
                    >
                        <div className="ms-2 me-auto">
                            <div className="fw-bold">
                                Respuestas &nbsp;
                                <Badge bg="secondary" pill>
                                    {respuestas}
                                </Badge>
                            </div>
                        </div>
                        <a href={process.env.REACT_APP_SELF_URL + '/formularios/respuestas/' + props.formulario.correlativo} target="_blank" data-toggle="tooltip" data-placement="top" title="Ver formulario" style={{ cursor: 'pointer', color: 'black' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16">
                                <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                            </svg>
                            <i className="bi bi-cloud-arrow-down-fill"></i>
                        </a>
                        <OverlayTrigger trigger="click" placement="left" overlay={popover} rootClose>
                            <div data-toggle="tooltip" data-placement="top" title="Copiar enlace de formulario" style={{ cursor: 'pointer', paddingLeft: '10px' }} onClick={() => navigator.clipboard.writeText(process.env.REACT_APP_SELF_URL + '/formularios/respuestas/' + props.formulario.correlativo)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard-check" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                                    <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                                    <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                                </svg>
                            </div>
                        </OverlayTrigger>
                    </ListGroup.Item>
                </ListGroup>
            </Row>
            <Row>
                <Form onSubmit={submitFile}>
                    <Form.Group controlId="formFileMultiple" className="mb-3">
                        <Form.Label>Seleccione el archivo con las notas</Form.Label>
                        <Form.Control required type="file" onChange={onChangeHandler} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                    </Form.Group>
                    <Button variant="dark" type="submit">Subir Archivo</Button>
                </Form>
            </Row>
            <ModalEditarFormulario formulario={props.formulario} preguntas={preguntas} show={editarFormulario} onHide={hideEditar} refresh={() => { setContador(contador + 1); props.refrescarInfo(); }} />
            <Confirmacion
                show={confirmacionEliminar}
                onHide={ocultarEliminarConfirmacion}
                accion={eliminar}
                mensaje={mensaje}
                uniqueid={props.formulario.UniqueID}
                consideracion={consideracion}
                param=''
            />
            <Confirmacion
                show={confirmacionOcultar}
                onHide={ocultarOcultarConfirmacion}
                accion={ocultarFormulario}
                mensaje={mensaje}
                uniqueid={props.formulario.UniqueID}
                consideracion={consideracion}
                param=''
            />
            <Confirmacion
                show={confirmacionPublicar}
                onHide={ocultarPublicarConfirmacion}
                accion={publicarFormulario}
                mensaje={mensaje}
                uniqueid={props.formulario.UniqueID}
                consideracion={consideracion}
                param=''
            />
            <ErrorModal
                color={"#E0E0E2"}
                show={modalError}
                onHide={cerrar}
                mensaje={mensaje}
                consideracion={''}
            />
            <SuccessModal
                color={"#E0E0E2"}
                show={modalSucc}
                onHide={cerrar}
                mensaje={mensaje}
                consideracion={''}
                enlace={''}
                llevaenlace={false}
            />

        </Container >
    )
}

export default DetalleFormulario