import React from 'react';
import { Modal, Button, Form } from "react-bootstrap";
import "../InscripcionCliente.css"
const ModalFacturacionData = (props) => {
    const submit = (evt) => {
        evt.preventDefault();
        props.submit();
        props.onHide();
    }
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
            backdrop='static'
        >
            <Modal.Header style={{ backgroundColor: "#E0E0E2" }}>
                <Modal.Title id="contained-modal-title-vcenter" className="font-bold">
                    Información de facturación
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: "#E0E0E2", height: '60vh' }} id="body-modal">
                <Form onSubmit={submit}>
                    <Form.Group className="mb-3" controlId="Fecha_Max_FACT">
                        <Form.Label>Fecha máxima que reciben facturas</Form.Label>
                        <Form.Control required type="date" name="fecha_Max_FACT" placeholder="Seleccione la fecha" onChange={props.cambioencabezado} value={props.fecha_Max_FACT} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="NIT">
                        <Form.Label>NIT</Form.Label>
                        <Form.Control required disabled={props.agremiado} type="text" placeholder="CF" name="nIT" onChange={props.cambioencabezado} value={props.nIT} pattern="([0-9]+K*)|CF" title="El nit debe ir sin guiones y si es de terminación 'K' ingresarla en mayúscula. Se permite poner 'CF'." />                    </Form.Group>
                    <Form.Group className="mb-3" controlId="Nombre_FACT">
                        <Form.Label>Nombre para la factura</Form.Label>
                        <Form.Control required type="text" disabled={props.agremiado} name="nombre_FACT" placeholder="Consumidor Final" onChange={props.cambioencabezado} value={props.nombre_FACT} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="Direccion">
                        <Form.Label>Dirección</Form.Label>
                        <Form.Control required type="text" disabled={props.agremiado} placeholder="Ciudad" name="direccion" onChange={props.cambioencabezado} value={props.direccion} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="Correo_FACT">
                        <Form.Label>Correo para enviar factura electrónica</Form.Label>
                        <Form.Control required type="email" placeholder="Por favor ingrese el correo" name="correo_FACT" onChange={props.cambioencabezado} value={props.correo_FACT} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="Forma_Pago_UniqueID">
                        <Form.Label>Indique su forma de pago</Form.Label>
                        <Form.Control as="select" required aria-label="Evento" name="forma_Pago_UniqueID" onChange={props.cambioencabezado} value={props.forma_Pago_UniqueID}>
                            <option value="" key="0">Seleccione la forma de pago</option>
                            {props.formas_pago.map(forma_pago => <option key={forma_pago.UniqueID} value={forma_pago.UniqueID}>{forma_pago.Nombre}</option>)}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="Comentario">
                        <Form.Label>Comentario sobre el pago</Form.Label>
                        <Form.Control as="textarea" rows={3} style={{ resize: 'none' }} name="comentario" onChange={props.cambioencabezado} value={props.comentario} readOnly />
                    </Form.Group>
                    <Button variant="secondary" className="font-bold" type='submit'>Finalizar registro de inscripción</Button>
                </Form>
            </Modal.Body>
        </Modal>)
}

export default ModalFacturacionData