import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Form } from 'react-bootstrap'
//import DEFAULT_URL from '../../api/url';
import axios from '../../api/axios';
const NuevoMail = () => {
    const [contador] = useState(0);
    const [cambios, setCambios] = useState(0);
    const [usuario, setUsuario] = useState({});
    const [titulo, setTitulo] = useState('');
    const [subtitulo, setSubtitulo] = useState('');
    const [contenido, setContenido] = useState('');
    const [imagen, setImagen] = useState(null);
    const [fecha, setFecha] = useState('');
    const [asunto, setAsunto] = useState('');
    const [destinatarios, setDestinatarios] = useState([]);
    const [validated, setValidated] = useState(false);
    const [cargando, setCargando] = useState(false);
    const [exito, setExito] = useState(false);
    const [error, setError] = useState(false);
    const [destinatario, setDestinatario] = useState('');
    const [excelFile, setExcelFile] = useState(null);
    const [criterio, setCriterio] = useState(0);
    const [listaEventos, setListaEventos] = useState([]);
    const [enlace, setEnlace] = useState('');
    const [nombreEnlace, setNombreEnlace] = useState('');
    useEffect(() => {
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/auth/userinfo', { cache: "no-cache", credentials: 'include' })
            .then((res) => {
                if (res.status == 401) {
                    window.location.reload();
                } else {
                    return res.json();
                }
            })
            .then(data => {
                setUsuario(data);
                fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/asesor/inscripciones-en-agenda/' + data.UniqueID)
                    .then((res) => {
                        return res.json();
                    })
                    .then(data => {
                        setListaEventos(data.rows);
                    });

            });
    }, [contador]);
    useEffect(() => {

    }, [cambios]);
    const onChange = (evt) => {
        if (evt.target.name == 'titulo') {
            setTitulo(evt.target.value);
        } else if (evt.target.name == 'subtitulo') {
            setSubtitulo(evt.target.value);
        } else if (evt.target.name == 'contenido') {
            setContenido(evt.target.value);
        } else if (evt.target.name == 'imagen') {
            setImagen(evt.target.files[0]);
        } else if (evt.target.name == 'fecha') {
            setFecha(evt.target.value);
        } else if (evt.target.name == 'asunto') {
            setAsunto(evt.target.value);
        } else if (evt.target.name == 'destinatario') {
            setDestinatario(evt.target.value);
        } else if (evt.target.name == 'fileExcel') {
            setExcelFile(evt.target.files[0]);
        } else if (evt.target.name == 'criterio') {
            setCriterio(evt.target.value);
        } else if (evt.target.name == 'nombre-enlace') {
            setNombreEnlace(evt.target.value);
        } else if (evt.target.name == 'enlace') {
            setEnlace(evt.target.value);
        }
        setCambios(cambios + 1);
    }
    const onSubmit = (evt) => {
        const form = evt.currentTarget;
        if (form.checkValidity() === false) {
            evt.preventDefault();
            evt.stopPropagation();
        } else {
            evt.preventDefault();
            setCargando(true);
            if (destinatarios.length > 0) {
                if (imagen != null) {
                    const data = new FormData();
                    data.append('file', imagen);
                    let pic = '';
                    let enlace_envio = {};
                    axios.post(process.env.REACT_APP_DEFAULT_URL+ "/api/files/upload/s3", data, {}).then(res => {
                        if (res.data.resultado.Location != null) {
                            pic = res.data.resultado.Location;
                        } else {
                            pic = null;
                        }
                        if (enlace != '' && nombreEnlace != '') {
                            enlace_envio.url = enlace;
                            enlace_envio.titulo = nombreEnlace;
                        } else {
                            enlace_envio = null;
                        }
                        const info = {
                            html: null,
                            titulo: titulo,
                            asesor: usuario,
                            subtitulo: subtitulo,
                            contenido: contenido,
                            img: pic,
                            fecha: fecha,
                            subject: asunto,
                            to: destinatarios,
                            enlace: enlace_envio
                        };
                        const requestOptions = {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(info)
                        };
                        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/email/programar-envio', requestOptions)
                            .then((res) => {
                                return res.json();
                            })
                            .then(data => {
                                if (data.codigo == 200) {
                                    setCargando(false);
                                    setExito(true);
                                } else {
                                    setCargando(false);
                                    setError(true);
                                }
                                setCambios(cambios + 1);
                            });

                    });
                } else {
                    let enlace_envio = {};
                    if (enlace != '' && nombreEnlace != '') {
                        enlace_envio.url = enlace;
                        enlace_envio.titulo = nombreEnlace;
                    } else {
                        enlace_envio = null;
                    }
                    const info = {
                        html: null,
                        titulo: titulo,
                        asesor: usuario,
                        subtitulo: subtitulo,
                        contenido: contenido,
                        img: null,
                        fecha: fecha,
                        subject: asunto,
                        to: destinatarios,
                        enlace: enlace_envio
                    };
                    const requestOptions = {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(info)
                    };
                    fetch(process.env.REACT_APP_DEFAULT_URL + '/api/email/programar-envio', requestOptions)
                        .then((res) => {
                            return res.json();
                        })
                        .then(data => {
                            if (data.codigo == 200) {
                                setCargando(false);
                                setExito(true);
                            } else {
                                setCargando(false);
                                setError(true);
                            }
                            setCambios(cambios + 1);
                        });
                }
            }
        }
        setValidated(true);
        setCambios(cambios + 1);
    }
    const addDestinatario = () => {
        if (destinatario != '') {
            if (validateEmail(destinatario)) {
                insertDestinatario(destinatario);
            }
        }
    }
    const insertDestinatario = (mail) => {
        const index_finder = (curr) => curr == mail;
        if (destinatarios.findIndex(index_finder) == -1) {
            let tmp = destinatarios;
            tmp.push(mail);
            setDestinatarios(tmp);
            setDestinatario('');
            setCambios(cambios + 1);
        }
    }
    const validateEmail = (email) => {
        let regex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        return regex.test(email);
    }
    const addExcelFile = () => {
        if (excelFile != null) {
            const data = new FormData();
            data.append('file', excelFile);
            axios.post(process.env.REACT_APP_DEFAULT_URL+ "/api/files/upload/inscripcion/memory", data, {}).then(res => {
                if (res.data.codigo == 200) {
                    let tmp = destinatarios;
                    res.data.data.data.map((actual, index) => {
                        const index_finder = (curr) => curr == actual.email;
                        if (tmp.findIndex(index_finder) == -1) {
                            tmp.push(actual.email);
                        }
                        if (index == (res.data.data.data.length - 1)) {
                            setDestinatarios(tmp);
                            setCambios(cambios + 1);
                        }
                    });
                } else {
                }
            });
        } else {
        }

    }
    const addParticipantesCriterio = () => {
        if (criterio == 1) {
            ///api/read/asesor/inscripciones-en-db/:UniqueID
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/asesor/inscripciones-en-db/' + usuario.UniqueID)
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    let tmp = destinatarios;
                    data.rows.map((actual, index) => {
                        const index_finder = (curr) => curr == actual.Correo;
                        if (tmp.findIndex(index_finder) == -1) {
                            if (validateEmail(actual.Correo)) {
                                tmp.push(actual.Correo);
                            }
                        }
                        if (index == (data.rows.length - 1)) {
                            setDestinatarios(tmp);
                            setCambios(cambios + 1);
                        }
                    });
                });
        } else if (criterio == 2) {
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/detalle_inscripcion/all')
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    let tmp = destinatarios;
                    data.rows.map((actual, index) => {
                        const index_finder = (curr) => curr == actual.Correo;
                        if (tmp.findIndex(index_finder) == -1) {
                            if (validateEmail(actual.Correo)) {
                                tmp.push(actual.Correo);
                            }
                        }
                        if (index == (data.rows.length - 1)) {
                            setDestinatarios(tmp);
                            setCambios(cambios + 1);
                        }
                    });
                });
        } else {
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/asesor/inscripciones-en-evt/' + usuario.UniqueID + '/' + criterio)
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    let tmp = destinatarios;
                    data.rows.map((actual, index) => {
                        const index_finder = (curr) => curr == actual.Correo;
                        if (tmp.findIndex(index_finder) == -1) {
                            if (validateEmail(actual.Correo)) {
                                tmp.push(actual.Correo);
                            }
                        }
                        if (index == (data.rows.length - 1)) {
                            setDestinatarios(tmp);
                            setCambios(cambios + 1);
                        }
                    });
                });
        }

    }
    return (
        <Container fluid>
            <Row style={{ padding: '2%' }}>
                <h1>Programación envío correo electrónico masivo</h1>
            </Row>
            <Row style={{ padding: '2%' }}>
                <Form noValidate validated={validated} onSubmit={onSubmit}>
                    <Container>
                        <Row style={{ marginTop: '25px' }}>
                            <Form.Group controlId="fecha">
                                <Form.Label>Fecha y hora de envío del correo</Form.Label>
                                <Form.Control
                                    required
                                    type="datetime-local"
                                    placeholder="Ingrese la fecha de envío del correo"
                                    value={fecha}
                                    onChange={onChange}
                                    name='fecha'
                                    disabled={cargando || exito || error}
                                />
                                <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                <Form.Control.Feedback type='invalid'>Ingrese la fecha de envío del correo</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row style={{ marginTop: '25px' }}>
                            <Form.Group controlId="asunto">
                                <Form.Label>Asunto del correo</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Ingrese el asunto del correo"
                                    value={asunto}
                                    onChange={onChange}
                                    name='asunto'
                                    disabled={cargando || exito || error}
                                />
                                <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                <Form.Control.Feedback type='invalid'>Ingrese el asunto del correo</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row style={{ marginTop: '25px' }}>
                            <Form.Group controlId="titulo">
                                <Form.Label>Título del correo</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Ingrese el título del correo"
                                    value={titulo}
                                    onChange={onChange}
                                    name='titulo'
                                    disabled={cargando || exito || error}
                                />
                                <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                <Form.Control.Feedback type='invalid'>Ingrese el título del correo</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row style={{ marginTop: '25px' }}>
                            <Form.Group controlId="subtitulo">
                                <Form.Label>Subtítulo del correo</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Ingrese el subtítulo del correo"
                                    value={subtitulo}
                                    onChange={onChange}
                                    name='subtitulo'
                                    disabled={cargando || exito || error}
                                />
                                <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                <Form.Control.Feedback type='invalid'>Ingrese el subtítulo del correo</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row style={{ marginTop: '25px' }}>
                            <Form.Group controlId="contenido">
                                <Form.Label>Contenido del correo</Form.Label>
                                <Form.Control
                                    required
                                    as='textarea'
                                    placeholder="Ingrese el contenido del correo"
                                    style={{ resize: 'none' }}
                                    rows={15}
                                    value={contenido}
                                    onChange={onChange}
                                    name='contenido'
                                    disabled={cargando || exito || error}
                                />
                                <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                <Form.Control.Feedback type='invalid'>Ingrese el contenido del correo</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row style={{ marginTop: '25px' }}>
                            <Form.Group controlId="imagen">
                                <Form.Label>Adjuntar imagen (opcional)</Form.Label>
                                <Form.Control
                                    type='file'
                                    placeholder="Puede adjuntar una imagen"
                                    onChange={onChange}
                                    name='imagen'
                                    disabled={cargando || exito || error}
                                    accept="image/*"
                                />
                                <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                <Form.Control.Feedback type='invalid'>Puede adjuntar una imagen</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row>
                            <h5>Adjuntar un enlace</h5>
                            <Col xs={12} md={12} lg={6} style={{ marginTop: '25px' }}>
                                <Row >
                                    <h5>Ingrese el enlace (opcional)</h5>
                                    <Form.Control
                                        type="text"
                                        placeholder="Ingrese el enlace"
                                        value={enlace}
                                        onChange={onChange}
                                        name='enlace'
                                        disabled={cargando || exito || error}
                                    />
                                </Row>
                            </Col>
                            <Col xs={12} md={12} lg={6} style={{ marginTop: '25px' }}>
                                <h5>Texto para indicar acceso al enlace</h5>
                                <Form.Control
                                    type="text"
                                    placeholder="Ingrese el título del enlace"
                                    value={nombreEnlace}
                                    onChange={onChange}
                                    name='nombre-enlace'
                                    disabled={cargando || exito || error}
                                />                            </Col>
                        </Row>
                        <Row style={{ padding: '2%' }}>
                            <h2>Destinatarios ({destinatarios.length})</h2>
                        </Row>
                        <Row>
                            <Col xs={12} md={8} lg={8} style={{ marginTop: '25px' }}>
                                <Row >
                                    <Form.Control
                                        type="email"
                                        placeholder="Ingrese un destinatario"
                                        value={destinatario}
                                        onChange={onChange}
                                        name='destinatario'
                                        disabled={cargando || exito || error}
                                    />
                                </Row>
                            </Col>
                            <Col xs={12} md={8} lg={4} style={{ marginTop: '25px' }}>
                                <Button variant='outline-success' onClick={addDestinatario} disabled={cargando || exito || error} >Añadir destinatario</Button>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '25px' }}>
                            <Button href='https://escuela-bancaria.s3.us-east-2.amazonaws.com/TEMPLATE+CARGA+MASIVA+CORREOS.xlsx' disabled={cargando || exito || error} target="_blank" variant='outline-primary'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-down" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M3.5 10a.5.5 0 0 1-.5-.5v-8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 0 0 1h2A1.5 1.5 0 0 0 14 9.5v-8A1.5 1.5 0 0 0 12.5 0h-9A1.5 1.5 0 0 0 2 1.5v8A1.5 1.5 0 0 0 3.5 11h2a.5.5 0 0 0 0-1h-2z" />
                                <path fillRule="evenodd" d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z" />
                            </svg>Descargar plantilla de excel carga masiva</Button>
                        </Row>
                        <Row>
                            <Col xs={12} md={8} lg={8} style={{ marginTop: '25px' }}>
                                <Row >
                                    <Form.Control
                                        type='file'
                                        placeholder="Puede adjuntar un archivo de excel"
                                        onChange={onChange}
                                        name='fileExcel'
                                        disabled={cargando || exito || error}
                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    />
                                </Row>
                            </Col>
                            <Col xs={12} md={8} lg={4} style={{ marginTop: '25px' }}>
                                <Button variant='outline-success' onClick={addExcelFile} disabled={cargando || exito || error}>Añadir destinatarios de archivo excel</Button>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '25px' }}>
                            <h3>Añadir destinatarios de mi base de datos</h3>
                        </Row>
                        <Row>
                            <Col xs={12} md={8} lg={8} style={{ marginTop: '25px' }}>
                                <Row >
                                    <Form.Select aria-label="criterio" onChange={onChange} value={criterio} name='criterio' disabled={cargando || exito || error} >
                                        <option>Seleccione un criterio</option>
                                        <option value={1}>Todos mis inscritos</option>
                                        {(usuario != null && usuario.tipo == 4) && <option value={2}>Todos los inscritos en la base de datos escuelabancaria.com</option>}
                                        {listaEventos.map((actual, indice) => <option key={'evt-' + indice} value={actual.UniqueID}>{actual.evento}</option>)}
                                    </Form.Select>
                                </Row>
                            </Col>
                            <Col xs={12} md={8} lg={4} style={{ marginTop: '25px' }}>
                                <Button variant='outline-success' onClick={addParticipantesCriterio} disabled={cargando || exito || error} >Añadir destinatarios seleccionados</Button>
                            </Col>
                        </Row>
                        {(!cargando && !error && !exito) && <Row style={{ marginTop: '25px' }}>
                            <Button type='submit' variant='outline-dark'>Programar envío</Button>
                        </Row>}
                        {(!cargando && (error || exito)) && <Row style={{ marginTop: '25px' }}>
                            <Button onClick={() => { window.location.reload(); }} variant='outline-dark'>Programar otro envío</Button>
                        </Row>}
                        {destinatarios.map((actual, index) => <Row style={{ padding: '2%', borderTop: 'solid 2px rgb(230,230,230)', marginTop: '25px' }}>
                            <Col xs={12} md={12} lg={8} style={{ marginTop: '25px' }}>
                                <p style={{ textAlign: 'left' }}>{index + 1})<strong>  {actual}</strong></p>
                            </Col>
                            <Col xs={12} md={12} lg={4} style={{ marginTop: '25px' }}>
                                <Button disabled={cargando || exito || error} variant='outline-danger' onClick={() => { let tmp = destinatarios; tmp.splice(index, 1); setDestinatarios(tmp); setCambios(cambios + 1); }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3" viewBox="0 0 16 16">
                                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                </svg></Button>
                            </Col>
                        </Row>)}
                    </Container>
                </Form>
            </Row>
        </Container>
    )
}

export default NuevoMail

/*
        if (evt.target.name == 'titulo') {
            setTitulo(evt.target.value);
        } else if (evt.target.name == 'subtitulo') {
            setSubtitulo(evt.target.value);
        } else if (evt.target.name == 'contenido') {
            setContenido(evt.target.value);
        } else if (evt.target.name == 'imagen') {
            setImagen(evt.target.files[0]);
        } else if (evt.target.name == 'fecha') {
            setFecha(evt.target.value);
        } else if (evt.target.name == 'asunto') {
            setAsunto(evt.target.value);
        } */