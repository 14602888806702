import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
//import DEFAULT_URL from '../../../../../api/url';
import { Container, Table, Row, Col, Button, Card } from "react-bootstrap";
import './style.css'
const DiplomasYCertificados = () => {
    const { participante } = useParams();
    const [contador, setContador] = useState(0);
    const [diplomas, setDiplomas] = useState([]);
    const [notas, setNotas] = useState([]);
    useEffect(() => {
        async function fetchData() {
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/diplomas/client/' + participante, { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                }).then(data => {
                    if (data.resultado.length == 0) {
                        window.location.replace('/unauthorized');
                    } else {
                        setDiplomas(data.resultado);
                        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/actividad/detail/' + participante, { cache: "no-cache" })
                            .then((res) => {
                                return res.json();
                            }).then(data => {
                                setNotas(data.respuesta);
                            });
                    }
                });
        }
        fetchData();
    }, [contador]);

    return (
        <Container fluid>
            <Row>
                <Col xs={12} md={12} lg={6}>
                    <img src='https://escuela-bancaria.s3.us-east-2.amazonaws.com/Logotipos+Finales+ABG+2022-01.png' alt='logos-ABG' style={{ width: '100%' }} />
                </Col>
                <Col xs={12} md={12} lg={6} style={{ display: 'grid', alignItems: 'center', padding: '10%' }}>
                    <h1>Escuela Bancaria de Guatemala</h1>
                    <h2>Diplomas y certificados</h2>
                    <br />
                    {diplomas.length > 0 && <h5>{diplomas[0].Nombres + ' ' + diplomas[0].Apellidos}</h5>}
                    {diplomas.length > 0 && <h5>{diplomas[0].Correo}</h5>}
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={12} lg={6} style={{ marginTop: '25px' }}>
                    {diplomas.length > 0 && <img src={diplomas[0].flier} alt='flier-evento' style={{ width: '100%' }} />}
                </Col>
                <Col xs={12} md={12} lg={6} style={{ marginTop: '25px' }}>
                    <Container>
                        {diplomas.length > 0 && diplomas.map((actual, index) => <Row style={{ marginTop: '15px', padding: '2%' }} key={index}>
                            <Card style={{ width: '100%' }}>
                                {actual.tipo == 1 && <Card.Header>Diploma</Card.Header>}
                                {actual.tipo == 2 && <Card.Header>Certificado</Card.Header>}
                                <Card.Body>
                                    {actual.tipo == 2 && <Card.Title>{actual.correlativo}</Card.Title>}
                                    <Card.Text>
                                        Generado el {actual.fecha}
                                    </Card.Text>
                                    <Card.Body>
                                        <iframe src={process.env.REACT_APP_DEFAULT_URL + '/api/diplomas/getObject/' + actual.llave_diploma} style={{ width: '100%' }} className='frame-diploma' />
                                    </Card.Body>
                                    <Button variant='outline-success' href={process.env.REACT_APP_DEFAULT_URL + '/api/diplomas/getObject/' + actual.llave_diploma} target="_blank">Descargar o imprimir</Button>
                                </Card.Body>
                            </Card>
                        </Row>)}
                        {/*notas.length > 0 && <Row>
                            <h3>Resumen de notas</h3>
                            <Table striped bordered hover variant="dark">
                                <thead>
                                    <tr>
                                        <th>Actividad</th>
                                        <th>Ponderacion</th>
                                        <th>Nota</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {notas.map((actual) => <tr>
                                        <td>{actual.actividad}</td>
                                        <td>{actual.ponderacion}</td>
                                        <td>{actual.nota}</td>
                                    </tr>)}
                                    <tr>
                                        <td>TOTAL</td>
                                        <td>
                                            {notas.reduce((accumulator, object) => {
                                                return accumulator + object.ponderacion;
                                            }, 0)}
                                        </td>
                                        <td>
                                            {notas.reduce((accumulator, object) => {
                                                return accumulator + object.nota;
                                            }, 0)}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                                        </Row>*/}
                    </Container>
                </Col>
            </Row>
        </Container>
    )
}

export default DiplomasYCertificados