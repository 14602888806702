import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Row,
  Button,
  Col,
  Form,
  Container,
  ProgressBar,
  Alert,
  InputGroup,
} from "react-bootstrap";
//import DEFAULT_URL from '../../../../../api/url';
//import { SELF_URL } from '../../../../../api/url';
import "./common.css";
const Formulario = () => {
  const { correlativo } = useParams();
  const [contador, setContador] = useState(0);
  const [formulario, setFormulario] = useState({});
  const [preguntas, setPreguntas] = useState([]);
  const [mensaje, setMensaje] = useState("");
  const [checked, setChecked] = useState(false);
  const [asesores, setAsesores] = useState([]);
  const [salida, setSalida] = useState([]);
  const [paises, setPaises] = useState([]);
  const [dia, setDia] = useState("");
  const [mesAnio, setMesAnio] = useState("");
  const [promocional, setPromocional] = useState("");
  const [exito, setExito] = useState("none");
  const [error, setError] = useState("none");
  const [cargando, setCargando] = useState("none");
  const [detalle, setDetalle] = useState("block");
  const [status, setStatus] = useState(0);
  const [cambios, setCambios] = useState(0);
  const [final, setFinal] = useState("none");
  const [validated, setValidated] = useState(false);
  useEffect(() => {
    async function fetchData() {
      fetch(
        process.env.REACT_APP_DEFAULT_URL +
          "/api/read/formulario/" +
          correlativo,
        { cache: "no-cache" }
      )
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data.preguntas.length == 0) {
            window.location.replace("/unauthorized");
          } else {
            if (data.formulario.disponible == 0) {
              window.location.replace("/unauthorized");
            } else {
              let form = data.formulario;
              let pregunts = data.preguntas;
              pregunts.map((pregunta, index) => {
                pregunta.respuesta = "";
              });
              setFormulario(data.formulario);
              setPreguntas(pregunts);
              fetch(
                process.env.REACT_APP_DEFAULT_URL +
                  "/api/read/formulario/report/" +
                  data.formulario.UniqueID,
                { cache: "no-cache" }
              )
                .then((res) => {
                  return res.json();
                })
                .then((data) => {
                  if (salida != undefined) {
                    setSalida(data.salida);
                  } else {
                    setSalida([]);
                  }
                });
              fetch(
                process.env.REACT_APP_DEFAULT_URL +
                  "/api/read_id/evento/" +
                  data.formulario.evento_UniqueID,
                { cache: "no-cache" }
              )
                .then((res) => {
                  return res.json();
                })
                .then((data) => {
                  setMesAnio(data.mes_anio);
                  setDia(data.dia);
                  if (form.tipo == "1" || form.tipo == "4") {
                    setPromocional(data.flier);
                  } else {
                    setPromocional(form.promocional);
                  }
                });
            }
          }
        });
      fetch(process.env.REACT_APP_DEFAULT_URL + "/api/read/asesor", {
        cache: "no-cache",
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setAsesores(data.rows);
        });
      fetch(process.env.REACT_APP_DEFAULT_URL + "/api/read/paises")
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          let paises_arr = [];
          data.paises.map((actual) => {
            paises_arr.push({ id: actual.code, nombre: actual.name });
          });
          paises_arr.sort(function (a, b) {
            return a.nombre.localeCompare(b.nombre);
          });
          setPaises(paises_arr);
        });
    }
    if (contador == 0) {
      fetchData();
    }
  }, [contador]);

  useEffect(() => {}, [cambios]);

  const onChange = (evt) => {
    const index_finder = (pregunta) => pregunta.UniqueID == evt.target.name;
    let pregunts = preguntas;
    pregunts[pregunts.findIndex(index_finder)].respuesta = evt.target.value;
    setPreguntas(pregunts);
    setCambios(cambios + 1);
  };
  const procesar = () => {
    let tmp = preguntas;
    if (tmp[0].respuesta.trim() == "") {
      tmp[0].respuesta = "PENDIENTE";
    }
    if (tmp[1].respuesta == "") {
      tmp[1].respuesta = "PENDIENTE";
    }
    if (tmp[2].respuesta == "") {
      tmp[2].respuesta = "PENDIENTE";
    }
    setPreguntas(tmp);
    setCambios(cambios + 1);
    const info = { preguntas: preguntas, UniqueID: formulario.UniqueID };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(info),
    };
    setMensaje("Sincronizando información...");
    setStatus(25);
    setCambios(cambios + 1);
    fetch(
      process.env.REACT_APP_DEFAULT_URL + "/api/submit/formulario",
      requestOptions
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.codigo == 200) {
          setStatus(30);
          setCambios(cambios + 1);
          let llenado_id = data.llenado_id;
          if (formulario.tipo == "1") {
            const index_finder = (asesor) =>
              asesor.Nombres + " " + asesor.Apellidos == preguntas[7].respuesta;
            const id_asesor =
              asesores[asesores.findIndex(index_finder)].UniqueID;
            const email_asesor =
              asesores[asesores.findIndex(index_finder)].email;
            var today = new Date();
            const info = {
              participantes: [
                {
                  No: 0,
                  identificacion: "PENDIENTE",
                  Nombres: preguntas[1].respuesta,
                  Apellidos: preguntas[2].respuesta,
                  Puesto: preguntas[4].respuesta,
                  Correo: preguntas[0].respuesta,
                  Tel: preguntas[5].respuesta,
                  Celular: "PENDIENTE",
                  Pais: preguntas[6].respuesta,
                },
              ],
              Agremiado: false,
              Inversion: "0",
              Fecha_Max_FACT:
                today.getFullYear() +
                "-" +
                (today.getMonth() + 1) +
                "-" +
                today.getDate(),
              Nombre_FACT: "CF",
              NIT: "CF",
              Direccion: "Ciudad",
              Correo_FACT: preguntas[0].respuesta,
              Responsable:
                preguntas[1].respuesta + " " + preguntas[2].respuesta,
              Correo_RESP: preguntas[0].respuesta,
              Tel_RESP: preguntas[5].respuesta,
              Responsable_PAGO:
                preguntas[1].respuesta + " " + preguntas[2].respuesta,
              Correo_RP: preguntas[0].respuesta,
              Tel_RP: preguntas[5].respuesta,
              Comentario: "INSCRIPCIÓN DE CORTESÍA",
              Evento_Unique_ID: formulario.evento_UniqueID,
              Forma_Pago_UniqueID: "15",
              Moneda_Unique_ID: 8,
              moneda: "Q",
              Asesor_UniqueID: id_asesor,
              empresa: preguntas[3].respuesta,
            };
            const requestOptions = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(info),
            };
            fetch(
              process.env.REACT_APP_DEFAULT_URL + "/api/inscribir",
              requestOptions
            )
              .then((res) => {
                return res.json();
              })
              .then((data) => {
                if (data.codigo == 200) {
                  setStatus(45);
                  setCambios(cambios + 1);
                  let id_inscripcion = data.id;
                  let detalle_inscripcion = data.inscritos[0];
                  const info = {
                    llenado: llenado_id,
                    formulario: formulario.UniqueID,
                    detalle_inscripcion: detalle_inscripcion,
                    inscripcion: id_inscripcion,
                  };
                  const requestOptions = {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify(info),
                  };
                  fetch(
                    process.env.REACT_APP_DEFAULT_URL +
                      "/api/enlazar/formulario",
                    requestOptions
                  )
                    .then((res) => {
                      return res.json();
                    })
                    .then((data) => {
                      if (data.codigo == 200) {
                        setStatus(60);
                        setCambios(cambios + 1);
                        const info = { UniqueID: id_inscripcion };
                        const requestOptions = {
                          method: "POST",
                          headers: {
                            "Content-Type": "application/json",
                          },
                          body: JSON.stringify(info),
                        };
                        fetch(
                          process.env.REACT_APP_DEFAULT_URL +
                            "/api/update/inscripcion/confirmar_pago",
                          requestOptions
                        )
                          .then((res) => {
                            return res.json();
                          })
                          .then((data) => {
                            if (data.codigo == 200) {
                              setStatus(70);
                              setCambios(cambios + 1);
                              const info = {
                                email: preguntas[0].respuesta,
                                titulo: formulario.titulo,
                                nombres: preguntas[1].respuesta,
                                promocional: formulario.promocional,
                                enlace:
                                  process.env.REACT_APP_SELF_URL +
                                  "/inscripcion/public/ver/" +
                                  id_inscripcion,
                                dia: dia,
                                mes_anio: mesAnio,
                                email_asesor: email_asesor,
                              };
                              const requestOptions = {
                                method: "POST",
                                headers: {
                                  "Content-Type": "application/json",
                                },
                                body: JSON.stringify(info),
                              };
                              fetch(
                                process.env.REACT_APP_DEFAULT_URL +
                                  "/api/confirmar/formulario/cortesia",
                                requestOptions
                              )
                                .then((res) => {
                                  return res.json();
                                })
                                .then((data) => {
                                  if (data.codigo == 200) {
                                    setStatus(100);
                                    setMensaje(
                                      "Respuestas almacenadas exitosamente"
                                    );
                                    operacionExito();
                                  } else {
                                    setMensaje(
                                      "Ocurrió un error al confirmar el formulario: " +
                                        data.respuesta
                                    );
                                    operacionError();
                                  }
                                });
                            } else {
                              setMensaje(
                                "Ocurrió un error al validar la inscripción"
                              );
                              operacionError();
                            }
                          });
                      } else {
                        setMensaje(
                          "Ocurrió un error al validar la inscripción"
                        );
                        operacionError();
                      }
                    });
                } else {
                  setMensaje("Ocurrió un error al inscribir");
                  operacionError();
                }
              });
          } else if (formulario.tipo == "4") {
            fetch(
              process.env.REACT_APP_DEFAULT_URL +
                "/api/read_id/evento/" +
                formulario.evento_UniqueID,
              { cache: "no-cache" }
            )
              .then((res) => {
                return res.json();
              })
              .then((data) => {
                if (data.rows.length > 0) {
                  setStatus(35);
                  setCambios(cambios + 1);
                  let precio = data.rows[0].precio_no_agremiados;
                  const index_finder = (asesor) =>
                    asesor.Nombres + " " + asesor.Apellidos ==
                    preguntas[7].respuesta;
                  const id_asesor =
                    asesores[asesores.findIndex(index_finder)].UniqueID;
                  const email_asesor =
                    asesores[asesores.findIndex(index_finder)].email;
                  var today = new Date();
                  const info = {
                    participantes: [
                      {
                        No: 0,
                        identificacion: "PENDIENTE",
                        Nombres: preguntas[1].respuesta,
                        Apellidos: preguntas[2].respuesta,
                        Puesto: preguntas[4].respuesta,
                        Correo: preguntas[0].respuesta,
                        Tel: preguntas[5].respuesta,
                        Celular: "PENDIENTE",
                        Pais: preguntas[6].respuesta,
                      },
                    ],
                    Agremiado: false,
                    Inversion: precio,
                    Fecha_Max_FACT:
                      today.getFullYear() +
                      "-" +
                      (today.getMonth() + 1) +
                      "-" +
                      today.getDate(),
                    Nombre_FACT: "CF",
                    NIT: "CF",
                    Direccion: "Ciudad",
                    Correo_FACT: preguntas[0].respuesta,
                    Responsable:
                      preguntas[1].respuesta + " " + preguntas[2].respuesta,
                    Correo_RESP: preguntas[0].respuesta,
                    Tel_RESP: preguntas[5].respuesta,
                    Responsable_PAGO:
                      preguntas[1].respuesta + " " + preguntas[2].respuesta,
                    Correo_RP: preguntas[0].respuesta,
                    Tel_RP: preguntas[5].respuesta,
                    Comentario: "FICHA PENDIENTE DE COBRO",
                    Evento_Unique_ID: formulario.evento_UniqueID,
                    Forma_Pago_UniqueID: "14",
                    Moneda_Unique_ID: 8,
                    moneda: "Q",
                    Asesor_UniqueID: id_asesor,
                    empresa: preguntas[3].respuesta,
                  };
                  const requestOptions = {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify(info),
                  };

                  fetch(
                    process.env.REACT_APP_DEFAULT_URL + "/api/inscribir",
                    requestOptions
                  )
                    .then((res) => {
                      return res.json();
                    })
                    .then((data) => {
                      if (data.codigo == 200) {
                        setStatus(50);
                        setCambios(cambios + 1);
                        let id_inscripcion = data.id;
                        let detalle_inscripcion = data.inscritos[0];
                        const info = {
                          llenado: llenado_id,
                          formulario: formulario.UniqueID,
                          detalle_inscripcion: detalle_inscripcion,
                          inscripcion: id_inscripcion,
                        };
                        const requestOptions = {
                          method: "POST",
                          headers: {
                            "Content-Type": "application/json",
                          },
                          body: JSON.stringify(info),
                        };
                        fetch(
                          process.env.REACT_APP_DEFAULT_URL +
                            "/api/enlazar/formulario",
                          requestOptions
                        )
                          .then((res) => {
                            return res.json();
                          })
                          .then((data) => {
                            if (data.codigo == 200) {
                              setStatus(60);
                              setCambios(cambios + 1);
                              const info = {
                                email: preguntas[0].respuesta,
                                titulo: formulario.titulo,
                                nombres: preguntas[1].respuesta,
                                promocional: formulario.promocional,
                                enlace:
                                  process.env.REACT_APP_SELF_URL +
                                  "/inscripcion/public/ver/" +
                                  id_inscripcion,
                                enlace_seguimiento:
                                  process.env.REACT_APP_SELF_URL +
                                  "/inscripcion/seguimiento/" +
                                  id_inscripcion,
                                dia: dia,
                                mes_anio: mesAnio,
                                email_asesor: email_asesor,
                              };
                              const requestOptions = {
                                method: "POST",
                                headers: {
                                  "Content-Type": "application/json",
                                },
                                body: JSON.stringify(info),
                              };
                              fetch(
                                process.env.REACT_APP_DEFAULT_URL +
                                  "/api/confirmar/formulario/pagado",
                                requestOptions
                              )
                                .then((res) => {
                                  return res.json();
                                })
                                .then((data) => {
                                  if (data.codigo == 200) {
                                    setStatus(100);
                                    setMensaje(
                                      "Respuestas almacenadas exitosamente"
                                    );
                                    operacionExito();
                                  } else {
                                    setMensaje(
                                      "Ocurrió un error al confirmar el formulario: " +
                                        data.respuesta
                                    );
                                    operacionError();
                                  }
                                });
                            } else {
                              setMensaje(
                                "Ocurrió un error al validar la inscripción"
                              );
                              operacionError();
                            }
                          });
                      } else {
                        setMensaje("Ocurrió un error al inscribir");
                        operacionError();
                      }
                    });
                }
              });
          } else {
            if (preguntas[0].respuesta != "PENDIENTE") {
              const info = {
                email: preguntas[0].respuesta,
                titulo: formulario.titulo,
                nombres: preguntas[1].respuesta,
                promocional: formulario.promocional,
              };
              const requestOptions = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(info),
              };
              fetch(
                process.env.REACT_APP_DEFAULT_URL + "/api/confirmar/formulario",
                requestOptions
              )
                .then((res) => {
                  return res.json();
                })
                .then((data) => {
                  if (data.codigo == 200) {
                    setStatus(100);
                    setMensaje("Respuestas almacenadas exitosamente");
                    operacionExito();
                  } else {
                    setMensaje(
                      "Ocurrió un error al confirmar el formulario: " +
                        data.respuesta
                    );
                    operacionError();
                  }
                });
            } else {
              setStatus(100);
              setMensaje("Respuestas almacenadas exitosamente");
              operacionExito();
            }
          }
        } else {
          setMensaje(
            "Ocurrió un error al almacenar las respuestas: " + data.respuesta
          );
          operacionError();
        }
      });
  };
  const submit = (evt) => {
    const form = evt.currentTarget;
    if (form.checkValidity() === false) {
      evt.preventDefault();
      evt.stopPropagation();
    } else {
      evt.preventDefault();
      setMensaje("Validando información");
      cargar();
      setStatus(0);
      fetch(
        process.env.REACT_APP_DEFAULT_URL +
          "/api/read/formulario/report/" +
          formulario.UniqueID,
        { cache: "no-cache" }
      )
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setStatus(25);
          if (data.salida != null) {
            if (
              data.salida.length == 0 ||
              preguntas[0].respuesta.trim() == ""
            ) {
              console.log("entro aca");
              procesar();
            } else {
              for (let i = 0; i < data.salida.length; i++) {
                if (
                  preguntas[0].respuesta.trim() ==
                  data.salida[i].respuestas[0].texto.trim()
                ) {
                  setMensaje(
                    "¡Correo electrónico registrado!\n Existe un registro con el correo " +
                      preguntas[0].respuesta
                  );
                  operacionError();
                  break;
                } else if (i == data.salida.length - 1) {
                  procesar();
                }
              }
            }
          } else {
            procesar();
          }
        });
    }
    setValidated(true);
    setCambios(cambios + 1);
  };
  const chequear = (evt) => {
    setChecked(evt.target.checked);
  };
  const cargar = () => {
    setExito("none");
    setError("none");
    setCargando("block");
    setDetalle("none");
    setStatus(0);
    setContador(contador + 1);
  };
  const operacionExito = () => {
    setExito("block");
    setError("none");
    setCargando("none");
    setDetalle("none");
    setStatus(0);
    setContador(0);
  };
  const operacionError = () => {
    setExito("none");
    setError("block");
    setCargando("none");
    setDetalle("none");
    setStatus(0);
    setCambios(cambios + 1);
  };
  const goBack = () => {
    setExito("none");
    setError("none");
    setCargando("none");
    setDetalle("block");
    setStatus(0);
    setCambios(cambios + 1);
  };
  const end = () => {
    setDetalle("none");
    setFinal("block");
    setCambios(cambios + 1);
  };
  return (
    <Container
      fluid
      style={{
        backgroundColor: "#d9f5f9",
        fontFamily: "Montserrat, sans-serif",
        paddingRight: "5%",
        paddingLeft: "5%",
        paddingBottom: "10%",
      }}
    >
      <Row style={{ padding: "5%" }}>
        <Container
          fluid
          style={{
            boxShadow: "10px 10px 56px 10px rgba(30,61,82,0.91)",
            borderRadius: "2em",
          }}
        >
          <Row>
            <Col
              xs={12}
              md={12}
              lg={6}
              style={{ display: "grid", placeItems: "center", padding: "2%" }}
            >
              <Container fluid>
                <Row>
                  <img
                    style={{ width: "100%", objectFit: "contain" }}
                    src={
                      "https://escuela-bancaria.s3.us-east-2.amazonaws.com/1674081393254-LOGOS+azul.png"
                    }
                  />
                </Row>
                <Row>
                  <h3
                    style={{
                      color: "black",
                      fontWeight: "bold",
                      textAlign: "justify",
                    }}
                  >
                    {formulario.titulo}
                  </h3>
                  <p
                    style={{
                      textAlign: "justify",
                      fontSize: "1.8em",
                      marginTop: "25px",
                    }}
                  >
                    {formulario.descripcion}
                  </p>
                </Row>
              </Container>
            </Col>
            <Col xs={12} md={12} lg={6} style={{ padding: "2%" }}>
              <img
                style={{
                  width: "100%",
                  objectFit: "contain",
                  borderRadius: "1em",
                }}
                src={promocional}
              />
            </Col>
          </Row>
        </Container>
      </Row>
      <Row style={{ display: detalle }}>
        <Container>
          <Row>
            <Form onSubmit={submit} noValidate validated={validated}>
              {preguntas.map(
                (pregunta, index) =>
                  (index == 0 || index == 1 || index == 2) &&
                  pregunta.tipo_pregunta_UniqueID == "1" && (
                    <Row
                      key={"pregunta" + index}
                      style={{
                        marginTop: "15px",
                        marginBottom: "15px",
                        padding: "3%",
                        borderRadius: "25px",
                        backgroundColor: "white",
                        borderLeft: "1em solid rgba(30,61,82,0.91)",
                      }}
                    >
                      <Container fluid>
                        <Row>
                          <h2 style={{ marginBottom: "25px" }}>
                            {pregunta.pregunta}
                          </h2>
                        </Row>
                        <Row>
                          <Form.Group controlId={pregunta.UniqueID}>
                            <InputGroup hasValidation>
                              {index > 0 && (
                                <Form.Control
                                  size="lg"
                                  type="text"
                                  required={
                                    formulario.tipo != "2" &&
                                    formulario.tipo != "5"
                                  }
                                  placeholder="Ingrese lo solicitado "
                                  name={pregunta.UniqueID}
                                  onChange={onChange}
                                  value={pregunta.respuesta}
                                />
                              )}
                              {index == 0 && (
                                <Form.Control
                                  size="lg"
                                  type="email"
                                  placeholder="Ingrese lo solicitado "
                                  name={pregunta.UniqueID}
                                  onChange={onChange}
                                  value={pregunta.respuesta}
                                />
                              )}
                              {(formulario.tipo == "2" ||
                                formulario.tipo == "5") &&
                                (index == 1 || index == 2) && (
                                  <Form.Control.Feedback
                                    style={{ textAlign: "left" }}
                                  >
                                    Este campo no es obligatorio
                                  </Form.Control.Feedback>
                                )}
                              {(((formulario.tipo == "2" ||
                                formulario.tipo == "5") &&
                                index != 1 &&
                                index != 2) ||
                                (formulario.tipo != "2" &&
                                  formulario.tipo != "5")) && (
                                <Form.Control.Feedback
                                  style={{ textAlign: "left" }}
                                >
                                  Ok
                                </Form.Control.Feedback>
                              )}
                              {((formulario.tipo != "2" &&
                                formulario.tipo != "5") ||
                                index == 0) && (
                                <Form.Control.Feedback
                                  type="invalid"
                                  style={{ textAlign: "left" }}
                                >
                                  Por favor, ingresa lo solicitado
                                </Form.Control.Feedback>
                              )}
                            </InputGroup>
                          </Form.Group>
                        </Row>
                      </Container>
                    </Row>
                  )
              )}
              {/* <Row style={{ marginTop: '15px', marginBottom: '15px', padding: '3%', borderRadius: '25px', backgroundColor: 'white', borderLeft: '1em solid rgba(30,61,82,0.91)' }}>
                                {preguntas.map((pregunta, index) =>
                                    ((index == 1 || index == 2) && pregunta.tipo_pregunta_UniqueID == '1') &&
                                    <Col xs={6} md={6} lg={6} style={{ backgroundColor: 'transparent', color: 'white', borderRadius: '15px', marginBottom: '15px' }} >
                                        <Form.Group className="mb-3" controlId={pregunta.UniqueID}>
                                            <Form.Label style={{ color: '#1e3d52', fontWeight: 'bold', backgroundColor: 'transparent' }}>{pregunta.pregunta}</Form.Label>
                                            {index > 0 && <Form.Control type="text" required={formulario.tipo != '2' && formulario.tipo != '5'} placeholder="Ingrese lo solicitado " name={pregunta.UniqueID} onChange={onChange} value={pregunta.respuesta} />}
                                            {index == 0 && <Form.Control type="email" required={formulario.tipo != '2' && formulario.tipo != '5'} placeholder="Ingrese lo solicitado " name={pregunta.UniqueID} onChange={onChange} value={pregunta.respuesta} />}
                                        </Form.Group>
                                    </Col>
                                )}
                                {preguntas.map((pregunta, index) =>
                                    ((index == 0) && pregunta.tipo_pregunta_UniqueID == '1') &&
                                    <Col xs={12} md={12} lg={12}>
                                        <Form.Group className="mb-3" controlId={pregunta.UniqueID}>
                                            <Form.Label style={{ color: '#1e3d52', fontWeight: 'bold' }}>{pregunta.pregunta}</Form.Label>
                                            {index > 0 && <Form.Control type="text" required placeholder="Ingrese lo solicitado " name={pregunta.UniqueID} onChange={onChange} value={pregunta.respuesta} />}
                                            {index == 0 && <Form.Control type="email" required placeholder="Ingrese lo solicitado " name={pregunta.UniqueID} onChange={onChange} value={pregunta.respuesta} />}
                                        </Form.Group>
                                    </Col>
                                )}
                                </Row>*/}
              {preguntas.map(
                (pregunta, index) =>
                  index > 2 && (
                    <Row
                      style={{
                        marginTop: "15px",
                        marginBottom: "15px",
                        padding: "3%",
                        borderRadius: "25px",
                        backgroundColor: "white",
                        borderLeft: "1em solid rgba(30,61,82,0.91)",
                      }}
                      key={pregunta.UniqueID}
                    >
                      {/*pregunta.tipo_pregunta_UniqueID == '1' &&
                                        <Form.Group className="mb-3" controlId={pregunta.UniqueID}>
                                            <Form.Label style={{ color: '#1e3d52', fontWeight: 'bold' }}>{pregunta.pregunta}</Form.Label>
                                            <Form.Control type="text" required placeholder="Ingrese lo solicitado " name={pregunta.UniqueID} onChange={onChange} value={pregunta.respuesta} />
                                        </Form.Group>
                            */}
                      {pregunta.tipo_pregunta_UniqueID == "1" && (
                        <Container fluid>
                          <Row>
                            <h2 style={{ marginBottom: "25px" }}>
                              {pregunta.pregunta}
                            </h2>
                          </Row>
                          <Row>
                            <Form.Group controlId={pregunta.UniqueID}>
                              <InputGroup hasValidation>
                                <Form.Control
                                  type="text"
                                  required
                                  placeholder="Ingrese lo solicitado "
                                  name={pregunta.UniqueID}
                                  onChange={onChange}
                                  value={pregunta.respuesta}
                                />
                                <Form.Control.Feedback
                                  style={{ textAlign: "left" }}
                                >
                                  Ok
                                </Form.Control.Feedback>
                                <Form.Control.Feedback
                                  type="invalid"
                                  style={{ textAlign: "left" }}
                                >
                                  Por favor, ingresa lo solicitado
                                </Form.Control.Feedback>
                              </InputGroup>
                            </Form.Group>
                          </Row>
                        </Container>
                      )}
                      {/*pregunta.tipo_pregunta_UniqueID == '2' &&
                                        <Form.Group className="mb-3" controlId={pregunta.UniqueID}>
                                            <Form.Label style={{ color: '#1e3d52', fontWeight: 'bold' }}>{pregunta.pregunta}</Form.Label>
                                            {checked == false &&
                                                <Form.Control required as="select" aria-label="tipo" name={pregunta.UniqueID} onChange={onChange} value={pregunta.respuesta}>
                                                    <option value="" key="0">Seleccione</option>
                                                    <option value="Si">Si</option>
                                                    <option value="No">No</option>
                                                </Form.Control>
                                            }
                                            {
                                                checked == true && <Form.Control type="text" required placeholder="Ingrese lo solicitado " name={pregunta.UniqueID} onChange={onChange} value={pregunta.respuesta} />
                                            }
                                            <Form.Check type="checkbox" label="Otro" style={{ textAlign: 'left', marginTop: '10px' }} checked={checked} onChange={chequear} />
                                        </Form.Group>
                                    */}
                      {pregunta.tipo_pregunta_UniqueID == "2" && (
                        <Container fluid>
                          <Row>
                            <h2 style={{ marginBottom: "25px" }}>
                              {pregunta.pregunta}
                            </h2>
                          </Row>
                          <Row>
                            <Form.Group controlId={pregunta.UniqueID}>
                              <InputGroup hasValidation>
                                {checked == false && (
                                  <Form.Control
                                    required
                                    as="select"
                                    name={pregunta.UniqueID}
                                    onChange={onChange}
                                    value={pregunta.respuesta}
                                  >
                                    <option value="" key="0">
                                      Seleccione
                                    </option>
                                    <option value="Si">Si</option>
                                    <option value="No">No</option>
                                  </Form.Control>
                                )}
                                {checked == true && (
                                  <Form.Control
                                    type="text"
                                    required
                                    placeholder="Ingrese lo solicitado "
                                    name={pregunta.UniqueID}
                                    onChange={onChange}
                                    value={pregunta.respuesta}
                                  />
                                )}
                                <Form.Control.Feedback
                                  style={{ textAlign: "left" }}
                                >
                                  Ok
                                </Form.Control.Feedback>
                                <Form.Control.Feedback
                                  type="invalid"
                                  style={{ textAlign: "left" }}
                                >
                                  Por favor, ingresa lo solicitado
                                </Form.Control.Feedback>
                              </InputGroup>
                            </Form.Group>
                          </Row>
                        </Container>
                      )}
                      {/*pregunta.tipo_pregunta_UniqueID == '3' &&
                                        <Form.Group className="mb-3" controlId={pregunta.UniqueID}>
                                            <Form.Label style={{ color: '#1e3d52', fontWeight: 'bold' }}>{pregunta.pregunta}</Form.Label>
                                            <Form.Control as="select" required aria-label="Asesor" name={pregunta.UniqueID} onChange={onChange} value={pregunta.respuesta}>
                                                <option value="" key="0">Seleccione el asesor</option>
                                                {asesores.map(asesor => <option key={asesor.UniqueID} value={asesor.Nombres + " " + asesor.Apellidos}>{asesor.Nombres + " " + asesor.Apellidos}</option>)}
                                            </Form.Control>
                                        </Form.Group>
                                */}
                      {pregunta.tipo_pregunta_UniqueID == "3" && (
                        <Container fluid>
                          <Row>
                            <h2 style={{ marginBottom: "25px" }}>
                              {pregunta.pregunta}
                            </h2>
                          </Row>
                          <Row>
                            <Form.Group controlId={pregunta.UniqueID}>
                              <InputGroup hasValidation>
                                <Form.Control
                                  as="select"
                                  required
                                  name={pregunta.UniqueID}
                                  onChange={onChange}
                                  value={pregunta.respuesta}
                                >
                                  <option value="" key="0">
                                    Seleccione el asesor
                                  </option>
                                  {asesores.map((asesor) => (
                                    <option
                                      key={asesor.UniqueID}
                                      value={
                                        asesor.Nombres + " " + asesor.Apellidos
                                      }
                                    >
                                      {asesor.Nombres + " " + asesor.Apellidos}
                                    </option>
                                  ))}
                                </Form.Control>
                                <Form.Control.Feedback
                                  style={{ textAlign: "left" }}
                                >
                                  Ok
                                </Form.Control.Feedback>
                                <Form.Control.Feedback
                                  type="invalid"
                                  style={{ textAlign: "left" }}
                                >
                                  Por favor, ingresa lo solicitado
                                </Form.Control.Feedback>
                              </InputGroup>
                            </Form.Group>
                          </Row>
                        </Container>
                      )}
                      {/*pregunta.tipo_pregunta_UniqueID == '4' &&
                                        <Form.Group className="mb-3" controlId={pregunta.UniqueID}>
                                            <Form.Label style={{ color: '#1e3d52', fontWeight: 'bold' }}>{pregunta.pregunta}</Form.Label>
                                            <Form.Control as="select" required aria-label="Pais" name={pregunta.UniqueID} onChange={onChange} value={pregunta.respuesta}>
                                                <option value="" key="0">Seleccione el país</option>
                                                {paises.map(pais => <option key={pais.id} value={pais.nombre}>{pais.nombre}</option>)}
                                            </Form.Control>
                                        </Form.Group>
                                */}
                      {pregunta.tipo_pregunta_UniqueID == "4" && (
                        <Container fluid>
                          <Row>
                            <h2 style={{ marginBottom: "25px" }}>
                              {pregunta.pregunta}
                            </h2>
                          </Row>
                          <Row>
                            <Form.Group controlId={pregunta.UniqueID}>
                              <InputGroup hasValidation>
                                <Form.Control
                                  as="select"
                                  required
                                  name={pregunta.UniqueID}
                                  onChange={onChange}
                                  value={pregunta.respuesta}
                                >
                                  <option value="" key="0">
                                    Seleccione el país
                                  </option>
                                  {paises.map((pais) => (
                                    <option key={pais.id} value={pais.nombre}>
                                      {pais.nombre}
                                    </option>
                                  ))}
                                </Form.Control>
                                <Form.Control.Feedback
                                  style={{ textAlign: "left" }}
                                >
                                  Ok
                                </Form.Control.Feedback>
                                <Form.Control.Feedback
                                  type="invalid"
                                  style={{ textAlign: "left" }}
                                >
                                  Por favor, ingresa lo solicitado
                                </Form.Control.Feedback>
                              </InputGroup>
                            </Form.Group>
                          </Row>
                        </Container>
                      )}
                      {/*pregunta.tipo_pregunta_UniqueID == '5' &&
                                        <Form.Group className="mb-3" controlId={pregunta.UniqueID}>
                                            <Form.Label style={{ color: '#1e3d52', fontWeight: 'bold' }}>{pregunta.pregunta}</Form.Label>
                                            <Form.Control as="select" required name={pregunta.UniqueID} onChange={onChange} value={pregunta.respuesta}>
                                                <option value="" key="0">Seleccione</option>
                                                {pregunta.opciones.map((actual, indice) => <option key={indice} value={actual.opcion}>{actual.opcion}</option>)}
                                            </Form.Control>
                                        </Form.Group>
                                */}
                      {pregunta.tipo_pregunta_UniqueID == "5" && (
                        <Container fluid>
                          <Row>
                            <h2 style={{ marginBottom: "25px" }}>
                              {pregunta.pregunta}
                            </h2>
                          </Row>
                          <Row>
                            <Form.Group controlId={pregunta.UniqueID}>
                              <InputGroup hasValidation>
                                <Form.Control
                                  as="select"
                                  required
                                  name={pregunta.UniqueID}
                                  onChange={onChange}
                                  value={pregunta.respuesta}
                                >
                                  <option value="" key="0">
                                    Seleccione
                                  </option>
                                  {pregunta.opciones.map((actual, indice) => (
                                    <option key={indice} value={actual.opcion}>
                                      {actual.opcion}
                                    </option>
                                  ))}
                                </Form.Control>
                                <Form.Control.Feedback
                                  style={{ textAlign: "left" }}
                                >
                                  Ok
                                </Form.Control.Feedback>
                                <Form.Control.Feedback
                                  type="invalid"
                                  style={{ textAlign: "left" }}
                                >
                                  Por favor, ingresa lo solicitado
                                </Form.Control.Feedback>
                              </InputGroup>
                            </Form.Group>
                          </Row>
                        </Container>
                      )}
                    </Row>
                  )
              )}
              <Row style={{ marginBottom: "15px" }}>
                <Button variant="light" className="font-bold" type="submit">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-send-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z" />
                  </svg>
                  &nbsp;&nbsp;&nbsp;&nbsp;Enviar
                </Button>
              </Row>
            </Form>
          </Row>
        </Container>
      </Row>
      <Row style={{ display: cargando, padding: "10%" }}>
        <Container
          fluid
          style={{ height: "45vh", display: "grid", placeItems: "center" }}
        >
          <Row>
            <h1>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="currentColor"
                className="bi bi-hourglass-split"
                viewBox="0 0 16 16"
              >
                <path d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48V8.35zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z" />
              </svg>
              &nbsp;&nbsp;&nbsp;&nbsp;Procesando operación
            </h1>
          </Row>
          <Row>
            <p style={{ fontSize: "1.6em" }}>
              Esto puede demorar unos minutos
              <br />
              Por favor espere...
            </p>
            <p style={{ fontSize: "1.6em", marginBottom: "15px" }}>
              <br />
              <br />
              <br />
              {mensaje}
            </p>
            <ProgressBar animated now={status} variant="dark" />
          </Row>
        </Container>
      </Row>
      <Row style={{ display: exito, padding: "10%" }}>
        <Container
          fluid
          style={{ height: "45vh", display: "grid", placeItems: "center" }}
        >
          <Row>
            <h1>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="currentColor"
                className="bi bi-bookmark-check"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M10.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z"
                />
                <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5V2zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1H4z" />
              </svg>
              &nbsp;&nbsp;&nbsp;&nbsp;Operación procesada con éxito
            </h1>
          </Row>
          <Row>
            <p style={{ fontSize: "1.6em" }}>{mensaje}</p>
          </Row>
          <Row style={{ marginTop: "25px" }}>
            <Button
              onClick={() => {
                goBack();
                end();
              }}
              variant="light"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-check2"
                viewBox="0 0 16 16"
              >
                <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
              </svg>
              &nbsp;&nbsp;&nbsp;&nbsp;Ok
            </Button>
          </Row>
        </Container>
      </Row>
      <Row style={{ display: error }}>
        <Container
          fluid
          style={{ height: "45vh", display: "grid", placeItems: "center" }}
        >
          <Row>
            <h1>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="currentColor"
                className="bi bi-bug"
                viewBox="0 0 16 16"
              >
                <path d="M4.355.522a.5.5 0 0 1 .623.333l.291.956A4.979 4.979 0 0 1 8 1c1.007 0 1.946.298 2.731.811l.29-.956a.5.5 0 1 1 .957.29l-.41 1.352A4.985 4.985 0 0 1 13 6h.5a.5.5 0 0 0 .5-.5V5a.5.5 0 0 1 1 0v.5A1.5 1.5 0 0 1 13.5 7H13v1h1.5a.5.5 0 0 1 0 1H13v1h.5a1.5 1.5 0 0 1 1.5 1.5v.5a.5.5 0 1 1-1 0v-.5a.5.5 0 0 0-.5-.5H13a5 5 0 0 1-10 0h-.5a.5.5 0 0 0-.5.5v.5a.5.5 0 1 1-1 0v-.5A1.5 1.5 0 0 1 2.5 10H3V9H1.5a.5.5 0 0 1 0-1H3V7h-.5A1.5 1.5 0 0 1 1 5.5V5a.5.5 0 0 1 1 0v.5a.5.5 0 0 0 .5.5H3c0-1.364.547-2.601 1.432-3.503l-.41-1.352a.5.5 0 0 1 .333-.623zM4 7v4a4 4 0 0 0 3.5 3.97V7H4zm4.5 0v7.97A4 4 0 0 0 12 11V7H8.5zM12 6a3.989 3.989 0 0 0-1.334-2.982A3.983 3.983 0 0 0 8 2a3.983 3.983 0 0 0-2.667 1.018A3.989 3.989 0 0 0 4 6h8z" />
              </svg>
              &nbsp;&nbsp;&nbsp;&nbsp;Ocurrió un error al almacenar sus
              respuestas
            </h1>
          </Row>
          <Row>
            <p style={{ fontSize: "1.6em" }}>{mensaje}</p>
          </Row>
          <Row style={{ marginTop: "25px" }}>
            <Button
              onClick={() => {
                goBack();
              }}
              variant="light"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-check2"
                viewBox="0 0 16 16"
              >
                <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
              </svg>
              &nbsp;&nbsp;&nbsp;&nbsp;Ok
            </Button>{" "}
          </Row>
        </Container>
      </Row>
      <Row style={{ display: final, padding: "10%" }}>
        <Container
          fluid
          style={{ height: "45vh", display: "grid", placeItems: "center" }}
        >
          <Row>
            <h1>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="currentColor"
                className="bi bi-bookmark-check"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M10.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z"
                />
                <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5V2zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1H4z" />
              </svg>
              &nbsp;&nbsp;&nbsp;&nbsp;¡Gracias por sus respuestas!
            </h1>
          </Row>
          <Row>
            <p style={{ fontSize: "1.6em" }}>Por favor, revise su email (si usted ingresó email)</p>
          </Row>
        </Container>
      </Row>
    </Container>
  );
};

export default Formulario;
