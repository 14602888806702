import React, { useEffect, useState } from 'react';
//import DEFAULT_URL, { SELF_URL } from '../../../../../api/url';
import { Row, Form, Alert, Button, Spinner } from "react-bootstrap";
const Participante = (props) => {
    const [contador, setContador] = useState([]);
    const [cargando, setCargando] = useState('none');
    const [cargando2, setCargando2] = useState('none');
    const [acciones, setAcciones] = useState('block');
    const [exito, setExito] = useState('none');
    const [exito2, setExito2] = useState('none');
    useEffect(() => {
    }, [contador]);

    const cargar = () => {
        setCargando('block');
        setAcciones('none');
        setExito('none');
        setContador(contador + 1);
    }
    const cargar2 = () => {
        setCargando('none');
        setCargando2('block');
        setAcciones('none');
        setExito('none');
        setContador(contador + 1);
    }
    const goBack = () => {
        setCargando('none');
        setCargando2('none');
        setAcciones('block');
        setExito('none');
        setExito2('none');
        setContador(contador + 1);
    }
    const operacionExito = () => {
        setCargando('none');
        setAcciones('none');
        setCargando2('none');
        setExito('block');
        setContador(contador + 1);
    }
    const operacionExito2 = () => {
        setCargando('none');
        setAcciones('none');
        setCargando2('none');
        setExito('none');
        setExito2('block');
        setContador(contador + 1);
    }
    const enviarDiploma = () => {
        cargar();
        const info = { diploma: props.diploma, participante: props.participante.UniqueID };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/diplomas/send', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(datos => {
                console.log(datos);
                if (datos.codigo == 200) {
                    operacionExito();
                    console.log(datos.enlace);
                } else {
                }
            });
    }
    const getDiploma = () => {
        cargar2();
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/diplomas/getDiplomaPDF/' + props.diploma + '/' + props.participante.UniqueID)
            .then((res) => {
                return res.json();
            })
            .then(datos => {
                if (datos.codigo == 200) {
                    fetch(process.env.REACT_APP_DEFAULT_URL + '/api/diplomas/getObject/' + datos.llave).then(response => {
                        response.blob().then(blob => {
                            const fileURL = window.URL.createObjectURL(blob);
                            let alink = document.createElement('a');
                            alink.href = fileURL;
                            alink.download = 'diploma-' + Date.now() + '-' + props.participante.UniqueID + '.pdf';
                            alink.click();
                        });
                    })
                    operacionExito2();
                } else {
                }
            });
    }

    return (
        <Row key={props.participante.UniqueID}>
            <Alert variant='dark' style={{ width: '100%' }}>
                <Form.Group className="mb-3" controlId={props.participante.UniqueID+' '+ props.diploma}>
                    <Form.Check onChange={props.checkEvent} name={props.participante.UniqueID} checked={props.participante.checked} type="checkbox" label={props.participante.Nombres + ' ' + props.participante.Apellidos + ' (' + props.participante.Correo + ')'} />
                </Form.Group>
                <h5>{props.participante.correlativo}</h5>
                <hr />
                <p className="mb-0" style={{ display: cargando }}>
                    <Spinner animation="border" role="status"></Spinner>&nbsp;&nbsp;&nbsp;Enviando diploma...
                </p>
                <p className="mb-0" style={{ display: cargando2 }}>
                    <Spinner animation="border" role="status"></Spinner>&nbsp;&nbsp;&nbsp;Generando diploma...
                </p>
                <p className="mb-0" style={{ display: exito }}>
                    <Button variant='outline-secondary' onClick={goBack}>Diploma enviado exitosamente, click para regresar</Button>
                </p>
                <p className="mb-0" style={{ display: exito2 }}>
                    <Button variant='outline-secondary' onClick={goBack}>Diploma generado exitosamente, click para regresar</Button>
                </p>
                {props.enlace != null && <Button variant="outline-dark" href={process.env.REACT_APP_SELF_URL+'/diplomas-y-certificados' +'/'+props.participante.hash_participante} target="_blank">Click para ver detalle</Button>}
            </Alert>
        </Row>)
}

export default Participante