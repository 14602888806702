import React, { useState, useEffect, Suspense } from 'react';
import { Modal, Row, Tabs, Tab, Table, Dropdown, Col, Container, ListGroup, Form, Breadcrumb, Button } from "react-bootstrap";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { EditarActividad } from './EditarAcrividad';
import { ErrorModal } from '../../../../Utils/ErrorModal/ErrorModal';
import { SuccessModal } from '../../../../Utils/SuccessModal/SuccessModal';
import { ModalNuevaActividad } from './ModalNuevaActividad';
import { Confirmacion } from '../../../../Utils/ConfirmationModal/Confirmacion';
//import DEFAULT_URL from '../../../../../api/url';
import PonderarParticipantes from './PonderarParticipantes';
const ModalActividades = (props) => {
    const [actividades, setActividades] = useState([]);
    const [total, setTotal] = useState('');
    const [modalSucc, setModalSucc] = useState(false);
    const [modalError, setModalError] = useState(false);
    const [mensaje, setMensaje] = useState(false);
    const [consideracion, setConsideracion] = useState('');
    const [nueva_actividad, setNueva_actividad] = useState(false);
    const [mensajeConfirmacion, setMensajeConfirmacion] = useState(false);
    const [id_actual, setId_actual] = useState('');
    const [contador, setContador] = useState(0);
    const [bandera, setBandera] = useState(0);
    const [filterActivities, setFilterActivities] = useState('');
    const [filteredActivities, setFilteredActivities] = useState([]);
    const [detalleActividades, setDetalleActividades] = useState('block');
    const [detalleActividad, setDetalleActividad] = useState('none');
    useEffect(() => {
        async function fetchData() {
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/evento/actividades/' + props.evento_uniqueid, { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setActividades([]);
                    setActividades(data.rows);
                    setFilteredActivities([]);
                    setFilteredActivities(data.rows);
                    setTotal(data.total[0].total);
                });
        }
        fetchData();
    }, [contador]);
    useEffect(() => {

    }, [bandera]);
    const exportDataExcel = () => {
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/detalle_inscripcion/evento/' + props.evento_uniqueid, { cache: "no-cache" })
            .then((res) => {
                return res.json();
            })
            .then(data => {
                const exp = [];
                for (let i = 0; i < data.rows.length; i++) {
                    var temp = { registro_academico: data.rows[i].registro_academico, Nombre: data.rows[i].Nombres + ' ' + data.rows[i].Apellidos, Nota: 0, Comentario: 'Observaciones' };
                    exp.push(temp);
                }
                const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                const fileExtension = '.xlsx';
                const ws = XLSX.utils.json_to_sheet(exp);
                const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const datos = new Blob([excelBuffer], { type: fileType });
                FileSaver.saveAs(datos, 'Listado Participantes ' + props.evento_uniqueid + fileExtension);
            });
    }
    const reporteNotas = () => {
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/actividad/finalgrades/' + props.evento_uniqueid, { cache: "no-cache" })
            .then((res) => {
                return res.json();
            })
            .then(data_ => {
                const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                const fileExtension = '.xlsx';
                const ws = XLSX.utils.json_to_sheet(data_.respuesta);
                const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const data = new Blob([excelBuffer], { type: fileType });
                FileSaver.saveAs(data, Date.now() + fileExtension);
            });
    }
    const onchangehandler = (evt) => {
        const arr_name = evt.target.name.split("-");
        const index_finder = (actividad) => actividad.UniqueID == arr_name[1];
        var new_array = actividades;
        switch (arr_name[0]) {
            case "Nombre":
                new_array[new_array.findIndex(index_finder)].Nombre = evt.target.value;
                setActividades(new_array);
                break;
            case "Descripcion":
                new_array[new_array.findIndex(index_finder)].Descripcion = evt.target.value;
                setActividades(new_array);
                break;
            case "Ponderacion":
                new_array[new_array.findIndex(index_finder)].Ponderacion = evt.target.value;
                setActividades(new_array);
                break;
            case "Criterio":
                new_array[new_array.findIndex(index_finder)].Criterio = evt.target.value;
                setActividades(new_array);
                break;
        }
        setBandera(bandera + 1);
    }

    const update_actividad = (evt) => {
        evt.preventDefault();
        const arr_actividad = evt.target.name.split("-");
        const index_finder = (actividad) => actividad.UniqueID == arr_actividad[1];
        const info = actividades[actividades.findIndex(index_finder)];
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/update/actividad', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data_ => {
                if (data_.codigo == 200) {
                    setContador(contador + 1);
                    setMensaje('Actividad actualizada exitosamente.');
                    setConsideracion('Por favor validar la información.');
                    setModalSucc(true);
                } else {
                    setMensaje(data_.respuesta.sqlState + ": " + data_.respuesta.sqlMessage);
                    setModalError(true);
                }
            });
    }

    const ocultar = () => {
        setModalError(false);
        setModalSucc(false);
        setConsideracion('');
    }

    const submitnewactividad = (actividad) => {
        if (actividad.Ponderacion + total > 100) {
            setMensaje("La ponderación total no puede pasar los 100 pts");
            setModalError(true);
        } else {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(actividad)
            };
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/create/actividad', requestOptions)
                .then((res) => {
                    return res.json();
                })
                .then(data_ => {
                    if (data_.codigo == 200) {
                        const requestBody = { EventoUniqueID: props.evento_uniqueid, Ponderacion: actividad.Ponderacion, actividad_UniqueID: data_.UniqueID, Criterio: actividad.Criterio };
                        const info = {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(requestBody)
                        };
                        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/upload/registro_academico/actividad/asignar', info)
                            .then((res) => {
                                return res.json();
                            })
                            .then(data_resp => {
                                if (data_resp.codigo == 200) {
                                    ocultar_nueva_actividad();
                                    setContador(contador + 1);
                                    setMensaje('Actividad registrada exitosamente');
                                    setConsideracion('');
                                    setModalSucc(true);
                                } else {
                                    setMensaje(data_resp.respuesta.sqlState + ": " + data_resp.respuesta.sqlMessage);
                                    setModalError(true);
                                }
                            });
                    } else {
                        setMensaje(data_.respuesta.sqlState + ": " + data_.respuesta.sqlMessage);
                        setModalError(true);
                    }
                });
        }
    }
    const ocultar_nueva_actividad = () => {
        setNueva_actividad(false);
    }

    const deleteactividad = (id) => {
        var confirmatinomessage = "¿Está segur@ que desea elimnar la actividad?";
        setConsideracion('Esta acción no se puede revertir, se eliminará la actividad y todos los entregables registrados.');
        setMensaje(confirmatinomessage);
        setId_actual(id);
        setMensajeConfirmacion(true);
    }
    const accioneliminaractividad = (id, extra) => {
        const info = { UniqueID: id };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/delete/actividad', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data_ => {
                if (data_.codigo == 200) {
                    ocultarConfirmacion();
                    setContador(contador + 1);
                    setMensaje('Actividad eliminada exitosamente');
                    setConsideracion('Se eliminó tanto la actividad como los entregables resgistrados.');
                    setModalSucc(true);
                } else {
                    setMensaje(data_.respuesta.sqlState + ": " + data_.respuesta.sqlMessage);
                    setModalError(true);
                }
            });
    }
    const ocultarConfirmacion = () => {
        setMensajeConfirmacion(false);
    }
    const entering = () => {
        setDetalleActividad('none');
        setDetalleActividades('block');
        setContador(contador + 1);
    }
    const onChange = (evt) => {
        setFilterActivities(evt.target.value);
        setBandera(bandera + 1);
        let result = [];
        result = actividades.filter((actual) => {
            return actual.Nombre.search(evt.target.value) != -1 || actual.fecha.search(evt.target.value) != -1;
        });
        setFilteredActivities(result);
        setBandera(bandera + 1);
    }
    return (
        <Container>
            <Modal
                {...props}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
                style={{ textAlign: 'center' }}
                onEnter={entering}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className="font-bold">
                        Actividades
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tabs defaultActiveKey="ponderacion" className="mb-3">
                        <Tab tabClassName="font-bold" eventKey="ponderacion" title="Planificación" style={{ padding: '2%' }}>
                            <Table striped bordered hover variant="dark" className='font-bold'>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Actividad</th>
                                        <th>Ponderación</th>
                                    </tr>
                                    {actividades.map((actividad, index) => <tr key={actividad.UniqueID}><td>{index + 1}</td><td>{actividad.Nombre}</td><td>{actividad.Ponderacion}</td></tr>)}
                                    <tr>
                                        <td colSpan={2} style={{ fontWeight: 'bolder' }}>TOTAL</td>
                                        <td style={{ fontWeight: 'bolder' }}>{total}</td>
                                    </tr>
                                </thead>
                                <tbody>
                                </tbody>
                            </Table>
                        </Tab>
                        <Tab tabClassName="font-bold" eventKey="calificaciones" title="Calificaciones">
                            <Tab.Container>
                                <Row style={{ display: detalleActividades }}>
                                    <Breadcrumb>
                                        <Breadcrumb.Item active>{props.evento_name}</Breadcrumb.Item>
                                        <Breadcrumb.Item active>
                                            Actividades
                                        </Breadcrumb.Item>
                                    </Breadcrumb>
                                </Row>
                                <Row style={{ marginTop: '20px' }}>
                                    <Col xs={12} md={12} lg={12} style={{ display: detalleActividades }}>
                                        <Row>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Buscar</Form.Label>
                                                <Form.Control value={filterActivities} onChange={onChange} placeholder="Puedes buscar por nombre o fecha de actividad" />
                                            </Form.Group>
                                        </Row>
                                    </Col>
                                    <Col xs={12} md={12} lg={12} style={{ display: detalleActividades }}>
                                        <ListGroup>
                                            {filteredActivities.map((actividad) => <Row xs={12} md={6} lg={4} style={{ marginBottom: '15px' }}><ListGroup.Item variant="dark" action key={actividad.UniqueID} href={"#" + actividad.UniqueID} onClick={() => { setDetalleActividades('none'); setDetalleActividad('block'); setBandera(bandera + 1); }}>{actividad.Nombre}</ListGroup.Item></Row>)}
                                        </ListGroup>
                                    </Col>
                                    <Col xs={12} md={12} lg={12} style={{ display: detalleActividad }}>
                                        <Tab.Content>
                                            <Suspense fallback={<div>Loading...</div>}>
                                                {actividades.map((actividad) =>
                                                    <Tab.Pane key={actividad.UniqueID} eventKey={"#" + actividad.UniqueID}>
                                                        <Row>
                                                            <Breadcrumb>
                                                                <Breadcrumb.Item active>{props.evento_name}</Breadcrumb.Item>
                                                                <Breadcrumb.Item active>
                                                                    Actividades
                                                                </Breadcrumb.Item>
                                                                <Breadcrumb.Item active>
                                                                    {actividad.Nombre}
                                                                </Breadcrumb.Item>
                                                            </Breadcrumb>
                                                        </Row>
                                                        <Row>
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="dark" style={{ width: '100%' }}>
                                                                    Acciones
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item onClick={exportDataExcel}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-spreadsheet-fill" viewBox="0 0 16 16">
                                                                            <path d="M12 0H4a2 2 0 0 0-2 2v4h12V2a2 2 0 0 0-2-2zm2 7h-4v2h4V7zm0 3h-4v2h4v-2zm0 3h-4v3h2a2 2 0 0 0 2-2v-1zm-5 3v-3H6v3h3zm-4 0v-3H2v1a2 2 0 0 0 2 2h1zm-3-4h3v-2H2v2zm0-3h3V7H2v2zm4 0V7h3v2H6zm0 1h3v2H6v-2z" />
                                                                        </svg>&nbsp;&nbsp;&nbsp;Descargar listado excel
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item onClick={reporteNotas}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-arrow-down-fill" viewBox="0 0 16 16">
                                                                            <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zm-1 4v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L7.5 11.293V7.5a.5.5 0 0 1 1 0z" />
                                                                        </svg>&nbsp;&nbsp;&nbsp;Descargar reporte de notas
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </Row>
                                                        <Row style={{ marginTop: '15px' }}>
                                                            <Row>
                                                                <Col xs={2} md={2} lg={2} onClick={() => { setDetalleActividades('block'); setDetalleActividad('none'); setBandera(bandera + 1); }}>
                                                                    <Button variant='outline-dark'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-backspace-fill" viewBox="0 0 16 16">
                                                                        <path d="M15.683 3a2 2 0 0 0-2-2h-7.08a2 2 0 0 0-1.519.698L.241 7.35a1 1 0 0 0 0 1.302l4.843 5.65A2 2 0 0 0 6.603 15h7.08a2 2 0 0 0 2-2V3zM5.829 5.854a.5.5 0 1 1 .707-.708l2.147 2.147 2.146-2.147a.5.5 0 1 1 .707.708L9.39 8l2.146 2.146a.5.5 0 0 1-.707.708L8.683 8.707l-2.147 2.147a.5.5 0 0 1-.707-.708L7.976 8 5.829 5.854z" />
                                                                    </svg>&nbsp;&nbsp;&nbsp; Atrás</Button>
                                                                </Col>
                                                            </Row>
                                                        </Row>
                                                        <Row>
                                                            <PonderarParticipantes actividad={actividad.UniqueID} ponderacion={actividad.Ponderacion} evento={props.evento_uniqueid} refrescar={() => { setContador(contador + 1); }} />
                                                        </Row>
                                                    </Tab.Pane>)}
                                            </Suspense >
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </Tab>
                        <Tab tabClassName="font-bold" eventKey="modificar-ponderacion" title="Modificar Planificación">
                            <Tab.Container>
                                <Row style={{ marginBottom: '25px' }}>
                                    <Col xs={1} md={1}>
                                        <div style={{ cursor: 'pointer', color: 'black' }} data-toggle="tooltip" data-placement="top" title="Nueva Actividad" onClick={() => setNueva_actividad(true)} >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                                            </svg>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={4}>
                                        <ListGroup>
                                            {actividades.map((actividad) => <ListGroup.Item action key={actividad.UniqueID} className="tab-actividades detalle" href={"#" + actividad.UniqueID}>{actividad.Nombre}</ListGroup.Item>)}
                                        </ListGroup>
                                    </Col>
                                    <Col sm={8}>
                                        <Tab.Content>
                                            {actividades.map((actividad) =>
                                                <Tab.Pane key={actividad.UniqueID} eventKey={"#" + actividad.UniqueID}>
                                                    <Container style={{ color: 'black' }}>
                                                        <Row>
                                                            <Col xs={1} md={1}>
                                                                <div style={{ cursor: 'pointer', color: 'black' }} data-toggle="tooltip" data-placement="top" title="Eliminar Actividad" onClick={() => deleteactividad(actividad.UniqueID)} >
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-trash-fill" viewBox="0 0 16 16">
                                                                        <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                                                                    </svg>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <EditarActividad key={actividad.UniqueID} actividad={actividad} onchangehandler={onchangehandler} update_actividad={update_actividad} />
                                                        </Row>
                                                    </Container>
                                                </Tab.Pane>
                                            )}
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </Tab>
                    </Tabs>
                </Modal.Body>
            </Modal>
            <SuccessModal
                color={"#d3d3d4"}
                show={modalSucc}
                onHide={ocultar}
                mensaje={mensaje}
                consideracion={consideracion}
                enlace={''}
                llevaenlace={false}
            />
            <ErrorModal
                color={"#d3d3d4"}
                show={modalError}
                onHide={ocultar}
                mensaje={mensaje}
                consideracion={consideracion}
            />
            <ModalNuevaActividad
                submitnewactividad={submitnewactividad}
                show={nueva_actividad}
                onHide={ocultar_nueva_actividad}
                evento_UniqueID={props.evento_uniqueid}
            />

            <Confirmacion
                show={mensajeConfirmacion}
                onHide={ocultarConfirmacion}
                accion={accioneliminaractividad}
                mensaje={mensaje}
                uniqueid={id_actual}
                consideracion={consideracion}
                param={''}
            />
        </Container>

    );
}

export default ModalActividades