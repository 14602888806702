import React, { useState, useEffect } from 'react';
import {
    useParams
} from "react-router-dom";
import { Row, Col, Button, Container, Form, Accordion, Alert, InputGroup } from "react-bootstrap";
import './ViewInscripcion.css';
//import DEFAULT_URL from '../../../api/url';
import { Confirmacion } from '../../Utils/ConfirmationModal/Confirmacion';
import { SuccessModal } from '../../Utils/SuccessModal/SuccessModal';
import { ErrorModal } from '../../Utils/ErrorModal/ErrorModal';
import { ModalCargando } from '../../Utils/Modal-Cargando/ModalCargando';
export const SeguimientoInscripcion = () => {
    const [inscripcion_Agremiado, setInscripcion_Agremiado] = useState("");
    const [inscripcion_Inversion, setInscripcion_Inversion] = useState("");
    const [inscripcion_Fecha_Max_FACT, setInscripcion_Fecha_Max_FACT] = useState("");
    const [inscripcion_Nombre_FACT, setInscripcion_Nombre_FACT] = useState("");
    const [inscripcion_NIT, setInscripcion_NIT] = useState("");
    const [inscripcion_Direccion, setInscripcion_Direccion] = useState("");
    const [inscripcion_Correo_FACT, setInscripcion_Correo_FACT] = useState("");
    const [inscripcion_Responsable, setInscripcion_Responsable] = useState("");
    const [inscripcion_Correo_RESP, setInscripcion_Correo_RESP] = useState("");
    const [inscripcion_Tel_RESP, setInscripcion_Tel_RESP] = useState("");
    const [inscripcion_Responsable_PAGO, setInscripcion_Responsable_PAGO] = useState("");
    const [inscripcion_Correo_RP, setInscripcion_Correo_RP] = useState("");
    const [inscripcion_Tel_RP, setInscripcion_Tel_RP] = useState("");
    const [inscripcion_Comentario, setInscripcion_Comentario] = useState("");
    const [evento_Nombre, setEvento_Nombre] = useState("");
    const [forma_pago_UniqueID, setForma_pago_UniqueID] = useState("");
    const [moneda_UniqueID, setMoneda_UniqueID] = useState("");
    const [moneda_Nombre, setMoneda_Nombre] = useState("");
    const [detalle, setDetalle] = useState([]);
    const [asesor, setAsesor] = useState({});
    const [empresa, setEmpresa] = useState({});
    const { id } = useParams();
    const [contador, setContador] = useState(0);
    const [formas_Pago, setFormas_pago] = useState([]);
    const [update, setUpdate] = useState(0);
    const [estado, setEstado] = useState("");
    const [confirmacion, setConfirmacion] = useState(false);
    const [mensaje, setMensaje] = useState('');
    const [consideracion, setConsideracion] = useState('');
    const [modalSucc, setModalSucc] = useState(false);
    const [modalError, setModalError] = useState(false);
    const [modalCargando, setModalCargando] = useState(false);
    const [mensajeCargando, setMensajeCargando] = useState('');
    const [curr, setCurr] = useState(false);
    const [comprobante, setComprobante] = useState(null);
    var endPoint = process.env.REACT_APP_DEFAULT_URL + "/api/read_id/inscripcion/" + id;
    useEffect(() => {
        async function fetchData() {
            fetch(endPoint, { cache: "no-cache" })
                .then(res => {
                    return res.json();
                })
                .then(data => {
                    setInscripcion_Agremiado(data.rows[0].inscripcion_Agremiado);
                    setInscripcion_Inversion(data.rows[0].inscripcion_Inversion);
                    setInscripcion_Fecha_Max_FACT(data.rows[0].inscripcion_Fecha_Max_FACT);
                    setInscripcion_Nombre_FACT(data.rows[0].inscripcion_Nombre_FACT);
                    setInscripcion_NIT(data.rows[0].inscripcion_NIT);
                    setInscripcion_Direccion(data.rows[0].inscripcion_Direccion);
                    setInscripcion_Correo_FACT(data.rows[0].inscripcion_Correo_FACT);
                    setInscripcion_Responsable(data.rows[0].inscripcion_Responsable);
                    setInscripcion_Correo_RESP(data.rows[0].inscripcion_Correo_RESP);
                    setInscripcion_Responsable_PAGO(data.rows[0].inscripcion_Responsable_PAGO);
                    setInscripcion_Correo_RP(data.rows[0].inscripcion_Correo_RP);
                    setInscripcion_Tel_RP(data.rows[0].inscripcion_Tel_RP);
                    setInscripcion_Comentario(data.rows[0].inscripcion_Comentario);
                    setEvento_Nombre(data.rows[0].evento_Nombre);
                    setForma_pago_UniqueID(data.rows[0].forma_pago_UniqueID);
                    setMoneda_UniqueID(data.rows[0].moneda_UniqueID);
                    if (data.rows[0].moneda_UniqueID == '7') {
                        setCurr(true);
                    }
                    setMoneda_Nombre(data.rows[0].moneda_Nombre);
                    setInscripcion_Tel_RESP(data.rows[0].inscripcion_Tel_RESP);
                    setDetalle(data.detalle);
                    setAsesor(data.asesor[0]);
                    setEmpresa(data.rows[0].empresa);
                    setEstado(data.rows[0].estado);
                    setComprobante(data.rows[0].comprobante);
                });
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/forma_pago', { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setFormas_pago(data.rows);
                });
        }
        fetchData();
    }, [contador]);
    const seleccion_moneda = (evt) => {
        if (evt.target.checked) {
            setMoneda_Nombre('USD');
            setMoneda_UniqueID(7)
        } else {
            setMoneda_Nombre('QTZ');
            setMoneda_UniqueID(8);
        }
        setCurr(evt.target.checked);
        setUpdate(update + 1);
    }
    useEffect(() => {
    }, [update]);

    const ocultar = () => {
        setModalSucc(false);
        setModalError(false);
        setContador(contador + 1);
    }
    const onChange = (evt) => {
        switch (evt.target.name) {
            case "Agremiado":
                setInscripcion_Agremiado(evt.target.checked);
                break;
            case "Inversion":
                setInscripcion_Inversion(evt.target.value);
                break;
            case "Fecha_Max_FACT":
                setInscripcion_Fecha_Max_FACT(evt.target.value);
                break;
            case "Nombre_FACT":
                setInscripcion_Nombre_FACT(evt.target.value);
                break;
            case "NIT":
                setInscripcion_NIT(evt.target.value);
                break;
            case "Direccion":
                setInscripcion_Direccion(evt.target.value);
                break;
            case "Correo_FACT":
                setInscripcion_Correo_FACT(evt.target.value);
                break;
            case "empresa":
                setEmpresa(evt.target.value);
                break;
            case "Responsable":
                setInscripcion_Responsable(evt.target.value);
                break;
            case "Correo_RESP":
                setInscripcion_Correo_RESP(evt.target.value);
                break;
            case "Tel_RESP":
                setInscripcion_Tel_RESP(evt.target.value);
                break;
            case "Responsable_PAGO":
                setInscripcion_Responsable_PAGO(evt.target.value);
                break;
            case "Correo_RP":
                setInscripcion_Correo_RP(evt.target.value);
                break;
            case "Tel_RP":
                setInscripcion_Tel_RP(evt.target.value);
                break;
            case "Forma_Pago_UniqueID":
                setForma_pago_UniqueID(evt.target.value);
                break;
            case "Comentario":
                setInscripcion_Comentario(evt.target.value);
        }
        setUpdate(update + 1);
    }
    const confirmar = (id, param) => {
        setConfirmacion(false);
        setMensajeCargando('Almacenando información, por favor espere...');
        setModalCargando(true);
        const info = { UniqueID: id };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/update/inscripcion/confirmar_pago', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                ocultarModalCargando();
                if (data.codigo == 200) {
                    setMensaje('Pago confirmado Exitosamente');
                    setModalSucc(true);
                } else {
                    setMensaje(data.respuesta);
                    setModalError(true);
                }
            });

    }
    const submit = (evt) => {
        evt.preventDefault();
        setMensajeCargando('Almacenando información, por favor espere...');
        setModalCargando(true);
        const info = {
            Agremiado: inscripcion_Agremiado,
            Inversion: inscripcion_Inversion,
            Fecha_Max_FACT: inscripcion_Fecha_Max_FACT,
            Nombre_FACT: inscripcion_Nombre_FACT,
            NIT: inscripcion_NIT,
            Direccion: inscripcion_Direccion,
            Correo_FACT: inscripcion_Correo_FACT,
            Responsable: inscripcion_Responsable,
            Correo_RESP: inscripcion_Correo_RESP,
            Tel_RESP: inscripcion_Tel_RESP,
            Responsable_PAGO: inscripcion_Responsable_PAGO,
            Correo_RP: inscripcion_Correo_RP,
            Tel_RP: inscripcion_Tel_RP,
            Comentario: inscripcion_Comentario,
            Forma_Pago_UniqueID: forma_pago_UniqueID,
            Moneda_Unique_ID: moneda_UniqueID,
            empresa: empresa,
            UniqueID: id
        };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/update/inscripcion', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                ocultarModalCargando();
                if (data.codigo == 200) {
                    setMensaje('Información Modificada Exitosamente');
                    setModalSucc(true);
                } else {
                    setMensaje(data.respuesta);
                    setModalError(true);
                }
            });

    }
    const ocultarConfirmacion = () => {
        setConfirmacion(false);
    }
    const ocultarModalCargando = () => {
        setModalCargando(false);
    }
    return (
        <div className="contenedor-view-inscripcion">
            <Container>
                <Row>
                    <h1 className="title-contancia">Seguimiento de inscripción</h1>
                </Row>
                <Row>
                    <Col xs={12} md={6} className="columna-formulario-view">
                        <Form onSubmit={submit}>
                            <Container>
                                <Row >
                                    <Col>
                                        <div style={{ padding: '2%', textAlign: 'center' }}>
                                            <Form.Group className="mb-3" controlId="Agremiado" style={{ color: 'white' }}>
                                                <Form.Check
                                                    type="switch"
                                                    id="custom-switch"
                                                    className="font-normal"
                                                    name="Agremiado"
                                                    label="Agremiados"
                                                    style={{ textAlign: 'left' }}
                                                    checked={inscripcion_Agremiado}
                                                    onChange={onChange}
                                                    disabled={estado == '2'}
                                                />
                                            </Form.Group>
                                            <Accordion defaultActiveKey="0" className="bg-success" style={{ marginTop: '5%' }}>
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header className="font-bold">INFORMACIÓN DEL EVENTO</Accordion.Header>
                                                    <Accordion.Body className="font-normal">
                                                        <Form.Group className="mb-3" controlId="Forma_Pago_UniqueID">
                                                            <Form.Label>Asesor</Form.Label>
                                                            <Form.Control type="text" value={asesor.Nombres + " " + asesor.Apellidos} disabled aria-label="Asesor" name="Asesor_UniqueID"></Form.Control>
                                                        </Form.Group>
                                                        <Form.Group className="mb-3" controlId="Inversion">
                                                            <Form.Label>Inversión</Form.Label>
                                                            <InputGroup className="mb-3">
                                                                <InputGroup.Text>{moneda_Nombre}</InputGroup.Text>
                                                                <Form.Control disabled={estado == '2'} required={estado == '1'} onChange={onChange} name="Inversion" placeholder="Ingrese el monto de inversión" value={inscripcion_Inversion} />
                                                            </InputGroup>
                                                            <Form.Check
                                                                type="switch"
                                                                id="switch-dolar"
                                                                className="font-normal"
                                                                label="$ (USD)"
                                                                onChange={seleccion_moneda}
                                                                style={{ textAlign: 'left' }}
                                                                disabled={estado == '2'}
                                                                checked={curr}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group className="mb-3" controlId="Evento_Unique_ID">
                                                            <Form.Label>Evento</Form.Label>
                                                            <Form.Control type="text" disabled aria-label="Evento" name="Evento_Unique_ID" value={evento_Nombre} ></Form.Control>
                                                        </Form.Group>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header className="font-bold">DATOS PARA LA FACTURA ELECTRÓNICA</Accordion.Header>
                                                    <Accordion.Body className="font-normal">
                                                        <Form.Group className="mb-3" controlId="Fecha_Max_FACT">
                                                            <Form.Label>Fecha máxima que reciben facturas</Form.Label>
                                                            <Form.Control disabled={estado == '2'} required={estado == '1'} onChange={onChange} type="date" name="Fecha_Max_FACT" placeholder="Seleccione la fecha" value={inscripcion_Fecha_Max_FACT} />
                                                        </Form.Group>
                                                        <Form.Group className="mb-3" controlId="Nombre_FACT">
                                                            <Form.Label>Nombre para la factura</Form.Label>
                                                            <Form.Control disabled={estado == '2'} required={estado == '1'} onChange={onChange} type="text" name="Nombre_FACT" placeholder="Consumidor Final" value={inscripcion_Nombre_FACT} />
                                                        </Form.Group>
                                                        <Form.Group className="mb-3" controlId="NIT">
                                                            <Form.Label>NIT</Form.Label>
                                                            <Form.Control disabled={estado == '2'} required={estado == '1'} onChange={onChange} type="text" placeholder="CF" name="NIT" value={inscripcion_NIT} />
                                                        </Form.Group>
                                                        <Form.Group className="mb-3" controlId="Direccion">
                                                            <Form.Label>Dirección</Form.Label>
                                                            <Form.Control disabled={estado == '2'} required={estado == '1'} onChange={onChange} type="text" placeholder="Ciudad" name="Direccion" value={inscripcion_Direccion} />
                                                        </Form.Group>
                                                        <Form.Group className="mb-3" controlId="Correo_FACT">
                                                            <Form.Label>Correo para enviar factura electrónica</Form.Label>
                                                            <Form.Control disabled={estado == '2'} required={estado == '1'} onChange={onChange} type="text" placeholder="Por favor ingrese el correo" name="Correo_FACT" value={inscripcion_Correo_FACT} />
                                                        </Form.Group>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="2">
                                                    <Accordion.Header className="font-bold">CONTACTO Y FORMA DE PAGO</Accordion.Header>
                                                    <Accordion.Body className="font-normal">
                                                        <Row>
                                                            <Col xs={12} md={12}>
                                                                <Form.Group className="mb-3" controlId="Empresa">
                                                                    <Form.Label>Empresa</Form.Label>
                                                                    <Form.Control disabled={estado == '2'} required={estado == '1'} onChange={onChange} type="text" placeholder="Ingrese la empresa" name="empresa" value={empresa} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xs={12} md={12}>
                                                                <Form.Group className="mb-3" controlId="Responsable">
                                                                    <Form.Label>Responsable de la inscripción</Form.Label>
                                                                    <Form.Control disabled={estado == '2'} required={estado == '1'} onChange={onChange} type="text" placeholder="Ingrese el responsable" name="Responsable" value={inscripcion_Responsable} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xs={12} md={8}>
                                                                <Form.Group className="mb-3" controlId="Correo_RESP">
                                                                    <Form.Label>Correo</Form.Label>
                                                                    <Form.Control disabled={estado == '2'} required={estado == '1'} onChange={onChange} type="text" placeholder="Ingrese el correo" name="Correo_RESP" value={inscripcion_Correo_RESP} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xs={12} md={4}>
                                                                <Form.Group className="mb-3" controlId="Tel_RESP">
                                                                    <Form.Label>Tel.</Form.Label>
                                                                    <Form.Control disabled={estado == '2'} required={estado == '1'} onChange={onChange} type="text" placeholder="Ingrese el teléfono" name="Tel_RESP" value={inscripcion_Tel_RESP} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xs={12} md={12}>
                                                                <Form.Group className="mb-3" controlId="Responsable_PAGO">
                                                                    <Form.Label>Persona responsable del trámite de Pago</Form.Label>
                                                                    <Form.Control disabled={estado == '2'} required={estado == '1'} onChange={onChange} type="text" placeholder="Ingrese el responsable" name="Responsable_PAGO" value={inscripcion_Responsable_PAGO} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xs={12} md={8}>
                                                                <Form.Group className="mb-3" controlId="Correo_RP">
                                                                    <Form.Label>Correo</Form.Label>
                                                                    <Form.Control disabled={estado == '2'} required={estado == '1'} onChange={onChange} type="email" placeholder="Ingrese el correo" name="Correo_RP" value={inscripcion_Correo_RP} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xs={12} md={4}>
                                                                <Form.Group className="mb-3" controlId="Tel_RP">
                                                                    <Form.Label>Tel.</Form.Label>
                                                                    <Form.Control disabled={estado == '2'} required={estado == '1'} onChange={onChange} type="text" placeholder="Ingrese el teléfono" name="Tel_RP" value={inscripcion_Tel_RP} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xs={12} md={12}>
                                                                <Form.Group className="mb-3" controlId="Forma_Pago_UniqueID">
                                                                    <Form.Label>Indique su forma de pago</Form.Label>
                                                                    <Form.Control as="select" aria-label="Evento" name="Forma_Pago_UniqueID" disabled={estado == '2'} required={estado == '1'} onChange={onChange} value={forma_pago_UniqueID}>
                                                                        <option value="" key="0">Seleccione la forma de pago</option>
                                                                        {formas_Pago.map(forma_pago => <option key={forma_pago.UniqueID} value={forma_pago.UniqueID}>{forma_pago.Nombre}</option>)}
                                                                    </Form.Control>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xs={12} md={12}>
                                                                <Form.Group className="mb-3" controlId="Comentario">
                                                                    <Form.Label>Comentario sobre el pago</Form.Label>
                                                                    <Form.Control disabled={estado == '2'} required={estado == '1'} onChange={onChange} as="textarea" rows={3} style={{ resize: 'none' }} name="Comentario" value={inscripcion_Comentario} />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    </Col>
                                </Row>
                                {estado == '1' &&
                                    <Row style={{ marginTop: '25px', marginBottom: '25px' }}>
                                        <Button type="submit" variant='light'>Subir Cambios</Button>
                                    </Row>}
                                <Row style={{ marginTop: '25px', marginBottom: '25px' }}>
                                    <Container>
                                        <Row>
                                            <Alert show={estado == '1'} style={{ textAlign: 'left' }} variant="danger">
                                                <Alert.Heading>Inscripción pendiente de pago</Alert.Heading>
                                                <p>
                                                    Por favor contactar a cliente para dar seguimiento al pago
                                                    de la presente ficha de inscripción.
                                                </p>
                                                <hr />
                                                <div className="d-flex justify-content-end">
                                                    <Button variant="outline-danger" onClick={() => { setMensaje('¿Estás segur@ de confirmar el pago de la presente ficha de inscripcioón?'); setConsideracion('Esta acción no se puede revertir'); setConfirmacion(true); }}>
                                                        Confirmar Pago
                                                    </Button>
                                                </div>
                                            </Alert>
                                            <Alert show={estado == '2'} style={{ textAlign: 'left' }} variant="success">
                                                <Alert.Heading>Pago de inscripción confirmado</Alert.Heading>
                                                <p>
                                                    ¡Todo listo! se ha confirmado el pago de la presente ficha de inscripción.
                                                </p>
                                                <hr />
                                                <p>
                                                    La información de esta ficha de inscripción no podrá
                                                    modificarse.
                                                </p>
                                            </Alert>
                                        </Row>
                                    </Container>
                                </Row>
                            </Container>
                        </Form>
                    </Col>
                    <Col xs={12} md={6}>
                        <Container>
                            <Row>
                                <Col>
                                    <div className="view-detalle" style={{ padding: '2%', textAlign: 'center' }}>
                                        <Accordion>
                                            {detalle.map(actual =>
                                                <Accordion.Item eventKey={actual.UniqueID} key={actual.UniqueID}>
                                                    <Accordion.Header>{actual.Nombres + ' ' + actual.Apellidos}</Accordion.Header>
                                                    <Accordion.Body>
                                                        <Container>
                                                            {inscripcion_Inversion > 0 && <Row>
                                                                <Col xs={12} md={6}>
                                                                    <Form.Group className="mb-3" >
                                                                        <Form.Label>No. identificacion</Form.Label>
                                                                        <Form.Control disabled type="text" value={actual.identificacion} />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col xs={12} md={6}>
                                                                    <Form.Group className="mb-3" >
                                                                        <Form.Label>País</Form.Label>
                                                                        <Form.Control disabled type="text" value={actual.pais} />
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>}
                                                            {inscripcion_Inversion == 0 && <Row>
                                                                <Col xs={12} md={12}>
                                                                    <Form.Group className="mb-3" >
                                                                        <Form.Label>País</Form.Label>
                                                                        <Form.Control disabled type="text" value={actual.pais} />
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>}
                                                            <Row>
                                                                <Col xs={12} md={6}>

                                                                    <Form.Group className="mb-3" >
                                                                        <Form.Label>Nombres</Form.Label>
                                                                        <Form.Control disabled type="text" value={actual.Nombres} />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col xs={12} md={6}>
                                                                    <Form.Group className="mb-3" >
                                                                        <Form.Label>Apellidos</Form.Label>
                                                                        <Form.Control disabled type="text" value={actual.Apellidos} />
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={12} md={6}>
                                                                    <Form.Group className="mb-3" >
                                                                        <Form.Label>Puesto</Form.Label>
                                                                        <Form.Control disabled type="text" value={actual.Puesto} />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col xs={12} md={6}>
                                                                    <Form.Group className="mb-3" >
                                                                        <Form.Label>Correo Electrónico</Form.Label>
                                                                        <Form.Control disabled type="email" value={actual.Correo} />
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={12} md={6}>
                                                                    <Form.Group className="mb-3" >
                                                                        <Form.Label>Teléfono y extensión</Form.Label>
                                                                        <Form.Control disabled type="text" value={actual.Telefono} />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col xs={12} md={6}>
                                                                    <Form.Group className="mb-3" >
                                                                        <Form.Label>Celular</Form.Label>
                                                                        <Form.Control disabled type="text" value={actual.celular} />
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                        </Container>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            )}
                                        </Accordion>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
                {comprobante != null && <Row style={{ color: 'white', paddingBottom:'75px' }}>
                    <h1 className="title-contancia">Scan de cheque o depósito/transferencia</h1>
                    <iframe src = {comprobante} alt = 'comprobante' style = {{height:'800px'}}/>
                </Row>}
            </Container>
            <Confirmacion
                show={confirmacion}
                onHide={ocultarConfirmacion}
                accion={confirmar}
                mensaje={mensaje}
                uniqueid={id}
                consideracion={consideracion}
                param=''
            />
            <SuccessModal
                color={"#b5bad0"}
                show={modalSucc}
                onHide={ocultar}
                mensaje={mensaje}
                consideracion={consideracion}
                enlace={''}
                llevaenlace={false}
            />
            <ErrorModal
                color={"#b5bad0"}
                show={modalError}
                onHide={ocultar}
                mensaje={mensaje}
                consideracion={consideracion}
            />
            <ModalCargando
                color={"#b5bad0"}
                show={modalCargando}
                onHide={ocultarModalCargando}
                mensaje={mensajeCargando}
            />
        </div>
    );

}