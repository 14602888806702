import React, { useState, useEffect } from 'react'
import { Modal, Alert, Row, Form, Container } from "react-bootstrap";
import './common.css'
const VerRespuestas = (props) => {
    const [contador, setContador] = useState(0);
    const [preguntas, setPreguntas] = useState([]);
    const [respuesta, setRespuesta] = useState([]);
    useEffect(async () => {

    }, [contador]);
    const entering = () => {
        setPreguntas(props.preguntas);
        setRespuesta(props.respuesta);
        setContador(contador + 1);
    }
    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
            style={{ color: 'black' }}
            onEnter={entering}
        >
            <Modal.Header closeButton style={{ backgroundColor: "#617785" }}>
                <Modal.Title id="contained-modal-title-vcenter" className="font-bold">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-info" viewBox="0 0 16 16">
                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                    </svg>
                    &nbsp;&nbsp;&nbsp;Detalle de respuestas
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: "#617785", height: '80vh' }} className="cuerpo-modal-nuevo-cliente">
                <Form>
                    <Container style={{ padding: '2%' }}>
                        {(respuesta != null && respuesta.enlazado == 1) &&
                            <Alert variant="danger">
                                <Alert.Heading>Respuesta asociada a inscripción</Alert.Heading>
                                <p>
                                    Cualquier cambio en este registro se verá reflejado en los reportes de inscripción de gerencia
                                </p>
                                <hr />
                                <p className="mb-0">
                                    Cualquier acción con este registro afecta la integridad de la información
                                </p>
                            </Alert>
                        }
                        {respuesta != null &&
                            preguntas.map((pregunta, index) =>
                                <Row style={{ backgroundColor: 'white', borderRadius: '15px', padding: '10px', marginBottom: '25px', color: 'black' }} key={pregunta.UniqueID}>
                                    {pregunta.tipo_pregunta_UniqueID == '1' &&
                                        <Form.Group className="mb-3" controlId={pregunta.UniqueID}>
                                            <Form.Label>{pregunta.pregunta}</Form.Label>
                                            <Form.Control type="text" disabled placeholder="Ingrese la pregunta " name={pregunta.UniqueID} style={{ backgroundColor: '#617785', color: 'white' }} value={respuesta.respuestas[index].texto} />
                                        </Form.Group>
                                    }
                                    {pregunta.tipo_pregunta_UniqueID == '2' &&
                                        <Form.Group className="mb-3" controlId={pregunta.UniqueID}>
                                            <Form.Label>{pregunta.pregunta}</Form.Label>
                                            <Form.Control type="text" disabled placeholder="Ingrese la pregunta " name={pregunta.UniqueID} style={{ backgroundColor: '#617785', color: 'white' }} value={respuesta.respuestas[index].texto} />
                                        </Form.Group>
                                    }
                                    {pregunta.tipo_pregunta_UniqueID == '3' &&
                                        <Form.Group className="mb-3" controlId={pregunta.UniqueID}>
                                            <Form.Label>{pregunta.pregunta}</Form.Label>
                                            <Form.Control type="text" disabled placeholder="Ingrese la pregunta " name={pregunta.UniqueID} style={{ backgroundColor: '#617785', color: 'white' }} value={respuesta.respuestas[index].texto} />
                                        </Form.Group>
                                    }
                                    {pregunta.tipo_pregunta_UniqueID == '4' &&
                                        <Form.Group className="mb-3" controlId={pregunta.UniqueID}>
                                            <Form.Label>{pregunta.pregunta}</Form.Label>
                                            <Form.Control type="text" disabled placeholder="Ingrese la pregunta " name={pregunta.UniqueID} style={{ backgroundColor: '#617785', color: 'white' }} value={respuesta.respuestas[index].texto} />
                                        </Form.Group>
                                    }
                                </Row>
                            )
                        }
                    </Container>
                </Form>
            </Modal.Body>
        </Modal >
    )
}

export default VerRespuestas