import React, { useState, useEffect } from 'react'
import { Row, Col, ListGroup, Tab, Container } from "react-bootstrap";
import { ModalNuevoCliente } from './../ModalNuevoCliente/ModalNuevoCliente'
import { ErrorModal } from '../../../Utils/ErrorModal/ErrorModal';
import { SuccessModal } from '../../../Utils/SuccessModal/SuccessModal';
//import DEFAULT_URL from '../../../../api/url';
import ClienteInfo from '../Clientes/ClienteInfo'
import Solicitud from './Solicitud';
import { Confirmacion } from '../../../Utils/ConfirmationModal/Confirmacion';
import { ModalEditarCliente } from './../ModalEditarCliente/ModalEditarCliente';
import { ModalCargando } from '../../../Utils/Modal-Cargando/ModalCargando';
const Prospectos = () => {
    const [modalError, setModalError] = useState(false);
    const [modalSucc, setModalSucc] = useState(false);
    const [nuevoCliente, setNuevoCliente] = useState(false);
    const [prospectos, setProspectos] = useState([]);
    const [contador, setContador] = useState(0);
    const [mensaje, setMensaje] = useState('');
    const [clienteActual, setClienteActual] = useState({});
    const [editarCliente, setEditarCliente] = useState(false);
    const [consideracion, setConsideracion] = useState('');
    const [idActual, setIdActual] = useState('');
    const [confirmacionEliminar, setConfirmacionEliminar] = useState(false);
    const [modalCargando, setModalCargando] = useState(false);
    const [mensajeCargando, setMensajeCargando] = useState('');
    const [asesor, setAsesor] = useState('');
    const cerrarNuevoCliente = () => {
        setNuevoCliente(false);
        setContador(contador + 1);
    }
    useEffect(async () => {
        async function fetchData() {
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/auth/userinfo', {cache: "no-cache",  credentials: 'include' })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/prospectos/' + data.UniqueID, { cache: "no-cache" })
                        .then((res) => {
                            return res.json();
                        })
                        .then(data => {
                            setProspectos(data.rows);
                        });
                    setAsesor(data.UniqueID);
                });
        }
        fetchData();
    }, [contador]);
    const eliminar = (id) => {
        const info = {
            Empresa_UniqueID: id
        }
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/delete/cliente', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                if (data.codigo == 200) {
                    setModalSucc(true);
                    setMensaje('Cliente eliminado del sistema exitosamente');
                    ocultarEliminarConfirmacion();
                    setContador(contador + 1);
                } else {
                    setModalError(true);
                    ocultarEliminarConfirmacion();
                    setMensaje('ERROR');
                }
            });
    }
    const ocultarEliminarConfirmacion = () => {
        setConfirmacionEliminar(false);
    }
    const cerrar = () => {
        setModalError(false);
        setModalSucc(false);
    }
    const cerrarEditarCliente = () => {
        setEditarCliente(false);
        setContador(contador + 1);
    }

    const submitEdit = (cliente) => {
        const info = {
            Empresa: cliente.empresa,
            Direccion: cliente.direccion,
            Telefono: cliente.telefono,
            asesor_UniqueID: asesor,
            NombreFactura: cliente.NombreFactura,
            NIT: cliente.NIT,
            CorreoFacturacion: cliente.CorreoFacturacion,
            ResponsableInscripcion: cliente.ResponsableInscripcion,
            TelefonoResponsable: cliente.TelefonoResponsable,
            CorreoResponsable: cliente.CorreoResponsable,
            ResponsablePago: cliente.ResponsablePago,
            CorreoPago: cliente.CorreoPago,
            TelefonoPago: cliente.TelefonoPago,
            FormaPago: cliente.FormaPago,
            Empresa_UniqueID: cliente.Empresa_UniqueID,
            tipo: cliente.Prioridad,
            comentario: cliente.Comentario,
        }
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/update/prospecto', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                if (data.codigo == 200) {
                    setModalSucc(true);
                    setMensaje('Información ingresada al sistema exitosamente');
                    cerrarEditarCliente();
                } else {
                    setModalError(true);
                    setMensaje('ERROR');
                }
            });
    }
    const submitCliente = (cliente) => {
        setMensajeCargando('Subiendo prospecto al sistema, por favor espere...');
        setModalCargando(true);
        if (!/^(([0-9]+)((k?)|(K?)){1,13})$/.test(cliente.NIT)) {
            setModalCargando(false);
            setMensaje('ERROR: El nit ingresado no cumple con la estructura requerida');
            setConsideracion('La estructura consiste en números y opcionalmente al final la letra "K" sin guiones')
            setModalError(true);
            return false;
        } else {
            const info = {
                Empresa: cliente.empresa,
                Direccion: cliente.direccion,
                Telefono: cliente.telefono,
                asesor_UniqueID: asesor,
                NombreFactura: cliente.NombreFactura,
                NIT: cliente.NIT,
                CorreoFacturacion: cliente.CorreoFacturacion,
                ResponsableInscripcion: cliente.ResponsableInscripcion,
                TelefonoResponsable: cliente.TelefonoResponsable,
                CorreoResponsable: cliente.CorreoResponsable,
                ResponsablePago: cliente.ResponsablePago,
                CorreoPago: cliente.CorreoPago,
                TelefonoPago: cliente.TelefonoPago,
                FormaPago: cliente.FormaPago,
                Comentario: cliente.Comentario,
                Prioridad: cliente.Prioridad
            }
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(info)
            };
            console.log(requestOptions);
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/create/cliente', requestOptions)
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setModalCargando(false);
                    if (data.codigo == 200) {
                        setModalSucc(true);
                        setMensaje('Cliente ingresado al sistema exitosamente');
                        cerrarNuevoCliente();
                        return true;
                    } else {
                        setModalError(true);
                        setMensaje('ERROR');
                        return false;
                    }
                });
        }
    }
    return (
        <Container>
            <Row>
                <Col xs={1} md={1} lg={1}>
                    <div style={{ color: 'white', cursor: 'pointer' }} data-toggle="tooltip" data-placement="top" title="Nuevo Cliente" onClick={() => { setNuevoCliente(true); setContador(contador + 1); }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                        </svg>
                    </div>
                </Col>
                <Col xs={1} md={1} lg={1}>
                    <div style={{ color: 'white', cursor: 'pointer' }} data-toggle="tooltip" data-placement="top" title="Refrescar" onClick={() => setContador(contador + 1)} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                            <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                        </svg>
                    </div>
                </Col>
            </Row>
            <Row style={{ padding: '20px' }}>
                <Tab.Container id="list-group-tabs-example" >
                    <Row>
                        <Col sm={4} className="columna-contenido">
                            <ListGroup>
                                {prospectos != null && prospectos.map(empresa =>
                                    <ListGroup.Item key={empresa.UniqueID} className="tab-cartera cliente" style={{ backgroundColor: '#ffffff', textAlign: 'left' }} action href={"#" + empresa.UniqueID} onClick={() => setClienteActual(empresa)}>
                                        {empresa.Empresa}
                                    </ListGroup.Item>
                                )}
                            </ListGroup>
                        </Col>
                        <Col sm={8} className="columna-contenido">
                            <Tab.Content>
                                {prospectos != null && prospectos.map(prospecto =>
                                    <Tab.Pane eventKey={"#" + prospecto.UniqueID} key={prospecto.UniqueID}>
                                        <Container>
                                            <Row style={{ marginBottom: '20px' }}>
                                                <Col xs={1} md={1} lg={1}>
                                                    <div style={{ color: 'white', cursor: 'pointer' }} data-toggle="tooltip" data-placement="top" title="Eliminar Cliente" onClick={() => { setMensaje('¿Está segur@ de elminar el prospecto seleccionado?'); setConsideracion('Esta acción no se puede revertir'); setIdActual(prospecto.UniqueID); setConfirmacionEliminar(true); }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-trash3-fill" viewBox="0 0 16 16">
                                                            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                                                        </svg>
                                                    </div>
                                                </Col>
                                                <Col xs={1} md={1} lg={1}>
                                                    <div style={{ color: 'white', cursor: 'pointer' }} data-toggle="tooltip" data-placement="top" title="Actualizar Cliente" onClick={() => { setClienteActual(prospecto); setEditarCliente(true) }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-pencil-fill" viewBox="0 0 16 16">
                                                            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                                        </svg>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Solicitud solicitud={prospecto} />
                                            <Row>
                                                <h1 style={{ marginBottom: '25px', marginTop: '25px', color: 'white' }}>Información del prospecto</h1>
                                                <ClienteInfo empresa={prospecto} />
                                            </Row>
                                        </Container>
                                    </Tab.Pane>
                                )}
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Row>
            <ModalNuevoCliente
                show={nuevoCliente}
                onHide={cerrarNuevoCliente}
                submitCliente={submitCliente}
            />
            <ErrorModal
                color={"#E0E0E2"}
                show={modalError}
                onHide={cerrar}
                mensaje={mensaje}
                consideracion={consideracion}
            />
            <SuccessModal
                color={"#E0E0E2"}
                show={modalSucc}
                onHide={cerrar}
                mensaje={mensaje}
                consideracion={''}
                enlace={''}
                llevaenlace={false}
            />
            <ModalEditarCliente
                tipo='1'
                show={editarCliente}
                onHide={cerrarEditarCliente}
                submitEdit={submitEdit}
                cliente={clienteActual}
            />
            <Confirmacion
                show={confirmacionEliminar}
                onHide={ocultarEliminarConfirmacion}
                accion={eliminar}
                mensaje={mensaje}
                uniqueid={idActual}
                consideracion={consideracion}
                param=''
            />
            <ModalCargando
                color={"#b5bad0"}
                show={modalCargando}
                onHide={() => setModalCargando(false)}
                mensaje={mensajeCargando}
            />
        </Container>)
}

export default Prospectos