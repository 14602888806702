import React from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Navbar, Nav } from "react-bootstrap";
import { Outlet } from "react-router-dom";
const GafetesFisicos = () => {
  const { hash } = useParams();
  return (
    <Container fluid>
      <Row>
        <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
          <Container>
            <Navbar.Brand
              href={process.env.REACT_APP_SELF_URL + "/gafetes-fisicos/" + hash+'/all'}
            >
              Gafetes físicos
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link
                  href={
                    process.env.REACT_APP_SELF_URL +
                    "/gafetes-fisicos/" +
                    hash +
                    "/create"
                  }
                >
                  Nuevo Gafete
                </Nav.Link>
              </Nav>
              <Nav>
                <Nav.Link eventKey={2} href={"/home"}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-90deg-left"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1.146 4.854a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H12.5A2.5 2.5 0 0 1 15 6.5v8a.5.5 0 0 1-1 0v-8A1.5 1.5 0 0 0 12.5 5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4z"
                    />
                  </svg>
                  &nbsp;&nbsp;&nbsp;Regresar a sistema
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </Row>
      <Row>
        <Outlet />
      </Row>
    </Container>
  );
};

export default GafetesFisicos;
