import React, { useState, useEffect } from 'react'
import useLogout from '../../../hooks/useLogout';
import './Cartera.css'
import { Row, Button, Col, Tabs, Tab, Container, ButtonGroup, Popover, Card, OverlayTrigger } from "react-bootstrap";
import Clientes from './Clientes/Clientes';
import Procesadas from './Inscripciones/Procesadas';
import NoProcesadas from './Inscripciones/NoProcesadas';
import Prospectos from './Prospectos/Prospectos';
import Leads from './Leads/Leads';
import Propuestas from './Propuestas/Propuestas';
function Cartera() {
  const [nombres, setNombres] = useState('');
  const [apellidos, setApellidos] = useState('');
  const [img, setImg] = useState('');
  const [email, setEmail] = useState('');
  const [contador, setContador] = useState(0);
  const [key, setKey] = useState('leads');
  const [uniqueID, setUniqueID] = useState('');
  const [hash, setHash] = useState('');
  useEffect(async () => {
    async function fetchData() {
      fetch(process.env.REACT_APP_DEFAULT_URL + '/api/auth/userinfo', {cache: "no-cache",  credentials: 'include' })
        .then((res) => {
          return res.json();
        })
        .then(data => {
          setNombres(data.nombres);
          setApellidos(data.apellidos);
          setImg(data.foto);
          setUniqueID(data.UniqueID);
          setEmail(data.email);
          setHash(data.hash);
        });
    }
    fetchData();
  }, [contador]);
  const logout = useLogout();
  const singOut = async () => {
    await logout();
    setContador(contador + 1);
    window.location.replace('/');
  }
  const popover = (
    <Popover id="popover-basic">
      <Card className="text-center">
        <Card.Img variant="top" src={img} style={{ height: '300px' }} />
        <Card.Body>
          <Card.Title>{nombres + ' ' + apellidos}</Card.Title>
          <Card.Text>
            {email}
          </Card.Text>
          <Card.Text style={{ cursor: 'pointer' }}>
            <a target="_blank" href={process.env.REACT_APP_SELF_URL + '/asesor/page/' + hash}>Ver mi perfil público</a>
          </Card.Text>
        </Card.Body>
        <Card.Footer>
          <ButtonGroup className="me-2" aria-label="First group">
            <Button style={{ backgroundColor: '#343434 ', borderColor: 'transparent' }} onClick={() => window.location.replace('/usuarios/actualizar')}>Editar Perfil</Button>
            <Button style={{ backgroundColor: '#1e3d52', borderColor: 'transparent' }} onClick={singOut}>Cerrar Sesión</Button>
          </ButtonGroup>
        </Card.Footer>
      </Card>
    </Popover>
  );
  return (
    <div className="contenedor-cartera">
      <Container style={{ padding: '30px' }}>
        <Row style={{}}>
          <Col xs={8} md={8} lg={8}>
            <div style={{ right: '45%', position: 'relative', cursor: 'pointer', color: 'white' }} data-toggle="tooltip" data-placement="top" title="Atrás" onClick={() => window.location.replace('/asesor')}>
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-reply-fill" viewBox="0 0 16 16">
                <path d="M5.921 11.9 1.353 8.62a.719.719 0 0 1 0-1.238L5.921 4.1A.716.716 0 0 1 7 4.719V6c1.5 0 6 0 7 8-2.5-4.5-7-4-7-4v1.281c0 .56-.606.898-1.079.62z" />
              </svg>
            </div>
          </Col>
          <Col xs={4} md={4} lg={4} style={{ left: '0%', position: 'relative' }}>
            <OverlayTrigger trigger="click" placement="bottom" overlay={popover} rootClose>
              <Button variant='light'>{nombres}</Button>
            </OverlayTrigger>
          </Col>
        </Row>
        <Row style={{ padding: '15px' }}>
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
          >
            <Tab eventKey="leads" title="Leads" tabClassName='tab-cartera'>
              <Leads uniqueID={uniqueID} />
            </Tab>
            <Tab eventKey="prospectos" title="Prospectos" tabClassName='tab-cartera'>
              <Prospectos uniqueID={uniqueID} />
            </Tab>
            <Tab eventKey="clientes" title="Cartera de clientes" tabClassName='tab-cartera'>
              <Clientes uniqueID={uniqueID} />
            </Tab>
            <Tab eventKey="procesadas" title="Inscripciones procesadas" tabClassName='tab-cartera'>
              <Procesadas uniqueID={uniqueID} />
            </Tab>
            <Tab eventKey="noprocesadas" title="Inscripciones no procesadas" tabClassName='tab-cartera'>
              <NoProcesadas uniqueID={uniqueID} />
            </Tab>
            <Tab eventKey="propuestas" title="Propuestas in house" tabClassName='tab-cartera'>
              <Propuestas uniqueID={uniqueID} />
            </Tab>
          </Tabs>
        </Row>
      </Container>
    </div>
  )
}

export default Cartera