import React, { Component } from 'react';
import { Modal, Row, Button, Form, Container } from "react-bootstrap";
export class IngresarCalificacion extends Component {

    state = { nota: 0, comentario: '' }

    onChange = (evt) => {
        this.setState({ [evt.target.name]: evt.target.value });
    }
    calificar = (evt) => {
        evt.preventDefault();
        this.props.calificar(this.state);
        this.props.onHide();
    }
    render() {
        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton style={{ backgroundColor: "#b5bad0" }}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Ingresar Calificación
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: "#b5bad0" }}>
                    <Form onSubmit={this.calificar}>
                        <Container>
                            <Row>
                                <Form.Group className="mb-3" controlId="Tel_RP">
                                    <Form.Label>Nota</Form.Label>
                                    <Form.Control required type="text" placeholder={"Nota sobre " + this.props.ponderacion + ' pts'} name="nota" onChange={this.onChange} />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group className="mb-3" controlId="Comentario">
                                    <Form.Label>Comentario</Form.Label>
                                    <Form.Control as="textarea" rows={3} style={{ resize: 'none' }} name="comentario" onChange={this.onChange} />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Button variant="secondary" className="font-bold" type="submit">Calificar</Button>
                            </Row>
                        </Container>
                    </Form>
                </Modal.Body>
            </Modal>

        );
    }
}