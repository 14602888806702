import useAuth from '../useAuth';
//import DEFAULT_URL from '../api/url';

const useLogout = () => {
    const { setAuth } = useAuth();
    const logout = async () => {
        setAuth({});
        //localStorage.removeItem("CEA");
        try {
            /*
            const response = await axios('/api/auth/logout', {
                withCredentials: true
            })*/

            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/auth/logout', { cache: "no-cache", credentials: 'include' });
        } catch (err) {
            console.log(err);
        }
    }
    return logout;
}

export default useLogout;