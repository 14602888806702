import React, { useEffect, useState } from 'react'
import { Row, Button, Col, Form, Alert, ListGroup, Container, Modal } from "react-bootstrap";
//import DEFAULT_URL from '../../../../api/url';
import { SuccessModal } from '../../../Utils/SuccessModal/SuccessModal';
import { ErrorModal } from '../../../Utils/ErrorModal/ErrorModal';
import ModalNuevaIntervencion from './ModalNuevaIntervencion';
import ModalEditarLead from './ModalEditarLead';
import { ModalNuevoCliente } from './ModalNuevoProspecto';
const ModalSeguimiento = (props) => {
    const [contador, setContador] = useState(0);
    const [modalSucc, setModalSucc] = useState(false);
    const [modalError, setModalError] = useState(false);
    const [mensaje, setMensaje] = useState('');
    const [nuevaIntervencion, setNuevaIntervencion] = useState(false);
    const [intervenciones, setIntervenciones] = useState([]);
    const [editarLead, setEditarLead] = useState(false);
    const [nuevoCliente, setNuevoCliente] = useState(false);
    useEffect(() => {
        async function fetchData() {
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/leads/intervenciones/' + props.lead.UniqueID, { cache: "no-cache", credentials: 'include' })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setIntervenciones(data.rows);
                });
        }
        fetchData();
    }, [contador]);
    const cerrarNuevoCliente = () => {
        setNuevoCliente(false);
        setContador(contador + 1);
    }
    const ocultarEditarLead = () => {
        setEditarLead(false);
        setContador(contador + 1);
    }
    const ocultar = () => {
        setModalError(false);
        setModalSucc(false);
        props.refrescar();
        props.onHide();
        setContador(contador + 1);
    }
    const cerrarNuevaIntervencion = () => {
        setNuevaIntervencion(false);
        setContador(contador + 1);
    }
    const entering = () => {
        setContador(contador + 1);
    }
    const submitCliente = (cliente) => {
        const info = {
            Empresa: cliente.empresa,
            Direccion: cliente.direccion,
            Telefono: cliente.telefono,
            asesor_UniqueID: props.lead.asesor_UniqueID,
            NombreFactura: cliente.NombreFactura,
            NIT: cliente.NIT,
            DireccionFacturacion: cliente.DireccionFacturacion,
            CorreoFacturacion: cliente.CorreoFacturacion,
            ResponsableInscripcion: cliente.ResponsableInscripcion,
            TelefonoResponsable: cliente.TelefonoResponsable,
            CorreoResponsable: cliente.CorreoResponsable,
            ResponsablePago: cliente.ResponsablePago,
            CorreoPago: cliente.CorreoPago,
            TelefonoPago: cliente.TelefonoPago,
            FormaPago: cliente.FormaPago,
            Comentario: cliente.Comentario,
            Prioridad: cliente.Prioridad
        }
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/create/cliente', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data => {
                if (data.codigo == 200) {
                    const info = { UniqueID: props.lead.UniqueID };
                    const requestOptions = {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(info)
                    };
                    fetch(process.env.REACT_APP_DEFAULT_URL + '/api/update/leads/convertir', requestOptions)
                        .then((res) => {
                            return res.json();
                        })
                        .then(data => {
                            if (data.codigo == 200) {
                                setModalSucc(true);
                                setMensaje('Prospecto ingresado al sistema exitosamente');
                                cerrarNuevoCliente();
                            } else {
                                setModalError(true);
                                setMensaje('ERROR');
                            }
                        });
                } else {
                    setModalError(true);
                    setMensaje('ERROR');
                }
            });
    }
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable
            style={{ color: 'white' }}
            onEnter={entering}
        >
            <Modal.Header closeButton style={{ backgroundColor: "#1e3d52" }}>
                <Modal.Title id="contained-modal-title-vcenter" className="font-bold">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-info-lg" viewBox="0 0 16 16">
                        <path d="m9.708 6.075-3.024.379-.108.502.595.108c.387.093.464.232.38.619l-.975 4.577c-.255 1.183.14 1.74 1.067 1.74.72 0 1.554-.332 1.933-.789l.116-.549c-.263.232-.65.325-.905.325-.363 0-.494-.255-.402-.704l1.323-6.208Zm.091-2.755a1.32 1.32 0 1 1-2.64 0 1.32 1.32 0 0 1 2.64 0Z" />
                    </svg>
                    &nbsp;&nbsp;&nbsp;Seguimiento lead &nbsp;&nbsp;&nbsp;"{props.lead.nombre}"
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: "#1e3d52" }} className="cuerpo-modal-nuevo-cliente">
                <Container>
                    <Row style={{ height: '100%' }}>
                        <Col sm={12} md={12} lg={6} style={{ padding: '25px' }}>
                            <h1 style={{ textAlign: 'center' }}>Información de lead</h1>
                            <Row>
                                <Col sm={1} md={1} lg={1}>
                                    <div style={{ color: 'white', cursor: 'pointer', display: props.seguimiento }} data-toggle="tooltip" data-placement="top" title="Editar información" onClick={() => setEditarLead(true)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
                                            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                        </svg>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <ListGroup as="ol" style={{ padding: '15px' }}>
                                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                        <div className="ms-2 me-auto">
                                            <div className="fw-bold">Nombre</div>
                                        </div>
                                        {props.lead.nombre}
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                        <div className="ms-2 me-auto">
                                            <div className="fw-bold" >Puesto</div>
                                        </div>
                                        {props.lead.puesto}
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                        <div className="ms-2 me-auto">
                                            <div className="fw-bold">Institución</div>
                                        </div>
                                        {props.lead.institucion}
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                        <div className="ms-2 me-auto">
                                            <div className="fw-bold">Teléfono</div>
                                        </div>
                                        {props.lead.telefono}
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                        <div className="ms-2 me-auto">
                                            <div className="fw-bold">Correo</div>
                                        </div>
                                        {props.lead.correo}
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                        <div className="ms-2 me-auto">
                                            <div className="fw-bold">Origen</div>
                                        </div>
                                        {props.lead.origen}
                                    </ListGroup.Item>
                                </ListGroup>
                            </Row>
                            <Row>
                                <Button variant="light" style={{ display: props.seguimiento }} onClick={() => setNuevoCliente(true)}>Convertir en prospecto</Button>
                            </Row>
                        </Col>
                        <Col sm={12} md={12} lg={6} style={{ padding: '25px' }} className="columna-contenido-intervenciones">
                            <h1 style={{ textAlign: 'center' }}>Intervenciones con lead</h1>
                            <Container style={{ marginTop: '15px' }}>
                                <Row>
                                    <Col xs={1} md={1} lg={1}>
                                        <div style={{ color: 'white', cursor: 'pointer' }} data-toggle="tooltip" data-placement="top" title="Refrescar" onClick={() => setContador(contador + 1)} >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                                                <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                                            </svg>
                                        </div>
                                    </Col>
                                    <Col xs={1} md={1} lg={1}>
                                        <div style={{ color: 'white', cursor: 'pointer', display: props.seguimiento }} data-toggle="tooltip" data-placement="top" title="Nueva intervencion" onClick={() => setNuevaIntervencion(true)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                                            </svg>
                                        </div>
                                    </Col>
                                </Row>
                                {intervenciones.map((intervencion) =>
                                    <Row style={{ padding: '25px' }}>
                                        <Alert variant="light">
                                            <Alert.Heading>{intervencion.fecha}</Alert.Heading>
                                            <Form.Group className="mb-3" controlId="comentario">
                                                <Form.Control as="textarea" disabled placeholder="Ingrese sus comentarios sobre la solicitud" rows={3} style={{ resize: 'none' }} name="Comentario" value={intervencion.descripcion} />
                                            </Form.Group>
                                            <hr />
                                            <div className="d-flex justify-content-end">
                                                {'Tipo de intervención: ' + intervencion.tipo}
                                            </div>
                                        </Alert>
                                    </Row>
                                )}
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <ErrorModal
                color={"#b5bad0"}
                show={modalError}
                onHide={ocultar}
                mensaje={mensaje}
                consideracion={''}
            />
            <SuccessModal
                color={"#b5bad0"}
                show={modalSucc}
                onHide={ocultar}
                mensaje={mensaje}
                consideracion={''}
                enlace={''}
                llevaenlace={false}
            />
            <ModalNuevaIntervencion show={nuevaIntervencion} onHide={cerrarNuevaIntervencion} lead={props.lead} />
            <ModalEditarLead show={editarLead} onHide={ocultarEditarLead} refrescar={props.refrescar} lead={props.lead} />
            <ModalNuevoCliente
                show={nuevoCliente}
                onHide={cerrarNuevoCliente}
                submitCliente={submitCliente}
                lead={props.lead}
            />
        </Modal>
    )
}

export default ModalSeguimiento