import React, { Component } from 'react';
import { Modal, Row, Button, Form, Container } from "react-bootstrap";
export class EditarCalificacion extends Component {

    render() {
        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton style={{ backgroundColor: "#d3d3d4" }}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Editar Calificación
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: "#d3d3d4" }}>
                    <Form onSubmit={this.props.submit} name={'form-' + this.props.entregable.UniqueID}>
                        <Container>
                            <Row>
                                <Form.Group className="mb-3" controlId="Tel_RP">
                                    <Form.Label>Nota</Form.Label>
                                    <Form.Control required type="text" placeholder={"Nota sobre " + this.props.ponderacion + ' pts'} name={"nota-" + this.props.entregable.UniqueID} onChange={this.props.changeHandler} value={this.props.entregable.Ponderacion} />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group className="mb-3" controlId="Comentario">
                                    <Form.Label>Comentario</Form.Label>
                                    <Form.Control as="textarea" rows={3} style={{ resize: 'none' }} name={"comentario-" + this.props.entregable.UniqueID} onChange={this.props.changeHandler} value={this.props.entregable.Comentario} />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Button variant="outline-dark" className="font-bold" type="submit">Guardar</Button>
                            </Row>
                        </Container>
                    </Form>
                </Modal.Body>
            </Modal>

        );
    }
}