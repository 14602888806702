import React, { useEffect, useState } from 'react';
//import DEFAULT_URL from '../../../../../api/url';
import { Row, Container, Form, Button, ProgressBar, Alert, Col, Accordion, Dropdown } from "react-bootstrap";
const Especificaciones = (props) => {
    const [contador, setContador] = useState(0);
    const [formulario, setFormulario] = useState('none');
    const [exito, setExito] = useState('none');
    const [error, setError] = useState('none');
    const [confirmacion, setConfirmacion] = useState('none');
    const [cargando, setCargando] = useState('none');
    const [x, setX] = useState(0);
    const [y, setY] = useState(0);
    const [w, setW] = useState(0);
    const [h, setH] = useState(0);
    const [status, setStatus] = useState(100);
    const [mensaje, setMensaje] = useState('');
    const [detalle, setDetalle] = useState('block');
    useEffect(() => {
    }, [contador]);
    const submit = (evt) => {
        evt.preventDefault();
        setStatus(0);
        setMensaje('Sincronizando base de datos...');
        cargar();
        setContador(contador + 1);
        const info = { x_qr: x, y_qr: y, w_qr: w, h_qr: h, UniqueID: props.diploma };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/diplomas-y-certificados/update/coordenadas/qr', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(datos => {
                if (datos.codigo == 200) {
                    setStatus(100);
                    setMensaje('Especificaciones actualizadas exitosamente');
                    operacionExito();
                } else {
                    setMensaje('Ocurrió un error al actualizar información en db');
                    operacionError();
                }
            });

        setContador(contador + 1);
    }

    const onChange = (evt) => {
        if (evt.target.name === "x") {
            setX(evt.target.value);
        } else if (evt.target.name === "y") {
            setY(evt.target.value);
        } else if (evt.target.name === "w") {
            setW(evt.target.value);
        } else if (evt.target.name === "h") {
            setH(evt.target.value);
        }
        setContador(contador + 1);
    }
    const cargar = () => {
        setExito('none');
        setError('none');
        setConfirmacion('none');
        setCargando('block');
        setDetalle('none');
        setFormulario('none');
        setContador(contador + 1);
    }
    const operacionExito = () => {
        setExito('block');
        setError('none');
        setConfirmacion('none');
        setCargando('none');
        setDetalle('none');
        setFormulario('none');
        props.refrescar();
        setContador(contador + 1);
    }
    const operacionError = () => {
        setExito('none');
        setError('block');
        setConfirmacion('none');
        setCargando('none');
        setDetalle('none');
        setFormulario('none');
        setContador(contador + 1);
    }
    const goBackToDetail = () => {
        setExito('none');
        setError('none');
        setConfirmacion('none');
        setCargando('none');
        setDetalle('block');
        setFormulario('none');
        setContador(contador + 1);
    }
    const goToForm = () => {
        setX(props.x_qr);;
        setY(props.y_qr);
        setH(props.h_qr);
        setW(props.w_qr);
        setFormulario('block');
        setExito('none');
        setError('none');
        setConfirmacion('none');
        setCargando('none');
        setDetalle('none');
        setContador(contador + 1);
    }
    return (
        <Container fluid>
            <Row style={{ display: detalle, marginTop: '45px' }}>
            </Row>
            <Row style={{ display: detalle, marginTop: '45px' }}>
                <h3>Especificaciones para código qr</h3>
            </Row>
            <Row style={{ display: detalle, marginTop: '45px' }}>
                <h4>Coordenada en x: <strong>{props.x_qr}</strong></h4>
            </Row>
            <Row style={{ display: detalle, marginTop: '45px' }}>
                <h4>Coordenada en y: <strong>{props.y_qr}</strong></h4>
            </Row>
            <Row style={{ display: detalle, marginTop: '45px' }}>
                <h4>Ancho en pixeles: <strong>{props.w_qr}px</strong></h4>
            </Row>
            <Row style={{ display: detalle, marginTop: '45px' }}>
                <h4>Alto en pixeles: <strong>{props.h_qr}px</strong></h4>
            </Row>
            <Row style={{ display: detalle, marginTop: '45px' }}>
                <Button variant = 'outline-primary' onClick={goToForm}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                </svg>&nbsp;&nbsp;&nbsp;&nbsp;Editar especificaciones</Button>
            </Row>
            <Row style={{ display: formulario, marginTop: '25px' }}>
                <Form onSubmit={submit}>
                    <Container>
                        <Row>
                            <Col xs={12} md={12} lg={6}>
                                <Form.Group>
                                    <Form.Label>Coordenada x para código qr</Form.Label>
                                    <Form.Control type="number" step=".01" required placeholder="Ingrese la coordenada en x para código qr" name="x" onChange={onChange} value={x} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={12} lg={6}>
                                <Form.Group>
                                    <Form.Label>Coordenada y para código qr</Form.Label>
                                    <Form.Control type="number" step=".01" required placeholder="Ingrese la coordenada en y para código qr" name="y" onChange={onChange} value={y} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={12} lg={6}>
                                <Form.Group>
                                    <Form.Label>Ancho del código qr en pixeles</Form.Label>
                                    <Form.Control type="number" step=".01" required placeholder="Ingrese el ancho del código qr en pixeles" name="w" onChange={onChange} value={w} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={12} lg={6}>
                                <Form.Group>
                                    <Form.Label>Alto del código qr en pixeles</Form.Label>
                                    <Form.Control type="number" step=".01" required placeholder="Ingrese el alto del código qr en pixeles" name="h" onChange={onChange} value={h} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '35px' }}>
                            <Button type="submit" variant="success">Registrar cambios</Button>
                        </Row>
                        <Row style={{ marginTop: '35px' }}>
                            <Button onClick={goBackToDetail} variant="outline-dark">Cancelar</Button>
                        </Row>
                    </Container>
                </Form>
            </Row>
            <Row style={{ display: cargando }}>
                <Alert variant='dark'>
                    <Alert.Heading>Procesando operación</Alert.Heading>
                    <p>
                        Esto puede demorar unos minutos
                    </p>
                    <hr />
                    <p className="mb-0">
                        Por favor espere...<br /><br />
                        {mensaje}
                        <br /><br />
                        <ProgressBar animated now={status} variant='dark' />
                    </p>
                </Alert>
            </Row>
            <Row style={{ display: exito }}>
                <Alert variant='success'>
                    <Alert.Heading>Operación procesada con éxito</Alert.Heading>
                    <p>
                        {mensaje}
                    </p>
                    <Row>
                        <Col xs={8} md={10} lg={10}>
                        </Col>
                        <Col xs={4} md={2} lg={2}>
                            <Row>
                                <Button variant='outline-success' onClick={goBackToDetail}>Ok</Button>
                            </Row>
                        </Col>
                    </Row>
                </Alert>
            </Row>
            <Row style={{ display: error }}>
                <Alert variant='danger'>
                    <Alert.Heading>{mensaje}</Alert.Heading>
                    <Row>
                        <Col xs={8} md={10} lg={10}>
                        </Col>
                        <Col xs={4} md={2} lg={2}>
                            <Row>
                                <Button variant='outline-danger' onClick={goBackToDetail}>Ok</Button>
                            </Row>
                        </Col>
                    </Row>
                </Alert>
            </Row>
        </Container>)
}

export default Especificaciones