import React, { useState, useEffect } from 'react';
import { Modal, Row, Tabs, Tab, Table, Col, Container, ListGroup } from "react-bootstrap";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import PonderarParticipantes from './PonderarParticipantes';
import { EditarActividad } from './EditarAcrividad';
import { ErrorModal } from '../../../Utils/ErrorModal/ErrorModal';
import { SuccessModal } from '../../../Utils/SuccessModal/SuccessModal';
import { ModalNuevaActividad } from './ModalNuevaActividad';
import { Confirmacion } from '../../../Utils/ConfirmationModal/Confirmacion';
//import DEFAULT_URL from '../../../../api/url'
const ModalActividades = (props) => {
    const [actividades, setActividades] = useState([]);
    const [total, setTotal] = useState('');
    const [modalSucc, setModalSucc] = useState(false);
    const [modalError, setModalError] = useState(false);
    const [mensaje, setMensaje] = useState(false);
    const [consideracion, setConsideracion] = useState('');
    const [nueva_actividad, setNueva_actividad] = useState(false);
    const [mensajeConfirmacion, setMensajeConfirmacion] = useState(false);
    const [id_actual, setId_actual] = useState('');
    const [contador, setContador] = useState(0);
    const [bandera, setBandera] = useState(0);
    useEffect(() => {
        async function fetchData() {
            fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/evento/actividades/' + props.evento_uniqueid, { cache: "no-cache" })
                .then((res) => {
                    return res.json();
                })
                .then(data => {
                    setActividades([]);
                    setActividades(data.rows);
                    setTotal(data.total[0].total)
                });
        }
        fetchData();
    }, [contador]);
    useEffect(() => {

    }, [bandera])
    const exportDataExcel = () => {
        const exp = [];
        for (let i = 0; i < props.participantes.length; i++) {
            var temp = { registro_academico: props.participantes[i].registro_academico, identificacion: props.participantes[i].Identificacion, Nombre: props.participantes[i].Nombres + ' ' + props.participantes[i].Apellidos, Nota: 0, Comentario: 'Observaciones' };
            exp.push(temp);
        }
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(exp);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, 'Listado Participantes ' + props.evento_uniqueid + fileExtension);
    }
    const reporteNotas = () => {
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/actividad/finalgrades/' + props.evento_uniqueid, { cache: "no-cache" })
            .then((res) => {
                return res.json();
            })
            .then(data_ => {
                const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                const fileExtension = '.xlsx';
                const ws = XLSX.utils.json_to_sheet(data_.respuesta);
                const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const data = new Blob([excelBuffer], { type: fileType });
                FileSaver.saveAs(data, Date.now() + fileExtension);
            });
    }
    const onchangehandler = (evt) => {
        const arr_name = evt.target.name.split("-");
        const index_finder = (actividad) => actividad.UniqueID == arr_name[1];
        var new_array = actividades;
        switch (arr_name[0]) {
            case "Nombre":
                new_array[new_array.findIndex(index_finder)].Nombre = evt.target.value;
                console.log(new_array[new_array.findIndex(index_finder)].Nombre = evt.target.value);
                setActividades(new_array);
                break;
            case "Descripcion":
                new_array[new_array.findIndex(index_finder)].Descripcion = evt.target.value;
                console.log(new_array[new_array.findIndex(index_finder)].Descripcion = evt.target.value);
                setActividades(new_array);
                break;
            case "Ponderacion":
                new_array[new_array.findIndex(index_finder)].Ponderacion = evt.target.value;
                console.log(new_array[new_array.findIndex(index_finder)].Ponderacion = evt.target.value);
                setActividades(new_array);
                break;
            case "Criterio":
                new_array[new_array.findIndex(index_finder)].Criterio = evt.target.value;
                console.log(new_array[new_array.findIndex(index_finder)].Criterio = evt.target.value);
                setActividades(new_array);
                break;
        }
        setBandera(bandera + 1);
    }

    const update_actividad = (evt) => {
        evt.preventDefault();
        const arr_actividad = evt.target.name.split("-");
        const index_finder = (actividad) => actividad.UniqueID == arr_actividad[1];
        const info = actividades[actividades.findIndex(index_finder)];
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/update/actividad', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data_ => {
                if (data_.codigo == 200) {
                    setContador(contador + 1);
                    setMensaje('Actividad actualizada exitosamente.');
                    setConsideracion('Por favor validar la información.');
                    setModalSucc(true);
                } else {
                    setMensaje(data_.respuesta.sqlState + ": " + data_.respuesta.sqlMessage);
                    setModalError(true);
                }
            });
    }

    const ocultar = () => {
        setModalError(false);
        setModalSucc(false);
        setConsideracion('');
    }

    const submitnewactividad = (actividad) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(actividad)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/create/actividad', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data_ => {
                if (data_.codigo == 200) {
                    const requestBody = { EventoUniqueID: props.evento_uniqueid, Ponderacion: actividad.Ponderacion, actividad_UniqueID: data_.UniqueID, Criterio: actividad.Criterio };
                    const info = {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(requestBody)
                    };
                    fetch(process.env.REACT_APP_DEFAULT_URL + '/api/upload/registro_academico/actividad/asignar', info)
                        .then((res) => {
                            return res.json();
                        })
                        .then(data_resp => {
                            if (data_resp.codigo == 200) {
                                ocultar_nueva_actividad();
                                setContador(contador + 1);
                                setMensaje('Actividad registrada exitosamente');
                                setConsideracion('');
                                setModalSucc(true);
                            } else {
                                setMensaje(data_resp.respuesta.sqlState + ": " + data_resp.respuesta.sqlMessage);
                                setModalError(true);
                            }
                        });
                } else {
                    setMensaje(data_.respuesta.sqlState + ": " + data_.respuesta.sqlMessage);
                    setModalError(true);
                }
            });
    }
    const ocultar_nueva_actividad = () => {
        setNueva_actividad(false);
    }

    const deleteactividad = (id) => {
        var confirmatinomessage = "¿Está segur@ que desea elimnar la actividad?";
        setConsideracion('Esta acción no se puede revertir, se eliminará la actividad y todos los entregables registrados.');
        setMensaje(confirmatinomessage);
        setId_actual(id);
        setMensajeConfirmacion(true);
    }
    const accioneliminaractividad = (id, extra) => {
        const info = { UniqueID: id };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/delete/actividad', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data_ => {
                if (data_.codigo == 200) {
                    ocultarConfirmacion();
                    setContador(contador + 1);
                    setMensaje('Actividad eliminada exitosamente');
                    setConsideracion('Se eliminó tanto la actividad como los entregables resgistrados.');
                    setModalSucc(true);
                } else {
                    setMensaje(data_.respuesta.sqlState + ": " + data_.respuesta.sqlMessage);
                    setModalError(true);
                }
            });
    }
    const ocultarConfirmacion = () => {
        setMensajeConfirmacion(false);
    }
    return (
        <Container>
            <Modal
                {...props}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
                style={{ textAlign: 'center', color: 'white' }}
                fullscreen={true}
            >
                <Modal.Header closeButton style={{ backgroundColor: "#1e3d52", borderColor: 'transparent' }}>
                    <Modal.Title id="contained-modal-title-vcenter" className="font-bold">
                        Actividades
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: "#1e3d52", borderColor: 'transparent', height: '60vh' }} id="body-modal-edit-evento">
                    <Tabs defaultActiveKey="ponderacion" className="mb-3">
                        <Tab tabClassName="tab-actividades" eventKey="ponderacion" title="Planificación" style={{ padding: '2%' }}>
                            <Table striped bordered hover variant="light">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Actividad</th>
                                        <th>Ponderación</th>
                                    </tr>
                                    {actividades.map((actividad, index) => <tr key={actividad.UniqueID}><td>{index + 1}</td><td>{actividad.Nombre}</td><td>{actividad.Ponderacion}</td></tr>)}
                                    <tr>
                                        <td colSpan={2} style={{ fontWeight: 'bolder' }}>TOTAL</td>
                                        <td style={{ fontWeight: 'bolder' }}>{total}</td>
                                    </tr>
                                </thead>
                                <tbody>
                                </tbody>
                            </Table>
                        </Tab>
                        <Tab tabClassName="tab-actividades" eventKey="calificaciones" title="Calificaciones">
                            <Tab.Container>
                                <Row style={{ marginBottom: '25px' }}>
                                    <Col xs={1} md={1}>
                                        <div style={{ cursor: 'pointer', color: 'white' }} data-toggle="tooltip" data-placement="top" title="Descargar listado excel" onClick={exportDataExcel} >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-cloud-download-fill" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M8 0a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 4.095 0 5.555 0 7.318 0 9.366 1.708 11 3.781 11H7.5V5.5a.5.5 0 0 1 1 0V11h4.188C14.502 11 16 9.57 16 7.773c0-1.636-1.242-2.969-2.834-3.194C12.923 1.999 10.69 0 8 0zm-.354 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V11h-1v3.293l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z" />
                                            </svg>
                                        </div>
                                    </Col>
                                    <Col xs={1} md={1}>
                                        <div style={{ cursor: 'pointer', color: 'white' }} data-toggle="tooltip" data-placement="top" title="Descargar reporte de notas" onClick={reporteNotas} >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                            </svg>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={4}>
                                        <ListGroup>
                                            {actividades.map((actividad) => <ListGroup.Item action key={actividad.UniqueID} className="tab-actividades detalle" href={"#" + actividad.UniqueID}>{actividad.Nombre}</ListGroup.Item>)}
                                        </ListGroup>
                                    </Col>
                                    <Col sm={8}>
                                        <Tab.Content>
                                            {actividades.map((actividad) => <Tab.Pane key={actividad.UniqueID} eventKey={"#" + actividad.UniqueID}><PonderarParticipantes participantes={props.participantes} actividad={actividad.UniqueID} ponderacion={actividad.Ponderacion} evento={props.evento_uniqueid} refrescar={() => { setContador(contador + 1); }} /></Tab.Pane>)}
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </Tab>
                        <Tab tabClassName="tab-actividades" eventKey="modificar-ponderacion" title="Modificar Planificación">
                            <Tab.Container>
                                <Row style={{ marginBottom: '25px' }}>
                                    <Col xs={1} md={1}>
                                        <div style={{ cursor: 'pointer', color: 'white' }} data-toggle="tooltip" data-placement="top" title="Nueva Actividad" onClick={() => setNueva_actividad(true)} >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                                            </svg>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={4}>
                                        <ListGroup>
                                            {actividades.map((actividad) => <ListGroup.Item action key={actividad.UniqueID} className="tab-actividades detalle" href={"#" + actividad.UniqueID}>{actividad.Nombre}</ListGroup.Item>)}
                                        </ListGroup>
                                    </Col>
                                    <Col sm={8}>
                                        <Tab.Content>
                                            {actividades.map((actividad) =>
                                                <Tab.Pane key={actividad.UniqueID} eventKey={"#" + actividad.UniqueID}>
                                                    <Container style={{ color: 'white' }}>
                                                        <Row>
                                                            <Col xs={1} md={1}>
                                                                <div style={{ cursor: 'pointer', color: 'white' }} data-toggle="tooltip" data-placement="top" title="Eliminar Actividad" onClick={() => deleteactividad(actividad.UniqueID)} >
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-trash-fill" viewBox="0 0 16 16">
                                                                        <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                                                                    </svg>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <EditarActividad key={actividad.UniqueID} actividad={actividad} onchangehandler={onchangehandler} update_actividad={update_actividad} />
                                                        </Row>
                                                    </Container>
                                                </Tab.Pane>
                                            )}
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </Tab>
                    </Tabs>
                </Modal.Body>
            </Modal>
            <SuccessModal
                color={"#b5bad0"}
                show={modalSucc}
                onHide={ocultar}
                mensaje={mensaje}
                consideracion={consideracion}
                enlace={''}
                llevaenlace={false}
            />
            <ErrorModal
                color={"#b5bad0"}
                show={modalError}
                onHide={ocultar}
                mensaje={mensaje}
                consideracion={consideracion}
            />
            <ModalNuevaActividad
                submitnewactividad={submitnewactividad}
                show={nueva_actividad}
                onHide={ocultar_nueva_actividad}
                evento_UniqueID={props.evento_uniqueid}
            />

            <Confirmacion
                show={mensajeConfirmacion}
                onHide={ocultarConfirmacion}
                accion={accioneliminaractividad}
                mensaje={mensaje}
                uniqueid={id_actual}
                consideracion={consideracion}
                param={''}
            />
        </Container>

    );
}

export default ModalActividades
/*
export class ModalActividades extends Component {
    state = { actividades: [], total: '', participante_export: [], modalSucc: false, modalError: false, Mensaje: false, Consideracion: '', nueva_actividad: false, mensajeConfirmacion: false, id_actual: '' };
    componentDidMount = () => {
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/evento/actividades/' + this.props.evento_uniqueid, { cache: "no-cache" })
            .then((res) => {
                return res.json();
            })
            .then(data => {
                this.setState({ actividades: data.rows });
                this.setState({ total: data.total[0].total })
                //console.log(data.rows);
            });
    };

    exportDataExcel = () => {
        const exp = [];
        for (let i = 0; i < this.props.participantes.length; i++) {
            var temp = { registro_academico: this.props.participantes[i].registro_academico, identificacion: this.props.participantes[i].Identificacion, Nombre: this.props.participantes[i].Nombres + ' ' + this.props.participantes[i].Apellidos, Nota: 0, Comentario: 'Observaciones' };
            exp.push(temp);
        }
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(exp);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, 'Listado Participantes ' + this.props.evento_uniqueid + fileExtension);
    }
    reporteNotas = () => {
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/actividad/finalgrades/' + this.props.evento_uniqueid, { cache: "no-cache" })
            .then((res) => {
                return res.json();
            })
            .then(data_ => {
                //console.log(data_);
                const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                const fileExtension = '.xlsx';
                const ws = XLSX.utils.json_to_sheet(data_.respuesta);
                const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const data = new Blob([excelBuffer], { type: fileType });
                FileSaver.saveAs(data, Date.now() + fileExtension);
            });
    }
    onchangehandler = (evt) => {
        const arr_name = evt.target.name.split("-");
        const index_finder = (actividad) => actividad.UniqueID == arr_name[1];
        var new_array = this.state.actividades;

        switch (arr_name[0]) {
            case "Nombre":
                new_array[new_array.findIndex(index_finder)].Nombre = evt.target.value;
                this.setState({ actividades: new_array });
                break;
            case "Descripcion":
                new_array[new_array.findIndex(index_finder)].Descripcion = evt.target.value;
                this.setState({ actividades: new_array });
                break;
            case "Ponderacion":
                new_array[new_array.findIndex(index_finder)].Ponderacion = evt.target.value;
                this.setState({ actividades: new_array });
                break;
            case "Criterio":
                new_array[new_array.findIndex(index_finder)].Criterio = evt.target.value;
                this.setState({ actividades: new_array });
                break;
        }
    }

    update_actividad = (evt) => {
        evt.preventDefault();
        const arr_actividad = evt.target.name.split("-");
        const index_finder = (actividad) => actividad.UniqueID == arr_actividad[1];
        const info = this.state.actividades[this.state.actividades.findIndex(index_finder)];
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/update/actividad', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data_ => {
                if (data_.codigo == 200) {
                    this.setState({ Mensaje: 'Actividad actualizada exitosamente.' });
                    this.setState({ Consideración: 'Por favor recargue la página.' });
                    this.setState({ modalSucc: true });
                    fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/evento/actividades/' + this.props.evento_uniqueid, { cache: "no-cache" })
                        .then((res) => {
                            return res.json();
                        })
                        .then(data => {
                            this.setState({ actividades: data.rows });
                            this.setState({ total: data.total[0].total })
                        });
                } else {
                    this.setState({ Mensaje: data_.respuesta.sqlState + ": " + data_.respuesta.sqlMessage });
                    this.setState({ modalError: true });
                }
            });
    }

    ocultar = () => {
        this.setState({ modalError: false });
        this.setState({ modalSucc: false });
        this.setState({ Consideracion: '' });
    }

    submitnewactividad = (actividad) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(actividad)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/create/actividad', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data_ => {
                if (data_.codigo == 200) {
                    const requestBody = { EventoUniqueID: this.props.evento_uniqueid, Ponderacion: actividad.Ponderacion, actividad_UniqueID: data_.UniqueID, Criterio: actividad.Criterio };
                    const info = {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(requestBody)
                    };
                    fetch(process.env.REACT_APP_DEFAULT_URL + '/api/upload/registro_academico/actividad/asignar', info)
                        .then((res) => {
                            return res.json();
                        })
                        .then(data_resp => {
                            if (data_resp.codigo == 200) {
                                this.ocultar_nueva_actividad();
                                this.setState({ Mensaje: 'Actividad registrada exitosamente' });
                                this.setState({ Consideración: '' });
                                this.setState({ modalSucc: true });
                                fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/evento/actividades/' + this.props.evento_uniqueid, { cache: "no-cache" })
                                    .then((res) => {
                                        return res.json();
                                    })
                                    .then(data => {
                                        this.setState({ actividades: data.rows });
                                        this.setState({ total: data.total[0].total })
                                    });
                            } else {
                                this.setState({ Mensaje: data_resp.respuesta.sqlState + ": " + data_resp.respuesta.sqlMessage });
                                this.setState({ modalError: true });
                            }
                        });
                } else {
                    this.setState({ Mensaje: data_.respuesta.sqlState + ": " + data_.respuesta.sqlMessage });
                    this.setState({ modalError: true });
                }
            });
    }
    ocultar_nueva_actividad = () => {
        this.setState({ nueva_actividad: false });
    }

    deleteactividad = (id) => {
        var confirmatinomessage = "¿Está segur@ que desea elimnar la actividad?";
        this.setState({ Consideracion: 'Esta acción no se puede revertir, se eliminará la actividad y todos los entregables registrados.' });
        this.setState({ Mensaje: confirmatinomessage });
        this.setState({ id_actual: id });
        this.setState({ mensajeConfirmacion: true });
    }
    accioneliminaractividad = (id, extra) => {
        const info = { UniqueID: id };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(info)
        };
        fetch(process.env.REACT_APP_DEFAULT_URL + '/api/delete/actividad', requestOptions)
            .then((res) => {
                return res.json();
            })
            .then(data_ => {
                if (data_.codigo == 200) {
                    this.ocultarConfirmacion();
                    this.setState({ Mensaje: 'Actividad eliminada exitosamente' });
                    this.setState({ Consideracion: 'Se eliminó tanto la actividad como los entregables resgistrados.' });
                    this.setState({ modalSucc: true });
                    fetch(process.env.REACT_APP_DEFAULT_URL + '/api/read/evento/actividades/' + this.props.evento_uniqueid, { cache: "no-cache" })
                        .then((res) => {
                            return res.json();
                        })
                        .then(data => {
                            this.setState({ actividades: data.rows });
                            this.setState({ total: data.total[0].total })
                        });
                } else {
                    //console.log(data_);
                    this.setState({ Mensaje: data_.respuesta.sqlState + ": " + data_.respuesta.sqlMessage });
                    this.setState({ modalError: true });
                }
            });
    }
    ocultarConfirmacion = () => {
        this.setState({ mensajeConfirmacion: false });
    }
    render() {
        return (
            <Container>
                <Modal
                    {...this.props}
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    scrollable
                    style={{ textAlign: 'center', color: 'white' }}
                    fullscreen={true}
                >
                    <Modal.Header closeButton style={{ backgroundColor: "#1e3d52", borderColor: 'transparent' }}>
                        <Modal.Title id="contained-modal-title-vcenter" className="font-bold">
                            Actividades
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ backgroundColor: "#1e3d52", borderColor: 'transparent', height: '60vh' }} id="body-modal-edit-evento">
                        <Tabs defaultActiveKey="ponderacion" className="mb-3">
                            <Tab tabClassName="tab-actividades" eventKey="ponderacion" title="Planificación" style={{ padding: '2%' }}>
                                <Table striped bordered hover variant="light">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Actividad</th>
                                            <th>Ponderación</th>
                                        </tr>
                                        {this.state.actividades.map((actividad, index) => <tr key={actividad.UniqueID}><td>{index + 1}</td><td>{actividad.Nombre}</td><td>{actividad.Ponderacion}</td></tr>)}
                                        <tr>
                                            <td colSpan={2} style={{ fontWeight: 'bolder' }}>TOTAL</td>
                                            <td style={{ fontWeight: 'bolder' }}>{this.state.total}</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    </tbody>
                                </Table>
                            </Tab>
                            <Tab tabClassName="tab-actividades" eventKey="calificaciones" title="Calificaciones">
                                <Tab.Container>
                                    <Row style={{ marginBottom: '25px' }}>
                                        <Col xs={1} md={1}>
                                            <div style={{ cursor: 'pointer', color: 'white' }} data-toggle="tooltip" data-placement="top" title="Descargar listado excel" onClick={this.exportDataExcel} >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-cloud-download-fill" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd" d="M8 0a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 4.095 0 5.555 0 7.318 0 9.366 1.708 11 3.781 11H7.5V5.5a.5.5 0 0 1 1 0V11h4.188C14.502 11 16 9.57 16 7.773c0-1.636-1.242-2.969-2.834-3.194C12.923 1.999 10.69 0 8 0zm-.354 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V11h-1v3.293l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z" />
                                                </svg>
                                            </div>
                                        </Col>
                                        <Col xs={1} md={1}>
                                            <div style={{ cursor: 'pointer', color: 'white' }} data-toggle="tooltip" data-placement="top" title="Descargar reporte de notas" onClick={this.reporteNotas} >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                                                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                                </svg>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={4}>
                                            <ListGroup>
                                                {this.state.actividades.map((actividad) => <ListGroup.Item action key={actividad.UniqueID} className="tab-actividades detalle" href={"#" + actividad.UniqueID}>{actividad.Nombre}</ListGroup.Item>)}
                                            </ListGroup>
                                        </Col>
                                        <Col sm={8}>
                                            <Tab.Content>
                                                {this.state.actividades.map((actividad) => <Tab.Pane key={actividad.UniqueID} eventKey={"#" + actividad.UniqueID}><PonderarParticipantes participantes={this.props.participantes} actividad={actividad.UniqueID} ponderacion={actividad.Ponderacion} evento={this.props.evento_uniqueid} /></Tab.Pane>)}
                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            </Tab>
                            <Tab tabClassName="tab-actividades" eventKey="modificar-ponderacion" title="Modificar Planificación">
                                <Tab.Container>
                                    <Row style={{ marginBottom: '25px' }}>
                                        <Col xs={1} md={1}>
                                            <div style={{ cursor: 'pointer', color: 'white' }} data-toggle="tooltip" data-placement="top" title="Nueva Actividad" onClick={() => this.setState({ nueva_actividad: true })} >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                                                </svg>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={4}>
                                            <ListGroup>
                                                {this.state.actividades.map((actividad) => <ListGroup.Item action key={actividad.UniqueID} className="tab-actividades detalle" href={"#" + actividad.UniqueID}>{actividad.Nombre}</ListGroup.Item>)}
                                            </ListGroup>
                                        </Col>
                                        <Col sm={8}>
                                            <Tab.Content>
                                                {this.state.actividades.map((actividad) =>
                                                    <Tab.Pane key={actividad.UniqueID} eventKey={"#" + actividad.UniqueID}>
                                                        <Container style={{ color: 'white' }}>
                                                            <Row>
                                                                <Col xs={1} md={1}>
                                                                    <div style={{ cursor: 'pointer', color: 'white' }} data-toggle="tooltip" data-placement="top" title="Eliminar Actividad" onClick={() => this.deleteactividad(actividad.UniqueID)} >
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-trash-fill" viewBox="0 0 16 16">
                                                                            <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                                                                        </svg>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <EditarActividad key={actividad.UniqueID} actividad={actividad} onchangehandler={this.onchangehandler} update_actividad={this.update_actividad} />
                                                            </Row>
                                                        </Container>
                                                    </Tab.Pane>
                                                )}
                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            </Tab>
                        </Tabs>
                    </Modal.Body>
                </Modal>
                <SuccessModal
                    color={"#b5bad0"}
                    show={this.state.modalSucc}
                    onHide={this.ocultar}
                    mensaje={this.state.Mensaje}
                    consideracion={this.state.Consideracion}
                    enlace={''}
                    llevaenlace={false}
                />
                <ErrorModal
                    color={"#b5bad0"}
                    show={this.state.modalError}
                    onHide={this.ocultar}
                    mensaje={this.state.Mensaje}
                    consideracion={this.state.Consideracion}
                />
                <ModalNuevaActividad
                    submitnewactividad={this.submitnewactividad}
                    show={this.state.nueva_actividad}
                    onHide={this.ocultar_nueva_actividad}
                    evento_UniqueID={this.props.evento_uniqueid}
                />

                <Confirmacion
                    show={this.state.mensajeConfirmacion}
                    onHide={this.ocultarConfirmacion}
                    accion={this.accioneliminaractividad}
                    mensaje={this.state.Mensaje}
                    uniqueid={this.state.id_actual}
                    consideracion={this.state.Consideracion}
                    param={''}
                />
            </Container>

        );
    }
}
*/